const dayjs = require('dayjs')
require('dayjs/locale/ja')

class DateUtil {

  static dateStringDate(date) {
    return this.dateStringBase(dayjs(date), "YYYY-MM-DD")
  }
  static dateStringDateTime(date) {
    return this.dateStringBase(dayjs(date), "YYYY-MM-DD HH:mm:ss")
  }
  static dateStringDateTimeMilli(date) {
    return this.dateStringBase(dayjs(date), "YYYY-MM-DD HH:mm:ss:SSS")
  }
  static dateStringBase(date, format) {
    return dayjs(date).format(format)
  }
  static getUnixTimeStampOfSecond() {
    return dayjs().unix()
  }
  static getUnixTimeStampOfMilliSecond() {
    return dayjs().valueOf()
  }
  
  static utcDateStringToJST(dateString) {
    
    let date = dayjs.utc(dateString)
    dayjs.locale('ja')
    let jstStr = dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    console.log(`utcDateStringToJST ${dateString} -> ${jstStr}`)
    return jstStr
  }
  /**
   * 日本時間の現在日時文字列を取得します。
   * @returns {String} 日本時間の現在日時文字列
   */
  static getDateStringDateTimeMilli() {
    dayjs.locale('ja')
    return dayjs().format('YYYY-MM-DD HH:mm:ss:SSS')
  }
  /**
   * 日本時間の現在日付文字列を取得します。
   * @returns {String} 日本時間の現在日付文字列
   */
  static getDateString(format='YYYY-MM-DD') {
    dayjs.locale('ja')
    return dayjs().format(format)
  }
  /**
   * 日本時間の現在日時文字列を取得します。
   * @returns {String} 日本時間の現在日付文字列
   */
  static getDateTimeString() {
    dayjs.locale('ja')
    return dayjs().format('YYYY-MM-DD HH:mm:ss')
  }
  static getFormatString(dateString, format) {
    try {
      let date = dayjs(dateString);
      return date.format(format);
    } catch (e) {
      console.log(e);
      return '';
    }
  }

  static isAfter(baseDate, targetDate) {
    let a = dayjs(baseDate)
    let b = dayjs(targetDate)
    console.log(`isAfter ${a.format('YYYY-MM-DD HH:mm:ss')} ${b.format('YYYY-MM-DD HH:mm:ss')}`)

    return a.isAfter(b)
  }

  static isBefore(baseDate, targetDate) {
    return dayjs(baseDate).isBefore(targetDate)
  }

  /**
   * 現在日時が指定した期間範囲内かどうかを判定します。
   * @param {String} from 期間開始文字列
   * @param {String} end 期間終了文字列
   * @returns {Boolean} 期間範囲内の場合true、さもなくばfalse
   */
  static isWithinPeriod(from, to) {
    let result = false;

    // 現在日時
    const nowDateTime = this.getDateTimeString();
    const now = new Date(nowDateTime);

    // 開始/終了
    const format = 'YYYY/MM/DD HH:mm:ss';
    const formatFrom = this.getFormatString(from, format);
    const formatTo = this.getFormatString(to, format);

    console.log(now)
    console.log(formatFrom)

    const start = new Date(formatFrom);
    const end = new Date(formatTo);

    if (now < start || now > end) {
      // 有効期限範囲外
      return result;
    }

    result = true;
    console.log('有効期限範囲内')
    return result;
  }
  /**
   * 指定した日時が開始、終了の期間内かどうかを判定します。
   *
   * @param {String} target 対象日時
   * @param {String} start 判定開始日時
   * @param {String} end 判定終了日時
   * @returns {Boolean} 期間内であればtrue、さもなくばfalse
   */
  static isWithinDate(target, start, end) {

    // 指定時刻を文字列からオブジェクト生成
    const targetDate = new Date(target)
    const startDate = new Date(start)
    const endDate = new Date(end)

    if (targetDate.getTime() < startDate.getTime()) {
      // 開始日時よりも前 -> 範囲外
      return false;
    }

    if (targetDate.getTime() > endDate.getTime()) {
      // 終了日時よりも後 -> 範囲外
      return false;
    }

    // 範囲内
    return true;
  }
  /**
   * 指定日が指定した期間範囲内かどうかを判定します。
   * @param {String} from 期間開始文字列
   * @param {String} end 期間終了文字列
   * @param {String} target チェック対象日
   * @returns {Boolean} 期間範囲内の場合true、さもなくばfalse
   */
  static isWithinPeriodTarget(from, to, target) {

    let result = false;
    let _from = from.length == 10 ? from + " 00:00:00" : from
    let _to = to.length == 10 ? to + " 23:59:59" : to
    let _target = target.length == 10 ? target + " 00:00:00" : target

    // チェック日時
    const now = new Date(_target);

    // 開始/終了  
    const format = 'YYYY/MM/DD HH:mm:ss';
    const formatFrom = this.getFormatString(_from, format);
    const formatTo = this.getFormatString(_to, format);

    console.log(now)
    console.log(formatFrom)

    const start = new Date(formatFrom);
    const end = new Date(formatTo);

    if (now < start || now > end) {
      // 有効期限範囲外
      return result;
    }

    result = true;
    return result;
  }
  /**
   * 日付の差分を返す。
   * @param {*} fromString 
   * @param {*} toString 
   * @param {*} option dayJsのdiffメソッドが対応するオプション文字列
   * @returns 
   */
  static diff(fromString, toString, option="day") {
    try {
      const dateTo = dayjs(toString);
      const dateFrom = dayjs(fromString);
      return dateFrom.diff(dateTo, option);
    } catch (e) {
      console.log(e.stack)
      return ""
    }
  }

}

module.exports = DateUtil
