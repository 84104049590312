<template>
  <div>
    <div class="container-fluid mt-1">
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <div class="container-fluid mt-1">
            <div class="card">
              <div class="card-header border-0">
                <div class="row">

                  <div class="col-12 text-right">
                    <base-button
                      icon
                      outline
                      type="primary"
                      @click="onClickNewTemplate"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fa fa-plus-square"></i
                      ></span>
                      <span class="btn-inner--text">新規帳票テンプレート</span>

                    </base-button>

                  </div>
                </div>
              </div>
              <!-- 帳票テンプレート一覧表示テーブル -->
              <el-table
                class="table-responsive align-items-center table-flush"
                header-row-class-name="thead-light"
                :data="formTemplateDatas"
              >
                <el-table-column
                  label="帳票名"
                  min-width="200px"
                  prop="name"
                  sortable
                >
                  <template v-slot="{ row }">
                    <!-- <img :src="row.project.image" class="avatar rounded-circle mr-3" /> -->
                    <b>
                      <a href="#!" @click.prevent="onClickFormTemplate(row)"
                        >{{ row.formTemplate.name }}
                      </a>
                    </b>
                  </template>
                </el-table-column>
                <el-table-column label="データタイプ" width="150px">
                  <template v-slot="{ row }">
                    <span>{{ row.formTemplate.id }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="メモ">
                  <template v-slot="{ row }">
                    <span>{{ row.formTemplate.memo }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="作成日" width="150px" sortable>
                  <template v-slot="{ row }">
                    <span>{{ dateStringFormat(row.formTemplate.createDate) }}</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column>
                  <template v-slot="{ row }">
                    <div class="table-actions">
                      <el-tooltip content="Edit" placement="top">
                        <a
                          href="#!"
                          @click.prevent="onEditFormTemplete(row)"
                          class="table-action table-action-edit"
                          data-toggle="tooltip"
                          data-original-title="編集"
                        >
                          <i
                            class="fas fa-edit text-red"
                            style="font-size: 1.5rem"
                          ></i>
                        </a>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column> -->
              </el-table>

              <!-- 新規追加、編集用のモーダル -->
              <modal v-model:show="modals.templateForm" size="lg" body-classes="p-0">

                  <card
                    type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0"
                  >
                    <div class="col-md-12">
                      <label class="form-control-label">データタイプ</label>
                      <select v-model="FormTemplateData.id" class="w-100 form-control form-control-border-gray">
                        <option disabled value="" >データタイプを選択</option>
                        <option v-for="item in idList"
                          v-bind:value="item.key"
                          v-bind:key="item.key">
                        {{ item.typeName }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-12">
                        <base-input type="text" label="帳票テンプレート名">
                          <template></template>
                          <el-input
                            placeholder="帳票名を入力してください"
                            v-model="FormTemplateData.name"
                          ></el-input>
                        </base-input>
                    </div>
                    <div class="col-md-12">
                      <label class="form-control-label">テンプレートファイル</label>
                      <select v-model="FormTemplateData.filePath" class="w-100 form-control form-control-border-gray">
                        <option disabled value="" >テンプレートファイルを選択</option>
                        <option v-for="item in templateFileList"
                          v-bind:value="item.key"
                          v-bind:key="item.key">
                        {{ item.key }}
                        </option>
                      </select>
                    </div>

                    <div class="col-md-12" v-if="false">
                      <div v-if="FormTemplateData.fileName">
                        <div class="row">
                          <div class="col-md-9">
                            {{FormTemplateData.fileName}}
                          </div>

                          <div class="col-md-3">

                            <base-button
                              icon
                              outline
                              type="danger"
                              @click="FormTemplateData.fileName = '';FormTemplateData.filePath = ''"
                            >
                              <span class="btn-inner--icon"
                                ><i class="fa fa-trash"></i
                              ></span>
                              <span class="btn-inner--text">削除</span>

                            </base-button>
                          </div>
                        </div>
                      </div>
                      <base-input v-if="!FormTemplateData.fileName" type="text" label="帳票テンプレートファイル">
                        <template></template>
                        <el-upload
                        class="upload-demo m-1"
                        style="text-align:left;"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :on-change="handleOnChange"
                        :on-remove="handleRemove"
                        :file-list="uploadFileList"
                        :multiple="false"
                        :limit="1"
                        :on-exceed="handleExceed"
                        :auto-upload="false"
                        list-type="text"
                      >
                          <el-button size="small" type="primary" class="d-flex justify-content-start">帳票テンプレートファイルを選択</el-button>
                          <div slot="tip" class="el-upload__tip">※xlsx形式のファイルのみテンプレートに使用可能です。</div>
                        </el-upload>
                      </base-input>

                    </div>
                    <div class="col-md-12">

                      <base-input type="text" label="並び順">
                        <template></template>
                        <el-input-number
                          v-model="FormTemplateData.order"
                          :min="0"
                          :max="100"
                        ></el-input-number>
                      </base-input>
                    </div>
                    <div class="col-md-12">
                      <base-input type="text" label="メモ">
                      <template></template>
                        <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="帳票に関する概要などを入力して下さい"
                        v-model="FormTemplateData.memo">
                        </el-input>

                      </base-input>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-4">
                          <base-button
                            block
                            type="secondary"
                            @click="onClickCancel"
                            >キャンセル</base-button
                          >
                        </div>
                        <div class="col-4">
                          <base-button
                            v-if="!addMode"
                            block
                            type="secondary"
                            @click="onClickDelete"
                            >削除</base-button
                          >
                        </div>
                        <div class="col-4">
                          <base-button
                            v-if="addMode"
                            block
                            type="primary"
                            @click="onClickSubmit"
                            >登録</base-button
                          >
                          <base-button
                            v-if="!addMode"
                            block
                            type="primary"
                            @click="onClickSubmit"
                            >更新</base-button
                          >
                        </div>
                      </div>
                    </div>
                  </card>
              </modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  /** このVueだけのスタイル */
</style>
<style >
  /** 全体に影響するスタイル */
</style>
<script>
import { Storage } from 'aws-amplify';
import appLog from "@/appUtils/AppLog"
import FormTemplateModel from "@/appModel/FormTemplate/FormTemplateModel"
// import DateUtil from "@/appUtils/DateUtil"
// import UserInfo from "@/appUtils/UserInfo"
import Modal from "@/components/Modal";
import DateUtil from '../../appUtils/DateUtil';
import * as yup from 'yup';
import { Form, Field } from "vee-validate";
import UserInfo from '../../appUtils/UserInfo';
import mime from 'mime-types';
import path from 'path';
import { useToast } from "vue-toastification";

export default {
  components: {
    Modal,
    // Form,
    // Field,
  },
  data() {
    return {
      userInfo: {},
      FormTemplateData: {
        name: "",
        filePath: "",
        fileName: "",
        order: 0,
        memo: "",
      },
      addMode: true,
      targetModel: {},
      formTemplateDatas: [],
      uploadFileList: [],
      modals: {
        templateForm: false
      },
      templateFileList: [],
      idList: [{
        "key": "1",
        "typeName": "静岡向け出力"
      }, {
        "key": "2",
        "typeName": "三井オリジナル帳票向け出力"
      }, {
        "key": "3",
        "typeName": "国交省向け出力"
      },
      ]

    };
  },
  computed: {
    vuename() {
      return this.$route.name
    }
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    appLog.infoLog(`${this.vuename}`, `Start ${this.vuename}`)
    this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 画面の初期化
     */
    init() {
      console.log("init")

      UserInfo.getUserInfo().then(function(result) {
        this.userInfo = result
      }.bind(this))

      // タイトルをセット
      let navInfo = this.$store.state.navInfo
      navInfo.title = "帳票テンプレート管理"
      navInfo.description = ""
      this.$store.commit("setNavInfo", navInfo)

      this.loadTemplateData().then((result) => {
        console.log(`${result.length}`)
      })

      // S3上のテンプレートファイルリストを取得する
      this.getTemplateFileList().then(function(result){
        this.templateFileList = result
        
      }.bind(this))
    },
    /**
     * 帳票データをDBから読み込み
     */
    async loadTemplateData(){
      const data = await FormTemplateModel.getFormTemplateList()
      this.formTemplateDatas = data.sort((a, b) => {
        console.log(`order: ${a.formTemplate.order} ${b.formTemplate.order}`)
        if (a.formTemplate.order < b.formTemplate.order) return -1;
        if (a.formTemplate.order > b.formTemplate.order) return 1;
        return 0;
        
      });
      return this.formTemplateDatas
    },
    /**
     * アップロード処理のエラーハンドラ
     */
    handleExceed(files, fileList) {
      this.$message.warning({
        duration: 5000,
        message: `アップロード可能なファイルは1つだけです。`});
      // this.$message.warning(`The limit is 10, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
    },
    /**
     * アップロードファイルの変更時のハンドラ
     */
    handleOnChange(file, fileList){
      console.log(fileList[0].name)
      this.uploadFileList = fileList

    },
    /**
     * アップロードファイル削除時のハンドラ
     */
    handleRemove(file, fileList){
      this.uploadFileList = fileList
    },
    /**
     * 帳票フォーマット入力フォームのクリア
     */
    clearTemplateForm() {
      this.FormTemplateData.name = ""
      this.FormTemplateData.filePath = ""
      this.FormTemplateData.order = 0
      this.FormTemplateData.memo = ""

    },
    /**
     * 帳票フォーマットフォームにDBの値をセット
     */
    setTemplateForm() {
      this.clearTemplateForm()
      this.FormTemplateData.id = this.targetModel.formTemplate.id
      this.FormTemplateData.name = this.targetModel.formTemplate.name
      this.FormTemplateData.filePath = this.targetModel.formTemplate.filePath
      this.FormTemplateData.order = this.targetModel.formTemplate.order
      this.FormTemplateData.memo = this.targetModel.formTemplate.memo
      //this.FormTemplateData.fileName = this.FormTemplateData.filePath
    },
    /**
     * DBに帳票フォーマットフォームの値をセット
     */
    applyTemplateForm() {
      this.targetModel.formTemplate.id = this.FormTemplateData.id
      this.targetModel.formTemplate.name = this.FormTemplateData.name
      this.targetModel.formTemplate.filePath = this.FormTemplateData.filePath
      this.targetModel.formTemplate.order = this.FormTemplateData.order
      this.targetModel.formTemplate.memo = this.FormTemplateData.memo
      console.log(JSON.stringify(this.targetModel, null, "\t"))

    },
    /**
     * テンプレートファイルリストを返す
     */
    async getTemplateFileList() {
      let _list = []
      let list = []
      try {
        // const option = {level: "public"}
        const dir = `rebarxlsxtemplate`
        _list = await Storage.list(dir)
        // alert(JSON.stringify(list))
      } catch (e) {
        console.log(`getTemplateFileList Error ${JSON.stringify(e)}`)
        throw e
      }
      list = _list.filter((item) => {
        console.log(`getTemplateFileList ${JSON.stringify(item)}`)
        return item.key.indexOf('.xlsx') >= 0
      })
      // alert(list.length)
      return list
    },
    async putTemplateFile() {
      const userInfo = this.userInfo
      let filePath = null
      if (this.uploadFileList.length > 0) {
        let file = this.uploadFileList[0]
        const contentType = mime.lookup(file)
        let tenantId = userInfo.group
        const fileName = file.name
        filePath = `formTemplete/${fileName}`

        const s3KeyPrefix = `xlsxtemplate/${tenantId}/`
        const option = {level: "public", customPrefix: {public: s3KeyPrefix}, contentType: contentType}
        const s3Key = filePath
        console.log(s3Key)
        await Storage.put(s3Key, file, option)
      }
      return filePath
    },
    /**
     * 帳票フォーマットデータを登録する
     */
    async saveFormTemplate() {

      const userInfo = this.userInfo

      try {

        if (this.addMode) {
          // 追加モード
          // 画面からテンプレートファイルのアップロードは行わない。手動でS3にアップロードされているファイルを選択するのみ。
          // ユーザ毎にテンプレートファイルをアップロードすると管理が煩雑になるので。
          //const filePath = await this.putTemplateFile()
          //this.targetModel.formTemplate.filePath = filePath

          this.targetModel.formTemplate.createDate = DateUtil.getDateTimeString()
          this.targetModel.formTemplate.createUser = userInfo.cognitoId
          await FormTemplateModel.addFormTemplate(this.targetModel)

        } else {
          // 編集モード
          // const filePath = await this.putTemplateFile()
          // if (filePath) {
          //   this.targetModel.formTemplate.filePath = filePath
          // }
          this.targetModel.formTemplate.updateDate = DateUtil.getDateTimeString()
          this.targetModel.formTemplate.updateUser = userInfo.cognitoId
          await FormTemplateModel.updateFormTemplate(this.targetModel)
        }
      } catch (e) {
        console.log(JSON.stringify(e))
        appLog.errLog(this.$route.name, userInfo.cognitoId, `${JSON.stringify(e)}`)
        throw e
      }
    },
    /**
     * 帳票テンプレートファイル削除処理
     */
    async deleteFormTemplate() {
      const userInfo = this.userInfo
      try {
        await FormTemplateModel.deleteFormTemplate(this.targetModel)
      } catch (e) {
        console.log(JSON.stringify(e))
        appLog.errLog(this.$route.name, userInfo.cognitoId, `${JSON.stringify(e)}`)
        throw e
      }
    },
    /**
     * 新規帳票テンプレートボタン押下のハンドラ
     */
    async onClickNewTemplate(){
      this.clearTemplateForm()
      this.targetModel = await FormTemplateModel.getNewData()
      this.setTemplateForm()
      this.addMode = true
      this.modals.templateForm = true
    },
    async onClickFormTemplate(row) {
      this.clearTemplateForm()
      this.targetModel = row
      this.setTemplateForm()
      this.addMode = false
      this.modals.templateForm = true
    },
    validate(){
      let result = true
      // 必須チェック
      // console.log(`filePath:${this.targetModel.formTemplate.filePath} fileList:${this.uploadFileList.length}`)
      // if (!this.targetModel.formTemplate.filePath && this.uploadFileList.length == 0) {
      //   this.showBottomToast(`帳票フォーマットを指定してください。`, 'warning')
      //   result = false
      // }
      if (this.targetModel.formTemplate.name == "") {
        this.showBottomToast(`フォーマット名を指定してください`, 'warning')
        result = false
      }
      
      return result
    },

    /**
     * 帳票テンプレートフォームの登録ボタンハンドラ
     */
    async onClickSubmit() {
      let loader = this.showLoader()

      try {
        this.applyTemplateForm()
        const flg = this.validate()
        if (flg) {
          await this.saveFormTemplate()
          this.modals.templateForm = false
        }
      } finally {
        await this.loadTemplateData()
        this.hideLoader(loader)
      }
    },
    async onClickDelete() {
      let loader = this.showLoader()

      try {
        this.applyTemplateForm()
        
        await this.deleteFormTemplate()
        this.modals.templateForm = false
        
      } finally {
        await this.loadTemplateData()
        this.hideLoader(loader)
      }
    },
    /**
     * 帳票テンプレートフォームの登録ボタンハンドラ
     */
    onClickCancel(){
      this.clearTemplateForm()
      this.modals.templateForm = false
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * 日付文字を別の形式に変更
     */
    dateStringFormat(dateString) {
      return DateUtil.getFormatString(dateString, "YYYY年MM月DD日")
    },
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },

    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
  },
};
</script>
