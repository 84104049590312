<template>
  <div>
    <base-header class="pb-1" type="">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 d-inline-block mb-0">テナント管理</h6>
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
              <route-bread-crumb></route-bread-crumb>
            </nav>
          </div>
        </div>
    </base-header>
    <div class="container-fluid mt-1">
      <div class="card">
        <div class="card-header border-0">
            <div class="row">
                <div class="col-6">
                <h3 class="mb-0">契約一覧</h3>
                </div>
                <div class="col-6 text-right">
                    <base-button icon outline type="primary" @click="onClickNewTenant">
                        <span class="btn-inner--icon" ><i class="fa fa-plus-square"></i></span>
                        <span class="btn-inner--text">新規契約追加</span>
                    </base-button>
                </div>
            </div>
        </div>

        <el-table
        class="table-responsive align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="tenants">
        <el-table-column label="契約者名" min-width="410px" prop="name" sortable >
            <template v-slot="{ row }">
            <!-- <img :src="row.project.image" class="avatar rounded-circle mr-3" /> -->
            <b>
                <a
                    href="#!"
                    @click.prevent="onClickTenant(row)">{{ row.tenant.name }}
                </a>
                </b>
            </template>
        </el-table-column>
        <el-table-column label="契約開始日" min-width="140px" sortable>
          <template v-slot="{ row }">
            <span>{{row.tenant.contractStart}}</span>
          </template>
        </el-table-column>
        <el-table-column label="契約終了日" min-width="140px" sortable>
          <template v-slot="{ row }">
            <span>{{row.tenant.contractEnd}}</span>
          </template>
        </el-table-column>
        
        </el-table>
    </div>
      
    </div>
  </div>
</template>

<style scoped></style>

<script>

import TenantModel from '../../appModel/Tenant/TenantModel'

import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  data() {
    return {
      tenants: []
    };
  },
  watch: {},
  /**
   * コンピュートプロパティ
   */
  computed: {},
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {
      try {
        
        this.tenants = await TenantModel.getTenantList()
        
      } catch (e) {
        console.log(`error ${e.message}`)
        throw e
      }

    },
    onClickNewTenant() {
      // Storeの情報をクリア
      let tenant = this.$store.state.tenant;
      tenant.id = "";
      this.$store.commit("setTenant", tenant);

      this.$router.push({
        name: "TenantEdit",
      });
    },
  },
};
</script>
