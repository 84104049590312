<template>
    
    <div>
        <!-- <base-header class="pb-1" type="">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 d-inline-block mb-0">ミーティング</h6>
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                        
                    </nav>
                </div>
            </div>
        </base-header> -->
        <!-- <base-header class="pb-6" type=""> </base-header> -->
        <meeting-list ref="meetingList" @selectedMeeting="selectedMeeting" @showNewMeeting="showNewMeeting"></meeting-list>
        <modal v-model:show="meetingDetailShow">
            <create-meeting ref="createMeeting" @createCancel="createCancel"></create-meeting>
        </modal>

        <!-- <meeting-client></meeting-client> -->
        <!-- <meeting-calendar></meeting-calendar> -->
        
    </div>
</template>

<script>
import CreateMeeting from "@/appViews/Meeting/CreateMeeting"

import MeetingList from "@/appViews/Meeting/MeetingList"
import Modal from "@/components/Modal";

// import MeetingClient from "@/appViews/Meeting/MeetingClient"

export default {
    components: {
        CreateMeeting,
        MeetingList,
        Modal,
        // MeetingClient
    },
    data() {
        return {
            meetingDetailShow:false,

        }   
    },
    methods : {
        selectedMeeting() {
            //alert(`行選択`)
            //this.meetingDetailShow = true
            this.$refs.createMeeting.setMeeting()
        },
        showNewMeeting() {
            this.meetingDetailShow = true
            this.$refs.createMeeting.initForm()
            
        },
        createCancel() {
            this.meetingDetailShow = false
        }
    }
}
</script>