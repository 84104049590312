<template>
  <div>
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card bg-white border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="row align-items-center">
                <div class="col-sm-12">
                  <h2 class="mb-0 text-center pb-4">メンテナンス中です</h2>
                </div>
              </div>
              <div class="row mt-3 justify-content-center">
                <div class=" col-md-8">
                  <p class="mb-0 text-center pb-4 mainte-message"
                    style="white-space:pre-wrap; word-wrap:break-word;"
                    v-text="message"
                  >
                  </p>
                  <h3 class="mb-0 text-center pb-2">メンテナンス期間</h3>
                  <p class="mb-0 text-center pb-1">開始： {{start}}</p>
                  <p class="mb-0 text-center pb-1">終了： {{end}}</p>
                </div>
              </div>
              <div class="row mt-3 justify-content-center">
                <div class=" col-md-8">
                  <p class="mb-0 text-center pb-4">※メンテナンス期間は延長されることがあります。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.mainte-message {
  white-space: pre-wrap;
}
</style>
<script>

import { Storage } from "aws-amplify";
import appLog from "@/appUtils/AppLog"
import DateUtil from "@/appUtils/DateUtil"

export default {
  /**
   * プロパティ
   */
  props: {
    // /**
    //  * メッセージ
    //  */
    // message: {
    //   type: String,
    //   default: "メンテナンス中です\nしばらくお待ちください。",
    // },
    // /**
    //  * 開始時間
    //  */
    // start: {
    //   type: String,
    //   default: "2021-08-23 18:00:00",
    // },
    // /**
    //  * 終了時間
    //  */
    // end: {
    //   type: String,
    //   default: "2021-08-23 18:30:00",
    // },
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
    appLog.infoLog("Maintenance.vue", '', `start maintenance.`)
    this.init()
  },
  data() {
    return {
      message: '',
      start: '',
      end: '',
    }
  },
  methods: {
    async init() {

      let maintenance = this.$store.state.maintenance;
      console.log(maintenance);

      if (!maintenance.start && !maintenance.end) {
        await this.loadMaintenace();
        maintenance = this.$store.state.maintenance;
      }
      this.message = maintenance.message;

      const format = 'YYYY/MM/DD HH:mm'

      if (maintenance.start) {
        this.start = DateUtil.getFormatString(maintenance.start, format);
      }

      if (maintenance.end) {
        this.end = DateUtil.getFormatString(maintenance.end, format);
      }

      console.log(this.message);
      console.log(this.start);
      console.log(this.end);

    },

    async loadMaintenace() {

      try {
        const result = await Storage.get(`${process.env.VUE_APP_MAINTENANCE_FILE}`, { download: true })
        result.Body.text().then(string => {
          try {
            let maintenance = JSON.parse(string)
            this.$store.commit("setMaintenance", maintenance);
            console.log(this.$store.state.maintenance.message)

            // test
            // maintenance.end = '2021-08-24 18:30:00'

            // 範囲内チェック
            const isMaintenace = DateUtil.isWithinPeriod(maintenance.start, maintenance.end);

            if (!isMaintenace) {
              // 範囲外 -> サインインページへ遷移
              this.$router.push({
                path: `/`
              })
            }

          } catch (e) {
            console.log(e)
            console.log(`メンテナンス情報なし`)
          }
        })
      } catch (e) {
        console.log(e)
        console.log(`メンテナンス情報なし`)
      }
    },
  }
}
</script>
