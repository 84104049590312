<template>
  <div>
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <h2 class="mb-0 text-center pb-4">
            <div logo="./img/logo/MapletFS_Cloud.png">Maplet FS Cloud</div>
          </h2>
          <div class="card border-0 mb-0">
            <div v-if="!isSendVerificationCode" class="card-body px-lg-5 py-lg-5">
              <div class="row align-items-center">
                <div class="col-sm-12">
                  <!--
                  <h2 class="mb-0 text-left pb-4">
                    <div logo="./img/logo/MapletFS_Cloud.png">Maplet FS Cloud</div>
                  </h2>
                   -->
                   <h3 class="col-sm-12 mb-0 pb-4 text-center">パスワードを再設定</h3>
                </div>
              </div>
              <div class="row mt-3 justify-content-center">
              <!-- <div class="row align-items-center"> -->
                <!-- <h3 class="col-sm-12 mb-0 pb-4">パスワードを再設定</h3> -->
                <div class="col-md-8">
                  <base-input
                    alternative
                    label="ログインユーザーIDを入力してください。"
                    >
                    <el-input
                      addon-left-icon="fas fa-user"
                      placeholder="ログインユーザーID"
                      v-model="userId"
                    ></el-input>
                  </base-input>
                </div>
              </div>
              <div class="row mt-3 justify-content-center">
                <div class="col-8">
                  <el-divider></el-divider>
                </div>
              </div>
              <div class="row mt-3 justify-content-center">
                <div class="col-md-4">
                  <base-button
                    icon
                    type="link"
                    class="btn btn-secondary w-100 text-left"
                    style="background-color: transparent"
                    @click="backLogin"
                  >
                    <span class="btn-inner--icon"
                      ><i class="fas fa-arrow-left"></i
                    ></span>
                    <span class="btn-inner--text text-left">ログイン画面に戻る</span>
                  </base-button>
                </div>
                <!-- <div class="col-md-4"></div> -->
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    @click="clickSendVerificationCode"
                  >
                    認証コードを送信する
                  </button>
                </div>
              </div>
            </div>
            <div v-if="isSendVerificationCode" class="card-body px-lg-5 py-lg-5">
              <div class="row align-items-center">
                <div class="col-sm-12">
                   <h3 class="col-sm-12 mb-0 pb-4 text-center">パスワードを再設定</h3>
                </div>
              </div>
              <div class="row mt-3 justify-content-center">
                <div class="col-md-6">
                  <base-input
                    alternative
                    label="認証コード"
                    >
                    <el-input
                      addon-left-icon="fas fa-key"
                      placeholder="認証コード"
                      v-model="verificationCode"
                    ></el-input>
                  </base-input>

                  <base-input
                    alternative
                    label="新しいパスワード"
                    >
                    <el-input
                      addon-left-icon="fas fa-lock"
                      placeholder="新しいパスワード"
                      v-model="newPassword"
                    ></el-input>
                  </base-input>

                  <base-input
                    alternative
                    label="パスワードの確認"
                    >
                    <el-input
                      addon-left-icon="fas fa-lock"
                      placeholder="パスワードの確認"
                      v-model="confirmPassword"
                    ></el-input>
                  </base-input>
                </div>

              </div>
              <div class="row mt-3 justify-content-center">
                <div class="col-8">
                  <el-divider></el-divider>
                </div>
              </div>
              <div class="row mt-3 justify-content-center">
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-secondry w-100"
                    @click="clickCancel"
                  >
                    キャンセル
                  </button>
                </div>
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    @click="changePassword"
                  >
                    パスワードを変更する
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import { Auth } from 'aws-amplify'
import { useToast } from "vue-toastification";

export default {
  components: {

  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  data() {
    return {
      userId: "",
      isSendVerificationCode: false,
      verificationCode: "",
      newPassword: "",
      confirmPassword: ""
    };
  },
  methods: {
    init() {
      console.log('ForgotPassword: init')

      this.userId = "";
      this.isSendVerificationCode = false;
      this.verificationCode = "";
      this.newPassword = "";
      this.confirmPassword = "";

    },

    /**
     * 認証コードを送信します。
     */
    async clickSendVerificationCode() {
      if (!this.userId) {
        this.showBottomToast(`ログインユーザIDを入力してください。`, 'error');
        // test
        // this.isSendVerificationCode = true;
        return;
      }

      try {
        console.log(`SendVerificationCode`)

        const _this = this;
        await Auth.forgotPassword(this.userId)
          .then(data => {
            console.log(data)
            _this.showBottomToast(`認証コードを送信しました。`, 'info');

            // 認証コード入力状態へ
            _this.isSendVerificationCode = true;
            return
          })
          .catch(err => {
            console.log(err)
            throw err
          })

      } catch (e) {
        // appLog.errLog({view: "Signin.vue", message: `signIn fail useId:${this.userId} error ${e.code} ${JSON.stringify(e)}`})
        switch (e.code) {
        case "UserNotFoundException":
          this.showBottomToast(`認証エラー`, 'error')
          break

        case "NotAuthorizedException":
          this.showBottomToast(`認証エラー`, 'error')
          break

        case "CodeDeliveryFailureException":
          this.showBottomToast(`検証コードの配信に失敗しました`, 'warning')
          break
        default:
          this.showBottomToast(`${e.message}}`, 'warning')
          break
        }
        console.log(JSON.stringify(e))
      }
    },

    backLogin() {

      // ログイン画面へ
      this.$router.push({
        name: 'Signin'
      })

      return;
    },

    async changePassword() {

      if (
        !this.verificationCode ||
        !this.newPassword ||
        !this.confirmPassword
      ) {
        // 未入力はエラー
        this.showBottomToast(`未入力の項目があります`, `error`)
        return;
      }

      if (this.newPassword != this.confirmPassword) {
        this.showBottomToast(`パスワードが一致しません`, `error`)
        return;
      }

      try {
        this.loader = this.$loading.show();

        console.log(this.userId)
        console.log(this.verificationCode)
        console.log(this.newPassword)

        const _this = this;
        // let error = new Error();
        await Auth.forgotPasswordSubmit(
          this.userId,
          this.verificationCode,
          this.newPassword)
          .then(data => {
            console.log(data);
            _this.isSendVerificationCode = false;
          }
          ).catch(err => {
            console.log(err)
            throw err
          })

      } catch (e) {
        // appLog.errLog({view: "Signin.vue", message: `signIn fail useId:${this.userId} error ${e.code} ${JSON.stringify(e)}`})
        switch (e.code) {
        case "UserNotFoundException":
          this.showBottomToast(`認証エラー`, 'error')
          break

        case "NotAuthorizedException":
          this.showBottomToast(`認証エラー`, 'error')
          break;
        case "CodeMismatchException":
          this.showBottomToast(`入力した認証コードが異なります`, 'error')
          break;
        case "ExpiredCodeException":
          this.showBottomToast(`認証コードの有効期限が切れています。`, 'warning')
          break;
        case "InvalidPasswordException":
        case "InvalidParameterException":
          this.showBottomToast(`パスワード要件を満たしていません`, 'warning')
          break;
        case "UserLambdaValidationException":
          // 発生理由不明 →PWは更新されているのでいったん成功扱いとしておく
          // this.showBottomToast(`パスワードの変更に失敗しました`, `error`)
          this.isSendVerificationCode = false;
          this.backLogin();
          break;
        default:
          this.showBottomToast(`パスワードの変更に失敗しました`, `error`)
          // this.showBottomToast(`${e.message}}`, 'warning')
          break
        }
        console.log(JSON.stringify(e))
        // this.showBottomToast(`パスワードの変更に失敗しました`, `error`)
        throw e

      } finally {
        this.loader.hide()
        this.loader = null
      }
      // ログイン画面へ
      this.backLogin();
    },

    clickCancel() {
      this.isSendVerificationCode = false;
    },

    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },

    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
  },
};
</script>
