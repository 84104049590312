<template>
  <div>
    <div class="container-fluid m-1 pl-1">
      <div class="card">
        <div class="card-header pl-0 pr-0 pt-2">
          <!-- Title -->
          <h3 class="h3 mb-1">帳票出力</h3>
        </div>

        <div class="row mt-3">
          <span>
            {{numberOfSelect}}件のデータが選択されています。
          </span>
        </div>
        <div class="row mt-3">
          <span>
            出力する帳票を選択して下さい。
          </span>
        </div>
        <div class="row mt-3">
          
          <div class="col-lg-3 col-md-12">
            <h3 class="h3 mb-1">帳票タイプ</h3>
            <el-radio-group v-model="selectedFormId">
              <el-radio class="w-100 m-2" @change="onSelectTemplate" v-for="item in formTemplateDatas" :key="item.formTemplate.id" :label="item.formTemplate.id">{{item.formTemplate.name}}</el-radio>
            </el-radio-group>
            
          </div>
          <div class="col-lg-9 col-md-12">
            <h3 class="h3 mb-1">説明</h3>
            <div v-if="selectTemplateData">
              
              {{ selectTemplateData.formTemplate.memo }}
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <div class="row">
              <div class="col-4">
                <base-button
                  block
                  type="secondary"
                  @click="onClickCancel"
                  >キャンセル</base-button
                >
              </div>
              <div class="col-4">
                
              </div>
              <div class="col-4">
                <base-button
                  block
                  type="primary"
                  @click="onClickMakeForm"
                  >帳票作成</base-button
                >
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<style scoped>
  /** このVueだけのスタイル */
</style>
<style >
  /** 全体に影響するスタイル */
</style>
<script>
import appLog from "@/appUtils/AppLog"
import FormTemplateModel from "@/appModel/FormTemplate/FormTemplateModel"
// import DateUtil from "@/appUtils/DateUtil"
// import UserInfo from "@/appUtils/UserInfo"

export default {
  components: {

  },
  /**
   * プロパティ
   */
  props: {
    /**
     * プロジェクトID
     */
    selectedList: {
      type: Array,
    },
  },
  data() {
    return {
      formTemplateDatas: [],
      formRadioList: [],
      selectTemplateData: null,
      selectedFormId: null
      
    };
  },
  computed: {
    vuename() {
      return this.$route.name
    },
    numberOfSelect() {
      if (this.selectedList) {
        if (this.selectedList.length) {
          return this.selectedList.length
        } else {
          return 0
        }
      }
      return 0
    }
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    appLog.infoLog(`${this.vuename}`, `Start ${this.vuename}`)
    this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    init() {
      console.log("init")
      this.loadTemplateData().then((result) => {
        console.log(`FormOutput: init() ${result.length}`)
      })
    },
    // setupRadioData() {
    //   this.formTemplateDatas.forEach(function(item) {
    //     item.
    //   }.bind(this))
    // },
    /**
     * 帳票データをDBから読み込み
     */
    async loadTemplateData(){
      this.formTemplateDatas = await FormTemplateModel.getFormTemplateList()
      return this.formTemplateDatas
    },
    onSelectTemplate() {
      //alert(this.selectedFormId)
      let selectTemplateDatas = this.formTemplateDatas.filter(function(item) {
        return item.formTemplate.id == this.selectedFormId
      }.bind(this))
      if (selectTemplateDatas.length > 0) {
        this.selectTemplateData = selectTemplateDatas[0]
      }
    },
    onClickCancel() {
      this.$emit("cancel")
    },
    onClickMakeForm(){
      this.$emit("makeForm")
    }
  },
};
</script>
