<template>

  <div class="wrapper">
    <side-bar logo="./img/logo/z@2x.jpg" >
      <template v-slot:links>
        <!-- ダッシュボード -->
        <el-tooltip class="item" :disabled="isExpandSidebar()" effect="dark" content="ダッシュボード" placement="right">
        <sidebar-item
          :link="{
            name: 'ダッシュボード',
            path: '/dashboard',
            icon: 'ni ni-shop text-primary',
          }"
        >
        </sidebar-item>
        </el-tooltip>
        <el-tooltip class="item" :disabled="isExpandSidebar()" effect="dark" content="ユーザ管理" placement="right">
        <sidebar-item
          :link="{
            name: 'ユーザー管理',
            path: '/userlist',
            icon: 'ni ni-single-02 text-primary',
          }"
        >
        </sidebar-item>
        </el-tooltip>
        <el-tooltip class="item" :disabled="isExpandSidebar()" effect="dark" content="プロジェクト" placement="right">
        <sidebar-item
          :link="{
            name: 'プロジェクト',
            path: '/projects',
            icon: 'ni ni-app text-primary',
          }"
        >
        </sidebar-item>
        </el-tooltip>
        <el-tooltip class="item" :disabled="isExpandSidebar()" effect="dark" content="ビデオミーティング" placement="right">
        <sidebar-item
          :link="{
            name: 'ビデオミーティング',
            path: '/meeting',
            icon: 'fa fa-video text-primary',
          }"
        >
        </sidebar-item>
        </el-tooltip>
        <sidebar-item
          v-if="env == 'local'"
          :link="{
            name: '帳票マスタ管理',
            path: '/formmanage',
            icon: 'fa fa-file-contract text-primary',
          }"
        >
        </sidebar-item>
      
        
        <div class="row">
          <div class="col-12 d-flex justify-content-left">
            <el-tooltip class="item" :disabled="isExpandSidebar()" effect="dark" content="通知" placement="right">
            <sidebar-item
              :link="{
                name: '通知',
                path:'/noticeList',
                icon: 'ni ni-bell-55 text-primary'
              }"
            >
            </sidebar-item>
            </el-tooltip>
            <div class="mt-2">
              <span class="badge badge-sm badge-circle badge-floating badge-primary">{{notReadNoticeCount}}</span>
            </div>
          </div>
        </div>

        <!--
        <a  class="nav-item" @click="onClickTodo" tag="li">
          <a  class="nav-link">
            <i class="fa fa-tasks text-primary"></i>
            <span class="nav-link-text">ToDoリスト</span>
          </a>
        </a>
        -->

        <sidebar-item
        v-if="debug"
        :link="{
          name: 'マップテスト',
          path:'/mapTest',
          icon: 'fa fa-hammer text-primary'
          }"
        >

        </sidebar-item>

        <sidebar-item
          v-if="ncsManager"
            :link="{
              name: 'テナント管理',
              path: '/tenantManage',
              icon: 'fa fa-building text-primary',
            }"
          >
          </sidebar-item>
        <sidebar-item
            v-if="debug"
            :link="{
                name: '開発',
                icon: 'fa fa-tools text-primary',
            }"
        >
            <sidebar-item :link="{name: '認証情報', path: '/sample',icon: 'ni ni-map-big text-primary',}"></sidebar-item>
            <sidebar-item :link="{name: 'Storeダンプ', path: '/storedebug', icon: 'ni ni-map-big text-primary',}"></sidebar-item>
            <sidebar-item :link="{name: '実装サンプル', path: '/sample', icon: 'ni ni-map-big text-primary',}"></sidebar-item>
        </sidebar-item>

      </template>

    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType" :appType="appType" @showtodolist="handlerShowTodoList"></dashboard-navbar>

      <div class="router-content" @click="$sidebar.displaySidebar(false)">
          <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>

      <el-drawer title="Todoリスト" v-model="drawer" @close="onCloseTodo" :size="todoSize" :direction="direction" :before-close="handleClose">
          <card>
          <!-- <template v-slot:header>
            <h5 class="h3 mb-0">To doリスト</h5>
          </template> -->
          <todo-list @todoEdit="onTodoEdit" ref="todoList"></todo-list>
        </card>
      </el-drawer>
    </div>

    <!-- <modal v-model:show="noticeEdit">
      <notice-edit ref="noticeEdit" :editNoticeData="noticeData" @close-notice="closeNotice"></notice-edit>
    </modal> -->
    <!-- <modal v-model:show="todoEdit">
      <todo-edit ref="todoEdit" :editTodoData="todoData"></todo-edit>
    </modal> -->
  </div>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import TodoList from '@/appViews/Projects/ToDo/TodoList.vue'
import TodoEdit from '@/appViews/Projects/ToDo/TodoEdit.vue'
// import NoticeEdit from '@/appViews/Notice/NoticeEdit.vue'
import Modal from "@/components/Modal";
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import SideBar from '../../components/MCZSidebarPlugin/SideBar.vue';
import SidebarItem from '../../components/MCZSidebarPlugin/SidebarItem.vue';
import UserInfo from '../../appUtils/UserInfo'
import UserModel from '@/appModel/users/UserModel'
import NoticeModel from "@/appModel/Notice/NoticeModel"

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SideBar,
    TodoList,
    TodoEdit,
    // NoticeEdit,
    Modal,
    SidebarItem,
    editTodoData: null,
    editNoticeData: null,
  },
  
  data() {
    return {
      isTooltip: false,
      env: "dev",
      appType: "mcz",
      ncsManager: false,
      userType: "0",
      debug: false,
      todoEditShow: false,
      noticeEditShow: false,
      notReadNoticeCount: ""
    }
  },
  computed: {
    todoSize() {
      return this.$store.state.todo.size
    },
    drawer() {
      if (this.$store.state.todo.drawer) {
        this.$nextTick(function() {
          if (this.$refs.todoList) {
            this.$refs.todoList.init()
          } else {
            console.log(`todoListなし`)
          }

        }.bind(this))
      }
      return this.$store.state.todo.drawer
    },
    todoEdit() {
      if (this.$store.state.todo.edit) {
        this.$nextTick(function() {
          this.$refs.todoEdit.initTodo()
        })
      }
      return this.$store.state.todo.edit
    },
    // noticeEdit() {
    //   console.log(this.$store.state);
    //   if (this.$store.state.notice.edit == null) {
    //     return false;
    //   }
    //   if (this.$store.state.notice.edit) {
    //     this.$nextTick(function() {
    //       this.$refs.noticeEdit.initNotice()
    //     })
    //   }
    //   return this.$store.state.notice.edit
    // },
    todoData() {
      return this.$store.state.todo.data
    },
    noticeData() {
      return this.$store.state.notice.data
    },
    

  },
  watch: {

  },
  methods: {
    isExpandSidebar() {
      const bodyClass = document.body.getAttribute("class")
      if (!bodyClass) {
        return false
      }
      if (bodyClass.indexOf("g-sidenav-show") > 0) {
        // サイドバー表示中
        return true
      } else {
        // サイドバー非表示
        return false
      }
    },
    async init() {
      const loginUser = await UserInfo.getUserInfo()
      this.ncsManager = loginUser.group == "ncsmanager"

      console.log(`this.ncsManager:${this.ncsManager}`)

      this.env = process.env.NODE_ENV
    },
    onCloseTodo(){
      let todoStore = this.$store.state.todo
      todoStore.drawer = false
      this.$store.commit('setTodo', todoStore)
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    onTodoEdit(param) {
      let todoStore = this.$store.state.todo
      todoStore.drawer = false
      this.$store.commit('setTodo', todoStore)
      this.editTodoData = param
      this.todoEditShow = true
      this.$nextTick(function() {
        this.$refs.todoEdit.initTodo()
      }.bind(this))
    },
    // onNoticeEdit(param) {
    //   let noticeStore = this.$store.state.notice
    //   noticeStore.drawer = false
    //   this.$store.commit('setNotice', noticeStore)
    //   this.editNoticeData = param
    //   this.noticeEditShow = true
    //   this.$nextTick(function() {
    //     this.$refs.noticeEdit.initTodo()
    //   }.bind(this))
    // },
    handlerShowTodoList() {
      this.onClickTodo()
    },
    onClickTodo() {

      let todoStore = this.$store.state.todo
      todoStore.drawer = !todoStore.drawer
      this.$store.commit('setTodo', todoStore)
    },
    onEndTodo() {

      this.todoEditShow = false

    },
    async getNotReadNoticeCount() {
      this.notReadNoticeCount = "";
      let count = await NoticeModel.getNotReadNoticeCount();

      if (count > 0) {
        this.notReadNoticeCount = count;
      }
    },
  },
  mounted() {
    this.init()
    this.initScrollbar();
    this.getNotReadNoticeCount();
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
    console.log('beforeCreate');
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    console.log('created');
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
    console.log('beforeMount');
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
    console.log('beforeUpdate');
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
    console.log('updated');

    // 現在のパスを取得
    const path = this.$route.path;
    console.log(path)

    if (path == '/noticeList') {
      // 通知を表示した際は未読がすべて既読になるのでクリア
      this.notReadNoticeCount = '';
    }
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
    console.log('beforeUnmount');
  },
  unmounted() {
    //Vue インスタンスが破壊された後
    console.log('unmounted');
  },
};
</script>
<style lang="scss"></style>
