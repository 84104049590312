<template>
    <splitpanes horizontal class="default-theme project-split-parent" >
      <pane :size=5>
        <div class="row ">
          <div class="col-12">
            <base-button size="sm" class="btn-white btn-icon-only" @click="handlerLeftPainToggle">
              <span class="btn-inner--icon"><i :class="pane1 > 0 ? `fa fa-chevron-left` : `fa fa-chevron-right`"></i
              ></span>
            </base-button>

            <!-- <base-dropdown size="sm"
              style="z-index:1000;"
              :hasToggle="false"
              title-classes="btn btn-white btn-sm"
              menuClasses="zindex-dropdown">
              <template v-slot:title>
                <span class="btn-inner--icon"
                ><i class="fa fa-grip-lines-vertical"></i
              ></span>
              </template>

              <a class="dropdown-item" @click="handlerLeftPainToggle">
                {{pane1==0 ? `ツリーパネルを開く` : `ツリーパネルを隠す`}}</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" @click="handlerCenterPainToggle">
                {{pane2==0 ? `中央パネルを開く` : `中央パネルを隠す`}}</a>

              <div class="dropdown-divider" v-if="showRightPanel"></div>
              <a class="dropdown-item" @click="handlerRightPainToggle" v-if="showRightPanel">
                {{(pane1 + pane2 == 100) ? `右パネルを開く` : `右パネルを隠す`}}</a>
            </base-dropdown> -->
          </div>
        </div>
        <!-- <span>a</span> -->
      </pane>
      <pane :size=95>
        <splitpanes :push-other-panes="false" @resize="panelResized">
          <pane v-model:size="pane1">
            <!-- ツリー表示 -->
              <tree-list :treeData="treeData" :defaultProps="defaultProps" @clicked-node="onNodeSelect" @transfer="transferProc" :project="project" :projectId="projectId"></tree-list>
          </pane>
          <pane v-model:size="pane2" style="overflow:hidden;position:relative;">
            <!-- <button @click="openDrawerMenu">検査箇所表示</button> -->
            <!-- 検査マスタ一覧 -->
            <inspect-master-list v-if="inspectMasterListArgs" @transfer="transferProc" :transferArgs="inspectMasterListArgs" :projectId="projectId"></inspect-master-list>
            <!-- 検査マスタ編集 -->
            <inspect-master-edit v-if="inspectMasterEditArgs" @transfer="transferProc" :transferArgs="inspectMasterEditArgs" :projectId="projectId"></inspect-master-edit>
            <!-- ファイルリスト表示 -->
            <file-list-view v-if="fileType===0 || fileListArgs!=null" :dirList="dirList" @clicked-file="onFileSelect" @transfer="transferProc" :transferArgs="fileListArgs"></file-list-view>
            <!-- 背景図表示 -->
            <leaflet-map v-if="fileType===1 || fileType===2" :projectId="projectId" ref="map" :nodeId="nodeId" :fileType="fileType" @transfer="transferProc" :transferArgs="mapArgs"></leaflet-map>
            <!-- 地図表示 -->
            <!-- <leaflet-map v-if="fileType===2" :projectId="projectId" ref="map" :nodeId="nodeId" :fileType="fileType" @transfer="transferProc" :transferArgs="mapArgs"></leaflet-map> -->
            <!-- 地図表示（仮に表示、leaflet-mapコンポーネント一つだけで行えるようにする。） -->
            <!-- <leaflet-map-tile v-if="fileType===2 || mapTileArgs!=null" :projectId="projectId" ref="mapTile" :nodeId="nodeId" @transfer="transferProc" :transferArgs="mapTileArgs"></leaflet-map-tile> -->
            <!-- 検査箇所表示(ドロワースタイルで表示させる) -->
            <transition name="right">
              <div v-if="drawerFlg" style="overflow-y:scroll;overflow-x:hidden;" class="drawer-menu-wrapper p-2">
                <inspect-place @transfer="transferProc" :editPlaceId="editPlaceId" :projectId="projectId" :nodeId="nodeId" :addPlaceData="addPlaceData"></inspect-place>
              </div>
            </transition>
            <!-- 検査箇所一覧表示 -->
            <inspect-place-list v-if="fileType===4" :projectId="projectId" :nodeId="nodeId" :nodeType="nodeType" @transfer="transferProc"></inspect-place-list>
          </pane>

          <pane v-if="fileInfo" :size="fileInfo == null ? 0 : 100 - pane1 - pane2" >
            <file-preview v-if="fileInfo" :fileInfo="fileInfo" :tenantId="tenandId" :projectId="projectId" :nodeId="nodeId" :fileAttributes="fileAttributes" @transfer="transferProc" :transferArgs="rightArgs"></file-preview>

            <!-- <inspect-place v-if="inspectPlaceArgs" @transfer="transferProc" :transferArgs="inspectPlaceArgs" ></inspect-place> -->
          </pane>
          <!-- <pane :size="inspectPlaceArgs == null ? 0 : 100 - pane1 - pane2" v-if="inspectPlaceArgs">

          </pane> -->
        </splitpanes>
      </pane>
    </splitpanes>

</template>
<style scoped>
    .project-split-parent{
        height: 100%;
    }
  .splitpanes.default-theme .splitpanes__pane{
    background-color: white!important;
  }
</style>
<style>
.right-enter-active,
.right-leave-active {
  transform: translate(0px, 0px);
  transition: transform 200ms cubic-bezier(.29,.7,.63,1.02) 0ms;

}
.right-enter,
.right-leave-to {
  transform: translateX(100%) translateX(0px);
  transition: transform 400ms cubic-bezier(.29,.7,.63,1.02) 0ms;
}

.left-enter-active, .left-leave-active {
  transform: translate(0px, 0px);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.left-enter, .left-leave-to {
  transform: translateX(-100vw) translateX(0px);
}
.drawer-menu-wrapper {
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background-color: rgba(250,250,250,1);
  border-left:1px solid rgba(150,150,150,0.9);
  box-shadow: 0 -10px 25px 0 rgba(0, 0, 0, .5);

}
.drawer-menu {
  padding: 24px;
}
</style>

<script>
import TreeList from "./TreeList";
import FilePreview from "./FilePreview";
import FileListView from "./FileListView";
import { Splitpanes, Pane } from 'splitpanes';
import LeafletMap from "@/appViews/Map/Map.vue";
import LeafletMapTile from "@/appViews/Map/MapTile.vue";
import InspectMasterList from "@/appViews/InspectMaster/InspectMasterList.vue"
import InspectMasterEdit from "@/appViews/InspectMaster/InspectMasterEdit.vue"
import InspectPlace from "@/appViews/InspectPlace/InspectPlace.vue"
import InspectPlaceList from "@/appViews/InspectPlace/InspectPlaceList.vue"

export default {
  name: "split-file-view",
  components: {
    TreeList,
    FilePreview,
    FileListView,
    Splitpanes,
    Pane,
    LeafletMap,
    LeafletMapTile,
    InspectMasterList,
    InspectMasterEdit,
    InspectPlace,
    InspectPlaceList
  },
  props: {
    /**
     * ツリーに表示するデータ
     */
    treeData: Array,
    /**
     * TODO:不要なら消す
     */
    defaultProps: Object,
    /**
     * プロジェクトID
     */
    projectId: String,
    /**
     * プロジェクト
     */
    project: Object
  },
  mounted() {
    this.init()
  },
  data() {
    return {
      // カレントディレクトリのパンくず表示用不要か？
      dirList: [],
      tenandId: "",
      // fileList:[],
      fileInfo: null,
      fileAttributes: [],
      pane1: 20,
      pane2: 80,
      fileType: 3,
      mapTitle: "",
      nodeData: {},
      showRightPanel: false,
      // projectId: "",
      nodeId: "",
      nodeType: "",
      fileListArgs: null,
      mapArgs: null,
      mapTileArgs: null,
      inspectMasterEditArgs: null,
      inspectMasterListArgs: {},
      inspectPlaceArgs: null,
      drawerFlg: false,
      placeId: null,
      addPlaceData: null,
      editPlaceId: null,
    }
  },
  methods: {
    init() {

    },
    openDrawerMenu() {
      this.drawerFlg = !this.drawerFlg;
    },
    transferProc(params) {
      // {sendTo:[router.pushに渡すJSON],key:string(ex:inspectMasterListArgs),values:{}}

      console.log(`transferProc:${JSON.stringify(params, null, "\t")}`)

      if (params.sendTo) {
        // ルーティング先が指定されている場合
        this.clearSelectInfo()
        this.$router.push(params.sendTo)
        return
      } else {
        //this.clearSelectInfo()
        if (params) {

          switch (params.key) {
          case "inspectPlaceArgs":
            this.editPlaceId = null
            this.addPlaceData = null
            if (params.newPlaceData){
              this.mapArgs = {}
              //this.placeId = params.newPlaceData.id
              this.addPlaceData = params.newPlaceData.dbData
              this.drawerFlg = !this.drawerFlg;
              console.log(`inspectPlaceArgs open ${JSON.stringify(this.addPlaceData)}`)
            } else if (params.selectPlaceData) {
              this.mapArgs = {}
              this.editPlaceId = params.selectPlaceData.id
              this.drawerFlg = !this.drawerFlg;
              console.log(`inspectPlaceArgs open ${params.selectPlaceData.id}`)
            } else if (params.values) {
              this.mapArgs = {}
              this.editPlaceId = params.values.id
              this.drawerFlg = !this.drawerFlg;
              console.log(`inspectPlaceArgs open ${params.values.id}`)
            }

            break
          case "inspectPlaceAddComplete":
            this.mapArgs = {}
            this.drawerFlg = false
            this.addPlaceData = null

            this.$refs.map.addPoi(params.values)
            break
          case "inspectPlaceEditComplete":
            this.mapArgs = {}
            this.drawerFlg = false
            this.addPlaceData = null

            this.$refs.map.addPoi(params.values)
            break
          case "inspectPlaceCancel":
            this.mapArgs = {}
            this.drawerFlg = false
            this.addPlaceData = null
            break
          case "inspectPlaceListArgs":
            // ノードID
            this.clearSelectInfo()
            // UI更新処理が走ってから検査箇所一覧を表示
            this.$nextTick(function() {
              this.nodeData = params.values
              console.log(JSON.stringify(this.nodeData, null, "\t"))
              this.nodeId = this.nodeData.id
              this.nodeType = this.nodeData.type
              this.mapArgs = {}
              this.drawerFlg = false
              this.addPlaceData = null
              this.fileType = 4;
            }.bind(this))

            break
          case "mapArgs":
            // 検査箇所一覧⇒Map
            this.clearSelectInfo()
            // UI更新処理が走ってからMapを表示
            this.$nextTick(function() {
              this.dirList = null

              this.mapArgs = params.values;

              // ノードID
              this.nodeId = params.values.nodeId;
              const nodeType = params.values.nodeType;
              switch (nodeType) {
              case "backimage":
                this.showRightPanel = true
                this.pane1 = 20
                this.pane2 = 80
                this.inspectPlaceArgs = {}
                this.fileType=1
                break
              case "map":
                this.fileType=2
                break
              }
            }.bind(this))
            break
          default:
            this.clearSelectInfo()
            console.log(`transferProc ${JSON.stringify(params)}`)
            this[params.key] = params.values

            console.log(`${params.key}`)
            break

          }
        }
      }
    },
    clearSelectInfo() {
      this.fileType = -1
      this.fileListArgs = null
      this.mapArgs = null
      this.mapTileArgs = null
      this.inspectMasterEditArgs = null
      this.inspectMasterListArgs = null
      this.inspectPlaceArgs = null
      this.showRightPanel = false
      this.drawerFlg = false
      this.placeId = null
    },
    onNodeSelect(data) {
      // プロジェクトID
      this.project
      // const currentProject = this.$store.state.project;
      // this.projectId = currentProject.project.id
      // ノードID
      this.nodeData = data
      console.log(JSON.stringify(this.nodeData, null, "\t"))
      this.nodeId = this.nodeData.id

      this.clearSelectInfo()

      // const _this = this
      console.log(data.type)
      this.$nextTick(function() {
        switch (data.type) {
        case "folder":
          this.fileType=0
          // this.fileList = data.fileList
          this.dirList = data.dirList
          break
        case "backimage":
          this.fileType=1
          //this.mapTitle = data.label
          // this.fileList = null
          this.dirList = null
          this.showRightPanel = true
          this.pane1 = 20
          this.pane2 = 80
          this.inspectPlaceArgs = {}
          // TODO: 動作確認用で固定値
          // this.placeId = "kruaXG7bbCGVWowrDviKWW"
          break
        case "map":
          // TODO:地図表示する
          this.fileType=2
          this.dirList = null
          break
        case "nobackimage":
          this.nodeId = this.nodeData.id
          this.nodeType = this.nodeData.type
          this.mapArgs = {}
          this.drawerFlg = false
          this.addPlaceData = null
          this.fileType = 4
          this.dirList = null
          this.showRightPanel = true
          this.pane1 = 20
          this.pane2 = 80
          this.inspectPlaceArgs = {}
          break
        case "project":
          // this.fileType = 3
          // this.dirList = null
          // this.inspectMasterListArgs = {}
          break
        case "inspectMaster":
          this.fileType = 3
          this.dirList = null
          this.inspectMasterListArgs = {}
          break
        default:
          this.fileType=-1
          break
        }
      }.bind(this))
    },
    onFileSelect(data) {
      if (this.pane1 + this.pane2 > 95) {
        this.pane2 = 100 - this.pane1 - 20 <= 0 ? (100 - this.pane1) / 2 : 100 - this.pane1 - 20
      }
      this.showRightPanel = true

      this.fileInfo = {
        title: data.title,
      }
      this.fileAttributes = [
        {name: "type", value: data.type},
        {name: "size", value: data.size},
        {name: "createDate", value: data.createDate}
      ]

    },
    handlerLeftPainToggle() {
      console.log(`handlerLeftPainToggle`)
      this.pane1 = this.pane1 == 0 ? 20 : 0
      this.pane2 = this.fileInfo ? this.pane2 : 100
    },
    handlerCenterPainToggle() {
      this.pane2 = this.pane2 == 0 ? 80 : 0
    },
    handlerRightPainToggle() {
      if (this.pane1 + this.pane2 == 100) {
        this.pane2 = 60
      } else {
        this.pane1 = 20
        this.pane2 = 80
      }
    },
    panelResized() {
      console.log(`panelResized`)
      if (this.$refs.mapTile) {
        if (this.$refs.mapTile.parentPanelResized) {
          this.$refs.mapTile.parentPanelResized()
        }

      }
      if (this.$refs.map) {
        if (this.$refs.map.parentPanelResized) {
          this.$refs.map.parentPanelResized()
        }
      }
    }
  }
//   methods: {
//     getBreadName(route) {
//       return route.name;
//     },
//   },
};
</script>

<style scoped></style>
