<template>

    <div class="container-fluid mt-1">
        <div class="card mb-4">
            <!-- Card header -->
            <div class="card-header">
                <h3 class="mb-0">新規ミーティングを作成する</h3>
            </div>
            <!-- Card body -->
            <div class="card-body">
                <!-- Form groups used in grid -->
                <div class="row">
                    <div class="col-md-12">
                        <!-- <base-input
                            label="タイトル"
                            id="meetingtitle-input"
                            name="title"
                        /> -->
                        <base-input type="text" label="タイトル">
                          <template></template>
                          <el-input
                            placeholder="ミーティングのタイトルを入力"
                            v-model="title"
                          ></el-input>
                        </base-input>
                    </div>
                    <div class="col-md-12">
                        <!-- <base-input
                            label="ユーザネーム"
                            id="userName-input"
                            name="userName"
                        /> -->
                        <base-input type="text" label="ユーザーネーム">
                          <template></template>
                          <el-input
                            placeholder="ミーティング中に表示する名前"
                            v-model="userName"
                          ></el-input>
                        </base-input>
                    </div>
                    <div class="col-md-12">
                        <base-input name="selectmultiple" label="ミーティングに招待するユーザ">
                          <el-select
                            v-model="selectedUsers"
                            multiple
                            filterable
                            placeholder="ユーザを選択して下さい（複数選択可）"
                          >
                            <el-option
                              v-for="user in users"
                              :key="user.user.userId"
                              :label="user.user.name"
                              :value="user.user.userId"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                    </div>
                    
                </div>
                <!-- <div class="text-center">
                  <base-button type="primary" @click="createMeeting" native-type="button" class="my-4">ミーティングを作成</base-button>
                  
                </div> -->
                <div class="row mt-4">
                  <div class="col-6">
                    <base-button
                      block
                      type="secondary"
                      @click="onClickCancel"
                      >キャンセル</base-button
                    >
                  </div>
                  <div class="col-6">
                    <!-- <base-button
                      block
                      type="primary"
                      @click="onClickSave"
                      >保存</base-button
                    > -->
                    <!-- <base-button type="primary" block @click="createMeeting" native-type="button" >作成</base-button> -->
                    <base-button type="primary" block @click="createMeetingAndAttendee" native-type="button" >作成</base-button>
                    
                  </div>
                </div>
                <!-- <span>{{debug}}</span> -->
            </div>
        </div>
        <div class="card mb-4" v-show="false">
            <!-- Card header -->
            <div class="card-header">
                <h3 class="mb-0">ミーティングを削除</h3>
            </div>
            <!-- Card body -->
            <div class="card-body">
                <!-- Form groups used in grid -->
                <div class="row">
                    <div class="col-md-12">
                        <base-input
                            name="deleteMeetingId"
                            label="ミーティングID"
                            id="deleteMeetingId-input"
                        />
                    </div>
                </div>
                <div class="text-center">
                  <base-button type="primary" @click="deleteMeeting" native-type="button" class="my-4">ミーティングを削除</base-button>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>

import { API, Auth } from "aws-amplify";
import MeetingModel from "../../appModel/meeting/MeetingModel.js"
import UserInfo from "../../appUtils/UserInfo"
import DateUtil from "@/appUtils/DateUtil"
import UserModel from "@/appModel/users/UserModel";


import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
} from 'amazon-chime-sdk-js';


export default {
  components: {
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
    if (this.onUpdateMapletFSCloudData){
      this.onUpdateMapletFSCloudData.unsubscribe()
      this.onUpdateMapletFSCloudData = null
    }
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  data() {
    return {
      title: "",
      userName: "",
      meetingDateTime: "",
      meeting: {},
      attendee: {},
      debug: "",
      loginUserName: "",
      loader: null,
      users: [],
      selectedUsers: [],
      onUpdateMapletFSCloudData: null,
      waitTime: 0,
      loadingProgressMessage: "",
    }
  },

  methods: {
    init() {
      // ユーザ取得
      this.getProjectUsers()
    },
    async getProjectUsers() {
      this.users = await UserModel.getUserList();
    },
    showLoading() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 指定されたミーティングルームへ参加する
     */
    setMeeting() {
      try {
        this.joinMeeting().then(function () {
          console.log(`参加完了`)
        }.bind(this)).catch(function(e){
          console.log(`参加失敗 ${e}`)
        })
      } catch (e) {
        console.log(`e`)
      }
    },
    /**
     * ミーティングの新規作成画面の初期化
     */
    async initForm() {
      // alert('initForm')
      let storeMeetingData = this.$store.state.meeting
      storeMeetingData = {
        meeting: {},
        attendee: {},
        meetingSession: {}
      }
      this.$store.commit('setMeeting', storeMeetingData)
      
      this.selectedUsers = []

      // 仮タイトル
      let title = `${DateUtil.dateStringDate(new Date())}のミーティング`
      this.title = title
      let result = await Auth.currentUserInfo()
      
      this.loginUserName = result.username
      this.userName = result.username
        
    },
    /**
     * ミーティング作成画面でキャンセル押下時
     */
    onClickCancel() {
      this.$emit("createCancel")
    },
    
    /**
     * ミーティングIDを新規取得してミーティングへ参加する
     */
    async createMeetingAndAttendee() {
      try {
        // ローディングを表示
        this.loader = this.showLoading()
        
        // ログインユーザ情報を取得
        let userinfo = await UserInfo.getUserInfo()
        if (!userinfo.group) {
          return
        }
        // ミーティング管理DBの登録
        let data = await MeetingModel.getNewData(userinfo.sub)        
        data.meeting.title = this.title
        data.meeting.startDateTime = this.meetingDateTime
        data.meeting.invitedUsers = this.selectedUsers
        // DB登録
        await MeetingModel.addMeeting(data)
        window.open(`./#/meetingclient?sk=${encodeURIComponent(data.sk)}`, '_blank')
        // this.$router.push({
        //   path: `/meetingclient`,
        //   query: { sk: `${data.sk}` },
        // });
               
        // ChimeミーティングIDの取得
        await this.getMeetingId(data.pk, data.sk)
        
      } catch (e) {
        console.log(`${JSON.stringify(e)}`)
        this.loader.hide()
        throw e
      }
    },
    
  }
}
</script>
