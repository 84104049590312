<template>
  <div>
    <card>
      <div class="row mt-1">
        <div class="col-12">
          <!--
          <div v-if="uploadFileList.length > 0">
            <small>未アップロードファイル  {{uploadFileList.length}}ファイル</small>
          </div>
           -->
          <el-upload
            class="upload-demo m-1"
            style="text-align:left;"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-change="handleOnChange"
            :on-remove="handleRemove"
            :file-list="uploadFileList"
            :multiple="true"
            :limit="10"
            :on-exceed="handleExceed"
            :auto-upload="false"
            list-type="text"
          >
            <el-button size="small" type="primary" class="d-flex justify-content-start">ファイルを選択</el-button>
            <div slot="tip" class="el-upload__tip">1ファイル100MB以下で10ファイルまで指定できます。</div>

          </el-upload>
          <div class="row">
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <base-button
                block
                type="secondary"
                @click="onClickCancel"
                >キャンセル</base-button
              >
            </div>
            <div class="col-6">
              <base-button
                block
                type="primary"
                @click="onClickUpload"
                >アップロード</base-button
              >
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>
<style>
.el-upload-list__item.is-ready {
  width: 100% !important;
  height: 100% !important;
}
</style>
<script>
import { Storage } from 'aws-amplify';
import FileModel from "../../../appModel/File/FileModel";
import UserInfo from '../../../appUtils/UserInfo';

export default {
  components: {

  },
  props: {

  },
  data() {
    return {
      tenantId: "",
      uploadFileList: [],
      sizeOverFiles: [],
      loader: null,
    }
  },
  /** ライフサクルメソッド */
  beforeCreate(){
    //インスタンスは生成されたがデータが初期化される前
  },
  created(){
    //インスタンスが生成され､且つデータが初期化された後
    this.init()

    const _this = this
    this.tenantId = this.getTenantId().then(id => {
      _this.tenantId = id
    })
  },
  beforeMount(){
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted(){
    //インスタンスが DOM 要素にマウントされた後
    console.log(`mounted`)

  },
  beforeUpdate(){
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },

  /** コンピューテッドプロパティ */
  computed: {
    currentProject() {
      return this.$store.state.project
    },
    currentNode() {
      return this.$store.state.selectedNode
    },
  },
  methods: {
    /**
     * 初期化処理
     */
    init() {
      console.log(`fileUpload init`)
      console.log(this.currentProject.sk)
      this.uploadFileList = [];
      this.sizeOverFiles = [];
      this.loader = null;
    },
    handleOnChange(file, fileList){
      console.log(file)
      console.log(fileList)

      // サイズオーバーか
      const sizeMb = file.size / 1024 / 1024;
      const isOverSize = sizeMb > 100;

      if (isOverSize) {
        this.$message.warning({
          duration: 5000,
          message: `ファイルサイズが100MBを超えています。
                  ${file.name}  ${Math.round(sizeMb)}MB`});
      }

      let result = fileList.filter((file) => {
        const sizeMb = file.size / 1024 / 1024;
        return sizeMb <= 100;
      });

      this.uploadFileList = result

    },
    handleRemove(file, fileList){
      this.uploadFileList = fileList
    },
    handleExceed(files, fileList) {
      this.$message.warning({
        duration: 5000,
        message: `${files.length} ファイルを選択したので、合計${files.length + fileList.length}ファイルです。
                            1回のアップロードで選択できるのは10ファイルまでです。`});
      // this.$message.warning(`The limit is 10, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
    },
    async onClickCancel() {
      this.uploadFileList = [];
      this.sizeOverFiles = [];
      this.$emit("closeUpload");
    },
    async onClickUpload() {
      try {
        console.log(`start upload`)
        let files = this.uploadFileList
        // let files = this.files

        this.loader = this.$loading.show();

        if (files.length < 1) {
          console.log(`upload対象ファイルなし`)
          return;
        }

        const projectId = this.getProjectId()
        const nodeId = this.getCurrentNodeId()

        if (!this.tenantId || !projectId || !nodeId) {
          alert(`パラメータ不足: ${this.tenantId}/${projectId}/${nodeId}`)
          return
        }

        for (let i = 0; i < files.length; i++) {
          // S3キー名
          const s3Key = `${this.tenantId}/${projectId}/${nodeId}/${files[i].name}`
          // ファイルサイズ
          const size = files[i].size
          const fileName = files[i].name
          const fileBody = files[i]

          try {
            // S3バケットにファイルを保存
            await Storage.put(s3Key, fileBody).then(result => {
              console.log(result)
              // this.refresh()
            }).catch(err => console.log(err));
          } catch (e) {
            alert(`S3アップロード失敗 ${e.message}`)
            throw e
          }

          try {
            // DBにレコード登録
            let dbData = await FileModel.getNewFileData(projectId, nodeId)
            dbData.file.fileName = fileName
            dbData.file.fileSize = size

            FileModel.addFile(dbData)
          } catch (e) {
            alert(`DB更新失敗 ${e.message}`)
            Storage.remove(s3Key)
            throw e
          }
        }

        this.$emit("closeUpload");
        // this.loadFileList()
      } catch (e) {
        console.log(`アップロードエラー ${e}`)
        throw e
      } finally {
        this.loader.hide()
        this.loader = null
      }
    },
    async getTenantId() {
      const loginInfo = await UserInfo.getUserInfo()
      if (!loginInfo.group) {
        return null
      }
      return loginInfo.group
    },
    getProjectId() {
      return this.currentProject.sk.split("#")[1]
    },
    getCurrentNodeId() {
      return this.currentNode.id
    },
  },
}
</script>
