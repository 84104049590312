/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apiMakeThumbnail",
            "endpoint": "https://umb6zka46d.execute-api.ap-northeast-1.amazonaws.com/rebarprod",
            "region": "ap-northeast-1"
        },
        {
            "name": "apiexcelform",
            "endpoint": "https://1jqjzqsg79.execute-api.ap-northeast-1.amazonaws.com/rebarprod",
            "region": "ap-northeast-1"
        },
        {
            "name": "apimeasuredata",
            "endpoint": "https://pcirxsgq0f.execute-api.ap-northeast-1.amazonaws.com/rebarprod",
            "region": "ap-northeast-1"
        },
        {
            "name": "mczattendeeresstapi",
            "endpoint": "https://4udixapire.execute-api.ap-northeast-1.amazonaws.com/rebarprod",
            "region": "ap-northeast-1"
        },
        {
            "name": "mczmeetingrestapi",
            "endpoint": "https://tnn2eavdu5.execute-api.ap-northeast-1.amazonaws.com/rebarprod",
            "region": "ap-northeast-1"
        },
        {
            "name": "mczrebarcsv",
            "endpoint": "https://7d6nemdtn4.execute-api.ap-northeast-1.amazonaws.com/rebarprod",
            "region": "ap-northeast-1"
        },
        {
            "name": "mczsimpleexcelform",
            "endpoint": "https://pr67ob876a.execute-api.ap-northeast-1.amazonaws.com/rebarprod",
            "region": "ap-northeast-1"
        },
        {
            "name": "mczusermanager",
            "endpoint": "https://1nki2cjvzh.execute-api.ap-northeast-1.amazonaws.com/rebarprod",
            "region": "ap-northeast-1"
        },
        {
            "name": "meetingManager",
            "endpoint": "https://dw4s1vk04e.execute-api.ap-northeast-1.amazonaws.com/rebarprod",
            "region": "ap-northeast-1"
        },
        {
            "name": "usermanager",
            "endpoint": "https://rce4nhlv5a.execute-api.ap-northeast-1.amazonaws.com/rebarprod",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://5tugage6vfg5hmab6hxkw45jyy.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-northeast-1:20f39951-9d1a-4be6-97ff-118ed4f81690",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_pSdD8a92I",
    "aws_user_pools_web_client_id": "441ttlahmcfnjjlbfb2s7bfvvn",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "mapletfscloud5a2da1ffc4444b669f2c35d71994ebb6143645-rebarprod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ]
};


export default awsmobile;
