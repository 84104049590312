<template>
  <router-view ></router-view>  
  <VueDragResize ref="wipeArea" v-if="wipe.show" isResizable="false" :isActive="true" :z="1060" :w="wipeSize.width" :h="wipeSize.height" :x="initWipeLeft()" :y="initWipeTop()" v-on:resizing="resize" v-on:dragging="resize" >
      <div class="prepareArea" v-if="!wipe.isReadyMeeting">
        <div class="text-center mt-2 mb-2">ミーティングを準備しています。しばらくお待ち下さい。</div>
      </div>
      <div class="startVideoUi" v-if="isPreviewMode&&wipe.isReadyMeeting">
        <div class="row no-gutters">
          <div class="col-12">
            <video  id="video-preview" ref="video-preview" class="video-preview" />
            <div class="video-preview-ui">
              <div class="d-flex justify-content-center">
                <div class="text-center ml-2 mr-2">
                  <base-button round class="btn-icon-only meeting-btn-default ml-1 mr-0" v-if="!isVideoOff" @click="toggleVideo">
                    <span class="btn-inner--icon"><i class="fas fa-video"></i></span>
                  </base-button>
                  <base-button round class="btn-icon-only meeting-btn-default ml-1 mr-0" v-if="isVideoOff" @click="toggleVideo">
                    <span class="btn-inner--icon"><i class="fas fa-video-slash"></i></span>
                  </base-button>
                  <el-dropdown class="dropdown" trigger="click" @command="onChangeVideoDevice">
                    <span class="btn btn-sm btn-icon-only text-white mic-property-btn">
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item v-for="option in videoInputList" :key="option.text" :command="option.value">{{option.text}}</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <div class="text-center ml-2 mr-2">
                  <base-button round class="btn-icon-only meeting-btn-default mr-0" v-if="!isMute" @click="toggleMic">
                    <span class="btn-inner--icon"><i class="fas fa-microphone "></i></span>
                  </base-button>
                  <base-button round class="btn-icon-only meeting-btn-default mr-0" v-if="isMute" @click="toggleMic">
                    <span class="btn-inner--icon"><i class="fas fa-microphone-slash "></i></span>
                  </base-button>

                  <el-dropdown class="dropdown" trigger="click" @command="onChangeMicDevice">
                    <span class="btn btn-sm btn-icon-only text-white mic-property-btn">
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item v-for="option in audioInputList" :key="option.text" :command="option.value">{{option.text}}</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                  <!-- <base-progress
                    class="progress-xs mb-0"
                    :type="`success`"
                    :value="selfAudioLevel"
                  >
                  </base-progress> -->
                </div>
              </div>  
              <!-- <div class="row">
                <div class="col-6">
                  
                </div>
                <div class="col-6">
                  
                </div>
              </div> -->
            </div>
            <div class="video-preview-userstatus">
              <i class="fas fa-volume-up tile-volume" v-bind:class="{tileVolumeOn: selfAudioLevel }" ></i>
            </div>

            <video v-if="isMeetingStarted" id="wipeVideo" ref="wipeVideo" class="wipe-video" />
            <div class="text-center mt-2 mb-2">ミーティングを開始します</div>
              
              <div class="d-flex justify-content-center mb-3">
                <base-button
                  type="primary"
                  @click="startMeeting"
                  >開始する</base-button
                >
              </div>
              <div class="d-flex justify-content-center">
                <base-button
                  size="sm"
                  @click="leaveMeeting"
                  type="secondary"
                  >キャンセル</base-button
                >
              </div>
          </div>
        </div>
        
      </div>
      <div class="startVideoUi" v-if="isMeetingStarted">
        <div class="row no-gutters">
          <div class="col-12">
            <video id="wipeVideo" ref="wipeVideo" class="wipe-video" />
            <div class="video-preview-ui">
              <div class="d-flex justify-content-center">
                <div class="text-center ml-2 mr-2">
                  <base-button round class="btn-icon-only meeting-btn-default ml-1 mr-0" v-if="!isVideoOff" @click="toggleVideo">
                    <span class="btn-inner--icon"><i class="fas fa-video"></i></span>
                  </base-button>
                  <base-button round class="btn-icon-only meeting-btn-default ml-1 mr-0" v-if="isVideoOff" @click="toggleVideo">
                    <span class="btn-inner--icon"><i class="fas fa-video-slash"></i></span>
                  </base-button>
                  <el-dropdown class="dropdown" trigger="click" @command="onChangeVideoDevice">
                    <span class="btn btn-sm btn-icon-only text-white mic-property-btn">
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item v-for="option in videoInputList" :key="option.text" :command="option.value">{{option.text}}</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <div class="text-center ml-2 mr-2">
                  <base-button round class="btn-icon-only meeting-btn-default mr-0" v-if="!isMute" @click="toggleMic">
                    <span class="btn-inner--icon"><i class="fas fa-microphone "></i></span>
                  </base-button>
                  <base-button round class="btn-icon-only meeting-btn-default mr-0" v-if="isMute" @click="toggleMic">
                    <span class="btn-inner--icon"><i class="fas fa-microphone-slash "></i></span>
                  </base-button>

                  <el-dropdown class="dropdown" trigger="click" @command="onChangeMicDevice">
                    <span class="btn btn-sm btn-icon-only text-white mic-property-btn">
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item v-for="option in audioInputList" :key="option.text" :command="option.value">{{option.text}}</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                  <!-- <base-progress
                    class="progress-xs mb-0"
                    :type="`success`"
                    :value="selfAudioLevel"
                  >
                  </base-progress> -->
                </div>

                <div class="text-center ml-2 mr-2">
                  <base-button round class="btn-icon-only meeting-btn-default mr-0 text-red" @click="leaveMeeting">
                    <span class="btn-inner--icon"><i class="fas fa-power-off"></i></span>
                  </base-button>

                </div>
              </div>  
              <!-- <div class="row">
                <div class="col-6">
                  
                </div>
                <div class="col-6">
                  
                </div>
              </div> -->
            </div>
            <div class="video-preview-userstatus">
              <i class="fas fa-volume-up tile-volume" v-bind:class="{tileVolumeOn: selfAudioLevel }" ></i>
            </div>
          </div>
        </div>
        
      </div>
  </VueDragResize>
  <div v-if="isFullScreenVideo()">

  </div>
  
  <!-- <div class="wipeArea" ref="wipeArea">
  </div> -->
</template>
<style>
.wipeArea{
  position: fixed;
  right:10px;

}
.startVideoUi{
  position: absolute;
  width:100%;
  height:100%;
  background-color: white;
  border-radius: 5px;
}

.video-preview {
  object-fit: contain;
  /* height: 200px;
  width: 200px; */
  /* height: 100%; */
  width: 100%;
  top: 0;
  left: 0;
}
.tileVolumeOn {
  opacity: 1 !important;
}
.wipe-video {
  /* border: 1px solid white; */
  /* width: 100%;
        aspect-ratio: 16 / 9; */
  object-fit: contain;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.video-preview-ui {
  position: inherit;
  width: 100%;
  height: 55px;
  margin-top: -55px;
  
}
.video-preview-userstatus{
  position: inherit;
  width: 100%;
  height: 30px;
  margin-top: -32px;
  margin-left: 5px;
}
.tile-volume{
  z-index:1100;
  /* position:inherit !important; */
  left:20px;
  top:10px;
  color:#E4007F;
  transition-property: opacity;
  transition-duration: 0.1s;
  transition-timing-function : ease-out;
  opacity: 0.4;
}
.vdr {
  overflow: hidden;
  border: 1px solid gray !important;
  border-radius: 10px !important;
}
.vdr.active:before {
  outline: 0px; 
}
.vdr-stick {
  display: none !important;
}
</style>
<script>
import VueDragResize from 'vue3-drag-resize';
import {
  ConsoleLogger,
  DefaultActiveSpeakerPolicy,
  DefaultModality,
  LogLevel,
  DefaultDeviceController,
  DefaultMeetingSession,
  MeetingSessionConfiguration,
  AsyncScheduler,
  DataMessage
} from "amazon-chime-sdk-js";
import MeetingProvider from "@/appUtils/MeetingProvider"
import UserInfo from "@/appUtils/UserInfo";

export default {
  components: {
    VueDragResize
  },
  data() {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      isMute: false,
      isVideoOff: true,
      meetingSession: {},
      wipe: {
        isReadyMeeting: false,
        title: "ミーティングを開催",
        width: 240,
        height: 350,
        show: false,
        isPreviewMode: true,
        isMeetingStarted: false,
      },
      meetingProvider: null,
      audioInputList: [],
      videoInputList: [],
      audioInput: "",
      videoInput: "",
      
    }
  },
  mounted(){
    
  },
  computed: {
    wipeSize() {
      if (this.wipe.isPreviewMode) {
        return {width: 240, height: 350}
      } else if (this.wipe.isMeetingStarted) {
        return {width: 240, height: 180}
      }
      return {width: 240, height: 350}
    },
    
    meetingStatus() {
      if (this.$store.state.meeting.provider) {
        
        return true
      } else {
        return false
      }
    },
    meetingWatch() {
      return this.$store.getters.getMeetingStartFlg
    },
    selfAudioLevel() {
      if (!this.meetingProvider) {
        return 0
      }
      // console.log(`selfAudioLevel:${this.meetingProvider.selfAudioLevel}`)
      return this.meetingProvider.selfAudioLevel
    },
    selfAudioLevelPercent() {
      if (!this.meetingProvider) {
        return 0
      }
      //console.log(`selfAudioLevel:${this.meetingProvider.selfAudioLevel / 100}`)
      const percentValue = this.meetingProvider.selfAudioLevel * 1.5 / 100
      console.log(`selfAudioLevelPercent:${percentValue}`)
      if (percentValue < 0.4) {
        console.log(`selfAudioLevelPercent:0.4未満`)
        return 0.4
      } else {
        console.log(`selfAudioLevelPercent:0.4以上`)
        return percentValue
      }
    },
    isPreviewMode() {
      return this.wipe.isPreviewMode
    },
    isMeetingStarted() {
      return this.wipe.isMeetingStarted
    }
  },
  watch: {
    /**
     * ミーティングの開始を監視
     */
    meetingWatch() {
      console.log(`meetingWatch: ${this.meetingWatch}`)
      this.wipe.show = this.meetingWatch
      if (this.wipe.show) {
        this.$nextTick(function() {
          setTimeout(function(){
            console.log("meetingWatch 500ms")
            this.initMeeting()
          }.bind(this), 500)
        }.bind(this))
      }
      
    }
  },
  methods: {
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    isWipeShow() {
      
      return this.$store.state.meeting.wipe
    },
    initWipeLeft() {
      console.log("initWipeLeft" + (window.innerWidth - this.wipeSize.width))
      return window.innerWidth - (this.wipeSize.width + 20)
    },
    initWipeTop() {
      console.log(" initWipeTop " + (window.innerHeight - this.wipeSize.height))
      return window.innerHeight - (this.wipeSize.height + 20)
    },
    isFullScreenVideo() {
      return this.$store.state.meeting.fullScreen
    },
    async initMeeting() {

      let userInfo = await UserInfo.getUserInfo()
      this.meetingProvider = new MeetingProvider()

      // ミーティング参加準備完了後のデリゲートメソッドを登録
      this.meetingProvider.delegate.didEndMeetingStartProcess = async function(meetingProvider) {
        this.wipe.isReadyMeeting = true
        this.$nextTick(async function(){
          // 別Vueで使いまわしを考えてvuexに格納する（不要なら廃止）
          let meeting = this.$store.state.meeting
          const session = meetingProvider.meetingSession
          meeting.meetingSession = session
          meeting.wipe = true
          meeting.audioInputList = meetingProvider.audioInputList
          meeting.videoInputList = meetingProvider.videoInputList
          meeting.provider = meetingProvider
          this.$store.commit("setMeeting", meeting)

          this.audioInputList = this.meetingProvider.audioInputList
          this.videoInputList = this.meetingProvider.videoInputList
          
          // await this.getPermissions()
          if (this.meetingProvider.videoInputList.length > 0) {
            await this.showPreview(this.meetingProvider.videoInputList[0].value)
          } else {
            await this.showPreview(null);
          }

          // 音声入力ソースをChime SDKにセット
          if (this.audioInputList.length > 0) {
            // alert(`音声あり`)
            await this.meetingProvider.meetingSession.audioVideo.chooseAudioInputDevice(
              this.audioInputList[0].value
            );
          }
          
          // ミーティングを開始する
          // meetingProvider.meetingSession.audioVideo.start();
        }.bind(this))
        
      }.bind(this)
      this.meetingProvider.delegate.didUpdateTiles = this.updateTiles.bind(this)

      await this.meetingProvider.startMyRoom(userInfo.sub)
      
    },

    /**
     * 権限取得
     */
    async getPermissions() {
      try {
        let streamAudio = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        streamAudio.getTracks().forEach((track) => track.stop());
        await this.setupAudioDeviceList();
      } catch (e) {
        //alert('音声入出力無し')
      }
      try {
        let streamVideo = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        streamVideo.getTracks().forEach((track) => track.stop());
        await this.setupVideoDeviceList();
      } catch (e) {
        // alert()
        //alert('ビデオカメラ無し')
        this.videoInputList = [{ text: `なし`, value: `` }]
        this.videoInput = ``

        this.videoQualityList = [{ text: `なし`, value: `` }]
        this.videoQuality = ``
      }

    },
    /**
     * 映像ソース変更
     */
    async changeVideoDevice() {
      const previewElement = this.$refs.videoPreview;
      const deviceId = this.videoInput;
      await this.showPreview(deviceId);
    },
    /**
     * プレビュー表示
     */
    async showPreview(deviceId) {
      try {
        this.meetingProvider.meetingSession.audioVideo.stopVideoPreviewForVideoInput(
          document.getElementById("video-preview")
        );
        console.log(`showPreview deviceId:${deviceId}`)
        if (deviceId) {
          this.isVideoOff = false
          console.log(`showPreview deviceId is exist`)
          await this.meetingProvider.meetingSession.audioVideo.chooseVideoInputDevice(deviceId);
          this.meetingProvider.meetingSession.audioVideo.startVideoPreviewForVideoInput(
            document.getElementById("video-preview")
          );
        } else {
          console.log(`showPreview deviceId is nothing`)
          this.isVideoOff = true
          //await this.meetingProvider.meetingSession.audioVideo.chooseVideoInputDevice(deviceId)
          const blueScreen = DefaultDeviceController.createEmptyVideoDevice()
          await this.meetingProvider.meetingSession.audioVideo.chooseVideoInputDevice(blueScreen)
          this.meetingProvider.meetingSession.audioVideo.startVideoPreviewForVideoInput(
            document.getElementById("video-preview")
          );
        }
      } catch (e) {
        console.log(`showPreview error ${JSON.stringify(e.message, null, "\t")}`)
        throw e
      }
    },
    async onChangeMicDevice(value) {
      console.log(`onChangeMicDevice start  value:${value}`)

      if (value) {
        this.audioInput = value
        await this.meetingProvider.meetingSession.audioVideo.chooseAudioInputDevice(
          this.audioInput
        );
      } else {
        this.isMute = false
        this.toggleMic()
      }

    },
    async onChangeVideoDevice(value) {
      console.log(`onChangeVideoDevice start  value:${value}`)

      if (this.isPreviewMode) {
        await this.showPreview(value)
      } else {
        if (value) {
          this.videoInput = value
          await this.meetingProvider.meetingSession.audioVideo.chooseVideoInputDevice(
            this.videoInput
          );
        } else {

          this.isVideoOff = false
          this.toggleVideo()
        }
      }
      
    },
    toggleMic() {
      if (this.isMute) {
        // ミュート解除
        this.meetingProvider.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
        this.isMute = !this.isMute
      } else {
        // ミュート
        this.meetingProvider.meetingSession.audioVideo.realtimeMuteLocalAudio();
        this.isMute = !this.isMute
      }
    },
    toggleVideo() {
      this.$nextTick(function(){
        if (this.isVideoOff) {
          // ビデオをOFF→ONに切り替え
          try {
            if (this.videoInput) {
              // ビデオ入力有り
              // this.openVideoInputFromSelection(this.videoInput, false);
              this.meetingProvider.meetingSession.audioVideo.startLocalVideoTile();
            }
            this.isVideoOff = false
          }
          catch (err) {
            console.log(`no video input device selected`)
          }
        }
        else {
          this.meetingProvider.meetingSession.audioVideo.stopLocalVideoTile();
          this.isVideoOff = true
        }
      }.bind(this))
    },
    startMeeting() {
      this.wipe.isPreviewMode = false
      this.wipe.isMeetingStarted = true
      this.meetingProvider.meetingSession.audioVideo.startLocalVideoTile();
      this.meetingProvider.meetingSession.audioVideo.start();
    },
    leaveMeeting() {
      try {
        if (this.isPreviewMode) {
          // プレビュー終了
          this.meetingProvider.meetingSession.audioVideo.stopVideoPreviewForVideoInput(
            document.getElementById("video-preview")
          );
        } else {
          // ミーティング終了
          this.meetingProvider.meetingSession.audioVideo.stop();
        }
        this.wipe.show = false
        this.wipe.isReadyMeeting = false
        let meeting = this.$store.state.meeting
        // App.vueのWatchで監視しているプロパティを更新する
        meeting.startFlg = false
        this.$store.commit("setMeeting", meeting)
      } finally {
        // this.$router.push({
        //   name: "MeetingMain",
        // });
        window.close();
      }
    },
    /**
     * タイル状態の更新
     * サムネイル映像、メイン映像の表示に関する処理
     */
    updateTiles() {
      this.$nextTick(function(){
        //alert(`updateTiles`)
        let flg = false;
        console.log(`updateTiles: ${this.meetingProvider.tiles.length}`)
        // Object.keys(this.tiles).forEach(function(id) {
        // this.tiles.forEach(function(tile){
        //   // let tile = this.tiles[id]
        //   let tileId = tile.tileState.tileId;
        //   let videoId = `video${tileId}`;
        //   if (!this.bindElement.includes(videoId)) {
        //     this.bindElement.push(videoId);
        //     let videoElement = this.$refs[videoId];
        //     if (videoElement) {
        //       console.log(`updateTiles:サムネイル tileId:${tileId}`)
        //       this.meetingSession.audioVideo.bindVideoElement(
        //         tileId,
        //         videoElement
        //       );
        //     }
        //   }
        //   let localId = this.localTileId();
        // }.bind(this));

        // // 画面共有コンテンツであれば優先して表示する
        // // Object.keys(this.tiles).forEach(function(id) {
        // this.tiles.forEach(function(tile){
        //   // let tile = this.tiles[id]
        //   console.log(`tile.isContent:: ${tile.tileState.isContent}`);
        //   if (tile.tileState.isContent && !flg) {
        //     flg = true;
        //     let tileId = tile.tileState.tileId;
        //     this.isStartShare = true;
        //     console.log(`updateTiles isStartShare: ${this.isStartShare}`)
        //     const mainElement = this.$refs.mainVideo;
        //     if (mainElement) {
        //       console.log(`updateTiles:画面共有コンテンツ tileId:${tileId}`)
        //       this.meetingSession.audioVideo.bindVideoElement(
        //         tileId,
        //         mainElement
        //       );
        //       this.mainTileId = tileId
        //     }
        //   }
        // }.bind(this));

        // // 他のユーザの画面を表示する
        // // Object.keys(this.tiles).forEach(function(id) {
        // // let tile = this.tiles[id]
        // this.tiles.forEach(function(tile){
        //   let tileId = tile.tileState.tileId;
        //   let localId = this.localTileId();
        //   if (tileId != localId && !flg) {
        //     flg = true;
        //     const mainElement = this.$refs.mainVideo;
        //     if (mainElement) {
        //       this.isStartShare = false;
        //       console.log(`updateTiles:他ユーザ tileId:${tileId}`)
        //       this.meetingSession.audioVideo.bindVideoElement(
        //         tileId,
        //         mainElement
        //       );
        //       this.mainTileId = tileId
        //     }
        //   }
        // }.bind(this));

        // ここまで来てもメイン画面に何も表示されていなければ自身の画面を表示する
        if (!flg) {
          // Object.keys(this.tiles).forEach(function(id) {
          // let tile = this.tiles[id]
          console.log(`updateTiles tiles: ${this.meetingProvider.tiles.length}`)
          this.meetingProvider.tiles.forEach(function(tile){
            let tileId = tile.tileState.tileId;
            let localId = this.meetingProvider.localTileId();
            if (tileId == localId && !flg) {
              flg = true;
              const mainElement = this.$refs.wipeVideo;
              if (mainElement) {
                // this.isStartShare = false;
                // console.log(`updateTiles:自身の画面 tileId:${tileId}`)
                //this.meetingSession.audioVideo.unbindVideoElement(this.mainTileId)
              
                this.meetingProvider.meetingSession.audioVideo.bindVideoElement(
                  tileId,
                  mainElement
                );
                // this.mainTileId = tileId

              }
            }
          }.bind(this));
        }
      }.bind(this))
      

},
  }
};
</script>
