const short = require("short-uuid");
const baseModel = require("../baseModel")

/**
 * 検査結果用のデータ取得モデルクラス
 */
class InspectResultModel extends baseModel {

  /**
   * テナント内の検査結果リストを取得する
   * @param {プロジェクトID} projectId
   * @param {ノードID} nodeId
   * @returns {テナント内の検査結果リスト}
   */
  static async getInspectResultList(projectId, nodeId, placeId) {
    return await super.getList({ beginsWith: this.createSkForList(projectId, nodeId, placeId) });
    // return await super.getList({ beginsWith: "inspectResult" })
  }

  /**
   * 検査結果を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteInspectResult(data) {
    await super.deleteData(data)
  }

  /**
   * 検査結果管理レコードを追加する
   * @param {*} data
   */
  static async addInspectResult(data) {
    await super.addData(data)
  }

  /**
   * 検査結果管理レコードを更新する
   * @param {*} data
   */
  static async updateInspectResult(data) {
    await super.updateData(data)
  }

  /**
   * 検査結果管理レコードを取得する
   * @param {*} sk
   */
  static async getInspectResult(sk) {
    return await super.getSingleData(sk);
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, nodeId, placeId, id) {
    // inspectResult#プロジェクトID#ノードID#箇所ID(uuidで採番)#検査結果ID
    const prefix = `inspectResult`;
    return `${prefix}#${projectId}#${nodeId}#${placeId}#${id}`;
  
  }

  /**
   * 取得用skの文字列を生成
   * @param {プロジェクトID} projectId
   * @param {ノードID} nodeId
   * @returns {取得用skの文字列}
   */
  static createSkForList(projectId, nodeId, placeId) {
    const prefix = `inspectResult`;
    const getSk = `${prefix}#${projectId}#${nodeId}#${placeId}`;
    console.log('getSk:' + getSk);
    return getSk;
  }

  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId, nodeId, placeId) {
    let newRowData = await super.getNewData()

    const id = short.generate()
    newRowData.sk = this.createSk(projectId, nodeId, placeId, id)

    newRowData.inspectResultSet = {
      id: id,
      masterId: "",
      inspectList: [],
      photoList: [],
    }

    return newRowData

  }
}

module.exports = InspectResultModel;
