const {API,graphqlOperation} = require('aws-amplify')
const {createMapletFSCloudData,deleteMapletFSCloudData,updateMapletFSCloudData} = require('@/graphql/mutations')
const {listMapletFSCloudDatas,getMapletFSCloudData} = require('@/graphql/queries')
const short = require('short-uuid');
const baseModel = require("../baseModel")

/**
 * プロジェクト用のデータ取得モデルクラス
 */
class TodoModel extends baseModel {
  /**
   * テナント内のプロジェクトリストを取得する
   * @param {テナント(グループ)}} tenantId 
   * @returns 
   */
  static async getTodoList() {
    return await super.getList({beginsWith:"todo#"})
  }

  /**
   * プロジェクトを削除する
   * @param {pk:テナントID, sk:sk} data 
   * @returns 
   */
  static async deleteTodo(data) {
    await super.deleteData(data)
  }

  /**
   * プロジェクト管理レコードを追加する
   * @param {*} data 
   */
  static async addTodo(data){
    await super.addData(data)
  }

  /**
   * プロジェクト管理レコードを更新する
   * @param {*} data 
   */
  static async updateTodo(data) {
    await super.updateData(data)
  }

  /**
   * プロジェクト管理レコードを取得する
   * @param {*} data 
   */
  static async getTodo(data){
    let result = await API.graphql(graphqlOperation(getMapletFSCloudData,{pk:data.pk,sk:data.sk}))
    return result.data.getMapletFSCloudData
      
  }
    
  /**
   * skの文字列を生成
   * @param {*}  
   * @returns 
   */
  static createSk(){
    return `todo#${short.generate()}`
  }

  /**
   * 新規登録用のスキーマ
   */
  static async getNewData() {
    let newRowData = await super.getNewData()
    
    const id = short.generate()
    newRowData.sk = this.createSk(id)
    
    newRowData.todo = {
      id: id,
      title: "",
      createUser: "",
      targetUser: "",
      content: "",
      scheduledDateTime: "",
      status: "",
      parameters: ""
    }


    return newRowData
  }
  /**
   * 新規登録用のスキーマ
   */
  // static getNewData() {
  //   return {
  //     pk: "",
  //     sk: "",
  //     lsiStr1: "-",
  //     lsiStr2: "-",
  //     lsiStr3: "-",
  //     lsiStr4: "-",
  //     lsiInt1: 0,
  //     groups: [],
  //     todo: {
  //       todoId: "",
  //       title: "",
  //       createUser: "",
  //       targetUser: "",
  //       content: "",
  //       scheduledDateTime: "",
  //       status: "",
  //       parameters: ""
  //     }
  //   }
  // }
}

module.exports = TodoModel