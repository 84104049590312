const { Storage } = require("aws-amplify");
const aws = require("aws-sdk")
const UserInfo = require('./UserInfo');
const DateUtil = require('./DateUtil')
const AppLog = require('./AppLog')
const { Auth } = require('@aws-amplify/auth')
const awsmobile = require("@/aws-exports");
const constants = require("@/Config/constants")

/**
 * テナント毎のプライベートフォルダへアクセスするクラスです
 */
class TenantStorage {

  /**
   * コンストラクタ
   * @param {*} groupId グループID
   */
  constructor(groupId) {
    this.groupId = groupId
    // STSで切り替えるロール
    this.tenantArn = "arn:aws:iam::222792311388:role/amplify-mapletfscloud-dev-163716-tenantStsRole"
    // 取得したトークン期限
    this.duration = 60 * 60
    // ユーザーデータを格納するS3バケット
    this.tenantBucket = awsmobile.default.aws_user_files_s3_bucket
    // 取得したトークンはプロパティに保持。有効期限が切れた場合は再取得を行う
    this.stsResult = null

    if (process.env.VUE_APP_STSDURATION) {
      this.duration = process.env.VUE_APP_STSDURATION
    }
    
    if (process.env.VUE_APP_TENANTARN) {
      this.tenantArn = process.env.VUE_APP_TENANTARN
    }
    this.baseDir = `${constants.default.s3_tenant_prefix}/${this.groupId}/`
    console.log(`baseDir: ${this.baseDir}`)
  }

  /**
   * テナントのベースフォルダ
   * @returns 
   */
  getBaseDir() {
    return this.baseDir
  }
  /**
   * S3 APIをテナントアクセス可能なロールで初期化して取得
   * @returns 
   */
  async createS3Instance() {

    const tenantCredential = await this.getStsCredential()

    aws.config.update({
      credentials: new aws.Credentials({
        accessKeyId: tenantCredential.Credentials.AccessKeyId,
        secretAccessKey: tenantCredential.Credentials.SecretAccessKey,
        sessionToken: tenantCredential.Credentials.SessionToken,
      }),
    })

    return new aws.S3({
      credentials: new aws.Credentials({
        accessKeyId: tenantCredential.Credentials.AccessKeyId,
        secretAccessKey: tenantCredential.Credentials.SecretAccessKey,
        sessionToken: tenantCredential.Credentials.SessionToken,
      }),
      region: 'ap-northeast-1',
    });
  }
  /**
   * キーを指定してオブジェクトを取得します。バイナリデータを返します。
   * @param {*} key バケットルートからのキー(tenants/で始まる)
   * @returns 
   */
  async get(key) {
    try {
      const s3 = await this.createS3Instance()
      console.log("TenantStorage get")
      let params = {
        Bucket: this.tenantBucket, 
        Key: `${key}`
      };
      console.log(`TenantStorage ${JSON.stringify(params)}`)
      let data = await s3.getObject(params).promise()
      
      // imgタグにセットする用のbase64
      data.toBase64 = function() {
        return this.dataBodyToBase64(data)
      }.bind(this)

      return data
    } catch (e) {
      AppLog.errLog("TenantStorage:get", ``, `${JSON.stringify(e)}`)
      throw e
    }
  }
  /**
   * データとキーを指定してバケットにアップロードします
   * @param {*} key バケットルートからのキー
   * @param {*} data アップロードするデータ
   * @returns 
   */
  async put(key, data, option = null) {
    try {
      const s3 = await this.createS3Instance()
      let params = {
        Body: data,
        Bucket: this.tenantBucket, 
        Key: `${key}`
      };

      if (option) {
        for (let key in option){
          params[key] = option[key];
        }
      }
      return await s3.putObject(params).promise()
    } catch (e) {
      AppLog.errLog("TenantStorage:put", ``, `${JSON.stringify(e)}`)
    }
  }
  /**
   * フォルダ（プレフィックス）を指定してオブジェクトリストを取得します
   * @param {*} prefix 取得したいフォルダのキー
   * @returns 
   */
  async list(prefix) {
    try {
      let keyList = []
      let continuationToken = ""
      const s3 = await this.createS3Instance()
      
      let params = {
        Bucket: this.tenantBucket, 
        Prefix: `${prefix}`
      };
      
      let continueFlg = true
      
      while (continueFlg) {
        if (continuationToken) {
          params.ContinuationToken = continuationToken
        }
        let res = await s3.listObjectsV2(params).promise()
        res.Contents.map(v => v.Key).forEach(v => {
          keyList.push(v);
        });

        if (res.IsTruncated) {
          continuationToken = res.NextContinuationToken
        } else {
          continueFlg = false
        }
      }
      return keyList

    } catch (e) {
      AppLog.errLog("TenantStorage:list", ``, `${JSON.stringify(e)}`)
    }
  }
  /**
   * キーを指定してオブジェクトを削除します
   * @param {*} key 
   */
  async delete(key) {
    try {
      const s3 = await this.createS3Instance()

      let params = {
        Bucket: this.tenantBucket, 
        Key: `${key}`
      };

      await s3.deleteObject(params).promise()
    } catch (e) {
      AppLog.errLog("TenantStorage","",`${JSON.stringify(e)}`)
    }
  }

  /**
   * AWS STSでテナントアクセス用のロールにスイッチし、AWS SDKに必要なアクセスTokenを取得する
   */
  async getStsCredential() {
    if (!this.tenantArn) {
      return
    }
    // クレデンシャルがすでにある場合に期限内であるかチェック
    if (this.stsResult) {
      let now = new Date()
      if (DateUtil.isBefore(now, this.stsResult.Credentials.Expiration)) {
        // 期限前であれば再取得不要なので終了
        return this.stsResult
      } else {
        // 期限切れであればクリア
        this.stsResult = null
      }
    }
    
    this.stsResult = null
    const currentCredentials = await Auth.currentCredentials()
    const sessionName = `tenantStsRole-${DateUtil.getUnixTimeStampOfSecond()}`

    let stsParams = {credentials: currentCredentials };

    let sts = new aws.STS(stsParams)
    let params = {
      RoleArn: this.tenantArn, 
      RoleSessionName: sessionName, 
      DurationSeconds: this.duration,
      Tags: [
        {
          Key: 'TenantID', 
          Value: `${this.groupId}` 
        },
      ],
    };
    console.log(`getStsCredential: ${JSON.stringify(params)}`)
    const result = await sts.assumeRole(params).promise()
    AppLog.debugLog("StorageUtil", "", `getTenantCredential: ${JSON.stringify(result)}`)
    this.stsResult = result

    return result
  }
  
  /**
   * S3から取得したオブジェクトデータをimgタグのsrcに対応したフォーマットでBase64化する
   * @param {*} data S3からgetObjectで取得したデータ
   * @returns 
   */
  dataBodyToBase64(data) {
    if (!data) {
      return
    }
    if (!data.Body) {
      return
    }
    return 'data:image/jpg;base64,' + data.Body.toString('base64')
  }
  /**
   * AWS SDKを使用してテナント
   * @param {*} key 
   * @returns 
   */
  async getObjectAwsSDK(key) {
    const s3 = this.createS3Instance()

    let params = {Bucket: awsmobile.aws_user_files_s3_bucket, Key: `${key}`};
    let data = await s3.getObject(params).promise()

    let base64 = 'data:image/jpg;base64,' + data.Body.toString('base64')

    return base64
  }
  static async getTenantFileUrl(s3Key) {
     
    if (!s3Key) {
      return ""
    }

    try {
      const user = await UserInfo.getUserInfo()
      const s3KeyPrefix = `tenant/${user.group}/`
      console.log(`getTenantFile ${s3KeyPrefix}${s3Key}`)
      const url = await this.get(`${s3Key}`, {level: "public", customPrefix: {public: s3KeyPrefix}})
      console.log(`url:: ${url}`)
      return url.toString()
    } catch (e) {
      alert(`error getTenantFile ${JSON.stringify(e, null, "\t")}`)
      throw e
    }
  }
  static async get(key, config) {
    try {
      let imageUrl = await Storage.get(key, config)
      return imageUrl
    } catch (e) {
      this.debugText = `エラー:${JSON.stringify(e)}`
    }
  }
}

module.exports = TenantStorage;