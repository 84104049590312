<template>
  <div class="container-fluid no-gutters">
    <div class="row no-gutters mt-0">
      <div class="col-md-12 col-lg-2">
        <div class="subContent" v-if="true">
          <div class="row">
            <!-- 検索項目 -->
            <div class="row pt-3 pb-2 pl-4 pr-4 text-left">
              <div class="col-lg-12">
                <div class="row pt-1">
                  <div class="col-12">
                    <h4 class="content-title">検索条件</h4>
                  </div>
                </div>
                <div class="row" >
                  <div class="col-sm-12">
                    <base-input
                      type="text"
                      label=""
                    ><template></template>
                      <el-autocomplete
                        class="inline-input w-100"
                        v-model="searchCondition.inspectPlaceNumber"
                        :fetch-suggestions="placeNumberSearch"
                        placeholder="検査箇所Noを入力"
                        @select="handleSelect"
                        clearable
                      ></el-autocomplete>
                    </base-input>
                  </div>
                </div>
                <div class="row" >
                  <div class="col-sm-12">
                    <base-input
                      type="text"
                      label=""
                    ><template></template>
                      <el-autocomplete
                        class="inline-input w-100"
                        v-model="searchCondition.inspectPlaceName"
                        :fetch-suggestions="placeNameSearch"
                        placeholder="検査箇所名を入力"
                        @select="handleSelect"
                        clearable
                      ></el-autocomplete>
                    </base-input>
                  </div>
                </div>
                <div class="row" >
                  <div class="col-sm-12">
                    <base-input
                      type="text"
                      label=""
                    ><template></template>
                      <el-autocomplete
                        class="inline-input w-100"
                        v-model="searchCondition.lineName"
                        :fetch-suggestions="lineNameSearch"
                        placeholder="通り芯名を入力"
                        @select="handleSelect"
                        clearable
                      ></el-autocomplete>
                    </base-input>
                  </div>
                </div>
                <div class="row" >
                  <div class="col-sm-12">
                    <base-input
                      type="text"
                      label=""
                    ><template></template>
                      <el-autocomplete
                        class="inline-input w-100"
                        v-model="searchCondition.createUser"
                        :fetch-suggestions="createUserSearch"
                        placeholder="作成者を入力"
                        @select="handleSelect"
                        clearable
                      ></el-autocomplete>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mb-2">
                <!-- <button type="button" class="btn primary w-100" @click="searchList">検索</button> -->
                <base-button
                  outline
                  type="primary"
                  class="w-100"
                  @click="searchList"
                >
                  <span class="btn-inner--icon"
                    ><i class="fas fa-search"></i>
                    </span>
                  <span class="btn-inner--text">検索</span>
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
        <!-- <div class="col">
          <div class="vertical-separator inline-block" role="separator"></div>
        </div> -->
      <div class="col-lg-10 ml-0 pt-1">
        <div class="card">
          <div class="card-header border-0" v-if="true">
            <div class="row pt-0 pb-0 pl-2 pr-3 no-gutters">
              <!-- 検査箇所一覧(検索結果) -->
              <div class="col-lg-4">
                <table-tool-menu
                  :title="title"
                  :isAdd="false"
                  :isEdit="false"
                  :isCopy="false"
                  :isExport="isExport"
                  :exportTitle="'帳票出力'"
                  :isSingleSelect="isSingleSelect"
                  :isMultiSelect="isMultiSelect"
                  :processing="processing"
                  @export="onClickOutput"
                  @delete="onClickDelete"
                >
                </table-tool-menu>
              </div>
              <!--
              <div class="col-2 w-100">
                  <h4 class="text-left ">検査箇所一覧</h4>
              </div>
              <div class="col-9 text-right">
                <base-button
                  icon
                  outline
                  type="primary"
                  @click="onClickOutput"
                >
                  <span class="btn-inner--icon"
                    ><i class="fas fa-file-export"></i>
                    </span>
                  <span class="btn-inner--text">帳票出力</span>
                </base-button>
              </div>
              <div class="col text-right">
                <base-button
                  round
                  type="danger"
                  class="btn-icon-only"
                  @click.prevent="onClickDelete()"
                >
                  <span class="btn-inner--icon"
                    ><i class="fa fa-trash-alt"></i
                  ></span>
                </base-button>
              </div>
            </div>
            <div class="row mt-1">
            -->
              <div class="col-lg-4">
                <div class="mt-1 d-flex justify-content-center">
                  <el-pagination
                    @current-change="setCurrent"
                    :page-size="pagination.perPage"
                    :current-page="pagination.currentPage"
                    layout="prev, pager, next"
                    :total="total">
                  </el-pagination>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
            </div>
          </div>
          <el-table
            class="table-responsive align-items-center table-flush pl-2"
            header-row-class-name="thead-light"
            ref="inspectPlaceListTable"
            empty-text="検査箇所がありません"
            @selection-change="handleSelectionChange"
            @sort-change="runSort"
            :default-sort="{ prop: 'name', order: 'descending' }"
            :cell-style="{height: '20px'}"
            :row-key="getRowKey"
            :data="pagedData">
              <el-table-column type="selection" align="left" min-width="120px" >
              </el-table-column>
              <el-table-column label="検査箇所No" min-width="140px" prop="textValue" sortable="'custom'">
                  <template v-slot="{ row }">
                  <!-- <span class="text-truncate">{{row.inspectPlace.textValue}}</span> -->
                  <b>
                    <a href="#!" @click.prevent="onPushMap(row)"
                      >{{ row.inspectPlace.textValue }}
                    </a>
                  </b>
                </template>
              </el-table-column>
              <el-table-column label="検査箇所名" min-width="300px" prop="name" sortable="'custom'">
                <template v-slot="{ row }">
                  <span class="text-truncate">{{row.inspectPlace.name}}</span>
                </template>
              </el-table-column>
              <el-table-column label="通り芯名" min-width="140px" prop="lineName" sortable="'custom'">
                <template v-slot="{ row }">
                  <span class="text-truncate">{{row.inspectPlace.lineName}}</span>
                </template>
              </el-table-column>
              <el-table-column label="アイコン" min-width="200px" prop="iconClass" sortable="'custom'">
                <template v-slot="{ row }">
                  <img
                    :src="iconUrlList[`${row.inspectPlace.inspectMasterId}#${row.inspectPlace.iconClass}`]"
                    v-if="iconUrlList[`${row.inspectPlace.inspectMasterId}#${row.inspectPlace.iconClass}`]"
                    class="bg-white avatar-sm mr-2" />
                  <!-- <img :src="iconUrlList[`${row.inspectPlace.inspectMasterId}#${row.inspectPlace.iconClass}`]" v-if="row.inspectPlace.iconClass" class="bg-white avatar mr-3" /> -->
                  <!-- <img :src="iconUrlList[row.iconFilePath]" v-if="row.iconFilePath" class="bg-white avatar mr-3" />                   -->
                  {{ iconClassNameList[`${row.inspectPlace.inspectMasterId}#${row.inspectPlace.iconClass}`] }}
                </template>
              </el-table-column>
              <el-table-column label="作成者" min-width="200px" prop="createUser" sortable="'custom'">
                <template v-slot="{ row }">
                  <span class="text-truncate">{{row.inspectPlace.createUser}}</span>
                </template>
              </el-table-column>
            </el-table>
          <div class="row mt-1">
            <div class="col">
              <div class="mt-1 d-flex justify-content-center">
                <el-pagination
                  @current-change="setCurrent"
                  :page-size="pagination.perPage"
                  :current-page="pagination.currentPage"
                  layout="prev, pager, next"
                  :total="total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal v-model:show="modal.isShow">
      <template v-slot:header>
          <h5 class="modal-title" id="exampleModalLabel">{{modal.title}}</h5>
      </template>
      <div>
        {{modal.message}}
      </div>
      <template v-slot:footer>
          <base-button v-model:type="modal.cancelType" @click="modal.cancelCallBack">{{modal.cancelLabel}}</base-button>
          <base-button v-model:type="modal.okType" @click="modal.okCallBack">{{modal.okLabel}}</base-button>
      </template>
    </modal>
  </div>
  <modal v-model:show="formOutputShow" size="lg">
    <formOutput  @cancel="cancelForm" @makeForm="makeForm" :selectedList="selectedData" ref="formOutput" ></formOutput>
  </modal>
</template>
<style scoped>
.subContent {
  border-radius: 0.25rem !important;
  background-color: white;
  margin: 5px;
  padding: 5px;
  font-size: 0.9em;
}

.vertical-separator {
    border-left: 1.5px solid lightgray;
    height: 100%;
    margin: 2px 0px 0px 0px;
    padding: 0px px 0px 0px;
}

</style>
<script>

import { Storage } from "aws-amplify";
import InspectMasterModel from "../../appModel/InspectMaster/InspectMasterModel";
import InspectPlaceModel from "../../appModel/InspectPlace/InspectPlaceModel";
import UserInfo from '../../appUtils/UserInfo';
import FormOutput from '@/appViews/Form/FormOutput'
import TableToolMenu from "../components/Menu/TableToolMenu"
import Modal from "@/components/Modal";
import DateUtil from '../../appUtils/DateUtil'
import { useToast } from "vue-toastification";
import constants from "@/Config/constants"
import FileUtil from "@/appUtils/FileUtil"

export default {
  /**
   * コンポーネント
   */
  components: {
    FormOutput,
    Modal,
    TableToolMenu
  },
  /**
   * プロパティ
   */
  props: {
    /**
     * プロジェクトID
     */
    projectId: {
      type: String,
      default: "2F47bBSU4tyrFDnqhmuMGA",
    },
    /**
     * ノードID
     */
    nodeId: {
      type: String,
      default: "mSRe8zwA4r8N9NkMpH21Zw",
    },
    /**
     * ノード種別(backimage / map)
     */
    nodeType: {
      type: String,
      default: "backimage",
    },
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
    if (!this.sortColumn.prop) {
      // 初期ソート
      this.sortColumn = {prop: `name`, order: `descending`}
    }
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    this.inspectMasters = null
    this.inspectPlaces = null
    //Vue インスタンスが破壊された後
  },
  data() {
    return {
      inspectMasters: [],
      inspectPlaces: [],
      inspectPlacesAll: [],
      iconUrlList: {},
      iconClassNameList: {},
      searchCondition: {
        // 検査箇所No
        // 検査箇所名
        // 通り芯名
        // 作成者
        inspectPlaceNumber: "",
        inspectPlaceName: "",
        lineName: "",
        createUser: "",
      },
      suggestions: {
        inspectPlaceNumber: [],
        inspectPlaceName: [],
        lineName: [],
        createUser: [],
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      sortColumn: {},
      selectedData: [],
      formOutputShow: false,
      modal: {
        isShow: false,
        title: "",
        message: "",
        okLabel: "OK",
        okType: "secondary",
        okCallBack: function() {
          alert(`push Ok`)
          this.modal.isShow = false
        },
        cancelLabel: "キャンセル",
        cancelType: "primary",
        cancelCallBack: function() {
          alert(`push cancel`)
          this.modal.isShow = false
        },
      },
      title: "検査箇所一覧",
      isExport: true,
      isSingleSelect: false,
      isMultiSelect: false,
    };
  },
  watch: {

  },
  /**
   * コンピュートプロパティ
   */
  computed: {
    /***
     * ページング用のデータをかえす
     */
    pagedData() {
      return this.inspectPlaces.slice(this.from, this.to)
    },
    /**
     * ページング制御用
     */
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    /**
     * ページング制御用
     */
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    /**
     * ページング制御用
     */
    total() {
      return this.inspectPlaces.length;
    },
    // /**
    //  * 検査マスタ
    //  */
    // inspectMaster(masterId) {
    //   if (this.inspectMasters) {
    //     for (let i = 0; i < this.inspectMasters.length; i++) {
    //       if (this.inspectMasters[i].id == masterId) {
    //         return this.inspectMasters[i].inspectMaster
    //       }
    //     }
    //   }
    //   return []
    // },
    // /**
    //  * 検査マスタのアイコン
    //  */
    // iconSettingList() {
    //   if (this.inspectMaster(masterId)) {
    //     if (this.inspectMaster(masterId).iconSetting) {
    //       return this.inspectMaster(masterId).iconSetting
    //     }
    //   }
    //   return []
    // },
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * ページ変更時のハンドラ
     */
    setCurrent(newPage) {
      this.pagination.currentPage = newPage
    },
    getRowKey(row){
      return row.sk
    },

    async init() {

      let loader = this.showLoader();

      try {
        // 検査マスタ
        this.inspectMasters = await InspectMasterModel.getInspectMasterList(this.projectId);

        // 検査箇所マスタ
        this.inspectPlacesAll = await InspectPlaceModel.getInspectPlaceList(this.projectId, this.nodeId);
        this.inspectPlaces = await this.searchFilter();
        console.log(`件数: ${this.inspectPlaces.length}`)

        if (this.sortColumn) {
          await this.changeTableSort(this.sortColumn);
        }

        // サジェストリストを初期化
        this.initSuggestions()

        // アイコンリスト
        await this.setIcons();

        if (this.sortColumn) {
          await this.changeTableSort(this.sortColumn);
        }

      } finally {
        this.hideLoader(loader)
      }

    },

    /**
     * サジェスト表示用のデータを初期化する
     */
    async initSuggestions() {
      // 検査箇所No
      let numberDic = {}
      // 検査箇所名
      let nameDic = {}
      // 通り芯名
      let lineNameDic = {}
      // 作成者
      let createUserDic = {}

      // 検査箇所データの値からサジェスト表示用の値を取得
      this.inspectPlaces.forEach(function(item) {
        numberDic[item.inspectPlace.textValue] = item.inspectPlace.textValue
        nameDic[item.inspectPlace.name] = item.inspectPlace.name
        lineNameDic[item.inspectPlace.lineName] = item.inspectPlace.lineName
        createUserDic[item.inspectPlace.createUser] = item.inspectPlace.createUser
      }.bind(this))

      this.suggestions.inspectPlaceNumber = []
      this.suggestions.inspectPlaceName = []
      this.suggestions.lineName = []
      this.suggestions.createUser = []

      // Element-UI用のデータ形式に変換
      Object.keys(numberDic).forEach(value => {
        this.suggestions.inspectPlaceNumber.push({ "value": value, "link": "" })
      })
      Object.keys(nameDic).forEach(value => {
        this.suggestions.inspectPlaceName.push({ "value": value, "link": "" })
      })
      Object.keys(lineNameDic).forEach(value => {
        this.suggestions.lineName.push({ "value": value, "link": "" })
      })
      Object.keys(createUserDic).forEach(value => {
        this.suggestions.createUser.push({ "value": value, "link": "" })
      })
    },
    /**
     * 検索ボタンのイベントハンドラ
     */
    async searchList() {
      // テーブル選択状態をクリア
      this.clearSelect()
      // 検索条件でフィルターする
      this.inspectPlaces = await this.searchFilter()
    },
    /**
     * テーブルの選択状態をクリアする
     */
    clearSelect() {
      this.$refs.inspectPlaceListTable.clearSelection()
      this.selectedData = []
    },
    /**
     * 検索条件の内容でフィルタする
     */
    async searchFilter() {
      // フィルタ前データを取得
      let filterData = this.inspectPlacesAll;

      if (this.searchCondition.inspectPlaceNumber) {
        filterData = filterData.filter(function(item) {
          return item.inspectPlace.textValue.indexOf(this.searchCondition.inspectPlaceNumber) >= 0
        }.bind(this))
      }

      if (this.searchCondition.inspectPlaceName) {
        filterData = filterData.filter(function(item) {
          return item.inspectPlace.name.indexOf(this.searchCondition.inspectPlaceName) >= 0
        }.bind(this))
      }

      if (this.searchCondition.lineName) {
        filterData = filterData.filter(function(item) {
          return item.inspectPlace.lineName.indexOf(this.searchCondition.lineName) >= 0
        }.bind(this))
      }

      if (this.searchCondition.createUser) {
        filterData = filterData.filter(function(item) {
          return item.inspectPlace.createUser.indexOf(this.searchCondition.createUser) >= 0
        }.bind(this))
      }

      return filterData
    },
    /**
     * 入力候補探索（検査箇所No)
     */
    placeNumberSearch(queryString, cb) {
      if (this.suggestions.inspectPlaceNumber.length == 0) {
        cb()
      }
      if (!queryString) {
        cb(this.suggestions.inspectPlaceNumber)
      }

      let results = this.suggestions.inspectPlaceNumber.filter(item => {
        return item.value.indexOf(queryString) >= 0
      })

      cb(results);
    },
    /**
     * 入力候補探索（検査箇所名)
     */
    placeNameSearch(queryString, cb){
      if (this.suggestions.inspectPlaceName.length == 0) {
        cb()
      }
      if (!queryString) {
        cb(this.suggestions.inspectPlaceName)
      }

      let results = this.suggestions.inspectPlaceName.filter(item => {
        return item.value.indexOf(queryString) >= 0
      })

      cb(results);
    },
    /**
     * 入力候補探索（通り芯名)
     */
    lineNameSearch(queryString, cb){
      if (this.suggestions.lineName.length == 0) {
        cb()
      }
      if (!queryString) {
        cb(this.suggestions.lineName)
      }
      let results = this.suggestions.lineName.filter(item => {
        return item.value.indexOf(queryString) >= 0
      })

      cb(results);
    },
    /**
     * 入力候補探索（作成者)
     */
    createUserSearch(queryString, cb){
      if (this.suggestions.createUser.length == 0) {
        cb()
      }
      if (!queryString) {
        cb(this.suggestions.createUser)
      }
      let results = this.suggestions.createUser.filter(item => {
        return item.value.indexOf(queryString) >= 0
      })

      cb(results);
    },
    /**
     * アイコン情報を設定します。
     */
    async setIcons() {

      const _this = this
      this.iconUrlList = {};
      this.iconClassNameList = {};

      // 検査マスタループ
      for (let i = 0; i < this.inspectMasters.length; i++) {
        const inspectMaster = this.inspectMasters[i].inspectMaster;
        const masterId = inspectMaster.id;
        const iconSettings = inspectMaster.iconSetting;

        iconSettings.forEach((item) => {
          if (item.iconFilePath) {
            _this.getTenantFile(item.iconFilePath).then(result => {

              // 検査マスタID#アイコンクラス
              const key = `${masterId}#${item.iconClass}`;

              // アイコンURL
              _this.iconUrlList[key] = result;

              // 名称
              _this.iconClassNameList[key] = item.name;

              console.log(`アイコンURL:${_this.iconUrlList[key]}`)
              console.log(`定義名:${_this.iconClassNameList[key]}`)
            })
          }
        })
      }
    },

    /**
     * テナントのファイルURL文字列を取得します。
     *
     * @param {String} s3Key S3キー
     *
     * @returns ファイルURL文字列
     */
    async getTenantFile(s3Key) {

      if (!s3Key) {
        return ""
      }
      try {
        return await FileUtil.getTenantFileUrl(s3Key)

        // const user = await UserInfo.getUserInfo();
        // const s3KeyPrefix = `tenant/${user.group}/`;
        // console.log(`getTenantFile ${s3KeyPrefix}${s3Key}`);

        // const url = await this.get(`${s3Key}`, {level: "public", customPrefix: {public: s3KeyPrefix}});
        // console.log(`url:: ${url}`);

        // return url.toString();
      } catch (e) {
        alert(`error getTenantFile ${JSON.stringify(e, null, "\t")}`);
        throw e;
      }
    },

    /**
     * S3からURLを取得します。
     *
     * @param {String} key S3キー
     * @param {Object} config 設定情報
     *
     * @returns URL
     */
    async get(key, config) {
      try {
        let imageUrl = await Storage.get(key, config)
        return imageUrl
      } catch (e) {
        this.debugText = `エラー:${JSON.stringify(e)}`
      }
    },

    /**
     * ソートを実行します。
     */
    async runSort(column) {
      await this.changeTableSort(column);
    },

    /**
     * テーブルのソート順を変更します。
     */
    async changeTableSort(column){
      console.log(column);

      if (column === false) {
        return;
      }

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;

      console.log(fieldName);

      if (!fieldName) {
        return;
      }

      // 復元用に保持
      // this.sortColumn = column;

      // ソート用フィールドに置き換え
      // if (fieldName == "name") {
      //   fieldName = 'title';
      // } else if (fieldName == "size") {
      //   fieldName = 'sortSize';
      // }

      if (sortingType == "descending"){
        if (fieldName == 'iconClass') {

          this.inspectPlaces = this.inspectPlaces.sort((a, b) => {
            // console.log(a.rebarmeasure[fieldName]);
            if (b.inspectPlace[fieldName] < a.inspectPlace[fieldName]) return -1;
            if (b.inspectPlace[fieldName] > a.inspectPlace[fieldName]) return 1;
          });

        } else {
          // 日本語ソート
          this.inspectPlaces = this.inspectPlaces.sort((a, b) => {
            // console.log(a.rebarmeasure[fieldName]);
            // nullは空文字に置換
            if (!a.inspectPlace[fieldName]) a.inspectPlace[fieldName] = '';
            if (!b.inspectPlace[fieldName]) b.inspectPlace[fieldName] = '';
            a = this.convertKana(a.inspectPlace[fieldName].toString());
            b = this.convertKana(b.inspectPlace[fieldName].toString());
            if (a < b) return 1;
            if (a > b) return -1;
            return 0;
            // return a.localeCompare(b, 'ja');
          })
        }
      }
      else {
        if (fieldName == 'iconClass') {
          this.inspectPlaces = this.inspectPlaces.sort((a, b) => {
            if (b.inspectPlace[fieldName] < a.inspectPlace[fieldName]) return 1;
            if (b.inspectPlace[fieldName] > a.inspectPlace[fieldName]) return -1;
          });
        } else {
          // 日本語ソート
          this.inspectPlaces = this.inspectPlaces.sort((a, b) => {
            // nullは空文字に置換
            if (!a.inspectPlace[fieldName]) a.inspectPlace[fieldName] = '';
            if (!b.inspectPlace[fieldName]) b.inspectPlace[fieldName] = '';
            a = this.convertKana(a.inspectPlace[fieldName].toString());
            b = this.convertKana(b.inspectPlace[fieldName].toString());
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
          })
        }
      }
    },
    // https://gist.github.com/kawanet/5553478
    /** カタカナをひらがなに変換する
     * @param {String} src - カタカナ
     * @returns {String} - ひらがな
     */
    convertKana(src) {
      return src.replace(/[\u30a1-\u30f6]/g, function(match) {
        const chr = match.charCodeAt(0) - 0x60;
        return String.fromCharCode(chr);
      });
    },

    /**
     * マップビューへ遷移します。
     * @param {Object} クリックした検査箇所データ
     */
    onPushMap(row) {
      // ノードID、種別を返す
      row[`nodeId`] = this.nodeId;
      row[`nodeType`] = this.nodeType;

      console.log(JSON.stringify(row, null, "\t"));

      // 地図表示する引数として検査箇所データを指定
      this.$emit('transfer', {key: "mapArgs", values: row})
    },

    /**
     * 削除クリック処理です。
     */
    async onClickDelete(title) {
      if (!this.selectedData || this.selectedData < 1) {
        return;
      }

      let loader = this.showLoader();

      try {
        await this.deleteSelectedData();
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * 選択した検査箇所データを削除します（論理削除）
     */
    async deleteSelectedData() {
      // console.log(this.selectedData)
      const count = this.selectedData.length;
      let message = "";
      if (count < 1) {
        return;
      } else if (count == 1) {
        message = this.selectedData[0].inspectPlace.name;
      } else if (count > 1) {
        message = `${count}件のデータ`
      }
      const _this = this
      this.modal = {
        isShow: true,
        title: "検査箇所の削除",
        message: `${message}を削除します。よろしいですか？`,
        okLabel: "削除",
        okType: "danger",
        okCallBack: function() {
          try {
            for (let i = 0; i < count; i++) {
              let data = _this.selectedData[i];

              try {
                // 削除フラグ
                data.inspectPlace.deleteFlag = true;
                // 更新日時
                data.timestamp = DateUtil.getUnixTimeStampOfMilliSecond();
                console.log(data)

                InspectPlaceModel.updateInspectPlace(data);

                // this.outputLog(`info`, `deleted ${data.sk}`)

              } catch (e) {
                console.log(JSON.stringify(e))
                // this.outputLog(`error`, `failed delete ${data.sk} : ${e.message}`)

                if (e.errors) {
                  if (e.errors.length > 0) {
                    switch (e.errors[0].errorType) {
                    case "DynamoDB:ConditionalCheckFailedException":
                      _this.showBottomToast(`他のユーザにて削除されています。`, `warning`)
                      // this.outputLog(`warn`, `競合 pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
                      // リロード
                      _this.init();
                      break
                    default:
                      // this.outputLog(`error`, `削除に失敗 error:${JSON.stringify(e)} pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
                      break
                    }
                  } else {
                    _this.showBottomToast(`削除に失敗しました`, `error`)
                    // this.outputLog(`error`, `削除に失敗 error:${JSON.stringify(e)} pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
                  }

                } else {
                  _this.showBottomToast(`削除に失敗しました`, `error`)
                  // this.outputLog(`error`, `削除に失敗 error:${JSON.stringify(e)} pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
                }

                throw e
              }

            }

            // 選択状態をクリア
            _this.clearSelect();

            _this.init();

          } catch (e) {
            console.log(JSON.stringify(e))
            _this.showBottomToast(`データの削除に失敗しました`, `error`)
            throw e
          }

          // 閉じる
          _this.modal.isShow = false
        },
        cancelLabel: "キャンセル",
        cancelType: "secondary",
        cancelCallBack: function() {
          _this.modal.isShow = false
        },
      }
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },

    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    handleSelectionChange(rowDatas) {
      const count = rowDatas.length;
      console.log(`選択件数：${count}`);

      if (count > 1) {
        this.isSingleSelect = false;
        this.isMultiSelect = true;
        this.isExport = true;
      } else if (count == 1) {
        this.isSingleSelect = true;
        this.isMultiSelect = false;
        this.isExport = true;
      } else {
        // 0件
        this.isSingleSelect = false;
        this.isMultiSelect = false;
        this.isExport = false;
      }

      this.selectedData = rowDatas;
    },

    onClickOutput() {
      console.log('onClickOutput')
      this.formOutputShow = true
    },
    makeForm(){
      this.formOutputShow = false
    },
    cancelForm(){
      this.formOutputShow = false
    },
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },

    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },

  },
};
</script>
