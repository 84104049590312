<template>
  <!-- <div>
    <div class="container-fluid mt-1">
      <div class="row align-items-center" style="height: 48px">
          <div id="inspect-menu" class="col clearfix">
          <base-dropdown title-classes="btn btn-secondary" menuClasses="zindex-tooltip" class="float-right">
            <template v-slot:title>
              <i class="fa fa-map-pin"></i>検査箇所
            </template>
              <a class="dropdown-item" @click="handlerMenuAddInpectPlace" ><i class="fa fa-plus text-primary"></i>新規追加</a>
              <a class="dropdown-item" @click="handlerMenuMoveInpectPlace" ><i class="fa fa-share text-primary"></i>移動</a>
              <a class="dropdown-item" @click="handlerMenuDeleteInpectPlace" ><i class="fa fa-trash text-primary"></i>削除</a>
          </base-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <div class="card border-0">
            <div id="map-container">
              <div id="map" class="map-canvas"></div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div id="map" class="map-canvas"></div>
</template>

<style scoped>
  #map{
   width: 100%;
   height: 100%; 
  }
  #inspect-menu {
    z-index: 1200;
    height: 48px;
  }

  .map-container {
    z-index: 0;
    height: 300px;
    /* height: 100vh; */
    /* height: 500px;
    width: 500px; */
    text-align: left;
  }

  .map {
    /* width: 500px;
    height: 500px; */

     width: 100%;
     height: 100%;

  }

  </style>
<script>

import { Storage } from "aws-amplify";
import BackImageModel from "../../appModel/BackImage/BackImageModel";
import InspectPlaceModel from "../../appModel/InspectPlace/InspectPlaceModel";
// import { formatDate } from '@fullcalendar/common';

// data()内に保持するとLeafletの挙動がおかしくなります。
let mlMap = null;

// メニュー
const InspectPlaceMenu = {
  None: {
    val: 0,
    text: ""
  },
  Add: {
    val: 1,
    text: "新規追加"
  },
  MoveStart: {
    val: 2,
    text: "移動開始"
  },
  MoveEnd: {
    val: 3,
    text: "移動終了"
  },
  Delete: {
    val: 4,
    text: "削除"
  }
}

let inspectMenu = null;

// 仮ID用のカウンタ
let tempIdCounter = 0;

// 検査箇所レイヤ名（仮)
const poiLayerName = '検査箇所';

//test
// let isReload = false;

// 対象POIのID
// let targetPoiId = null;

export default {
  /**
   * コンポーネント
   */
  components: {

  },
  /**
   * プロパティ
   */
  props: {
    /**
     * プロジェクトID
     */
    projectId: {
      type: String,
      default: "2F47bBSU4tyrFDnqhmuMGA",
    },
    /**
     * ノードID
     */
    nodeId: {
      type: String,
      default: "mSRe8zwA4r8N9NkMpH21Zw",
    },
  },
  data() {
    return {
      data1: "",
      backImage: {
        backimagePath: "",
        backImageBase64: "",
        inspectPlaces: [],
        targetInspectPlace: null
      },
    };
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  async created() {
    //インスタンスが生成され､且つデータが初期化された後
    console.log('[START] created');

    try {
      // PDFの読み込みが終わる前に地図作成処理が実行されるので移動
      // await this.init();
    } finally {
      console.log('[END] created')
    }
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  async mounted() {
    //インスタンスが DOM 要素にマウントされた後
    console.log('[START] mounted');

    // map要素の動的作成
    // <div id="map" class=c style="height: 600px"></div>
    // const parentDiv = document.getElementById("map-container");
    // const mapDiv = document.createElement("div");
    // mapDiv.id = "map";
    // mapDiv.className = "map-canvas";
    // mapDiv.style.height = "600px";
    // parentDiv.appendChild(mapDiv);

    try {

      // 初期処理内でPDFファイルの読み込み
      await this.init();

      // 地図生成
      await this.mapCreateTile();

    } finally {
      console.log('[END] mounted');
    }
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前

    // console.log('[START] beforeUnmount');

    // try {
    //   if (mlMap) {
    //     console.log('mlMap:remove');
    //     mlMap.off();
    //     mlMap.remove();
    //     mlMap = null;
    //   }
    // } finally {
    //   console.log('[END] beforeUnmount');
    // }

    // const parentDiv = document.getElementById("map-container");
    // console.log(parentDiv);
    // if (parentDiv.hasChildNodes()) {
    //   parentDiv.removeChild(parentDiv.firstChild);
    //   console.log('removeChild:map');
    // }
  },
  unmounted() {
    //Vue インスタンスが破壊された後
    // console.log('[START] unmounted');

    try {
      if (mlMap) {
        console.log('mlMap:remove');
        mlMap.remove();
      }
    } finally {
      console.log('[END] unmounted');
    }

    // const parentDiv = document.getElementById("map-container");
    // console.log(parentDiv);
    // if (parentDiv.hasChildNodes()) {
    //   parentDiv.removeChild("map");
    //   console.log('removeChild:map');
    // }
  },
  /**
   * watchはvueインスタンスのプロパティ(dataで定義している値など)の変更を監視する
   */
  watch: {
    data1() {
      //alert(`${this.data1} changed`);
    },
  },
  /**
   * コンピュートプロパティ。this.prop1のようにして取得できる
   */
  computed: {
    computed1() {
      return "";
    },
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {

      try {
        // 背景図の初期化
        //await this.initBackImage();

        // ToDo: アイコンの初期化

        // 検査箇所の初期化
        //await this.initInspectPlace();

      } catch (e) {
        alert(`${JSON.stringify(e.errors, null, "\t")}`);
        throw e
      }
    },
    parentPanelResized() {
      console.log(`parentPanelResized`)
      mlMap.invalidateSize()
    },
    /**
     * 背景図情報の初期化処理
     */
    async initBackImage() {

      try {
        console.log('projectId:' + this.projectId);
        console.log('nodeId:' + this.nodeId);

        const prefix = `backImage`;
        // DBから背景図情報のレコード取得
        const backImage = await BackImageModel.getBackImage({
          sk: `${prefix}#${this.projectId}#${this.nodeId}`,
        });

        console.log(backImage);
        if (backImage == null) {
          console.log('背景図未設定')
          return;
        }

        this.backImage.backimagePath = backImage.backImage.backimagePath;
        console.log('背景図パス：' + this.backImage.backimagePath);

        //背景図ファイルをバイナリで取得
        const result = await Storage.get(
          this.backImage.backimagePath,
          { level: "public", download: true, }
        );

        // base64化する
        this.backImage.backImageBase64 = await this.readFileAsDataURL(result.Body);
        // console.log(this.backImage.backImageBase64);

        // TODO:leafletにわたす

      } catch (e) {
        alert(`${JSON.stringify(e.errors, null, "\t")}`);
        throw e
      }

    },

    /**
     * BLOBからBASE64文字列へ
     */
    async readFileAsDataURL(blob) {
      const base64 = await new Promise((resolve) => {
        const reader = new FileReader();

        // eslint-disable-next-line no-unused-vars
        reader.onload = (e) => {
          const result = reader.result;
          resolve(result);
        };

        reader.readAsDataURL(blob);
      });

      return base64;
    },

    /**
     * 検査箇所データの初期化
     */
    async initInspectPlace() {

      try {
        console.log('projectId:' + this.projectId);
        console.log('nodeId:' + this.nodeId);

        // DBから検査箇所情報のレコード取得
        this.inspectPlaces = await InspectPlaceModel.getInspectPlaceList(this.projectId, this.nodeId);
        // const prefix = `inspectPlace`;
        // this.inspectPlaces = await InspectPlaceModel.getInspectPlaceList({
        //   sk: `${prefix}#${this.projectId}#${this.nodeId}`,
        // });

        console.log('検査箇所数:' + this.inspectPlaces.length);

      } catch (e) {
        alert(`${JSON.stringify(e.errors, null, "\t")}`);
        throw e
      }
    },
    async mapCreateTile() {
      mlMap = new MLMap('map', MLMap.VIEW_TYPE.MAP);
      mlMap.addGsiStdLayer('地理院タイル 標準地図');
      mlMap.addGsiPaleLayer('地理院タイル 淡色地図');
      mlMap.addGsiPhotoLayer('地理院タイル 写真');
      mlMap.setView([35.68944, 139.69167], 12);

      const bounds = L.latLngBounds([-90, -180], [90, 180]);
      mlMap.setMaxBounds(bounds.pad(0.25));
    },
    /**
     * 地図の作成 ToDo:メソッド分割
     */
    async mapCreate() {
      // MapletLeaflet関連出力ログレベル
      MLLog.level = MLLog.LOGLEVEL.INFO;

      // PDFを表示する場合はPDFにアクセスする前に必ず設定
      MLPdfUtil.setPdfJsWorkerUrl('./maplet-leaflet/external/pdfjs-2.7.570/pdf.worker.js');

      // ビュータイプを図面表示用に設定してMLMapを生成
      mlMap = null;
      mlMap = new MLMap('map', MLMap.VIEW_TYPE.NON_GEOGRAPHICAL);

      if (!this.backImage.backImageBase64) {
        console.log('背景図Base64未設定');
        return;
      }

      // '識別文字','PDF実データ' の構成になっているので、実データのみを取得
      const pdfBase64 = this.backImage.backImageBase64.split(',')[1];
      // console.log(pdfBase64);

      // PDF情報
      const doc = await MLPdfUtil.getDocumentInfoByBase64(pdfBase64);
      const page = await MLPdfUtil.getPageInfo(doc, 1);

      // レイヤ追加
      mlMap.addPdfLayer('PDF', {
        pdfData: pdfBase64,
        page: 1,
        cMapUrl: './maplet-leaflet/external/pdfjs-2.7.570/cmaps',
      });

      // アイコンスタイル ToDo
      const poiStyle = { iconUrl: './favicon.png' };

      // 検査箇所ラベルスタイル
      const poiLabelStyle = {
        color: '#FFFFFF',
        weight: 4,
        fillColor: '#263238',
        fontSize: 16,
      };

      // 検査箇所レイヤ追加
      mlMap.addPoiLayer(poiLayerName, poiStyle, poiLabelStyle);

      // 作図レイヤ追加
      mlMap.addShapeLayer('作図（お絵描き）');

      // --------------------
      // 表示オブジェクト追加
      // --------------------

      // 検査箇所追加
      this.inspectPlaces.forEach(item => {
        // 仮ID
        if (item.inspectPlace.textValue != null) {
          if (tempIdCounter < item.inspectPlace.textValue) {
            tempIdCounter = item.inspectPlace.textValue;
          }
        }

        mlMap.addPoi(poiLayerName, {
          id: item.inspectPlace.placeId,
          x: item.inspectPlace.x,
          y: item.inspectPlace.y,
          label: item.inspectPlace.textValue,
        });
      });

      tempIdCounter++;
      console.log('tempIdCounter:' + tempIdCounter);

      // --------------------
      // コントロール
      // --------------------

      // mlMap.setLayerControlVisible(false);
      mlMap.setMapInfoControlVisible(true);
      mlMap.setDrawControlVisible(true);

      // --------------------
      // 初期表示位置へ移動
      // --------------------

      // PDF全体を表示
      let bounds = L.latLngBounds([0, 0], [page.height, page.width]);

      // ローカルストレージに保存されているか
      const skString = this.getSeekKeyString();
      const lsBounds = JSON.parse(localStorage.getItem(skString));
      console.log(lsBounds);
      if (localStorage.getItem(skString)) {
        try {
          bounds = L.latLngBounds(
            [lsBounds._southWest.lng, lsBounds._southWest.lat], [lsBounds._northEast.lng, lsBounds._northEast.lat]);
        } catch (e) {
          localStorage.removeItem(skString);
        }
      }
      mlMap.fitBounds(bounds);
      mlMap.setMaxBounds(bounds.pad(0.75));

      // 図形の上下(Z-Order)を正しく描画
      mlMap.refreshZOrder();

      // --------------------
      // イベント
      // --------------------
      this.initMapEvent();

    },

    /**
     * 地図イベントの初期化処理です。
     */
    initMapEvent() {
      mlMap.on('click', (e) => {
        console.log(`[${e.type}] lnglat: ${e.latlng.lng}, ${e.latlng.lat}`);

        mlMap.clearSelectedPoi(poiLayerName);

        if (inspectMenu == InspectPlaceMenu.Add) {

          console.log('検査箇所 -追加');
          this.addInspectPoi(e.latlng.lng, e.latlng.lat);

          // モードを終了
          inspectMenu = InspectPlaceMenu.None;

        } else if (inspectMenu == InspectPlaceMenu.MoveStart) {

          console.log('検査箇所 -移動開始');
          const items = mlMap.getPoiByLatLng(poiLayerName, e.latlng);
          console.log(items);
          mlMap.setSelectedPoi(poiLayerName, items);

          if (items.length < 1) {
            return;
          }

          mlMap.setSelectedPoi(poiLayerName, items);

          // IDを保持
          this.targetPoiId = items[0].id;

          // メニューモード更新
          inspectMenu = InspectPlaceMenu.MoveEnd;

        } else if (inspectMenu == InspectPlaceMenu.MoveEnd) {

          console.log('検査箇所 -移動終了');

          let ret = confirm('移動します。よろしいですか？');

          if (ret) {
            this.updateInspectPoi(this.targetPoiId, e.latlng);
          } else {
            // 移動モードを終了
            inspectMenu = InspectPlaceMenu.None;
          }

        } else if (inspectMenu == InspectPlaceMenu.Delete) {

          console.log('検査箇所 -削除');
          const items = mlMap.getPoiByLatLng(poiLayerName, e.latlng);
          console.log(items);

          if (items.length < 1) {
            return;
          }

          mlMap.setSelectedPoi(poiLayerName, items);

          // 削除確認ダイアログ
          // this.modals.notice = true;
          let ret = confirm('削除します。よろしいですか？');
          if (ret) {
            this.deleteInspectPoi(items[0].id);

            // モードを終了
            inspectMenu = InspectPlaceMenu.None;
          }

          mlMap.clearSelectedPoi(poiLayerName);

        }
      });

      // 地図移動後
      mlMap.on('moveend', (e) => {
        let bounds = e.target.getBounds();
        console.log(`[${e.type}] bounds: ${bounds}`);

        this.saveBounds(bounds);
      });

      // 縮尺変更後
      mlMap.on('zoomend', (e) => {
        let bounds = e.target.getBounds();
        console.log(`[${e.type}] bounds: ${bounds}`);

        this.saveBounds(bounds);
      });
    },

    /**
     * メニュー[検査箇所] - [追加] クリック
     */
    handlerMenuAddInpectPlace() {
      console.log('メニュー[検査箇所] - [追加] クリック');
      inspectMenu = InspectPlaceMenu.Add;
    },

    /**
     * 検査箇所追加
     * @param {*} width
     * @param {*} height
     */
    async addInspectPoi(x, y) {

      try {
        // // IDは仮で日付
        // const date = new Date().toString();
        // console.log(date);
        // const dates[] = date.split(' ');
        // const dateString = dates
        // // const dateString = formatDate(date, 'yyyyMMddHHmmssSSS');

        // DBへ登録
        let dbData = await InspectPlaceModel.getNewData(this.projectId, this.nodeId);
        // const prefix = `inspectPlace`;
        // let dbData = await InspectPlaceModel.getNewData({
        //   sk: `${prefix}#${this.projectId}#${this.nodeId}`,
        // });
        const placeId = dbData.sk.split('#')[3];
        console.log('placeId:' + placeId);
        dbData.inspectPlace.placeId = placeId;
        dbData.inspectPlace.x = x;
        dbData.inspectPlace.y = y;
        dbData.inspectPlace.textValue = tempIdCounter;

        InspectPlaceModel.addInspectPlace(dbData);

        // 図形を追加
        mlMap.addPoi(poiLayerName, {
          id: placeId,
          x: x,
          y: y,
          label: tempIdCounter,
        });

        tempIdCounter++;

        // 図形の上下(Z-Order)を正しく描画
        mlMap.refreshZOrder();

      } catch (e) {
        alert(`DB更新失敗 ${e.message}`)
        throw e
      }
    },

    /**
     * メニュー[検査箇所] - [移動] クリック
     */
    handlerMenuMoveInpectPlace() {
      console.log('メニュー[検査箇所] - [移動] クリック');
      inspectMenu = InspectPlaceMenu.MoveStart;
    },

    /**
     * 検査箇所を更新（座標の移動）します。
     * @param {*} id
     * @param {*} latlng
     */
    async updateInspectPoi(id, latlng) {

      try {
        // 地図クリックで取得したIDから対象の検査箇所データを取得
        await this.getTargetInspectId(id);

        // DBを更新
        let dbData = this.targetInspectPlace;
        console.log(dbData);
        dbData.inspectPlace.x = latlng.lng;
        dbData.inspectPlace.y = latlng.lat;
        InspectPlaceModel.updateInspectPlace(dbData);

        // 図形を更新
        const poi = mlMap.getPoiById(poiLayerName, id);
        console.log(poi);
        poi.x = latlng.lng;
        poi.y = latlng.lat;
        mlMap.updatePoi(poiLayerName, poi);

        // 図形の上下(Z-Order)を正しく描画
        mlMap.refreshZOrder();

      } catch (e) {
        alert(`DB更新失敗 ${e.message}`)
        throw e
      } finally {
        inspectMenu = InspectPlaceMenu.None;
      }
    },

    /**
     * メニュー[検査箇所] - [削除] クリック
     */
    handlerMenuDeleteInpectPlace() {
      console.log('メニュー[検査箇所] - [削除] クリック');
      inspectMenu = InspectPlaceMenu.Delete;
    },

    /**
     * 検査箇所を削除します。
     * @param {*} id
     */
    async deleteInspectPoi(id) {

      try {
        // 地図クリックで取得したIDから対象の検査箇所データを取得
        await this.getTargetInspectId(id);

        // DBから削除
        console.log(this.targetInspectPlace);
        InspectPlaceModel.deleteInspectPlace(this.targetInspectPlace);

        // 図形を削除
        mlMap.removePoiById(poiLayerName, id);

        // 図形の上下(Z-Order)を正しく描画
        mlMap.refreshZOrder();

      } catch (e) {
        alert(`DB更新失敗 ${e.message}`)
        throw e
      }
    },

    /**
     * 対象の検査箇所データを取得します。
     */
    async getTargetInspectId(id) {

      this.inspectPlaces.forEach(item => {

        if (item.inspectPlace.placeId == id) {
          this.targetInspectPlace = item;
        }

      });
    },

    /**
     * 現在の地図表示領域をローカルストレージに保存します。
     * @param {現在の表示領域} bounds
     */
    saveBounds(bounds) {
      const parsed = JSON.stringify(bounds);
      console.log(this.getSeekKeyString() + ':' + parsed);
      localStorage.setItem(this.getSeekKeyString(), parsed);
    },

    /**
     * sk文字列を取得します。
     */
    getSeekKeyString() {
      const prefix = `backImage`;
      return `${prefix}#${this.projectId}#${this.nodeId}`;
    },

    reload() {
      this.$router.go({path: this.$router.currentRoute.path, force: true});
    }
  },
};
</script>
