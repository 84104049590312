<template>
  <div>
    <card v-if="todoData">
    <!-- タイトル -->
    <base-input type="text" label="タイトル">
      <template></template>
      <el-input
        placeholder="ToDoのタイトルを入力"
        v-model="todoData.todo.title"
      ></el-input>
    </base-input>
    
    <!-- 対象者 -->
    <base-input name="ToDoの対象者を選択" label="対象者" v-model="todoData.todo.targetUser">
      <el-select placeholder=""
        v-model="todoData.todo.targetUser"
        >
        <el-option v-for="user in users"
                  class="select-danger"
                  :value="user.user.userId"
                  :label="user.user.name"
                  :key="user.user.name">
        </el-option>
      </el-select>
    </base-input>
    <!-- 内容 -->
    <base-input type="text" label="内容">
      <template></template>
      <!-- <el-input
        placeholder="Please input"
        v-model="todoData.todo.content"
      ></el-input> -->
      <el-input type="textarea" v-model="todoData.todo.content"></el-input>
    </base-input>
    <!-- 予定日時 -->
    <base-input
      type="text"
      label="予定日"
      addon-left-icon="ni ni-calendar-grid-58"
    >
      <template v-slot="{ focus, blur }">
        <flat-picker
          @on-open="focus"
          @on-close="blur"
          :config="{ allowInput: true }"
          class="form-control datepicker"
          v-model="todoData.todo.scheduledDateTime"
        >
        </flat-picker>
      </template>
      <!-- <el-input placeholder="Please input" v-model="tenantData.tenant.contractStart"></el-input> -->
    </base-input>
    <!-- 状態 -->

    <!-- 関連ファイル -->
    <!-- <file-input initialLabel="ファイルを選択" ref="fileInput"></file-input> -->

    <!-- パラメータ(任意のJSONリテラル)   -->
    <div class="row">
      <div class="col-6">
        <base-button
          block
          type="secondary"
          @click="onClickCancel"
          >キャンセル</base-button
        >
      </div>
      <div class="col-6">
        <base-button
          block
          type="primary"
          @click="onClickSave"
          >保存</base-button
        >
      </div>
    </div>
    </card>
  </div>
</template>

<style>

</style>


<script>
import UserModel from "@/appModel/users/UserModel";
import TodoModel from "@/appModel/ToDo/TodoModel"
import FileInput from "@/components/Inputs/FileInput";
import Card from "@/components/Cards/Card.vue";
import flatPicker from "vue-flatpickr-component";

export default {
  components: {
    FileInput,
    flatPicker,
    Card
  },
  props: {
    editTodoData: Object,
    isEdit: Boolean,
    id: String
  },
  computed: {
  },
  data() {
    return {
      users: [],
      todoData: null
    }
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    //this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    initTodo() {
      console.log(`TotoEdit initTodo`)
      // ユーザ取得
      this.getProjectUsers()
      // 新規登録データ
      this.todoData = null
      
      if (this.editTodoData) {
        console.log(`edit`)
        this.todoData = Object.assign({}, this.editTodoData)
      } else {
        console.log(`new`)
        this.getNewTodoData()
      }
    },
    async getProjectUsers() {
      this.users = await UserModel.getUserList();
    },
    async getNewTodoData() {
      this.todoData = await TodoModel.getNewData()
    },
    async onClickSave() {
      try {
        if (this.editTodoData) {
          //更新
          console.log(`更新`)
          await TodoModel.updateTodo(this.todoData)
        } else {
          //新規
          console.log(`新規`)
          await TodoModel.addTodo(this.todoData)
        }
        this.onEndTodo()
      } catch (e) {
        alert(`Todo更新エラー ${JSON.stringify(e)}`)
        throw e
      }
    },
    onEndTodo(){
      let todoStore = this.$store.state.todo
      todoStore.edit = false
      todoStore.data = null
      todoStore.drawer = true
      this.$store.commit('setTodo', todoStore)
    },
    onClickCancel(){
      this.onEndTodo()
    }
  }
}
</script>