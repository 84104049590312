<template>
  <div class="card">
    <!-- <div class="border-0 card-header">
      <ol class="breadcrumb">
        <li
          class="breadcrumb-item"
          v-for="dir in dirList"
          :key="dir"
          style="display: inline-block"
        >
          {{ dir }}
        </li>
      </ol>
    </div> -->
    <div class="row mt-1">
      <div class="col">
        <!-- <el-button>
          <label for="file" class="p-0">
              ファイル追加<input type="file" @change="upload" id="file" style="display:none;">
          </label>
        </el-button> -->
        <!-- <dropzone-file-upload v-model="fileSingle"></dropzone-file-upload> -->
        <!--
        <file-input @change="filesChange" initialLabel="ファイルを選択" ref="fileInput"></file-input>
         -->
      </div>

      <div class="col-3 d-flex justify-content-end mr-1">
        <base-button
                icon
                outline
                type="primary"
                @click="onClickUpload"
              >
                <span class="btn-inner--icon"
                  ><i class="fa fa-cloud-upload-alt"></i
                ></span>
                <span class="btn-inner--text">アップロード</span>
              </base-button>
      </div>
    </div>
    <div class="row mt-1">
      <div class="mt-2 ml-4">
        <span>
          <i class="fas fa-search"></i>
        </span>
      </div>
      <div class="col-md-6">
        <base-input>
          <el-input alternative clearable placeholder="検索" v-model="searchWord">
          </el-input>
        </base-input>
      </div>
      <div class="col">
        <div class="mt-1 d-flex justify-content-end">
          <base-pagination v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total"></base-pagination>
        </div>
      </div>
    </div>
    <!-- <div class="mt-2 mb-2"></div> -->
    <!-- <div>{{display.nodeName}}</div> -->
    <el-table
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      empty-text="ファイルがありません"
      :data="pagedData"
      @sort-change="changeTableSort"
    >
      <el-table-column type="selection" align="left" min-width="120px">
      </el-table-column>
      <el-table-column
        label="ファイル名"
        min-width="310px"
        prop="name"
        :sortable="'custom'"
      >
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <!-- <a href="#" class="avatar rounded-circle mr-3">
              <img alt="Image placeholder" :src="row.img" />
            </a> -->
            <span
              v-bind:class="row.iconClass"
              style="font-size: 2rem; margin-right: 1rem"
            ></span>
            <div class="media-body">
              <span class="font-weight-600 name mb-0 text-sm">
                <a href="#!" @click.prevent="onClickFile(row)">
                  <span v-html="highLight(row.title)"></span>
                </a>
                <!-- <a href="#!" @click.prevent="onClickFile(row)">{{
                  row.title
                }}</a> -->
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="ファイルサイズ"
        prop="size"
        min-width="140px"
        :sortable="'custom'"
      >
      </el-table-column>

      <el-table-column min-width="100px">
        <template v-slot="{ row }">
        <el-dropdown trigger="click" class="dropdown" >
          <span class="btn btn-sm btn-icon-only text-light">
            <i class="fas fa-ellipsis-v mt-2"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show">
              <el-dropdown-item command="remove" v-on:click.prevent="onConfirmRemoveFile(row)">削除</el-dropdown-item>
              <!-- <a class="dropdown-item" @click.prevent="onConfirmRemoveFile(row)">削除</a> -->
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <modal v-model:show="modals.fileRemoveModal">
      <template v-slot:header>
          <h5 class="modal-title" id="exampleModalLabel">ファイル削除</h5>
      </template>
      <div>
        {{removeFile.title}}を削除しますか？
      </div>
      <template v-slot:footer>
          <base-button type="secondary" @click="onCancel">キャンセル</base-button>
          <base-button type="danger" @click="onRemove">削除</base-button>
      </template>
    </modal>

    <modal v-model:show="showFileUploadModal">
      <file-upload ref="fileUpload" @close-upload="closeUpload"></file-upload>
    </modal>
  </div>
</template>
<style>
.custom-file-label::after {
  content: "参照" !important;
}
</style>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import { API,Storage } from 'aws-amplify';
import FileModel from "../../../appModel/File/FileModel";
import UserInfo from '../../../appUtils/UserInfo';
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
import FileInput from "@/components/Inputs/FileInput";
import Modal from "@/components/Modal";
import FileUpload from '@/appViews/components/FileView/FileUpload.vue'
const short = require("short-uuid");

export default {
  name: "light-table",
  components: {
    FileInput,
    Modal,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    FileUpload,
  },
  props: {
    dirList: Array,
    // fileList: Array,
  },
  data() {
    return {
      //currentNode:"",
      tenantId: "",
      display: {
        nodeName: "",

      },
      modals: {
        fileRemoveModal: false
      },
      fileList: [],
      files: [],
      fileSingle: [],
      projects: [
        {
          img: "img/theme/bootstrap.jpg",
          fileType: "jpg",
          title: "IMG_0123456789.jpg",
          size: "1,234KB",
          status: "pending",
          statusType: "warning",
          completion: 60,
        },
      ],
      removeFile: {},
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      searchWord: '',
      showFileUploadModal: false,
      sortColumn: {},
    };
  },
  /** ライフサクルメソッド */
  beforeCreate(){
    //インスタンスは生成されたがデータが初期化される前
    //this.currentNode = this.$store.state.tree
  },
  created(){
    //インスタンスが生成され､且つデータが初期化された後
    const _this = this
    this.tenantId = this.getTenantId().then(id => {
      _this.tenantId = id
    })
  },
  beforeMount(){
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted(){
    //インスタンスが DOM 要素にマウントされた後
    console.log(`mounted`)
    this.init()

  },
  beforeUpdate(){
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeDestroy(){
    //Vue インスタンスが破壊される前
  },
  destroyed(){
    //Vue インスタンスが破壊された後

  },
  /** コンピューテッドプロパティ */
  computed: {
    pagedData() {
      return this.searchData.slice(this.from, this.to);
      // return this.fileList.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchData.length;
      // return this.fileList.length;
    },
    currentProject() {
      return this.$store.state.project
    },
    currentNode() {
      return this.$store.state.selectedNode
    },
    searchData() {

      // 空白除去、大文字小文字区別なし
      let word = this.searchWord.trim().toLowerCase()

      if (word === '') {
        return this.fileList;
      }

      return this.fileList.filter(file => {
        return file.title.includes(word)
      })
    }
  },
  watch:{
    currentNode:function(newValue,oldValue) {
      this.init()
    }
  },
  /** クラスメソッド */
  methods: {
    init() {
      if (!this.currentNode) {
        return
      }
      this.display.nodeName = this.currentNode.label

      this.loadFileList()
      this.showFileUploadModal = false;
      this.sortColumn = {};
    },
    refresh() {
      this.loadFileList()
    },
    async loadFileList() {
      const projectId = this.getProjectId()
      const nodeId = this.getCurrentNodeId()
      if (!projectId || !nodeId) {
        alert(`パラメータ不足: ${projectId}/${nodeId}`)
        return
      }
      const results = await FileModel.getFileList(projectId,nodeId)
      this.fileList = results
      console.log(`loadFileList ${JSON.stringify(this.fileList,null,"\t")}`)

    },
    onConfirmRemoveFile(file) {
      console.log(`onConfirmRemoveFile ${JSON.stringify(file)}`)
      this.removeFile = file
      this.modals.fileRemoveModal = true
    },
    onCancel() {
      this.removeFile = {}
      this.modals.fileRemoveModal = false
    },
    async onRemove() {
      try {
        const projectId = this.getProjectId()
        const nodeId = this.getCurrentNodeId()
        // S3キー名
        const s3Key = `${this.tenantId}/${projectId}/${nodeId}/${this.removeFile.title}`
        // ファイル削除
        await Storage.remove(s3Key)
        // DB削除
        await FileModel.deleteFile(this.removeFile.rawData)

      } catch (e) {
        throw new Error(`ファイル削除エラー ${e}`)

      } finally {
        this.removeFile = {}
        this.modals.fileRemoveModal = false
        this.refresh()
      }
    },
    filesChange(files) {
      if (files.length > 0) {
        this.files = files
      }
    },
    async onClickUpload() {
      // ファイルアップロードモーダルを表示
      this.showFileUploadModal = true;
      this.$refs.fileUpload.init();

      // try {
      //   console.log(`start upload`)
      //   let files = this.files
      //   const projectId = this.getProjectId()
      //   const nodeId = this.getCurrentNodeId()

      //   if(!this.tenantId || !projectId || !nodeId) {
      //     alert(`パラメータ不足: ${this.tenantId}/${projectId}/${nodeId}`)
      //     return
      //   }
      //   // S3キー名
      //   const s3Key = `${this.tenantId}/${projectId}/${nodeId}/${files[0].name}`
      //   // ファイルサイズ
      //   const size = files[0].size
      //   const fileName = files[0].name
      //   const fileBody = files[0]

      //   try {
      //     // S3バケットにファイルを保存
      //     await Storage.put(s3Key, fileBody).then(result => {
      //       console.log(result)
      //       this.refresh()
      //     }).catch(err => console.log(err));
      //   } catch (e) {
      //     alert(`S3アップロード失敗 ${e.message}`)
      //     throw e
      //   }

      //   try {
      //     // DBにレコード登録
      //     let dbData = await FileModel.getNewFileData(projectId,nodeId)
      //     dbData.file.fileName = fileName
      //     dbData.file.fileSize = size

      //     FileModel.addFile(dbData)
      //   } catch (e) {
      //     alert(`DB更新失敗 ${e.message}`)
      //     Storage.remove(s3Key)
      //     throw e
      //   }
      //   this.loadFileList()
      // } catch (e) {
      //   console.log(`アップロードエラー ${e}`)
      //   throw e
      // }
    },
    // async upload(e) {
    //   try {
    //     console.log(`start upload`)
    //     var files = e.target.files || e.dataTransfer.files;
    //     console.log(files)

    //     const projectId = this.getProjectId()
    //     const nodeId = this.getCurrentNodeId()

    //     if(!this.tenantId || !projectId || !nodeId) {
    //       alert(`パラメータ不足: ${this.tenantId}/${projectId}/${nodeId}`)
    //       return
    //     }
    //     // S3キー名
    //     const s3Key = `${this.tenantId}/${projectId}/${nodeId}/${files[0].name}`
    //     // ファイルサイズ
    //     const size = files[0].size
    //     const fileName = files[0].name
    //     const fileBody = files[0]
    //     try {
    //       // S3バケットにファイルを保存
    //       const result = await Storage.put(s3Key, fileBody)
    //       .then(result => {
    //         console.log(result)
    //         this.refresh()
    //         })
    //       .catch(err => console.log(err));
    //     } catch(e) {
    //       alert(`S3アップロード失敗 ${e.message}`)
    //       throw e
    //     }

    //     try {
    //       // DBにレコード登録
    //       let dbData = await FileModel.getNewFileData(projectId,nodeId)
    //       dbData.file.fileName = fileName
    //       dbData.file.fileSize = size

    //       FileModel.addFile(dbData)
    //     }catch(e) {
    //       alert(`DB更新失敗 ${e.message}`)
    //       Storage.remove(s3Key)
    //       throw e
    //     }
    //     this.loadFileList()
    //   } catch(e) {

    //     throw e
    //   }

    // },
    async getTenantId() {
      const loginInfo = await UserInfo.getUserInfo()
      if (!loginInfo.group) {
        return null
      }
      return loginInfo.group
    },

    getProjectId() {
      return this.currentProject.sk.split("#")[1]
    },
    getCurrentNodeId() {
      return this.currentNode.id
    },
    onClickFile(data) {
      this.$emit("clicked-file", data);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`Cancel the transfert of ${ file.name } ?`);
    },
    highLight(text) {
      let word = this.searchWord.trim().toLowerCase()

      if (word === '') return text

      if (!text.includes(word)) return text

      const re = new RegExp(word, 'ig');

      return text.replace(re, function(search){
        return '<span style="background-color:yellow;font-weight:bold">'+ search + '</span>'
      })
    },
    async closeUpload() {
      await this.loadFileList();
      this.changeTableSort(this.sortColumn);
      this.showFileUploadModal = false;
    },
    changeTableSort(column){
      console.log(column);

      if (!column) {
        return;
      }

      // アップロードモーダルから戻った際の復元用に保持
      this.sortColumn = column;

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;

      // ソート用フィールドに置き換え
      if (fieldName == "name") {
        fieldName = 'title';
      } else if (fieldName == "size") {
        fieldName = 'sortSize';
      }

      if (sortingType == "descending"){
        this.fileList = this.fileList.sort((a, b) => {
          console.log(a[fieldName]);
          if (a[fieldName] < b[fieldName]) return 1;
          if (a[fieldName] > b[fieldName]) return -1;
        });
      }
      else {
        this.fileList = this.fileList.sort((a, b) => {
          if (b[fieldName] < a[fieldName]) return 1;
          if (b[fieldName] > a[fieldName]) return -1;
        });
      }

      console.log(this.fileList);
    }
  },
};
</script>
