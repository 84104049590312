<template>
  <div>
    <div class="container-fluid mt-1">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <h3>プロジェクト基本情報</h3>
            <div class="pl-lg-4">
              <base-input type="text" label="プロジェクト名">
                <template></template>
                <el-input
                  placeholder="Please input"
                  v-model="projectData.project.name"
                ></el-input>
              </base-input>
            </div>
            <h3>オプション</h3>
            <div class="pl-lg-4">
              <div class="row">
                <div
                  class="col-12"
                  v-for="option in projectData.project.options"
                  :key="option.id"
                >
                  <!-- @click.prevent="onClickProject(row)" -->

                  <div class="row">
                    <div class="col-3">
                      <base-input type="text" label="項目名">
                        <el-input
                          placeholder="項目名を入力"
                          v-model="option.label"
                        ></el-input>
                      </base-input>
                    </div>
                    <div class="col">
                      <base-input type="text" label="設定値">
                        <el-input
                          placeholder="値を入力"
                          v-model="option.value"
                        ></el-input>
                      </base-input>
                    </div>
                    <div class="col-auto d-flex align-items-center">
                      <!-- <base-button  round type="primary" @click="onClickDeleteOption">
                                        <span class="btn-inner--icon"><i class="fa fa-trash-alt"></i></span>
                                        <span class="btn-inner--text">削除</span>
                                    </base-button> -->
                      <base-button
                        round
                        type="primary"
                        class="btn-icon-only"
                        @click.prevent="onClickRemoveOption(option)"
                      >
                        <span class="btn-inner--icon"
                          ><i class="fa fa-trash-alt"></i
                        ></span>
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>

              <base-button
                icon
                outline
                type="primary"
                @click="onClickAddOption"
              >
                <span class="btn-inner--icon"
                  ><i class="fa fa-plus-square"></i
                ></span>
                <span class="btn-inner--text">項目を追加</span>
              </base-button>
            </div>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <card>
            <template v-slot:header>
              <h5 class="h3 mb-0">プロジェクトユーザ</h5>
            </template>
            <el-table
              class="table-responsive table-flush"
              header-row-class-name="thead-light"
              row-key="id"
              :data="queriedData"
              @selection-change="onSelectionChangeUser"
            >
              <el-table-column
                label="ユーザ名"
                min-width="220px"
                prop="name"
                sortable
              >
                <template v-slot="{ row }">
                  <b>{{ row.name }}</b>
                </template>
              </el-table-column>

              <el-table-column label="権限" prop="product" sortable>
                <template v-slot="{ row }">
                  <span v-if="row.usertype == '0'">一般</span>
                  <span v-if="row.usertype == '1'">管理者</span>
                  <span></span>
                </template>
              </el-table-column>

            </el-table>
            <template v-slot:footer>
              <div
                class="
                  col-12
                  d-flex
                  justify-content-center justify-content-sm-between
                  flex-wrap
                "
              >
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </template>
          </card>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <base-button
              block
              type="primary"
              @click="onClickCreateProject"
              v-if="isNewProject"
              >プロジェクトを作成する</base-button
            >
            <base-button
              block
              type="primary"
              @click="onClickSaveProject"
              v-if="!isNewProject"
              >プロジェクトを保存する</base-button
            >
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "../../components/Cards/Card.vue";
import UserModel from "../../appModel/users/UserModel";
import UserInfo from "../../appUtils/UserInfo";
import ProjectModel from "../../appModel/project/ProjectModel";
//import ProgressTrackList from "@/views/Dashboard/ProgressTrackList";

export default {
  components: {
    Card,
  },
  /**
   * プロパティ
   */
  data() {
    return {
      projectId: "",
      isNewProject: true,
      userList: [],
      projectData: { project: {} },
      project: {
        name: "あいうえお",
        options: [],
        users: [],
        maxOption: 5,
        maxUsers: 10,
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
    };
  },
  /**
   * コンピューテッドプロパティ
   */
  computed: {
    // ユーザマスタにプロジェクトへの存在有無を反映させた結果
    tableData() {
      this.userList.forEach((data) => {
        //this.userList.push(data.user)
        //console.log(`userList: ${JSON.stringify(this.userList[index])}`)
        data.joined = false;
        // console.log(`this.project.users:: ${JSON.stringify(this.project.users)}`)
        if (this.projectData.project.users) {
          const index = this.projectData.project.users.findIndex(
            (projectUser) => {
              console.log(
                `tableData:findIndex${projectUser.cognitoUserId} ${data.cognitoUserId}`
              );
              return projectUser.cognitoUserId == data.cognitoUserId;
            }
          );
          if (index >= 0) {
            data.joined = true;
          }
        }

        //console.log(`this.userList[idx]::${JSON.stringify(this.userList[idx])}`)
      });
      return this.userList;
    },
    // ページングによる絞り込みを行った結果
    pagedData() {
      //return this.userList.slice(this.from, this.to);
      //console.log(this.tableData.length)
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      // 検索条件が指定されていない場合はページング後の配列を返す
      if (!this.searchQuery) {
        return this.pagedData;
      }
      // 検索条件によるフィルタ処理
      let result = this.tableData.filter((row) => {
        // 該非判定フラグ
        let isIncluded = false;
        // 検索条件項目分ループ
        for (let key of this.propsToSearch) {
          // 検索条件の値取得
          let rowValue = row[key].toString();
          // 文字列がデータ内に含まれているかチェック
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            // 含まれている場合
            isIncluded = true;
          }
        }

        return isIncluded;
      });
      // 検索結果をページングして返す
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.userList.length;
    },
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    this.getProjectUsers();
    let storeData = this.$store.state.project;
    this.isNewProject = this.$route.meta.isNewProject == "1";
    // this.isNewProject = (!storeData.sk)
    if (this.isNewProject) {
      // 新規プロジェクト
      console.log(`新規プロジェクト`);
      this.newProject();
    } else {
      // プロジェクト編集
      console.log(`プロジェクト編集: ${JSON.stringify(storeData, null, "\t")}`);
      this.setProject(storeData.sk);
    }
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },

  methods: {
    async setProject(projectId) {
      this.projectData = { project: {} };
      const pk = (await UserInfo.getUserInfo()).group;
      const sk = projectId;
      this.projectData = await ProjectModel.getProject({ pk: pk, sk: sk });
      console.log(`setProject ${JSON.stringify(this.projectData, null, "\t")}`);
    },
    async newProject() {
      this.projectData = { project: {} };
      this.projectData = await ProjectModel.getNewData();
      //this.projectData.sk = ProjectModel.createSk()
      const group = (await UserInfo.getUserInfo()).group;
      this.projectData.pk = group;
      this.projectData.groups.push(group);
    },
    async getProjectUsers() {
      const userInfo = await UserInfo.getUserInfo();
      console.log(`getProjectUsers: ${JSON.stringify(userInfo)}`);
      const result = await UserModel.getUserList();
      console.log(`getProjectUsers:result = ${result ? result.length : 0}`);
      this.userList = [];

      result.forEach((data) => {
        this.userList.push(data.user);
      });
    },
    onChangeUserJoin(row) {
      // 選択ユーザからプロジェクト上のユーザを取得
      const index = this.projectData.project.users.findIndex((projectUser) => {
        return projectUser.cognitoUserId == row.cognitoUserId;
      });

      if (index >= 0) {
        // プロジェクト上にユーザが存在するため、ユーザを削除する
        //row.joined = false
        // プロジェクト上からユーザを削除する
        console.log(`onChangeUserJoin 削除 ${row.cognitoUserId}`);
        this.projectData.project.users.slice(index, 1);
      } else {
        console.log(`onChangeUserJoin ${JSON.stringify(row)}`);
        // プロジェクト上にユーザが存在しないのでユーザを追加する
        console.log(`onChangeUserJoin 追加 ${row.cognitoUserId}`);
        let user = Object.assign({}, row);
        delete user.joined;
        this.projectData.project.users.push(user);
      }
    },
    onClickAddOption() {
      this.addOption();
    },
    onClickRemoveOption(option) {
      const index = this.projectData.project.options.findIndex(
        (element) => {
          return element.id == option.id;
        }
      );
      if (index) {
        this.deleteOption(index);
      }
    },
    addOption() {
      if (
        this.projectData.project.options.length >=
        this.projectData.project.maxOption
      ) {
        alert("上限です");
        return;
      }
      let optionId = 0;
      if (this.projectData.project.options.length > 0) {
        optionId = this.projectData.project.options.length;
      }
      this.projectData.project.options.push({
        id: optionId,
        label: "",
        value: "",
      });
    },
    deleteOption(index) {
      this.projectData.project.options.splice(index, 1);
    },
    async onClickCreateProject() {
      let loader = this.showLoader();

      try {
        console.log(JSON.stringify(this.projectData, null, "\t"));
        await ProjectModel.addProject(this.projectData);

        let id = this.projectData.project.id;
        await this.setStoreProject();
        this.$router.push({
          path: `/projectMain`,
          query: { id: `${id}` },
        });

        // this.$router.push({
        //   name: "Projects",
        // });
      } catch (e) {
        console.log(JSON.stringify(e))
      } finally {
        this.hideLoader(loader);
      }
    },
    async onClickSaveProject() {
      let loader = this.showLoader();

      try {
        // ProjectModel.updateProject(this.projectData)
        await ProjectModel.updateProject(this.projectData);

        let id = this.projectData.project.id;
        await this.setStoreProject();
        this.$router.push({
          path: `/projectMain`,
          query: { id: `${id}` },
        });

        // this.$router.push({
        //   name: "Projects",
        // });
      } catch (e) {
        console.log(JSON.stringify(e))
      } finally {
        this.hideLoader(loader);
      }
    },
    async setStoreProject() {
      let store = this.$store.state.project;
      store = this.projectData.project;
      this.$store.commit("setProject", store);
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
  },
};
</script>
<style>
.profile-header {
  background-image: url(/img/theme/profile-cover.jpg);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
</style>
