const short = require("short-uuid");
const UserInfo = require("../../appUtils/UserInfo");
const baseModel = require("../baseModel");

/**
 * 背景図用のデータ取得モデルクラス
 */
class BackImageModel extends baseModel {
  /**
   * テナント内の背景図リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getBackImageList(projectId, nodeId) {
    return await super.getList({
      beginsWith: `backImage#${projectId}#${nodeId}#`,
    });
  }

  /**
   * 背景図を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteBackImage(data) {
    await super.deleteData(data);
  }

  /**
   * 背景図管理レコードを追加する
   * @param {*} data
   */
  static async addBackImage(data) {
    await super.addData(data);
  }

  /**
   * 背景図管理レコードを更新する
   * @param {*} data
   */
  static async updateBackImage(data) {
    // 背景図内容の更新を行った場合は更新日付を書き換える。DBの値だけの場合は何もしない。
    alert(`updateBackImage`);

    await super.updateData(data);
  }

  /**
   * 背景図管理レコードを取得する
   * @param {*} data
   */
  static async getBackImage(data) {
    return await super.getSingleData(data);
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, nodeId) {
    //backImage#プロジェクトID#ノードID
    return `backImage#${projectId}#${nodeId}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewBackImageData(projectId, nodeId) {
    let newRowData = await super.getNewData();
    newRowData.sk = this.createSk(projectId, nodeId);
    newRowData.backImage = {
      backImageName: "",
      backimagePath: "",
      lineSetting: [],
      inspectMasterList: [],
    };

    return newRowData;
  }
}

module.exports = BackImageModel;
