<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-success navbar-dark': type === 'default' }"
  >
    <!-- Search form -->
    <!-- <form
      class="navbar-search form-inline mr-sm-3"
      :class="{
        'navbar-search-light': type === 'default',
        'navbar-search-dark': type === 'light',
      }"
      id="navbar-search-main"
    >
      <div class="form-group mb-0">
        <div class="input-group input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <input class="form-control" placeholder="Search" type="text" />
        </div>
      </div>
      <button
        type="button"
        class="close"
        data-action="search-close"
        data-target="#navbar-search-main"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </form> -->
    <h3>{{title}}</h3>
    <!-- <h5>{{description}}</h5> -->
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none" v-if="appType=='mcz'">
        <!-- Sidenav toggler -->
        <div
          v-if="false"
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <base-dropdown
        menu-classes="dropdown-menu-lg bg-secondary dropdown-menu-right"
        class="nav-item "
        tag="li"
        title-tag="a"
        title-classes="nav-link"
        icon="fa fa-video text-primary"
        v-if="appType=='mcz'"
      >
        <div class="row shortcuts px-4">
          <div class="col-12">
            ビデオミーティング
          </div>
          
          <a @click="startMyRoom"  class="col-6 shortcut-item">
            <span class="shortcut-media avatar rounded-circle bg-gradient-info">
              <i class="fas fa-broadcast-tower"></i>
            </span>
            <small>開催する</small>
          </a>
          <a @click="joinMeeting" class="col-6 shortcut-item">
            <span
              class="shortcut-media avatar rounded-circle bg-gradient-orange"
            >
              <i class="fa fa-tv"></i>
            </span>
            <small>参加する</small>
          </a>

        </div>
      </base-dropdown>
      <!-- <base-dropdown
        menu-classes="dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right"
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link"
        icon="ni ni-ungroup"
        v-if="appType=='mcz'"
      >
        <div class="row shortcuts px-4">
          <a @click="onClickTodo"  class="col-6 shortcut-item">
            <span class="shortcut-media avatar rounded-circle bg-gradient-red">
              <i class="fa fa-tasks"></i>
            </span>
            <small>ToDo</small>
          </a>
          <a @click="onClickMeeting" class="col-6 shortcut-item">
            <span
              class="shortcut-media avatar rounded-circle bg-gradient-orange"
            >
              <i class="fa fa-video"></i>
            </span>
            <small>ミーティング</small>
          </a>

        </div>
      </base-dropdown> -->
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="nav-link pr-0" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle far fa-user">
                <!-- <img alt="Image placeholder" src="img/theme/team-4.jpg" /> -->
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{userData.name}}</span>
              </div>
            </div>
          </a>
        </template>

        <!-- <div class="dropdown-header noti-title">
          <h6 class="text-overflow m-0">Welcome!</h6>
        </div> -->

        <!-- <a href="#!" class="dropdown-item">
          <i class="ni ni-single-02"></i>
          <span>My profile</span>
        </a>
        <a href="#!" class="dropdown-item">
          <i class="ni ni-settings-gear-65"></i>
          <span>Settings</span>
        </a>
        <a href="#!" class="dropdown-item">
          <i class="ni ni-calendar-grid-58"></i>
          <span>Activity</span>
        </a>
        <a href="#!" class="dropdown-item">
          <i class="ni ni-support-16"></i>
          <span>Support</span>
        </a>
        <div class="dropdown-divider"></div> -->
        <!-- <a class="dropdown-item">
          <i class="fa fa-user"></i>
          <span @click="showUser">ユーザ情報</span>
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item">
          <i class="fa fa-door-open"></i>
          <span @click="signOut">ログアウト</span>
        </a> -->
        <a class="dropdown-item" @click="showUser">
          <i class="fa fa-user"></i>ユーザ情報</a>
        <a class="dropdown-item" @click="signOut">
          <i class="fa fa-door-open"></i>ログアウト</a>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<style>
.dropdown-menu{
  z-index: 1005;
}
</style>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import { Auth } from "aws-amplify";
import UserModel from "../../appModel/users/UserModel"
import UserInfo from "../../appUtils/UserInfo";
import MeetingProvider from "@/appUtils/MeetingProvider"

export default {
  components: {
    BaseNav,
  },
  props: {
    appType: "",
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    title() {
      const metaTitle = this.$route.meta.title
      console.log(`meta title:${metaTitle}`)

      const navInfo = this.$store.state.navInfo
      console.log(`navInfo.title:${navInfo.title}`)

      if (metaTitle == navInfo.title) {
        return !metaTitle ? "" : metaTitle
      } else {
        if (metaTitle && navInfo.title) {
          return `${metaTitle} - ${navInfo.title}`
        } else if (!metaTitle) {
          return !navInfo.title ? "" : navInfo.title
        } else if (!navInfo.title) {
          return !metaTitle ? "" : metaTitle
        } else {
          return ""
        }
      }
      // return !metaTitle ? "" : metaTitle
      // const navInfo = this.$store.state.navInfo
      // console.log(`navInfo.title:${navInfo.title}`)
      // return !navInfo.title ? "" : navInfo.title
    },
    description() {
      const navInfo = this.$store.state.navInfo
      return !navInfo.description ? "" : navInfo.description
    }
  },
  mounted(){
    this.initNavbarData().then(() => {
      console.log('initNavbarData')
    })
  },
  data() {
    return {
  　  userData: {},
      userInfo: {},
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    onClickTodo() {
      // this.$refs.todolist.init()
      // let todoStore = this.$store.state.todo
      // todoStore.drawer = !todoStore.drawer
      // this.$store.commit('setTodo', todoStore)
      this.$emit('showtodolist', {})

    },
    onClickMeeting() {
      this.$router.push({
        name: 'MeetingMain'
      })
    },
    async initNavbarData() {
      // let result = await Auth.currentUserInfo()
      // alert(JSON.stringify(result,null,"\t"))
      try {

        this.userInfo = await UserInfo.getUserInfo()

        const userId = this.userInfo.userName
        console.log(`initNavbarData ${userId}`)
        const pk = this.userInfo.group
        const sk = UserModel.createSk(this.userInfo.sub)
        //alert(`pk:${pk}, sk:${sk}`)
        const userData = await UserModel.getUser({pk: pk, sk: sk})
        //alert(JSON.stringify(userData,null,"\t"))
        this.userData = userData.user
      } catch (e) {
        console.log(`ユーザ情報取得エラー`)
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    signOut: function() {
      Auth.signOut()
      this.$router.push({
        name: 'Signin'
      })
    },
    async showUser() {
      // TODO:ユーザ情報表示中だと
      const currentPath = this.$router.currentRoute._rawValue.path

      let userinfo = await UserInfo.getUserInfo()
      const userId = userinfo.userName
      let params = this.$store.state.editUserParams
      params.editUserId = userId
      params.createFlg = false
      params.fromNavbar = true

      this.$store.commit("setEditUserParams", params)

      this.$router.push({
        path: `/editusermodal`,
      });

    },
    async startMyRoom() {
      let meeting = this.$store.state.meeting
      // App.vueのWatchで監視しているプロパティを更新する
      meeting.startFlg = true
      this.$store.commit("setMeeting", meeting)

      
    }
  },
};
</script>
