<template>
  <div class="card">
    <div class="border-0 card-header">
      <ol class="breadcrumb" >
        <li class="breadcrumb-item" v-for="dir in dirList"
      :key="dir"
      style="display: inline-block">{{dir}}</li>
      </ol>
    </div>
    <div class="card-footer py-4 d-flex justify-content-end">
      <base-pagination v-model="currentPage" :total="1"></base-pagination>
    </div>
    <el-table
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      :data="fileList"
    >
    <el-table-column type="selection" align="left" min-width="120px">
      </el-table-column>
      <el-table-column label="ファイル名" min-width="310px" prop="name" sortable>
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <!-- <a href="#" class="avatar rounded-circle mr-3">
              <img alt="Image placeholder" :src="row.img" />
            </a> -->
            <span v-bind:class="row.iconClass" style="font-size:2rem;margin-right:1rem;"></span>
            <div class="media-body">
              <span class="font-weight-600 name mb-0 text-sm">
                <a href="#!" @click.prevent="onClickFile(row)">{{ row.title }}aaa</a>
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="ファイルサイズ" prop="size" min-width="140px" sortable>
      </el-table-column>

      <el-table-column min-width="180px">
        <el-dropdown trigger="click" class="dropdown">
          <span class="btn btn-sm btn-icon-only text-light">
            <i class="fas fa-ellipsis-v mt-2"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-table-column>
    </el-table>

    
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
export default {
  name: "light-table",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  props: {
    dirList:Array,
    fileList:Array
  },
  data() {
    return {
      projects:[
        {
          img: "img/theme/bootstrap.jpg",
          fileType:"jpg",
          title: "IMG_0123456789.jpg",
          size: "1,234KB",
          status: "pending",
          statusType: "warning",
          completion: 60,
        }
      ],
      currentPage: 1,
    };
  },
  methods: {
    onClickFile(data) {
      this.$emit('clicked-file',data)
    }
  }
};
</script>
