const { Analytics, AWSKinesisFirehoseProvider } = require('aws-amplify');
const DateUtil = require('./DateUtil')
Analytics.addPluggable(new AWSKinesisFirehoseProvider());
// 利用する Kinesis の情報を指定
Analytics.configure({
  AWSKinesisFirehose: {
    // リージョン
    region: 'ap-northeast-1',
    // 送信に失敗したときのリトライ上限数
    resendLimit: 5
  }
});
const streamName = process.env.VUE_APP_LOG_STREAM
const loglevelString = process.env.VUE_APP_LOG_LEVEL
const logLevelList = { "DEBUG": 0, "INFO": 1, "WARN": 2, "ERROR": 3}

class AppLog {
  /**
   * 現在のログレベルを返す
   * @returns ログレベルの整数値
   */
  static getLogLevel() {
    let level = 1
    if (loglevelString) {
      level = logLevelList[loglevelString]
    }

    if (!level) {
      return 1
    }
    return level
  }
  /**
   * ログデータを出力します(debug)
   * @param {*} view ビュー
   * @param {*} user ログインユーザーID
   * @param {*} message 内容 (画面表示にわたすパラメータ、またはエラーのメッセージ)
   */
  static debugLog(view, user, message) {
    if (this.getLogLevel() > logLevelList["DEBUG"]) {
      return
    }
    
    const time = DateUtil.dateStringDateTimeMilli()
    let data = {
      type: "[DEBUG]",
      view: view,
      user: user,
      message: message,
      time: time
    }
    
    console.log(`${JSON.stringify(data)}`)
      
    // Analytics.record({
    //   data: data,
    //   streamName: streamName
    // }, 'AWSKinesisFirehose');
  }
  /**
   * ログデータを出力します(info)
   * @param {*} view ビュー
   * @param {*} user ログインユーザーID
   * @param {*} message 内容 (画面表示にわたすパラメータ、またはエラーのメッセージ)
   */
  static infoLog(view, user, message) {
    if (this.getLogLevel() > logLevelList["INFO"]) {
      return
    }
    const time = DateUtil.dateStringDateTimeMilli()
    const data = {
      type: "[INFO]",
      view: view,
      user: user,
      message: message,
      time: time
    }
    // デバッグ時のみコンソール出力
    if (this.getLogLevel() == logLevelList["DEBUG"]) {
      console.log(`${JSON.stringify(data)}`)
    }

    Analytics.record({
      data: data,
      streamName: streamName
    }, 'AWSKinesisFirehose');
  }
  /**
   * ログデータを出力します(info)
   * @param {*} view ビュー
   * @param {*} user ログインユーザーID
   * @param {*} message 内容 (画面表示にわたすパラメータ、またはエラーのメッセージ)
   */
  static async infoLogAsync(view, user, message) {
    if (this.getLogLevel() > logLevelList["INFO"]) {
      return
    }
    const time = DateUtil.dateStringDateTimeMilli()
    const data = {
      type: "[INFO]",
      view: view,
      user: user,
      message: message,
      time: time
    }
    // デバッグ時のみコンソール出力
    if (this.getLogLevel() == logLevelList["DEBUG"]) {
      console.log(`${JSON.stringify(data)}`)
    }

    await Analytics.record({
      data: data,
      streamName: streamName
    }, 'AWSKinesisFirehose');
  }
  /**
   * ログデータを出力します(warn)
   * @param {*} view ビュー
   * @param {*} user ログインユーザーID
   * @param {*} message 内容 (画面表示にわたすパラメータ、またはエラーのメッセージ)
   */
  static warnLog(view, user, message) {
    if (this.getLogLevel() > logLevelList["WARN"]) {
      return
    }
    const time = DateUtil.dateStringDateTimeMilli()
    const data = {
      type: "[WARN]",
      view: view,
      user: user,
      message: message,
      time: time
    }
    
    // デバッグ時のみコンソール出力
    if (this.getLogLevel() == logLevelList["DEBUG"]) {
      console.log(`${JSON.stringify(data)}`)
    }

    Analytics.record({
      data: data,
      streamName: streamName
    }, 'AWSKinesisFirehose');
  }
  /**
   * ログデータを出力します(error)
   * @param {*} view ビュー
   * @param {*} user ログインユーザーID
   * @param {*} message 内容 (画面表示にわたすパラメータ、またはエラーのメッセージ)
   */
  static errLog(view, user, message) {
    if (this.getLogLevel() > logLevelList["ERROR"]) {
      return
    }
    const time = DateUtil.dateStringDateTimeMilli()
    const data = {
      type: "[ERROR]",
      view: view,
      user: user,
      message: message,
      time: time
    }
    console.log(`${JSON.stringify(data)}`)

    Analytics.record({
      data: data,
      streamName: streamName
    }, 'AWSKinesisFirehose');
  }
}
module.exports = AppLog
