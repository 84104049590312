<template>
  <div>

    <div class="container-fluid mt-1">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>

            <h3>背景図設定</h3>
            <div class="pl-lg-4">
                <base-input type="text" label="背景図名">
                    <template></template>
                    <el-input placeholder="Please input" v-model="display.backImageName"></el-input>
                </base-input>
                <base-input name="select" label="検査マスタ" v-if="model.backImageData.backImage" v-model="model.backImageData.backImage.inspectMasterList">
                  <el-select placeholder="" v-bind:disabled="!isNew"
                    v-model="model.backImageData.backImage.inspectMasterList"
                    @change="chengeInspectMaster"
                    multiple
                    filterable>
                    <el-option v-for="item in inspectMasterList"
                              class="select-primary"
                              :value="item.inspectMaster.id"
                              :label="item.inspectMaster.name"
                              :key="item.inspectMaster.name">
                    </el-option>
                  </el-select>
                </base-input>

                <!-- <div class="row"> -->
                  <!-- <div class="col-12">
                    <file-input initialLabel="背景図ファイルを選択" @change="uploadBackImage" ref="fileInput"></file-input>
                  </div> -->
<!--
                  <div class="col-6">
                    <img class="back-image-preview" v-bind:src="display.backImageFilePath" v-if="fileType==1" />
                    <div class="back-pdf-preview text-center " v-if="fileType==2" >


                      <span class="text-gray">{{display.backImageFileName}}</span>

                    </div>
                    <div class="no-image text-center align-middle"  v-if="fileType==0" >
                      <span class="text-gray">背景図未設定</span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mt-2 mb-2">
                      <el-upload
                        action=""
                        ref="upload"
                        :auto-upload="false"
                        :file-list="uploadFiles"
                        :thumbnail-mode="true"
                        :on-change="addFile"
                        :on-remove="removeFile"
                        :multiple="false"
                      >
                        <el-button size="mini" type="primary">背景図ファイルを選択</el-button>
                      </el-upload>


                    </div>
                  </div>
                </div>
                 -->

                <!-- <div class="no-image text-center align-middle" v-else>
                  <span class="text-gray">背景図未設定</span>
                </div> -->
                <!-- <div class="mt-2 mb-2">
                  <el-upload
                    action=""
                    ref="upload"
                    :auto-upload="false"
                    :file-list="uploadFiles"
                    :thumbnail-mode="true"
                    :on-change="addFile"
                    :on-remove="removeFile"
                    :multiple="false"
                  >
                    <el-button size="mini" type="primary">Click to upload</el-button>
                  </el-upload>
                   -->
                  <!-- <el-button>
                  <label for="file" class="p-0">
                      背景図を追加<input type="file" @change="upload" id="file" style="display:none;">
                  </label>
                  </el-button> -->
                <!-- </div> -->
            </div>


          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 order-xl-1">
            <card>
              <div class="row">
                <div class="col-6 text-left">
                  <base-button type="secondary" icon @click="onClickCancel">
                      <span class="btn-inner--icon"
                          ><i class="fas fa-times"></i
                      ></span>
                      <span class="btn-inner--text">キャンセル</span>
                  </base-button>
                </div>
                <div class="col-6 text-right">
                  <base-button type="primary" icon @click="onClickSave">
                      <span class="btn-inner--icon"
                          ><i class="fas fa-save"></i
                      ></span>
                      <span class="btn-inner--text">保存</span>
                  </base-button>
                </div>
              </div>
            </card>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .back-image-preview{
    /* max-width: 320px; */
    width:100%;
    height: 320px;
    border:3px dotted gray;
    object-fit: contain;
  }
  .back-pdf-preview{
    /* max-width: 320px; */
    width:100%;
    height: 320px;
    border:3px dotted gray;
    object-fit: contain;
  }
  .back-pdf-preview > div{
    max-height: 320px;
    width: 100%;
    text-align: center;
  }
  canvas {
    height: 300px;
    max-height: 300px;
    object-fit: contain;
  }
  .no-image {
    width:100%;
    height: 320px;
    border:3px dotted #999999;
  }

</style>

<script>
import { Storage } from 'aws-amplify'
import UserInfo from '../../appUtils/UserInfo';
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import FileInput from "@/components/Inputs/FileInput";
import BackImageModel from '../../appModel/BackImage/BackImageModel'
import InspectMasterModel from "@/appModel/InspectMaster/InspectMasterModel"
import mime from 'mime-types';
// import constants from "@/Config/constants"
import TenantStorage from "@/appUtils/TenantStorage"
import AppLog from "@/appUtils/AppLog"

const path = require('path')

export default {
  components:{
    DropzoneFileUpload,
    FileInput,
  },
  beforeCreate(){
      //インスタンスは生成されたがデータが初期化される前

  },
  created(){
    //インスタンスが生成され､且つデータが初期化された後
    const _this = this

    UserInfo.getUserInfo().then(result => {
      if (result.group) {
        _this.tenantId = result.group
      }
    })

  },
  beforeMount(){
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted(){
    //インスタンスが DOM 要素にマウントされた後
    this.init().then(() => {

    })
  },
  beforeUpdate(){
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount(){
    //Vue インスタンスが破壊される前
  },
  unmounted(){
    //Vue インスタンスが破壊された後

  },
  data () {
    return {
      uploadFiles: [],
      source1: "",
      // fileType:0,
      isNew: false,
      display : {
        backImageName: "",
        backImageFileName: "",
        backImageFilePath: "",
        s3Key: "",

      },
      tenantId: "",
      model: {
        backImageData: {}
      },
      inspectMasterList: null,
      tenantStorage: null
    }
  },
  watch:{
    uploadFile(newValue) {
      alert(newValue)
    },

  },
  /**
   * コンピュートプロパティ
   */
  computed:{
    fileType() {
      let ext = path.extname(this.display.backImageFileName).toLowerCase()
      if (!ext) {
        console.log(`ext nothing fileType 0`)
        return 0
      }
      switch(ext) {
        case ".jpg":
        case ".png":
        case ".gif":
          console.log(`fileType 1`)
          return 1

        case ".pdf":
          console.log(`fileType 2`)
          return 2

        default:
          console.log(`fileType 0`)
          return 0
          break
      }
    },
    currentProject() {
      return this.$store.state.project
    },
    currentNode() {
      return this.$store.state.selectedNode
    },
    projectId() {
      return this.currentProject.sk.split("#")[1]
    },
    currentNodeId() {
      return this.currentNode.id
    },
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async loadBackImageData() {
      try {

        const sk = BackImageModel.createSk(this.projectId,this.currentNodeId)
        this.model.backImageData = await BackImageModel.getBackImage({sk:sk})
        console.log(`loadBackImageData: ${JSON.stringify(this.model.backImageData,null,"\t")}`)
        if (!this.model.backImageData) {
          // 新規作成
          console.log(`新規`)
          this.isNew = true
          this.model.backImageData = await BackImageModel.getNewBackImageData(this.projectId,this.currentNodeId)
          console.log(`loadBackImageData: ${JSON.stringify(this.model.backImageData,null,"\t")}`)
          this.display.backImageName = this.currentNode.label
        } else {
          this.isNew = false

          this.display.s3Key = this.model.backImageData.backImage.backimagePath
          this.display.backImageName = this.model.backImageData.backImage.backImageName
          this.display.backImageFileName = path.basename(this.model.backImageData.backImage.backimagePath)
          this.display.backImageFilePath = await Storage.get(this.display.s3Key)
        }

        // 検査マスタ取得
        InspectMasterModel.getInspectMasterList(this.projectId).then(function(result) {
          this.inspectMasterList = result
          if (result.length > 0) {
            this.currentInspectMaster = result[0]
          }

        }.bind(this))
      }catch(e){
        alert("loadBackImageData" + e.message)
        throw e
      }
    },
    async uploadBackImage(files) {

      if (files.length > 0) {

        let loader = this.showLoader();

        try {
          const projectId = this.projectId
          const nodeId = this.currentNodeId

          if (!this.tenantId || !projectId || !nodeId) {
            alert(`パラメータ不足: ${this.tenantId}/${projectId}/${nodeId}`)
            return
          }

          // 掃除
          const tempKey = `${this.tenantId}/${projectId}/${nodeId}/temp/`
          const list = await Storage.list(tempKey)
          for (let i = 0;i < list.length;i++) {
            await Storage.remove(list[i].key)
          }
          const file = files[0]
          //this.files = files

          // S3キー名

          const s3Key = `${this.tenantId}/${projectId}/${nodeId}/temp/${file.name}`

          const fileName = file.name
          const fileBody = file.raw
          console.log(`s3Key:${s3Key}`)

          let contentType = mime.lookup(files[0].name)
          console.log(`contentType:${contentType}`)
          const option = {level: "public", contentType: contentType}

          // S3バケットにファイルを保存
          await Storage.put(s3Key, files[0], option)
          // await Storage.put(s3Key, files[0])
          //this.refresh()

          console.log(`fileType:${this.fileType}`)
          this.$nextTick(async function(){
            this.display.s3Key = s3Key
            this.display.backImageFileName = fileName
            this.display.backImageFilePath = await Storage.get(s3Key)
          }.bind(this))
          // setTimeout(async function() {
          //   this.display.s3Key = s3Key
          //   this.display.backImageFileName = fileName
          //   this.display.backImageFilePath = await Storage.get(s3Key)

          // }.bind(this), 100)

        } catch (e) {
          alert(`error ${JSON.stringify(e,null,"\t")}`)
          throw e
        } finally {
          this.hideLoader(loader);
        }
      }
    },
    async addFile(file, fileList) {

      let loader = this.showLoader();

      this.uploadFiles = fileList
      console.log(`file:${JSON.stringify(file,null,"\t")}`)
      console.log(`filelist:${JSON.stringify(fileList,null,"\t")}`)

      try {
        const projectId = this.projectId
        const nodeId = this.currentNodeId

        if (!this.tenantId || !projectId || !nodeId) {
          alert(`パラメータ不足: ${this.tenantId}/${projectId}/${nodeId}`)
          return
        }

        // 掃除
        const tempKey = `${this.tenantId}/${projectId}/${nodeId}/temp/`
        const list = await Storage.list(tempKey)
        for (let i = 0;i < list.length;i++) {
          await Storage.remove(list[i].key)
        }

        // S3キー名

        const s3Key = `${this.tenantStorage.getBaseDir()}${projectId}/${nodeId}/${file.name}`
        // ファイルサイズ
        const size = file.size
        const fileName = file.name
        const fileBody = file.raw

        let contentType = mime.lookup(file.name)
        console.log(`contentType:${contentType}`)
        //const option = {level: "public", contentType: contentType}
        const option = {"ContentType": contentType}

        try {
          // S3バケットにファイルを保存
          console.log(`s3Key:${s3Key}`)
          await this.tenantStorage.put(s3Key, fileBody, option)
          this.$nextTick(async function(){
            this.display.s3Key = s3Key
            this.display.backImageFileName = fileName
            this.display.backImageFilePath = await this.tenantStorage.get(s3Key)
          }.bind(this))
          // const result = await Storage.put(s3Key, fileBody, option)
          // .then(result => {
          //   console.log(result)
          //   this.refresh()
          //   })
          // .catch(err => console.log(err));
        } catch(e) {
          alert(`S3アップロード失敗 ${e.message}`)
          throw e
        }

        const ext = fileName.split(".")[1].toLowerCase()


        console.log(`fileType:${this.fileType}`)
        let _this = this
        // setTimeout(async function() {
        //   _this.display.s3Key = s3Key
        //   _this.display.backImageFileName = fileName
        //   _this.display.backImageFilePath = await Storage.get(s3Key)
        // }, 100)

      } catch (e) {
        alert(e.message)
        throw e
      } finally {
        this.hideLoader(loader);
      }
    },
    removeFile(file, fileList) {
      this.uploadFiles = fileList
      //alert(JSON.stringify(fileList,null,"\t"))
    },
    async init() {
      const user = await UserInfo.getUserInfo()
      this.tenantStorage = new TenantStorage(user.group)

      this.loadBackImageData().then({

      })


    },

    async upload(e) {
      try {
        console.log(`start upload`)
        var files = e.target.files || e.dataTransfer.files;
        console.log(files)

        const projectId = this.projectId
        const nodeId = this.currentNodeId

        if (!this.tenantId || !projectId || !nodeId) {
          alert(`パラメータ不足: ${this.tenantId}/${projectId}/${nodeId}`)
          return
        }

        // 掃除
        const tempKey = `${this.tenantId}/${projectId}/${nodeId}/temp/`
        const list = await Storage.list(tempKey)
        for (let i = 0;i < list.length;i++) {
          await Storage.remove(list[i].key)
        }


        // S3キー名
        const s3Key = `${this.tenantId}/${projectId}/${nodeId}/temp/${files[0].name}`
        // ファイルサイズ
        const size = files[0].size
        const fileName = files[0].name
        const fileBody = files[0]

        try {
          // S3バケットにファイルを保存
          const result = await Storage.put(s3Key, fileBody)
          .then(result => {
            console.log(result)
            this.refresh()
            })
          .catch(err => console.log(err));
        } catch(e) {
          alert(`S3アップロード失敗 ${e.message}`)
          throw e
        }

        this.display.backImageFilePath = await Storage.get(s3Key)

      } catch(e) {
        AppLog.errLog(this.$route.name, "", JSON.stringify(e))
        throw e
      }

    },
    getProjectId() {
      return this.currentProject.sk.split("#")[1]
    },
    getCurrentNodeId() {
      return this.currentNode.id
    },
    async onClickCancel() {
      this.$router.push({
        path: `/projectMain`,query:{id:`${this.projectId}`},
      })
    },
    async onClickSave(){

      let loader = this.showLoader();

      try {
        this.model.backImageData.backImage.backImageName = this.display.backImageName
        this.model.backImageData.backImage.backimagePath = this.display.s3Key
        if (this.isNew){
          await BackImageModel.addBackImage(this.model.backImageData)
        } else {
          alert(`更新`)
          await BackImageModel.updateBackImage(this.model.backImageData)
        }

        this.$router.push({
          path: `/projectMain`,query:{id:`${this.projectId}`},
        })

      } catch (e){
        alert(JSON.stringify(e.errors,null,"\t"))
        throw e
      } finally {
        this.hideLoader(loader);
      }

    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
  }
}
</script>
