const moment = require('moment-timezone')

class DateUtil {
  /**
   * TTL(Time to Live)設定値を取得します。
   *
   * @returns TTL設定値
   */
  static getTtl(){
    // 保持日数
    const ttlKeepDays = 30
    // 現在時間から指定時間後の日時
    const ttl = moment().add(ttlKeepDays, 'days').tz('Asia/Tokyo')
    return ttl.unix() // UnixTimestampを取得
  }

  /**
   * 指定されたDate文字列(UTC前提)の日本標準時での日時文字列('YYYY-MM-DD HH:mm:ss')を取得します。
   *
   * @param date 対象日付
   * @returns 日時文字列('YYYY-MM-DD HH:mm:ss')
   */
  static getLocalDateTimeString(date) {
    return moment(date).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')
  }

  static getDateString(dateString, format) {
    return this.getStringToDate(dateString).format(format)
  }

  /**
   * 指定されたDate文字列(日本標準時前提)での日時文字列('YYYY-MM-DD HH:mm:ss')を取得します。
   * @param {*} dateStringJp 
   * @param {*} format 
   * @returns 
   */
  static formatJPDateString(dateStringJp, format) {
    return moment(dateStringJp).format(format);
  }
  /**
   * 世界標準時でのISOフォーマット日付文字列を取得
   */
  static getUTCDateStringISO(){
    return moment().toISOString();
  }

  /**
   * 指定文字列を日本時間のmomentオブジェクトに変換
   * @param {*} dateString 
   */
  static getStringToDate(dateString){
    let date = moment.tz(dateString, 'Asia/Tokyo');
    return date;
  }

  /**
   * UNIXタイムスタンプ
   * @param {*} utcTimeStamp ミリ秒 
   */
  static unitxTimeToAsiaTimeString(utcTimeStamp) {
    return moment.utc(utcTimeStamp).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')
  }
}

module.exports = DateUtil;