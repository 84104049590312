<template>
  <div>
    <base-header class="pb-1" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">テナント編集</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt-1">
      <div class="row">
        <div class="col-12">
          <card>
              <h3>テナント情報</h3>
            <div class="row">
              <div class="col-12">
                <div class="pl-lg-4">
                  <base-input type="text" label="テナント名">
                    <template></template>
                    <el-input
                      placeholder="Please input"
                      v-model="tenantData.tenant.name"
                    ></el-input>
                  </base-input>
                </div>
                <div class="pl-lg-4">
                  <base-input type="text" label="テナントID">
                    <template></template>
                    <el-input
                      placeholder="Please input"
                      v-model="tenantData.tenant.tenantId"
                    ></el-input>
                  </base-input>
                </div>
                <div class="pl-lg-4">
                  <base-input
                    type="text"
                    label="契約開始日"
                    addon-left-icon="ni ni-calendar-grid-58"
                  >
                    <template v-slot="{ focus, blur }">
                      <flat-picker
                        @on-open="focus"
                        @on-close="blur"
                        :config="{ allowInput: true }"
                        class="form-control datepicker"
                        v-model="tenantData.tenant.contractStart"
                      >
                      </flat-picker>
                    </template>
                    <!-- <el-input placeholder="Please input" v-model="tenantData.tenant.contractStart"></el-input> -->
                  </base-input>
                </div>
                <div class="pl-lg-4">
                  <base-input
                    name="date1"
                    label="契約終了日"
                    addon-left-icon="ni ni-calendar-grid-58"
                  >
                    <template v-slot="{ focus, blur }">
                      <flat-picker
                        @on-open="focus"
                        @on-close="blur"
                        :config="{ allowInput: true }"
                        class="form-control datepicker"
                        v-model="tenantData.tenant.contractEnd"
                      >
                      </flat-picker>
                    </template>
                  </base-input>
                  <!-- <el-input placeholder="Please input" v-model="tenantData.tenant.name"></el-input> -->
                </div>
              </div>
              <div class="col-12">
                <div class="pl-lg-4">
                  <base-input type="text" label="容量上限">
                    <template></template>
                    <el-input-number
                      v-model="tenantData.tenant.maxCapacity"
                      @change="handleChange"
                      :min="0"
                      :max="100"
                    ></el-input-number>
                    <!-- <el-input placeholder="Please input" v-model="tenantData.tenant.maxCapacity"></el-input> -->
                  </base-input>
                </div>
                <div class="pl-lg-4">
                  <base-input type="text" label="プロジェクト数上限">
                    <template></template>
                    <el-input-number
                      v-model="tenantData.tenant.maxProjects"
                      @change="handleChange"
                      :min="0"
                      :max="100"
                    ></el-input-number>
                    <!-- <el-input placeholder="Please input" v-model="tenantData.tenant.maxProjects"></el-input> -->
                  </base-input>
                </div>
                <div class="pl-lg-4">
                  <base-input type="text" label="ファイル数上限">
                    <template></template>
                    <el-input-number
                      v-model="tenantData.tenant.maxFiles"
                      @change="handleChange"
                      :min="0"
                      :max="10000"
                    ></el-input-number>
                    <!-- <el-input placeholder="Please input" v-model="tenantData.tenant.maxFiles"></el-input> -->
                  </base-input>
                </div>
                <div class="pl-lg-4">
                  <base-input type="text" label="ユーザ数上限">
                    <template></template>
                    <el-input-number
                      v-model="tenantData.tenant.maxUsers"
                      @change="handleChange"
                      :min="0"
                      :max="50"
                    ></el-input-number>
                    <!-- <el-input placeholder="Please input" v-model="tenantData.tenant.maxUsers"></el-input> -->
                  </base-input>
                </div>
              </div>
            </div>
          </card>
        </div>
        <div class="col-12" v-if="isNewData">
          <card>
            <h3>利用可能システム</h3>
            <div class="pl-lg-4">
              <base-checkbox class="mb-3" v-model="mczoption">
                  MCZ
              </base-checkbox>
              <base-checkbox class="mb-3" v-model="rebaroption">
                  配筋検査システム
              </base-checkbox>
            </div>
          </card>
        </div>
        <div class="col-12" v-if="isNewData">
          <card>
            <h3>管理者ユーザ情報</h3>
            <div class="pl-lg-4">
              <base-input name="date1" label="管理者ユーザID">
                <template></template>
                <el-input
                  placeholder="Please input"
                  v-model="userData.user.userId"
                >
                </el-input>
              </base-input>
              <!-- <el-input placeholder="Please input" v-model="tenantData.tenant.name"></el-input> -->
            </div>
            <div class="pl-lg-4">
              <base-input name="date1" label="管理者ユーザ名">
                <template></template>
                <el-input
                  placeholder="Please input"
                  v-model="userData.user.name"
                ></el-input>
              </base-input>
              <!-- <el-input placeholder="Please input" v-model="tenantData.tenant.name"></el-input> -->
            </div>
            <div class="pl-lg-4">
              <base-input name="date1" label="パスワード">
                <template></template>
                <el-input
                  placeholder="Please input"
                  v-model="password"
                ></el-input>
              </base-input>
              <!-- <el-input placeholder="Please input" v-model="tenantData.tenant.name"></el-input> -->
            </div>

            <div class="pl-lg-4">
              <base-input name="date1" label="メールアドレス">
                <template></template>
                <el-input
                  placeholder="Please input"
                  v-model="userData.user.email"
                ></el-input>
              </base-input>
              <!-- <el-input placeholder="Please input" v-model="tenantData.tenant.name"></el-input> -->
            </div>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <base-button block type="primary" @click="onClickSave"
              >保存</base-button
            >
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import flatPicker from "vue-flatpickr-component";
import TenantModel from "../../appModel/Tenant/TenantModel";
import UserModel from "../../appModel/users/UserModel";
import API from "@aws-amplify/api";

export default {
  components: {
    flatPicker,
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init();
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  data() {
    return {
      tenantData: {
        tenant: {},
      },
      userData: {
        user: {},
      },
      mczoption: false,
      rebaroption: false,
      password: "",
      isNewData: true,
    };
  },
  watch: {},
  /**
   * コンピュートプロパティ
   */
  computed: {},
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {
      try {
        let tenantId = this.$store.state.tenant.tenantId;
        if (tenantId) {
          // データ取得
          this.isNewData = false;
          console.log(`更新`);
          this.tenantData = await TenantModel.getSingleData(tenantId);
        } else {
          // 新規
          this.isNewData = true;
          console.log(`追加`);
          this.tenantData = await TenantModel.getNewData();
          this.userData = await UserModel.getNewData();
          this.userData.user.usertype = 1;
        }
        console.log(JSON.stringify(this.tenantData, null, "\t"));
      } catch (e) {
        console.log(`error ${e.message}`);
      }
    },
    /**
     * 保存ボタン押下
     */
    async onClickSave() {
      
      console.log(this.tenantData, null, "\t");

      let availableOptions = [this.mczoption ? "MCZ" : "", this.rebaroption ? "REBAR" : "" ]
      this.tenantData.tenant.availableOptions = availableOptions

      if (!this.isNewData) {
        // 更新
        console.log(`更新`);
        await TenantModel.updateTenant(this.tenantData);
      } else {
        // 追加
        console.log(`追加`);
        // キーをセット
        this.tenantData.sk = TenantModel.createSk(this.tenantData.tenant.tenantId);
        // テナントのユーザとなるためpkはテナントIDをセット
        this.userData.pk = this.tenantData.tenant.tenantId
        this.userData.sk = UserModel.createSk(this.userData.user.userId);

        // groupにテナントIDを追加する
        this.tenantData.groups.push(this.tenantData.tenant.tenantId);
        this.userData.groups.push(this.tenantData.tenant.tenantId);

        console.log(JSON.stringify(this.tenantData, null, "\t"));
        console.log(JSON.stringify(this.userData, null, "\t"));

        try {
          await TenantModel.addTenant(this.tenantData);
        } catch (e) {
          console.log(`テナント追加エラー`);
          throw e;
        }

        

        if (
          !this.userData.user.name ||
          !this.password ||
          !this.userData.user.userId ||
          !this.userData.user.email
        ) {
          alert(`未入力`);
          return;
        }
        // REST-APIを呼び出し
        const APIName = "mczusermanager";
        const path = "/user";
        const myInit = {
          headers: {},
          response: true,
          body: {
            username: this.userData.user.userId,
            password: this.password,
            groupName: this.tenantData.tenant.tenantId,
            attributes: [
              { Name: "email", Value: this.userData.user.email },
              { Name: "name", Value: this.userData.user.name },
              { Name: "email_verified", Value: "true" },
            ],
          },
        };

        const response = await API.post(APIName, path, myInit);
        console.log(`mczusermanager API Response: ${JSON.stringify(response, null, "\t")}`)
        const data = response.data;
        if (data.success != "") {
          const errorInfo = JSON.parse(data.message)
          switch (errorInfo.code) {
          case "UsernameExistsException":
            throw 'すでに登録済みのメールアドレスです。'
            
          default:
            throw `${errorInfo.code} ${errorInfo.message}`
          }
        }
       
        this.userData.user.userId = data.sub
        this.userData.sk = UserModel.createSk(this.userData.user.userId);

        try {
          await UserModel.addUser(this.userData);
        } catch (e) {
          console.log(`管理者追加エラー`);
          await TenantModel.deleteTenant(this.tenantData);
          throw e;
        }
      }

      this.$router.push({
        name: "TenantManage",
      });
    },
  },
};
</script>
