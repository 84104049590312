<template>
  <div id="horizonal-toolbar" class="horizonal-toolbar col-12 align-items-center" role="toolbar">
    <div class="row">
      <!-- <div class="col-12"> -->
      <div class="col-xs-auto mr-4">
        <h4 class="mt-1 mb-0">{{title}}</h4>
        <!-- <h4 class="mt-1 mb-0">検査ステータス</h4> -->
      </div>
      <div v-if="!isSingleSelect && !isMultiSelect && isAdd" id="no-select-menu" class="col-xs-auto inline-block">
        <el-tooltip class="item" effect="dark" v-bind:content="addTitle" placement="bottom">
          <el-button size="medium" circle @click="onClickAddNew">
            <i class="fas fa-plus"></i>
          </el-button>
        </el-tooltip>
        <!--
        <div v-if="!isSingleSelectStatus && !isMultiSelectStatus" id="no-select-hasData-menu" class="col-md-auto inline-block">
          <el-tooltip class="item" effect="dark" content="上に移動" placement="bottom">
            <el-button v-bind:disabled="processing || disableMoveUp" size="medium" circle @click="onClickRowMove('up')">
              <i class="fas fa-arrow-up"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="下に移動" placement="bottom">
            <el-button v-bind:disabled="processing || disableMoveDown" size="medium" circle @click="onClickRowMove('down')">
              <i class="fas fa-arrow-down"></i>
            </el-button>
          </el-tooltip>
        </div>
          -->
      </div>
      <div v-if="isSingleSelect" id="single-select-menu" class="col-xs-auto inline-block">
        <div v-if="isDelete" id="export-menu" class="inline-block">
          <el-tooltip class="item" effect="dark" v-bind:content="deleteTitle" placement="bottom">
            <el-button size="medium" circle icon="el-icon-delete" style="font-size:1.2em;" @click="onDeleteRows()">
            </el-button>
          </el-tooltip>
        </div>
        <div v-if="isEdit" id="export-menu" class="inline-block">
          <el-tooltip class="item" effect="dark" v-bind:content="editTitle" placement="bottom">
            <el-button size="medium" circle @click.prevent="onClickEdit">
              <i class="fas fa-pen"></i>
            </el-button>
          </el-tooltip>
        </div>
        <div v-if="isCopy" id="export-menu" class="inline-block">
          <el-tooltip class="item" effect="dark" v-bind:content="copyTitle" placement="bottom">
            <el-button size="medium" circle @click="onClickCopy">
              <i class="far fa-clone"></i>
            </el-button>
          </el-tooltip>
        </div>
        <div v-if="isSingleSelect && isOrderMove" id="single-select-hasData-menu" class="inline-block">
          <el-tooltip class="item" effect="dark" content="上に移動" placement="bottom">
            <el-button v-bind:disabled="processing || disableMoveUp" size="medium" circle @click="onClickRowMove('up')">
              <i class="fas fa-arrow-up"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="下に移動" placement="bottom">
            <el-button v-bind:disabled="processing || disableMoveDown" size="medium" circle @click="onClickRowMove('down')">
              <i class="fas fa-arrow-down"></i>
            </el-button>
          </el-tooltip>
        </div>
        <div v-if="isExport" id="export-menu" class="ml-3 inline-block">
          <el-tooltip class="item" effect="dark" v-bind:content="exportTitle" placement="bottom">
            <el-button size="medium" circle @click="onClickExport">
              <i class="fas fa-file-export"></i>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <div v-if="isMultiSelect" id="multi-select-menu" class="col-xs-auto inline-block">
        <el-tooltip class="item" effect="dark" v-bind:content="deleteTitle" placement="bottom">
          <el-button size="medium" circle icon="el-icon-delete" style="font-size:1.2em;" @click="onDeleteRows()">
          </el-button>
        </el-tooltip>
        <div v-if="isExport" id="export-menu" class="ml-3 inline-block">
          <el-tooltip class="item" effect="dark" v-bind:content="exportTitle" placement="bottom">
            <el-button size="medium" circle @click="onClickExport">
              <i class="fas fa-file-export"></i>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>
<style scoped>
  .el-button {
    border-style: none;
  }

  .inline-block {
    position: relative;
    display: inline-block;
    font-size: 14px;
  }

  .horizonal-toolbar {
    left: 0;
    height: 24px;
    width: 100%;
    text-align: left;
    font-size: 0;
  }

  .horizonal-toolbar.base-toolbar {
    /* background-color: #ffffff; */
    box-sizing: border-box;
    padding: 0px 24px 0px 21px;
    z-index: 1200;
    /* height: 48px; */
  }

  .base-toolbar {
    /* background-color: #f5f5f5 */
    z-index: 1001;
  }
</style>
<script>
import Maintenance from '../../Maintenance.vue';

export default {
  components: { Maintenance },
  props: {
    /**
     * メニュー左に表示するタイトル
     */
    title: {
      type: String,
      default: "",
    },
    /**
     * 新規追加ボタンのツールチップに表示するタイトル
     */
    addTitle: {
      type: String,
      default: "新規作成",
    },
    /**
     * 編集ボタンのツールチップに表示するタイトル
     */
    editTitle: {
      type: String,
      default: "編集",
    },
    /**
     * コピーボタンのツールチップに表示するタイトル
     */
    copyTitle: {
      type: String,
      default: "コピー",
    },
    /**
     * 削除ボタンのツールチップに表示するタイトル
     */
    deleteTitle: {
      type: String,
      default: "削除",
    },
    /**
     * 帳票出力ボタンのツールチップに表示するタイトル
     */
    exportTitle: {
      type: String,
      default: "帳票出力",
    },
    /**
     * 新規追加表示フラグ
     */
    isAdd: {
      type: Boolean,
      default: true,
    },
    /**
     * 編集表示フラグ
     */
    isEdit: {
      type: Boolean,
      default: true,
    },
    /**
     * コピー表示フラグ
     */
    isCopy: {
      type: Boolean,
      default: true,
    },
    /**
     * 削除表示フラグ
     */
    isDelete: {
      type: Boolean,
      default: true,
    },
    /**
     * 単一選択フラグ
     */
    isSingleSelect: {
      type: Boolean,
      default: false,
    },
    /**
     * 複数選択フラグ
     */
    isMultiSelect: {
      type: Boolean,
      default: false,
    },
    /**
     * 行移動表示フラグ
     */
    isOrderMove: {
      type: Boolean,
      default: false,
    },
    /**
     * 帳票出力ボタンを表示するかどうか
     */
    isExport: {
      type: Boolean,
      default: false,
    },
    /**
     * 処理中かどうか
     */
    processing: {
      type: Boolean,
      default: false,
    },
    /**
     * 上へ移動ボタンを非表示にするかどうか
     */
    disableMoveDown: {
      type: Boolean,
      default: false,
    },
    /**
     * 下へ移動ボタンを非表示にするかどうか
     */
    disableMoveUp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // title: "",
      // isSingleSelect: false,
      // isMultiSelect: false,
      // processing: false,
    }
  },
  /** ライフサクルメソッド */
  beforeCreate(){
    //インスタンスは生成されたがデータが初期化される前
  },
  created(){
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount(){
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted(){
    //インスタンスが DOM 要素にマウントされた後
    console.log(`mounted`)
  },
  beforeUpdate(){
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  methods: {
    onClickAddNew() {
      this.$emit("addNew");
    },
    onClickExport() {
      this.$emit("export");
    },
    onDeleteRows() {
      this.$emit("delete", this.title);
    },
    onClickEdit() {
      this.$emit("edit");
    },
    onClickCopy() {
      this.$emit("copy");
    },
    onClickRowMove(direction) {
      this.$emit("moveRow", direction);
    },
  }
}
</script>
