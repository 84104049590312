<template>
  <div class="map">
    <div class="mt-1 d-flex flex-column" style="height: 100%">
    <!-- <div class="container-fluid mt-1 d-flex flex-column" style="height: 100%"> -->
      <div class="row clearfix no-gutters" style="height: 48px">
        <div id="map-horizonal-toolbar" class="map-horizonal-toolbar col-8 align-items-center" role="toolbar">
          <div class="row">
            <div v-bind:disabled="drawShape" id="draw-shape-menu-line" class="col-md-auto inline-block">
            <!-- <div v-show="drawLine" id="draw-shape-menu-line" class="col-md-auto inline-block"> -->
              <div class="row alien-items-flex-start">
                <div class="toolbar-separator inline-block" role="separator"></div>
                <!-- 線幅選択 -->
                <!-- <base-dropdown name="select" label="線の幅" v-bind:style="{ color: drawStyle.line.color }" v-model="drawStyle.line">
                  <el-select placeholder="" v-model="drawStyle.line">
                    <el-option :value="{ width: 4 }" style="height: 4px" class="select-danger">4
                      <div></div>
                    </el-option>
                    <el-option :value="{ width: 8 }" style="height: 8px" class="select-danger">8</el-option>
                    <el-option :value="{ width: 12 }" style="height: 12px" class="select-danger">12</el-option>
                    <el-option :value="{ width: 16 }" style="height: 16px" class="select-danger">16</el-option>
                  </el-select>
                </base-dropdown> -->

                <base-dropdown
                  menu-classes="dropdown-menu dropdown-menu-dark bg-white"
                  class="nav-item base-toolbar"
                  v-show="drawNone"
                  v-bind:class="{'disabled': drawShape}"
                  tag="li"
                  title-tag="a"
                  title-classes="nav-link"
                  icon="fas fa-window-minimize"
                >
                  <div v-show="drawNone" v-bind:disabled="drawShape" class="row shortcuts px-4">
                    <a @click="handerleInputLineWidth(4)" class="col-6 shortcut-item text-dark">
                      <span class="shortcut-media avatar bg-gradient-white text-dark"
                      style="padding-bottom: 8px">
                        <i class="fas fa-window-minimize"></i>
                      </span>
                      <small>細線</small>
                    </a>
                    <a @click="handerleInputLineWidth(8)" class="col-6 shortcut-item text-dark">
                      <span
                        class="shortcut-media avatar bg-gradient-white text-dark"
                        style="padding-bottom: 16px">
                        <i class="fas fa-window-minimize fa-2x"></i>
                      </span>
                      <small>標準</small>
                    </a>
                    <a @click="handerleInputLineWidth(16)" class="col-6 shortcut-item text-dark">
                      <span
                        class="shortcut-media avatar bg-gradient-white text-dark cover"
                        style="padding-bottom: 36px">
                        <i class="fas fa-window-minimize fa-4x"></i>
                      </span>
                      <small>太線</small>
                    </a>

                  </div>
                </base-dropdown>
                <div
                  disabled
                  v-show="!drawNone"
                  class="toolbar-menu-button col-md-auto inline-block">
                  <i class="fas fa-window-minimize disabled"></i>
                </div>
                <!-- <div id="v-model-select-stroke-width" class="toolbar-menu-button col-md-auto inline-block">
                  <select v-model="selectedStrokeWidth" style="font-family: FontAwesome">
                    <option class="">&#xf068;</option>
                    <option>        </option>
                    <option><i class="fas fa-window-minimize fa-3x"></i>        </option>
                    <option><i class="fas fa-window-minimize fa-4x"></i>        </option>
                    <option><i class="fas fa-window-minimize fa-5x"></i>        </option>
                  </select>
                </div> -->
                <!-- <div id="strokeWidthButton" class="toolbar-menu-button col-md-auto inline-block" role="button" aria-disabled="false" aria-expanded="false" aria-haspopup="true" aria-hidden="false" data-tooltip="枠線の色">
                  <i class="fas fa-window-minimize"></i>
                </div> -->
                <!-- 線色指定 -->
                <el-dropdown
                  v-bind:disabled="drawShape" class="dropdown" trigger="click">
                  <span
                    class="btn btn-md btn-icon-only"
                    v-bind:style="{ color: drawStyle.line.color }"
                    v-bind:class="{ 'dropdown-toggle': !drawShape }"
                    >
                    <i class="fa fa-pen pt-2"></i>
                  </span>
                  <template #dropdown>
                    <div class="row" style="width: 140px;">
                      <el-dropdown-menu
                        v-for="(color, index) in selectColors"
                        :key="index"
                        style="display: inline-block;"
                      >
                        <li class="col-xs ml-1 mr-1" style="display: inline-block;">
                          <span
                            class="btn btn-sm round"
                            style="border-radius: 50%; width:16px; height:16px;"
                            v-bind:style="{ backgroundColor: color.hex }"
                            @click.prevent="selectedLineColor(color)">
                          </span>
                        </li>
                      </el-dropdown-menu>
                    </div>
                  </template>
                </el-dropdown>
                <!--
                <div id="strokeColorButton" v-bind:style="{ color: drawStyle.line.color }" class="toolbar-menu-button col-md-auto inline-block" role="button" aria-disabled="false" aria-expanded="false" aria-haspopup="true" aria-hidden="false" data-tooltip="枠線の色">
                  <i class="fa fa-pen"></i>
                  <input type="color" id="strokeColorInput" v-bind:disabled="drawShape" v-model="drawStyle.line.color" v-on:change="handerleInputLineColor">
                </div>
                 -->
                <div class="toolbar-separator inline-block" role="separator"></div>
              </div>
            </div>
            <div v-bind:disabled="drawShape" id="draw-shape-menu-fill" class="col-md-auto inline-block">
            <!-- <div v-show="drawFill" id="draw-shape-menu-fill" class="col-md-auto inline-block"> -->
              <div class="row alien-items-center">
                <el-dropdown
                  v-bind:disabled="drawShape" class="dropdown" trigger="click">
                  <span
                    class="btn btn-md btn-icon-only"
                    v-bind:style="{ color: drawStyle.fill.color }"
                    v-bind:class="{ 'dropdown-toggle': !drawShape }"
                    >
                    <i class="fa fa-fill-drip pt-2"></i>
                  </span>
                  <template #dropdown>
                    <div class="row" style="width: 140px;">
                      <el-dropdown-menu
                        v-for="(color, index) in selectColors"
                        :key="index"
                        style="display: inline-block;"
                      >
                        <li class="col-xs ml-1 mr-1" style="display: inline-block;">
                          <span
                            class="btn btn-sm round"
                            style="border-radius: 50%; width:16px; height:16px;"
                            v-bind:style="{ backgroundColor: color.hex }"
                            @click.prevent="selectedFillColor(color)">
                          </span>
                        </li>
                      </el-dropdown-menu>
                    </div>
                  </template>
                </el-dropdown>
                <!--
                <div id="fillColorButton" v-bind:style="{ color: drawStyle.fill.color }" class="toolbar-menu-button col-md-auto inline-block" role="button" aria-disabled="false" aria-expanded="false" aria-haspopup="true" aria-hidden="false" data-tooltip="枠線の色">
                  <i class="fa fa-fill-drip"></i>
                  <input type="color" id="fillColorInput" v-bind:disabled="drawShape" v-model="drawStyle.fill.color" v-on:change="handerleInputFillColor">
                </div>
                 -->
                <div class="toolbar-separator inline-block" role="separator"></div>
              </div>
            </div>
            <div v-bind:disabled="drawShape" id="draw-shape-menu-text" class="col-md-auto inline-block">
            <!-- <div v-show="drawText" id="draw-shape-menu-text" class="col-md-auto inline-block"> -->
              <div class="row alien-items-center">
                <!-- <div class="toolbar-separator inline-block" role="separator"></div> -->
                <div id="v-model-select-text-size" class="toolbar-menu-button col-md-auto inline-block">
                  <select v-bind:disabled="drawShape" v-model="drawStyle.text" v-on:change="handerleInputTextSize">
                    <option :value="{ size: 36, color: drawStyle.text.color }" class="text-size-xlarge">タイトル</option>
                    <option :value="{ size: 24, color: drawStyle.text.color }" class="text-size-large">サブタイトル</option>
                    <option :value="{ size: 18, color: drawStyle.text.color }" class="text-size-medium">見出し</option>
                    <option :value="{ size: 14, color: drawStyle.text.color }" class="text-size-small">標準</option>
                  </select>
                </div>
                <el-dropdown
                  v-bind:disabled="drawShape" class="dropdown" trigger="click">
                  <span
                    class="btn btn-md btn-icon-only"
                    v-bind:style="{ color: drawStyle.text.color }"
                    v-bind:class="{ 'dropdown-toggle': !drawShape }"
                    >
                    <i class="fa fa-font pt-2"></i>
                  </span>
                  <template #dropdown>
                    <div class="row" style="width: 140px;">
                      <el-dropdown-menu
                        v-for="(color, index) in selectColors"
                        :key="index"
                        style="display: inline-block;"
                      >
                        <li class="col-xs ml-1 mr-1" style="display: inline-block;">
                          <span
                            class="btn btn-sm round"
                            style="border-radius: 50%; width:16px; height:16px;"
                            v-bind:style="{ backgroundColor: color.hex }"
                            @click.prevent="selectedTextColor(color)">
                          </span>
                        </li>
                      </el-dropdown-menu>
                    </div>
                  </template>
                </el-dropdown>
                <!--
                <div id="textColorButton" v-bind:style="{ color: drawStyle.text.color }" class="toolbar-menu-button col-md-auto inline-block" role="button" aria-disabled="false" aria-expanded="false" aria-haspopup="true" aria-hidden="false" data-tooltip="枠線の色">
                  <i class="fa fa-font"> </i>
                  <input type="color" id="textColorInput" v-bind:disabled="drawShape" v-model="drawStyle.text.color" v-on:change="handerleInputTextColor">
                </div> -->

                <div class="toolbar-separator inline-block" role="separator"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 clearfix">
          <!-- <base-dropdown v-show="drawNone" title-classes="btn btn-secondary" menuClasses="zindex-tooltip" class="float-right base-toolbar">
            <template v-slot:title>
              <i class="fa fa-map-pin"></i>検査箇所
            </template>
            <a class="dropdown-item" @click="handlerMenuAddInpectPlace" ><i class="fa fa-plus text-primary"></i>新規追加</a>
            <a class="dropdown-item" @click="handlerMenuMoveInpectPlace" ><i class="fa fa-share text-primary"></i>移動</a>
            <a class="dropdown-item" @click="handlerMenuDeleteInpectPlace" ><i class="fa fa-trash text-primary"></i>削除</a>
          </base-dropdown> -->
          <base-dropdown round v-show="drawNone" title-classes="btn btn-primary" menuClasses="zindex-tooltip" class="float-right base-toolbar" direction="down" menuOnRight="true">
            <template v-slot:title>
              <span class="btn-inner--icon">
                <i class="fa fa-plus">検査箇所</i>
              </span>
            </template>
            <a class="dropdown-item" @click="handlerMenuAddInpectPlace" ><i class="fa fa-plus text-primary"></i>新規追加</a>
            <a class="dropdown-item" @click="handlerMenuMoveInpectPlace" ><i class="fa fa-share text-primary"></i>移動</a>
            <a class="dropdown-item" @click="handlerMenuDeleteInpectPlace" ><i class="fa fa-trash text-primary"></i>削除</a>
          </base-dropdown>

          </div>
      </div>
      <div class="row map no-gutters">
        <div class="col-xl-12 col-md-12 map">
          <div class="card border-0 map">
              <div class="mapUi">
                <!-- <el-input placeholder="検査箇所名で検索"  v-model="input1">
                  <template #prepend>検索</template>
                </el-input> -->

                <el-input
                  placeholder="キーワードを入力"
                  v-model="searchWord"
                  class="input-with-select map-search-input"
                >
                  <template #prepend>
                    <el-select v-model="searchTarget" class="map-search-select" placeholder="対象を選択">
                      <el-option label="検査箇所No" value="1" key="1"></el-option>
                      <el-option label="検査箇所名" value="2" key="2"></el-option>
                      <el-option label="検査者" value="3" key="3"></el-option>
                    </el-select>
                  </template>
                  <template #append>
                    <el-button icon="el-icon-search"></el-button>
                  </template>
                </el-input>
              </div>

              <!-- <div class="mapFunctionUi">
                <base-dropdown round v-show="drawNone" title-classes="btn btn-primary" menuClasses="zindex-tooltip" class="float-right base-toolbar" direction="down" menuOnRight="true">
                  <template v-slot:title>
                    <span class="btn-inner--icon">
                      <i class="fa fa-plus">検査箇所</i>
                    </span>
                  </template>
                  <a class="dropdown-item" @click="handlerMenuAddInpectPlace" ><i class="fa fa-plus text-primary"></i>新規追加</a>
                  <a class="dropdown-item" @click="handlerMenuMoveInpectPlace" ><i class="fa fa-share text-primary"></i>移動</a>
                  <a class="dropdown-item" @click="handlerMenuDeleteInpectPlace" ><i class="fa fa-trash text-primary"></i>削除</a>
                </base-dropdown>
              </div> -->
              
            <!-- <div id="map-container"> -->
              <div id="map" class="map">
                <base-button v-if="fileType===2"
                  class="btn-white btn-icon-only overmap-bottom-right leaflet-bar leaflet-touch"
                  size="sm" @click="moveCurrentLocation"
                  >
                  <span class="btn-inner--icon"
                      ><i class="far fa-compass fa-2x"></i
                  ></span>
                </base-button>
              </div>
              <!-- <div id="map" class="map-canvas" style="height: 600px"></div> -->
              <!-- <div id="map" class="map-canvas" style="height: 600px"></div> -->
            <!-- </div> -->
            <modal v-model:show="modal.isShow">
              <template v-slot:header>
                  <h5 class="modal-title" id="modalLabel">{{modal.title}}</h5>
              </template>
              <div>
                {{modal.message}}
              </div>
              <template v-slot:footer>
                  <base-button v-model:type="modal.cancelType" @click="modal.cancelCallBack">{{modal.cancelLabel}}</base-button>
                  <base-button v-show="modal.isRetry" v-model:type="modal.retryType" @click="modal.retryCallBack">{{modal.retryLabel}}</base-button>
                  <base-button v-model:type="modal.okType" @click="modal.okCallBack">{{modal.okLabel}}</base-button>
              </template>
            </modal>
            <!-- 手書き作図文字入力ダイアログ -->
            <modal v-model:show="modalInput.isShow">
              <p>{{modalInput.message}}</p>
              <form role="form">
                <el-input
                  alternative
                  v-model="modalInput.inputValue"
                  class="mb-3"
                  placeholder=""
                >
                </el-input>
              </form>
              <template v-slot:footer>
                  <base-button v-model:type="modalInput.cancelType" @click="modalInput.cancelCallBack">{{modalInput.cancelLabel}}</base-button>
                  <base-button v-show="modalInput.isRetry" v-model:type="modalInput.retryType" @click="modalInput.retryCallBack">{{modalInput.retryLabel}}</base-button>
                  <base-button v-model:type="modalInput.okType" @click="modalInput.okCallBack">{{modalInput.okLabel}}</base-button>
              </template>
            </modal>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-xl-12 col-md-12">
          <card>
            <template v-slot:footer>
              <h3>S3キー</h3>
              <span class="">{{ backImage.backimagePath }}</span><br />

              <hr />
            </template>
          </card>
        </div>
      </div> -->
    </div>
  </div>
</template>
<style>
  .mapUi .el-select .el-input {
    width: 150px;
    color: #333333;
  }
  .mapUi .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
</style>
<style scoped>
  
  .mapUi{
    left:60px;
    top:10px;
    position: absolute !important;
    z-index: 401 !important;
  }
  .mapFunctionUi{
    right:60px;
    top:10px;
    position: absolute !important;
    z-index: 401 !important;
  }
  .overmap-bottom-right {
    position:fixed;
    right:32px;
    bottom:24px;
    width: 32px;
    height: 32px;
    padding: 0px 0px 0px 0px;
    z-index: 1001;
  }

  .disabled {
    color:#999999;
  }

  .clearfix::after {
    content: "";
    display: block;
    clear: both;
  }

  .cover {
    object-fit: cover;
  }

  .storke-width-2px {
    font-family: "FontAwesome";
    font-size: 14px;
  }

  .storke-width-2px:before,
  .storke-width-2px::after {
    content: "";
    flex-grow: 1;
    height: 2px;
    background: #000;
    margin: 0 0;
  }

  .text-size-xlarge {
    font-size: 36px;
  }

  .text-size-large {
    font-size: 24px;
  }

  .text-size-medium {
    font-size: 18px;
  }

  .text-size-small {
    font-size: 14px;
  }

  .toolbar-menu-button {
    font-size: 14px;
    letter-spacing: 0.2px;
    font-weight: 400;
    height: 30px;
    margin: 5px 3px;
    color: #3c4043;
  }

  .toolbar-separator {
    border-left: 1px solid #dadce0;
    height: 26px;
    margin: 8px 6px;
  }

  .inline-block {
    position: relative;
    display: inline-block;
    font-size: 14px;
  }

  .map-horizonal-toolbar {
    left: 0;
    height: 44px;
    width: 100%;
    text-align: left;
    font-size: 0;
  }

  .map-horizonal-toolbar.base-toolbar {
    /* background-color: #ffffff; */
    box-sizing: border-box;
    padding: 0px 24px 0px 21px;
    z-index: 1200;
    /* height: 48px; */
  }

  .base-toolbar {
    /* background-color: #f5f5f5 */
    z-index: 1001;
  }

  .map-container {
    z-index: 0;
    height: 100%;
    /* height: 100vh; */
    /* height: 500px;
    width: 500px; */
    text-align: left;
  }

  .map {
    /* width: 500px;
    height: 500px; */

     width: 100%;
     height: 100%;

  }

  </style>
<script>

import { Storage } from "aws-amplify";
import Modal from "@/components/Modal";
import BackImageModel from "../../appModel/BackImage/BackImageModel";
import ShapeModel from "../../appModel/BackImage/ShapeModel";
import InspectMasterModel from "../../appModel/InspectMaster/InspectMasterModel";
import InspectPlaceModel from "../../appModel/InspectPlace/InspectPlaceModel";
import UserInfo from '../../appUtils/UserInfo';
const path = require('path')
import DateUtil from '../../appUtils/DateUtil'
import mime from 'mime-types';
import TenantStorage from "@/appUtils/TenantStorage"
import FileUtil from "@/appUtils/FileUtil"

// data()内に保持するとLeafletの挙動がおかしくなります。
let mlMap = null;

// メニュー
const InspectPlaceMenu = {
  None: {
    val: 0,
    text: ""
  },
  Add: {
    val: 1,
    text: "新規追加"
  },
  MoveStart: {
    val: 2,
    text: "移動開始"
  },
  MoveEnd: {
    val: 3,
    text: "移動終了"
  },
  Delete: {
    val: 4,
    text: "削除"
  }
}

let inspectMenu = null;

// 仮ID用のカウンタ
let tempIdCounter = 0;

// 検査箇所レイヤ名（仮)
// const poiLayerName = '検査箇所';

//test
// let isReload = false;

// 対象POIのID
// let targetPoiId = null;

export default {
  /**
   * コンポーネント
   */
  components: {
    Modal,
  },
  /**
   * プロパティ
   */
  props: {
    /**
     * プロジェクトID
     */
    projectId: {
      type: String,
      default: "2F47bBSU4tyrFDnqhmuMGA",
    },
    /**
     * ノードID
     */
    nodeId: {
      type: String,
      default: "mSRe8zwA4r8N9NkMpH21Zw",
    },
    /**
     * 背景図種別
     */
    fileType: {
      type: Number,
      default: 1,
    },
    /**
     * 遷移引数（検査箇所＋地図種別）
     * 検査箇所一覧から遷移する場合のみ設定されます。
     */
    transferArgs: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      searchWord: "",
      searchTarget: "1",
      data1: "",
      backImage: {
        backimageName: "",
        backimagePath: "",
        backImageBase64: "",
        lineSetting: [],
        inspectPlaces: [],
        targetInspectPlace: null,
        drawShapes: [],
        targetDrawShape: null,
      },
      inspectIcons: null,
      inspectLayers: null,
      drawLine: false,
      drawFill: false,
      drawText: false,
      drawNone: true,
      drawShape: false,
      drawStyle: {
        line: {
          color: "text-primary",
          width: "4px"
        },
        fill: {
          color: "text-primary",
        },
        text: {
          color: "text-primary",
          size: "14px"
        }
      },
      modal: {
        isShow: false,
        title: "",
        message: "",
        okLabel: "OK",
        okType: "secondary",
        okCallBack: function() {
          alert(`push Ok`)
          this.modal.isShow = false
        },
        isRetry: false,
        retryLabel: "再指定",
        retryType: "success",
        retryCallBack: function() {
          alert(`push retry`)
          this.modal.isShow = false
        },
        cancelLabel: "キャンセル",
        cancelType: "primary",
        cancelCallBack: function() {
          alert(`push cancel`)
          this.modal.isShow = false
        },
      },
      modalInput: {
        isShow: false,
        message: "",
        inputValue: "",
        okLabel: "OK",
        okType: "secondary",
        okCallBack: function() {
          alert(`push Ok`)
          this.modalInput.isShow = false
        },
        isRetry: false,
        retryLabel: "再指定",
        retryType: "success",
        retryCallBack: function() {
          alert(`push retry`)
          this.modalInput.isShow = false
        },
        cancelLabel: "キャンセル",
        cancelType: "primary",
        cancelCallBack: function() {
          alert(`push cancel`)
          this.modalInput.isShow = false
        },
      },
      selectColors: [
        {
          rgb: "192,0,0",
          hex: "#C00000",
        },
        {
          rgb: "255,0,0",
          hex: "#FF0000",
        },
        {
          rgb: "255,192,0",
          hex: "#FFC000",
        },
        {
          rgb: "255,255,0",
          hex: "#FFFF00",
        },
        {
          rgb: "148,208,80",
          hex: "#92D050",
        },
        {
          rgb: "0,176,80",
          hex: "#00B050",
        },
        {
          rgb: "0,176,240",
          hex: "#00B0F0",
        },
        {
          rgb: "0,112,192",
          hex: "#0070C0",
        },
        {
          rgb: "112,48,160",
          hex: "#7030A0",
        },
        {
          rgb: "0,0,0",
          hex: "#000000",
        }
      ],
      TenantStorage: null
    };
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  async created() {

    if (mlMap) {
      // ドロワーが開いたときに走るので抜ける
      console.log('[CANCEL] created');
      return;
    }

    //インスタンスが生成され､且つデータが初期化された後
    console.log('[START] created');

    try {
      // PDFの読み込みが終わる前に地図作成処理が実行されるので移動
      // await this.init();
    } finally {
      console.log('[END] created')
    }
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  async mounted() {

    if (mlMap) {
      // ドロワーが開いたときに走るので抜ける
      console.log('[CANCEL] mounted');
      return;
    }

    //インスタンスが DOM 要素にマウントされた後
    console.log('[START] mounted');

    let loader = this.showLoader();

    try {

      // 初期処理内でPDFファイルの読み込み
      await this.init();

      // 地図生成
      await this.mapCreate();

    } finally {
      this.hideLoader(loader);
      console.log('[END] mounted');
    }
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
    console.log('[START] unmounted');

    try {
      this.modal.isShow = false;
      this.modalInput.isShow = false;
      this.showDrawTextInput = false;

      this.inspectIcons = null;

      if (mlMap) {
        console.log('mlMap:remove');
        mlMap.remove();
        mlMap = null;
      }
    } finally {
      console.log('[END] unmounted');
    }

  },
  /**
   * watchはvueインスタンスのプロパティ(dataで定義している値など)の変更を監視する
   */
  watch: {
    data1() {
      //alert(`${this.data1} changed`);
    },
  },
  /**
   * コンピュートプロパティ。this.prop1のようにして取得できる
   */
  computed: {
    computed1() {
      return "";
    },
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {

      try {
        const user = await UserInfo.getUserInfo()
        this.tenantStorage = new TenantStorage(user.group)

        // 背景の初期化処理
        await this.initBaseMap();

        // アイコンの初期化
        await this.initInspectIcons();

        // 検査箇所の初期化
        await this.initInspectPlace();

        // 手書き作図図形の初期化
        await this.initDrawShapes();

        // 手書き作図コントロールの初期化
        this.initDrawControls();

      } catch (e) {
        alert(`${JSON.stringify(e, null, "\t")}`);
        throw e
      }
    },

    /**
     * Leafletの地図領域を更新します。
     * 親要素のリサイズ時に呼び出します。
     */
    parentPanelResized() {
      console.log(`parentPanelResized`)
      mlMap.invalidateSize()
    },

    /**
     * 背景地図の初期化処理
     */
    async initBaseMap() {

      if (this.fileType == 1) {
        // 背景図面の初期化
        await this.initBackImage();
      }

    },

    /**
     * 背景図情報の初期化処理
     */
    async initBackImage() {

      try {
        console.log('projectId:' + this.projectId);
        console.log('nodeId:' + this.nodeId);

        const prefix = `backImage`;
        // DBから背景図情報のレコード取得
        const backImage = await BackImageModel.getBackImage({
          sk: `${prefix}#${this.projectId}#${this.nodeId}`,
        });

        console.log(backImage);
        if (backImage == null) {
          console.log('背景図未設定')
          return;
        }

        // 背景図名
        this.backImage.backImageName = backImage.backImage.backImageName;

        // 背景図パス
        this.backImage.backimagePath = backImage.backImage.backimagePath;
        console.log('背景図パス：' + this.backImage.backimagePath);

        // 通り芯情報を保持
        this.backImage.lineSetting = backImage.backImage.lineSetting;

        let contentType = mime.lookup(this.backImage.backimagePath)

        // 背景図ファイルをバイナリで取得
        // const result = await Storage.get(
        //   this.backImage.backimagePath,
        //   { level: "public", download: true, contentType: contentType}
        // );

        // base64化する
        //this.backImage.backImageBase64 = await this.readFileAsDataURL(result.Body);

        // テナント毎プライベートから取得
        this.backImage.backImageBase64 = (await this.tenantStorage.get(this.backImage.backimagePath)).toBase64()

        // console.log(this.backImage.backImageBase64);

        // TODO:leafletにわたす

      } catch (e) {
        // alert(`${JSON.stringify(e.errors, null, "\t")}`);
        throw e
      }

    },

    /**
     * BLOBからBASE64文字列へ
     */
    async readFileAsDataURL(blob) {
      const base64 = await new Promise((resolve) => {
        const reader = new FileReader();

        // eslint-disable-next-line no-unused-vars
        reader.onload = (e) => {
          const result = reader.result;
          resolve(result);
        };

        reader.readAsDataURL(blob);
      });

      return base64;
    },

    /**
     * 検査箇所アイコンの初期化
     */
    async initInspectIcons() {

      try {

        // DBから検査箇所マスタのレコード取得
        const inspectMasters = await InspectMasterModel.getInspectMasterList(this.projectId);

        console.log('検査箇所マスタ数:' + inspectMasters.length);

        this.inspectIcons = [];
        // this.inspectIcons = new Array(inspectMasters.length);

        const masterCount = inspectMasters.length;

        for (let i = 0; i < masterCount; i++) {
          console.log(inspectMasters[i]);

          // 検査箇所マスタID
          let id = null;
          id = inspectMasters[i].inspectMaster.id;

          // 検査箇所マスタ名称
          let name = null;
          name = inspectMasters[i].inspectMaster.name;

          // アイコン設定

          let masterName = null;
          masterName = name;

          inspectMasters[i].inspectMaster.iconSetting.forEach((item) => {
            let iconImageBase64 = "";
            console.log(`アイコン:${item}`);
            // console.log(`アイコンパス:${item.iconFilePath}`);

            // S3からアイコンファイルダウンロード
            this.downloadIconFile(item.iconFilePath).then(result => {
              iconImageBase64 = result;

              console.log(`アイコンBase64:${iconImageBase64}`)

              // レイヤ名 = 検査マスタ名 - アイコン名
              let layerName = null;
              layerName = masterName + ' - ';
              layerName += item.name;
              console.log('レイヤ名：' + layerName);

              this.inspectIcons.push({
                id: id,
                name: masterName,
                iconSettings: item,
                iconBase64: iconImageBase64,
                layerName: layerName
                //iconSettings: iconLayerSettings
              });

            });

          });

        }

      } catch (e) {
        // alert(`${JSON.stringify(e.errors, null, "\t")}`);
        throw e
      }
    },

    /**
     * アイコンファイルをダウンロードします。
     *
     * @returns アイコンファイルのbase64文字列
     */
    async downloadIconFile(icon) {

      // const user = await UserInfo.getUserInfo()

      return await FileUtil.getTenantFileUrl(icon)
      // const s3KeyPrefix = `tenant/${user.group}/`
      // console.log(`getTenantFile ${s3KeyPrefix}${icon}`)

      // const result = await Storage.get(icon, {
      //   // level: 'private',
      //   level: "public",
      //   customPrefix: {public: s3KeyPrefix},
      //   download: true,
      // });

      // console.log(result);

      // const base64 = await this.readFileAsDataURL(result.Body);

      // return base64;

    },

    /**
     * 検査箇所データの初期化
     */
    async initInspectPlace() {

      try {
        console.log('projectId:' + this.projectId);
        console.log('nodeId:' + this.nodeId);

        // DBから検査箇所情報のレコード取得
        this.inspectPlaces = await InspectPlaceModel.getInspectPlaceList(this.projectId, this.nodeId);

        console.log('検査箇所数:' + this.inspectPlaces.length);

      } catch (e) {
        alert(`${JSON.stringify(e.errors, null, "\t")}`);
        throw e
      }
    },

    /**
     * 手書き作図データの初期化
     */
    async initDrawShapes() {

      this.drawShapes = new Array();

      try {
        // DBから手書き作図情報のレコード取得
        this.drawShapes = await ShapeModel.getShapeList(this.projectId, this.nodeId);

        console.log('手書き作図図形数:' + this.drawShapes.length);

      } catch (e) {
        alert(`${JSON.stringify(e.errors, null, "\t")}`);
        throw e
      }
    },

    /**
     ** 手書き作図コントロールの初期化
     */
    initDrawControls() {

    },

    /**
     * 地図の作成
     */
    async mapCreate() {

      // MapletLeaflet関連出力ログレベル
      MLLog.level = MLLog.LOGLEVEL.INFO;

      // --------------------
      // レイヤ追加
      // --------------------

      let bounds = [];
      bounds = await this.addLayers();

      // --------------------
      // 表示オブジェクト追加
      // --------------------

      // 通り芯追加
      this.addBaseLines();

      // 検査箇所追加
      this.addInspectPois();

      // 手書き作図追加
      this.addDrawShapes();

      // --------------------
      // コントロール
      // --------------------

      // mlMap.setLayerControlVisible(false);
      mlMap.setMapInfoControlVisible(true);
      mlMap.setDrawControlVisible(true);

      // --------------------
      // 初期表示位置へ移動
      // --------------------
      // ローカルストレージに保存されているか
      const skString = this.getSeekKeyString();
      const lsBounds = JSON.parse(localStorage.getItem(skString));
      console.log(lsBounds);

      if (localStorage.getItem(skString)) {
        try {
          bounds = L.latLngBounds(
            [lsBounds._southWest.lat, lsBounds._southWest.lng],
            [lsBounds._northEast.lat, lsBounds._northEast.lng]);
        } catch (e) {
          localStorage.removeItem(skString);
        }
      }
      mlMap.fitBounds(bounds);

      // 検査箇所一覧から遷移した場合は箇所位置へ移動
      console.log(`transferArgs ${this.transferArgs}`)

      if (this.transferArgs) {
        // const zoomLv = mlMap.getZoom();
        const placeData = this.transferArgs.inspectPlace;
        const latlng = [placeData.y, placeData.x];
        mlMap.panTo(latlng);

        // 仮図形の作図
        this.addTempPoi(latlng);
      }

      // 図形の上下(Z-Order)を正しく描画
      mlMap.refreshZOrder();

      // --------------------
      // イベント
      // --------------------
      this.initMapEvent();

    },

    /**
     * 地図イベントの初期化処理です。
     */
    initMapEvent() {

      // クリック
      this.initMapEventClick();

      // 作図開始/終了
      this.initMapEventShapeDrawStartEnd();

      // 作図完了
      this.initMapEventShapeDrawFinish();

      // 図形削除
      this.initMapEventShapeDelete();

      // 地図移動後
      mlMap.on('moveend', (e) => {
        let bounds = e.target.getBounds();
        console.log(`[${e.type}] bounds: ${bounds}`);

        this.saveBounds(bounds);
      });

      // 縮尺変更後
      mlMap.on('zoomend', (e) => {
        let bounds = e.target.getBounds();
        console.log(`[${e.type}] bounds: ${bounds}`);

        this.saveBounds(bounds);
      });
    },

    /**
     * 地図クリックイベントの初期化処理です。
     */
    initMapEventClick() {

      // レイヤ名リスト
      // const poiLayers = this.getPoiLayerNames();

      mlMap.on('click', (e) => {
        console.log(`[${e.type}] lnglat: ${e.latlng.lng}, ${e.latlng.lat}`);

        this.clearSelectedPois();
        // mlMap.clearSelectedPoi(poiLayerName);

        if (inspectMenu == InspectPlaceMenu.Add) {

          console.log('検査箇所 -追加');

          // 仮図形の作図
          this.addTempPoi(e.latlng);

          // 通り芯取得(先頭行を採用)
          const lines = mlMap.getBaseLineByDomMouseEvent(e.originalEvent);

          // 図形の作図（属性入力遷移）
          this.addInspectPoi(e.latlng.lng, e.latlng.lat, lines);

          // モードを終了
          inspectMenu = InspectPlaceMenu.None;

        } else if (inspectMenu == InspectPlaceMenu.MoveStart) {

          console.log('検査箇所 -移動開始');
          let items = [];
          items = this.getPoiItems(e);
          // const items = mlMap.getPoiByLatLng(poiLayerName, e.latlng);

          if (items.length < 1) {
            return;
          }

          console.log(items);
          mlMap.setSelectedPoi(items[0].layerName, items);
          // mlMap.setSelectedPoi(poiLayerName, items);

          // IDを保持
          this.targetPoiId = items[0].id;

          // メニューモード更新
          inspectMenu = InspectPlaceMenu.MoveEnd;

        } else if (inspectMenu == InspectPlaceMenu.MoveEnd) {

          console.log('検査箇所 -移動終了');

          // let ret = false;
          // let ret = confirm('移動します。よろしいですか？');

          // 仮図形の作図
          this.addTempPoi(e.latlng);

          const _this = this;
          this.$nextTick(function() {
            this.modal = {
              isShow: true,
              title: "検査箇所の移動",
              message: `移動します。よろしいですか？`,
              okLabel: "OK",
              okType: "info",
              okCallBack: function() {
                // ret = true;

                _this.updateInspectPoi(_this.targetPoiId, e.latlng);

                // 閉じる
                _this.modal.isShow = false
                _this.clearSelectedPois();
              },
              cancelLabel: "キャンセル",
              cancelType: "secondary",
              cancelCallBack: function() {
                // 移動モードを終了
                inspectMenu = InspectPlaceMenu.None;

                _this.modal.isShow = false
                _this.clearSelectedPois();
              },
            };
          });

          // if (ret) {
          //   this.updateInspectPoi(this.targetPoiId, e.latlng);
          // } else {
          //   // 移動モードを終了
          //   inspectMenu = InspectPlaceMenu.None;
          // }

        } else if (inspectMenu == InspectPlaceMenu.Delete) {

          console.log('検査箇所 -削除');
          const items = this.getPoiItems(e);

          if (items.length < 1) {
            return;
          }

          // const items = mlMap.getPoiByLatLng(poiLayerName, e.latlng);
          console.log(items);

          // mlMap.setSelectedPoi(items[0].layerName, items);
          // mlMap.setSelectedPoi(poiLayerName, items);

          // 仮図形の作図(test)
          this.addTempPoi(e.latlng);

          // 削除確認ダイアログ
          // let ret = false;
          // let ret = confirm('削除します。よろしいですか？');

          const _this = this;
          this.$nextTick(function() {
            this.modal = {
              isShow: true,
              title: "検査箇所の削除",
              message: `削除します。よろしいですか？`,
              okLabel: "OK",
              okType: "danger",
              okCallBack: function() {
                // ret = true;

                _this.deleteInspectPoi(items[0]);
                // _this.deleteInspectPoi(items[0].id);

                // モードを終了
                inspectMenu = InspectPlaceMenu.None;

                // 閉じる
                _this.modal.isShow = false
                _this.clearSelectedPois();
              },
              cancelLabel: "キャンセル",
              cancelType: "secondary",
              cancelCallBack: function() {
                // モードを終了
                inspectMenu = InspectPlaceMenu.None;
                _this.modal.isShow = false
                _this.clearSelectedPois();
              },
            };
          }.bind(this));

          this.clearSelectedPois();

        } else {
          // 検査箇所選択 → 検査結果の表示
          const items = this.getPoiItems(e);

          console.log(items);

          if (items.length < 1) {
            return;
          }

          // 仮図形の作図(test)
          this.addTempPoi(e.latlng);

          // 先頭の1件のみ対象
          mlMap.setSelectedPoi(items[0].layerName, items[0]);

          // 検査結果表示
          this.$emit('transfer', {key: "inspectPlaceArgs", values: {id: items[0].id}});

          // ToDo:検査結果表示を閉じたら選択図形クリア
          // mlMap.clearSelectedPoi(poiLayerName);
        }
      });
    },

    /**
     * 地図作図開始/終了イベントの初期化処理です。
     */
    initMapEventShapeDrawStartEnd() {

      // 作図スタイルの初期化
      this.initDefaultDrawStyle();

      mlMap.on(MLMap.EVENT_SHAPE_DRAW_START, (e) => {
        // 作図開始
        console.log(`[${e.type}] shapeType: ${e.shapeType}`);

        this.drawNone = false;
        this.drawLine = false;
        this.drawFill = false;
        this.drawText = false;

        this.drawShape = true;

        switch (e.shapeType) {
          case MLMap.SHAPE_TYPE.POLYLINE:
          case MLMap.SHAPE_TYPE.FREEHAND:
            this.drawLine = true;
            console.log(this.drawStyle.line.color);
            break;

          case MLMap.SHAPE_TYPE.POLYGON:
          case MLMap.SHAPE_TYPE.RECT:
          case MLMap.SHAPE_TYPE.CIRCLE:
            this.drawLine = true;
            this.drawFill = true;
            break;

          case MLMap.SHAPE_TYPE.TEXT:
            this.drawText = true;
        }

      });

      mlMap.on(MLMap.EVENT_SHAPE_DRAW_STOP, (e) => {

        // 作図終了（キャンセル）
        console.log(`[${e.type}] shapeType: ${e.shapeType}`);

        this.cancelDrawShape();

      });
    },

    /**
     * 地図作図完了イベントの初期化処理です。
     */
    initMapEventShapeDrawFinish() {

      mlMap.on(MLMap.EVENT_SHAPE_DRAW_FINISH, (e) => {
        console.log(`[${e.type}] shapeType: ${e.shapeType}`);
        console.log(e.layer);

        // e.shapeType: 作図図形タイプ
        // e.layer: 作図図形

        if (e.shapeType === MLMap.SHAPE_TYPE.TEXT) {
          // let text = window.prompt('登録する文字列を入力してください。');

          let text = "";
          const _this = this;
          let _e = e;
          this.$nextTick(function() {
            this.modalInput = {
              isShow: true,
              // inputValue: "",
              message: `作図する文字列を入力してください。`,
              okLabel: "OK",
              okType: "info",
              okCallBack: function() {
                console.log(_this.modalInput.inputValue);
                console.log(_e);

                text = _this.modalInput.inputValue;

                // 空文字の場合はキャンセル
                if (!text) {
                  // 閉じる
                  _this.modalInput.isShow = false;
                  _this.modalInput.inputValue = "";
                  return;
                }

                _e.layer.setText(text);
                console.log(_e.layer);

                // DB登録
                _e.layer.shapeItem = {};
                _this.addDrawShape(_e.shapeType, _e.layer);

                // 閉じる
                _this.modalInput.isShow = false;
                _this.modalInput.inputValue = "";
              },
              cancelLabel: "キャンセル",
              cancelType: "secondary",
              cancelCallBack: function() {
                _this.modalInput.isShow = false;
                _this.modalInput.inputValue = "";
                _this.cancelDrawShape();
              },
              // ToDo:削除するかFinishイベントをキャンセルできるかどうか
              isRetry: false,
              retryLabel: "再指定",
              retryType: "default",
              retryCallBack: function() {
                console.log('retry');
                _this.modalInput.isShow = false;
              },
            };
          });

        } else {

          // DB登録, 作図情報(idなど図形に持たせたい情報をshapeItemへ)設定、図形スタイル変更など
          e.layer.shapeItem = {};

          // DB登録, 図形スタイル変更など
          this.addDrawShape(e.shapeType, e.layer);

        }
      });
    },

    /**
     * 地図図形削除イベントの初期化処理です。
     */
    initMapEventShapeDelete() {
      mlMap.on(MLMap.EVENT_SHAPE_DELETE, (e) => {
        console.log(`[${e.type}] layers: ${e.layers.length}`);
        console.log(e.layers);

        // e.layers: 削除した作図図形配列
        const layers = e.layers;

        for (let i = 0; i < layers.length; i++) {
          // DBから削除など(layer.shapeItemに設定したIDなどをキーに)
          console.log(layers[i]);

          if (!this.delteDrawShape(layers[i].shapeItem.id)) {
            // 失敗した場合
            console.log('rollback:' + layers[i]);
            mlMap.rollbackShape(layers[i]);
          }

          mlMap.refreshZOrder();
        }
      });
    },

    /**
     * 手書き作図スタイルを初期化します。
     */
    initDefaultDrawStyle() {
      this.drawStyle = {
        line: {
          color: "#000000",
          // color: "#E4007F",
          width: 4,
        },
        fill: {
          color: "#E4007F",
        },
        text: {
          color: "#E4007F",
          size: 14,
        },
      };

      // ローカルストレージから復元
      let val = "";
      val = this.getItem('drawStyleLineColor');
      if (val) {
        this.drawStyle.line.color = val;
      }
      val = this.getItem('drawStyleLineWidth');
      if (val) {
        this.drawStyle.line.width = val;
      }
      val = this.getItem('drawStyleFillColor');
      if (val) {
        this.drawStyle.fill.color = val;
      }
      val = this.getItem('drawStyleTextColor');
      if (val) {
        this.drawStyle.text.color = val;
      }
      val = this.getItem('drawStyleTextSize');
      if (val) {
        this.drawStyle.text.size = val;
      }

      const lineColor = this.drawStyle.line.color;
      const lineWidth = this.drawStyle.line.width;
      const fillColor = this.drawStyle.fill.color;
      const textColor = this.drawStyle.text.color;
      const textSize = this.drawStyle.text.size;

      this.setDrawStyleLineColor(lineColor);
      this.setDrawStyleLineWidth(lineWidth);
      this.setDrawStyleFillColor(fillColor);
      this.setDrawStyleTextColor(textColor);
      this.setDrawStyleTextSize(textSize);

    },

    /**
     * 手書き作図スタイル設定 - 線色
     * @param {string} color カラー
     */
    setDrawStyleLineColor(color) {

      mlMap.drawControlOptions.draw.polyline.shapeOptions.color = color;
      mlMap.drawControlOptions.draw.freehand.shapeOptions.color = color;
      mlMap.drawControlOptions.draw.polygon.shapeOptions.color = color;
      mlMap.drawControlOptions.draw.rectangle.shapeOptions.color = color;
      mlMap.drawControlOptions.draw.circle.shapeOptions.color = color;

      // ローカルストレージに保存
      this.saveItem('drawStyleLineColor', color);

    },

    /**
     * 手書き作図スタイル設定 - 線幅
     * @param {int} width 線幅
     */
    setDrawStyleLineWidth(width) {

      mlMap.drawControlOptions.draw.polyline.shapeOptions.weight = width;
      mlMap.drawControlOptions.draw.freehand.shapeOptions.weight = width;
      mlMap.drawControlOptions.draw.polygon.shapeOptions.weight = width;
      mlMap.drawControlOptions.draw.rectangle.shapeOptions.weight = width;
      mlMap.drawControlOptions.draw.circle.shapeOptions.weight = width;

      // ローカルストレージに保存
      this.saveItem('drawStyleLineWidth', width);
    },

    /**
     * 手書き作図スタイル設定 - 面色
     * @param {string} color カラー
     */
    setDrawStyleFillColor(color) {

      mlMap.drawControlOptions.draw.polygon.shapeOptions.fillColor = color;
      mlMap.drawControlOptions.draw.rectangle.shapeOptions.fillColor = color;
      mlMap.drawControlOptions.draw.circle.shapeOptions.fillColor = color;

      // ローカルストレージに保存
      this.saveItem('drawStyleFillColor', color);

    },

    /**
     * 手書き作図スタイル設定 - 文字色
     * @param {string} color カラー
     */
    setDrawStyleTextColor(color) {

      mlMap.drawControlOptions.draw.text.shapeOptions.fillColor = color;

      // ローカルストレージに保存
      this.saveItem('drawStyleTextColor', color);
    },

    /**
     * 手書き作図スタイル設定 - 文字サイズ
     * @param {int} size サイズ
     */
    setDrawStyleTextSize(size) {

      mlMap.drawControlOptions.draw.text.shapeOptions.fontSize = size;

      // ローカルストレージに保存
      this.saveItem('drawStyleTextSize', size);
    },

    /**
     * 手書き作図コントロールスタイル変更 - 線色
     */
    handerleInputLineColor() {

      this.setDrawStyleLineColor(this.drawStyle.line.color);

    },
    selectedLineColor(color) {
      this.drawStyle.line.color = color.hex;
      this.handerleInputLineColor();
    },
    /**
     * 手書き作図コントロールスタイル変更 - 線幅
     * @param {int} width 線幅
     */
    handerleInputLineWidth(width) {
      console.log('線幅：' + width);
      this.drawStyle.line.width = width;
      this.setDrawStyleLineWidth(this.drawStyle.line.width);

    },

    /**
     * 手書き作図コントロールスタイル変更 - 面色
     */
    handerleInputFillColor() {

      this.setDrawStyleFillColor(this.drawStyle.fill.color);

    },
    selectedFillColor(color) {
      this.drawStyle.fill.color = color.hex;
      this.handerleInputFillColor();
    },

    /**
     * 手書き作図コントロールスタイル変更 - 文字色
     */
    handerleInputTextColor() {

      console.log('文字色：' + this.drawStyle.text.color);
      this.setDrawStyleTextColor(this.drawStyle.text.color);

    },
    selectedTextColor(color) {
      this.drawStyle.text.color = color.hex;
      this.handerleInputTextColor();
    },

    /**
     * 手書き作図コントロールスタイル変更 - 文字色
     */
    handerleInputTextSize() {

      console.log('文字サイズ：' + this.drawStyle.text.size);
      console.log('文字色：' + this.drawStyle.text.color);
      this.setDrawStyleTextSize(this.drawStyle.text.size);

    },

    /**
     * 手書き作図をキャンセルします。
     */
    cancelDrawShape() {

      this.drawNone = true;
      this.drawLine = false;
      this.drawFill = false;
      this.drawText = false;

      this.drawShape = false;
    },

    /**
     * 手書き作図図形をDBへ登録します。
     *
     * @param {MLMap.SHAPE_TYPE} shapeType 作図図形タイプ
     * @param {Leaflet.layer} layer 作図図形
     * @returns 成功した場合の図形ID(失敗時はnull)
     */
    async addDrawShape(shapeType, layer) {
      let ret = null;
      console.log('shapeType:' + shapeType);
      console.log('layer:' + layer);

      try {

        // DBへ登録
        let dbData = await ShapeModel.getNewData(this.projectId, this.nodeId);

        const shapeId = dbData.sk.split('#')[3];
        console.log('shapeId:' + shapeId);
        dbData.shape.shapeId = shapeId;
        dbData.shape.shapeType = shapeType;
        // dbData.shape.shapeType = this.createShapeTypeString(shapeType);

        // 座標の圧縮文字列
        let latlngs = null;

        switch (shapeType) {
          case MLMap.SHAPE_TYPE.POLYLINE:
          case MLMap.SHAPE_TYPE.FREEHAND:
            latlngs = layer.getLatLngs();
            break;

          case MLMap.SHAPE_TYPE.POLYGON:
          case MLMap.SHAPE_TYPE.RECT:
            latlngs = layer.getLatLngs()[0];
            break;

          case MLMap.SHAPE_TYPE.CIRCLE:
          case MLMap.SHAPE_TYPE.TEXT:
            latlngs = [layer.getLatLng()];
            break;

          default:
            return;
        }

        const compressCoordsText = MLCompressUtil.latLngArrayToBase64(latlngs);
        console.log(latlngs);
        dbData.shape.coordinates = compressCoordsText;

        // 図形タイプ別フィールド
        this.setDrawShapeTypeValue(shapeType, layer.options, dbData);

        if (shapeType == MLMap.SHAPE_TYPE.TEXT) {
          dbData.shape.shapeText = layer._text;
        }
        // test
        // console.log(dbData.shape.lineColor);

        await ShapeModel.addShape(dbData);

        // 成功した場合
        // 図形IDを登録（削除時に使用）
        layer.shapeItem.id = shapeId;
        // console.log(this.drawShapes);
        this.drawShapes.push(dbData);

        mlMap.commitShape(layer);

        ret = dbData;
        return ret;

      } catch (e) {
        alert(`DB登録失敗 ${e.message}`)
        throw e
      }
    },

    /**
     * 手書き図形タイプ別のDB登録値を設定します。
     *
     * @param {MLMap.SHAPE_TYPE} shapeType 作図図形タイプ
     * @param {Leaflet.layer._options} options Leaflet図形オプション
     * @param {ShapeModel.newRowData} dbData DB登録行データ
     */
    setDrawShapeTypeValue(shapeType, options, dbData) {
      switch (shapeType) {
      case MLMap.SHAPE_TYPE.POLYLINE:
      case MLMap.SHAPE_TYPE.FREEHAND:
        dbData.shape.lineColor = options.color;
        dbData.shape.lineWidth = options.weight;
        break;

      case MLMap.SHAPE_TYPE.POLYGON:
      case MLMap.SHAPE_TYPE.RECT:
      case MLMap.SHAPE_TYPE.CIRCLE:
        dbData.shape.lineColor = options.color;
        dbData.shape.lineWidth = options.weight;

        if (options.fill) {
          if (options.fillColor == null) {
            dbData.shape.paintColor = options.color;
          } else {
            dbData.shape.paintColor = options.fillColor;
          }
          dbData.shape.paintAlpha = options.fillOpacity;
        } else {
          // 中空
          dbData.shape.paintAlpha = 0;
        }
        break;

      case MLMap.SHAPE_TYPE.TEXT:
        dbData.shape.shapeTextSize = options.fontSize;
        dbData.shape.shapeTextColor = options.fillColor;
        break;
      default:
        break;
      }

      if (shapeType == MLMap.SHAPE_TYPE.CIRCLE) {
        // 半径
        dbData.shape.radius = Math.floor(options.radius);
      }
    },

    /**
     * 手書き図形を削除します。
     * @param {*} id
     */
    delteDrawShape(id) {

      console.log(id);

      try {

        // DBから削除
        this.getTargetDrawShape(id);
        console.log(this.targetDrawShape);

        ShapeModel.deleteShape(this.targetDrawShape);

        return true;

      } catch (e) {
        alert(`DB削除失敗 ${e.message}`)
        throw e
      }
    },

    /**
     * 作図図形タイプ文字列を作成します。
     * ToDo 削除
     *
     * @param {作図図形タイプ} shapeType
     * @returns 作図図形タイプ文字列
     */
    createShapeTypeString(shapeType) {
      switch (shapeType) {
      case MLMap.SHAPE_TYPE.POLYLINE:
        return 'ポリライン';
      case MLMap.SHAPE_TYPE.POLYGON:
        return 'ポリゴン';
      case MLMap.SHAPE_TYPE.RECT:
        return '矩形';
      case MLMap.SHAPE_TYPE.CIRCLE:
        return '円';
      case MLMap.SHAPE_TYPE.FREEHAND:
        return 'フリーハンドライン';
      case MLMap.SHAPE_TYPE.TEXT:
        return 'テキスト';
      default:
        return '';
      }
    },

    /**
     * 文字列から作図図形タイプを作成します。
     * ToDo 削除
     *
     * @param {string} shapeTypeString 作図図形タイプ文字列
     * @returns {MLMap.SHAPE_TYPE} 作図図形タイプ
     */
    createShapeType(shapeTypeString) {
      switch (shapeTypeString) {
      case 'ポリライン':
        return MLMap.SHAPE_TYPE.POLYLINE;
      case 'ポリゴン':
        return MLMap.SHAPE_TYPE.POLYGON;
      case '矩形':
        return MLMap.SHAPE_TYPE.RECT;
      case '円':
        return MLMap.SHAPE_TYPE.CIRCLE;
      case 'フリーハンドライン':
        return MLMap.SHAPE_TYPE.FREEHAND;
      case 'テキスト':
        return MLMap.SHAPE_TYPE.TEXT;
      default:
        return MLMap.SHAPE_TYPE.NON;
      }
    },

    /**
     * DBから取得した手書き作図データを地図に追加します。
     */
    async addDrawShapes() {

      console.log(this.drawShapes.length);

      if (this.drawShapes.length == 0) {
        return;
      }

      this.drawShapes.forEach(item => {

        const shape = item.shape;

        // 図形タイプ
        const shapeType = shape.shapeType;
        // const shapeType = this.createShapeType(shape.shapeType);

        //  座標BASE64圧縮文字列
        const compressCoordsText = shape.coordinates;

        // ID
        let shapeItem = { id: shape.shapeId };

        // スタイル
        // let style = {};
        const style = this.createDrawShapeStyle(shape);
        // console.log('style:' + style);

        try {
          // console.log(style);

          // 手書き作図図形追加
          mlMap.addShape(shapeType, compressCoordsText, shapeItem, style);
        } catch (e) {
          console.log('shapeType:' + shapeType + ' id:' + shape.shapeId + ' ' + e);
        }

      });
    },

    /**
     * 手書き図形のスタイルを作成します。
     *
     * @param {ShapeModel.shape} shape DB手書き図形行データ
     * @param {object} style スタイル
     */
    createDrawShapeStyle(shape) {
      console.log(shape.shapeType);

      let style = {};
      let fillValue = true;

      switch (shape.shapeType) {
      // switch (this.createShapeType(shape.shapeType)) {
      case MLMap.SHAPE_TYPE.POLYLINE:
      case MLMap.SHAPE_TYPE.FREEHAND:
        style.color = shape.lineColor;
        style.weight = shape.lineWidth;
        style.opacity = 0.8;
        // style = {
        //   color: shape.lineColor,
        //   weight: shape.lineWidth,
        //   // opacity: 0.8,
        // };
        break;
      case MLMap.SHAPE_TYPE.POLYGON:
      case MLMap.SHAPE_TYPE.RECT:
        if (shape.paintAlpha == 0) {
          fillValue = false;
        }
        style.color = shape.lineColor;
        style.weight = shape.lineWidth;
        style.fill = fillValue;
        style.fillColor = shape.paintColor;
        style.fillOpacity = shape.paintAlpha;
        style.opacity = 0.8;

        // style = {
        //   color: shape.lineColor,
        //   weight: shape.lineWidth,
        //   fill: fillValue,
        //   fillColor: shape.paintColor,
        //   fillOpacity: shape.paintAlpha,
        // };
        break;

      case MLMap.SHAPE_TYPE.CIRCLE:
        if (shape.paintAlpha == 0) {
          fillValue = false;
        }
        style = {
          color: shape.lineColor,
          weight: shape.lineWidth,
          // fill: fillValue,
          fillColor: shape.paintColor,
          fillOpacity: shape.paintAlpha,
          radius: shape.radius,
          opacity: 0.8,
        };
        break;

      case MLMap.SHAPE_TYPE.TEXT:
        style = {
          // color: shape.lineColor,
          // weight: shape.lineWidth,
          isBold: true,
          fillColor: shape.shapeTextColor,
          opacity: 0.8,
          fillOpacity: 0.8,
          fontSize: shape.shapeTextSize,
          text: shape.shapeText,
        };
        break;
      default:
        // console.log('図形タイプなし');
        style = {};
        break;
      }

      // console.log(style);
      return style;

    },

    /**
     * レイヤ群を追加します。
     *
     * @returns 地図表示初期領域
     */
    async addLayers() {

      // 初期表示領域
      let bounds = [];

      // 背景レイヤの追加
      bounds = await this.addBaseLayers();

      // 検査箇所レイヤ追加
      console.log(this.inspectIcons);
      this.addPoiLayers();

      // 作図レイヤ追加
      mlMap.addShapeLayer('作図（お絵描き）');

      return bounds;

    },

    /**
     * 背景レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    async addBaseLayers() {

      let bounds = [];
      let ext = ``;

      switch (this.fileType) {
      case 1:
        // 背景図面の場合
        ext = path.extname(this.backImage.backimagePath);

        if (ext == '.pdf') {
          // PDF
          bounds = await this.addPdfLayer();
        } else {
          // png/jpg
          bounds = await this.addImageLayer();
        }

        // 通り芯
        await this.addBaseLineLayer();

        break;

      case 2:
        // 地図の場合
        bounds = await this.addGsiLayers();
        break;
      }

      return bounds;
    },

    /**
     * 図面PDFレイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    async addPdfLayer() {

      // PDFを表示する場合はPDFにアクセスする前に必ず設定
      MLPdfUtil.setPdfJsWorkerUrl('./maplet-leaflet/external/pdfjs-2.7.570/pdf.worker.js');

      // ビュータイプを図面表示用に設定してMLMapを生成
      mlMap = null;
      mlMap = new MLMap('map', MLMap.VIEW_TYPE.NON_GEOGRAPHICAL);

      if (!this.backImage.backImageBase64) {
        console.log('背景図Base64未設定');
        return;
      }

      // '識別文字','PDF実データ' の構成になっているので、実データのみを取得
      const pdfBase64 = this.backImage.backImageBase64.split(',')[1];
      // console.log(pdfBase64);

      // PDF情報
      const doc = await MLPdfUtil.getDocumentInfoByBase64(pdfBase64);
      const page = await MLPdfUtil.getPageInfo(doc, 1);

      // レイヤ追加
      mlMap.addPdfLayer(this.backImage.backImageName, {
        pdfData: pdfBase64,
        page: 1,
        cMapUrl: './maplet-leaflet/external/pdfjs-2.7.570/cmaps',
      });

      // PDF全体を表示
      let bounds = L.latLngBounds([0, 0], [page.height, page.width]);

      // スクロール制限
      mlMap.setMaxBounds(bounds.pad(0.75));

      return bounds;
    },

    /**
     * 図面画像レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    async addImageLayer() {

      // ビュータイプを図面表示用に設定してMLMapを生成
      mlMap = null;
      mlMap = new MLMap('map', MLMap.VIEW_TYPE.NON_GEOGRAPHICAL);

      if (!this.backImage.backImageBase64) {
        console.log('背景図Base64未設定');
        return;
      }

      // PDFではない場合は分割する必要なし
      const base64 = this.backImage.backImageBase64;
      console.log(base64);

      // 図面情報
      const imageInfo = await MLImageUtil.getImageInfo(base64);
      console.log(imageInfo);

      console.log(this.backImage.backImageName);

      // レイヤ追加
      mlMap.addImageLayer(this.backImage.backImageName, base64, imageInfo.width, imageInfo.height);
      console.log('addImageLayer');

      // 画像全体を表示
      let bounds = L.latLngBounds([0, 0], [imageInfo.height, imageInfo.width]);
      console.log(bounds);

      // スクロール制限
      mlMap.setMaxBounds(bounds.pad(0.75));

      return bounds;
    },

    /**
     * 通り芯レイヤを追加します。
     *
     */
    async addBaseLineLayer() {

      if (!this.backImage.lineSetting) {
        return;
      }

      // 先頭行のスタイルを適用
      const lineSetting = this.backImage.lineSetting[0];

      // 通り芯スタイル
      let baseLineStyle = this.getBaseLineStyle(lineSetting);

      // 通り芯円スタイル
      let baseLineCircleStyle = this.getBaseLineCircleStyle(lineSetting);

      // 通り芯ラベルスタイル
      let baseLineLabelStyle = this.getBaseLineLabelStyle(lineSetting);

      // 通り芯レイヤ追加
      mlMap.addBaseLineLayer('通り芯', baseLineStyle, baseLineCircleStyle, baseLineLabelStyle);

    },

    /**
     * 通り芯スタイルを取得します。
     *
     * @param {lineSetting} lineSetting DB先頭行の通り芯設定
     *
     * @returns 通り芯スタイル
     */
    getBaseLineStyle(lineSetting) {

      // 初期値
      let baseLineStyle = {
        color: '#2962FF',
        weight: 3,
      };

      if (!lineSetting) {
        return baseLineStyle;
      }

      baseLineStyle.color = lineSetting.lineColor;
      baseLineStyle.weight = lineSetting.lineWidth;

      return baseLineStyle;

    },

    /**
     * 通り芯円スタイルを取得します。
     *
     * @param {lineSetting} lineSetting DB先頭行の通り芯設定
     *
     * @returns 通り芯円スタイル
     */
    getBaseLineCircleStyle(lineSetting) {

      // 初期値
      let baseLineCircleStyle = {
        // fillColor: '#ffffff',
        fillColor: '#2962FF',
      };

      if (!lineSetting) {
        return baseLineCircleStyle;
      }

      // Todo
      baseLineCircleStyle.color = lineSetting.lineColor;

      return baseLineCircleStyle;

    },

    /**
     * 通り芯ラベルスタイルを取得します。
     *
     * @param {lineSetting} lineSetting DB先頭行の通り芯設定
     *
     * @returns 通り芯ラベルスタイル
     */
    getBaseLineLabelStyle(lineSetting) {

      // 初期値
      let baseLineLabelStyle = {
        // fillColor: '#2962FF',
        fillColor: '#FFFFFF',
      };

      if (!lineSetting) {
        return baseLineLabelStyle;
      }

      // baseLineLabelStyle.fillColor = lineSetting.lineColor;

      return baseLineLabelStyle;

    },

    /**
     * 国土地理院タイル地図レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    async addGsiLayers() {

      mlMap = null;
      mlMap = new MLMap('map', MLMap.VIEW_TYPE.MAP);

      mlMap.addGsiStdLayer('地理院タイル 標準地図');
      mlMap.addGsiPaleLayer('地理院タイル 淡色地図');
      mlMap.addGsiPhotoLayer('地理院タイル 写真');

      mlMap.setView([35.68944, 139.69167], 12);

      const bounds = L.latLngBounds([25, 120], [45, 150]);
      // const bounds = L.latLngBounds([-90, -180], [90, 180]);
      mlMap.setMaxBounds(bounds.pad(0.25));

      return bounds;
    },

    /**
     * 検査箇所レイヤ群を追加します。
     */
    addPoiLayers() {

      // ToDo:仕様確認
      // 検査箇所ラベルスタイル
      const poiLabelStyle = {
        color: '#FFFFFF',
        weight: 4,
        fillColor: '#263238',
        fontSize: 16,
      };

      try {

        if (!this.inspectIcons) {
          return;
        }

        // 先にアイコン定義を並び替え
        this.inspectIcons.sort(function(a, b) {
          if (a.id > b.id) return -1;
          if (a.id < b.id) return 1;
          if (a.iconSettings.order > b.iconSettings.order) return -1;
          if (a.iconSettings.order < b.iconSettings.order) return 1;
          return 0;
        });

        // レイヤ数 = 検査マスタ数 × アイコン定義数分

        for (let i = 0; i < this.inspectIcons.length; i++) {
          // console.log(this.inspectIcons[i]);
          console.log('アイコン定義:' + JSON.stringify(this.inspectIcons[i], null, "\t"));

          // let iconSettings = null;
          let layerName = null;
          let poiStyle = null;

          this.$nextTick(function() {
            // iconSettings = this.inspectIcons[i].iconSettings;
            layerName = this.inspectIcons[i].layerName;
            // layerName = iconSettings.layerName;
            poiStyle = { iconUrl: this.inspectIcons[i].iconBase64 };

            // 検査箇所レイヤ追加
            mlMap.addPoiLayer(layerName, poiStyle, poiLabelStyle);
          }.bind(this))
        }

      } catch (e) {
        alert(`${JSON.stringify(e.errors, null, "\t")}`);
        throw e
      }
    },

    /**
     * テナントのファイルURL文字列を取得します。
     *
     * @param {String} s3Key S3キー
     *
     * @returns ファイルURL文字列
     */
    async getTenantFile(s3Key) {

      if (!s3Key) {
        return ""
      }
      try {
        const user = await UserInfo.getUserInfo();
        const s3KeyPrefix = `tenant/${user.group}/`;
        console.log(`getTenantFile ${s3KeyPrefix}${s3Key}`);

        const url = await this.get(`${s3Key}`, {level: "public", customPrefix: {public: s3KeyPrefix}});
        console.log(`url:: ${url}`);

        return url.toString();
      } catch (e) {
        alert(`error getTenantFile ${JSON.stringify(e, null, "\t")}`);
        throw e;
      }
    },

    /**
     * S3からURLを取得します。
     *
     * @param {String} key S3キー
     * @param {Object} config 設定情報
     *
     * @returns URL
     */
    async get(key, config) {
      try {
        let imageUrl = await Storage.get(key, config)
        return imageUrl
      } catch (e) {
        this.debugText = `エラー:${JSON.stringify(e)}`
      }
    },

    /**
     * DBから取得した通り芯データを地図に追加します。
     */
    async addBaseLines() {

      if (!this.backImage.lineSetting) {
        return;
      }

      try {
        this.backImage.lineSetting.forEach(item => {

          console.log(`${JSON.stringify(item, null, "\t")}`);

          // // 通り芯タイプ
          // let lineType = MLMap.BASE_LINE_DIRECTION.HORIZONTAL;
          // switch (item.lineType) {
          // // switch (Number(item.lineType)) {
          //   case 0:
          //     lineType = MLMap.BASE_LINE_DIRECTION.HORIZONTAL;
          //     break;

          //   case 1:
          //     lineType = MLMap.BASE_LINE_DIRECTION.VERTICAL;
          //     break;

          //   case 2:
          //     lineType = MLMap.BASE_LINE_DIRECTION.OTHER;
          //     break;

          //   default:
          //     console.log(`通り芯タイプ警告:: ${item.lineType}`);
          //     break;
          // }

          // // ラベル表示位置
          // let labelPosition = MLMap.BASE_LINE_LABEL_POSITION.START;
          // if (item.labelPosition == 1) {
          // // if (item.labelPosition == '1') {
          //   labelPosition = MLMap.BASE_LINE_LABEL_POSITION.END;
          // }

          mlMap.addBaseLine({
            id: item.streetlineId,
            x1: item.startX,
            y1: item.startY,
            x2: item.endX,
            y2: item.endY,
            label: item.lineName,
            labelPosition: item.labelPosition,
            // labelPosition: labelPosition,
            direction: item.lineType,
            // direction: lineType,
          });
        });

      } catch (e) {
        alert(`通り芯作図失敗 ${e.message}`)
        throw e
      }

    },

    /**
     * DBから取得した検査箇所データを地図に追加します。
     */
    async addInspectPois() {

      if (this.inspectPlaces.length == 0) {
        return;
      }

      this.inspectPlaces.forEach(item => {
        // 仮表示文字列
        if (item.inspectPlace.textValue != null) {
          if (tempIdCounter < item.inspectPlace.textValue) {
            tempIdCounter = item.inspectPlace.textValue;
          }
        }

        this.addPoi(item);
        // mlMap.addPoi(poiLayerName, {
        //   id: item.inspectPlace.placeId,
        //   x: item.inspectPlace.x,
        //   y: item.inspectPlace.y,
        //   label: item.inspectPlace.textValue,
        // });
      });

      tempIdCounter++;
      console.log('tempIdCounter:' + tempIdCounter);
    },

    /**
     * メニュー[検査箇所] - [追加] クリック
     */
    handlerMenuAddInpectPlace() {
      console.log('メニュー[検査箇所] - [追加] クリック');
      inspectMenu = InspectPlaceMenu.Add;
    },

    /**
     * 一時POI図形を作図します。
     * ＊検査箇所の新規登録時にコールします。
     *
     * @param {latlng} latlng 座標XY
     */
    addTempPoi(latlng) {
      console.log(`lnglat: ${latlng.lng}, ${latlng.lat}`);

      mlMap.clearTempPoi();

      mlMap.addTempPoi(latlng, {
        color: '#FF6D00',
        opacity: 0.8,
        weight: 4,
        fillColor: '#FF6D00',
        fillOpacity: 0.4,
        radius: 24,
      });
    },

    /**
     * 検査箇所追加
     * @param {int} x 検査箇所座標X
     * @param {int} y 検査箇所座標Y
     * @param {Object} line 通り芯オブジェクト（取得できなかった場合は空配列)
     */
    async addInspectPoi(x, y, line) {

      try {

        // 通り芯名       
        let lineName = ""
        // ToDo
        const margin = 10;
        if (this.fileType == 1) {
          const lineStrings = this.getTorishinStringByPoint(x, y, margin);
          console.log(lineStrings);
          lineName = lineStrings.x + ' ' + lineStrings.y;
        }
        
        // let lineName = "";
        // if (line.length > 0) {
        //   console.log(line);
        //   lineName = line[0].label;
        //   console.log('通り芯名:' + lineName);
        // }

        // DBへ登録
        let dbData = await InspectPlaceModel.getNewData(this.projectId, this.nodeId);

        const placeId = dbData.sk.split('#')[3];
        console.log('placeId:' + placeId);
        dbData.inspectPlace.placeId = placeId;
        dbData.inspectPlace.x = x;
        dbData.inspectPlace.y = y;
        dbData.inspectPlace.textValue = tempIdCounter;
        dbData.inspectPlace.lineName = lineName;

        console.log('検査箇所新規登録：' + JSON.stringify(dbData));

        // 検査箇所属性入力へ渡す
        this.$emit('transfer', {key: "inspectPlaceArgs", newPlaceData: {dbData}});

      } catch (e) {
        alert(`DB登録失敗 ${e.message}`)
        throw e
      }
    },

    /**
     * 検査箇所図形を地図に追加します。
     * ＊属性入力でDB登録後にコールします。
     *
     * @param {dbRowData} dbData 検査箇所DB登録行データ
     */
    addPoi(dbData) {

      console.log(dbData);
      // const inspectPlace = dbData.inspectPlace;

      console.log('inspectPlace' + JSON.stringify(dbData.inspectPlace, null, "\t"));
      console.log('inspectMasterId：' + dbData.inspectPlace.inspectMasterId);
      console.log('iconClass：' + dbData.inspectPlace.iconClass);

      mlMap.clearTempPoi();

      // 検査マスタIDとアイコンclassから、レイヤを決定
      const layerName = this.getPoiLayerName(dbData.inspectPlace.inspectMasterId, dbData.inspectPlace.iconClass);

      if (!layerName) {
        console.log('レイヤ名未定義 検査マスタID:' +
        dbData.inspectPlace.inspectMasterId + ' アイコン定義:' + dbData.inspectPlace.iconClass);
        return;
      }

      console.log('レイヤ名あり：' + layerName);

      let labelText = "test";
      if (dbData.inspectPlace.textValue) {
        labelText = dbData.inspectPlace.textValue;
      }
      console.log('ラベル文字：' + labelText);

      // 図形を追加
      mlMap.addPoi(layerName, {
        id: dbData.inspectPlace.placeId,
        x: dbData.inspectPlace.x,
        y: dbData.inspectPlace.y,
        // label: inspectPlace.name,
        label: labelText,
        layerName: layerName,
      });

      // 保持しておく(移動、削除用)
      this.inspectPlaces.push(dbData);
    },

    /**
     * 検査マスタIDとアイコン定義からレイヤ名を取得します。
     *
     * @param {int} inspectMasterId 検査マスタID
     * @param {int} iconClass アイコン定義
     * @returns レイヤ名称（見つからなかった場合はnull)
     */
    getPoiLayerName(inspectMasterId, iconClass) {
      if (!this.inspectIcons) {
        return null;
      }

      console.log('図形側検査マスタID：' + inspectMasterId);
      console.log('図形側アイコンクラス：' + iconClass);

      for (let i = 0; i < this.inspectIcons.length; i++) {

        if (this.inspectIcons[i].id == inspectMasterId) {

          const iconSettings = this.inspectIcons[i].iconSettings;

          // console.log('アイコンクラス図形側：' + iconClass);
          // console.log('アイコンクラス保持側：' + iconSettings.iconClass);

          if (iconSettings.iconClass == iconClass) {
            return this.inspectIcons[i].layerName;
          }
        }
      }

      return null;
    },

    /**
     * 検査箇所レイヤ名称群を取得します。
     *
     */
    getPoiLayerNames() {

      if (!this.inspectIcons) {
        return null;
      }

      let ret = [];

      for (let i = 0; i < this.inspectIcons.length; i++) {

        ret.push(this.inspectIcons[i].layerName);

      }

      console.log(ret);
      return ret;
    },

    /**
     * 検査箇所POIの選択状態をクリアします。
     */
    clearSelectedPois() {

      // 仮図形も削除
      mlMap.clearTempPoi();

      // すべてのレイヤ名称を取得
      const poiLayerNames = this.getPoiLayerNames();

      for (let i = 0; i < poiLayerNames.length; i++) {
        mlMap.clearSelectedPoi(poiLayerNames[i]);
      }

    },

    /**
     * クリック時のイベント引数から検査箇所POIを取得します。
     *
     * @param {eventArg} e Map上のクリックイベント引数
     * @returns 検査箇所POI
     */
    getPoiItems(e) {

      const poiLayerNames = this.getPoiLayerNames();
      let items = [];

      for (let i = 0; i < poiLayerNames.length; i++) {

        items = mlMap.getPoiByDomMouseEvent(poiLayerNames[i], e.originalEvent);

        console.log(items);

        if (items.length > 0) {
          console.log(items);
          return items;
        }
      }

      return items;

    },

    /**
     * メニュー[検査箇所] - [移動] クリック
     */
    handlerMenuMoveInpectPlace() {
      console.log('メニュー[検査箇所] - [移動] クリック');
      inspectMenu = InspectPlaceMenu.MoveStart;
    },

    /**
     * 検査箇所を更新（座標の移動）します。
     * @param {*} id
     * @param {*} latlng
     */
    async updateInspectPoi(id, latlng) {

      try {
        // 地図クリックで取得したIDから対象の検査箇所データを取得
        await this.getTargetInspectId(id);

        // DBを更新
        let dbData = this.targetInspectPlace;
        console.log(dbData);
        dbData.inspectPlace.x = latlng.lng;
        dbData.inspectPlace.y = latlng.lat;
        InspectPlaceModel.updateInspectPlace(dbData);

        // 図形を更新
        const layerName = this.getPoiLayerName(dbData.inspectPlace.inspectMasterId, dbData.inspectPlace.iconClass);
        const poi = mlMap.getPoiById(layerName, id);
        // const poi = mlMap.getPoiById(poiLayerName, id);
        console.log(poi);
        poi.x = latlng.lng;
        poi.y = latlng.lat;
        mlMap.updatePoi(layerName, poi);
        // mlMap.updatePoi(poiLayerName, poi);

        // 図形の上下(Z-Order)を正しく描画
        mlMap.refreshZOrder();

      } catch (e) {
        alert(`DB更新失敗 ${e.message}`)
        throw e
      } finally {
        inspectMenu = InspectPlaceMenu.None;
      }
    },

    /**
     * メニュー[検査箇所] - [削除] クリック
     */
    handlerMenuDeleteInpectPlace() {
      console.log('メニュー[検査箇所] - [削除] クリック');
      inspectMenu = InspectPlaceMenu.Delete;
    },

    /**
     * 検査箇所を削除します。
     * @param {*} item 削除対象の地図上でクリック取得した図形情報
     */
    async deleteInspectPoi(item) {

      try {
        console.log(item);

        // 地図クリックで取得したIDから対象の検査箇所データを取得
        await this.getTargetInspectId(item.id);

        // DBから削除
        let data = this.targetInspectPlace;

        // 削除フラグ
        data.inspectPlace.deleteFlag = true;
        // 更新日時
        data.timestamp = DateUtil.getUnixTimeStampOfMilliSecond();
        console.log(data)

        InspectPlaceModel.updateInspectPlace(data);
        // InspectPlaceModel.deleteInspectPlace(this.targetInspectPlace);

        // 図形を削除
        // let dbData = this.targetInspectPlace;
        // const layerName = this.getPoiLayerName(dbData.inspectPlace.inspectMasterId, dbData.inspectPlace.iconClass);
        mlMap.removePoiById(item.layerName, item.id);
        // mlMap.removePoiById(poiLayerName, id);

        // 図形の上下(Z-Order)を正しく描画
        mlMap.refreshZOrder();

      } catch (e) {
        alert(`DB削除失敗 ${e.message}`)
        throw e
      }
    },

    /**
     * 対象の手書き作図データを取得します。
     */
    getTargetDrawShape(id) {

      console.log(id);
      // console.log(this.drawShapes);

      this.drawShapes.forEach(item => {
        // console.log(item);
        // console.log(item.shape.shapeId);
        let shapeId = item.shape.shapeId;

        if (shapeId == id) {
          console.log(item);
          this.targetDrawShape = item;
          return;
        }

      });
      // console.log('targetDrawShape:not found');
    },

    /**
     * 対象の検査箇所データを取得します。
     */
    async getTargetInspectId(id) {

      this.inspectPlaces.forEach(item => {

        if (item.inspectPlace.placeId == id) {
          this.targetInspectPlace = item;
        }

      });
    },

    /**
     * 現在の地図表示領域をローカルストレージに保存します。
     * @param {現在の表示領域} bounds
     */
    saveBounds(bounds) {
      const parsed = JSON.stringify(bounds);
      console.log(this.getSeekKeyString() + ':' + parsed);
      localStorage.setItem(this.getSeekKeyString(), parsed);
    },

    /**
     * 指定した値をローカルストレージに保存します。
     * @param {string} keyValue キー文字列
     * @param {string} item 保存するアイテム文字列
     */
    saveItem(keyValue, item) {
      const key = this.createKeyString(keyValue);
      console.log('key:' + key);
      localStorage.setItem(key, item);
    },

    /**
     * ローカルストレージから指定した値を取得します。
     * @param {string} keyValue キー文字列
     * @returns 保存するアイテム文字列
     */
    getItem(keyValue) {
      const key = this.createKeyString(keyValue);
      console.log('key:' + key);
      return localStorage.getItem(key);
    },

    /**
     * ローカルストレージのキーを生成します。
     * @param {string} keyValue キー文字列
     * @returns ローカルストレージのキー文字列
     */
    createKeyString(keyValue) {
      return this.getSeekKeyString() + '#' + keyValue;
    },

    /**
     * sk文字列を取得します。
     */
    getSeekKeyString() {
      const prefix = `backImage`;
      return `${prefix}#${this.projectId}#${this.nodeId}`;
    },

    /**
     * x,y座標からそのポイントに通り芯の文字列を返します。
     * 以下より移植
     *   BackImage.swift
     *   InspectionPatrolSystem
     *
     * @param {Float} x X座標
     * @param {Float} y Y座標
     * @param {Float} margin マージン値
     *
     * @returns X,Yの通り芯文字列
     */
    getTorishinStringByPoint(x, y, margin) {

      if (!this.backImage.lineSetting) {
        return;
      }

      // X
      const xLabels = mlMap.getXBaseLineLabelsByPoint(x, y, margin);

      // Y
      const yLabels = mlMap.getYBaseLineLabelsByPoint(x, y, margin);

      let retStr = {
        x: xLabels.join('-'),
        y: yLabels.join('-')
      }

      // // X座標と通芯の位置関係から通芯名を習得する。
      // // 通芯のy座標値は必ずしも図面上の端部となっているわけでない。検査箇所のy座標値に満たない場合もある

      // let retStr = {
      //   x: '',
      //   y: ''
      // }

      // // X
      // let startX = '';
      // let endX = '';

      // const xLines = this.backImage.lineSetting.filter(function (lineSetting){
      //   let start = lineSetting.startY;
      //   let end = lineSetting.endY;
      //   if (start > end) {
      //     start = lineSetting.endY;
      //     end = lineSetting.startY;
      //   }

      //   console.log('start:' + start + ' end:' + end);
      //   console.log('lineType:' + lineSetting.lineType);

      //   // 分類：垂直のものを取得
      //   // Y座標が通芯の開始と終了の範囲にあるかも考慮する
      //   // ※MLMap.BASE_LINE_DIRECTION.VERTICAL:2 DB lineType縦: 1 のため + 1
      //   return (Number(lineSetting.lineType) + 1) == MLMap.BASE_LINE_DIRECTION.VERTICAL
      //           && start <= (Number(y) + Number(margin))
      //           && end >= (Number(y) - Number(margin))
      // }).sort(function(src, dst) {
      //   // 昇順でソートしておく
      //   if (src.startX < dst.startX) return 1;
      //   return 0;
      // });

      // console.log(xLines);

      // let i = 0;
      // for (let line in xLines) {

      //   line = xLines[i];
      //   i++;

      //   // 始点と終点のXは同じはずなので始点のみを使う
      //   const baseX = parseFloat(line.startX);

      //   // ライン以下の場合は終点がライン
      //   if (x < baseX - margin) {
      //     endX = line.lineName;

      //     // 最小値より小さい場合は確定
      //     if (line.streetlineId == xLines[0].streetlineId) {
      //       startX = line.lineName;
      //     }
      //   }

      //   // ライン以上の場合は始点がライン
      //   if (x > baseX + margin) {
      //     startX = line.lineName;

      //     // 最大値より大きい場合は確定
      //     if (line.streetlineId == xLines.slice(-1)[0].streetlineId) {
      //       endX = line.lineName;
      //     }
      //   }

      //   // ライン上（マージン込み）＞確定
      //   if (baseX - margin <= x && x <= baseX + margin) {
      //     startX = line.lineName;
      //     endX = line.lineName;
      //   }

      //   // 始点と終点が入っていれば確定
      //   if (startX && endX) {
      //     break;
      //   }

      // }

      // // 別々の場合は範囲、同じ場合はライン上か範囲外
      // if (startX != endX) {
      //   retStr.x = startX + '-' + endX;
      // } else {
      //   retStr.x = startX;
      // }

      // // Y
      // let startY = '';
      // let endY = '';

      // const yLines = this.backImage.lineSetting.filter(function (lineSetting){
      //   let start = lineSetting.startX;
      //   let end = lineSetting.endX;

      //   if (start > end) {
      //     start = lineSetting.endX;
      //     end = lineSetting.startX;
      //   }

      //   console.log('start:' + start + ' end:' + end);
      //   console.log('lineType:' + lineSetting.lineType);
      //   console.log('MLMap.BASE_LINE_DIRECTION.HORIZONTAL:' + MLMap.BASE_LINE_DIRECTION.HORIZONTAL);

      //   // 分類：水平のものを取得する
      //   // X座標が通芯の開始と終了の範囲にあるかも考慮する
      //   // ※MLMap.BASE_LINE_DIRECTION.HORIZONTAL:1 DB lineType横: 0 のため + 1
      //   return (Number(lineSetting.lineType) + 1) == MLMap.BASE_LINE_DIRECTION.HORIZONTAL
      //       && start <= (Number(x) + Number(margin))
      //       && end >= (Number(x) - Number(margin))
      // }).sort(function(src, dst) {
      //   // 昇順でソートしておく
      //   if (src.startY < dst.startY) return 1;
      //   return 0;
      // });

      // console.log(yLines);

      // i = 0;
      // for (let line in yLines) {
      //   console.log(line);
      //   line = yLines[i];
      //   i++;

      //   // 始点と終点のYは同じはずなので始点のみを使う
      //   const baseY = parseFloat(line.startY);

      //   console.log('baseY:' + baseY + ' y:' + y + ' margin:' + margin);

      //   // ライン以下の場合は終点がライン
      //   if (y < baseY - margin) {
      //     endY = line.lineName;

      //     // 最小値より小さい場合は確定
      //     if (line.streetlineId == yLines[0].streetlineId) {
      //       startY = line.lineName;
      //     }
      //   }

      //   // ライン以上の場合は始点がライン
      //   if (y > baseY + margin) {
      //     startY = line.lineName;

      //     // 最大値より大きい場合は確定
      //     if (line.streetlineId == yLines.slice(-1)[0].streetlineId) {
      //       endY = line.lineName;
      //     }
      //   }

      //   // ライン上（マージン込み）＞確定
      //   if (baseY - margin <= y && y <= baseY + margin) {
      //     startY = line.lineName
      //     endY = line.lineName
      //   }

      //   // 始点と終点が入っていれば確定
      //   if (startY && endY) {
      //     break;
      //   }
      // }

      // console.log('startY:' + startY + ' endY:' + endY);

      // // 別々の場合は範囲、同じ場合はライン上か範囲外
      // if (startY != endY) {
      //   retStr.y = startY + '-' + endY;
      // } else {
      //   retStr.y = startY;
      // }

      // 結果を返す
      return retStr;
    },

    /**
     * 現在地へ移動します。
     */
    moveCurrentLocation() {

      if (navigator.geolocation == false) {
        return;
      }

      function success(e) {
        let lat = e.coords.latitude;
        let lng = e.coords.longitude;

        mlMap.panTo({lat: lat, lng: lng});
      }

      function error() {
        console.log('現在地取得エラー');
      }

      navigator.geolocation.getCurrentPosition(success, error);
    },

    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },

    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },

    reload() {
      this.$router.go({path: this.$router.currentRoute.path, force: true});
    }
  },
};
</script>
