<template>
  <div>
    <div class="container-fluid">
      <!-- <div class="row shadow-1 pt-2 pl-2 fixed-top bg-white">
        <div class="col-sm-10 text-left ">
          <h4 class="content-title">ログインユーザ：{{loginUserName}}</h4>
        </div>
        <div class="col-sm-2">
          <button v-if="true" type="button" class="btn btn-primary pt-0 pb-0" @click="signOut">サインアウト</button>&nbsp;
        </div>
      </div> -->

      <div class="row mt-0 no-gutters">
        <div class="col-md-12 col-lg-3">
          <div class="subContent searchForm" v-if="true">
            <div class="row pt-3 pb-2 pl-2 pr-3 text-left">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-12">
                    <h4 class="content-title">検索条件</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="row text-left no-gutters">
              <div class="col-lg-12 pl-3 pr-2">
                <div class="row justify-content-end" >
                  <div class="col-sm-12 mb-2">
                    <label class="form-control-label">測定タイプ</label><br />
                    <input
                      class="form-check-input ml-1"
                      type="checkbox"
                      v-model="serachCondition.isgap"
                      id="isgap"
                    />
                    <label
                      class="form-check-label ml-4"
                      for="isgap"
                      >配筋間隔</label
                    >
                    <input
                      class="form-check-input ml-1"
                      type="checkbox"
                      v-model="serachCondition.ismargin"
                      id="ismargin"
                    />
                    <label
                      class="form-check-label ml-4"
                      for="ismargin"
                      >かぶり</label
                    >
                    <input
                      class="form-check-input ml-1"
                      type="checkbox"
                      v-model="serachCondition.isjoint"
                      id="isjoint"
                    />
                    <label
                      class="form-check-label ml-4"
                      for="isjoint"
                      >重ね継手長</label
                    >
                  </div>
                  <div class="col-sm-12">
                    <base-input
                      type="text"
                      label="測定日(From)"
                    ><template></template>
                      <el-date-picker
                        v-model="serachCondition.from"
                        type="date"
                        class="w-100"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        placeholder="測定日(From)"
                        >
                      </el-date-picker>
                    </base-input>
                  </div>
                </div>
                <div class="row justify-content-end" >
                  <div class="col-sm-12">
                    
                    <base-input
                      type="text"
                      label="測定日(To)"
                    ><template></template>
                      <el-date-picker
                        v-model="serachCondition.to"
                        type="date"
                        class="w-100"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        placeholder="測定日(To)"
                        >
                      </el-date-picker>
                    </base-input>
                    
                  </div>
                </div>
                <div class="row justify-content-end" >
                  <div class="col-sm-12">
                    
                    <base-input
                      type="text"
                      label="測点"
                    ><template></template>
                      <el-autocomplete
                        class="inline-input w-100"
                        v-model="serachCondition.shootingSpot"
                        :fetch-suggestions="shootingSpotSeach"
                        placeholder="測点を入力"
                        @select="handleSelect"
                        clearable
                      ></el-autocomplete>
                    </base-input>
                  </div>
                </div>
                <div class="row justify-content-end" >
                  <div class="col-sm-12">
                    <base-input
                      type="text"
                      label="測定者"
                    ><template></template>
                      <el-autocomplete
                        class="inline-input w-100"
                        v-model="serachCondition.measurerName"
                        :fetch-suggestions="measurerNameSeach"
                        placeholder="測定者を入力"
                        @select="handleSelect"
                        clearable
                      ></el-autocomplete>
                    </base-input>
                  </div>
                </div>
                <div class="row justify-content-end" >
                  <div class="col-sm-12">
                    <base-input
                      type="text"
                      label="詳細箇所名"
                    ><template></template>
                      <el-autocomplete
                        class="inline-input w-100"
                        v-model="serachCondition.detailSpot"
                        :fetch-suggestions="detailSpotSeach"
                        placeholder="詳細箇所名を入力"
                        @select="handleSelect"
                        clearable
                      ></el-autocomplete>
                    </base-input>
                    </div>
                </div>
              </div>
            </div>
            <div class="row text-left mt-2 mb-2">
              <div class="col-lg-5">
                <button type="button" class="btn btn-white w-100" @click="searchClear">クリア</button>
              </div>
              <div class="col-lg-1">
                
              </div>
              <div class="col-lg-5">
                <button type="button" class="btn btn-rebarprimary w-100" @click="seachList">検索</button>
              </div>
            </div>
          </div>
        <!-- </div>

        <div class="col-md-12 col-lg-3"> -->
          <div class="subContent">
            <div class="row pt-3 pb-2 pl-2 pr-3 text-left">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-12">
                    <h4 class="content-title">帳票出力</h4>
                  </div>
                </div>
                <div class="row mt-3" >
                  <!-- <div class="col-sm-12">
                    <h5 class="content-title border-bottom border-dark">様式選択</h5>
                  </div> -->

                  <div class="col-12">
                    <label class="form-control-label">様式選択 <!--v-if--></label>
                    <select v-model="selectedTemplate" @change="onSelectedTemplate" class="w-100 form-control form-control-border-gray">
                      <option disabled value="" >選択してください</option>
                      <option v-for="formTemplate in formTemplateList"
                        v-bind:value="formTemplate"
                        v-bind:key="formTemplate.id">
                      {{ formTemplate.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3 mb-3" >
                  <div class="col-sm-12" v-if="env == 'local' && false">
                    <a v-if="mentenaceMode" class="text-rebarprimary" @click="onClickformManage">帳票テンプレート管理</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <!-- <div class="row mt-3 justify-content-end"> -->
                  <!-- <div class="col-lg-3"> -->
                    <button type="button" class="btn btn-rebarprimary w-100" @click="createForm">帳票出力</button>
                  <!-- </div> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-9 ">
          <div class="subContent">
            <div class="row pt-3 pb-2 pl-2 pr-3 no-gutters">
              <div class="col">
                <h4 class="content-title text-left ">測定データ一覧</h4>
              </div>
              <div class="col text-right">
                <base-button
                  round
                  type="danger"
                  class="btn-icon-only"
                  @click.prevent="onClickDelete()"
                >
                  <span class="btn-inner--icon"
                    ><i class="fa fa-trash-alt"></i
                  ></span>
                </base-button>
              </div>
              
              <div class="col-lg-3" v-if="false">
                <input type="file" id="xml_file" @change="upload" /> <br />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <div class="mt-1 d-flex justify-content-center">
                  <!-- <base-pagination v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total"></base-pagination> -->
                  <el-pagination
                    @current-change="setCurrent"
                    :page-size="pagination.perPage"
                    :current-page="pagination.currentPage"
                    layout="prev, pager, next"
                    :total="total">
                  </el-pagination>
                </div>
              </div>
            </div>
            <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light app-th-row"
            header-cell-class-name="measure-list-header"
            ref="measureListTable" 
            :cell-style="{padding: '6px'}"
            @selection-change="handleSelectionChange"
            @sort-change="runSort"
            :default-sort="{ prop: 'name', order: 'descending' }"
            :row-key="getRowKey"
            :data="pagedData">
              <el-table-column type="selection" align="left"  :reserve-selection="true" min-width="40px" >
              </el-table-column>
              <el-table-column label="測定日/測点" min-width="300px" prop="name" sortable="'custom'">
                  <template v-slot="{ row }">
                  <!-- <img :src="row.project.image" class="avatar rounded-circle mr-3" /> -->
                    {{row.rebarmeasure.title}}<br />
                    <b>
                      <a href="#!" class="text-rebarprimary text-truncate"
                          @click.prevent="onClickMeasure(row)">{{ row.rebarmeasure.shootingSpot }}
                      </a>
                    </b>
                  </template>
              </el-table-column>
              <el-table-column label="測定タイプ" min-width="140px" prop="measurerName" sortable="'custom'">
                <template v-slot="{ row }">
                  <span v-if="row.type == '0'" class="text-truncate">配筋間隔</span>
                  <span v-if="row.type == '1'" class="text-truncate">かぶり</span>
                  <span v-if="row.type == '2'" class="text-truncate">重ね継手長</span>
                </template>
              </el-table-column>
              <el-table-column label="測定者" min-width="140px" prop="measurerName" sortable="'custom'">
                <template v-slot="{ row }">
                  <span class="text-truncate">{{row.rebarmeasure.measurerName}}</span>
                </template>
              </el-table-column>
              <el-table-column label="工種" min-width="140px" prop="constructionType" sortable="'custom'">
                <template v-slot="{ row }">
                  <span class="text-truncate">{{row.rebarmeasure.constructionType}}</span>
                </template>
              </el-table-column>
              <el-table-column label="詳細箇所名" min-width="200px" prop="detailSpot" sortable="'custom'">
                <template v-slot="{ row }">
                  <span class="text-truncate">{{row.rebarmeasure.detailSpot}}</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="row mt-1">
              <div class="col">
                <div class="mt-1 d-flex justify-content-center">
                  <!-- <base-pagination v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total"></base-pagination> -->
                  <el-pagination
                    @current-change="setCurrent"
                    :page-size="pagination.perPage"
                    :current-page="pagination.currentPage"
                    layout="prev, pager, next"
                    :total="total">
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #007bff !important;
    background-color: #007bff !important;
  }
  .el-checkbox__input.is-checked+.el-checkbox__label{
    color: #007bff !important;
  }
  .el-checkbox__inner:hover{
    border-color: #007bff !important;
  }
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #007bff !important;
  }

</style>
<style lang="scss" scoped>
  .form-control {
    border:1px solid #999999 !important;
  }

</style>
<style>
.ct-notification .Vue-Toastification__toast--default {
  padding: 0;
  overflow: inherit;
  box-shadow: none;
  background-color: transparent;
}

.Vue-Toastification__container{
  z-index: 999999 !important;
}

.measure-list-header {
  color:black !important;
}

.searchForm .form-group {
  margin-bottom:0.5em !important;
}
</style>
<style>
@import "./css/common.css";
@import "./css/list.css";

</style>
<script>
// import { Auth } from "aws-amplify";
import { Storage } from "aws-amplify";
import MeasureModel from "../../appModel/rebar/MeasureModel";
import FormFactory from "./form/FormFactory"
import axios from 'axios';
import API from '@aws-amplify/api';
// import crypto from "crypto";
// import cryptoBrowserify from "crypto-browserify";
import { useToast } from "vue-toastification";

import "sweetalert2/dist/sweetalert2.css";

// import BaseFormCreator from "./form/BaseFormCreator";
const BaseFormCreator = require("./form/BaseFormCreator");
const path = require('path')
const { stringify } = require("csv-stringify/sync");
const generate = require("csv-generate");
import JSZip from "jszip";
import { max } from 'moment';
import FormTemplateModel from "@/appModel/FormTemplate/FormTemplateModel"
import UserInfo from "../../appUtils/UserInfo"
import appLog from "../../appUtils/AppLog"
import AuthUtil from "../../appUtils/AuthUtil"
import DateUtil from '../../appUtils/DateUtil'
import flatPicker from "vue-flatpickr-component";
import clonedeep from 'lodash/cloneDeep';


export default {
  components: {
    flatPicker,
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
    // console.log(this.sortColumn)
    
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init()
    this.mentenaceMode = process.env.NODE_ENV == "local"
    if (!this.sortColumn.prop) {
      // 初期ソート
      this.sortColumn = {prop: `title`, order: `descending`}
    }
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  data() {
    return {
      mentenaceMode: false,
      serachCondition: {
        // 測定日(日付1～日付2のように範囲指定)
        // 測点
        // 測定者
        // 工種
        // 詳細箇所名
        from: "",
        to: "",
        shootingSpot: "",
        measurerName: "",
        detailSpot: "",
        isgap: true,
        ismargin: true,
        isjoint: true
      },
      suggestions: {
        shootingSpot: [],
        measurerName: [],
        detailSpot: [],
      },
      measures: [],
      measuresAll: [],
      selectedTemplate: null,
      designRangeList: [
        {id: "0", name: "±Φ"},
        {id: "1", name: "±10"},
        {id: "2", name: "±20"},
        {id: "3", name: "±20(±10)"},
      ],
      typelist: [
        {id: "0", name: "鉄筋工"},
        {id: "1", name: "床版工"},
      ],
      formTemplateList: [{
        "id": 3,
        "name": "様式３（国土交通省様式）",
        "order": 1,
        "template": "public/rebarxlsxtemplate/format_3_mlit_v1_1_type0.xlsx"
      },
      {
        "id": 4,
        "name": "CSV出力",
        "order": 3,
        "template": "format_4.csv"
      }
      ],
      selectedData: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      sortColumn: {},
      env: "dev",
      formTemplateDatas: []
    };
  },
  watch: {},
  /**
   * コンピュートプロパティ
   */
  computed: {
    /***
     * ページング用のデータをかえす
     */
    pagedData() {
      // console.log('pagedData:' + JSON.stringify(this.measures, null, "\t"))
      return this.measures.slice(this.from, this.to)
    },
    /**
     * ページング制御用
     */
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    /**
     * ページング制御用
     */
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    /**
     * ページング制御用
     */
    total() {
      return this.measures.length;
    },
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * ページ変更時のハンドラ
     */
    setCurrent(newPage) {

      this.pagination.currentPage = newPage
    },
    getRowKey(row){
      return row.sk
    },
    async init() {
      await this.outputLog(`info`, `start List.`)

      this.loader = this.$loading.show({color: '#11cdef', backgroundColor: "#333333"});

      try {

        // 検索条件を復元
        let key = await this.getUserId()
        let savedSerachCondition = JSON.parse(localStorage.getItem(key));
        if (savedSerachCondition){
          this.serachCondition = savedSerachCondition
        }

        // 測定データリストを取得する
        this.measuresAll = await MeasureModel.getMeasureList()

        this.measures = await this.searchFilter()
        // console.log(`件数: ${this.measures.length}`)
        // console.log(this.measures)

        if (this.sortColumn) {
          await this.changeTableSort(this.sortColumn);
        }
        // this.sortColumn = {};

        await this.loadFormType()

        // サジェストリストを初期化
        this.initSuggestions()

        // 環境変数取得
        this.env = process.env.NODE_ENV

        // ユーザ毎の帳票テンプレートを取得
        await this.loadTemplateData()

        // ユーザ毎の帳票テンプレートをマージ
        this.margeTemplateList()

      } finally {
        this.loader.hide()
      }
    },
    /**
     * サジェスト表示用のデータを初期化する
     */
    async initSuggestions() {
      // 測点
      let shootingSpotDic = {}
      // 測定者
      let measurerNameDic = {}
      // 詳細箇所
      let detailSpotDic = {}
      // 測定データの値からサジェスト表示用の値を取得
      this.measures.forEach(function(item) {
        shootingSpotDic[item.rebarmeasure.shootingSpot] = item.rebarmeasure.shootingSpot
        measurerNameDic[item.rebarmeasure.measurerName] = item.rebarmeasure.measurerName
        detailSpotDic[item.rebarmeasure.detailSpot] = item.rebarmeasure.detailSpot
      }.bind(this))

      this.suggestions.shootingSpot = []
      this.suggestions.measurerName = []
      this.suggestions.detailSpot = []
      // Element-UI用のデータ形式に変換
      Object.keys(shootingSpotDic).forEach(value => {
        this.suggestions.shootingSpot.push({ "value": value, "link": "" })
      })
      // Element-UI用のデータ形式に変換
      Object.keys(measurerNameDic).forEach(value => {
        this.suggestions.measurerName.push({ "value": value, "link": "" })
      })
      // Element-UI用のデータ形式に変換
      Object.keys(detailSpotDic).forEach(value => {
        this.suggestions.detailSpot.push({ "value": value, "link": "" })
      })
    },
    /**
     * 検索ボタンのイベントハンドラ
     */
    async seachList() {
      try {
        this.loader = this.$loading.show({color: '#11cdef', backgroundColor: "#333333"});

        // テーブル選択状態をクリア
        this.clearSelect()
        // 測定データリストを取得する
        this.measuresAll = await MeasureModel.getMeasureList()
        // 検索条件でフィルターする
        this.measures = await this.searchFilter()
        // ソート
        if (this.sortColumn) {
          await this.changeTableSort(this.sortColumn);
        }
        // ローカルストレージに検索条件を保存する
        let key = await this.getUserId()
        const parsed = JSON.stringify(this.serachCondition);
        localStorage.setItem(key, parsed);

      } finally {
        this.loader.hide()
      }
      
    },
    /**
     * 検索条件クリア
     */
    async searchClear() {
      // テーブル選択状態をクリア
      this.clearSelect()  
      // 検索条件をクリア
      this.serachCondition.from = ""
      this.serachCondition.to = ""
      this.serachCondition.shootingSpot = ""
      this.serachCondition.measurerName = ""
      this.serachCondition.detailSpot = ""
      this.serachCondition.isgap = true
      this.serachCondition.ismargin = true
      this.serachCondition.isjoint = true

      // ローカルストレージもクリア
      let key = await this.getUserId()
      localStorage.removeItem(key);

      // 検索条件でフィルターする
      this.measures = await this.searchFilter()
    },
    /**
     * テーブルの選択状態をクリアする
     */
    clearSelect() {
      this.$refs.measureListTable.clearSelection()
      this.selectedData = []
    },
    /**
     * 検索条件の内容でフィルタする
     */
    async searchFilter() {
      // フィルタ前データを取得
      let filterData = this.measuresAll
      
      filterData.forEach(function(item) {
        let skList = item.sk.split("#")
        // type 0:配筋間隔 1:かぶり 2:継手長  
        let type = "0"
        if (skList.length >= 3) {
          type = skList[1]
        }
        item.type = type
      }.bind(this))

      filterData = filterData.filter(function(item) {
        
        switch (item.type) {
        case "0":
          return this.serachCondition.isgap
        case "1":
          return this.serachCondition.ismargin
        case "2":
          return this.serachCondition.isjoint
        default:
          return false
        }
      }.bind(this))

      // 配筋間隔の測定データ
      if (this.serachCondition.isgap) {
        //
      }

      // かぶりの測定データ

      // 継手長の測定データ

      if (this.serachCondition.from || this.serachCondition.to) {
        let from = "2021-01-01"
        if (this.serachCondition.from) {
          from = DateUtil.dateStringDate(this.serachCondition.from)
        }
        let to = DateUtil.getDateString()
        if (this.serachCondition.to) {
          to = DateUtil.dateStringDate(this.serachCondition.to)
        }
        //let from = this.serachCondition.from ? this.serachCondition.from : "2021-01-01"
        //let to = this.serachCondition.to ? this.serachCondition.to : DateUtil.getDateString()

        filterData = filterData.filter(function(item) {
          let target = item.rebarmeasure.title
          return DateUtil.isWithinPeriodTarget(from, to, target)
        }.bind(this))
      }

      if (this.serachCondition.shootingSpot) {
        filterData = filterData.filter(function(item) {
          return item.rebarmeasure.shootingSpot.indexOf(this.serachCondition.shootingSpot) >= 0
        }.bind(this))
      }

      if (this.serachCondition.measurerName) {
        filterData = filterData.filter(function(item) {
          return item.rebarmeasure.measurerName.indexOf(this.serachCondition.measurerName) >= 0
        }.bind(this))
      }

      if (this.serachCondition.detailSpot) {
        filterData = filterData.filter(function(item) {
          return item.rebarmeasure.detailSpot.indexOf(this.serachCondition.detailSpot) >= 0
        }.bind(this))
      }

      
      return filterData
      
    },
    shootingSpotSeach(queryString, cb) {
      if (this.suggestions.shootingSpot.length == 0) {
        cb([])
      }
      if (!queryString) {
        cb(this.suggestions.shootingSpot)
      }
      
      let results = this.suggestions.shootingSpot.filter(item => {
        return item.value.indexOf(queryString) >= 0
      })
      
      cb(results);
    },
    detailSpotSeach(queryString, cb){
      if (this.suggestions.detailSpot.length == 0) {
        cb([])
      }
      if (!queryString) {
        cb(this.suggestions.detailSpot)
      }

      let results = this.suggestions.detailSpot.filter(item => {
        return item.value.indexOf(queryString) >= 0
      })
      
      cb(results);
    },
    measurerNameSeach(queryString, cb){
      if (this.suggestions.measurerName.length == 0) {
        cb([])
      }
      if (!queryString) {
        cb(this.suggestions.measurerName)
      }
      let results = this.suggestions.measurerName.filter(item => {
        return item.value.indexOf(queryString) >= 0
      })
      
      cb(results);
    },
    /**
     * 測定データの選択
     */
    onClickMeasure(row) {
      this.$store.commit('setMeasureData', row)

      this.outputLog(`info`, `go to Detail:${row.sk}`)

      this.$router.push({
        path: `/detail`,
        query: { id: `${row.sk}` },
        // name: 'Detail'
      })
    },
    /**
     * 帳票種別の表示。サーバ上に配置しているJSONファイルをリスト表示する。
     */
    async loadFormType() {
      // const result = await Storage.get("formType.json", { download: true })
      // result.Body.text().then(string => {

      //     let formTypeData = JSON.parse(string)
      //     this.formTemplateList = formTypeData.list
      //   this.selectedTemplate = this.formTemplateList[0]

      //   console.log(this.formTemplateList)
      // })
      this.selectedTemplate = this.formTemplateList[0]
    },
    handleSelectionChange(rowDatas) {
      // alert(rowData.length)
      // alert(JSON.stringify(rowData, null, "\t"))
      this.selectedData = rowDatas
      console.log(this.selectedData)
    },
    /**
     * アップロード用REST API呼び出しテストコード
     */
    async upload(e) {
      try {

        console.log(`upload`)
        const data = e.target.files || e.dataTransfer.files

        let base64 = await this.getBase64(data[0]);
        console.log(`base64: ${base64}`)

        if (!base64) {
          return
        }
        const apiName = 'apimeasuredata';
        const path = '/import';
        const checksum = ""

        console.log(`checksum: ${checksum}`)
        const myInit = {
          response: true,
          body: {
            checksum: checksum,
            data: base64
          }
        };

        API.post(apiName, path, myInit).then(response => {
          // Add your code here
          const data = response.data
          alert(`${data.code},${data.message}`)
        })
          .catch(error => {
            alert(`ng : ${error}`)
            console.log(error.response);
          });
      } catch (e) {

        console.log(`error: ${JSON.stringify(e)}`)
        throw e
      }
      
    },
    getHash(encodedData) {

      let decodedFile = null
      try {
        const fileData = encodedData.replace(/^data:\w+\/.+;base64,/, '')

        decodedFile = Buffer.from(fileData, 'base64')

      } catch (e) {
        throw new Error(`4,デコードに失敗`)
      }

      const hash = crypto.createHash('sha256').update(decodedFile).digest('hex')
      return hash
    },
    getBase64 (file) {

      return new Promise((resolve, reject) => {

        const reader = new FileReader()
        console.log(`readAsDataURL: ${file}`)
        reader.readAsDataURL(file)

        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },

    /**
     * 帳票作成を行う
    */
    async createForm () {
      try {
        console.log('createForm')
        // セッション切れ確認
        let isValid = await this.isValidSession();
        if (!isValid) {
          return;
        }

        if (!this.selectedTemplate.template) {
          this.showBottomToast(`様式を選択して下さい`, `error`)
          return
        }

        if (this.selectedData.length == 0) {
          this.showBottomToast(`出力するデータを選択してください。`, `error`)
          return
        }
        let formDataMax = 10
        if (process.env.VUE_APP_FORMDATA_MAX) {
          formDataMax = process.env.VUE_APP_FORMDATA_MAX
        }
        if (this.selectedData.length > formDataMax) {
          this.showBottomToast(`${this.selectedData.length}件選択しています。` + '\r'
                              + `出力する測定データは${formDataMax}件以内で選択してください。`, `error`)
          return
        }

        if (this.selectedTemplate.name == 'CSV出力') {
          // CSV出力処理へ
          this.showBottomToast(`CSV作成中です。このままでしばらくお待ちください。`, `info`)
          await this.downloadCsv()
          return
        }

        // かぶり、長さ継手長は国土交通省フォーマットでのみ出力可能
        let type0List = []
        let type1List = []
        let type2List = []

        const template_type1 = "public/rebarxlsxtemplate/format_3_mlit_v1_1_type1.xlsx"
        const template_type2 = "public/rebarxlsxtemplate/format_3_mlit_v1_1_type2.xlsx"

        this.selectedData.forEach(data => {
          switch (data.type) {
          case "0":
            // 配筋間隔
            type0List.push(data)
            break;
          case "1":
            // かぶり
            type1List.push(data)
            break;
          case "2":
            // 重ね継手長
            type2List.push(data)
            break;
          }
        })

        // 対象が0件であれば終了する
        if (type0List.length == 0 & type1List.length == 0 && type2List.length == 0) {
          this.showBottomToast(`出力するデータを選択してください。`, `error`)
          return
        }
        // 規格値未設定チェック
        let invalidDesignList = []
        type0List.forEach(data => {
          // 配筋間隔は未設定は空文字
          if (data.rebarmeasure.designRangeClass == "") {
            invalidDesignList.push(data)
          }
        })
        type1List.forEach(data => {
          // かぶりは未設定=0
          if (data.rebarmeasure.designRangeClass == "0") {
            invalidDesignList.push(data)
          } else if ((data.rebarmeasure.designRangeClass == "1" || data.rebarmeasure.designRangeClass == "2" || data.rebarmeasure.designRangeClass == "3") && (!data.rebarmeasure.designRangeValue && !(data.rebarmeasure.designRangeValue === 0))) {
            
            invalidDesignList.push(data)
          }
        })
        type2List.forEach(data => {
          // 重ね継手長は自由入力
          if (!data.rebarmeasure.designRangeValue && !(data.rebarmeasure.designRangeValue === 0)) {
            invalidDesignList.push(data)
          }
        })
        
        if (invalidDesignList.length > 0) {
        //   this.showBottomToast(`規格値が未設定のデータが指定されています。`, `error`)
        //   return
          if (!window.confirm("規格値が未設定の測定データが存在します。帳票出力を続けますか？")) {
            return
          }
        }

        console.log(`this.selectedTemplate.id ${this.selectedTemplate.id}`)
        
        // ローディング表示
        this.loader = this.$loading.show({color: '#11cdef', backgroundColor: "#333333"});

        if (this.selectedTemplate.id == "3") {
          // 国交省フォーマットを選択している場合はかぶり、長さ継手長の帳票を作成
          const type0Template = this.selectedTemplate
          let type1Template = clonedeep(this.selectedTemplate)
          type1Template.template = template_type1
          let type2Template = clonedeep(this.selectedTemplate)
          type2Template.template = template_type2

          if (type0List.length > 0) {
            this.showBottomToast(`測定タイプ：配筋間隔の帳票作成中です。このままでしばらくお待ちください。`, `info`)
            await this.requestForm(type0List, this.selectedTemplate.id, type0Template, function(){
              if (type1List.length == 0 && type2List.length == 0) {
                this.loader.hide()
              }
            }.bind(this))
          }
          if (type1List.length > 0) {
            // this.loader = this.$loading.show({color: '#11cdef', backgroundColor: "#333333"});
            this.showBottomToast(`測定タイプ：かぶりの帳票作成中です。このままでしばらくお待ちください。`, `info`)
            await this.requestForm(type1List, 4, type1Template, function(){
              if (type2List.length == 0) {
                this.loader.hide()
              }
            }.bind(this))
          }
          if (type2List.length > 0) {
            // this.loader = this.$loading.show({color: '#11cdef', backgroundColor: "#333333"});
            this.showBottomToast(`測定タイプ：重ね継手長の帳票作成中です。このままでしばらくお待ちください。`, `info`)
            await this.requestForm(type2List, 5, type2Template, function(){
              this.loader.hide()
            }.bind(this))
          }
        } else {
          // 国交省フォーマット以外は配筋間隔のみ出力可能
          if (type0List.length > 0) {
            await this.requestForm(type0List, this.selectedTemplate.id, this.selectedTemplate, function(){
              this.loader.hide()
            }.bind(this))
          } else {
            this.showBottomToast(`配筋間隔のデータが選択されていません。`, `error`)
            this.loader.hide()
            return 
          }
        }


        // タイプ毎に帳票作成を実行する

        console.log(`template:${this.selectedTemplate.id}`)
        
      } catch (e) {
        await this.outputLog(`error`, `帳票作成エラー ${e.stack}`)
        this.loader.hide()
      }
    },
    /**
     * 帳票作成のリクエストを行う
     */
    async requestForm(measureList, creatorTypeId, selectedTemplate, callback) {
      let factory = new FormFactory(creatorTypeId)
      await factory.formCreator.setInitValue()
      const dataKeys = await this.getSelectedDataKeys(measureList);
      try {
        factory.formCreator.makeFormData(measureList, selectedTemplate)
        const excelUrl = await factory.formCreator.invokeFormApi()
        axios({
          url: excelUrl,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          this.showBottomToast(`帳票作成が完了致しました。自動的にダウンロードが開始されます。`, `info`)
          const link = document.createElement('a');
          link.href = url;
          //link.setAttribute('download', outFileName);
          link.setAttribute('download', factory.formCreator.apiResult.data.formFileName);
          document.body.appendChild(link);
          link.click();
          //link.revokeObjectURL();

          this.outputLog(`info`, `created excel: ${factory.formCreator.apiResult.data.formFileName} selectedData:${dataKeys}`)
        }).finally(() => {
          callback(true)
          // this.loader.hide()
        });
      } catch (e) {
        this.showBottomToast(`帳票の作成に失敗しました`, `error`)
        // this.loader.hide()
        this.outputLog(`info`, `failed create excel: selectedData:${dataKeys}`)
        callback(false)
        throw e
      }
    },
    /**
     * 選択データのキー文字列を取得します。
     * (複数時はカンマ区切り)
     * @param {Object} datas 選択データリスト
     */
    async getSelectedDataKeys(datas) {
      if (!datas) {
        return;
      }
      let keys = [];

      for (let i = 0; i < datas.length; i++) {
        const data = datas[i];
        keys.push(data.sk);
      }

      const keyString = keys.join(', ');
      console.log(keyString);
      return keyString;
    },
    /**
     * CSVダウンロード
    */
    async downloadCsv() {
      console.log(this.selectedData);

      let creator = new BaseFormCreator();
      const currentDateString = creator.getCurrentDateString("YYYYMMDDHHmmssSSS");

      const dataKeys = await this.getSelectedDataKeys(this.selectedData);

      try {
        this.loader = this.$loading.show({color: '#11cdef', backgroundColor: "#333333"});

        // CSV用データ生成
        let csvDatas = this.createCsvData();
        let columns = this.createCsvColumns();
        //2022/11/28
        console.log(csvDatas);
        console.log(columns);
        // const csv = generate({
        //   objectMode: false,
        //   rowDelimiter: '\r\n'
        // }).pipe(stringify(csvDatas,
        //   {header: true,
        //     quoted: true,
        //     columns: columns,
        //   }))
        const csv = stringify(csvDatas,
          {header: true,
            quoted: true,
            record_delimiter: 'windows',
            columns: columns,
          })

        console.log(csv);

        // BOM
        let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

        // CSVフォーマットの文字列をblobに
        let csvBlob = new Blob([bom, csv], {type: 'text/csv'});

        // zip作成のパッケージを初期化
        const zip = new JSZip();

        // CSVのバイナリ追加
        zip.file('csv_' + currentDateString + '.csv', csvBlob);
        // zip.file(this.createCsvFileName(), csvBlob);

        // 画像データのリストを取得
        const images = await this.getImages();
        console.log(images);

        for (let i = 0; i < images.length; i++) {
          const image = images[i];
          // 画像のバイナリ追加
          zip.file(image.fileName, image.imageBlob);
        }

        // zipのバイナリ作成
        console.log(zip);
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // ブラウザにダウンロードダイアログを表示する処理
        const a = document.createElement('a');
        a.href = URL.createObjectURL(zipBlob);
        a.download = 'csvfiles_' + currentDateString + '.zip';

        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        this.showBottomToast(`CSVを出力しました。`, `info`)
        this.outputLog(`info`, `outputed csv : ${'csvfiles_' + currentDateString + '.zip'} selectedData:${dataKeys}`)

      } catch (e) {
        this.showBottomToast(`CSVの作成に失敗しました`, `error`)
        this.outputLog(`error`, `failed out csv : ${'csvfiles_' + currentDateString + '.zip'} selectedData:${dataKeys}`)
        throw e
      } finally {
        this.loader.hide()

      }

    },
    /**
     * CSV用データ生成
    */
    createCsvData() {
      let csvDatas = [];

      this.selectedData.forEach((data) => {
        let rebar1DesignRangeValue = ``
        let rebar1LocalDesignRangeValue = ``
        let rebar2DesignRangeValue = ``
        let rebar2LocalDesignRangeValue = ``

        let kaburiDesignRangeValue = ``
        let kaburiLocalDesignRangeValue = ``
        let jointDesignRangeValue = ``
        let jointLocalDesignRangeValue = ``
        let kaburiDesignValue = ``
        let jointDesignValue = ``
        let kaburiValue = ``
        let jointValue = ``

        const rebar = data.rebarmeasure;
        let creator = new BaseFormCreator();

        switch (data.type) {
        case "0":
          // 配筋間隔
          // データA規格値
          rebar1DesignRangeValue = creator.getDesignRangeValue(
            rebar.designRangeClass,
            rebar.rebar1DiameterDesign,
            rebar.isMinusValidlyheight
          );
          //データA社内規格値
          rebar1LocalDesignRangeValue = creator.getLocalDesignRangeValue(
            rebar.localDesignRangeClass,
            rebar.rebar1DiameterDesign,
            rebar1DesignRangeValue,
            rebar.isMinusValidlyheight
          );

          if (rebar1DesignRangeValue == null) {
            rebar1DesignRangeValue = '';
          } else {
            rebar1DesignRangeValue = '±' + rebar1DesignRangeValue;
          }

          if (rebar1LocalDesignRangeValue == null) {
            rebar1LocalDesignRangeValue = "未選択";
          } else {
            if (rebar.localDesignRangeClass == "1") {
              rebar1LocalDesignRangeValue = '±Φ' + rebar1LocalDesignRangeValue;
            } else {
              rebar1LocalDesignRangeValue = '±' + rebar1LocalDesignRangeValue;
            }
          }

          // データB規格値
          rebar2DesignRangeValue = creator.getDesignRangeValue(
            rebar.designRangeClass,
            rebar.rebar2DiameterDesign,
            rebar.isMinusValidlyheight
          );
          // データB社内規格値
          rebar2LocalDesignRangeValue = creator.getLocalDesignRangeValue(
            rebar.localDesignRangeClass,
            rebar.rebar2DiameterDesign,
            rebar2DesignRangeValue,
            rebar.isMinusValidlyheight
          );
          if (rebar2DesignRangeValue == null) {
            rebar2DesignRangeValue = '';
          } else {
            rebar2DesignRangeValue = '±' + rebar2DesignRangeValue;
          }

          if (rebar2LocalDesignRangeValue == null) {
            rebar2LocalDesignRangeValue = "未選択";
          } else {
            if (rebar.localDesignRangeClass == "1") {
              rebar2LocalDesignRangeValue = '±Φ' + rebar2LocalDesignRangeValue;
            } else {
              rebar2LocalDesignRangeValue = '±' + rebar2LocalDesignRangeValue;
            }
          }
          break;
        case "1":
          // かぶり
          kaburiDesignRangeValue = creator.getKaburiDesignRangeList(
            rebar.designRangeClass,
            rebar.designRangeValue,
            rebar.designValue
          );
          kaburiLocalDesignRangeValue = creator.getKaburiDesignRangeList(
            rebar.localDesignRangeClass,
            rebar.localDesignRangeValue,
            rebar.designValue
          );
          kaburiDesignValue = rebar.designValue
          kaburiValue = rebar.value ? rebar.value : ``

          break;
        case "2":
          // 重ね継手長
          jointDesignRangeValue = rebar.designRangeValue ? rebar.designRangeValue : ``
          jointLocalDesignRangeValue = rebar.localDesignRangeValue ? rebar.localDesignRangeValue : ``
          jointDesignValue = rebar.designValue
          jointValue = rebar.value ? rebar.value : ``
          break;
        }
        
        let csvRow = {
          constructionName: rebar.constructionName,
          constructionType: rebar.constructionType,
          company: rebar.company,
          measurerName: rebar.measurerName,
          shootingSpot: rebar.shootingSpot,
          type: this.getTypeValue(rebar.type),
          detailNote: rebar.detailNote,
          memo: rebar.memo,
          importType: this.getImportTypeValue(rebar.importType),
          detailSpot: rebar.detailSpot,
          imageListJpg: this.getImageListFileName(rebar.imageList, 'jpg'),
          imageListPng: this.getImageListFileName(rebar.imageList, 'png'),
          isMinusValidlyheight: rebar.constructionType,
          designRangeClass1: rebar1DesignRangeValue,
          localDesignRangeClass1: rebar1LocalDesignRangeValue,
          rebar1Memo: rebar.rebar1Memo,
          rebar1No: rebar.rebar1No,
          rebar1DiameterDesign: rebar.rebar1DiameterDesign ? 'D' + rebar.rebar1DiameterDesign : rebar.rebar1DiameterDesign,
          rebar1PitchDesign: rebar.rebar1PitchDesign,
          totalPitch1: rebar.totalPitch1,
          designRangeClass2: rebar2DesignRangeValue,
          localDesignRangeClass2: rebar2LocalDesignRangeValue,
          rebar2Memo: rebar.rebar2Memo,
          rebar2No: rebar.rebar2No,
          rebar2DiameterDesign: rebar.rebar2DiameterDesign ? 'D' + rebar.rebar2DiameterDesign : rebar.rebar2DiameterDesign,
          rebar2PitchDesign: rebar.rebar2PitchDesign,
          totalPitch2: rebar.totalPitch2,
          pitchList1: rebar.pitchList1.length,
          pitchList2: rebar.pitchList2.length,
          averagePitch1: rebar.averagePitch1,
          averagePitch2: rebar.averagePitch2,
          kaburiDesignRangeValue: kaburiDesignRangeValue,
          kaburiLocalDesignRangeValue: kaburiLocalDesignRangeValue,
          jointDesignRangeValue: jointDesignRangeValue,
          jointLocalDesignRangeValue: jointLocalDesignRangeValue,
          kaburiDesignValue: kaburiDesignValue,
          jointDesignValue: jointDesignValue,
          kaburiValue: kaburiValue,
          jointValue: jointValue 
        }

        // 件数分繰り返し
        let index = 0;
        // 測定データがあれば見出しタイトルを追加
        if (rebar.pitchList1.length > 0) {
          let key = 'pitch' + index;
          csvRow[key] = "(赤)測定値"
          index++;
        }
        // 測定データを追加
        for (let i = 0; i < rebar.pitchList1.length; i++) {
          let key = 'pitch' + index;
          csvRow[key] = rebar.pitchList1[i].value;
          index++;
        }
        // 測定データがあれば見出しタイトルを追加
        if (rebar.pitchList2.length > 0) {
          let key = 'pitch' + index;
          csvRow[key] = "(緑)測定値"
          index++;
        }
        // 測定データを追加
        for (let i = 0; i < rebar.pitchList2.length; i++) {
          let key = 'pitch' + index;
          csvRow[key] = rebar.pitchList2[i].value;
          index++;
        }

        csvDatas.push(csvRow);
      })
      console.log(csvDatas);
      return csvDatas;
    },

    /**
     * CSV列情報（ヘッダー）生成
    */
    createCsvColumns() {
      // 固定部
      let columns = [
        { key: 'constructionName', header: '工事名' },
        { key: 'constructionType', header: '工種' },
        { key: 'company', header: '請負会社' },
        { key: 'measurerName', header: '測定者' },
        { key: 'shootingSpot', header: '測点' },
        { key: 'type', header: '種別' },
        { key: 'detailNote', header: '詳細' },
        { key: 'memo', header: '備考' },
        { key: 'importType', header: '測定タイプ' },
        { key: 'detailSpot', header: '詳細箇所名' },
        { key: 'imageListJpg', header: '撮影写真ファイル名' },
        { key: 'imageListPng', header: '計測写真ファイル名' },
        { key: 'isMinusValidlyheight', header: '設計値:有効高さ' },
        { key: 'designRangeClass1', header: 'データA(赤):規格値' },
        { key: 'localDesignRangeClass1', header: 'データA(赤):社内規格値' },
        { key: 'rebar1Memo', header: 'データA(赤):鉄筋名' },
        { key: 'rebar1No', header: 'データA(赤):鉄筋番号' },
        { key: 'rebar1DiameterDesign', header: 'データA(赤):設計径' },
        { key: 'rebar1PitchDesign', header: 'データA(赤):設計間隔' },
        { key: 'totalPitch1', header: 'データA(赤):累計間隔' },
        { key: 'designRangeClass2', header: 'データB(緑):規格値' },
        { key: 'localDesignRangeClass2', header: 'データB(緑):社内規格値' },
        { key: 'rebar2Memo', header: 'データB(緑):鉄筋名' },
        { key: 'rebar2No', header: 'データB(緑):鉄筋番号' },
        { key: 'rebar2DiameterDesign', header: 'データB(緑):設計径' },
        { key: 'rebar2PitchDesign', header: 'データB(緑):設計間隔' },
        { key: 'totalPitch2', header: 'データB(緑):累計間隔' },
        { key: 'pitchList1', header: 'データA件数' },
        { key: 'pitchList2', header: 'データB件数' },
        { key: 'averagePitch1', header: 'データA(赤):平均間隔' },
        { key: 'averagePitch2', header: 'データB(緑):平均間隔' },
        { key: 'kaburiDesignRangeValue', header: 'かぶり:規格値'},
        { key: 'kaburiLocalDesignRangeValue', header: 'かぶり:社内規格値'},
        { key: 'kaburiDesignValue', header: 'かぶり:設計値'},
        { key: 'kaburiValue', header: 'かぶり:測定値'},
        { key: 'jointDesignRangeValue', header: '重ね継手長:規格値'},
        { key: 'jointLocalDesignRangeValue', header: '重ね継手長:社内規格値'},
        { key: 'jointDesignValue', header: '重ね継手長:設計値'},
        { key: 'jointValue', header: '重ね継手長:測定値'},
      ];

      // まずもっとも長いA-B合計のpitch数を求める
      let maxCount = this.getMaxPicthCount();

      //pitchATitle pitchBTitle

      // 件数分繰り返し
      for (let i = 0; i < maxCount; i++) {
        let column = {};
        column['key'] = 'pitch' + i;
        column['header'] = '';

        columns.push(column);
      }

      return columns;
    },

    /**
     * 測定データの件数を取得する
     * 測定データの件数がバラバラなため、最大数を取得する必要がある
     */
    getMaxPicthCount() {
      let maxCount = 0;
      //2022/11/28
      console.log(this.selectedData.length);
      for (let i = 0; i < this.selectedData.length; i++) {
        const rebar = this.selectedData[i].rebarmeasure;
        //2022/11/28
        console.log(rebar);
        // データA測定件数
        let countA = rebar.pitchList1.length;
        // 測定データがあれば見出し出力用に+1する。
        countA += countA > 0 ? 1 : 0
        // データB測定件数
        let countB = rebar.pitchList2.length;
        // 測定データがあれば見出し出力用に+1する。
        countB += countB > 0 ? 1 : 0

        if (maxCount < countA + countB) {
          maxCount = countA + countB;
        }
      }
      console.log('最大pitch数:' + maxCount)
      // //2022/11/28
      // maxCount=1;
      return maxCount;
    },
    getTypeValue(typeKey) {
      switch (typeKey) {
      case 0:
        return '鉄筋工';
      case 1:
        return '床版工';
      }
    },
    getImportTypeValue(typeKey) {
      switch (typeKey) {
      case 0:
        return '実測値';
      case 1:
        return '立会値';
      }
    },
    getImageListFileName(imageList, ext) {
      for (let i = 0; i < imageList.length; i++) {
        const image = imageList[i];
        if (image.s3key.endsWith(ext)) {
          return path.basename(image.s3key);
        }
      }
    },
    getIsMinusValidlyheightValue(isMinusValidlyheight) {
      switch (isMinusValidlyheight) {
      case true:
        return 'あり';
      case false:
        return 'なし';
      default:
        return 'なし';
      }
    },
    createPitchListValues(header, pitchList1, pitchList2) {
      let values = [header];

      // 先頭（データB(緑）：平均間隔）
      console.log(values);
      console.log(header);
      // values.put(header);

      for (let i = 0; i < pitchList1.length; i++) {
        values.push(pitchList1[i].value);
      }

      for (let i = 0; i < pitchList2.length; i++) {
        values.push(pitchList2[i].value);
      }

      console.log(values.join(","));
      return values.join(",");
    },

    createCsvFileName() {

      const csvName = this.selectedTemplate.template;
      console.log(csvName);
      return csvName;

    },

    async getImages() {

      // ファイル名、imageBlobのリストを返す
      let images = [];

      for (let i = 0; i < this.selectedData.length; i++) {
        const rebar = this.selectedData[i].rebarmeasure;
        const imageList = rebar.imageList;

        for (let j = 0; j < imageList.length; j++) {
          const image = imageList[j];

          try {
            // S3上にある写真ファイルを取得してBlobに
            // Amplify SDKのStorage.getで指定したキーのファイルをバイナリダウンロードできます
            console.log(image.s3key);
            const result = await Storage.get(image.s3key, { download: true, level: "private" });
            //result.BodyにはBlobが入っている
            const imageBlob = result.Body;

            let item = {};
            item['fileName'] = path.basename(image.s3key);
            item['imageBlob'] = imageBlob;

            images.push(item);
          } catch (e) {
            // 写真をDLできなかった場合は続行（不整合データを無視）
            console.log('写真ダウンロードエラー:' + e.message + ' ' + image.s3key)
          }

        }
      }

      return images;
    },

    /**
     * 削除クリック
    */
    async onClickDelete() {
      // セッション切れ確認
      let isValid = await this.isValidSession();
      if (!isValid) {
        return;
      }

      if (!this.selectedData || this.selectedData < 1) {
        return;
      }

      try {
        // this.loader = this.$loading.show({color: '#11cdef', backgroundColor: "#333333"});

        await this.deleteSelectedData();
      } finally {
        
        // this.loader.hide();
      }
    },
    /**
     * 選択した帳票を削除する（論理削除）
    */
    async deleteSelectedData() {

      const count = this.selectedData.length;

      // 確認
      this.$confirm(count + '件のデータを削除します。よろしいですか?', '測定データの削除', {
        confirmButtonText: 'OK',
        cancelButtonText: 'キャンセル',
        type: 'warning'
      }).then(async function() {
        this.loader = this.$loading.show({color: '#11cdef', backgroundColor: "#333333"});
        try {
          for (let i = 0; i < count; i++) {
            let data = this.selectedData[i];

            try {
              // 削除フラグ
              data.rebarmeasure.deleteFlg = true;
              // 更新日時
              data.timestamp = DateUtil.getUnixTimeStampOfMilliSecond();
              delete data.type;
              await MeasureModel.updateMeasure(data);

              this.outputLog(`info`, `deleted ${data.sk}`)

              // 測定結果ファイルを削除
              const imageList = data.rebarmeasure.imageList;

              // ファイルが設定されているか
              if (imageList.length > 0) {
                // フォルダパスを取得
                const dir= path.dirname(imageList[0].s3key)
                const list = await Storage.list(dir, { level: "private" })
                for (let i = 0;i < list.length;i++) {
                  
                  try {
                    // private指定の場合、ユーザ毎のフォルダをルートとしたs3keyを取得出来る。
                    // private指定してget,put,deleteが可能なパスとして扱える。
                    this.outputLog(`info`, `deleted file ${list[i].key}`)
                    await Storage.remove(list[i].key, { level: "private" })
                  } catch (e) {
                    await this.outputLog(`error`, `ファイル削除に失敗 error:${JSON.stringify(e)} pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
                  }
                }
              }

              
            } catch (e) {
              console.log(JSON.stringify(e))
              this.outputLog(`error`, `failed delete ${data.sk} : ${e.message}`)

              if (e.errors) {
                if (e.errors.length > 0) {
                  switch (e.errors[0].errorType) {
                  case "DynamoDB:ConditionalCheckFailedException":
                    this.showBottomToast(`他のユーザにて削除されています。`, `warning`)
                    await this.outputLog(`warn`, `競合 pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
                    // リロード
                    this.init();
                    break
                  default:
                    await this.outputLog(`error`, `削除に失敗 error:${JSON.stringify(e)} pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
                    break
                  }
                } else {
                  this.showBottomToast(`削除に失敗しました`, `error`)
                  await this.outputLog(`error`, `削除に失敗 error:${JSON.stringify(e)} pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
                }

              } else {
                this.showBottomToast(`削除に失敗しました`, `error`)
                await this.outputLog(`error`, `削除に失敗 error:${JSON.stringify(e)} pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
              }

              throw e
            }

          }
          // 選択状態をクリア
          this.clearSelect();
          this.init();
        // this.$message({
        //   type: 'success',
        //   message: 'Delete completed'
        // });
        } catch (e) {
          console.log(JSON.stringify(e))
          this.showBottomToast(`データの削除に失敗しました`, `error`)
          this.clearSelect();
          this.init();
          throw e
        } finally {
          this.loader.hide();
          // this.loader.hide();
        }
      }.bind(this)).catch((e) => {
        console.log(`削除失敗`)
        // this.$message({
        //   type: 'info',
        //   message: '削除をキャンセルしました'
        // });
      });
    },
    /**
     * 下部にトーストを表示する
     */
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },
    /**
     * 指定されたプロパティでトーストを表示
     */
    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
    async runSort(column) {
      await this.changeTableSort(column);
    },
    async changeTableSort(column){
      // console.log(column);

      if (column === false) {
        return;
      }

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;

      // console.log(fieldName);

      if (!fieldName) {
        return;
      }
      
      // 詳細から戻った際の復元用に保持
      this.sortColumn = column;

      // ソート用フィールドに置き換え
      if (fieldName == "name") {
        fieldName = 'title';
      } else if (fieldName == "size") {
        fieldName = 'sortSize';
      }

      if (sortingType == "descending"){
        if (fieldName == 'title') {

          this.measures = this.measures.sort((a, b) => {
            // console.log(a.rebarmeasure[fieldName]);
            if (b.rebarmeasure[fieldName] < a.rebarmeasure[fieldName]) return -1;
            if (b.rebarmeasure[fieldName] > a.rebarmeasure[fieldName]) return 1;
          });

        } else {
          // 日本語ソート
          this.measures = this.measures.sort((a, b) => {
            // console.log(a.rebarmeasure[fieldName]);
            // nullは空文字に置換
            if (!a.rebarmeasure[fieldName]) a.rebarmeasure[fieldName] = '';
            if (!b.rebarmeasure[fieldName]) b.rebarmeasure[fieldName] = '';
            a = this.convertKana(a.rebarmeasure[fieldName].toString());
            b = this.convertKana(b.rebarmeasure[fieldName].toString());
            if (a < b) return 1;
            if (a > b) return -1;
            return 0;
            // return a.localeCompare(b, 'ja');
          })
        }
      }
      else {
        if (fieldName == 'title') {
          this.measures = this.measures.sort((a, b) => {
            if (b.rebarmeasure[fieldName] < a.rebarmeasure[fieldName]) return 1;
            if (b.rebarmeasure[fieldName] > a.rebarmeasure[fieldName]) return -1;
          });
        } else {
          // 日本語ソート
          this.measures = this.measures.sort((a, b) => {
            // nullは空文字に置換
            if (!a.rebarmeasure[fieldName]) a.rebarmeasure[fieldName] = '';
            if (!b.rebarmeasure[fieldName]) b.rebarmeasure[fieldName] = '';
            a = this.convertKana(a.rebarmeasure[fieldName].toString());
            b = this.convertKana(b.rebarmeasure[fieldName].toString());
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
            // return b.rebarmeasure[fieldName].localeCompare(a.rebarmeasure[fieldName], 'ja');
            // return b.rebarmeasure[fieldName].localeCompare(a.rebarmeasure[fieldName], 'ja');
          })
        }
      }

      // console.log(this.measures);
    },
    // https://gist.github.com/kawanet/5553478
    /** カタカナをひらがなに変換する
     * @param {String} src - カタカナ
     * @returns {String} - ひらがな
     */
    convertKana(src) {
      return src.replace(/[\u30a1-\u30f6]/g, function(match) {
        const chr = match.charCodeAt(0) - 0x60;
        return String.fromCharCode(chr);
      });
    },

    /**
     * ログインユーザ―IDを取得します。
     * @returns ログインユーザ―ID
     */
    async getUserId() {
      let id = '';

      let userInfo = await UserInfo.getUserInfo();
      id = userInfo.userName;

      return id;
    },

    /**
     * ログインユーザ―IDを取得します。
     * @returns ログインユーザ―ID
     */
    async getVueName() {
      return `List.vue`
    },
    /**
     * ログを出力します。
     * @param {string} level ログレベル
     * @param {string} message 内容
     */
    async outputLog(level, message) {
      let view = await this.getVueName();
      let user = await this.getUserId();

      switch (level) {
      case `debug`:
        appLog.debugLog(view, user, message)
        break;
      case `info`:
        appLog.infoLog(view, user, message)
        break;
      case `warn`:
        appLog.warnLog(view, user, message)
        break;
      case `error`:
        appLog.errLog(view, user, message)
        break;

      }
    },
    /**
     * ユーザセッション切れかどうかを判定します。
     * falseの場合はルートへ遷移します。
     * @returns セッションが有効の場合true、さもなくばfalse。
     */
    async isValidSession() {

      let isValid = await AuthUtil.isValidSession();
      if (!isValid) {
        AuthUtil.alert();

        // セッションなし -> ルートへリダイレクト
        this.$router.push({
          path: `/`
        })
        return false;
      }

      return true;
    },
    onClickformManage() {
      this.$router.push({
        path: `/rebarformmanage`
      })
    },
    /**
     * 帳票データをDBから読み込み
     */
    async loadTemplateData(){
      const data = await FormTemplateModel.getFormTemplateList()
      this.formTemplateDatas = data.sort((a, b) => {
        // console.log(`order: ${a.formTemplate.order} ${b.formTemplate.order}`)
        if (a.formTemplate.order < b.formTemplate.order) return -1;
        if (a.formTemplate.order > b.formTemplate.order) return 1;
        return 0;
        
      });
    },
    /**
     * デフォルトの帳票テンプレートリストとDB上のユーザ帳票テンプレートをマージ
     */
    margeTemplateList() {
      let margedList = []
      if (this.formTemplateDatas.length == 0) {
        return
      }

      let defaultList = this.formTemplateList
      // 国交省は先頭に
      margedList.push(defaultList[0])
      this.formTemplateList = []

      // ユーザ毎の帳票をマージする
      this.formTemplateDatas.forEach(function(item) {
        margedList.push({
          "id": parseInt(item.formTemplate.id),
          "name": item.formTemplate.name,
          "order": item.formTemplate.order,
          "template": `public/${item.formTemplate.filePath}`
        })
      }.bind(this))

      // CSV出力は最後に
      margedList.push(defaultList[1])

      this.formTemplateList = margedList
      
    }

    
  },
};
</script>
