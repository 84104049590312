const { Auth } = require("aws-amplify");
const { Buffer } = require("buffer");
const DateUtil = require("./DateUtil")
const AppLog = require("./AppLog")

class UserInfo {
  static async getCurrentUserGroup() {
    try {
      let session = await Auth.currentSession();
      const jwtToken = session.idToken.jwtToken;
      const tokens = jwtToken.split(".");
      const tokenBody = Buffer.from(tokens[1], "base64").toString();
      let json = JSON.parse(tokenBody);

      if (json["cognito:groups"]) {
        return json["cognito:groups"];
      }
    } catch (e) {
      return "";
    }
    return "";
  }
  static async getGroup() {
    const user = await Auth.currentAuthenticatedUser()
    return user.signInUserSession.accessToken.payload["cognito:groups"]
  }
  static async getUserInfo() {
    let result = await Auth.currentUserInfo();
    let session = await Auth.currentSession();
    AppLog.debugLog("UserInfo", "", `getUserInfo session: ${JSON.stringify(session.idToken.jwtToken)}`)
    // console.log(`getUserInfo session: ${JSON.stringify(session.idToken.jwtToken)}`)
    let jwtToken = session.idToken.jwtToken;
    let tokens = jwtToken.split(".");
    let json = JSON.parse(Buffer.from(tokens[1], "base64").toString());
    
    AppLog.debugLog("UserInfo", "", `getUserInfo: ${JSON.stringify(json)}`)
    // console.log(`getUserInfo: ${JSON.stringify(json)}`)

    return {
      cognitoId: result.id,
      userName: result.username,
      sub: result.attributes.sub,
      group: json["cognito:groups"][0],
    };
  }
  /**
   * ログインセッションの有効期限チェック
   * ※exp期限を迎えるとAuth.currentSession()のたびに更新される。
   * Cognitoの設定で更新Tokenの期限を60分とし、更新トークン期限切れによるログアウトが可能か検証する
   */
  static async checkTokenExpired() {
    let session = await Auth.currentSession();
    let credentials = await Auth.currentUserCredentials();
    
    //let json = JSON.parse(Buffer.from(credentials.sessionToken, "base64").toString());
    // console.log(`checkTokenExpired session:${JSON.stringify(session, null, "\t")}`)
    AppLog.debugLog("UserInfo", "", `checkTokenExpired session:${JSON.stringify(session, null, "\t")}`)

    if (!session) {
      // console.log(`checkTokenExpired セッション情報無し`)
      AppLog.debugLog("UserInfo", "", `checkTokenExpired セッション情報無し`)
      return false
    }
    if (!session.accessToken){
      // console.log(`checkTokenExpired アクセストークン無し`)
      AppLog.debugLog("UserInfo", "", `checkTokenExpired アクセストークン無し`)
      return false
    }
    const expiredTime = session.accessToken.payload.exp
    const currentTimestamp = DateUtil.getUnixTimeStampOfSecond()
    // console.log(`checkTokenExpired currentTimestamp:${currentTimestamp} expiredTime:${expiredTime}`)
    AppLog.debugLog("UserInfo", "", `checkTokenExpired currentTimestamp:${currentTimestamp} expiredTime:${expiredTime}`)
    if (currentTimestamp > expiredTime) {
      // console.log(`checkTokenExpired 期限切れ`)
      AppLog.debugLog("UserInfo", "", `checkTokenExpired 期限切れ`)
      return false
    }
    // console.log(`checkTokenExpired 期限内`)
    AppLog.debugLog("UserInfo", "", `checkTokenExpired 期限内`)
    return true
  }
}

module.exports = UserInfo;
