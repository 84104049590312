<template>
  <div>
    <!-- <div class="container-fluid mt-1"> -->
      <div class="card">
          <div class="card-header border-0">
            <div class="row ml-0">
              <table-tool-menu
                class="col-12"
                :title="title"
                :addTitle="addTitle"
                :isAdd="isAdd"
                :isSingleSelect="false"
                :isMultiSelect="false"
                :processing="false"
                :disableMoveDown="false"
                :disableMoveUp="false"
                @addNew="onClickNewUser"
              >
              </table-tool-menu>
              <!--
              <div class="col-6 text-right">
                <base-button
                  icon
                  outline
                  type="primary"
                  @click="onClickNewUser"
                >
                  <span class="btn-inner--icon"
                    ><i class="fa fa-plus-square"></i
                  ></span>
                  <span class="btn-inner--text">新しいユーザを追加</span>
                </base-button>
              </div>
               -->
            </div>
          </div>
        <el-table
            class="table-responsive table-flush"
            header-row-class-name="thead-light"
            row-key="id"
            @sort-change="runSort"
            :data="userList"
          >
            <el-table-column
              label="ユーザ名"
              min-width="220px"
              prop="user.name"
              sortable="'custom'"
            >
              <template v-slot="{ row }">
                <b>
                  <a href="#!" @click.prevent="onClickEditUser(row)"
                    >{{ row.user.name }}
                  </a>
                </b>
              </template>
            </el-table-column>

            <el-table-column label="権限" prop="user.usertype" sortable="'custom'">
              <template v-slot="{ row }">
                <span v-if="row.user.usertype == '0'">一般</span>
                <span v-if="row.user.usertype == '1'">管理者</span>
                <span></span>
              </template>
            </el-table-column>

          </el-table>
        </div>
      <!-- </div> -->
  </div>
</template>
<script>
import UserModel from "../../appModel/users/UserModel"
import TableToolMenu from "../components/Menu/TableToolMenu"
import SortUtil from '../../appUtils/SortUtil';

export default {
  components: {
    TableToolMenu
  },
  data() {
    return {
      users: {},
      userList: [],
      items: [
      ],
      title: "ユーザー一覧",
      addTitle: "新しいユーザーを追加",
      isAdd: true,
    };
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後

  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  methods: {

    async init() {

      let loader = this.showLoader();

      try {
        await this.getUserList()

        // タイトルをセット
        let navInfo = this.$store.state.navInfo
        navInfo.title = "ユーザー一覧"
        navInfo.description = ""
        this.$store.commit("setNavInfo", navInfo)
      } catch (e) {
        console.log(JSON.stringify(e))
      } finally {
        this.hideLoader(loader);
      }
    },
    async getUserList() {
      this.userList = await UserModel.getEditableUserList();
    },
    onClickEditUser(row) {
      console.log(JSON.stringify(row, null, "\t"));
      let id = row.sk.split("#")[1];
      let params = this.$store.state.editUserParams
      params.editUserId = id
      params.createFlg = false
      params.fromNavbar = false
      this.$store.commit("setEditUserParams", params)

      this.$router.push({
        path: `/edituser`
      });

    },
    onClickNewUser() {
      let params = this.$store.state.editUserParams
      params.editUserId = null
      params.createFlg = true
      params.fromNavbar = false
      this.$store.commit("setEditUserParams", params)

      this.$router.push({
        name: "MakeUser",
      });
    },
    /**
     * ソートを実行します。
     */
    async runSort(column) {
      // this.$nextTick(async function() {
      await this.changeTableSort(column);
      // }.bind(this))

    },

    /**
     * テーブルのソート順を変更します。
     */
    async changeTableSort(column){
      console.log(column);

      if (column === false) {
        return;
      }

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;

      console.log(fieldName);

      if (!fieldName) {
        return;
      }

      // 復元用に保持
      // this.sortColumn = column;

      // 日本語ソート
      let isKana = false;
      if (fieldName == "user.name") {
        isKana = true;
      }

      // let list = JSON.parse(JSON.stringify(this.userList))
      // this.userList = null;
      // this.userList = await SortUtil.sort(list, fieldName, sortingType, isKana);

      this.userList = await SortUtil.sort(this.userList, fieldName, sortingType, isKana);
      console.log(this.userList);

    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
  }
};
</script>
