<template>
  <div>
    <base-header class="pb-6" type=""> </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <stats-card
            title="Store"
            type="gradient-red"
            sub-title="350,097"
            icon="ni ni-active-40"
          >
            <pre>{{debug}}</pre>
            <!-- <make-user /> -->
          </stats-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StatsCard from "@/components/Cards/StatsCard";

export default {
  components: {
    StatsCard,
  },
  data() {
    return {
        debug:""

    }
  },
  beforeCreate(){
      //インスタンスは生成されたがデータが初期化される前

  },
  created(){
      //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount(){
      //インスタンスが DOM 要素にマウントされる前
  },
  mounted(){
      //インスタンスが DOM 要素にマウントされた後
      this.putStoreInfo()
  },
  beforeUpdate(){
      //データは更新されたが DOM に適用される前
  },
  updated() {
      //データが更新され､且つ DOM に適用された後
  },
  beforeDestroy(){
      //Vue インスタンスが破壊される前
  },
  destroyed(){
      //Vue インスタンスが破壊された後

  },
  methods: {
        putStoreInfo: function() {
          this.debug = JSON.stringify(this.$store.state.meeting.selectedMeetingData,null,"\t")
        

          
      },

  }

};
</script>