<template>
  <div>
    <div class="container-fluid mt-1">
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <stats-card
            v-bind:title="cognitoData.title"
            type="gradient-red"
            sub-title="350,097"
            icon="ni ni-active-40"
          >
            <template v-slot:footer>
              <span class="">{{vuename}}</span>
            </template>
            <!-- <make-user /> -->
          </stats-card>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  /** このVueだけのスタイル */
</style>
<style >
  /** 全体に影響するスタイル */
</style>
<script>
import appLog from "@/appUtils/AppLog"
// import DateUtil from "@/appUtils/DateUtil"
// import UserInfo from "@/appUtils/UserInfo"

export default {
  components: {

  },
  data() {
    return {
      
    };
  },
  computed: {
    vuename() {
      return this.$route.name
    }
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    appLog.infoLog(`${this.vuename}`, `Start ${this.vuename}`)
    this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    init() {
      console.log("init")
    }
    
  },
};
</script>
