const short = require("short-uuid");
const baseModel = require("../baseModel")

/**
 * 配筋帳票の取得モデルクラス
 */
class MeasureFormModel extends baseModel {
  /**
   * テナント内の配筋帳票リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getFormDataList() {
    return await super.getList({ beginsWith: "formOutput#" })
  }

  /**
   * 配筋帳票を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteFormData(data) {
    await super.deleteData(data)
  }

  /**
   * 配筋帳票管理レコードを追加する
   * @param {*} data
   */
  static async addFormData(data) {
    await super.addData(data)
  }

  /**
   * 配筋帳票管理レコードを更新する
   * @param {*} data
   */
  static async updateFormData(data) {
    await super.updateData(data)
  }

  /**
   * 配筋帳票管理レコードを取得する
   * @param {*} data
   */
  static async getFormData(data) {
    return await super.getSingleData(data);
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(id) {
    return `formOutput#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData() {
    let newRowData = await super.getNewData()
    
    const id = short.generate()
    newRowData.sk = this.createSk(id)
    
    newRowData.formOutputSetting = {
      templateFile: "",
      outputFileName: "",
      xlsxFileS3Key: "",
      workBookFileName: "",
      sheet: "",
      imageSheet: "",
      datalist: [],
      imageList: [],
      staticValueList: [],
      iterateValueList: [],
      photoValuelist: []
    }

    return newRowData
    
  }
}

module.exports = MeasureFormModel;
