<template>
  <div class="bg-light-gray" style="height:100%">
    <a @click.prevent="onClickBackToInspecList" class="h5">検査マスタ一覧へ戻る</a>
    <div class="container-fluid pt-1">
      <!-- <breadcrumb>
        <breadcrumb-item><a href="/dashboard">ダッシュボード</a></breadcrumb-item>
        <breadcrumb-item><a href="#!">検査マスタ一覧</a></breadcrumb-item>
        <breadcrumb-item active>検査マスタ設定</breadcrumb-item>
      </breadcrumb> -->
      <!--
      <card shadow class="mb-0" v-if="dbData">
        <div class="row">
          <div class="col-8">
            <div class="row">
              <div class="col-md-auto">
                <h4 class="text-uppercase ls-1 mb-0 mt-2 mr--2">
                    検査名
                </h4>
              </div>
              <div class="col-9 w-100">
                <el-input placeholder="" v-model="dbData.inspectMaster.name"></el-input>
              </div>
            </div>
          </div>
          <div class="col-0">
          </div>
          <div class="col-2">
            <base-button
              block
              type="secondary"
              @click="onClickCancelPlace"
              v-if="!isNewProject"
              >キャンセル</base-button
            >
          </div>
          <div class="col-2">
            <base-button
              block
              type="primary"
              @click="onClickSavePlace"
              v-if="!isNewProject"
              >保存</base-button
            >
          </div>
        </div>
      </card>
       -->
      <!--
      <card shadow class="mb-0" v-if="dbData">
        <template v-slot:header>
            <h4 class="text-uppercase ls-1 mb-0">
              検査マスタ設定
            </h4>
          </template>
        <div class="row">
          <div class="col-9">
            <div class="pl-lg-4">
              <base-input type="text" label="検査名">
                <template></template>
                <el-input placeholder="" v-model="dbData.inspectMaster.name"></el-input>
              </base-input>
            </div>
          </div>
          <div class="col-3">

          </div>
        </div>
      </card>
      -->

      <card shadow class="mt-1 mb-0" v-if="dbData">
        <el-tabs type="card" v-model="activeTab" @tab-click="tabSelect" >
          <el-tab-pane label="基本設定" name="tabBase">
            <div class="card-header border-0">
              <div class="row">
                <div class="col-8">
                  <div class="row">
                    <div class="col-md-auto">
                      <h4 class="text-uppercase ls-1 mb-0 mt-2 mr--2">
                          検査名
                      </h4>
                    </div>
                    <div class="col-9 w-100">
                      <el-input placeholder="" v-model="dbData.inspectMaster.name"></el-input>
                    </div>
                  </div>
                </div>
                <div class="col-0">
                </div>
                <div class="col-2">
                  <base-button
                    block
                    type="secondary"
                    @click="onClickBackToInspecList"
                    v-if="!isNewProject"
                    >キャンセル</base-button
                  >
                </div>
                <div class="col-2">
                  <base-button
                    block
                    type="primary"
                    @click="saveInspectMasterBase"
                    v-if="!isNewProject"
                    >保存</base-button
                  >
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="検査ステータス" name="tabStatus">
            <div class="card-header border-0">
              <div id="horizonal-toolbar" class="horizonal-toolbar col-12 align-items-center" role="toolbar">
                <div class="row">
                  <div>
                    <h4 class="mt-1 mb-0">検査ステータス</h4>
                  </div>
                  <div v-if="!isSingleSelectStatus && !isMultiSelectStatus" id="no-select-menu" class="col-md-auto inline-block">
                    <el-tooltip class="item" effect="dark" content="検査ステータスの新規作成" placement="bottom">
                      <el-button size="medium" circle @click="onClickAddNew">
                        <i class="fas fa-plus"></i>
                      </el-button>
                    </el-tooltip>
                    <!--
                    <div v-if="!isSingleSelectStatus && !isMultiSelectStatus" id="no-select-hasData-menu" class="col-md-auto inline-block">
                      <el-tooltip class="item" effect="dark" content="上に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveUp" size="medium" circle @click="onClickRowMove('up')">
                          <i class="fas fa-arrow-up"></i>
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="下に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveDown" size="medium" circle @click="onClickRowMove('down')">
                          <i class="fas fa-arrow-down"></i>
                        </el-button>
                      </el-tooltip>
                    </div>
                     -->
                  </div>
                  <div v-if="isSingleSelectStatus" id="single-select-menu" class="col-md-auto inline-block">
                    <el-tooltip class="item" effect="dark" content="削除" placement="bottom">
                      <el-button size="medium" circle icon="el-icon-delete" style="font-size:1.2em;" @click="onDeleteRows('検査ステータス')">
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="検査ステータスの編集" placement="bottom">
                      <el-button size="medium" circle @click.prevent="onClickEdit">
                        <i class="fas fa-pen"></i>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="検査ステータスのコピー" placement="bottom">
                      <el-button size="medium" circle @click="onClickCopy">
                        <i class="far fa-clone"></i>
                      </el-button>
                    </el-tooltip>
                    <div v-if="isSingleSelectStatus" id="single-select-hasData-menu" class="col-md-auto inline-block">
                      <el-tooltip class="item" effect="dark" content="上に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveUp" size="medium" circle @click="onClickRowMove('up')">
                          <i class="fas fa-arrow-up"></i>
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="下に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveDown" size="medium" circle @click="onClickRowMove('down')">
                          <i class="fas fa-arrow-down"></i>
                        </el-button>
                      </el-tooltip>
                    </div>
                  </div>
                  <div v-if="isMultiSelectStatus" id="multi-select-menu" class="col-md-auto inline-block">
                    <el-tooltip class="item" effect="dark" content="削除" placement="bottom">
                      <el-button size="medium" circle icon="el-icon-delete" style="font-size:1.2em;" @click="onDeleteRows('検査ステータス')">
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              highlight-current-row
              @selection-change="handleSelectionChangeStatus"
              @current-change="handleCurrentChangeStatus"
              ref="tableStatus"
              :data="iconSettingList"
            >
              <!-- チェック -->
              <el-table-column type="selection" align="left" min-width="120px">
              </el-table-column>
              <!-- ステータス名 -->
              <el-table-column
                prop="name"
                label="ステータス名"
              >
                <template v-slot="{ row }">
                  <img :src="iconUrlList[row.iconFilePath]" v-if="row.iconFilePath" class="bg-transparent avatar mr-3" />
                  <b>
                    <a href="#!" @click.prevent="onClickEdit(row)"
                      >{{ row.name }}
                    </a>
                  </b>
                </template>
              </el-table-column>
            </el-table>
            <!-- 新規追加、編集用のモーダル(検査ステータス) -->
            <modal v-model:show="modals.status" size="sm" body-classes="p-0">
              <template v-slot:header>
                <h6 class="modal-title">検査ステータスの{{actionType.label}}</h6>
              </template>
              <card
                type="secondary"
                header-classes="bg-transparent pb-5"
                body-classes="px-lg-2 py-lg-2"
                class="border-0 mb-0"
              >
                <div class="col-md-12">
                  <base-input type="text" label="ステータス名">
                    <template></template>
                    <el-input
                      placeholder="ステータス名を入力してください"
                      v-model="editIconItem.name"
                    ></el-input>
                  </base-input>
                </div>
                <div class="col-md-12">
                  <base-input type="text" label="アイコンファイル">
                    <!--
                    <el-image
                      v-if="iconSetting.iconFilePath"
                      style="width: 100px; height: 100px"
                      :src="iconUrlList[iconSetting.iconFilePath]"
                      :fit="fill">
                    </el-image>
                     -->
                    <el-upload
                      class="avatar-uploader w-100 m-1"
                      action=""
                      :on-change="handleOnChange"
                      :on-remove="handleRemove"
                      :file-list="uploadFileList"
                      :multiple="false"
                      :on-exceed="handleExceed"
                      :auto-upload="false"
                      :show-file-list="false"
                    >
                      <img id="iconImage" v-if="editIconItem.iconUrl" :src="editIconItem.iconUrl" class="bg-transparent avatar mr-2">
                      <!-- <img id="iconImage" v-if="editIconItem.iconFilePath" :src="editIconItem.iconFilePath" class="bg-transparent avatar mr-2"> -->
                      <span
                        v-if="editIconItem.iconFilePath"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)">
                        <i class="el-icon-delete"></i>
                      </span>
                      <el-button size="small" type="primary" class="">ファイルを選択</el-button>
                    </el-upload>
                  </base-input>
                </div>
              </card>
                <div class="row mt-2 mb-2 ml-2 mr-2">
                  <div class="col-sm-5">
                    <base-button
                      type="secondary"
                      block
                      @click="modals.status = false"
                      >キャンセル</base-button
                    >
                  </div>
                  <div class="col-sm-2">
                  </div>
                  <div class="col-sm-5">
                    <base-button
                      v-if="addMode"
                      type="primary"
                      block
                      @click="onClickSubmitStatus"
                      >{{saveButtonLabel}}</base-button
                    >
                  </div>
                </div>

            </modal>
          </el-tab-pane>
          <el-tab-pane label="検査項目" name="tabItem">
            <div class="card-header border-0">
              <div id="horizonal-toolbar" class="horizonal-toolbar col-12 align-items-center" role="toolbar">
                <div class="row">
                  <div>
                    <h4 class="mt-1 mb-0">検査項目</h4>
                  </div>
                  <div v-if="!isSingleSelectItem && !isMultiSelectItem" id="no-select-menu" class="col-md-auto inline-block">
                    <el-tooltip class="item" effect="dark" content="検査項目の新規作成" placement="bottom">
                      <el-button size="medium" circle @click="onClickAddNew">
                        <i class="fas fa-plus"></i>
                      </el-button>
                    </el-tooltip>
                    <!--
                    <div v-if="!isSingleSelectItem && !isMultiSelectItem" id="no-select-hasData-menu" class="col-md-auto inline-block">
                      <el-tooltip class="item" effect="dark" content="上に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveUp" size="medium" circle @click="onClickRowMove('up')">
                          <i class="fas fa-arrow-up"></i>
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="下に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveDown" size="medium" circle @click="onClickRowMove('down')">
                          <i class="fas fa-arrow-down"></i>
                        </el-button>
                      </el-tooltip>
                    </div>
                     -->
                  </div>
                  <div v-if="isSingleSelectItem" id="single-select-menu" class="col-md-auto inline-block">
                    <el-tooltip class="item" effect="dark" content="削除" placement="bottom">
                      <el-button size="medium" circle icon="el-icon-delete" style="font-size:1.2em;" @click="onDeleteRows('検査項目')">
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="検査項目の編集" placement="bottom">
                      <el-button size="medium" circle @click.prevent="onClickEdit">
                        <i class="fas fa-pen"></i>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="検査項目のコピー" placement="bottom">
                      <el-button size="medium" circle @click="onClickCopy">
                        <i class="far fa-clone"></i>
                      </el-button>
                    </el-tooltip>
                    <div v-if="isSingleSelectItem" id="single-select-hasData-menu" class="col-md-auto inline-block">
                      <el-tooltip class="item" effect="dark" content="上に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveUp" size="medium" circle @click="onClickRowMove('up')">
                          <i class="fas fa-arrow-up"></i>
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="下に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveDown" size="medium" circle @click="onClickRowMove('down')">
                          <i class="fas fa-arrow-down"></i>
                        </el-button>
                      </el-tooltip>
                    </div>
                  </div>
                  <div v-if="isMultiSelectItem" id="multi-select-menu" class="col-md-auto inline-block">
                    <el-tooltip class="item" effect="dark" content="削除" placement="bottom">
                      <el-button size="medium" circle icon="el-icon-delete" style="font-size:1.2em;" @click="onDeleteRows('検査項目')">
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              highlight-current-row
              @selection-change="handleSelectionChangeItem"
              @current-change="handleCurrentChangeItem"
              ref="tableItem"
              :data="inspectItemList"
            >
              <!-- チェック -->
              <el-table-column type="selection" align="left" min-width="120px">
              </el-table-column>
              <!-- 項目名 -->
              <el-table-column
                prop="name"
                label="項目名"
              >
                <template v-slot="{ row }">
                  <b>
                    <a href="#!" @click.prevent="onClickEdit(row)"
                      >{{ row.name }}
                    </a>
                  </b>
                </template>
              </el-table-column>
              <!-- 入力タイプ -->
              <el-table-column
                prop="itemType"
                label="入力タイプ"
              >
                <template v-slot="{ row }">
                  {{itemTypeName(row.itemType)}}
                </template>
              </el-table-column>
            </el-table>
            <!-- 新規追加、編集用のモーダル(検査項目) -->
            <modal v-model:show="modals.item" size="sm" body-classes="p-0">
              <template v-slot:header>
                <h6 class="modal-title">検査項目の{{actionType.label}}</h6>
              </template>
              <card
                type="secondary"
                header-classes="bg-transparent pb-5"
                body-classes="px-lg-2 py-lg-2"
                class="border-0 mb-0"
              >
                <div class="col-md-12">
                  <base-input type="text" label="項目名">
                    <template></template>
                    <el-input
                      placeholder="項目名を入力してください"
                      v-model="editItem.name"
                    ></el-input>
                  </base-input>
                </div>
                <div class="col-md-12">
                  <base-input name="select" label="入力タイプ" v-model="editItem.itemType">
                    <el-select placeholder=""
                      v-model="editItem.itemType"
                      filterable>
                      <el-option v-for="option in itemTypes"
                        class="select-danger"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-12">
                  <base-input name="select" label="リストを選択" v-if="editItem.itemType=='4' || editItem.itemType=='5'">
                    <el-select placeholder=""
                      v-model="editItem.masterType"
                      filterable>
                      <el-option v-for="option in masterList"
                        class="select-danger"
                        :value="option.id"
                        :label="option.name"
                        :key="option.name">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-12">
                  <label class="form-control-label">入力を必須とするか</label>
                  <base-checkbox v-model="editItem.required" class="mb-3"></base-checkbox>
                </div>
                <div class="col-md-12">
                  <base-input type="text" label="初期値">
                    <template></template>
                    <el-input
                      placeholder=""
                      v-model="editItem.defaultValue"
                    ></el-input>
                  </base-input>
                </div>
              </card>
                <div class="row mt-2 mb-2 ml-2 mr-2">
                  <div class="col-sm-5">
                    <base-button
                      type="secondary"
                      block
                      @click="modals.item = false"
                      >キャンセル</base-button
                    >
                  </div>
                  <div class="col-sm-2">
                  </div>
                  <div class="col-sm-5">
                    <base-button
                      v-if="addMode"
                      type="primary"
                      block
                      @click="onClickSubmitItem"
                      >{{saveButtonLabel}}</base-button
                    >
                  </div>
                </div>

            </modal>
          </el-tab-pane>
          <el-tab-pane label="リスト項目" name="tabList">
            <div class="card-header border-0">
              <div id="horizonal-toolbar" class="horizonal-toolbar col-12 align-items-center" role="toolbar">
                <div class="row">
                  <!-- リスト項目 -->
                  <div>
                    <h4 class="mt-1 mb-0">リスト項目</h4>
                  </div>
                  <div v-if="!isSingleSelectList && !isMultiSelectList" id="no-select-menu" class="col-md-auto inline-block">
                    <el-tooltip class="item" effect="dark" content="リスト項目の新規作成" placement="bottom">
                      <el-button size="medium" circle @click="onClickAddNew(0)">
                        <i class="fas fa-plus"></i>
                      </el-button>
                    </el-tooltip>
                    <!-- リスト項目は上下ソート現状無し
                    <div v-if="!isSingleSelectList && !isMultiSelectList" id="no-select-hasData-menu" class="col-md-auto inline-block">
                      <el-tooltip class="item" effect="dark" content="上に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveUp" size="medium" circle @click="onClickRowMove('up')">
                          <i class="fas fa-arrow-up"></i>
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="下に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveDown" size="medium" circle @click="onClickRowMove('down')">
                          <i class="fas fa-arrow-down"></i>
                        </el-button>
                      </el-tooltip>
                    </div>
                     -->
                  </div>
                  <div v-if="isSingleSelectList" id="single-select-menu" class="col-md-auto inline-block">
                    <el-tooltip class="item" effect="dark" content="削除" placement="bottom">
                      <el-button size="medium" circle icon="el-icon-delete" style="font-size:1.2em;" @click="onDeleteRows('リスト項目', 0)">
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="リストの編集" placement="bottom">
                      <el-button size="medium" circle @click.prevent="onEditInspectList">
                        <i class="fas fa-pen"></i>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="リストのコピー" placement="bottom">
                      <el-button size="medium" circle @click="onClickCopy(0)">
                        <i class="far fa-clone"></i>
                      </el-button>
                    </el-tooltip>
                  </div>
                  <div v-if="isMultiSelectList" id="multi-select-menu" class="col-md-auto inline-block">
                    <el-tooltip class="item" effect="dark" content="削除" placement="bottom">
                      <el-button size="medium" circle icon="el-icon-delete" style="font-size:1.2em;" @click="onDeleteRows('リスト項目', 0)">
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              highlight-current-row
              @selection-change="handleSelectionChangeList"
              @current-change="handleCurrentChangeList"
              ref="tableList"
              :data="masterList"
            >
              <!-- チェック -->
              <el-table-column type="selection" align="left" min-width="120px">
              </el-table-column>
              <!-- 項目名 -->
              <el-table-column
                prop="name"
                label="項目名"
              >
                <template v-slot="{ row }">
                  <b>
                    <a href="#!" @click.prevent="onEditInspectList(row)"
                      >{{ row.name }}
                    </a>
                  </b>
                </template>
              </el-table-column>
            </el-table>
            <!-- 新規追加、編集用のモーダル(リスト項目) -->
            <modal v-model:show="modals.list" size="sm" body-classes="p-0">
              <template v-slot:header>
                <h6 class="modal-title">リスト項目の{{actionType.label}}</h6>
              </template>
              <card
                type="secondary"
                header-classes="bg-transparent pb-5"
                body-classes="px-lg-2 py-lg-2"
                class="border-0 mb-0"
              >
                <div class="col-md-12">
                  <base-input type="text" label="項目名">
                    <template></template>
                    <el-input
                      placeholder="項目名を入力してください"
                      v-model="editMasterItem.name"
                    ></el-input>
                  </base-input>
                </div>
              </card>
                <div class="row mt-2 mb-2 ml-2 mr-2">
                  <div class="col-sm-5">
                    <base-button
                      type="secondary"
                      block
                      @click="modals.list = false"
                      >キャンセル</base-button
                    >
                  </div>
                  <div class="col-sm-2">
                  </div>
                  <div class="col-sm-5">
                    <base-button
                      v-if="addMode"
                      type="primary"
                      block
                      @click="onClickSubmitList"
                      >{{saveButtonLabel}}</base-button
                    >
                  </div>
                </div>

            </modal>
            <div class="card-header border-0" v-if="editMasterItem.id">
              <div id="horizonal-toolbar" class="horizonal-toolbar col-12 align-items-center" role="toolbar">
                <div class="row">
                  <!-- リスト選択項目 -->
                  <div>
                    <h4 class="mt-1 mb-0">選択項目</h4>
                  </div>
                  <div v-if="!isSingleSelectListItem && !isMultiSelectListItem" id="no-select-menu" class="col-md-auto inline-block">
                    <el-tooltip class="item" effect="dark" content="選択項目の新規作成" placement="bottom">
                      <el-button size="medium" circle @click="onClickAddNew(1)">
                        <i class="fas fa-plus"></i>
                      </el-button>
                    </el-tooltip>
                    <!--
                    <div v-if="!isSingleSelectListItem && !isMultiSelectListItem" id="no-select-hasData-menu" class="col-md-auto inline-block">
                      <el-tooltip class="item" effect="dark" content="上に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveUp" size="medium" circle @click="onClickRowMove('up')">
                          <i class="fas fa-arrow-up"></i>
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="下に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveDown" size="medium" circle @click="onClickRowMove('down')">
                          <i class="fas fa-arrow-down"></i>
                        </el-button>
                      </el-tooltip>
                    </div>
                    -->
                  </div>
                  <div v-if="isSingleSelectListItem" id="single-select-menu" class="col-md-auto inline-block">
                    <el-tooltip class="item" effect="dark" content="削除" placement="bottom">
                      <el-button size="medium" circle icon="el-icon-delete" style="font-size:1.2em;" @click="onDeleteRows('選択項目', 1)">
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="選択項目の編集" placement="bottom">
                      <el-button size="medium" circle @click.prevent="onEditInspectListItem">
                        <i class="fas fa-pen"></i>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="選択項目のコピー" placement="bottom">
                      <el-button size="medium" circle @click="onClickCopy(1)">
                        <i class="far fa-clone"></i>
                      </el-button>
                    </el-tooltip>
                    <div v-if="isSingleSelectListItem" id="single-select-hasData-menu" class="col-md-auto inline-block">
                      <el-tooltip class="item" effect="dark" content="上に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveUp" size="medium" circle @click="onClickRowMove('up')">
                          <i class="fas fa-arrow-up"></i>
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="下に移動" placement="bottom">
                        <el-button v-bind:disabled="processing || disableMoveDown" size="medium" circle @click="onClickRowMove('down')">
                          <i class="fas fa-arrow-down"></i>
                        </el-button>
                      </el-tooltip>
                    </div>
                  </div>
                  <div v-if="isMultiSelectListItem" id="multi-select-menu" class="col-md-auto inline-block">
                    <el-tooltip class="item" effect="dark" content="削除" placement="bottom">
                      <el-button size="medium" circle icon="el-icon-delete" style="font-size:1.2em;" @click="onDeleteRows('選択項目', 1)">
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <el-table
              v-if="editMasterItem.id"
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              highlight-current-row
              @selection-change="handleSelectionChangeListItem"
              @current-change="handleCurrentChangeListItem"
              ref="tableListItem"
              :data="editMasterItem.list"
            >
              <!-- チェック -->
              <el-table-column type="selection" align="left" min-width="120px">
              </el-table-column>
              <!-- 項目名 -->
              <el-table-column
                prop="name"
                label="項目名"
              >
                <template v-slot="{ row }">
                  <b>
                    <a href="#!" @click.prevent="onEditInspectListItem(row)"
                      >{{ row.name }}
                    </a>
                  </b>
                </template>
              </el-table-column>
              <!-- 値 -->
              <el-table-column
                prop="itemType"
                label="値"
              >
                <template v-slot="{ row }">
                  {{row.value}}
                </template>
              </el-table-column>
            </el-table>
            <!-- 新規追加、編集用のモーダル(リスト選択項目) -->
            <modal v-model:show="modals.listItem" size="sm" body-classes="p-0">
              <template v-slot:header>
                <h6 class="modal-title">選択項目の{{actionType.label}}</h6>
              </template>
              <card
                type="secondary"
                header-classes="bg-transparent pb-5"
                body-classes="px-lg-2 py-lg-2"
                class="border-0 mb-0"
              >
                <div class="col-md-12">
                  <base-input type="text" label="項目名">
                    <template></template>
                    <el-input
                      placeholder="項目名を入力してください"
                      v-model="editValueItem.name"
                    ></el-input>
                  </base-input>
                </div>
                <div class="col-md-12">
                  <base-input type="text" label="値">
                    <template></template>
                    <el-input
                      placeholder=""
                      v-model="editValueItem.value"
                    ></el-input>
                  </base-input>
                </div>
              </card>
                <div class="row mt-2 mb-2 ml-2 mr-2">
                  <div class="col-sm-5">
                    <base-button
                      type="secondary"
                      block
                      @click="modals.listItem = false"
                      >キャンセル</base-button
                    >
                  </div>
                  <div class="col-sm-2">
                  </div>
                  <div class="col-sm-5">
                    <base-button
                      v-if="addMode"
                      type="primary"
                      block
                      @click="onClickSubmitListItem"
                      >{{saveButtonLabel}}</base-button
                    >
                  </div>
                </div>

            </modal>
          </el-tab-pane>
        </el-tabs>
      </card>

      <!-- 旧UI Todoどこかで削除
      <div class="mt-2">
        <base-button v-model:type="tab1Type" @click.prevent="showTab(1)">検査項目</base-button>
        <base-button v-model:type="tab2Type" @click.prevent="showTab(2)">マスタデータ</base-button>
        <base-button v-model:type="tab3Type" @click.prevent="showTab(3)">検査アイコン</base-button>
      -->
        <!-- 検査項目設定 こここから -->
<!--
        <card v-if="tabs.tab1Show" class="mt--2">
          <template v-slot:header>
            <h4 class="text-uppercase ls-1 mb-0">
              検査項目設定
            </h4>
          </template>
          <div class="row">
            <div class="col-6">
              <el-table
                class="table-responsive align-items-center table-flush"
                header-row-class-name="thead-light"
                highlight-current-row

                @row-click="onClickInspectItemrow"
                :data="inspectItemList"
              >
                <el-table-column
                  width="50px"
                  class-name="p-1 text-center"
                  label="No"
                >
                  <template v-slot="{ row }">
                    {{row.order}}
                  </template>
                </el-table-column>
                <el-table-column
                  label="項目名"
                  min-width="100px"
                  prop="name"
                  sortable
                >
                  <template v-slot="{ row }">
                    {{ row.name }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="150px"
                  label="型"
                >
                  <template v-slot="{ row }">
                    {{itemTypeName(row.itemType)}}
                  </template>
                </el-table-column>
                <el-table-column
                  class-name="p-1 text-right"
                  label-class-name="d-flex justify-content-end"
                  width="80px"
                >
                  <template #header>
                    <base-button outline type="primary" @click="onClickAddItem">

                      <span class="btn-inner--text">＋</span>
                    </base-button>
                  </template>
                  <template v-slot="{ row }">
                    <div class="table-actions mr-1">
                      <el-tooltip content="削除" placement="top">
                        <a
                          href="#!"
                          @click.prevent="onDeleteInspectRow(row)"
                          class="table-action table-action-edit"
                          data-toggle="tooltip"
                          data-original-title="削除"
                        >
                          <i
                            class="fa fa-trash-alt text-red"
                            style="font-size: 1.5rem"
                          ></i>
                        </a>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

            </div>
            <div class="col-6" v-if="editItem">
              <div class="row">
                <div class="col-12">
                  <div class="pl-lg-4">
                    <base-input type="text" label="項目名">
                      <template></template>
                      <el-input
                        placeholder="Please input"
                        v-model="editItem.name"
                      ></el-input>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="pl-lg-4">
                    <base-input name="select" label="型" v-model="editItem.itemType">
                      <el-select placeholder=""
                        v-model="editItem.itemType"
                        filterable>
                        <el-option v-for="option in itemTypes"
                                  class="select-danger"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.label">
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input name="select" label="マスタを選択" v-if="editItem.itemType=='4'">
                      <el-select placeholder=""
                        v-model="editItem.masterType"
                        filterable>
                        <el-option v-for="option in masterList"
                                  class="select-danger"
                                  :value="option.id"
                                  :label="option.name"
                                  :key="option.name">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="pl-lg-4">
                    <label class="form-control-label">必須</label>
                    <base-checkbox v-model="editItem.required" class="mb-3"></base-checkbox>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="pl-lg-4">
                    <base-input type="text" label="並び順">
                      <template></template>
                      <el-input
                        placeholder=""
                        v-model="editItem.order"
                      ></el-input>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="pl-lg-4">
                    <base-input type="text" label="初期値">
                      <template></template>
                      <el-input
                        placeholder=""
                        v-model="editItem.defaultValue"
                      ></el-input>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
        -->
        <!-- 検査項目設定 ここまで -->

        <!-- リストマスタ設定 ここから -->
        <!--
        <card v-if="tabs.tab2Show" class="mt--2">
          <template v-slot:header>
            <h4 class="text-uppercase ls-1 mb-0">
              マスタデータ設定
            </h4>
          </template>
          <div class="row">
            <div class="col-12">
        -->
              <!-- リスト名のリスト -->
<!--
              <el-table
                class="table-responsive align-items-center table-flush"
                header-row-class-name="thead-light"
                highlight-current-row

                @row-click="onClickMasterRow"
                :data="masterList"
              >
                <el-table-column
                  width="40px"
                  class-name="p-1 text-center"
                  label="No"
                >
                  <template v-slot="{ row }">
                    {{row.order}}
                  </template>
                </el-table-column>
                <el-table-column
                  label="マスタ名"
                  min-width="100px"
                  prop="name"
                  sortable
                >
                  <template v-slot="{ row }">
                    {{ row.name }}
                  </template>
                </el-table-column>

                <el-table-column
                  class-name="p-1 text-right"
                  label-class-name="d-flex justify-content-end"
                  width="80px"
                >
                  <template #header>
                    <base-button outline type="primary" @click="onClickAddMasterItem">
                      <span class="btn-inner--text">＋</span>
                    </base-button>
                  </template>

                  <template v-slot="{ row }">
                    <div class="table-actions mr-1">
                      <el-tooltip content="削除" placement="top">
                        <a
                          href="#!"
                          @click.prevent="onDeleteMasterRow(row)"
                          class="table-action table-action-edit"
                          data-toggle="tooltip"
                          data-original-title="削除"
                        >
                          <i
                            class="fa fa-trash-alt text-red"
                            style="font-size: 1.5rem"
                          ></i>
                        </a>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="col-12" v-if="editMasterItem">
              <div class="row">
                <div class="col-12"  >
                  <span></span>
                  <base-input type="text" label="項目名">
                    <template></template>
                    <el-input
                      placeholder="Please input"
                      v-model="editMasterItem.name"
                    ></el-input>
                  </base-input>

                </div>
              </div>
              <div class="row">
                <div class="col-6">
            -->
                  <!-- リスト内容 -->
<!--
                  <span class="h4">選択項目一覧</span>
                  <el-table
                    class="table-responsive align-items-center table-flush"
                    header-row-class-name="thead-light"
                    highlight-current-row

                    @row-click="onClickInputMasterRow"
                    :data="editMasterItem.list"
                  >
                    <el-table-column
                      width="40px"
                      class-name="p-1 text-center"
                      label="No"
                    >
                      <template v-slot="{ row }">
                        {{row.order}}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="項目名"
                      min-width="100px"
                      prop="name"
                      sortable
                    >
                      <template v-slot="{ row }">
                        {{ row.name }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="値"
                      min-width="100px"
                      prop="name"
                      sortable
                    >
                      <template v-slot="{ row }">
                        {{ row.value }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      class-name="p-1 text-right"
                      label-class-name="d-flex justify-content-end"
                      width="80px"
                    >
                      <template #header>
                        <base-button outline type="primary" @click="onClickAddValueItem">
                          <span class="btn-inner--text">＋</span>
                        </base-button>
                      </template>

                      <template v-slot="{ row }">
                        <div class="table-actions mr-1">
                          <el-tooltip content="削除" placement="top">
                            <a
                              href="#!"
                              @click.prevent="onDeleteValueRow(row)"
                              class="table-action table-action-edit"
                              data-toggle="tooltip"
                              data-original-title="削除"
                            >
                              <i
                                class="fa fa-trash-alt text-red"
                                style="font-size: 1.5rem"
                              ></i>
                            </a>
                          </el-tooltip>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                -->
                <!-- リスト編集 -->
<!--
                <div class="col-6" v-if="editValueItem">
                  <span></span>
                  <div class="row">
                    <div class="col-12">
                      <div class="pl-lg-4">
                        <base-input type="text" label="項目名">
                          <template></template>
                          <el-input
                            placeholder="Please input"
                            v-model="editValueItem.name"
                          ></el-input>
                        </base-input>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="pl-lg-4">
                        <base-input type="text" label="値">
                          <template></template>
                          <el-input
                            placeholder="Please input"
                            v-model="editValueItem.value"
                          ></el-input>
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          -->
          <!-- <div class="row">
            <div class="col-xl-12 order-xl-1">
              <base-button block type="primary" @click="saveInspectMaster">保存</base-button>
            </div>
          </div> -->
        <!--
        </card>
         -->
        <!-- リストマスタ設定 ここまで -->
        <!-- 検査アイコン設定ここから -->
        <!--
        <card v-if="tabs.tab3Show" class="mt--2">
          <div class="row">
            <div class="col-6">
              <el-table
                class="table-responsive align-items-center table-flush"
                header-row-class-name="thead-light"
                highlight-current-row
                @row-click="onClickIconSettingRow"
                :data="iconSettingList"
              >
        -->
                <!-- NO -->
                <!--
                <el-table-column
                  width="50px"
                  class-name="p-1 text-center"
                  label="No"
                >
                  <template v-slot="{ row }">
                    {{row.order}}
                  </template>
                </el-table-column>
                -->
                <!-- アイコン名 -->
                <!--
                <el-table-column
                  prop="name"
                  label="アイコン名"
                  sortable
                >
                  <template v-slot="{ row }">
                    <img :src="iconUrlList[row.iconFilePath]" v-if="row.iconFilePath" class="bg-white avatar mr-3" />

                    {{ row.name }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="デフォルト"
                >
                  <template v-slot="{ row }">
                    <base-checkbox class="mb-3" v-model="row.isDefault" @input.prevent="onCheckDefaultIcon(row)"></base-checkbox>
                  </template>
                </el-table-column>
                <el-table-column
                  class-name="p-1 text-right"
                  label-class-name="d-flex justify-content-end"
                  width="80px"
                >
                  <template #header>
                    <base-button outline type="primary" @click="onClickAddIconItem">

                      <span class="btn-inner--text">＋</span>
                    </base-button>
                  </template>
                  <template v-slot="{ row }">
                    <div class="table-actions mr-1">
                      <el-tooltip content="削除" placement="top">
                        <a
                          href="#!"
                          @click.prevent="onDeleteIconRow(row)"
                          class="table-action table-action-edit"
                          data-toggle="tooltip"
                          data-original-title="削除"
                        >
                          <i
                            class="fa fa-trash-alt text-red"
                            style="font-size: 1.5rem"
                          ></i>
                        </a>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-6" v-if="editIconItem">
              <div class="row">
                <div class="col-12">
                  <div class="pl-lg-4">
                    <base-input type="text" label="アイコン名">
                      <template></template>
                      <el-input
                        placeholder="Please input"
                        v-model="editIconItem.name"
                      ></el-input>
                    </base-input>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="pl-lg-4">
                    <label class="form-control-label">アイコンファイル</label>
                    <file-input initialLabel="アイコンファイルを選択" @change="uploadIcon" ref="fileInput"></file-input>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </card>
        -->
        <!-- 検査アイコン設定ここまで -->
        <!--
        <card class="mt--2">
          <div class="row">
            <div class="col-xl-12 order-xl-1">
              <base-button block type="primary" @click="saveInspectMaster">保存</base-button>
            </div>
          </div>
        </card>
      </div>
    -->
    </div>
    <modal v-model:show="modal.isShow">
      <template v-slot:header>
          <h5 class="modal-title" id="exampleModalLabel">{{modal.title}}</h5>
      </template>
      <div>
        {{modal.message}}
      </div>
      <template v-slot:footer>
          <base-button v-model:type="modal.cancelType" @click="modal.cancelCallBack">{{modal.cancelLabel}}</base-button>
          <base-button v-model:type="modal.okType" @click="modal.okCallBack">{{modal.okLabel}}</base-button>
      </template>
    </modal>
  </div>
</template>
<style>
.current-row {
  border:2px solid blue;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 16px;
    color: #ffffff;
    /* color: #8c939d; */
    width: 100%;
    height: 64px;
    line-height: 64px;
    text-align: center;
  }
  .avatar {
    width: 64px;
    height: 64px;
    display: block;
  }

</style>
<script>

import InspectMasterModel from "../../appModel/InspectMaster/InspectMasterModel";
import short from "short-uuid"
import Modal from "@/components/Modal";
import UserInfo from '../../appUtils/UserInfo';
import TenantStorage from "@/appUtils/TenantStorage"
import FileInput from "@/components/Inputs/FileInput";
import { Storage } from "aws-amplify";
import path from 'path'
import mime from 'mime-types';

export default {
  components: {
    Modal,
    FileInput,
  },
  props: {
    transferArgs: null,
    /**
     * プロジェクトID
     */
    projectId: String
  },
  data() {
    return {
      tags: [],
      modal: {
        isShow: false,
        title: "",
        message: "",
        okLabel: "OK",
        okType: "secondary",
        okCallBack: function() {
          alert(`push Ok`)
          this.modal.isShow = false
        },
        cancelLabel: "キャンセル",
        cancelType: "primary",
        cancelCallBack: function() {
          alert(`push cancel`)
          this.modal.isShow = false
        },
      },
      activeTab: "tabBase",
      // activeTab: {
      //   props: {
      //     name: "tabStatus",
      //   }
      // },
      isSingleSelectStatus: false,
      isMultiSelectStatus: false,
      selectedDataStatus: null,
      currentRowBase: null,
      currentRowStatus: null,
      isSingleSelectItem: false,
      isMultiSelectItem: false,
      selectedDataItem: null,
      currentRowItem: null,
      isSingleSelectList: false,
      isMultiSelectList: false,
      selectedDataList: null,
      currentRowList: null,
      isSingleSelectListItem: false,
      isMultiSelectListItem: false,
      selectedDataListItem: null,
      currentRowListItem: null,
      processing: false,
      addMode: true,
      actionTypes: [
        {value: "-1", label: ""},
        {value: "0", label: "新規作成"},
        {value: "1", label: "編集"},
        {value: "2", label: "削除"},
        {value: "3", label: "コピー"},
      ],
      actionType: {value: "0", label: "新規作成"},
      saveButtonLabel: '登録',
      modals: {
        status: false,
        item: false,
        list: false,
        listItem: false
      },
      selectIconFile: null,
      // Todo comment
      tabs: {
        tab1Show: true,
        tab2Show: false,
        tab3Show: false
      },
      isNeverSaved: true,
      isNew: false,
      // projectId: null,
      inspectMasterSk: null,
      dbData: null,
      editItem: null,
      editMasterItem: null,
      editValueItem: null,
      editIconItem: null,
      iconUrlList: {},
      itemTypes: [
        {value: "0", label: "ラベル (編集不可)"},
        {value: "1", label: "テキスト"},
        {value: "2", label: "数値"},
        {value: "3", label: "日付"},
        {value: "4", label: "選択 (リスト)"},
        {value: "5", label: "選択 (ボタン)"}
      ],
      tenantStorage: null,
    };
  },
  computed: {
    inspectMasterName() {
      if (this.dbData){
        if (this.dbData.inspectMaster) {
          return this.dbData.inspectMaster.name
        }
      }
      return ""
    },
    inspectItemList() {
      if (this.dbData) {
        if (this.dbData.inspectMaster) {
          return this.dbData.inspectMaster.inspectItemList.slice().sort((a, b) => {
            return a.order - b.order;
          });
          // return this.dbData.inspectMaster.inspectItemList
        }
      }
      return []
    },
    masterList() {
      if (this.dbData) {
        if (this.dbData.inspectMaster) {
          console.log(this.dbData.inspectMaster)
          return this.dbData.inspectMaster.inputMaster
        }
      }
      return []
    },
    iconSettingList() {
      if (this.dbData) {
        if (this.dbData.inspectMaster) {
          return this.dbData.inspectMaster.iconSetting.slice().sort((a, b) => {
            return a.order - b.order;
          });
          // return this.dbData.inspectMaster.iconSetting
        }
      }
      return []
    },
    editInputMasterList() {
      if (this.editMasterItem) {
        if (this.editMasterItem.list) {
          return this.editMasterItem.list.slice().sort(function(a, b) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          });

          // return this.editMasterItem.list.slice().sort((a, b) => {
          //   return a.order - b.order;
          // });
          // return this.dbData.inspectMaster.inspectItemList
        }
      }
      return []
    },
    tab1Type() {
      return this.tabs.tab1Show ? "white" : "light"
    },
    tab2Type() {
      return this.tabs.tab2Show ? "white" : "light"
    },
    tab3Type() {
      return this.tabs.tab3Show ? "white" : "light"
    },
    disableMoveUp() {
      let currentRow = null;
      let index = -1;

      // リストは選択項目しか上下移動が無いので1を指定
      const selectData = this.getSelectedData(1);

      if (selectData && selectData.length) {
        currentRow = selectData[0];
        // console.log(currentRow);
        index = this.getRowIndex(currentRow.id);
      }

      // let tab = this.getActiveTab;
      // switch (tab) {
      // case "tabStatus":
      //   currentRow = this.selectedDataStatus[0];
      //   // currentRow = this.currentRowStatus;
      //   break;
      // case "tabItem":
      //   currentRow = this.selectedDataItem[0];
      //   // currentRow = this.currentRowItem;
      //   break;
      // case "tabList":
      //   currentRow = this.currentRowListItem;
      //   // currentRow = this.currentRowListItem;
      //   break;
      // }

      // console.log(currentRow);

      if (!currentRow || index == 0) {
      // if (!currentRow || currentRow.index == 0) {
        // 行が未選択 or 一番上
        return true;
      }

      return false;
    },
    disableMoveDown() {
      let currentRow = null;
      let dataList = null;
      let index = -1;

      // リストは選択項目しか上下移動が無いので1を指定
      const selectData = this.getSelectedData(1);
      if (selectData && selectData.length) {
        currentRow = selectData[0];
        index = this.getRowIndex(currentRow.id);
      }
      dataList = this.getTableList()

      // let tab = this.getActiveTab;
      // switch (tab) {
      // case "tabStatus":
      //   currentRow = this.currentRowStatus;
      //   dataList = this.iconSettingList;
      //   break;
      // case "tabItem":
      //   currentRow = this.currentRowItem;
      //   dataList = this.inspectItemList;
      //   break;
      // case "tabList":
      //   currentRow = this.currentRowListItem;
      //   dataList = this.editMasterItem.list;
      //   break;
      // }
      // console.log(currentRow)

      if (currentRow == null) {
        return true;
      } else if (index === dataList.length - 1) {
        // 行が未選択 or 一番下
        return true;
      }

      return false;
    },
    getActiveTab() {
      let tab = this.activeTab;
      if (!tab) {
        tab = this.activeTab.props.name;
      }

      return tab;
    },
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    this.init()
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {

    //Vue インスタンスが破壊される前
    // this.saveInspectMaster()

    // 新規登録で一度も保存していなければ破棄
    if (this.isNew && this.isNeverSaved) {
      console.log(`新規登録キャンセル`)
      InspectMasterModel.deleteInspectMaster(this.dbData);
    }
  },
  unmounted() {
    this.modal.isShow = false
    this.dbData = null
    this.editItem = null
    this.editMasterItem = null
    this.editValueItem = null
    this.isNeverSaved = true

    //Vue インスタンスが破壊された後
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {
      // const currentProject = this.$store.state.project;
      // this.projectId = currentProject.sk.split("#")[1]
      console.log(`this.transferArgs.sk:${this.transferArgs.sk}`)

      const user = await UserInfo.getUserInfo();
      this.tenantStorage = new TenantStorage(user.group)

      // 編集用アイテム初期化
      this.initEditItems();

      if (!this.transferArgs.sk) {
        // 新規
        console.log("新規")
        this.isNew = true
        await InspectMasterModel.getNewData(this.projectId).then(result => {
          this.dbData = result
          this.dbData.inspectMaster.name = "新規検査マスタ"
          console.log(JSON.stringify(this.dbData, null, "\t"))
          InspectMasterModel.addInspectMaster(this.dbData).then(() => {

            //初期登録後に再取得する(idやらcreateDateやらを取得する)
            InspectMasterModel.getInspectMaster(this.dbData.sk).then(result => {
              this.dbData = result
              console.log(JSON.stringify(this.dbData, null, "\t"))
              this.setIconUrl()
            })
          })
        })
      } else {
        // 編集
        console.log("編集")
        await InspectMasterModel.getInspectMaster(this.transferArgs.sk).then(result => {
          this.dbData = result
          console.log(JSON.stringify(this.dbData, null, "\t"))
          this.setIconUrl()
        })
      }
      this.actionType = this.setActionType("0");

    },
    /**
     * 編集用アイテムをを初期化します。
     */
    initEditItems() {
      // 編集用アイコン初期化
      this.initEditIcon();

      // 編集用項目初期化
      this.initEditItem();

      // 編集用リスト初期化
      this.initEditList();

      // 編集用リスト項目初期化
      this.initEditListItem();
    },
    /**
     * 編集用アイコンを初期化します。
     */
    initEditIcon() {
      let orderNum = 0
      const id = short.generate()
      let item = {
        id: id,
        name: "新規アイコン",
        iconClass: id,
        iconFilePath: "",
        systemIconType: "",
        systemIconColor: "",
        order: orderNum,
        isDefault: false,
      }

      this.editIconItem = item
    },
    /**
     * 編集用項目を初期化します。
     */
    initEditItem() {
      let orderNum = 0
      let item = {
        id: short.generate(),
        name: `新規項目${orderNum}`,
        itemType: "1",
        masterType: "",
        required: false,
        order: orderNum,
        defaultValue: ""
      }
      this.editItem = item
    },
    /**
     * 編集用リストを初期化します。
     */
    initEditList() {
      let item = {
        id: 0,
        name: "新規リスト",
        list: []
      }
      this.editMasterItem = item
    },
    /**
     * 編集用リスト項目を初期化します。
     */
    initEditListItem() {

      let item = {
        id: null,
        name: "新規選択項目",
        value: "",
        order: 0
      }

      this.editValueItem = item
    },
    onCheckDefaultIcon(row) {
      this.dbData.inspectMaster.iconSetting.forEach(function(item) {
        if (item.id != row.id) {
          item.isDefault = false
        }
      }.bind(this))

    },
    setIconUrl() {
      console.log(`setIconUrl`)
      const _this = this
      this.iconUrlList = {}
      this.iconSettingList.forEach((item) => {
        if (item.iconFilePath) {
          _this.getTenantFile(item.iconFilePath).then(result => {
            _this.iconUrlList[item.iconFilePath] = result
            console.log(`アイコンURL:${_this.iconUrlList[item.iconFilePath]}`)
          })
        }
      })
    },
    showTab(num) {
      this.tabs.tab1Show = false
      this.tabs.tab2Show = false
      this.tabs.tab3Show = false

      const _this = this
      this.$nextTick(() => {
        switch (num) {
        case 1:
          _this.tabs.tab1Show = true
          break
        case 2:
          _this.tabs.tab2Show = true
          break
        case 3:
          _this.tabs.tab3Show = true
          break
        }
      })

    },
    itemTypeName(value) {
      const type = this.itemTypes.find((type) => type.value == value)
      return type ? type.label : ""
    },
    onClickAddItem() {
      this.addItem()
    },
    onClickAddIconItem(){
      let orderNum = this.dbData.inspectMaster.iconSetting.length
      const id = short.generate()
      let item = {
        id: id,
        name: "新規アイコン",
        iconClass: id,
        iconFilePath: "",
        systemIconType: "",
        systemIconColor: "",
        order: orderNum,
        isDefault: false,
      }

      this.editIconItem = item
      this.dbData.inspectMaster.iconSetting.push(item)
    },
    addItem() {
      let orderNum = this.dbData.inspectMaster.inspectItemList.length
      let item = {
        id: short.generate(),
        name: `新規項目${orderNum}`,
        itemType: "1",
        masterType: "",
        required: false,
        order: orderNum,
        defaultValue: ""
      }
      this.editItem = item

      this.dbData.inspectMaster.inspectItemList.push(item)
    },
    onClickInspectItemrow(row) {
      this.editItem = null
      this.$nextTick(function() {
        this.editItem = row
      })
    },
    onDeleteItem(row) {

    },
    onClickAddMasterItem() {
      this.addMaster()
    },
    addMaster() {
      let item = {
        id: short.generate(),
        name: "新規マスタ",
        list: []
      }
      this.dbData.inspectMaster.inputMaster.push(item)
    },
    onClickMasterRow(row, column, event) {
      this.editMasterItem = null
      this.editValueItem = null
      this.$nextTick(function() {
        this.editMasterItem = row
      })

    },
    onClickAddValueItem() {

      const orderNum = this.editMasterItem.list.length
      console.log(`inputMasterId::${this.editMasterItem.id}`)
      const currentInputMaster = this.editMasterItem
      let curentIndex = this.dbData.inspectMaster.inputMaster.findIndex(item => {
        return item.id == currentInputMaster.id
      })

      let item = {
        id: short.generate(),
        name: "新規アイテム",
        value: "",
        order: orderNum
      }
      this.dbData.inspectMaster.inputMaster[curentIndex].list.push(item)
    },
    onClickInputMasterRow(row){
      this.editValueItem = null
      this.$nextTick(function() {
        this.editValueItem = row
      })
    },
    onClickIconSettingRow(row){
      this.editIconItem = null
      this.$nextTick(function() {

        this.editIconItem = row
      })
    },
    async saveInspectMaster() {
      try {
        await InspectMasterModel.updateInspectMaster(this.dbData).then(() => {
          console.log(`saveInspectMaster end`)

          // 一度でも保存したらfalse
          this.isNeverSaved = false;

          // 動作状態をクリア
          this.actionType = this.setActionType("-1");
        })
      } catch (e) {
        alert(`保存に失敗 ${e}`)
        throw e
      }
    },
    onDeleteInspectRow(row) {
      const _this = this
      this.editItem = null
      this.$nextTick(function() {
        this.modal = {
          isShow: true,
          title: "検査項目の削除",
          message: `${row.name}を削除しますか？`,
          okLabel: "削除",
          okType: "danger",
          okCallBack: function() {
            // 選択行のIDに該当しないものだけを残す
            _this.dbData.inspectMaster.inspectItemList = _this.dbData.inspectMaster.inspectItemList.filter(function(item) {
              return item.id != row.id
            })

            // 閉じる
            _this.modal.isShow = false
          },
          cancelLabel: "キャンセル",
          cancelType: "secondary",
          cancelCallBack: function() {
            _this.modal.isShow = false
          },
        }
      })

    },
    onDeleteMasterRow(row) {
      const _this = this
      this.editMasterItem = null
      this.editValueItem = null
      this.$nextTick(function() {
        this.modal = {
          isShow: true,
          title: "マスタデータの削除",
          message: `${row.name}を削除しますか？`,
          okLabel: "削除",
          okType: "danger",
          okCallBack: function() {
            // 選択行のIDに該当しないものだけを残す
            _this.dbData.inspectMaster.inputMaster = _this.dbData.inspectMaster.inputMaster.filter(function(item) {
              return item.id != row.id
            })

            // 閉じる
            _this.modal.isShow = false
          },
          cancelLabel: "キャンセル",
          cancelType: "secondary",
          cancelCallBack: function() {
            _this.modal.isShow = false
          },
        }
      })
    },
    onDeleteValueRow(row) {
      const _this = this
      this.editValueItem = null

      this.$nextTick(function() {
        this.modal = {
          isShow: true,
          title: "項目の削除",
          message: `${row.name}を削除しますか？`,
          okLabel: "削除",
          okType: "danger",
          okCallBack: function() {
            // 選択行のIDに該当しないものだけを残す
            _this.editMasterItem.list = _this.editMasterItem.list.filter(function(item) {
              return item.id != row.id
            })

            // 閉じる
            _this.modal.isShow = false
          },
          cancelLabel: "キャンセル",
          cancelType: "secondary",
          cancelCallBack: function() {
            _this.modal.isShow = false
          },
        }
      })
    },

    onClickBackToInspecList() {
      this.$emit('transfer', {key: "inspectMasterListArgs", values: {}})
    },
    onDeleteRows(titleName, listType) {
      console.log(listType);

      // アクティブな選択データ
      const tab = this.getActiveTab;
      const selectedData = this.getSelectedData(listType);
      const count = selectedData.length;

      let message = '';
      if (count < 1) {
        return;
      } else if (count == 1) {
        message = `${selectedData[0].name}を削除しますか？`
      } else if (count > 1) {
        message = `${count}件の${titleName}を削除しますか？`
      }
      // console.log(message);

      const _this = this
      this.$nextTick(function() {
        this.modal = {
          isShow: true,
          title: `${titleName}の削除`,
          message: message,
          okLabel: "削除",
          okType: "danger",
          okCallBack: function() {
            switch (tab) {
            case "tabStatus":
              // s3から削除
              for (let i = 0; i < count; i++) {
                _this.removeIconS3(selectedData[i].iconFilePath);
              }

              // 選択行のIDに該当しないものだけを残す
              for (let i = 0; i < count; i++) {
                _this.dbData.inspectMaster.iconSetting = _this.dbData.inspectMaster.iconSetting.filter(function(item) {
                  return item.id != selectedData[i].id
                })
              }
              break;
            case "tabItem":
              for (let i = 0; i < count; i++) {
                _this.dbData.inspectMaster.inspectItemList = _this.dbData.inspectMaster.inspectItemList.filter(function(item) {
                  return item.id != selectedData[i].id
                })
              }
              break;
            case "tabList":
              if (listType == 0) {
                for (let i = 0; i < count; i++) {
                  _this.dbData.inspectMaster.inputMaster = _this.dbData.inspectMaster.inputMaster.filter(function(item) {
                    return item.id != selectedData[i].id
                  })
                }
              } else if (listType == 1) {
                for (let i = 0; i < count; i++) {
                  _this.editMasterItem.list = _this.editMasterItem.list.filter(function(item) {
                    return item.id != selectedData[i].id
                  })
                }
              }
              break;
            }

            // DBを更新
            _this.saveInspectMaster();

            _this.initEditItems(_this.dbData.inspectMaster);

            // 閉じる
            _this.modal.isShow = false
          },
          cancelLabel: "キャンセル",
          cancelType: "secondary",
          cancelCallBack: function() {
            _this.modal.isShow = false
          },
        }
      })

    },
    async uploadIcon(files) {
      console.log(files)

      if (!this.editIconItem) {
        return
      }
      if (files.length > 0) {
        try {
          // 重複チェック(既存であればアップロードしない)
          if (await this.isDuplicateIcon(files[0].name)) {
            // s3のキーを更新
            this.editIconItem.iconFilePath = await this.getS3IconKey(files[0].name);
            console.log(this.editIconItem.iconFilePath)
            return;
          }

          if (this.actionType.value == "1") {
            // 既存のファイルをs3から削除(既存アイコンの変更時)
            await this.removeIconS3(this.editIconItem.iconFilePath);
            // await this.removeIconS3(files[0].name);
          }

          this.files = files
          const user = await UserInfo.getUserInfo()
          if (!this.dbData.inspectMaster.id) {
            throw new Error(`inspectMaster id is empty`)
          }

          const s3Key = `project_${this.projectId}/inspectMaster_${this.dbData.inspectMaster.id}/icon_${this.files[0].name}`
          // テナントprefixを付与
          const s3key = `${this.tenantStorage.getBaseDir()}${s3Key}`;

          let contentType = mime.lookup(files[0].name)
          const option = {ContentType: contentType}

          console.log(`uploadIcon:${s3key} ${JSON.stringify(option)}`)

          await this.tenantStorage.put(s3key, files[0].raw, option)

          // const s3Key = `project_${this.projectId}/inspectMaster_${this.dbData.inspectMaster.id}/icon_${this.files[0].name}`
          // const s3KeyPrefix = `tenant/${user.group}/`
          // const option = {level: "public", customPrefix: {public: s3KeyPrefix}, contentType: contentType}
          // console.log(`uploadIcon ${s3KeyPrefix}${s3Key} ${JSON.stringify(option)}`)
          // await Storage.put(s3Key, files[0].raw, option)

          this.editIconItem.iconFilePath = s3Key

        } catch (e) {
          alert(`error ${JSON.stringify(e,null,"\t")}`)
          throw e
        }
      }
    },

    async getTenantFile(s3Key) {

      if (!s3Key) {
        return ""
      }
      try {
        // テナントディレクトリを付与
        const s3key = `${this.tenantStorage.getBaseDir()}${s3Key}`
        const url = (await this.tenantStorage.get(s3key)).toBase64();
        console.log(`url:: ${url}`)
        return url;

        // const user = await UserInfo.getUserInfo()
        // const s3KeyPrefix = `tenant/${user.group}/`
        // console.log(`getTenantFile ${s3KeyPrefix}${s3Key}`)
        // const url = await this.get(`${s3Key}`, {level: "public", customPrefix: {public: s3KeyPrefix}})
        // console.log(`url:: ${url}`)
        // return url.toString()
      } catch (e) {
        alert(`error getTenantFile ${JSON.stringify(e, null, "\t")}`)
        throw e
      }
    },
    /**
     * アップロード用REST API呼び出しテストコード
     */
    async upload(option, e) {
      this.debugText = ""
      const data = e.target.files || e.dataTransfer.files
      this.debugText = `${JSON.stringify(option)}`
      try {
        const keyWithoutPrefix = data[0].name
        let result = await Storage.put(keyWithoutPrefix, data[0], option)
        this.debugText = `${this.debugText} 成功:${JSON.stringify(result)}`
        const url = await this.get(keyWithoutPrefix, option)
        this.debugText = `${this.debugText} URL::${url}`

        this.setIconUrl()
        return url
      } catch (e) {
        this.debugText = `${this.debugText} エラー:${JSON.stringify(e)}`
      }
    },
    async get(key, config) {
      try {
        let imageUrl = await Storage.get(key, config)
        return imageUrl
      } catch (e) {
        this.debugText = `エラー:${JSON.stringify(e)}`
      }
    },
    /**
     * 基本設定タブの保存ボタンクリック時処理です。
     */
    async saveInspectMasterBase() {
      // 検査マスタを保存
      await this.saveInspectMaster();

      // 一覧へ戻る
      this.onClickBackToInspecList();
    },
    /**
     * タブの切り替え時処理です。
     */
    tabSelect(tab, event) {
      console.log(tab)
      this.activeTab = tab.props.name;
    },
    /**
     * ステータステーブルの選択状態の変更時に呼び出されます。
     */
    handleSelectionChangeStatus(selections) {
      const count = selections.length;
      // console.log(selections);
      console.log(`選択件数：${count}`);

      if (count > 1) {
        this.isSingleSelectStatus = false;
        this.isMultiSelectStatus = true;
      } else if (count == 1) {
        this.isSingleSelectStatus = true;
        this.isMultiSelectStatus = false;
      } else {
        // 0件
        this.isSingleSelectStatus = false;
        this.isMultiSelectStatus = false;
      }

      this.selectedDataStatus = selections;
    },
    /**
     * ステータステーブルのカレント行の変更時に呼び出されます。
     */
    handleCurrentChangeStatus(value) {
      console.log(value);

      if (!value) {
        return;
      }

      let val = {...value};
      // インデックスを付与
      val['index'] = this.getRowIndex(value.id);

      this.currentRowStatus = val;
      // this.currentRowStatus = this.getRowIndex(value.id);
    },
    /**
     * 項目テーブルの選択状態の変更時に呼び出されます。
     */
    handleSelectionChangeItem(selections) {
      const count = selections.length;
      console.log(`選択件数：${count}`);

      if (count > 1) {
        this.isSingleSelectItem = false;
        this.isMultiSelectItem = true;
      } else if (count == 1) {
        this.isSingleSelectItem = true;
        this.isMultiSelectItem = false;
      } else {
        // 0件
        this.isSingleSelectItem = false;
        this.isMultiSelectItem = false;
      }

      this.selectedDataItem = selections;
    },
    /**
     * 項目テーブルのカレント行の変更時に呼び出されます。
     */
    handleCurrentChangeItem(value) {
      console.log(value);

      if (!value) {
        return;
      }

      let val = {...value};
      // インデックスを付与
      val['index'] = this.getRowIndex(value.id);

      this.currentRowItem = val;
      // this.currentRowStatus = this.getRowIndex(value.id);
    },
    /**
     * リストテーブルの選択状態の変更時に呼び出されます。
     */
    handleSelectionChangeList(selections) {
      const count = selections.length;
      console.log(`選択件数：${count}`);

      if (count > 1) {
        this.isSingleSelectList = false;
        this.isMultiSelectList = true;
      } else if (count == 1) {
        this.isSingleSelectList = true;
        this.isMultiSelectList = false;
      } else {
        // 0件
        this.isSingleSelectList = false;
        this.isMultiSelectList = false;
      }

      this.selectedDataList = selections;
    },
    /**
     * リストテーブルのカレント行の変更時に呼び出されます。
     */
    handleCurrentChangeList(value) {
      console.log(value);

      if (!value) {
        return;
      }

      // ここで選択項目をソートしておく
      value.list.sort(function(a, b) {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      })
      // value.list.slice().sort((a, b) => {
      //   return a.order - b.order;
      // })

      console.log(value)

      let val = {...value};
      // インデックスを付与
      val['index'] = this.getRowIndex(value.id);

      this.currentRowList = val;

      // 選択項目の表示
      this.editMasterItem = null
      // this.editValueItem = null
      const _this = this
      this.$nextTick(function() {
        _this.editMasterItem = value;
        // _this.editMasterItem = val
        // console.log(_this.editMasterItem)
      })
      this.editMasterItem = val;
      // console.log(this.editMasterItem)
    },
    /**
     * リスト選択項目テーブルの選択状態の変更時に呼び出されます。
     */
    handleSelectionChangeListItem(selections) {
      const count = selections.length;
      console.log(`選択件数：${count}`);

      if (count > 1) {
        this.isSingleSelectListItem = false;
        this.isMultiSelectListItem = true;
      } else if (count == 1) {
        this.isSingleSelectListItem = true;
        this.isMultiSelectListItem = false;
      } else {
        // 0件
        this.isSingleSelectListItem = false;
        this.isMultiSelectListItem = false;
      }

      this.selectedDataListItem = selections;
    },
    /**
     * リストテーブルのカレント行の変更時に呼び出されます。
     */
    handleCurrentChangeListItem(value) {
      console.log(value);

      if (!value) {
        return;
      }

      let val = {...value};
      // インデックスを付与
      val['index'] = this.getRowIndex(value.id);

      this.currentRowListItem = val;

    },
    /**
     * 各タブの新規追加ボタンクリック処理
     * @param {Number} listType 種別（リスト項目タブ時のみ指定）
     */
    onClickAddNew(listType) {
      this.actionType = this.setActionType("0");
      this.saveButtonLabel = "登録";

      let tab = this.getActiveTab;

      switch (tab) {
      case "tabStatus":
        this.onClickNewInspectStatus();
        break;
      case "tabItem":
        this.onClickNewInspectItem();
        break;
      case "tabList":
        if (listType == 0) {
          this.onClickNewInspectList();
        } else if (listType == 1) {
          this.onClickNewInspectListItem();
        }
        break;
      }
    },
    /**
     * 各タブの編集ボタンクリック処理
     * ※リスト項目タブ時は個別のメソッドを使用(複数テーブルのため)
     */
    onClickEdit(row) {
      this.actionType = this.setActionType("1");
      this.saveButtonLabel = "更新";

      let tab = this.getActiveTab;

      switch (tab) {
      case "tabStatus":
        this.onEditInspectStatus(row);
        break;
      case "tabItem":
        this.onEditInspectItem(row);
        break;
      }
    },
    /**
     * 各タブのコピーボタンクリック処理
     * @param {Number} listType 種別（リスト項目タブ時のみ指定）
     */
    onClickCopy(listType) {
      this.actionType = this.setActionType("3");
      this.saveButtonLabel = this.actionType.label;

      let tab = this.getActiveTab;
      let row = this.getSelectedData(listType)[0];
      // let row = this.getCurrentRow();

      switch (tab) {
      case "tabStatus":
        this.onEditInspectStatus(row);
        break;
      case "tabItem":
        this.onEditInspectItem(row);
        break;
      case "tabList":
        if (listType == 0) {
          this.onEditInspectList(row);
        } else if (listType == 1) {
          this.onEditInspectListItem(row);
        }
        break;
      }
    },
    /**
     * 検査ステータスの新規追加ボタンクリック処理
     */
    onClickNewInspectStatus() {
      this.addMode = true;
      this.iconSetting = '';

      // 登録、更新用アイコンリスト
      let orderNum = this.dbData.inspectMaster.iconSetting.length
      const id = short.generate()
      let item = {
        id: id,
        name: "新規アイコン",
        iconClass: id,
        iconFilePath: "",
        systemIconType: "",
        systemIconColor: "",
        order: orderNum,
        isDefault: false,
      }

      // this.iconSetting = item;
      // this.editIconItems.push(item)

      this.editIconItem = item
      // 仮要素（保存前に削除する）
      this.editIconItem['iconUrl'] = null;

      // データへの追加はモーダルで登録時
      // this.dbData.inspectMaster.iconSetting.push(item)

      this.modals.status = true;
    },
    /**
     * 検査ステータスの編集（編集ボタン / ステータス名クリック）
     */
    onEditInspectStatus(row) {

      console.log(row)

      if (!row.iconFilePath) {
        // 編集ボタンクリック時は、先に選択したチェック行が対象
        row = this.selectedDataStatus[0];
      }

      if (this.actionType.value == "3") {
        // コピー時はオブジェクトを複製
        console.log('copy')
        this.editIconItem = {...row};
        // idを更新
        this.editIconItem.id = short.generate();
        // orderは+1
        this.editIconItem.order = this.editIconItem.order + 1;
      } else {
        this.editIconItem = row;
      }

      // 仮要素（保存前に削除する）
      this.editIconItem['iconUrl'] = this.iconUrlList[row.iconFilePath];
      let file = {name: path.basename(row.iconFilePath)};
      this.selectIconFile = file;
      // this.onClickIconSettingRow(row);
      this.modals.status = true;

    },
    /**
     * 検査ステータスモーダルアイコン変更時の処理です。
     */
    async handleOnChange(file, fileList) {
      // let image = document.querySelector('#iconImage')
      // if (image) {
      //   console.log(image)
      //   image.src = '';
      // }
      console.log(file)

      // モーダル表示反映用
      const _this = this
      this.$nextTick(() => {
        _this.editIconItem.iconUrl = '';
        _this.editIconItem.iconUrl = URL.createObjectURL(file.raw);
        // _this.editIconItem.iconFilePath = '';
        // _this.editIconItem.iconFilePath = URL.createObjectURL(file.raw);
        // _this.imageUrl = '';
        // _this.imageUrl = URL.createObjectURL(file.raw);
      })

      // s3登録用
      this.selectIconFile = file;
      // this.editIconItem = file;

    },
    handleRemove(file) {
      console.log(file);

    },
    /**
     * 検査ステータスのモーダルで保存ボタンクリック時の処理です。
     */
    async onClickSubmitStatus() {
      console.log(this.editIconItem)
      // console.log(this.dbData.inspectMaster.iconSetting)

      // this.editIconItems.push(this.iconSetting)

      // 仮要素の削除
      delete this.editIconItem['iconUrl']
      delete this.editIconItem['index']

      // アイコンファイルrawデータが指定された（ファイルを選択された）場合のみs3登録
      if (this.selectIconFile.raw) {
        // s3にアップロード
        const files = [];
        files.push(this.selectIconFile);
        await this.uploadIcon(files);

        // // 元データに追加
        // this.dbData.inspectMaster.iconSetting.push(this.editIconItem)

        // // アイコンURLの更新(モーダル元vueへの反映) ★Todo
        // this.setIconUrl();
        // console.log(this.iconUrlList);
        // キーと値を同一で仮入れ（保存時にs3へアップロードした値で更新
        // this.iconUrlList[this.editItem.iconFilePath] = this.editItem.iconFilePath;
        // this.iconUrlList[this.iconSetting.iconFilePath] = this.iconSetting.iconFilePath;
        // this.iconUrlList.push(this.iconSetting.iconFilePath)

      }

      // 新規登録とコピー時
      if (this.actionType.value == "0" || this.actionType.value == "3") {
        // 元データに追加
        this.dbData.inspectMaster.iconSetting.push(this.editIconItem)
        console.log(this.dbData.inspectMaster.iconSetting)

        // アイコンURLの更新(モーダル元vueへの反映)
        this.setIconUrl();
        // console.log(this.iconUrlList);
      }

      // DBの更新
      await this.saveInspectMaster();

      this.modals.status = false;
    },

    /**
     * アイコンのs3キーを取得します。
     * @returns {string} アイコンs3キー
     */
    async getS3IconKey(iconFileName) {
      try {
        if (!this.dbData.inspectMaster.id) {
          throw new Error(`inspectMaster id is empty`)
        }

        const s3Key = `project_${this.projectId}/inspectMaster_${this.dbData.inspectMaster.id}/icon_${iconFileName}`
        // const s3KeyPrefix = `tenant/${user.group}/`
        // const option = {level: "public", customPrefix: {public: s3KeyPrefix}, contentType: contentType}

        // console.log(`IconS3Key ${s3KeyPrefix}${s3Key}`)
        // console.log(`uploadIcon ${s3KeyPrefix}${s3Key} ${JSON.stringify(option)}`)

        return s3Key;
      } catch (e) {
        alert(`error ${JSON.stringify(e, null, "\t")}`)
        throw e
      }

    },
    /**
     * s3からアイコンファイルを削除します。
     */
    async removeIconS3(iconName) {
      try {

        console.log(iconName)
        console.log(this.iconSettingList.length)

        // 複数定義されている場合は削除しない
        let count = this.iconSettingList.reduce((prev, item) => {
          console.log(item.iconFilePath)
          return prev + (item.iconFilePath == iconName ? 1 : 0)
        }, 0)
        console.log(count)
        if (count > 1) {
          // 1件(削除対象自身)以上ある場合は削除しない
          console.log(`アイコン定義数:${count} ->削除無し`)
          return;
        }

        // S3キー名
        const s3Key = `${this.tenantStorage.getBaseDir()}${iconName}`;
        // const s3Key = await this.getS3IconKey(iconName);
        console.log(s3Key)
        // const s3Key = `${this.tenantId}/${projectId}/${nodeId}/${this.removeFile.title}`

        if (!s3Key) {
          return;
        }

        // ファイル削除
        await this.tenantStorage.delete(s3Key);
        // await Storage.remove(s3Key);

      } catch (e) {
        throw new Error(`ファイル削除エラー ${e}`)

      }
    },
    /**
     * 現在のアイコンリストに重複があるかどうかを判定します。
     * @param {string} iconName 対象のアイコン名
     * @returns 重複がある場合true、さもなくばfalse。
     */
    async isDuplicateIcon(iconName) {
      let result = false;
      const prefixIconName = `icon_${iconName}`;

      this.iconSettingList.forEach((item) => {
        if (item.iconFilePath) {
          const fileName = path.basename(item.iconFilePath);
          console.log(fileName)
          if (fileName == prefixIconName) {
            console.log('重複あり')
            result = true;
            return;
          }
        }
      })
      return result;
    },
    /**
     * 検査項目の新規追加ボタンクリック処理
     */
    onClickNewInspectItem() {
      // this.addMode = true;
      // this.iconSetting = '';

      // 登録、更新用項目リスト
      let orderNum = this.dbData.inspectMaster.inspectItemList.length
      let item = {
        id: short.generate(),
        name: `新規項目${orderNum + 1}`,
        itemType: "1",
        masterType: "",
        required: false,
        order: orderNum,
        defaultValue: ""
      }
      this.editItem = item

      // モーダル保存時に元データへ追加
      // this.dbData.inspectMaster.inspectItemList.push(item)

      this.modals.item = true;
    },
    /**
     * 検査項目の編集（編集ボタン / テーブル項目名クリック）
     */
    onEditInspectItem(row) {

      console.log(row)

      if (!row.id) {
        // 編集ボタンクリック時は、先に選択したチェック行が対象
        row = this.selectedDataItem[0];
      }

      if (this.actionType.value == "3") {
        // コピー時はオブジェクトを複製
        this.editItem = {...row};
        // idを更新
        this.editItem.id = short.generate();
        // orderは最大値+1
        let orderCount = this.inspectItemList.length
        this.editItem.order = orderCount + 1;
      } else {
        this.editItem = row;
      }

      this.modals.item = true;

    },
    /**
     * 検査項目のモーダルで保存ボタンクリック時の処理です。
     */
    async onClickSubmitItem() {
      console.log(this.editItem)

      // 新規登録とコピー時
      if (this.actionType.value == "0" || this.actionType.value == "3") {
        // 元データに追加
        this.dbData.inspectMaster.inspectItemList.push(this.editItem)
        console.log(this.dbData.inspectMaster.inspectItemList)
      }

      // DBの更新
      await this.saveInspectMaster();

      this.modals.item = false;
    },
    /**
     * リスト項目の新規追加ボタンクリック処理
     */
    onClickNewInspectList() {
      // this.addMode = true;
      // this.iconSetting = '';

      // 登録、更新用リスト
      let item = {
        id: short.generate(),
        name: "新規リスト",
        list: []
      }
      // モーダル保存時に元データへ追加
      // this.dbData.inspectMaster.inputMaster.push(item)

      this.editMasterItem = item

      this.modals.list = true;
    },
    /**
     * リスト項目の編集（編集ボタン / テーブル項目名クリック）
     */
    onEditInspectList(row) {

      if (this.actionType.value != "3") {
        this.actionType = this.setActionType("1");
        this.saveButtonLabel = "更新";
      }

      console.log(row)

      if (!row.id) {
        // 編集ボタンクリック時は、先に選択したチェック行が対象
        row = this.selectedDataList[0];
      }

      if (this.actionType.value == "3") {
        // コピー時はオブジェクトを複製
        this.editMasterItem = {...row};
        // idを更新
        this.editMasterItem.id = short.generate();
      } else {
        this.editMasterItem = row;
      }

      this.modals.list = true;

    },
    /**
     * リスト項目のモーダルで保存ボタンクリック時の処理です。
     */
    async onClickSubmitList() {
      console.log(this.editMasterItem)

      // 新規登録とコピー時
      if (this.actionType.value == "0" || this.actionType.value == "3") {
        // 元データに追加
        this.dbData.inspectMaster.inputMaster.push(this.editMasterItem)
        console.log(this.dbData.inspectMaster.inputMaster)
      }

      // DBの更新
      await this.saveInspectMaster();

      this.modals.list = false;
    },
    /**
     * リスト選択項目の新規追加ボタンクリック処理
     */
    onClickNewInspectListItem() {

      const orderNum = this.editMasterItem.list.length
      console.log(`inputMasterId::${this.editMasterItem.id}`)
      const currentInputMaster = this.editMasterItem
      let curentIndex = this.dbData.inspectMaster.inputMaster.findIndex(item => {
        return item.id == currentInputMaster.id
      })

      let item = {
        id: short.generate(),
        name: "新規選択項目",
        value: "",
        order: orderNum
      }
      // モーダル保存時に元データへ追加
      // this.dbData.inspectMaster.inputMaster[curentIndex].list.push(item)

      this.editValueItem = item

      this.modals.listItem = true;
    },
    /**
     * リスト選択項目の編集（編集ボタン / テーブル項目名クリック）
     */
    onEditInspectListItem(row) {

      if (this.actionType.value != "3") {
        this.actionType = this.setActionType("1");
        this.saveButtonLabel = "更新";
      }

      console.log(row)

      if (!row.id) {
        // 編集ボタンクリック時は、先に選択したチェック行が対象
        row = this.selectedDataListItem[0];
      }

      if (this.actionType.value == "3") {
        // コピー時はオブジェクトを複製
        this.editValueItem = {...row};
        // idを更新
        this.editValueItem.id = short.generate();
        const orderCount = this.editMasterItem.list.length
        this.editValueItem.order = orderCount + 1;

      } else {
        this.editValueItem = row;
      }

      this.modals.listItem = true;

    },
    /**
     * リスト選択項目のモーダルで保存ボタンクリック時の処理です。
     */
    async onClickSubmitListItem() {
      console.log(this.editValueItem)

      // 新規登録とコピー時
      if (this.actionType.value == "0" || this.actionType.value == "3") {
        const currentInputMaster = this.editMasterItem
        let curentIndex = this.dbData.inspectMaster.inputMaster.findIndex(item => {
          return item.id == currentInputMaster.id
        })

        // 元データに追加
        this.dbData.inspectMaster.inputMaster[curentIndex].list.push(this.editValueItem)
        console.log(this.dbData.inspectMaster.inputMaster[curentIndex].list)
      }

      // DBの更新
      await this.saveInspectMaster();

      this.modals.listItem = false;
    },
    /**
     * テーブル行から指定したidのインデックスを取得します。
     * @params {string} id ID
     * @returns {number} インデックス
     */
    getRowIndex(id) {
      // 対象のテーブル
      let dataList = this.getTableList();

      console.log(`id：${id}`)
      console.log(dataList)

      let currentIndex = dataList.findIndex(item => {
        return item.id == id
      })

      console.log(`カレント行：${currentIndex}`)
      return currentIndex;
    },

    /**
     * 対象のテーブルリストを取得します。
     * @returns 対象タブのテーブルリスト
     */
    getTableList() {
      // 対象のテーブル
      let dataList = null;
      let tab = this.getActiveTab;
      switch (tab) {
      case "tabStatus":
        dataList = this.iconSettingList;
        break;
      case "tabItem":
        dataList = this.inspectItemList;
        break;
      case "tabList":
        // dataList = this.editInputMasterList;
        dataList = this.editMasterItem.list;
        break;
      }

      return dataList;
    },
    /**
     * 対象テーブルのカレント行データを取得します。
     * @returns 対象テーブルのカレント行データ
     */
    getCurrentRow(listType) {
      let row = null;
      let tab = this.getActiveTab;
      switch (tab) {
      case "tabStatus":
        row = this.currentRowStatus;
        break;
      case "tabItem":
        row = this.currentRowItem;
        break;
      case "tabList":
        if (listType == 0) {
          row = this.currentRowList;
        } else if (listType === 1) {
          row = this.currentRowListItem;
        }
        break;
      }

      return row;
    },
    /**
     * 対象テーブルの選択行データを取得します。
     * @returns 対象テーブルのカレント行データ
     */
    getSelectedData(listType) {
      let selectData = null;
      let tab = this.getActiveTab;
      switch (tab) {
      case "tabStatus":
        selectData = this.selectedDataStatus;
        break;
      case "tabItem":
        selectData = this.selectedDataItem;
        break;
      case "tabList":
        if (listType == 0) {
          selectData = this.selectedDataList;
        } else if (listType == 1) {
          selectData = this.selectedDataListItem;
        }

        break;
      }

      return selectData;
    },
    /**
     * 上下に移動ボタンをクリックしたときの処理です。
     * @params {string} direction 移動方向('up' / 'down')
     */
    async onClickRowMove(direction) {
      // 連続押下防止
      if (this.processing) return;
      this.processing = true;

      await this.updateOrder(direction);

      this.processing = false;
    },

    /**
     * リストの並び順を更新します。
     */
    async updateOrder(direction) {
      return new Promise((resolve) => {
        setTimeout(() => {
          // 対象のテーブル
          let dataList = this.getTableList();
          console.log(dataList)

          let currentRow = null;
          const selectData = this.getSelectedData(1);
          if (selectData) {
            currentRow = selectData[0];
          }
          // let currentRow = this.getCurrentRow(1);

          // 現在の行インデックス
          const nowIndex = this.getRowIndex(currentRow.id);
          // const nowIndex = currentRow.index;

          console.log(`現在の行インデックス：${nowIndex}`)
          // console.log(selectData)
          console.log(currentRow)

          // 移動後のインデックス
          let afterIndex = nowIndex + 1;
          if (direction == 'up') {
            afterIndex = nowIndex - 1;
          }

          // リストの中から2つのインデックスに該当する行を抽出し、
          // インデックスを入れ替えた値でorderを更新
          // ※order順で並んでいる想定
          // ※先頭、末尾行への考慮はボタンのdisableで制御済みの想定

          dataList[nowIndex].order = afterIndex;
          dataList[afterIndex].order = nowIndex;

          // console.log(dataList)

          // 明示的に並び替える
          dataList.sort(function(a, b) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          })

          // DBを更新
          this.saveInspectMaster().then(() => {
            // // 仮要素の削除
            // delete currentRow['index']

            // チェックが外れてしまうので、移動後のデータを再チェック
            // this.selectRow(dataList[afterIndex]);
            this.selectRow(currentRow, true);

            // const _this = this
            // this.$nextTick(() => {
            // チェックが外れてしまうので、移動後のデータを再チェック
            // _this.selectRow(currentRow);
            // })

          })

          // // 連続実行用に選択行情報を更新
          // currentRow.index = afterIndex;

          resolve();
        }, 100);
      });

    },
    /**
     * 上下移動後の行を選択します。
     */
    selectRow(row, isSelect) {
      console.log(row)
      // 対象のテーブル
      let tab = this.getActiveTab;
      switch (tab) {
      case "tabStatus":
        this.$refs.tableStatus.toggleRowSelection(row, isSelect);
        this.$refs.tableStatus.setCurrentRow(row);
        break;
      case "tabItem":
        this.$refs.tableItem.toggleRowSelection(row, isSelect);
        this.$refs.tableItem.setCurrentRow(row);
        break;
      case "tabList":
        this.$refs.tableListItem.toggleRowSelection(row, isSelect);
        this.$refs.tableListItem.setCurrentRow(row);
        break;
      }

    },
    setActionType(value) {
      const type = this.actionTypes.find((type) => type.value == value)
      return type ? type : null
    },
    /**
     * 選択した検査ステータスを削除します。
     */
    onDeleteInspectStatus() {


    },
  },
};
</script>
