/**
 * 入力チェックのUtilクラス
 */
class ValidateUtil {
  /**
   * 未入力チェック
   * @param {*} 検証する文字列
   * @returns true: OK、false: 入力エラー
   */
  static checkEmpty(str) {
    if (str) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 文字数チェック
   * @param {*} 検証する文字列
   * @returns true: OK、false: 入力エラー
   */
  static checkStrLength(str, length) {
    // 空文字は判定しない
    if (!str) {
      return true;
    }

    return str && str.length <= length;
  }

  /**
   * bitチェック
   * @param {*} 検証する文字列
   * @returns true: OK、false: 入力エラー
   */
  static checkBit(str) {
    let reg = new RegExp(/^[01]$/);
    return reg.test(str);
  }

  /**
   * 数値チェック
   * @param {*} 検証する文字列
   * @returns true: OK、false: 入力エラー
   */
  static checkNumber(str) {
    let reg = new RegExp(/^[0-9]+(\.[0-9]+)?$/);
    return reg.test(str);
  }

  /**
   * 小数チェック
   * @param {*} 検証する文字列
   * @returns true: OK、false: 入力エラー
   */
  static checkDecimal(str) {
    let reg = new RegExp(/^([1-9]\d*|0)(\.\d+)?$/);
    return reg.test(str);
  }

  /**
   * メールアドレスを検証する
   * @param {*} 検証する文字列
   * @returns true: OK、false: 入力エラー
   */
  static checkMailAddress(str) {

    // HTML5にて規定されているメールアドレスチェックの正規表現に準ずる
    // https://html.spec.whatwg.org/#e-mail-state-(type=email)
    let reg = /^[a-zA-Z0-9\.!#$%&'*+/=?^_'{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
    //let reg = /^[a-zA-Z0-9\.!#$%&'*+/=?^_'{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    return reg.test(str);
  }

  /**
   * 任意の文字列を許可しないチェック
   * @param {*} str 検証する文字列
   * @param {*} invalidWord  
   * @returns true:OK false:許可しない文字あり
   */
  static checkCustomWord(str, invalidWord) {
    let result = true;
    for (let i = 0; i < String(invalidWord).length; i++) {
      let check_str = String(invalidWord).substr(i, 1);
      let ret = String("dummy" + str).indexOf(check_str);
      if (ret > 0) {
        result = false;
        break;
      }
    }
    return result;
  }
  /**
   * 禁則文字チェック
   * @param {*} 検証する文字列
   * @returns true:OK false:禁則文字あり
   */
  static checkKinsokuChar(str) {
    let result = true;
    const kinsoku_str = process.env.VUE_APP_KINSOKU_CHARACTERS;
    for (let i = 0; i < String(kinsoku_str).length; i++) {
      let check_str = String(kinsoku_str).substr(i + 1, 1);
      let ret = String("dummy" + str).indexOf(check_str);
      if (ret > 0) {
        result = false;
        break;
      }
    }
    return result;
  }

  /**
   * decimal桁数チェック
   * @param {*} 検証する文字列
   * @returns true:OK false:桁数異常あり
   */
  static checkNumberOfDigitsInDecimal(str) {
    // lengthが2
    let words = str.split("/");
    if (words.length != 2) {
      return false;
    }

    if (words[0] === null || words[0] === "") {
      return false;
    }
    if (words[1] === null || words[1] === "") {
      return false;
    }
    if (isNaN(words[0]) || isNaN(words[1])) {
      return false;
    }
    let leftNumber = Number(words[0]);
    let rightNumber = Number(words[1]);
    // 左側・右側が整数であること
    if (Number.isInteger(leftNumber) && Number.isInteger(rightNumber)) {
      // 左が右よりも大きいこと
      if (leftNumber > rightNumber) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }
  // ----------------
  // バリデーションメソッド
  // ----------------
  /**
   * 基本入力チェック処理
   */
  static checkBase(value, callback, length=0, isRequire=false, isMail=false, isNumber=false, isKinsoku=false, isPlusNumber=false) {
    
    if (isRequire) {
      // 必須チェック
      if (!value) {
        console.log(`value:${value} 必須エラー`)
        callback(new Error('入力必須項目です。'))
        return false
      }
    }
    if (length > 0 && value) {
      // 文字数チェック
      if (!((value + '').length <= length)) {
        callback(new Error(`${length}文字以内で入力して下さい。`))
        return false
      }
    }

    // 禁則文字チェック
    if (isKinsoku) { 
      if (!ValidateUtil.checkKinsokuChar(value)) {
        callback(new Error(`禁則文字が指定されてます。\n禁則文字 ${process.env.VUE_APP_KINSOKU_CHARACTERS}`))
        return false
      }
    }
    
    if (isMail && value) {
      // メールアドレス形式チェック  
      if (!ValidateUtil.checkMailAddress(value)) {
        callback(new Error('正しくないメールアドレスの形式です。'))
        return false
      } 
    }

    if (isNumber && value) {
      // 数値チェック
      if (isNaN(value)) {
        callback(new Error('数値で入力して下さい。'))
        return false
      }
    }
    if (isPlusNumber && value) {
      console.log(`isPlusNumber ${value}`)
      // 数値チェック
      if (isNaN(value)) {
        callback(new Error('数値(正の値)で入力してください。'))
        return false
      }
      if (value - 0 < 0) {
        callback(new Error('数値(正の値)で入力してください。'))
        return false
      }
    }
    
    return true
  }
}

module.exports = ValidateUtil;
