
const {
  ConsoleLogger,
  DefaultActiveSpeakerPolicy,
  DefaultModality,
  LogLevel,
  DefaultDeviceController,
  DefaultMeetingSession,
  MeetingSessionConfiguration,
  AsyncScheduler,
  DataMessage
} = require("amazon-chime-sdk-js");

const { API } = require("aws-amplify");
const MeetingModel = require("@/appModel/meeting/MeetingModel");
const UserInfo = require("@/appUtils/UserInfo");

class MeetingProvider{
  constructor() {
    this.meetingSession = {}
    this.audioInputList = []
    this.videoInputList = []
    this.videoQualityList = []
    this.waitTime = 0
    this.parmission = false
    this.roster = {}
    this.tiles = {}
    this.isSpeaking = {}
    this.selfAudioLevel = 0
    this.delegate = {
      didEndMeetingStartProcess: function() {},
      didEndJoinAttendeeProcess: function() {},
      didEndLeabeatendeeProcess: function() {},
      didEndMeetingEndProcess: function() {},
      didUpdateTiles: function() {},
      
    }
  }

  async startMyRoom(myUserSub) {
    console.log(`startMyRoom start ${myUserSub}`)
    const permission = await this.getPermissions()
   
    try {
      this.userSub = myUserSub
      this.skPrefix = `meeting#${this.userSub}`
      console.log(`this.skPrefix ${this.skPrefix}`)
      // DBからミーティング情報を取得する
      this.selectedMeeting = await MeetingModel.getMeeting({sk: this.skPrefix})
      console.log(`startMyRoom :${JSON.stringify(this.selectedMeeting)}`)
      if (!this.selectedMeeting) {
        // レコードがない場合は新規作成する
        this.selectedMeeting = await MeetingModel.getNewData(this.userSub)
        await MeetingModel.addMeeting(this.selectedMeeting)
      }
      
      await this.prepareMeeting()

    } catch (e) {
      console.log(JSON.stringify(e))
    }
    console.log(`startMyRoom end ${myUserSub}`)
  }

  async joinMeeting(roomSk) {   
    try {
      this.skPrefix = `${roomSk}`
      // DBからミーティング情報を取得する
      this.selectedMeeting = await MeetingModel.getMeeting({sk: this.skPrefix})
      // レコードなければ終了する
      if (!this.selectedMeeting) {
        return
      }
      // DBからミーティング情報を取得する
      await this.prepareMeeting()

    } catch (e) {
      console.log(JSON.stringify(e))
    }
  }
  /**
   * 権限取得
   */
  async getPermissions() {
    let parmission = true
    try {
      let streamAudio = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      
    } catch (e) {
      //alert('音声入出力無し')
      parmission = false
    }
    try {
      let streamVideo = await navigator.mediaDevices.getUserMedia({
        video: true,
      
      });
      
    } catch (e) {
      parmission = false
    }

    return parmission
  }
  /**
   * ミーティング準備メイン処理
   */
  async prepareMeeting() {
    try {
      console.log(`prepareMeeting`)
      // ローディングを表示
      // this.loader = this.showLoading() //this.$loading.show();
      // URLパラメータで指定されたミーティングルーム情報を取得する
      this.selectedMeeting = await MeetingModel.getMeeting({sk: this.skPrefix})
      let inValidMeetingId = false
      if (this.selectedMeeting.meeting.meetingId) {
        console.log(`ミーティングIDあり ${this.selectedMeeting.meeting.meetingId}`)
        // ミーティングIDがあれば、有効なミーティングIDであるかチェックする
        try {
          // DB上のミーティングIDでChimeミーティングの開始と自身の参加まで行う
          await this.getMeeting(this.selectedMeeting)
        } catch (e) {
          console.log(`getMeetingで例外`)
          // 例外が起きた場合はミーティングIDが無効と判断する
          inValidMeetingId = true
        }
      }
      // ミーティングID無しまたはミーティング情報が無効である場合はミーティングID再取得を行いミーティング開始を試みる
      if (!this.selectedMeeting.meeting.meetingId || inValidMeetingId) {
        console.log(`新規ミーティングIDの発行`)

        await this.getMeetingId(this.selectedMeeting.pk, this.selectedMeeting.sk, this.selectedMeeting.meeting.meetingId)
      }
    } catch (e) {
      console.log(`${JSON.stringify(e)}`)
      // this.loader.hide()
      throw e
    }
  }

  /**
     * ChimeミーティングID取得
     */
  async getMeetingId(pk, sk, compareMeetingId = "") {
    try {
      // ミーティングID発行APIの呼び出し
      this.loadingProgressMessage = "ミーティングを作成中"
      const meetingApiName = 'mczmeetingrestapi'
      let meetingApiPath = '/meeting'

      const params = {
        headers: {},
        response: true,
        body: {
          pk: pk,
          sk: sk
        }
      };
        
      console.log(`mczmeetingrestapi params ${JSON.stringify(params)}`)
      const result = await API.post(meetingApiName, meetingApiPath, params)
      console.log(`mczmeetingrestapi end ${JSON.stringify(result)}`)
      // ミーティングID発行完了まで一定時間待機（初回は１秒待機）
      setTimeout(function() {
        console.log(`待機回数 : ${this.waitTime}`)
        this.waitTime += 1
        this.checkMeetingId(pk, sk, compareMeetingId)
      }.bind(this), 1000)

    } catch (e) {
      console.log(`error ${JSON.stringify(e)} pk:${pk} sk:${sk}`)
      throw e
    }
  }
  /**
     * ミーティングIDが発行されるまで一定回数待機する
     */
  checkMeetingId(pk, sk, compareMeetingId) {
    try {
      console.log(`checkMeetingId pk:${pk} sk:${sk} compareMeetingId:${compareMeetingId}`)
      MeetingModel.getMeeting({pk: pk, sk: sk}).then(function(result) {
        if (result) {
          // ミーティング取得前のIDと異なるIDとなっているかチェック
          if (result.meeting.meetingId != compareMeetingId) {
            // ミーティングIDが取得できれば次へ
            this.getMeeting(result)
            return
          } else {
            // ミーティングIDの発行を待つ
            if (this.waitTime <= 30) {
              // 30回までまつ
              this.waitTime += 1
              setTimeout(function() {
                console.log(`待機回数 : ${this.waitTime}`)
                this.checkMeetingId(pk, sk, compareMeetingId)
              }.bind(this), 300)
              return
            } else {
              alert(`タイムアウト`)
              // this.loader.hide()
              return
            }

          }
        } else {
          // this.loader.hide()
          alert(`対象データが存在しないため待機を終了`)
          return
        }
      }.bind(this))

    } catch (e) {
      console.log(`${JSON.stringify(e)}`)
      // this.loader.hide()
      throw e
    }

  }
  /**
     * ミーティングIDからミーティング情報を取得する。
     */
  async getMeeting(meetingRow) {
    try {
      console.log(`getMeeting`)

      // ミーティング情報取得の呼び出し
      const meetingApiName = 'mczmeetingrestapi'
      let meetingApiPath = '/meeting'
      meetingApiPath = `${meetingApiPath}/${meetingRow.meeting.meetingId}`
      console.log(`getMeeting ${meetingApiPath}`)
      const params = {
        headers: {},
        response: true,
      };
      const result = await API.get(meetingApiName, meetingApiPath, params)

      if (result.data) {
        if (!result.data.meeting) {
          throw new Error(`ミーティング情報取得失敗`)
        }
        console.log(`mczmeetingrestapi result ${JSON.stringify(result.data)}`)
        this.meeting = result.data.meeting

        // 参加情報を取得
        await this.attendMeeting(meetingRow)
        console.log(`ミーティング参加処理完了`)
        // ミーティング開始
        // await this.meetingStartProc()

      } else {
        throw new Error(`ミーティング情報取得失敗`)
      }

    } catch (e) {
      console.log(`${JSON.stringify(e)}`)
      // this.loader.hide()
      throw e
    }
  }
  /**
     * ミーティング参加者情報を取得して、ミーティングに参加する。
     */
  async attendMeeting(meetingRow) {
    try {
      // ミーティングID発行APIの呼び出し
      this.loadingProgressMessage = "ミーティングに参加します"

      const userInfo = await UserInfo.getUserInfo()

      const attendeeApiName = 'mczattendeeresstapi'
      let attendeeApiPath = '/attendee'
      attendeeApiPath = `${attendeeApiPath}/${encodeURIComponent(meetingRow.meeting.meetingId + "#" + userInfo.userName)}`
      const params = {
        headers: {},
        response: true,
      };
      const result = await API.get(attendeeApiName, attendeeApiPath, params)
      if (result.data) {
        this.attendee = result.data.attendee
        console.log(`result.data.attendee: ${JSON.stringify(result.data.attendee)}`)
        this.localAttendee = this.attendee.Attendee

        // Chimeセッションを作成する
        const logger = new ConsoleLogger('ChimeMeetingLogs', LogLevel.INFO);
        const deviceController = new DefaultDeviceController(logger);
        const configuration = new MeetingSessionConfiguration(this.meeting, this.attendee);
        this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);

        await this.setupAudioDeviceList()
        await this.setupVideoDeviceList()
        this.subscribeMeetingEvent()
        this.addObserver()

        this.delegate.didEndMeetingStartProcess(this)
        
      }

    } catch (e) { 
      console.log(`${JSON.stringify(e)}`)
      // this.loader.hide()
      throw e
    }
  }
  /**
     * 音声デバイスリストを取得
     */
  async setupAudioDeviceList() {
    this.audioInputList = this.populateList(
      await this.meetingSession.audioVideo.listAudioInputDevices(),
      `マイク`,
      { text: `なし`, value: `` }
    );
    this.speakerOutputList = this.populateList(
      await this.meetingSession.audioVideo.listAudioOutputDevices(),
      `スピーカー`,
      { text: `なし`, value: `` }
    );
    this.audioInput = this.audioInputList[0].value;
    console.log(`setupAudioDeviceList this.audioInput:${this.audioInput}`)
    this.speaker = this.speakerOutputList[0].value;

    // this.showPreview(this.videoInput);
  }
  /**
   * 映像デバイスリストを取得
   */
  async setupVideoDeviceList() {
    this.videoInputList = this.populateList(
      await this.meetingSession.audioVideo.listVideoInputDevices(),
      `ビデオ入力デバイス`,
      { text: `なし`, value: `` }
    );
    console.log(`ビデオデバイス数: ${this.videoInputList.length}`)

    this.videoQualityList.push({
      //640×360
      text: `360p (nHD) @ 15 fps (600 Kbps max)`,
      value: `360p`,
    });
    this.videoQualityList.push({
      //960x540
      text: `540p (qHD) @ 15 fps (1.4 Mbps max)`,
      value: `540p`,
    });
    this.videoQualityList.push({
      //1280x720
      text: `720p (HD) @ 15 fps (1.4 Mbps max)`,
      value: `720p`,
    });

    this.videoInput = this.videoInputList[0].value;
    this.videoQuality = this.videoQualityList[0].value;
  }
  /**
   *
   */
  populateList(devices, genericName, emptyItem) {
    //const devices = this.meetingSession.audioVideo.listAudioInputDevices()
    //alert(JSON.stringify(devices))

    let list = [];
    for (let i = 0; i < devices.length; i++) {
      list.push({
        text: devices[i].label || `${genericName} ${i + 1}`,
        value: devices[i].deviceId,
      });
    }
    if (emptyItem) {
      list.push(emptyItem);
    }
    console.log(`populateList genericName ${genericName} ${JSON.stringify(list, null, "\t")}`)
    return list;
  }
  addObserver() {
    // ミーティング状態変化の監視クラスを定義
    const observer = {
      // 映像ソースの増減時のデリゲート(参加者の増減)
      videoTileDidUpdate: function(tileState) {
        if (!tileState.boundAttendeeId) {
          return;
        }
        
        const tiles = this.meetingSession.audioVideo.getAllVideoTiles();
        console.log(`videoTileDidUpdate tiles:${tiles.length}`);
        tiles.forEach(function(tile){
          // 固定表示状態管理を追加
          // if (!this.pinStatus[tile.tileState.tileId]) {
          //   this.pinStatus[tile.tileState.tileId] = false;
          // }
          // }
        }.bind(this))

        this.tiles = tiles.filter((item) => {
          return item.tileState.tileId != null
        });
        this.delegate.didUpdateTiles()
      }.bind(this),
      videoTileWasRemoved: function(tileId) {
        console.log(`start videoTileWasRemoved ${tileId}`)
      
        this.tiles = this.tiles.filter((item) => {
          return item.tileState.tileId != null || item.tileState.tileId === tileId
        })
      }.bind(this)
    };
    console.log(`addObserver`)
    this.meetingSession.audioVideo.addObserver(observer);
    this.meetingSession.audioVideo.addContentShareObserver(observer);
  }
  subscribeMeetingEvent() {
    console.log("subscribeMeetingEvent")
    const callback = (presentAttendeeId, present, externalUserId,) => {
      if (present) {
        //入室時
        const isContentAttendee = new DefaultModality(presentAttendeeId).hasModality(DefaultModality.MODALITY_CONTENT);
        // roaster(参加者) が存在しなければ追加する。
        if (!this.roster[presentAttendeeId]) {
          this.roster[presentAttendeeId] = {
            name: `${presentAttendeeId}`,
            extendName: externalUserId.split('#').slice(-1)[0] + (isContentAttendee ? ' «Content»' : ''),
          };
        }
      } else {
        // 退出時
        delete this.roster[presentAttendeeId];
        
        return;
      }
      this.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(presentAttendeeId, (attendeeId, volume, muted, signalStrength) => {
        if (!this.roster[attendeeId]) {
          console.log(`realtimeSubscribeToVolumeIndicator nothing ${attendeeId}`)
          return;
        }
        if (volume !== null) {

          this.roster[attendeeId].volume = Math.round(volume * 100);
          console.log(`realtimeSubscribeToVolumeIndicator volume ${this.roster[attendeeId].volume} ${attendeeId}`)
          this.setSelfAudioLevel()
          if (this.roster[attendeeId].volume - 0 > 0) {
            this.isSpeaking[this.tileIdForAttendeeId(attendeeId)] = true
            console.log(`isSpeaking ${this.tileIdForAttendeeId(attendeeId)} ${attendeeId} true`)
          } else {
            this.isSpeaking[this.tileIdForAttendeeId(attendeeId)] = false
            console.log(`isSpeaking ${this.tileIdForAttendeeId(attendeeId)} ${attendeeId} false`)
          }
        }
        if (muted !== null) {
          this.roster[attendeeId].muted = muted;
          console.log(`realtimeSubscribeToVolumeIndicator muted ${this.roster[attendeeId].muted} ${attendeeId}`)
        }
        if (signalStrength !== null) {
          this.roster[attendeeId].signalStrength = Math.round(signalStrength * 100);
          console.log(`realtimeSubscribeToVolumeIndicator signalStrength ${this.roster[attendeeId].signalStrength} ${attendeeId}`)

        }
        // this.updateRoster();
      })
    };
    // 参加者の状況を監視するハンドラを登録
    this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(
      callback
    );
  }
  setSelfAudioLevel(){
    const localAttendeeId = this.localAttendee.AttendeeId
    // const localAttendeeId = this.tileIdToAttendeeId(this.localTileId())
    let level = 0

    if (localAttendeeId) {
      level = this.roster[localAttendeeId].volume
    }

    this.selfAudioLevel = level > 0 ? level + Math.round((100 - level)/2) : 0
    console.log(`selfAudioLevel ${localAttendeeId} ${level} ${this.selfAudioLevel}`)
  }
  // AttendeeIdに紐づくTileIDを取得する
  tileIdForAttendeeId(attendeeId) {
    if (attendeeId) {
      console.log(`tileIdForAttendeeId ${attendeeId}`)
      for (const tile of this.meetingSession.audioVideo.getAllVideoTiles()) {
        const state = tile.state();
        if (state.boundAttendeeId === attendeeId) {
          return state.tileId;
        }
      }
    }
    return null;
  }
  localTileId() {
    return this.meetingSession.audioVideo.hasStartedLocalVideoTile()
      ? this.meetingSession.audioVideo.getLocalVideoTile().state().tileId
      : null;
  }
}

module.exports = MeetingProvider