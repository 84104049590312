<template>
  <div class="container-fluid mt-1">
    <div class="card mb-4">
      <!-- Card header -->
      <div class="card-header">
        <div class="row">
          <div class="col-12 text-right">
            <base-button type="neutral" icon size="sm" @click="showNewMeeting">
              <span class="btn-inner--icon"
                ><i class="fas fa-plus-square"></i
              ></span>
              <span class="btn-inner--text">新規ミーティング</span>
            </base-button>
            <base-button
              @click="onStartReloadList"
              type="neutral"
              icon
              size="sm"
            >
              <span class="btn-inner--icon"
                ><i class="fa fa-sync-alt"></i
              ></span>
              <span class="btn-inner--text">リロード</span>
            </base-button>
          </div>
        </div>
      </div>
      <!-- Card body -->
      <div class="card-body">
        <el-table
          class="table-responsive align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="this.meetingList"
        >
          <el-table-column
            label="ミーティング名"
            min-width="410px"
            prop="name"
            sortable
          >
            <template v-slot="{ row }">
              <b>
                <a
                  v-if="row.title"
                  href="#!"
                  @click.prevent="onSelectMeeting(row)"
                  >{{ row.title }}
                </a>
                <a v-else href="#!" @click.prevent="onSelectMeeting(row)"
                  >タイトル無し
                </a>
              </b>
            </template>
          </el-table-column>
          <el-table-column
            label="参加中のユーザ数"
            prop="startDateTime"
            min-width="140px"
            sortable
          >
            <template v-slot="{ row }">

            </template>
          </el-table-column>

          <el-table-column min-width="180px" align="center">
            <template v-slot="{ row }">
              <div class="table-actions">
                <el-tooltip content="Delete" placement="top">
                  <a
                    href="#!"
                    @click.prevent="onDeleteMeeting(row)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    data-original-title="Delete product"
                  >
                    <i
                      class="fas fa-trash text-red"
                      style="font-size: 1.5rem"
                    ></i>
                  </a>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import Amplify, { API } from "aws-amplify";
import MeetingModel from "../../appModel/meeting/MeetingModel.js";
import UserInfo from "../../appUtils/UserInfo";
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
} from "amazon-chime-sdk-js";

export default {
  data() {
    return {
      meetingList: [],
      loader: null,
    };
  },
  mounted() {
    this.init()
    this.loadMeetingList();

  },

  methods: {
    init() {
      let navInfo = this.$store.state.navInfo
      navInfo.title = "ミーティング一覧"
      navInfo.description = ""
      this.$store.commit("setNavInfo", navInfo)
    },
    async onStartReloadList() {
      this.loadMeetingList();
    },
    async onSelectMeeting(row) {
      // const logger = new ConsoleLogger('ChimeMeetingLogs', LogLevel.INFO);
      // const deviceController = new DefaultDeviceController(logger);
      // const configuration = new MeetingSessionConfiguration(meeting, attendee);
      // const meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
      // let storeMeetingData = this.$store.state.meeting;
      // alert(JSON.stringify(row.meeting,null,"\t"))
      // storeMeetingData.selectedMeetingData = row.meeting;
      // this.$store.commit("setMeeting", storeMeetingData);
      // this.$emit("selectedMeeting");

      window.open(`./#/meetingclient?sk=${encodeURIComponent(row.sk)}`, '_blank')
      // this.$router.push({
      //   path: `/meetingclient`,
      //   query: { sk: `${row.sk}` },
      // });
    },
    async showNewMeeting() {
      this.$emit("showNewMeeting");
    },
    async loadMeetingList() {
      try {
        this.loader = this.$loading.show();
        this.meetingList = [];
        let userinfo = await UserInfo.getUserInfo();
        let group = userinfo.group;
        MeetingModel.getMeetingList(group).then((result) => {
          if (result) {
            result.forEach((item) => {
              this.meetingList.push({
                meetingId: item.meeting.meetingId,
                title: item.meeting.title,
                startDateTime: item.meeting.startDateTime,
                pk: item.pk,
                sk: item.sk,
                meeting: item,
              });
            });
          }

          //this.meetingList = result
        });
      } finally {
        this.loader.hide();
      }
    },
    async onDeleteMeeting(row) {
      try {
        if (!row.meetingId) {
          alert(`ID未指定`);
          return;
        }
        this.loader = this.$loading.show();

        const APIName = "meetingManager";
        const path = `/meeting/${row.meetingId}`;
        const myInit = {
          headers: {},
          response: true,
        };

        const result = await API.del(APIName, path, myInit);
        //alert(JSON.stringify(result))
        if (result.data) {
          /**
           * {code: 0,meeting:chimecon.meeting,attendee:chimecon.attendee,message:``}
           */
          this.loader.hide();
          if (result.data.code == 0) {
            //alert("削除成功")
            await MeetingModel.deleteMeeting(row.meeting);
            this.loadMeetingList();
          } else {
            alert(`エラー:${result.data.message}`);
          }
        }
      } catch (e) {
        this.loader.hide();
      } finally {
      }
    },
  },
};
</script>
