const { ContentShareMediaStreamBroker } = require("amazon-chime-sdk-js");
const short = require("short-uuid");
const baseModel = require("../baseModel")

/**
 * 配筋計測用のデータ取得モデルクラス
 */
class MeasureModel extends baseModel {
  /**
   * テナント内の配筋計測リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getMeasureList() {
    return await this.filterNotDeleteList(await super.getList({ beginsWith: "measurement#" }))
    // return await super.getList({ beginsWith: "measurement#" })
  }

  /**
   * テナント内の配筋計測リストから削除データ以外を取得する
   * @param {array} results テナント内の配筋計測リスト
   * @returns 削除データ以外の配筋計測リスト
   */
  static async filterNotDeleteList(results) {

    return results.filter(function (item) {
      // console.log(item)
      if (!item.rebarmeasure.deleteFlg) {
        // deleteFlgが未定義 -> 表示対象
        return item;
      }
      if (item.rebarmeasure.deleteFlg !== true) {
        // deleteFlgがfalse -> 表示対象
        return item;
      }

    })
  }

  /**
   * 配筋計測を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteMeasure(data) {
    await super.deleteData(data)
  }

  /**
   * 配筋計測管理レコードを追加する
   * @param {*} data
   */
  static async addMeasure(data) {
    delete data.createdAt;
    delete data.updatedAt;
    await super.addData(data)
  }

  /**
   * 配筋計測管理レコードを更新する
   * @param {*} data
   */
  static async updateMeasure(data) {
    await super.updateData(data)

  }

  /**
   * 配筋計測管理レコードを取得する
   * @param {*} data
   */
  static async getMeasure(data) {
    return await super.getSingleData(data);
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(id) {
    return `measurement#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  // static async getNewData() {
  //   let newRowData = await super.getNewData()

  //   const id = short.generate()
  //   newRowData.sk = this.createSk(id)

  //   newRowData.measure = {
  //     id: id,
  //     name: "",
  //     options: [],
  //     users: [],
  //   }

  //   return newRowData

  // }
}

module.exports = MeasureModel;
