const short = require("short-uuid");
const baseModel = require("../baseModel")
const appLog = require("../../appUtils/AppLog")

/**
 * カスタム帳票テンプレート用のデータ取得モデルクラス
 */
class FormTemplateModel extends baseModel {
  /**
   * テナント内のカスタム帳票テンプレートリストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getFormTemplateList() {
    return await super.getList({ beginsWith: "formTemplate#" })
  }

  /**
   * カスタム帳票テンプレートを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteFormTemplate(data) {
    await super.deleteData(data)
  }

  /**
   * カスタム帳票テンプレート管理レコードを追加する
   * @param {*} data
   */
  static async addFormTemplate(data) {
    console.log(JSON.stringify(data))
    await super.addData(data)
  }

  /**
   * カスタム帳票テンプレート管理レコードを更新する
   * @param {*} data
   */
  static async updateFormTemplate(data) {
    await super.updateData(data)
  }

  /**
   * カスタム帳票テンプレート管理レコードを取得する
   * @param {*} data
   */
  static async getFormTemplate(data) {

    return await super.getSingleData(data);

  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(id) {
    return `formTemplate#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData() {
    let newRowData = await super.getNewData()
    
    const id = short.generate()
    newRowData.sk = this.createSk(id)
    
    newRowData.formTemplate = {
      id: id,
      name: "",
      filePath: "",
      deleteFlg: false,
      order: 0,
      createDate: "",
      updateDate: "",
      createUser: "",
      updateUser: "",

    }

    return newRowData
    
  }
}

module.exports = FormTemplateModel;
