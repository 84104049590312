<template>
    <div class="card">
    <!-- Card image -->
    <img
        class="card-img-top"
        :src="fileUrl"
        alt="Image placeholder"
    />
    <!-- Card body -->
    <div class="card-body">
        <h3 class="card-title mb-3">{{fileInfo.title}}</h3>
        <el-table
      class="table-responsive align-items-center table-flush"
      header-row-class-name="thead-light"
      :data="fileAttributes"
    >
      <el-table-column label="項目" min-width="50px" prop="name" >
        <template v-slot="{ row }">
          <span>{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="値"
        prop="value"
        min-width="50px"
      >
      <template v-slot="{ row }">
          <span>{{row.value}}</span>
      </template>
      
      </el-table-column>

    </el-table>

    </div>
    </div>
</template>
<script>
export default {
  name: "inline-actions-table",
  props: {
    tenantId: String,
    projectId: String,
    nodeId: String,
    fileInfo: Object,
    fileAttributes: Array,
    fileUrl: {
      type: String,
      default: "img/theme/img-1-1000x600.jpg"
    }
  },
  computed: {
    
  },
  data() {
    return {
      
    }
  }
}
</script>
