<template>
  <div>
    <!-- Page content -->
    <div class="container mt-5" v-if="systemJsonLoadedFlg">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card bg-white border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="row align-items-center">
                <div class="col-sm-12">
                  <!-- <h2 class="mb-0">{{$t("ログイン")}}</h2> -->
                  <!-- <img src="/siteid_01.png" class="login-cplogo" /> -->
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-sm-12 d-flex justify-content-center">
                  <img v-if="isMcz" src="@/assets/img/equtcloud-390.svg" width="390" height="130">
                  
                  <h2 v-if="isRebar" class="mb-0 text-center login-title pb-4">{{appTitle}}</h2>
                </div>
              </div>
              <div class="row mt-3 justify-content-center">
                <div class=" col-md-8">
                  <base-input
                    v-if="false"
                    alternative
                    label="ユーザID"
                    >
                    <el-input
                      addon-left-icon="fas fa-user"
                      placeholder="ユーザID"
                      v-model="userId"
                      v-on:keydown.enter="signin"
                    ></el-input>
                  </base-input>
                  <label class="form-control-label">ユーザーID</label>
                  <el-input
                    v-if="false"
                    addon-left-icon="fas fa-user"
                    placeholder="ユーザID"
                    v-model="userId"
                    v-on:keydown.enter="signin"
                    size="large"
                  ></el-input>

                  <input
                    type="text"
                    
                    v-model="userId"
                    class="form-control rebar-input mb-4"
                  />

                  <base-input v-if="false" alternative label="パスワード">
                    <el-input
                      type="password"
                      placeholder="パスワード"
                      v-model="password"
                      autocomplete="off"
                      v-on:keydown.enter="signin"
                    ></el-input>
                  </base-input>
                  <label class="form-control-label">パスワード</label>
                  <input
                    type="password"
                    id="userId"
                    v-model="password"
                    autocomplete="off"
                    class="form-control rebar-input"
                  />
                  <div class="text-center mt-5">
                    <!-- <base-button icon type="primary" @click="signin" class="login-button">
                      <span class="btn-inner--icon"><i class="fa fa-sign-in-alt"></i></span>
                      <span class="btn-inner--text">ログイン</span>
                    </base-button> -->

                    <button type="button" class="btn btn-primary w-100" @click="signin">ログイン</button>

                  </div>

                  <div class="text-left mt-5">
                    <base-button type="link" @click="clickForgotPassword" v-if="enableForgotPassword">
                      パスワードを忘れた場合
                    </base-button>
                  </div>
                  <div class="text-center mt-5 text-default" style="font-size:0.8em;">
                    ver {{appver}}
                  </div>
                </div>
                <div class="col-md-12" v-if="notices.length">
                  <div class="card">
                    <!-- Card header -->
                    <div class="card-header">
                      <!-- Title -->
                      <h5 class="h3 mb-0">お知らせ</h5>
                    </div>
                    <!-- Card body -->
                    <div class="card-body">
                      <div
                        v-if="notices.length"
                        class="timeline timeline-one-side"
                        data-timeline-content="axis"
                        data-timeline-axis-style="dashed">

                        <div v-for="notice in notices" :key="notice.title" class="timeline-block">
                          <span class="timeline-step" :class="`badge-${notice.type}`">
                            <i :class="notice.icon"></i>
                          </span>
                          <div class="timeline-content">
                            <div>
                              <small>{{ notice.createDate }}</small>
                            </div>
                            <div class="d-flex justify-content-between pt-1">
                              <div>
                                <span class="text-sm font-weight-bold">{{
                                  notice.title
                                }}</span>
                              </div>

                            </div>
                            <h6 class="text-sm mt-1 mb-0">
                              <small style="white-space:pre-wrap; word-wrap:break-word;">{{ notice.description }}</small>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <small>お知らせはありません</small>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.login-button{
  width:100%;
  border-radius: 50px 50px 50px 50px !important;
}
.login-cplogo{
  object-fit: contain;
  width: 300px;
  height:60px;
}

.card {
  box-shadow:none !important;

}
h2.login-title:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  content: '';
  border-radius: 3px;
  /* background-color: #FADCE9; */
  /* background-image: -webkit-gradient(linear, right top, left top, from(#FADCE9), to(#E4007F)); */
  background-image: -webkit-linear-gradient(right, #ffffff 0%, #E4007F 500%, #ffffff 100%);
  background-image: linear-gradient(to left, #ffffff 0%, #E4007F 50%, #ffffff 100%);
}
.rebar-input:focus {
  border-block-color: #409EFF !important;
}
</style>
<script>
import { Storage } from "aws-amplify";
import { Form } from "vee-validate";
import { Auth } from 'aws-amplify'
import path from 'path'
import UserInfo from "../appUtils/UserInfo"
import TenantModel from "../appModel/Tenant/TenantModel"
import UserModel from "../appModel/users/UserModel"

import appLog from "@/appUtils/AppLog"
import DateUtil from "@/appUtils/DateUtil"
import { useToast } from "vue-toastification";

import pkg from "../../package"

export default {
  components: {
    Form,
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    // appLog.infoLog("Signin.vue", '', `start signIn.`)
    // appLog.infoLog({view: "Signin.vue", message: `start signIn.`})
    this.init().then(() => {
    })
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
    appLog.infoLog("Signin.vue", '', `end signIn.`)
    // appLog.infoLog({view: "Signin.vue", message: `end signIn.`})
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  data() {
    return {
      userId: "",
      password: "",
      rememberMe: false,
      appver: "",
      appTitle: "",
      enableForgotPassword: true,
      notices: [
      ],
      systemJsonLoadedFlg: false,
      showMaintenance: false,
      isMcz: false,
      isRebar: false
    };
  },
  methods: {
    async init() {

      await this.loadNotice()
      await this.loadMaintenace()
      if (!this.showMaintenance) {
        this.systemJsonLoadedFlg = true
      }
      
      //this.appver = process.env.VUE_APP_VER
      this.appver = pkg.version
      this.appTitle = process.env.VUE_APP_TITLE
      if (process.env.VUE_APP_ENABLE_FORGET_PASS == '0') {
        this.enableForgotPassword = false
      } else {
        this.enableForgotPassword = true
      }

      this.isMcz = process.env.VUE_APP_APPTYPE == "MCZ"
      this.isRebar = process.env.VUE_APP_APPTYPE == "REBAR"

    },
    async loadNotice() {
      try {
        //ファイル存在チェック
        //const dir = path.dirname(process.env.VUE_APP_NOTICE_FILE)
        const list = await Storage.list('')
        const findResult = list.find((item) => {
          return item.key == process.env.VUE_APP_NOTICE_FILE
        })
        if (findResult != undefined) {
          
          const result = await Storage.get(`${process.env.VUE_APP_NOTICE_FILE}`, { download: true })
          result.Body.text().then(string => {
            try {
              
              let noticeData = JSON.parse(string)
              
              this.notices = noticeData.list
            } catch (e) {
              
              appLog.debugLog(this.$route.name, this.userId, `noice is nothing`)
              
            }
          })
        } else {          
          appLog.debugLog(this.$route.name, this.userId, `noice file is nothing`)
        }
      } catch (e) {
        appLog.debugLog(this.$route.name, this.userId, `noice is nothing`)

      }
       
    },

    async loadMaintenace() {

      try {
        //ファイル存在チェック
        //const dir = path.dirname(process.env.VUE_APP_MAINTENANCE_FILE)
        const list = await Storage.list('')
        const findResult = list.find((item) => {
          return item.key == process.env.VUE_APP_MAINTENANCE_FILE
        })

        if (findResult) {
          this.showMaintenance = true
          const result = await Storage.get(`${process.env.VUE_APP_MAINTENANCE_FILE}`, { download: true })
          result.Body.text().then(string => {
            try {
              let maintenance = JSON.parse(string)
              this.$store.commit("setMaintenance", maintenance);

              // test
              // maintenance.end = '2021-08-24 18:30:00'

              // 範囲内チェック
              const isMaintenace = DateUtil.isWithinPeriod(maintenance.start, maintenance.end);
              if (isMaintenace) {
                
                // メンテナンスページへ遷移
                this.$router.push({
                  name: 'Maintenance'
                })
              }

            } catch (e) {
              appLog.debugLog(this.$route.name, this.userId, `meintenance is nothing`)
            }
          })
        } else {
          appLog.debugLog(this.$route.name, this.userId, `meintenance file is nothing`)
        }
      } catch (e) {
        appLog.debugLog(this.$route.name, this.userId, `meintenance is nothing`)
      }
    },
    /**
     * ログイン
     */
    async signin() {

      let loader = this.showLoader();

      try {

        if (!this.userId || !this.password) {
          this.showBottomToast(`ユーザIDまたはパスワードを入力してください。`, 'error')
          return
        }

        const ret = await Auth.signIn(this.userId, this.password)
        appLog.infoLog("Signin.vue", this.userId, `signIn success userId:${this.userId}`)
        // appLog.infoLog({view: "Signin.vue", message: `signIn success userId:${this.userId}`})

        const user = await UserInfo.getUserInfo()

        if (user.group == `ncsmanager`) {
          // NCS管理者はダッシュボードへ
          this.$router.push({
            name: 'Dashboard'
          })
          return
        } else {
          // 削除ユーザーかどうかの確認
          const pk = user.group
          const sk = UserModel.createSk(user.sub)
          const userData = await UserModel.getUser({pk: pk, sk: sk})
          if (userData.user.deleted != null && userData.user.deleted == true) {
            appLog.warnLog("Signin.vue", this.userId, `deletedUser userId:${this.userId}`)
            // appLog.warnLog({view: "Signin.vue", message: `deletedUser userId:${this.userId}`})
            this.showBottomToast(`このユーザーは削除されています。`, 'error')
            return;
          }

          const tenantData = await TenantModel.getTenant(user.group)
          if (tenantData.tenant.availableOptions.includes("REBAR") &&
            !tenantData.tenant.availableOptions.includes("MCZ")
          ) {
            // 配筋検査ユーザは配筋用結果一覧へ
            appLog.infoLog("Signin.vue", this.userId, `start rebar.`)
            // appLog.infoLog({view: "Signin.vue", message: `start rebar.`})
            
            // ログイン直後は検索条件削除
            let key = user.userName;
            localStorage.removeItem(key);

            this.$router.push({
              name: 'List'
            })
            return
          } else {
            // MCZユーザはダッシュボードへ
            appLog.infoLog("Signin.vue", this.userId, `start mcz.`)
            // appLog.infoLog({view: "Signin.vue", message: `start mcz.`})
            this.$router.push({
              name: 'Dashboard'
            })
            return
          }
        }

      } catch (e) {
        appLog.errLog("Signin.vue", this.userId, `signIn fail useId:${this.userId} error ${e.code} ${JSON.stringify(e)}`)
        // appLog.errLog({view: "Signin.vue", message: `signIn fail useId:${this.userId} error ${e.code} ${JSON.stringify(e)}`})
        switch (e.code) {
        case "UserNotFoundException":
          this.showBottomToast(`ログインできません。ユーザ名、パスワード、ご契約状況をご確認ください`, 'error')
          // alert(`認証エラー`)

          break
        case "NotAuthorizedException":
          this.showBottomToast(`ログインできません。ユーザ名、パスワード、ご契約状況をご確認ください`, 'error')
          // alert(`認証エラー`)
          break
        case "UserLambdaValidationException":
          this.showFailedMessage(e.message);
          break
        case "InvalidParameterException":
          this.showBottomToast(`ユーザ名またはパスワードが未入力`, 'warning')
          // alert(`ユーザ名またはパスワードが未入力`)
          break
        default:
          throw e
        }
      } finally {
        this.hideLoader(loader);
      }
    },
    clickForgotPassword() {

      // 認証コードを送信へ
      this.$router.push({
        name: 'ForgotPassword'
      })
      return;
    },
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },

    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
    showFailedMessage(exceptionMessage) {

      if (exceptionMessage.includes(`PreAuthentication`)) {
        this.showBottomToast(`ログインできません。ユーザ名、パスワード、ご契約状況をご確認ください`, `error`)
      }
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
  },
};
</script>
