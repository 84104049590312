import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { userInfo } from "@/appUtils/UserInfo"

import ArgonDashboard from "./plugins/argon-dashboard";
import ElementPlus from "element-plus";
// import "element-plus/lib/theme-chalk/index.css";
import "element-plus/dist/index.css"
import ja from 'element-plus/lib/locale/lang/ja'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// import * as AmplifyModules from 'aws-amplify'
// import { AmplifyPlugin } from 'aws-amplify-vue'
// import { components } from 'aws-amplify-vue'

import "splitpanes/dist/splitpanes.css";
//import {store} from "./store";
import { createStore } from "vuex";

import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";


// 共通スタイル
import "@/appStyle/common.scss"
// Amplify関係のセットアップ
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports.js";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";
Amplify.configure(awsconfig);

// 多言語化対応
// import { createI18n } from "vue-i18n";
// const i18n = createI18n({
//   locale: "ja",
//   messages: {
//     // 翻訳用のjson
//     ja: require("./lang/ja.json"),
//     en: require("./lang/en.json"),
//   },
// });

const store = createStore({
  state() {
    return {
      navInfo: {
        title: "",
        desctiption: ""
      },
      tenant: {
        id: "",
      },
      todo: {
        drawer: false,
        edit: false,
        data: null,
        size: "300px",
      },
      notice: {
        drawer: false,
        edit: false,
        data: null,
        size: "300px",
        linkedId: ""
      },
      project: {},
      editUserParams: {
        editUserId: null,
        createFlg: false,
        fromNavbar: false,
      },
      meeting: {
        selectedMeetingData: {},
        meeting: {},
        attendee: {},
        provider: null,
        startFlg: false,
        meetingSession: {},
        meetingList: [],
        audioInputList: [],
        videoInputList: [],
        wipe: false,
        fullScreen: false
      },
      user: "",
      loginUser: {},
      selectedNode: null,
      measureDetailData: null,
      maintenance: {
        message: "",
        start: "",
        end: "",
      },
      
      tenantStorage: null
    };
  },

  mutations: {
    
    setNavInfo(state, navInfo) {
      state.navInfo = navInfo
    },
    setTenant(state, tenant) {
      state.tenant = tenant;
    },
    setUser(state, user) {
      state.user = user;
    },
    setTodo(state, todo) {
      // console.log(todo);
      state.todo = todo;
    },
    setNotice(state, notice) {
      state.notice = notice;
    },
    setMeeting(state, meeting) {
      state.meeting = meeting;
    },
    setUserinfo(state, loginUser) {
      state.loginUser = loginUser;
    },
    setProject(state, project) {
      state.project = project;
    },
    setSelectedNode(state, node) {
      state.selectedNode = node;
    },
    setMeasureData(state, measureDetailData) {
      state.measureDetailData = measureDetailData
    },
    setEditUserParams(state, params) {
      state.editUserParams = params
    },
    setMaintenance(state, maintenance) {
      state.maintenance = maintenance;
    },
    setTenantStorage(state, tenantStorage) {
      state.tenantStorage = tenantStorage
    }

  },
  actions: {},
  getters: {
    getMeetingStartFlg: state => state.meeting.startFlg,
  },
  modules: {},
});

applyPolyfills().then(() => {
  defineCustomElements(window);
});
const options = { containerClassName: "ct-notification" };

const appInstance = createApp(App);
appInstance.config.isCustomElement = tag => tag.startsWith('amplify-');
appInstance.use(router);
appInstance.use(userInfo);
appInstance.use(ArgonDashboard);
appInstance.use(ElementPlus, {locale: ja})
//appInstance.use(ElementPlus);
// appInstance.use(i18n);
appInstance.use(store);
appInstance.use(Toast, options);

appInstance.use(
  VueLoading,
  {
    container: null,
    canCancel: false,
    color: "#E4007F",
    width: 64,
    height: 64,
    backgroundColor: "#ffffff",
    opacity: 0.5,
    isFullPage: true,
    zIndex: 9999,
  },
  {}
);

appInstance.mount("#app");
