<template>
  <div>
    <base-header class="pb-1" type="">
        <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
                <h6 class="h2 d-inline-block mb-0">ダッシュボード</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                    <route-bread-crumb type=""></route-bread-crumb>
                </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">

            </div>
        </div>
        <!-- Card stats -->
        <div class="row">

            <div class="col-xl-6 col-md-6">
                <stats-card type="gradient-primary" icon="fa fa-video">

                    <div class="row">
                        <div class="col-12">
                            <h5 class="card-title text-uppercase text-muted mb-0">参加予定のミーティング</h5>
                        </div>
                        <div class="col-12 mt-1">
                            <span class="text-sm ml-2">ミーティングはありません</span>
                        </div>
                        <div class="col-12 mt-2">
                            <base-button type="neutral" size="sm" icon @click="showNewMeeting">
                                <span class="btn-inner--icon"
                                    ><i class="fas fa-plus-square"></i
                                ></span>
                                <span class="btn-inner-text">新規作成</span>
                            </base-button>
                        </div>
                    </div>

                <!-- <make-user /> -->
                </stats-card>
            </div>
            <div class="col-xl-6 col-md-6">
                <stats-card type="gradient-primary" icon="fa fa-clipboard-list">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="card-title text-uppercase text-muted mb-0">ToDoリスト</h5>
                        </div>
                        <div class="col-12 mt-1">
                            <span class="text-sm ml-2">担当のToDoはありません</span>
                        </div>
                        <div class="col-12 mt-2">
                            <base-button type="neutral" size="sm" icon >
                                <span class="btn-inner--icon"><i class="fas fa-plus-square"></i></span>
                                <span class="btn-inner-text">新規作成</span>
                            </base-button>
                        </div>
                    </div>
                </stats-card>
            </div>
        </div>
        <div class="row" v-if="false">
          <div class="col-12">
            testgroup01テナント画像
            <img v-bind:src="testgroup01Image" class="m-1" />
          </div>
          <div class="col-12">
            devTenant1Imageテナント画像
            <img v-bind:src="devTenant1Image" class="m-1" />
          </div>
          <div class="col-12">
            testgroup06テナント画像
            <img v-bind:src="testgroup06Image" class="m-1" />
          </div>
          <div class="col-12">
            パブリック画像(Amplify SDK)
            <img v-bind:src="publicImage" style="max-width:200px;" class="m-1" />
          </div>
          <!-- <div class="col-12">
            パブリック画像(STS TokenでAWS SDK)
            <img v-bind:src="publicImage_awssdk" style="max-width:200px;" class="m-1" />
          </div> -->
        </div>
    </base-header>
    <modal v-model:show="meetingDetailShow">
        <create-meeting ref="createMeeting"></create-meeting>
    </modal>
    <div class="container-fluid">
        <!--Charts-->
        <div class="row">
            <div class="col-xl-5">
                <!-- <meeting-calendar></meeting-calendar> -->
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// Charts

// User
import CreateMeeting from "@/appViews/Meeting/CreateMeeting"

import Modal from "@/components/Modal";
import DateUtil from "@/appUtils/DateUtil"
import UserInfo from "@/appUtils/UserInfo"
import aws from 'aws-sdk';
import { Auth } from '@aws-amplify/auth';
import awsmobile from "@/aws-exports";
import { Storage } from "aws-amplify";
import TenantStorage from "@/appUtils/TenantStorage";

export default {
  components: {
    CreateMeeting,
    
    Modal,
  },
  
  data() {
    return {
      group: "",
      meetingDetailShow: false,
      testgroup01Image: "",
      devTenant1Image: "",
      testgroup06Image: "",
      publicImage: "",
      tenantStorage: null,
      publicImage_awssdk: null
    };
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    // タイトルをセット
    let navInfo = this.$store.state.navInfo
    navInfo.title = "ダッシュボード"
    navInfo.description = ""
    this.$store.commit("setNavInfo", navInfo)

    this.init().then(() => {
      console.log("init called.")
    })
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  methods: {
    async showNewMeeting() {
      this.meetingDetailShow = true
      this.$refs.createMeeting.initForm()
    },
    async init() {
      
      const userInfo = await UserInfo.getUserInfo()
      
      this.group = userInfo.group
      this.tenantStorage = new TenantStorage(this.group)
      console.log("group:" + this.group)

      //await this.getTenantObject()
      try {
        this.testgroup01Image = (await this.tenantStorage.get("tenant/testgroup01/testgroup01.png")).toBase64()

        // this.testgroup01Image = await this.getTenantObjectAwsSDK("testgroup01/testgroup01.png")
        // this.testgroup01Image = await this.getTenantObject("testgroup01.png")
      } catch (e) {
        console.log(`testgroup01Image error ${JSON.stringify(e)}`)
      }
      
      try {
        this.devTenant1Image = (await this.tenantStorage.get("tenant/devtenant1/devtenant1.png")).toBase64()

        // this.testgroup01Image = await this.getTenantObjectAwsSDK("testgroup01/testgroup01.png")
        // this.testgroup01Image = await this.getTenantObject("testgroup01.png")
      } catch (e) {
        console.log(`devTenant1Image error ${JSON.stringify(e)}`)
      }

      try {
        this.testgroup06Image = (await this.tenantStorage.get("tenant/testgroup06/testgroup06.png")).toBase64()
        // this.testgroup06Image = await this.getTenantObjectAwsSDK("testgroup06/testgroup06.png")
      } catch (e) {
        console.log(`testgroup06Image erro ${JSON.stringify(e)}`)
      }
      
      // try {
      //   this.publicImage_awssdk = (await this.tenantStorage.get("public/public.png")).toBase64()
        
      // } catch (e) {
      //   console.log(`testgroup06Image erro ${JSON.stringify(e)}`)
      // }

      await this.getPublic()

    },
    /**
     * テナントバケットアクセステスト
     */
    async getTenantCredential() {
      console.log('tenantDataTest start')
      const roleArn = "arn:aws:iam::222792311388:role/amplify-mapletfscloud-dev-163716-tenantStsRole"
      const sessionName = `tenantStsRole-${DateUtil.getUnixTimeStampOfSecond()}`
      
      const currentCredentials = await Auth.currentCredentials()

      let stsParams = {credentials: currentCredentials };
      let sts = new aws.STS(stsParams)
      
      let params = {
        RoleArn: roleArn, /* required */
        RoleSessionName: sessionName, /* required */
        DurationSeconds: 3600,
        Tags: [
          {
            Key: 'TenantID', /* required */
            Value: `${this.group}` /* required */
          },
        ],
      };
      // let sts = new aws.STS({apiVersion: '2011-06-15'});
      const result = await sts.assumeRole(params).promise()
      // let now = new Date()
      //alert(DateUtil.isAfter(now, result.Credentials.Expiration))

      //let date = DateUtil.utcDateStringToJST(result.Credentials.Expiration)

      console.log(`getTenantCredential: ${JSON.stringify(result)}`)

      return result
    },

    async getTenantObjectAwsSDK(key) {
      const tenantCredential = await this.getTenantCredential()

      aws.config.update({
        credentials: new aws.Credentials({
          accessKeyId: tenantCredential.Credentials.AccessKeyId,
          secretAccessKey: tenantCredential.Credentials.SecretAccessKey,
          sessionToken: tenantCredential.Credentials.SessionToken,
        }),
      })
      
      const s3 = new aws.S3({
        credentials: new aws.Credentials({
          accessKeyId: tenantCredential.Credentials.AccessKeyId,
          secretAccessKey: tenantCredential.Credentials.SecretAccessKey,
          sessionToken: tenantCredential.Credentials.SessionToken,
        }),
        region: 'ap-northeast-1',
      });

      let params = {Bucket: awsmobile.aws_user_files_s3_bucket, Key: `${key}`};
      let data = await s3.getObject(params).promise()

      let base64 = 'data:image/jpg;base64,' + data.Body.toString('base64')
      return base64
     
    },
    async getTenantObject(key) {
      
      const prefix = `${this.group}/`
      const tenantCredential = await this.getTenantCredential()
      
      aws.config.update({
        credentials: new aws.Credentials({
          accessKeyId: tenantCredential.Credentials.AccessKeyId,
          secretAccessKey: tenantCredential.Credentials.SecretAccessKey,
          sessionToken: tenantCredential.Credentials.SessionToken,
        }),
      })
      const result = await Storage.get(key, {level: "public", customPrefix: {public: prefix}, download: true})
      let base64 = 'data:image/jpg;base64,' + result.Body.toString('base64')
      return base64
    },
    async getPublic() {
      const key = `public.png`
      const url = await this.get(`${key}`);
      this.publicImage = url
    },
    /**
     * S3からURLを取得します。
     *
     * @param {String} key S3キー
     * @param {Object} config 設定情報
     *
     * @returns URL
     */
    async get(key, config) {
      try {
        let imageUrl = await Storage.get(key, config)
        return imageUrl
      } catch (e) {
        this.debugText = `エラー:${JSON.stringify(e)}`
        throw e
      }
    },
  },
};
</script>

<style></style>
