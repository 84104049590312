<template>
  <div class="container-fluid mt-1" v-if="userData">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-lg-2">
                  <button
                    type="button"
                    class="btn btn-secondary w-100"
                    @click="cancel"
                    v-if="!fromNavbar"
                  >
                    キャンセル
                  </button>
                </div>
                <div class="col-lg-3"></div>
                <div class="col-lg-5" v-if="!isEdit || allowChangePassword"></div>
                <div class="col-lg-4" v-if="isEdit && !allowChangePassword"></div>
                <div class="col-lg-2" v-if="!isEdit">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    @click="makeUser"
                  >
                    ユーザ作成
                  </button>
                </div>
                <div class="col-lg" v-if="!fromNavbar && isEdit && !allowChangePassword"></div>
                <div class="col-lg-2" v-if="isEdit">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    @click="updateUser"
                  >
                    ユーザ更新
                  </button>
                </div>
                <!-- <div class="col-lg-3" v-if="isEdit && !allowChangePassword"></div> -->
                <div class="col-lg text-center" v-if="!fromNavbar && isEdit && !allowChangePassword">
                  <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                      <i class="fas fa-ellipsis-v mt-3"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="deleteUser" icon="el-icon-delete">ユーザーを削除</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                  <!--
                  <button
                    type="button"
                    class="btn btn-danger w-100"
                    @click="clickDeleteUser"
                    v-if="!fromNavbar && isEdit && !allowChangePassword"
                  >
                    ユーザーを削除
                  </button>
                  -->
                </div>
                <div class="col-12">
                  <el-divider></el-divider>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <base-input type="text" label="メールアドレス">
                    <template></template>
                    <el-input
                      placeholder="mail@example.com"
                      v-model="userData.user.email"
                      :disabled="isEdit"
                    ></el-input>
                  </base-input>
                </div>
                <div class="col-lg-12">
                  <base-input type="text" label="ユーザ名" >
                    <template></template>

                    <el-input
                      placeholder="ユーザ名"
                      v-model="userData.user.name"

                    ></el-input>

                  </base-input>
                </div>
                <div class="col-lg-12">

                  <base-input type="text" label="権限" >
                    <el-select
                      placeholder=""
                      v-bind:disabled="isNormal || allowChangePassword"
                      v-model="userData.user.usertype" >
                      <el-option v-for="item in userTypeist"
                                class="select-primary"
                                :value="item.value"
                                :label="item.name"
                                :key="item.name">
                      </el-option>
                    </el-select>

                  </base-input>
                </div>
                <div class="col-lg-12" v-if="isEdit && allowUserDisable">
                  <el-divider></el-divider>
                  <base-checkbox class="mb-3 text-primary" v-model="isDisable">
                      このユーザを無効にする
                  </base-checkbox>
                  <el-divider></el-divider>
                </div>
                <div class="col-lg-12" v-if="isEdit && allowChangePassword">

                  <base-checkbox class="mb-3 text-primary" v-model="changePassword">
                      パスワードを変更する
                  </base-checkbox>

                  <div class="pl-lg-4">
                    <div class="col-lg-12">
                      <base-input type="text" label="現在のパスワード">
                        <template></template>
                        <el-input
                          type="password"
                          placeholder="現在のパスワード"
                          v-model="currentPassword"
                          :disabled="!changePassword"
                        ></el-input>
                      </base-input>
                    </div>

                    <div class="col-lg-12">
                      <base-input type="text" label="パスワード">
                        <template></template>
                        <el-input
                          type="password"
                          placeholder="パスワード"
                          v-model="password"
                          :disabled="!changePassword"
                        ></el-input>
                      </base-input>
                    </div>

                    <div class="col-lg-12">
                      <base-input type="text" label="パスワードの確認">
                        <template></template>
                        <el-input
                          type="password"
                          placeholder="パスワードの確認"
                          v-model="confirmPassword"
                          :disabled="!changePassword"
                        ></el-input>
                      </base-input>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12" v-if="!isEdit">
                  <base-input type="text" label="パスワード">
                    <template></template>
                    <el-input
                      type="password"
                      placeholder="パスワード"
                      v-model="password"
                    ></el-input>
                  </base-input>

                </div>

                <div class="col-lg-12" v-if="!isEdit">
                  <base-input type="text" label="パスワードの確認">
                    <template></template>
                    <el-input
                      type="password"
                      placeholder="パスワードの確認"
                      v-model="confirmPassword"
                    ></el-input>
                  </base-input>
                </div>
              </div>
<!--
              <div class="row">
                <div class="col-12">
                  <el-divider></el-divider>
                </div>
                <div class="col-lg-4">
                  <button
                    type="button"
                    class="btn btn-secondary w-100"
                    @click="cancel"
                    v-if="!fromNavbar"
                  >
                    キャンセル
                  </button>

                </div>
                <div class="col-lg-4"></div>
                <div class="col-lg-4" v-if="!isEdit">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    @click="makeUser"
                  >
                    ユーザ作成
                  </button>
                </div>
                <div class="col-lg-4" v-if="isEdit">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    @click="updateUser"
                  >
                    ユーザ更新
                  </button>
                </div>
              </div>
              <div class="row" v-if="isEdit">
                <div class="col-12">
                  <el-divider></el-divider>
                </div>
                <div class="col-lg-4">
                  <button
                    type="button"
                    class="btn btn-danger w-100"
                    @click="clickDeleteUser"
                    v-if="!fromNavbar"
                  >
                    ユーザーを削除
                  </button>
                </div>
              </div>
              -->
            </div>
          </el-form>
        </card>
      </div>
      <modal v-model:show="modal.isShow">
        <template v-slot:header>
            <h5 class="modal-title" id="modalLabel">{{modal.title}}</h5>
        </template>
        <div>
          {{modal.message}}
        </div>
        <template v-slot:footer>
          <base-button v-model:type="modal.cancelType" @click="modal.cancelCallBack">{{modal.cancelLabel}}</base-button>
          <base-button v-model:type="modal.okType" @click="modal.okCallBack">{{modal.okLabel}}</base-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>

import { defineComponent } from "vue";
import API from "@aws-amplify/api";
import { Auth } from 'aws-amplify'
import UserInfo from "../../appUtils/UserInfo.js";
import UserModel from "../../appModel/users/UserModel"
import { useToast } from "vue-toastification";
import Modal from "@/components/Modal";

export default defineComponent({
  name: "MakeUser",
  props: {
    msg: String,

    userId: String
  },
  /**
   * コンポーネント
   */
  components: {
    Modal,
  },
  data() {
    return {
      username: "",
      email: "",
      group: "",
      currentPassword: "",
      password: "",
      firstName: "",
      lastName: "",
      confirmPassword: "",
      editUserId: "",
      userData: null,
      changePassword: false,
      isDisable: false,
      isBackUserList: false,
      isEdit: false,
      fromNavbar: false,
      allowChangePassword: false,
      allowUserDisable: false,
      ruleForm: {
        name: '',
      },
      rules: {
        name: [
          { required: true, message: 'Please input Activity name', trigger: 'blur' },
          { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ]
      },
      userTypeist: [{value: "0", name: "一般"}, {value: "1", name: "管理者"}],
      loader: null,
      modal: {
        isShow: false,
        title: "",
        message: "",
        okLabel: "OK",
        okType: "secondary",
        okCallBack: function() {
          alert(`push Ok`)
          this.modal.isShow = false
        },
        cancelLabel: "キャンセル",
        cancelType: "primary",
        cancelCallBack: function() {
          alert(`push cancel`)
          this.modal.isShow = false
        },
      },
      isNormal: false,
    };
  },
  mounted() {
    this.init()

  },
  methods: {

    init() {

      // 編集するユーザID
      const params = this.$store.state.editUserParams
      if (params.editUserId == null && params.createFlg == false){
        // 対象のユーザIDも新規作成フラグもない場合は例外をスローする
        console.log(`必須パラメータ不足`)
        this.$router.push({
          name: "Dashboard",
        });
        return
      }

      this.editUserId = params.editUserId
      this.isEdit = !params.createFlg
      this.fromNavbar = params.fromNavbar

      console.log(`MakeUser init ${this.editUserId}`)

      UserInfo.getUserInfo().then(function(loginInfo) {
        console.log(loginInfo)

        this.group = loginInfo.group
        this.allowChangePassword = (params.editUserId == loginInfo.sub)
        // this.allowChangePassword = (params.editUserId == loginInfo.userName)

        // ログインユーザ―が一般の場合、権限は変更不可
        const pk = this.group
        const sk = UserModel.createSk(loginInfo.sub)
        UserModel.getUser({pk: pk, sk: sk}).then(function(loginUser) {
          this.isNormal = !Number(loginUser.user.usertype);
        }.bind(this))
      }.bind(this))

      if (this.isEdit) {
        //更新
        try {
          this.getUser()

        } catch (e) {
          console.log(`更新データ取得エラー`)
          throw e
        }

      } else {
        //新規

        try {
          this.newUser()
        } catch (e) {
          console.log(`更新データ取得エラー`)
          throw e
        }
      }

      // タイトルをセット
      let navInfo = this.$store.state.navInfo
      navInfo.title = this.isEdit ? "ユーザー情報の編集" : "新規ユーザーを追加"
      navInfo.description = ""
      this.$store.commit("setNavInfo", navInfo)

      // 確認モーダル
      this.modal.isShow = false;
    },
    /**
     * 更新対象のユーザ情報を取得
     */
    async getUser() {
      this.userData = await UserModel.getUser({sk: UserModel.createSk(this.editUserId)})
      this.isDisable = this.userData.user.status === "0"
    },
    /**
     * 新規ユーザ情報を取得
     */
    async newUser() {
      const loginInfo = await UserInfo.getUserInfo()
      let newUserRow = await UserModel.getNewData()

      newUserRow.pk = loginInfo.group

      this.userData = newUserRow
    },
    /**
     * ユーザ情報を更新
     */
    async updateUser() {
      try {
        this.loader = this.$loading.show();
        if (
          !this.userData.user.name ||
          !this.group
        ) {
          //未入力はエラー
          this.showBottomToast(`未入力の項目があります`, `error`)
          return;
        }
        if (this.changePassword) {
          if (!this.currentPassword) {
            this.showBottomToast(`現在のパスワードを入力してください`, `error`)
            return;
          }
          if (this.password != this.confirmPassword) {
            //未入力はエラー
            this.showBottomToast(`パスワードが一致しません`, `error`)
            return;
          }
        }
        this.userData.user.status = this.isDisable ? "0" : "1"
        await UserModel.updateUser(this.userData);
        if (!this.fromNavbar) {
          this.$router.push({
            name: "UserList",
          });
        } else {
          this.showBottomToast(`更新しました`, `info`)
        }

        // パスワード変更処理
        const user = await Auth.currentAuthenticatedUser() // ログイン中のユーザー情報
        await Auth.changePassword(
          user,
          this.currentPassword,
          this.password
        )

      } catch (e) {
        console.log(JSON.stringify(e))
        this.showBottomToast(`更新時にエラーが発生しました`, `error`)
        throw e
      } finally {
        this.loader.hide()
        this.loader = null
      }
      this.$router.push({
        name: "UserList",
      });
    },
    /**
     * キャンセルボタン押下
     */
    cancel(){
      this.$router.push({
        name: "UserList",
      });
    },
    /**
     * 新規ユーザ追加
     */
    async makeUser() {
      try {
        this.loader = this.$loading.show();

        if (
          !this.userData.user.email ||
          !this.userData.user.name ||
          !this.password ||
          !this.confirmPassword ||
          !this.group
        ) {
          //未入力はエラー
          this.showBottomToast(`未入力の項目があります`, `error`)
          return;
        }

        if (this.password != this.confirmPassword) {
          //未入力はエラー
          this.showBottomToast(`パスワードが一致しません`, `error`)
          return;
        }

        //const apiName = 'apimapletfscloudappuser';
        const apiName = "mczusermanager";
        const path = "/user"

        const userdata = {
          response: true,
          body: {
            username: this.userData.user.email,
            password: this.password,
            groupName: this.group,
            attributes: [
              { Name: "email", Value: this.userData.user.email },
              { Name: "name", Value: this.userData.user.name },
              { Name: "email_verified", Value: "true" },
            ]
          },
        };

        const response = await API.post(apiName, path, userdata);
        console.log(`mczusermanager API Response: ${JSON.stringify(response, null, "\t")}`)
        const data = response.data;
        if (data.success != "") {
          const errorInfo = JSON.parse(data.message)
          switch (errorInfo.code) {
          case "UsernameExistsException":
            throw 'すでに登録済みのメールアドレスです。'

          default:
            throw `${errorInfo.code} ${errorInfo.message}`
          }
        }

        // 仮：emailをユーザIDにセット
        this.userData.user.userId = data.sub
        this.userData.sk = UserModel.createSk(this.userData.user.userId);

        await UserModel.addUser(this.userData);
      } catch (e) {
        console.log(JSON.stringify(e))
        this.showBottomToast(e, `error`)
        throw e
      } finally {
        this.loader.hide()
        this.loader = null
      }

      this.$router.push({
        name: "UserList",
      });
    },

    /**
     * ユーザ―削除ボタンのクリック
     */
    clickDeleteUser() {

      // モーダルを表示
      const _this = this;
      this.$nextTick(function() {
        this.modal = {
          isShow: true,
          title: "ユーザーの削除",
          message: `${_this.userData.user.name}を削除します。よろしいですか？`,
          okLabel: "削除",
          okType: "danger",
          okCallBack: function() {
            _this.deleteUser();

            // 閉じる
            _this.modal.isShow = false

          },
          cancelLabel: "キャンセル",
          cancelType: "secondary",
          cancelCallBack: function() {

            _this.modal.isShow = false

          },
        };
      });

    },

    /**
     * ユーザ情報を削除
     * ToDo 現在はDynamoDBの論理削除（CognitoIDは操作なし）
     */
    async deleteUser() {

      try {
        this.loader = this.$loading.show();

        // 削除フラグを設定
        this.userData.user.deleted = true;

        await UserModel.updateUser(this.userData);
        if (!this.fromNavbar) {
          this.$router.push({
            name: "UserList",
          });
        } else {
          this.showBottomToast(`削除しました`, `info`)
        }

      } catch (e) {
        console.log(JSON.stringify(e))
        this.showBottomToast(`削除時にエラーが発生しました`, `error`)
        throw e
      } finally {
        this.loader.hide()
        this.loader = null
      }
      this.$router.push({
        name: "UserList",
      });
    },
    /**
     * メニュークリック時の処理です。
     * @param {String} command クリックしたメニューのコマンド
     */
    handleCommand(command) {
      switch (command) {
      case "deleteUser":
        this.clickDeleteUser();
        break;
      }
    },

    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },

    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName:["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
  },
});
</script>

<style></style>
