const { Storage } = require("aws-amplify");
const TenantStorage = require("@/appUtils/TenantStorage");
const UserInfo = require('./UserInfo');

class FileUtil {
  static async getTenantFileUrl(s3Key) {

    if (!s3Key) {
      return ""
    }

    try {
      const user = await UserInfo.getUserInfo()
      const tenantStorage = new TenantStorage(user.group)

      const s3KeyPrefix = tenantStorage.getBaseDir();
      console.log(`getTenantFile ${s3KeyPrefix}${s3Key}`)
      const url = (await tenantStorage.get(`${s3KeyPrefix}${s3Key}`)).toBase64();
      console.log(`url:: ${url}`)
      return url;

      // const s3KeyPrefix = `tenant/${user.group}/`
      // console.log(`getTenantFile ${s3KeyPrefix}${s3Key}`)
      // const url = await this.get(`${s3Key}`, {level: "public", customPrefix: {public: s3KeyPrefix}})
      // console.log(`url:: ${url}`)
      // return url.toString()
    } catch (e) {
      alert(`error getTenantFile ${JSON.stringify(e, null, "\t")}`)
      throw e
    }
  }
  static async get(key, config) {
    try {
      let imageUrl = await Storage.get(key, config)
      return imageUrl
    } catch (e) {
      this.debugText = `エラー:${JSON.stringify(e)}`
    }
  }
}

module.exports = FileUtil;
