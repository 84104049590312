const short = require("short-uuid");
const UserInfo = require("../../appUtils/UserInfo");
const baseModel = require("../baseModel")

/**
 * ファイル用のデータ取得モデルクラス
 */
class FileModel extends baseModel {
  /**
   * テナント内のファイルリストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getFileList(projectId,nodeId) {

    return await this.convertFileListData(await super.getList({ beginsWith: `file#${projectId}#${nodeId}#` }))

  }


  static async convertFileListData(results) {

    let fileList = []
    results.forEach((row,index) => {
      let iconClass = ""
      switch(row.file.fileName.split(".")[1].toLowerCase()) {
        case "jpg":
        case "png":
        case "gif":
          iconClass = "far fa-file-image"
          break
        case "pdf":
          iconClass = "far fa-file-pdf"
        case "xls":
        case "xlsx":
          iconClass = "far fa-file-excel"
          break
        default:
          iconClass = "far fa-file-file"
      }
      fileList.push({
        title: row.file.fileName,
        fileType:"jpg",
        iconClass:iconClass,
        size: this.convertUnitString(row.file.fileSize),
        sortSize: row.file.fileSize,
        createDate:row.file.createDate,
        rawData:Object.assign({},row)
      })
    })

    return fileList

  }


  static getUnitType(size) {
    const kb = 1024
    const mb = Math.pow(kb, 2)
    const gb = Math.pow(kb, 3)
    const tb = Math.pow(kb, 4)
    const returnData = (target, unit) => ({ target, unit })
    if (size >= tb) return returnData(tb, 'TB')
    if (size >= gb) return returnData(gb, 'GB')
    if (size >= mb) return returnData(mb, 'MB')
    if (size >= kb) return returnData(kb, 'KB')
    return returnData(null, 'byte')
  }
  static convertUnitString(size) {
    const { target, unit } = this.getUnitType(size)
    const newSize = target !== null ? Math.floor((size / target) * Math.pow(10, 2)) / Math.pow(10, 2) : size

    return newSize + unit
  }

  /**
   * ファイルを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteFile(data) {
    await super.deleteData(data)
  }

  /**
   * ファイル管理レコードを追加する
   * @param {*} data
   */
  static async addFile(data) {
    await super.addData(data)
  }

  /**
   * ファイル管理レコードを更新する
   * @param {*} data
   */
  static async updateFile(data,isFileUpdate) {
    // ファイル内容の更新を行った場合は更新日付を書き換える。DBの値だけの場合は何もしない。
    if(isFileUpdate) {
      data.file.updateDate = super.getNowDate()
      data.file.updateUser = await UserInfo.getUserInfo()
    }

    await super.updateData(data)
  }

  /**
   * ファイル管理レコードを取得する
   * @param {*} data
   */
  static async getFile(data) {
    return await super.getSingleData(data);
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId,nodeId,fileId) {
    //file#プロジェクトID#ノードID#ファイルID
    return `file#${projectId}#${nodeId}#${fileId}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewFileData(projectId,nodeId) {
    let newRowData = await super.getNewData()
    const userInfo = await UserInfo.getUserInfo()
    const id = short.generate()

    newRowData.sk = this.createSk(projectId,nodeId,id)
    newRowData.file = {
        nodeId:nodeId,
        id:id,
        fileName:"",
        fileSize:0,
        createDate:super.getNowDate(),
        createUser:userInfo.userName,
        updateDate:"",
        updateUser:""
    }

    return newRowData
  }
}

module.exports = FileModel;
