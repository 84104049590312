const decimal = require("decimal.js");

class MathUtil {
  /**
   * 引き算
   *
   * @returns 
   */
  static minus(v1, v2) {
    if (!v1) {
      return "";
    }
    if (isNaN(v1)) {
      return "";
    }
    if (isNaN(v2)) {
      return v1;
    }
    let x = new decimal(v1 - 0);
    return x.minus(v2 - 0);
  }
  /**
   * 掛け算
   *
   * @returns 
   */
  static multiply(v1, v2) {
    if (!v1) {
      return "";
    }
    if (isNaN(v1)) {
      return "";
    }
    if (isNaN(v2)) {
      return v1;
    }
    let x = new decimal(v1 - 0);
    return x.times(v2 - 0);
  }
  /**
   * 割り算
   * @param {*} v1 
   * @param {*} v2 
   * @returns 
   */
  static div(v1, v2) {
    if (!v1) {
      return "";
    }
    if (isNaN(v1)) {
      return "";
    }
    if (isNaN(v2)) {
      return v1;
    }
    let x = new decimal(v1 - 0);
    return x.div(v2 - 0);
  }
}

module.exports = MathUtil;
