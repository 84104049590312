<template>
  <div>
    <div class="container-fluid m-1 pl-1">
      <div class="card mb-0">
        <div class="card-header pl-0 pr-0 pt-2">
          <!-- Title -->
          <h3 class="h3 mb-1">{{title}}</h3>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <base-input type="text" label="検査階層名">
              <template></template>
              <el-input placeholder="検査階層名" v-model="display.levelName"></el-input>
            </base-input>
          </div>
        </div>
        <div v-bind:class="{disabled: !isNew}">
          <div class="row mt-0">
            <div class="col-md-12">
              <base-input name="select" label="検査マスタ" v-if="model.backImageData.backImage" v-model="model.backImageData.backImage.inspectMasterId">
                <el-select placeholder=""
                  v-model="model.backImageData.backImage.inspectMasterList"
                  @change="chengeInspectMaster"
                  multiple
                  filterable>
                  <el-option v-for="item in inspectMasterList"
                            class="select-primary"
                            :value="item.inspectMaster.id"
                            :label="item.inspectMaster.name"
                            :key="item.inspectMaster.name">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row mt-0">
            <h5 class="h5 ml-3">背景図</h5>
            <!-- <div class=""> -->
              <el-radio-group v-model="selectedBackTypeId" class="d-inline-flex justify-content-left col-12 w-100">
                <!-- <div class="d-inline-flex justify-content-between col-12"> -->
                  <el-radio
                    class=""
                    @change="onSelectBackType"
                    v-for="item in backTypes"
                    :key="item.id"
                    :label="item.id">
                    {{item.label}}
                  </el-radio>
                <!-- </div> -->
              </el-radio-group>
              <!-- <div class="row"> -->
              <div v-if="selectedBackTypeId != null" class="text-small col-12 w-100">
                <div>
                  <small>{{ selectBackType.memo }}</small>
                </div>
              </div>
                <!-- </div> -->
            <!-- </div> -->
          </div>
          <div class="row mt-3 ml-0" v-if="selectedBackTypeId == 0">
            <el-upload
              class="w-100 d-inline-flex justify-content-left align-items-baseline"
              action=""
              ref="upload"
              :auto-upload="false"
              :file-list="uploadFiles"
              :thumbnail-mode="true"
              :on-change="addFile"
              :on-remove="removeFile"
              :multiple="false"
            >
              <el-button size="small" type="primary" class="mr-2">背景図ファイルを選択</el-button>
            </el-upload>
          </div>
          <div class="card-footer col-md-12 mt-3 mb-0">
            <div class="row d-flex justify-content-between">
              <div class="col-4">
                <base-button
                  block
                  type="secondary"
                  @click="onClickCancel"
                  >キャンセル</base-button
                >
              </div>
              <div class="">

              </div>
              <div class="col-4">
                <base-button
                  block
                  type="primary"
                  @click="onClickSave"
                  >{{actionLabel}}</base-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  /** このVueだけのスタイル */
  .inline-block {
    position: relative;
    display: inline-block;
    font-size: 14px;
  }
</style>
<style >
  /** 全体に影響するスタイル */
</style>
<script>
import appLog from "@/appUtils/AppLog"
import { Storage } from "aws-amplify";
import UserInfo from '@/appUtils/UserInfo';
import TenantStorage from "@/appUtils/TenantStorage"
import BackImageModel from '@/appModel/BackImage/BackImageModel'
import InspectMasterModel from "@/appModel/InspectMaster/InspectMasterModel"
import { useToast } from "vue-toastification";
import mime from 'mime-types';
const path = require('path')

export default {
  components: {

  },
  /**
   * プロパティ
   */
  props: {
    // /**
    //  * 編集対象tree子データ
    //  */
    // treeChild: {
    //   type: Object,
    //   default: null,
    // },
  },
  data () {
    return {
      projectId: "",
      treeChild: null,
      uploadFiles: [],
      isNew: true,
      source1: "",
      display: {
        backImageName: "",
        backImageFileName: "",
        backImageFilePath: "",
        s3Key: "",
        levelName: "",
      },
      tenantId: "",
      model: {
        backImageData: {}
      },
      inspectMasterList: null,
      tenantStorage: null,
      backTypes: [{
        id: 0,
        label: "図面",
        memo: "PDFまたは画像(png / jpeg)ファイルを背景図に表示します。",
      }, {
        id: 1,
        label: "地図",
        memo: "国土地理院の地図を背景図に表示します。",
      }, {
        id: 2,
        label: "背景図なし",
        memo: "背景図を表示しない一覧だけの検査です。",
      }],
      selectBackType: null,
      selectedBackTypeId: null,
      title: "検査を追加",
      actionLabel: "登録",
    }
  },
  /**
   * コンピュートプロパティ
   */
  computed: {
    fileType() {
      let ext = path.extname(this.display.backImageFileName).toLowerCase()
      if (!ext) {
        console.log(`ext nothing fileType 0`)
        return 0
      }
      switch (ext) {
      case ".jpg":
      case ".png":
      case ".gif":
        console.log(`fileType 1`)
        return 1

      case ".pdf":
        console.log(`fileType 2`)
        return 2

      default:
        console.log(`fileType 0`)
        return 0
      }
    },
    currentProject() {
      console.log(this.$store.state.project)
      return this.$store.state.project
    },
    currentNode() {
      let node = this.treeChild;
      if (node) {
        return node;
      } else {
        return null;
      }
      // console.log(this.$store.state.selectedNode)
      // return this.$store.state.selectedNode
    },
    // projectId() {
    //   return this.currentProject.sk.split("#")[1]
    // },
    currentNodeId() {
      return this.currentNode.id
    },
  },
  beforeCreate(){
    //インスタンスは生成されたがデータが初期化される前
  },
  created(){
    //インスタンスが生成され､且つデータが初期化された後
    const _this = this

    UserInfo.getUserInfo().then(result => {
      if (result.group) {
        _this.tenantId = result.group
      }
    })

  },
  beforeMount(){
    //インスタンスが DOM 要素にマウントされる前

    console.log(this.backTypes)
    // this.selectBackType = this.backTypes[0];
    console.log(this.selectBackType)

    // ツリーが選択されていない(ツリーの初期ロード時)は処理しない
    if (!this.currentNode) {
      return;
    }

    this.init().then(() => {
      if (!this.isNew) {
        this.title = "検査の編集";
        this.actionLabel = "更新";
      }

    })
  },
  mounted(){
    //インスタンスが DOM 要素にマウントされた後

    // // ツリーが選択されていない(ツリーの初期ロード時)は処理しない
    // if (!this.currentNode) {
    //   return;
    // }

    // this.init().then(() => {
    //   if (!this.isNew) {
    //     this.title = "検査の編集";
    //     this.actionLabel = "更新";
    //   }

    // })
  },
  beforeUpdate(){
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount(){
    //Vue インスタンスが破壊される前
    this.uploadFiles = [];
    this.selectBackType = null;
    this.selectedBackTypeId = null;
  },
  unmounted(){
    //Vue インスタンスが破壊された後
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 前回の選択情報をクリアします。
     */
    async clearSelect() {
      this.model.backImageData = {};
      this.uploadFiles = [];
      this.selectBackType = null;
      this.selectBackType = {};
      this.selectBackType["memo"] = "";
      this.selectedBackTypeId = null;
    },
    async init(projectId, treeChild) {
      this.projectId = projectId;
      this.treeChild = treeChild;

      const user = await UserInfo.getUserInfo()
      this.tenantStorage = new TenantStorage(user.group)

      this.loadBackImageData().then({

      })
    },
    async loadBackImageData() {
      try {
        console.log(this.currentNode)
        // console.log(this.currentProject)
        if (!this.currentNode) {
          return;
        }

        this.isNew = true;
        if (this.treeChild.type) {
          this.isNew = false;
        }

        if (this.isNew) {
          console.log(`新規`)
          this.model.backImageData = await BackImageModel.getNewBackImageData(this.projectId, this.currentNodeId)
          console.log(`loadBackImageData: ${JSON.stringify(this.model.backImageData, null, "\t")}`)
          this.display.levelName = this.currentNode.label
        } else {
          // 現状編集はない想定
        }

        // 検査マスタ取得
        InspectMasterModel.getInspectMasterList(this.projectId).then(function(result) {
          this.inspectMasterList = result
          if (result.length > 0) {
            this.currentInspectMaster = result[0]
          }

        }.bind(this))
      } catch (e){
        alert("loadBackImageData" + e.message)
        throw e
      }
    },
    /**
     * 背景ラジオボタン変更時の処理です。
     */
    onSelectBackType(selectValue) {
      // console.log(selectValue)

      let selectedBackType = this.backTypes.filter(function(item) {
        return item.id == this.selectedBackTypeId;
      }.bind(this))

      console.log(selectedBackType)

      if (selectedBackType.length > 0) {
        this.selectBackType = selectedBackType[0]
      }

      console.log(this.selectBackType)
    },
    addFile(file, fileList) {
      // 前回のファイルをクリア
      this.removeFile();
      // this.$refs.upload.clearFiles();
      // this.uploadFiles = [];

      this.uploadFiles.push(file)
      console.log(`file:${JSON.stringify(file, null, "\t")}`)
      console.log(`filelist:${JSON.stringify(fileList, null, "\t")}`)

    },
    removeFile() {
      // 前回のファイルをクリア
      this.$refs.upload.clearFiles();
      this.uploadFiles = [];
    },
    onClickCancel() {
      this.$emit("cancel");
    },
    async onClickSave() {
      // 検査マスタ未選択
      if (this.model.backImageData.backImage.inspectMasterList.length < 1) {
        this.showBottomToast("検査マスタを選択してください。", "warn");
        return;
      }
      console.log(this.model.backImageData.backImage.inspectMasterList)

      // 背景図未選択
      if (this.selectedBackTypeId == null) {
        this.showBottomToast("背景図を選択してください。", "warn");
        return;
      }

      if (this.selectedBackTypeId == 0) {

        // 背景図が「図面」の場合
        if (this.uploadFiles.length < 1) {

          // 背景図が未選択であれば警告
          this.showBottomToast("背景図ファイルを選択してください。", "warn");
          return;

        } else {

          // 背景図面をs3に登録
          await this.uploadFile();
        }

        // 背景図名はファイル名は仮入れ(ToDo)
        this.model.backImageData.backImage.backImageName = path.basename(this.uploadFiles[0].name);

        // this.model.backImageData.backImage.backImageName = this.display.backImageName
        this.model.backImageData.backImage.backimagePath = this.display.s3Key

      }

      // DB登録
      await BackImageModel.addBackImage(this.model.backImageData)

      // 背景図ラジオボタンに応じたtreeオブジェクトを返す
      this.treeChild.label = this.display.levelName;

      let type = "";
      switch (this.selectedBackTypeId) {
      case 0:
        type = "backimage";
        break;
      case 1:
        type = "map";
        break;
      case 2:
        type = "nobackimage";
        break;
      }
      this.treeChild.type = type;

      this.$emit("save", this.treeChild);
    },
    async uploadFile() {

      // let loader = this.showLoader();
      const file = this.uploadFiles[0];

      try {
        const projectId = this.projectId
        const nodeId = this.currentNodeId

        if (!this.tenantId || !projectId || !nodeId) {
          alert(`パラメータ不足: ${this.tenantId}/${projectId}/${nodeId}`)
          return
        }

        // 掃除
        const tempKey = `${this.tenantId}/${projectId}/${nodeId}/temp/`
        const list = await Storage.list(tempKey)
        for (let i = 0;i < list.length;i++) {
          await Storage.remove(list[i].key)
        }

        // S3キー名

        const s3Key = `${this.tenantStorage.getBaseDir()}${projectId}/${nodeId}/${file.name}`
        // ファイルサイズ
        // const size = file.size
        const fileName = file.name
        const fileBody = file.raw

        let contentType = mime.lookup(file.name)
        console.log(`contentType:${contentType}`)
        //const option = {level: "public", contentType: contentType}
        const option = {"ContentType": contentType}

        try {
          // S3バケットにファイルを保存
          console.log(`s3Key:${s3Key}`)
          await this.tenantStorage.put(s3Key, fileBody, option)
          this.$nextTick(async function(){
            this.display.s3Key = s3Key
            this.display.backImageFileName = fileName
            this.display.backImageFilePath = await this.tenantStorage.get(s3Key)
          }.bind(this))
        } catch (e) {
          alert(`S3アップロード失敗 ${e.message}`)
          throw e
        }

        // const ext = fileName.split(".")[1].toLowerCase()

        console.log(`fileType:${this.fileType}`)

      } catch (e) {
        alert(e.message)
        throw e
      } finally {
        // this.hideLoader(loader);
      }
    },
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },

    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
  }
};
</script>
