const { API, graphqlOperation } = require("aws-amplify");
const {
  createMapletFSCloudData,
  deleteMapletFSCloudData,
  updateMapletFSCloudData,
} = require("@/graphql/mutations");
const {
  listMapletFSCloudDatas,
  getMapletFSCloudData,
} = require("@/graphql/queries");
const short = require('short-uuid');
const baseModel = require("../baseModel")

/**
 * テナント用のデータ取得モデルクラス
 */
class TenantModel extends baseModel {
  /**
   * テナント内のテナントリストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getTenantList() {
    
    const result = await API.graphql(
      graphqlOperation(listMapletFSCloudDatas, {
        pk: "tenant",
        sk: { beginsWith: "tenant#" }
      })
    );
    //alert(JSON.stringify(result))
    if (result) {
      if (result.data) {
        console.log(`getList : ${result.data.listMapletFSCloudDatas.items.length}`)
        return result.data.listMapletFSCloudDatas.items;
      }
    }
    return [];
  }

  /**
   * テナントを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteTenant(data) {
    await super.deleteData(data)
  }

  /**
   * テナント管理レコードを追加する
   * @param {*} data
   */
  static async addTenant(data) {
    await super.addData(data)
  }

  /**
   * テナント管理レコードを更新する
   * @param {*} data
   */
  static async updateTenant(data) {
    await super.updateData(data)
  }

  /**
   * テナント管理レコードを取得する
   * @param {*} data
   */
  static async getTenant(tenantId) {
    let result = await API.graphql(
      graphqlOperation(getMapletFSCloudData, { pk: "tenant", sk: `tenant#${tenantId}` })
    );
    return result.data.getMapletFSCloudData;
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(tenantId) {
    return `tenant#${tenantId}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData() {

    let newRowData = {
      pk: `tenant`,
      sk: ``,
      lsiStr1: "-",
      lsiStr2: "-",
      lsiStr3: "-",
      lsiStr4: "-",
      lsiInt1: 0,
      groups: [`ncsmanager`],
      
    }
    
    newRowData.tenant = {
      tenantId: ``,
      name: "",
      contractStart: "",
      contractEnd: "",
      maxCapacity: 0,
      maxProjects: 0,
      maxFiles: 0,
      maxUsers: 0,
      status: "",
      allocateVolume: 0,
      numberOfProject: 0,
      availableOptions: []
    }

    return newRowData
  }
}

module.exports = TenantModel;
