/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMapletFSCloudData = /* GraphQL */ `
  mutation CreateMapletFSCloudData(
    $input: CreateMapletFSCloudDataInput!
    $condition: ModelMapletFSCloudDataConditionInput
  ) {
    createMapletFSCloudData(input: $input, condition: $condition) {
      id
      pk
      sk
      lsiStr1
      lsiStr2
      lsiStr3
      lsiStr4
      lsiInt1
      groups
      tenant {
        tenantId
        name
        contractStart
        contractEnd
        maxCapacity
        maxProjects
        maxFiles
        maxUsers
        status
        allocateVolume
        numberOfProject
        availableOptions
      }
      user {
        name
        userId
        usertype
        status
        period
        email
        deleted
      }
      meeting {
        title
        roomId
        meetingId
        externalMeetingId
        audioHostUrl
        audioFallbackUrl
        screenDataUrl
        screenSharingUrl
        screenViewingUrl
        signalingUrl
        turnControlUrl
        mediaRegion
        startDateTime
        invitedUsers
        organizer
        ttl
      }
      todo {
        id
        title
        createUser
        targetUser
        content
        scheduledDateTime
        status
        parameters
      }
      project {
        id
        name
        options {
          id
          label
          value
        }
        users {
          name
          userId
          usertype
          status
          period
          email
          deleted
        }
      }
      rootItem {
        label
        id
        path
        children {
          label
          id
          path
          children {
            label
            id
            path
            children {
              label
              id
              path
              children {
                label
                id
                path
                children {
                  label
                  id
                  path
                  children {
                    label
                    id
                    path
                    type
                    order
                    deleteFlag
                    fileSize
                    fileList
                    createDate
                    createUser
                    updateDate
                    updateUser
                  }
                  type
                  order
                  deleteFlag
                  fileSize
                  fileList
                  createDate
                  createUser
                  updateDate
                  updateUser
                }
                type
                order
                deleteFlag
                fileSize
                fileList
                createDate
                createUser
                updateDate
                updateUser
              }
              type
              order
              deleteFlag
              fileSize
              fileList
              createDate
              createUser
              updateDate
              updateUser
            }
            type
            order
            deleteFlag
            fileSize
            fileList
            createDate
            createUser
            updateDate
            updateUser
          }
          type
          order
          deleteFlag
          fileSize
          fileList
          createDate
          createUser
          updateDate
          updateUser
        }
        type
        order
        deleteFlag
        fileSize
        fileList
        createDate
        createUser
        updateDate
        updateUser
      }
      treeData
      backImage {
        backImageName
        backimagePath
        parameters
        inspectMasterId
        inspectMasterList
        lineSetting {
          streetlineId
          lineType
          startX
          startY
          endX
          endY
          lineColor
          lineWidth
          lineName
          labelPosition
        }
      }
      inspectPlace {
        placeId
        name
        x
        y
        latitude
        longitude
        createUser
        inspectMasterId
        lineName
        iconClass
        deleteFlag
        placeImagePath
        iconWidth
        iconHeight
        iconOffsetX
        iconOffsetY
        textValue
        textSize
        textColor
        textEdgeColor
      }
      file {
        nodeId
        id
        fileName
        fileSize
        createDate
        createUser
        updateDate
        updateUser
      }
      rebarmeasure {
        constructionName
        constructionType
        company
        shootingSpot
        title
        detailNote
        memo
        type
        detailSpot
        rebar1No
        rebar1DiameterDesign
        rebar1PitchDesign
        rebar1Memo
        diameterList1 {
          name
          mark
          value
          unit
          designedValue
          remarks
        }
        pitchList1 {
          name
          mark
          value
          unit
          designedValue
          remarks
        }
        totalPitch1
        averagePitch1
        rebar2No
        rebar2DiameterDesign
        rebar2PitchDesign
        rebar2Memo
        diameterList2 {
          name
          mark
          value
          unit
          designedValue
          remarks
        }
        pitchList2 {
          name
          mark
          value
          unit
          designedValue
          remarks
        }
        totalPitch2
        averagePitch2
        measurerName
        designRangeClass
        designRangeValue
        localDesignRangeClass
        localDesignRangeValue
        outputStatus
        importType
        imageList {
          s3key
          width
          height
          isOrigin
          bytes
        }
        isMinusValidlyheight
        ttl
        deleteFlg
        value
        designValue
      }
      formOutputSetting {
        templateFile
        outputFileName
        xlsxFileS3Key
        workBookFileName
        sheet
        imageSheet
        datalist {
          dataId
          index
          value
          type
        }
        imageList {
          imageId
          row
          col
          cellRange
          width
          height
          s3Key
        }
        staticValueList {
          key
          value
          type
          style
        }
        iterateValueList {
          list {
            key
            value
            type
            style
          }
        }
        photoValuelist {
          list {
            key
            value
            type
            style
          }
        }
      }
      ttl
      shape {
        shapeId
        shapeType
        coordinates
        lineColor
        lineWidth
        paintColor
        paintAlpha
        radius
        shapeText
        shapeTextSize
        shapeTextColor
      }
      inspectMaster {
        id
        name
        inspectItemList {
          id
          name
          itemType
          masterType
          required
          order
          defaultValue
        }
        iconSetting {
          id
          name
          iconClass
          iconFilePath
          systemIconType
          systemIconColor
          order
          isDefault
        }
        inputMaster {
          id
          name
          list {
            id
            name
            value
            order
          }
        }
        lockFlg
      }
      inspectResultSet {
        id
        masterId
        inspectList {
          itemId
          value
          name
          itemType
          masterType
          required
          resultFlg
          order
        }
        photoList {
          filePath
          order
          isWrite
          memo
        }
      }
      notice {
        id
        targetUsers
        type
        content
        createUser
        Readed
        needPushNotice
        completedPush
        linkedId
      }
      timestamp
      formTemplate {
        id
        name
        filePath
        deleteFlg
        order
        createDate
        updateDate
        createUser
        updateUser
        memo
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMapletFSCloudData = /* GraphQL */ `
  mutation UpdateMapletFSCloudData(
    $input: UpdateMapletFSCloudDataInput!
    $condition: ModelMapletFSCloudDataConditionInput
  ) {
    updateMapletFSCloudData(input: $input, condition: $condition) {
      id
      pk
      sk
      lsiStr1
      lsiStr2
      lsiStr3
      lsiStr4
      lsiInt1
      groups
      tenant {
        tenantId
        name
        contractStart
        contractEnd
        maxCapacity
        maxProjects
        maxFiles
        maxUsers
        status
        allocateVolume
        numberOfProject
        availableOptions
      }
      user {
        name
        userId
        usertype
        status
        period
        email
        deleted
      }
      meeting {
        title
        roomId
        meetingId
        externalMeetingId
        audioHostUrl
        audioFallbackUrl
        screenDataUrl
        screenSharingUrl
        screenViewingUrl
        signalingUrl
        turnControlUrl
        mediaRegion
        startDateTime
        invitedUsers
        organizer
        ttl
      }
      todo {
        id
        title
        createUser
        targetUser
        content
        scheduledDateTime
        status
        parameters
      }
      project {
        id
        name
        options {
          id
          label
          value
        }
        users {
          name
          userId
          usertype
          status
          period
          email
          deleted
        }
      }
      rootItem {
        label
        id
        path
        children {
          label
          id
          path
          children {
            label
            id
            path
            children {
              label
              id
              path
              children {
                label
                id
                path
                children {
                  label
                  id
                  path
                  children {
                    label
                    id
                    path
                    type
                    order
                    deleteFlag
                    fileSize
                    fileList
                    createDate
                    createUser
                    updateDate
                    updateUser
                  }
                  type
                  order
                  deleteFlag
                  fileSize
                  fileList
                  createDate
                  createUser
                  updateDate
                  updateUser
                }
                type
                order
                deleteFlag
                fileSize
                fileList
                createDate
                createUser
                updateDate
                updateUser
              }
              type
              order
              deleteFlag
              fileSize
              fileList
              createDate
              createUser
              updateDate
              updateUser
            }
            type
            order
            deleteFlag
            fileSize
            fileList
            createDate
            createUser
            updateDate
            updateUser
          }
          type
          order
          deleteFlag
          fileSize
          fileList
          createDate
          createUser
          updateDate
          updateUser
        }
        type
        order
        deleteFlag
        fileSize
        fileList
        createDate
        createUser
        updateDate
        updateUser
      }
      treeData
      backImage {
        backImageName
        backimagePath
        parameters
        inspectMasterId
        inspectMasterList
        lineSetting {
          streetlineId
          lineType
          startX
          startY
          endX
          endY
          lineColor
          lineWidth
          lineName
          labelPosition
        }
      }
      inspectPlace {
        placeId
        name
        x
        y
        latitude
        longitude
        createUser
        inspectMasterId
        lineName
        iconClass
        deleteFlag
        placeImagePath
        iconWidth
        iconHeight
        iconOffsetX
        iconOffsetY
        textValue
        textSize
        textColor
        textEdgeColor
      }
      file {
        nodeId
        id
        fileName
        fileSize
        createDate
        createUser
        updateDate
        updateUser
      }
      rebarmeasure {
        constructionName
        constructionType
        company
        shootingSpot
        title
        detailNote
        memo
        type
        detailSpot
        rebar1No
        rebar1DiameterDesign
        rebar1PitchDesign
        rebar1Memo
        diameterList1 {
          name
          mark
          value
          unit
          designedValue
          remarks
        }
        pitchList1 {
          name
          mark
          value
          unit
          designedValue
          remarks
        }
        totalPitch1
        averagePitch1
        rebar2No
        rebar2DiameterDesign
        rebar2PitchDesign
        rebar2Memo
        diameterList2 {
          name
          mark
          value
          unit
          designedValue
          remarks
        }
        pitchList2 {
          name
          mark
          value
          unit
          designedValue
          remarks
        }
        totalPitch2
        averagePitch2
        measurerName
        designRangeClass
        designRangeValue
        localDesignRangeClass
        localDesignRangeValue
        outputStatus
        importType
        imageList {
          s3key
          width
          height
          isOrigin
          bytes
        }
        isMinusValidlyheight
        ttl
        deleteFlg
        value
        designValue
      }
      formOutputSetting {
        templateFile
        outputFileName
        xlsxFileS3Key
        workBookFileName
        sheet
        imageSheet
        datalist {
          dataId
          index
          value
          type
        }
        imageList {
          imageId
          row
          col
          cellRange
          width
          height
          s3Key
        }
        staticValueList {
          key
          value
          type
          style
        }
        iterateValueList {
          list {
            key
            value
            type
            style
          }
        }
        photoValuelist {
          list {
            key
            value
            type
            style
          }
        }
      }
      ttl
      shape {
        shapeId
        shapeType
        coordinates
        lineColor
        lineWidth
        paintColor
        paintAlpha
        radius
        shapeText
        shapeTextSize
        shapeTextColor
      }
      inspectMaster {
        id
        name
        inspectItemList {
          id
          name
          itemType
          masterType
          required
          order
          defaultValue
        }
        iconSetting {
          id
          name
          iconClass
          iconFilePath
          systemIconType
          systemIconColor
          order
          isDefault
        }
        inputMaster {
          id
          name
          list {
            id
            name
            value
            order
          }
        }
        lockFlg
      }
      inspectResultSet {
        id
        masterId
        inspectList {
          itemId
          value
          name
          itemType
          masterType
          required
          resultFlg
          order
        }
        photoList {
          filePath
          order
          isWrite
          memo
        }
      }
      notice {
        id
        targetUsers
        type
        content
        createUser
        Readed
        needPushNotice
        completedPush
        linkedId
      }
      timestamp
      formTemplate {
        id
        name
        filePath
        deleteFlg
        order
        createDate
        updateDate
        createUser
        updateUser
        memo
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMapletFSCloudData = /* GraphQL */ `
  mutation DeleteMapletFSCloudData(
    $input: DeleteMapletFSCloudDataInput!
    $condition: ModelMapletFSCloudDataConditionInput
  ) {
    deleteMapletFSCloudData(input: $input, condition: $condition) {
      id
      pk
      sk
      lsiStr1
      lsiStr2
      lsiStr3
      lsiStr4
      lsiInt1
      groups
      tenant {
        tenantId
        name
        contractStart
        contractEnd
        maxCapacity
        maxProjects
        maxFiles
        maxUsers
        status
        allocateVolume
        numberOfProject
        availableOptions
      }
      user {
        name
        userId
        usertype
        status
        period
        email
        deleted
      }
      meeting {
        title
        roomId
        meetingId
        externalMeetingId
        audioHostUrl
        audioFallbackUrl
        screenDataUrl
        screenSharingUrl
        screenViewingUrl
        signalingUrl
        turnControlUrl
        mediaRegion
        startDateTime
        invitedUsers
        organizer
        ttl
      }
      todo {
        id
        title
        createUser
        targetUser
        content
        scheduledDateTime
        status
        parameters
      }
      project {
        id
        name
        options {
          id
          label
          value
        }
        users {
          name
          userId
          usertype
          status
          period
          email
          deleted
        }
      }
      rootItem {
        label
        id
        path
        children {
          label
          id
          path
          children {
            label
            id
            path
            children {
              label
              id
              path
              children {
                label
                id
                path
                children {
                  label
                  id
                  path
                  children {
                    label
                    id
                    path
                    type
                    order
                    deleteFlag
                    fileSize
                    fileList
                    createDate
                    createUser
                    updateDate
                    updateUser
                  }
                  type
                  order
                  deleteFlag
                  fileSize
                  fileList
                  createDate
                  createUser
                  updateDate
                  updateUser
                }
                type
                order
                deleteFlag
                fileSize
                fileList
                createDate
                createUser
                updateDate
                updateUser
              }
              type
              order
              deleteFlag
              fileSize
              fileList
              createDate
              createUser
              updateDate
              updateUser
            }
            type
            order
            deleteFlag
            fileSize
            fileList
            createDate
            createUser
            updateDate
            updateUser
          }
          type
          order
          deleteFlag
          fileSize
          fileList
          createDate
          createUser
          updateDate
          updateUser
        }
        type
        order
        deleteFlag
        fileSize
        fileList
        createDate
        createUser
        updateDate
        updateUser
      }
      treeData
      backImage {
        backImageName
        backimagePath
        parameters
        inspectMasterId
        inspectMasterList
        lineSetting {
          streetlineId
          lineType
          startX
          startY
          endX
          endY
          lineColor
          lineWidth
          lineName
          labelPosition
        }
      }
      inspectPlace {
        placeId
        name
        x
        y
        latitude
        longitude
        createUser
        inspectMasterId
        lineName
        iconClass
        deleteFlag
        placeImagePath
        iconWidth
        iconHeight
        iconOffsetX
        iconOffsetY
        textValue
        textSize
        textColor
        textEdgeColor
      }
      file {
        nodeId
        id
        fileName
        fileSize
        createDate
        createUser
        updateDate
        updateUser
      }
      rebarmeasure {
        constructionName
        constructionType
        company
        shootingSpot
        title
        detailNote
        memo
        type
        detailSpot
        rebar1No
        rebar1DiameterDesign
        rebar1PitchDesign
        rebar1Memo
        diameterList1 {
          name
          mark
          value
          unit
          designedValue
          remarks
        }
        pitchList1 {
          name
          mark
          value
          unit
          designedValue
          remarks
        }
        totalPitch1
        averagePitch1
        rebar2No
        rebar2DiameterDesign
        rebar2PitchDesign
        rebar2Memo
        diameterList2 {
          name
          mark
          value
          unit
          designedValue
          remarks
        }
        pitchList2 {
          name
          mark
          value
          unit
          designedValue
          remarks
        }
        totalPitch2
        averagePitch2
        measurerName
        designRangeClass
        designRangeValue
        localDesignRangeClass
        localDesignRangeValue
        outputStatus
        importType
        imageList {
          s3key
          width
          height
          isOrigin
          bytes
        }
        isMinusValidlyheight
        ttl
        deleteFlg
        value
        designValue
      }
      formOutputSetting {
        templateFile
        outputFileName
        xlsxFileS3Key
        workBookFileName
        sheet
        imageSheet
        datalist {
          dataId
          index
          value
          type
        }
        imageList {
          imageId
          row
          col
          cellRange
          width
          height
          s3Key
        }
        staticValueList {
          key
          value
          type
          style
        }
        iterateValueList {
          list {
            key
            value
            type
            style
          }
        }
        photoValuelist {
          list {
            key
            value
            type
            style
          }
        }
      }
      ttl
      shape {
        shapeId
        shapeType
        coordinates
        lineColor
        lineWidth
        paintColor
        paintAlpha
        radius
        shapeText
        shapeTextSize
        shapeTextColor
      }
      inspectMaster {
        id
        name
        inspectItemList {
          id
          name
          itemType
          masterType
          required
          order
          defaultValue
        }
        iconSetting {
          id
          name
          iconClass
          iconFilePath
          systemIconType
          systemIconColor
          order
          isDefault
        }
        inputMaster {
          id
          name
          list {
            id
            name
            value
            order
          }
        }
        lockFlg
      }
      inspectResultSet {
        id
        masterId
        inspectList {
          itemId
          value
          name
          itemType
          masterType
          required
          resultFlg
          order
        }
        photoList {
          filePath
          order
          isWrite
          memo
        }
      }
      notice {
        id
        targetUsers
        type
        content
        createUser
        Readed
        needPushNotice
        completedPush
        linkedId
      }
      timestamp
      formTemplate {
        id
        name
        filePath
        deleteFlg
        order
        createDate
        updateDate
        createUser
        updateUser
        memo
      }
      createdAt
      updatedAt
    }
  }
`;
