const moment = require("moment-timezone");
const MeasureFormModel = require("../../../appModel/rebar/MeasureFormModel");

const { API, Storage } = require('aws-amplify');
const appLog = require("../../../appUtils/AppLog")
class BaseFormCreator {
  constructor() {
    // DB登録用のオブジェクト
  }

  async setInitValue() {
    this.formOutputData = await MeasureFormModel.getNewData();
    console.log(`BaseFormCreator: ${JSON.stringify(this.formOutputData,null,"\t")}`)
  }
  /**
   * 配筋間隔の規格値取得
   * @param {*} designRangeClass 
   * @param {*} designValue 
   * @param {*} isMinusValidlyheight 
   * @returns 
   */
  getDesignRangeValue(designRangeClass, designValue, isMinusValidlyheight) {
    switch (designRangeClass) {
    case "0":
      //設計径
      return designValue;
    case "1":
      //プラスマイナス10
      return 10;
    case "2":
      //プラスマイナス20
      return 20;
    case "3":
      //有効高さがマイナスかどうかで可変
      return isMinusValidlyheight ? 10 : 20;
    default:
      return null;
    }
  }
  getKaburiDesignRangeList(designRangeClass, designRangeValue, designValue) {
    switch (designRangeClass) {
    case "0":
      // 未設定
      return ""
    case "1":
      if (designRangeValue) {
        // ±○mmかつ最小かぶり以上
        return `±${designRangeValue}mmかつ最小かぶり以上`
      } else {
        return ``
      }
    case "2":
      // 0以上+○mm以内
      if (designRangeValue) {
        return `0以上+${designRangeValue}mm以内`
      } else {
        return ``
      }
    case "3":
      // ±○mm以内
      if (designRangeValue) {
        return `±${designRangeValue}mm以内`
      } else {
        return ``
      }
    case "4":
      // 設計値以上
      return "設計値以上"
    default:
      // undefined or null
      return ""
    }
    
  }
  getLocalDesignRangeValue(
    localDesignRangeClass,
    designValue,
    designRangeValue,
    isMinusValidlyheight
  ) {
    switch (localDesignRangeClass) {
    case "0":
      //未選択
      return null;
    case "1":
      //±Φ(設計
      return designValue;
    case "2":
      //規格値の80%
      return Math.round(designRangeValue * 0.8);
    case "3":
      //規格値の90%
      return Math.round(designRangeValue * 0.9);
    case "4":
      //規格値の80%(±10)
      return isMinusValidlyheight ? 10 : Math.round(designRangeValue * 0.8);
    case "5":
      //規格値の90%(±10)
      return isMinusValidlyheight ? 10 : Math.round(designRangeValue * 0.9);
    default:
      return null;
    }
  }

  getStaticData(_row) {
    const row = _row.rebarmeasure
    let staticData = [];

    //const typeName = row.type == "0" ? "鉄筋工" : "床版工"
    //工事名
    staticData.push({
      key: "constructionName",
      value: row.constructionName,
      type: "string",
    });
    //種別
    staticData.push({
      key: "constructionType",
      value: row.constructionType,
      type: "string",
    });
    //測点
    staticData.push({
      key: "shootingSpot",
      value: row.shootingSpot,
      type: "string",
    });
    //請負会社
    staticData.push({ key: "company", value: row.company, type: "string" });
    //測定者
    staticData.push({
      key: "measurerName",
      value: row.measurerName,
      type: "string",
    });
    //工種
    staticData.push({
      key: "typeName",
      value: row.type == "0" ? "鉄筋工" : "床版工",
      type: "string",
    });
    //詳細
    staticData.push({
      key: "detailNote",
      value: row.detailNote,
      type: "string",
    });
    //備考
    staticData.push({
      key: "memo",
      value: row.memo,
      type: "string",
    });

    return staticData;
  }
  invalidValueToEmpty(value) {
    if (!value && value !== 0 && value !== "0") {
    // if (!value) {
      return "";
    }
    if (isNaN(value)) {
      return 0;
    }
    return value;
  }
  appendSymbolAtFront(value, symbol) {
    if (!value) {
      return value
    }
    return `${symbol} ${value}`
  }
  appendSymbolAtBehind(value, symbol) {
    if (!value) {
      return value
    }
    return `${value} ${symbol}`
  }
  appendSign(value) {
    if (!value) {
      return value
    }
    if (isNaN(value)) {
      return value
    }
    let num = value - 0
    if (num - 0 > 0) {
      // +を付けた文字列に
      return `+${num}`
    } else {
      // マイナスがついているはず
      return `${num}`
    }
  }

  getCurrentDateString(format) {
    return moment(new Date()).tz("Asia/Tokyo").format(format);
  }
  /**
   * 帳票の入力データをJSONで作成し、S3にアップロードする
   * @param {*} key 
   */
  async putFormDataJson() {
    // 帳票出力データをJSONリテラルに
    const jsonData = JSON.stringify(this.formOutputData)
    // バイナリ化
    const blob = new Blob([jsonData], { type: 'application/json' })
    // DBのユニークidをキーにする
    const formDataJsonKey = `formData/${this.formOutputData.sk}.json`
    // S3アップロード
    await Storage.put(formDataJsonKey, blob, {
      level: 'private',
      contentType: 'application/json'
    });
  }

  async invokeFormApi() {
    try {
      // jsonファイルを登録
      await this.putFormDataJson()

      // DB登録
      // await MeasureFormModel.addFormData(this.formOutputData)
      appLog.debugLog('登録OK')
      // API呼び出しとダウンロード
      return await this.callSmcMakeXlsxForm()

    } catch (e) {
      console.log(`帳票作成API呼び出し時のエラー ${JSON.stringify(e, null, "\t")}`)
      throw e
    }

  }
  async callSmcMakeXlsxForm() {
    try {
      const APIName = 'apiexcelform'
      const path = '/excelform'
      const myInit = {

        headers: {},
        response: true,
        body: {
          pk: this.formOutputData.pk,
          sk: this.formOutputData.sk
        }
      };

      const result = await API.post(APIName, path, myInit)
      if (!result.data) {
        throw new Error(`帳票作成失敗`)
      }
      this.apiResult = result

      const outFileName = `xlsxform/${result.data.formFileName}`
      const excelUrl = await Storage.get(outFileName, {level: "private"})
      //await Storage.get(outFileName, {level: "private"})
      console.log(`excelUrl:${excelUrl}`)
      return excelUrl

    } catch (e){
      console.error(`Error: ${e}`)
      throw e
    }
  }

}
module.exports = BaseFormCreator;
