/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div>
    <div class="container-fluid mt-1">
      <div class="card">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-12 text-right">
              <base-button
                icon
                outline
                type="primary"
                size="sm"
                @click="onClickNewNotice"
              >
                <span class="btn-inner--icon"
                  ><i class="fa fa-plus-square"></i
                ></span>
                <span class="btn-inner--text">お知らせを追加</span>
              </base-button>

              <!-- ToDo -->
              <!--
                <ul class="list-group list-group-flush" data-toggle="checklist">
                <li class="list-group-item px-0" v-for="todo in todoList" :key="todo.title">
                  <div
                    class="checklist-item"
                    :class="{
                      'checklist-item-checked': todo.todo.status == 1,
                    }"
                  >
                    <div class="checklist-info">
                      <h5 class="checklist-title mb-0">{{ todo.todo.title }}</h5>
                      <small>{{ todo.todo.scheduledDateTime }}</small><br />
                      <small v-if="todo.todo.targetUser">対象:{{ users[todo.todo.targetUser] }}</small><br />
                      <small>{{todo.todo.content}}</small>
                    </div>
                    <div>
                      <el-dropdown class="dropdown" trigger="click">
                        <span class="btn btn-sm btn-icon-only text-light">
                          <i class="fas fa-ellipsis-v mt-2"></i>
                        </span>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <a class="dropdown-item" @click.prevent="onClickTodoEdit(todo)">編集</a>
                            <a class="dropdown-item text-danger" @click.prevent="onClickTodoDelete(todo)" >削除</a>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </div>
                  </div>
                </li>
              </ul>
              -->
            </div>
          </div>
        </div>

      <!-- 通知リスト -->
        <!-- <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="noticeList"
          >
          <el-table-column
              label="区分"
              sortable
            >
            <template v-slot="{ row }">
              <div class="table-actions">
                <el-tooltip content="Edit" placement="top">
                  <span
                    v-bind:class="row.iconClass"
                    style="font-size: 2rem; margin-right: 1rem"
                  ></span>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="内容"
            min-width="410px"
            prop="name"
            sortable
          >
            <template v-slot="{ row }">
              <b>
                <a href="#!" @click.prevent="onClickNoticeEdit(row)"
                  >{{ row.content }}
                </a>
              </b>
            </template>
          </el-table-column>
          <el-table-column label="作成日" min-width="140px" sortable>
            <template v-slot="{ row }">
              <span>{{ row.createdAt }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="180px" align="center">
            <template v-slot="{ row }">
              <div class="table-actions">
                <el-tooltip content="Delete" placement="top">
                <a
                    href="#!"
                    @click.prevent="onClickDeleteNotice(row)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    data-original-title="Delete product"
                >
                    <i class="fas fa-trash text-red" style="font-size:1.5rem;"></i>
                </a>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table> -->
        <div class="col-md-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <!-- Title -->
              <!-- <h5 class="h3 mb-0">お知らせ</h5> -->
            </div>
            <!-- Card body -->
            <div class="card-body">
              <div
                v-if="noticeList.length"
                class="timeline timeline-one-side"
                data-timeline-content="axis"
                data-timeline-axis-style="dashed">

                <div v-for="notice in sortedNotices" :key="notice.id" class="timeline-block">
                  <span class="timeline-step" :class="[`badge-${notice.iconType}`]">
                    <i :class="notice.iconClass"></i>
                  </span>
                  <div class="timeline-content">
                    <div class="d-flex justify-content-between pt-1">
                      <div>
                        <small>{{ notice.createdAt }}</small>
                      </div>
                      <div class="text-right">
                        <!-- <base-checkbox v-model="item.done" :type="item.checkType" /> -->
                        <el-dropdown class="dropdown" trigger="click">
                          <span class="btn btn-sm btn-icon-only text-dark">
                            <i class="fas fa-ellipsis-v mt-2"></i>
                          </span>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <!-- <a class="dropdown-item" @click.prevent="onClickTodoEdit(todo)">編集</a> -->
                              <a class="dropdown-item text-danger" @click.prevent="onClickNoticeDelete(notice)" >削除</a>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </div>

                    </div>
                    <!-- <div class="d-flex justify-content-between pt-1"> -->
                    <div class="text-sm">作成者：
                      <span class="text-sm font-weight-bold">{{
                        notice.createUserName
                      }}</span>
                    </div>
                    <!-- </div> -->
                    <div class="text-sm mt-1 mb-0">内容：
                      <!-- <small style="white-space:pre-wrap; word-wrap:break-word;">{{ notice.content }}</small> -->

                      <span v-if="!notice.linkedId" style="font-weight:bold;white-space:pre-wrap; word-wrap:break-word;">{{ notice.content }}</span>
                      <a  v-if="notice.linkedId" href="#!" @click.prevent="onClickLinkedId(notice.linkedId)">
                        <span v-if="notice.linkedId" style="font-weight:bold;white-space:pre-wrap; word-wrap:break-word;">{{ notice.content }}</span>
                      </a>
                    </div>
                    <!-- <a href="#hoge" class="btn btn-link px-0">hogehoge</a> -->
                    <!-- <b>
                      <a href="#!" @click.prevent="onClickLinkedId(notice.linkedId)"
                        >{{ notice.linkedId }}
                      </a>
                    </b> -->
                  </div>
                </div>
              </div>
              <div v-else>
                <small>お知らせはありません</small>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <modal v-model:show="showEditModal">
      <notice-edit ref="noticeEdit" :editNoticeData="noticeData" @close-notice="closeNotice"></notice-edit>
    </modal>
  </div>
</template>
<script>
import NoticeModel from "@/appModel/Notice/NoticeModel"
import UserModel from "@/appModel/users/UserModel";
import NoticeEdit from '@/appViews/Notice/NoticeEdit.vue'
import Modal from "@/components/Modal";

export default {
  components: {
    NoticeEdit,
    Modal,
    editNoticeData: null,
  },
  data() {
    return {
      noticeList: [],
      // todoList: [],
      users: {},
      showEditModal: false,
      /**
       * id:String
  title:String
  createUser:String
  targetUser:String
  content:String
  scheduledDateTime:String
  status:String
  parameters:String
       */
      items: [
        // {
        //   title: "資材受入検査をする(南ゲート)",
        //   description: "2021/10/01 13:00 PM",
        //   author:"現場監督",
        //   done: false,
        //   checkType: "info",
        // },
        // {
        //   title: "地下躯体工事の立会検査",
        //   description: "2021/10/01 12:30 PM",
        //   author:"現場監督",
        //   done: false,
        //   checkType: "warning",
        // },
        // {
        //   title: "工事担当者間の打ち合わせ",
        //   description: "2021/10/01 09:30 AM",
        //   author:"検査　次郎",
        //   done: true,
        //   checkType: "info",
        // },
        // {
        //   title: "工事現場巡回",
        //   description: "2021/10/01 08:00 AM",
        //   author:null,
        //   done: true,
        //   checkType: "info",
        // },
      ],
    };
  },
  computed: {
    sortedNotices() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.noticeList.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
    noticeEdit() {
      console.log(this.$store.state);
      if (this.$store.state.notice.edit == null) {
        return false;
      }
      if (this.$store.state.notice.edit) {
        this.$nextTick(function() {
          this.$refs.noticeEdit.initNotice()
        })
      }
      return this.$store.state.notice.edit
    },
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    let navInfo = this.$store.state.navInfo;
    navInfo.title = "通知";
    navInfo.description = "";
    this.$store.commit("setNavInfo", navInfo);

    this.showEditModal = false;
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  methods: {
    async init() {
      this.showLoader(true);

      await this.getProjectUsers().then(function() {
        this.getNoticeList();
      }.bind(this))

    },
    async getProjectUsers() {
      const result = await UserModel.getUserList();
      result.forEach(function(item){
        this.users[item.user.userId] = item.user.name
      }.bind(this))
    },

    async getNoticeList(){
      this.noticeList = await NoticeModel.getNoticeList();
      await this.updateNoticesReaded(this.noticeList);

      this.showLoader(false);
    },

    /**
     * お知らせ一覧の既読フラグをtrueに更新します。
     * @param {Array} notices お知らせ一覧
     * @returns 既読フラtrue更新後のお知らせ一覧
     */
    async updateNoticesReaded(notices) {
      console.log(notices);
      // this.noticeList = [];

      for (let i = 0; i < notices.length; i++) {
        let notice = notices[i];
        console.log(notice);

        if (!notice.Readed) {
          notice.Readed = true;
          notice.rawData.notice.Readed = true;
          console.log('既読');

          // 更新
          await NoticeModel.updateNotice(notice.rawData);
        }
      }

      console.log(this.noticeList);
    },
    onClickNewNotice() {

      this.showEditModal = true;
      this.$refs.noticeEdit.initNotice();
      console.log(this.showEditModal);

    },
    onClickTodoEdit(data){
      let todoStore = this.$store.state.todo
      todoStore.edit = true
      todoStore.data = data
      todoStore.drawer = false
      this.$store.commit('setTodo', todoStore)
      // this.$emit("todoEdit", data)
    },
    onClickNoticeEdit(data){
      let noticeStore = this.$store.state.notice
      noticeStore.edit = true
      noticeStore.data = data
      noticeStore.drawer = false
      this.$store.commit('setNotice', noticeStore)
      // this.$emit("todoEdit", data)

      console.log(data);
      this.editNoticeData = data;
      this.showEditModal = true;
      this.$refs.noticeEdit.initNotice();
    },
    async onClickNoticeDelete(data) {
      await NoticeModel.deleteNotice(data.rawData)
      this.noticeList = null;
      this.$nextTick(function(){
        this.init();
      }.bind(this))
    },
    async onClickLinkedId(linkedId) {
      let noticeStore = this.$store.state.notice
      noticeStore.linkedId = linkedId
      this.$store.commit("setNotice", noticeStore)
      let projectId = linkedId.split("#")[1]

      this.$router.push({
        path: `/projectMain`,
        query: { id: `${projectId}` },
      });
    },
    closeNotice(isCanceled) {
      console.log('通知モーダルクローズ');
      this.showEditModal = false;

      if (!isCanceled) {
        this.getNoticeList();
      }
    },
    showLoader(isShow) {
      if (isShow) {
        this.loader = this.$loading.show();
      } else {
        if (this.loader === null) {
          return;
        }
        this.loader.hide();
        this.loader = null;
      }
    },
  }
};
</script>
