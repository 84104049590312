<template>
  <div v-if="!loadwait" >
    <div class="container-fluid" v-if="measureDetailData.rebarmeasure">
      <div class="row">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0)" class="text-rebarprimary" @click="toList">測定データ一覧</a>
            </li>
            <li class="breadcrumb-item" >
              {{ measureDetailData.rebarmeasure.shootingSpot }}
            </li>
          </ol>
        </nav>
      </div>

      <!-- <Form as="el-form" @submit="onSubmit" :validation-schema="schemas"> -->
      <div class="row text-left no-gutters">
        <div class="col-md-3">
          <div id="baseData">
            <div class="subContent">
              <!-- <div class="row mt-3">
                
                <input
                  type="text"
                  v-model="dupuricateNum"
                  id="dupuricateNum"
                  class="form-control"
                />
                <button type="button" class="btn btn-rebarprimary w-100" @click="doDupuricate">複製</button>
              </div>
              <div class="row mt-3">
                <div class="col-sm-12">
                  <h4 class="content-title border-bottom border-dark">
                    測定タイプ
                  </h4>
                </div>
              </div> -->
              <div class="row mt-1 ml-1">
                <div class="col-12">
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="importType0"
                      v-model="measureDetailData.rebarmeasure.importType"
                      value="0"
                    />
                    <label class="form-check-label" for="importType0"
                      >実測値</label
                    >
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="importType1"
                      v-model="measureDetailData.rebarmeasure.importType"
                      value="1"
                    />
                    <label class="form-check-label" for="importType1"
                      >立会値</label
                    >
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm-12">
                  <h4 class="content-title border-bottom border-dark">
                    基本データ
                  </h4>
                </div>
              </div>
              <div class="row pb-1 pt-1 mb-1">
                <div class="col-xl-3 itemTitle">工事名</div>
                <div class="col-xl-9">
                  <div class="autoIndent">
                    {{ measureDetailData.rebarmeasure.constructionName }}
                  </div>
                </div>
              </div>
              <div class="row pb-1 pt-1 mb-1">
                <div class="col-xl-3 itemTitle">請負会社</div>
                <div class="col-xl-9">
                  <div class="autoIndent">{{ measureDetailData.rebarmeasure.company }}</div>
                </div>
              </div>
              <div class="row pb-1 pt-1 mb-1">
                <div class="col-xl-3 itemTitle">測定者</div>
                <div class="col-xl-9">
                  <div class="autoIndent">
                    <input
                      type="text"
                      v-model="measureDetailData.rebarmeasure.measurerName"
                      id="measurerName"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row pb-1 pt-1 mb-1">
                <div class="col-xl-3 itemTitle">工種</div>
                <div class="col-xl-9">
                  <div class="autoIndent">
                    {{ measureDetailData.rebarmeasure.constructionType }}
                  </div>
                </div>
              </div>
              <div class="row pb-1 pt-1 mb-1">
                <div class="col-xl-3 itemTitle">測点</div>
                <div class="col-xl-9">
                  <div class="autoIndent">
                    {{ measureDetailData.rebarmeasure.shootingSpot }}
                  </div>
                </div>
              </div>
              <div class="row pb-1 pt-1 mb-1">
                <div class="col-xl-3 itemTitle">種別</div>
                <div class="col-xl-9">
                  <div class="autoIndent">
                    <select
                      v-model="measureDetailData.rebarmeasure.type"
                      class="w-100 form-control"
                    >
                      <option
                        v-for="type in typelist"
                        v-bind:value="type.id"
                        v-bind:key="type.name">
                        {{ type.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row pb-1 pt-1 mb-1">
                <div class="col-xl-3 itemTitle">詳細</div>
                <div class="col-xl-9">
                  <div class="autoIndent">
                    <input
                      type="text"
                      v-model="measureDetailData.rebarmeasure.detailNote"
                      id="detailNote"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row pb-1 pt-1 mb-1">
                <div class="col-xl-3 itemTitle">備考</div>
                <div class="col-xl-9">
                  <div class="autoIndent">
                    <input
                      type="text"
                      v-model="measureDetailData.rebarmeasure.memo"
                      id="memo"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="subContent">
              <div class="row">
                <div class="col-sm-12">
                  <button
                    type="button submit"
                    class="btn btn-rebarprimary w-100"
                    @click="onSubmit"
                  >
                    保存
                  </button>
                </div>
              </div>
              <!-- <div class="row">
                    <div class="col-sm-12">
                      <input type="file" ref="input" id="file" @change="privateUploadTest" /> <br />
                    </div>
                  </div> -->
            </div>
          </div>
        </div>

        <div class="col-md-9">
          <div class="measureDetail">
            <div class="row">
              <div class="col-sm-12">
                <div class="subContent">
                  <div class="row mt-2 ml-1">
                    <div class="col-12">
                      <h4 class="content-title border-bottom border-dark">
                        測定画像
                      </h4>
                    </div>
                  </div>
                  <div class="row mt-2 ml-1">
                    <div class="col-sm-12">
                      <div class="row m-1 mt-2">
                        <!-- <el-image
                          v-for="thumb in thumbs"
                          :key="thumb"
                          style="max-width:400px;"
                          class="m-1 col-6 col-sm-12"
                          :src="thumb"
                          :preview-src-list="images"
                          :initial-index=1
                          :fit="`contain`">
                        </el-image> -->
                        <div class="col-6" v-for="(thumb, index) in thumbs" :key="thumb">
                          <el-image
                            style="max-width:400px;"
                            :src="thumb"
                            :initial-index="index"
                            :preview-src-list="images"
                            :fit="`contain`">
                          </el-image><br />
                          
                          <base-button type="rebarprimary" @click="downloadImageUrl(index)" v-if="downloadedFlgs[index] ?? true">
                            <i class="fas fa-download"></i>ダウンロード
                          </base-button>
                          <base-button type="success" @click="downloadImage(index)" v-else disabled>
                            <i class="fas fa-check"></i>ダウンロード済み
                          </base-button>
                        </div>
                        <!--
                        <div
                          class="col-6 col-sm-12"
                          v-for="image in images"
                          v-bind:key="image"
                        >
                          <img
                            v-bind:src="image"
                            style="max-width: 400px"
                            class="m-1"
                          />
                        </div>
                        -->
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2 ml-1">
                    <div class="col-sm-12">詳細個所名</div>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        id="detailSpot"
                        v-model="measureDetailData.rebarmeasure.detailSpot"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="measureType == '0'">
              <div class="col-sm-12">
                <div class="subContent">
                  <div class="row mt-3 ml-1">
                    <div class="col-sm-12">
                      <h4 class="content-title border-bottom border-dark">
                        設計値
                      </h4>
                    </div>
                  </div>
                  <div class="row mt-2 ml-1">
                    <div class="col-sm-12">
                      <div class="row mb-2">
                        <div class="col-sm-2">規格値</div>
                        <div class="col-sm-4">
                          <!-- <input type="text" id="designRangeClass" v-model="measureDetailData.rebarmeasure.designRangeClass" class="form-control"> -->
                          <select
                            v-model="measureDetailData.rebarmeasure.designRangeClass"
                            class="w-100 form-control"
                          >
                            <option
                              v-for="designRange in designRangeList"
                              v-bind:value="designRange.id"
                              v-bind:key="designRange.name"
                            >
                              {{ designRange.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-2">社内規格値</div>
                        <div class="col-sm-4">
                          <!-- <input type="text" id="localDesignRangeClass" v-model="measureDetailData.rebarmeasure.localDesignRangeClass" class="form-control"> -->
                          <select
                            v-model="measureDetailData.rebarmeasure.localDesignRangeClass"
                            class="w-100 form-control"
                          >
                            <option
                              v-for="designRange in localDesignRangeList"
                              v-bind:value="designRange.id"
                              v-bind:key="designRange.name"
                            >
                              {{ designRange.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-2">有効高さ</div>
                        <div class="col-sm-6">
                          <input
                            class="form-check-input ml-1"
                            type="checkbox"
                            v-model="measureDetailData.rebarmeasure.isMinusValidlyheight"
                            id="isMinusValidlyheight"
                          />
                          <label
                            class="form-check-label ml-4"
                            for="isMinusValidlyheight"
                            >有効高さがマイナスの場合はチェックON</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="measureType == '1'">
              <div class="col-sm-12">
                <div class="subContent">
                  <div class="row mt-3 ml-1">
                    <div class="col-sm-12">
                      <h4 class="content-title border-bottom border-dark">
                        設計値
                      </h4>
                    </div>
                  </div>
                  <div class="row mt-2 ml-1">
                    <div class="col-sm-12">
                      <div class="row mb-2">
                        <div class="col-sm-2">規格値</div>
                        <div class="col-sm-4">
                          <!-- <input type="text" id="designRangeClass" v-model="measureDetailData.rebarmeasure.designRangeClass" class="form-control"> -->
                          <select
                            v-model="measureDetailData.rebarmeasure.designRangeClass"
                            class="w-100 form-control"
                          >
                            <option
                              v-for="designRange in kaburiDesignRangeList"
                              v-bind:value="designRange.id"
                              v-bind:key="designRange.name"
                            >
                              {{ designRange.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-2" v-if="measureDetailData.rebarmeasure.designRangeClass && measureDetailData.rebarmeasure.designRangeClass != '0' && measureDetailData.rebarmeasure.designRangeClass != '4'">
                        <div class="col-sm-2">規格値(形式選択の○の値)</div>
                        <div class="col-sm-4">
                          <input
                            type="text"
                            id="designRangeValue"
                            v-model="measureDetailData.rebarmeasure.designRangeValue"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-2">社内規格値</div>
                        <div class="col-sm-4">
                          <!-- <input type="text" id="localDesignRangeClass" v-model="measureDetailData.rebarmeasure.localDesignRangeClass" class="form-control"> -->
                          <select
                            v-model="measureDetailData.rebarmeasure.localDesignRangeClass"
                            class="w-100 form-control"
                          >
                            <option
                              v-for="designRange in kaburiLocalDesignRangeList"
                              v-bind:value="designRange.id"
                              v-bind:key="designRange.name"
                            >
                              {{ designRange.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-2" v-if="measureDetailData.rebarmeasure.localDesignRangeClass && measureDetailData.rebarmeasure.localDesignRangeClass != '0' && measureDetailData.rebarmeasure.localDesignRangeClass != '4'">
                        <div class="col-sm-2">社内規格値(形式選択の○の値)</div>
                        <div class="col-sm-4">
                          <input
                            type="text"
                            id="rebar1Memo"
                            v-model="measureDetailData.rebarmeasure.localDesignRangeValue"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="measureType == '2'">
              <div class="col-sm-12">
                <div class="subContent">
                  <div class="row mt-3 ml-1">
                    <div class="col-sm-12">
                      <h4 class="content-title border-bottom border-dark">
                        設計値
                      </h4>
                    </div>
                  </div>
                  <div class="row mt-2 ml-1">
                    <div class="col-sm-12">
                      <div class="row mb-2">
                        <div class="col-sm-2">規格値</div>
                        <div class="col-sm-4">
                          <input
                            type="text"
                            id="designRangeValue"
                            v-model="measureDetailData.rebarmeasure.designRangeValue"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-2">社内規格値</div>
                        <div class="col-sm-4">
                          <input
                            type="text"
                            id="designRangeValue"
                            v-model="measureDetailData.rebarmeasure.localDesignRangeValue"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 配筋間隔データA表示 -->
            <el-form :model="measureDetailData.rebarmeasure" :rules="rules" ref="form">
              <div class="row" id="measure_no1" v-if="isShowDiameterList1">
                <div class="col-sm-12">
                  <div class="subContent">
                    <div class="row no-gutters">
                      <div class="col-sm-3">
                        <div class="row mt-3 mb-2 ml-1">
                          <div class="col-sm-12">
                            <h4 class="content-title">
                              データA(<span style="color: #ff0000">赤</span>)
                            </h4>
                          </div>
                        </div>
                        <div class="row mb-2 ml-1">
                          <div class="col-lg-5">鉄筋名</div>
                          <div class="col-lg-7">
                            <input
                              type="text"
                              id="rebar1Memo"
                              v-model="measureDetailData.rebarmeasure.rebar1Memo"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row mb-2 ml-1">
                          <div class="col-lg-5">鉄筋番号</div>
                          <div class="col-lg-7">
                            <input
                              type="text"
                              id="rebar1No"
                              v-model="measureDetailData.rebarmeasure.rebar1No"
                              class="form-control"
                            />
                          </div>
                        </div>

                        <div class="row mb-2 ml-1">
                          <div class="col-lg-5">設計径</div>
                          <div class="col-lg-7">
                            <!-- <input type="text" id="rebar1DiameterDesign" v-model="measureDetailData.rebarmeasure.rebar1DiameterDesign" class="form-control"> -->
                            <select
                              v-model="measureDetailData.rebarmeasure.rebar1DiameterDesign"
                              class="w-100 form-control"
                            >
                              <option
                                v-for="diameter in diameterList"
                                v-bind:value="diameter.value"
                                v-bind:key="diameter.name"
                              >
                                {{ diameter.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2 ml-1">
                          <div class="col-lg-5">設計間隔</div>
                          <div class="col-lg-7 type0">
                            <!-- <el-form :model="measureDetailData.rebarmeasure" :rules="rules" ref="form"> -->
                              <el-form-item prop="rebar1PitchDesign">
                                <el-input
                                  size="mini"
                                  placeholder=""
                                  v-model="measureDetailData.rebarmeasure.rebar1PitchDesign"
                                />
                              </el-form-item>
                            <!-- </el-form> -->
                            <!-- <Field v-if="false" name="rebar1PitchDesign" v-slot="{ value, field, errorMessage }">
                              <el-form-item :error="errorMessage">
                                <el-input
                                  ref="rebar1PitchDesign"
                                  class="input-validate"
                                  size="mini"
                                  placeholder=""
                                  v-bind="field"
                                  :model-value="value"
                                  v-model="measureDetailData.rebarmeasure.rebar1PitchDesign"
                                />
                              </el-form-item>
                            </Field> -->
                            <!--
                            <input
                              type="text"
                              id="rebar1PitchDesign"
                              name="rebar1PitchDesign"
                              v-model="measureDetailData.rebarmeasure.rebar1PitchDesign"
                              class="form-control"
                            />
                            <ErrorMessage name="rebar1PitchDesign" />
                            -->
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-9">
                        <div class="row mt-3">
                          <div class="col-sm-12">
                            <h4 class="content-title">測定値</h4>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <table class="float-left ml-2">
                              <tr class="tr-bar-red tr-measure">
                                <td class="td-measure"></td>
                                <td
                                  v-for="diameter in measureDetailData.rebarmeasure.diameterList1"
                                  v-bind:key="diameter.name"
                                  class="td-measure diameter-red"
                                >

                                </td>
                              </tr>
                              <tr class="tr-measure">
                                <td class="td-measure">測定間隔</td>
                                <td
                                  v-for="pitch in measureDetailData.rebarmeasure.pitchList1"
                                  v-bind:key="pitch.name"
                                  class="td-measure pitch-red"
                                >
                                  <div class="pitch-common">
                                    {{ pitch.value }}
                                  </div>
                                </td>
                                <td class="td-measure pitch-red"></td>
                              </tr>
                              <tr>
                                <td class="td-measure" colspan="100">
                                  <hr class="mt-1 mb-2" />
                                </td>
                              </tr>
                              <tr>
                                <td class="td-measure">累計間隔</td>
                                <td class="td-measure" colspan="100">
                                  {{ measureDetailData.rebarmeasure.totalPitch1 }}mm
                                </td>
                              </tr>
                              <tr>
                                <td class="td-measure">平均間隔</td>
                                <td class="td-measure" colspan="100">
                                  {{ measureDetailData.rebarmeasure.averagePitch1 }}mm
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 配筋間隔データB表示 -->
              <div class="row" id="measure_no2" v-if="isShowDiameterList2">
                <div class="col-sm-12">
                  <div class="subContent">
                    <div class="row no-gutters">
                      <div class="col-sm-3">
                        <div class="row mt-3 mb-2 ml-1">
                          <div class="col-sm-12">
                            <h4 class="content-title">
                              データB(<span style="color: #009900">緑</span>)
                            </h4>
                          </div>
                        </div>
                        <div class="row mb-2 ml-1">
                          <div class="col-lg-5">鉄筋名</div>
                          <div class="col-lg-7">
                            <input
                              type="text"
                              id="rebar2Memo"
                              v-model="measureDetailData.rebarmeasure.rebar2Memo"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row mb-2 ml-1">
                          <div class="col-lg-5">鉄筋番号</div>
                          <div class="col-lg-7">
                            <input
                              type="text"
                              id="rebar2No"
                              v-model="measureDetailData.rebarmeasure.rebar2No"
                              class="form-control"
                            />
                          </div>
                        </div>

                        <div class="row mb-2 ml-1">
                          <div class="col-lg-5">設計径</div>
                          <div class="col-lg-7 type0">
                            <!-- <input type="text" id="rebar2DiameterDesign" v-model="measureDetailData.rebarmeasure.rebar2DiameterDesign" class="form-control"> -->
                            <select
                              v-model="measureDetailData.rebarmeasure.rebar2DiameterDesign"
                              class="w-100 form-control"
                            >
                              <option
                                v-for="diameter in diameterList"
                                v-bind:value="diameter.value"
                                v-bind:key="diameter.name"
                              >
                                {{ diameter.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2 ml-1">
                          <div class="col-lg-5">設計間隔</div>
                          <div class="col-lg-7 type0">
                            <!-- <el-form :model="measureDetailData.rebarmeasure" :rules="rules" ref="form2"> -->
                              <el-form-item prop="rebar2PitchDesign">
                                <el-input
                                  size="mini"
                                  placeholder=""
                                  v-model="measureDetailData.rebarmeasure.rebar2PitchDesign"
                                />
                              </el-form-item>
                            <!-- </el-form> -->
                            <!-- <Field v-if="false" name="rebar2PitchDesign" v-slot="{ value, field, errorMessage }">
                              <el-form-item :error="errorMessage">
                                <el-input
                                  ref="rebar2PitchDesign"
                                  class="input-validate"
                                  size="mini"
                                  placeholder=""
                                  v-bind="field"
                                  :model-value="value"
                                  v-model="measureDetailData.rebarmeasure.rebar2PitchDesign"
                                />
                              </el-form-item>
                            </Field> -->
                            <!--
                            <input
                              type="text"
                              id="rebar2PitchDesign"
                              name="rebar2PitchDesign"
                              v-model="measureDetailData.rebarmeasure.rebar2PitchDesign"
                              class="form-control"
                            />
                            <ErrorMessage name="rebar2PitchDesign" />
                            -->
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-9">
                        <div class="row mt-3">
                          <div class="col-sm-12">
                            <h4 class="content-title">測定値</h4>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <table class="float-left ml-2">
                              <tr class="tr-measure">
                                <td class="td-measure"></td>
                                <td
                                  v-for="diameter in measureDetailData.rebarmeasure.diameterList2"
                                  v-bind:key="diameter.name"
                                  class="td-measure diameter-green"
                                >

                                </td>
                              </tr>
                              <tr class="tr-measure">
                                <td class="td-measure">測定間隔</td>
                                <td
                                  v-for="pitch in measureDetailData.rebarmeasure.pitchList2"
                                  v-bind:key="pitch.name"
                                  class="td-measure pitch-green"
                                >
                                  <div class="pitch-common">
                                    {{ pitch.value }}
                                  </div>
                                </td>
                                <td class="td-measure pitch-green"></td>
                              </tr>
                              <tr>
                                <td class="td-measure" colspan="100">
                                  <hr class="mt-1 mb-2" />
                                </td>
                              </tr>
                              <tr>
                                <td class="td-measure">累計間隔</td>
                                <td class="td-measure" colspan="100">
                                  {{ measureDetailData.rebarmeasure.totalPitch2 }}mm
                                </td>
                              </tr>
                              <tr>
                                <td class="td-measure">平均間隔</td>
                                <td class="td-measure" colspan="100">
                                  {{ measureDetailData.rebarmeasure.averagePitch2 }}mm
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-form>
            <!-- かぶり厚測定データ表示 -->
            <div class="row" v-if="measureType == '1'">
              <div class="col-sm-12">
                <div class="subContent">
                  <div class="row no-gutters">
                    <div class="col-sm-3">
                      <div class="row mt-3 mb-2 ml-1">
                        <div class="col-sm-12">
                          <h4 class="content-title">
                            データ
                          </h4>
                        </div>
                      </div>
                      <div class="row mb-2 ml-1">
                        <div class="col-lg-5">設計値</div>
                        <div class="col-lg-7 type1">
                          <el-form :model="measureDetailData.rebarmeasure" :rules="rules" ref="form">
                            <el-form-item prop="designValue">
                              <el-input
                                size="mini"
                                placeholder=""
                                v-model="measureDetailData.rebarmeasure.designValue"
                              />
                            </el-form-item>
                          </el-form>
                          <!-- <Field name="designValue" v-slot="{ value, field, errorMessage }">
                            <el-form-item :error="errorMessage">
                              <el-input
                                ref="designValue"
                                class="input-validate"
                                size="mini"
                                placeholder=""
                                v-bind="field"
                                :model-value="value"
                                v-model="measureDetailData.rebarmeasure.designValue"
                              />
                            </el-form-item>
                          </Field> -->
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-9">
                      <div class="row mt-3 mb-2 ml-1">
                        <div class="col-sm-12">
                          <h4 class="content-title">測定値</h4>
                        </div>
                      </div>
                      <div class="row mb-2 ml-1">
                        <div class="col-lg-2">かぶり厚</div>
                        <div class="col-lg-9">
                          {{measureDetailData.rebarmeasure.value}}mm
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 重ね継手長測定データ表示 -->
            <div class="row" v-if="measureType == '2'">
              <div class="col-sm-12">
                <div class="subContent">
                  <div class="row no-gutters">
                    <div class="col-sm-3">
                      <div class="row mt-3 mb-2 ml-1">
                        <div class="col-sm-12">
                          <h4 class="content-title">
                            データ
                          </h4>
                        </div>
                      </div>
                      <div class="row mb-2 ml-1">
                        <div class="col-lg-5">鉄筋番号1</div>
                        <div class="col-lg-7">
                          <input
                            type="text"
                            id="rebar2Memo"
                            v-model="measureDetailData.rebarmeasure.rebar1No"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="row mb-2 ml-1">
                        <div class="col-lg-5">設計径1</div>
                        
                        <div class="col-lg-7">
                          <select
                            v-model="measureDetailData.rebarmeasure.rebar1DiameterDesign"
                            class="w-100 form-control"
                          >
                            <option
                              v-for="diameter in diameterList"
                              v-bind:value="diameter.value"
                              v-bind:key="diameter.name"
                            >
                              {{ diameter.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="row mb-2 ml-1">
                        <div class="col-lg-5">鉄筋番号2</div>
                        <div class="col-lg-7">
                          <input
                            type="text"
                            id="rebar2Memo"
                            v-model="measureDetailData.rebarmeasure.rebar2No"
                            class="form-control"
                          />
                        </div>
                        
                      </div>
                      <div class="row mb-2 ml-1">
                        <div class="col-lg-5">設計径2</div>
                        
                        <div class="col-lg-7">
                          <el-select v-if="false" placeholder=""
                            v-model="measureDetailData.rebarmeasure.rebar2DiameterDesign">
                            <el-option v-for="diameter in diameterList"
                                      class="select-primary"
                                      :value="diameter.value"
                                      :label="diameter.name"
                                      :key="diameter.name">
                            </el-option>
                          </el-select>

                          <select
                            v-model="measureDetailData.rebarmeasure.rebar2DiameterDesign"
                            class="w-100 form-control"
                          >
                            <option
                              v-for="diameter in diameterList"
                              v-bind:value="diameter.value"
                              v-bind:key="diameter.name"
                            >
                              {{ diameter.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-2 ml-1">
                        <div class="col-lg-5">設計値</div>
                        <div class="col-lg-7 type2">
                          <el-form :model="measureDetailData.rebarmeasure" :rules="rules" ref="form">
                            <el-form-item prop="designValue">
                              <el-input
                                size="mini"
                                placeholder=""
                                v-model="measureDetailData.rebarmeasure.designValue"
                              />
                            </el-form-item>
                          </el-form>
                          <!-- <Field name="designValue" v-slot="{ value, field, errorMessage }">
                            <el-form-item :error="errorMessage">
                              <el-input
                                ref="designValue"
                                class="input-validate"
                                size="mini"
                                placeholder=""
                                v-bind="field"
                                :model-value="value"
                                v-model="measureDetailData.rebarmeasure.designValue"
                              />
                            </el-form-item>
                          </Field> -->
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-9">
                      <div class="row mt-3 mb-2 ml-1">
                        <div class="col-sm-12">
                          <h4 class="content-title">測定値</h4>
                        </div>
                      </div>
                      <div class="row ml-1">
                        <div class="col-sm-12" style="position:relative;">
                          <div style="position:absolute;">継手長</div>
                          <div style="width:255px;" class="text-center">{{measureDetailData.rebarmeasure.value}}mm</div>
                          <img src="./svg/jointimage.svg" class="mt-2" width="255" height="71" />  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12" style="height: 5em">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
      <!-- </Form> -->

      <div
        class="d-flex fixed-bottom justify-content-center my-box-light w-100"
        v-if="selectedMeasureDataId"
      >
        <div class="my-box w-50 bg-light rounded-top p-2 border shadow-1">
          <div class="row">
            <div class="col-5 mb-2">
              <button
                type="button"
                class="btn btn-rebarprimary w-100"
                @click="saveMeasureData"
              >
                保存
              </button>
            </div>
            <div class="col-2 mb-2">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="false"
      type="button"
      class="btn btn-primary"
      @click="createMeasureData"
    >
      保存テスト</button
    >&nbsp;
    <div v-if="false">
      <hr />
    </div>
  </div>
</template>
<style>
  .type0 .el-input__wrapper {
    box-shadow: 0 0 0 1px #999999 inset !important;
  }
  .type1 .el-input__wrapper {
    box-shadow: 0 0 0 1px #999999 inset !important;
  }
  .type2 .el-input__wrapper {
    box-shadow: 0 0 0 1px #999999 inset !important;
  }
</style>
<style lang="scss" scoped>
  .form-control {
    border:1px solid #999999 !important;
  }
  
</style>
<style scoped>
.subContent {
  border-radius: 0.25rem !important;
  background-color: white;
  margin: 5px;
  padding: 5px;
  font-size: 0.9em;
}

.content-title {
  font-weight: bold;
}

.text-with-icon-base {
  background-repeat: no-repeat;
  background-position-y: center;
  text-indent: 28px;
  background-size: 20px 20px;
}

.datalist {
  background-color: transparent;
}
.datalist:hover {
  background-color: #afefff;
}
.autoIndent {
  padding-left: auto;
}
@media (max-width: 1200px) {
  .autoIndent {
    padding-left: 1em;
  }
}
.content-title {
  font-weight: bold;
}
.breadcrumb {
  background-color: transparent;
  font-size: 0.8em;
  margin-bottom: 0;
  padding: 0.5em 2em;
}
.itemTitle {
  font-weight: bold;
}
/* .subContent{
  border-radius: .25rem!important;
  background-color: white;
  margin:5px;
  padding:5px;
  font-size: 0.9em;
} */
.detail {
  background-color: #f5f5f5;
}
.tr-measure {
  height: 2.5em;
}
.td-measure {
  position: relative;
  min-width: 60px;
}
.diameter-red {
  text-align: center;
  border-bottom: 2px solid red;
}
.diameter-red::after {
  content: "●";
  font-size: 16px;
  color: red;
  position: absolute;
  display: block;
  /* left:20px; */
  width: 100%;
  margin: 0 auto;
  border: 0px solid red;
  top: 1.5em;
}
.pitch-red {
  min-width: 50px;
}

.pitch-common {
  left: 50%;
  width: 100%;
  text-align: center;
  position: absolute;
  border-left: 0px dotted red;
  top: 0.5em;
}

.form-control {
  height: calc(1.2em + 0.5rem + 2px);
  padding: 0.2rem 0.5rem;
}
.diameter-green {
  text-align: center;
  border-bottom: 2px solid #009900;
}
.diameter-green::after {
  content: "●";
  font-size: 16px;
  color: #009900;
  position: absolute;
  display: block;
  /* left:20px; */
  width: 100%;
  margin: 0 auto;
  top: 1.5em;
}
.pitch-green {
  min-width: 50px;
}

.bar-red {
  height: 50px;
  display: inline-block;
  position: relative;
}
.bar-red::after {
  content: "";
  display: block;
  width: 100%;
  margin-top: 25px;
  position: absolute;
  border-bottom: 2px solid red;
}
.dot-red {
  position: relative;
}
.dot-red::after {
  content: "●";
  font-size: 16px;
  color: red;
  position: absolute;
  left: 20px;
  top: 13px;
}

.measureImage {
  max-width: 400px;
}
.formDataItem {
  font-size: 0.8em;
}
.rect20 {
  width: 20px;
  height: 20px;
}
.rect36 {
  width: 36px;
  height: 36px;
}
.closeButton1 {
  margin: 0;
  padding: 0;
  padding-top: -2px;
}

.measureDataContainer {
  background-color: #dcdcdc;
  margin-left: 15px;
  margin-right: 15px;
  padding: 1px 1px 0 1px;
}
.shadow-1 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.mpclear {
  margin: 0;
  padding: 0;
}
.mb-pulus1 {
  margin-bottom: 1px;
}
.ml-pulus1 {
  margin-left: 1px;
}
.mr-pulus1 {
  margin-right: 1px;
}
.measureDataTable tr {
  height: 2em;
}
.measureDataTitleRow {
  height: 2em;
  font-weight: bold;
  position: relative;
  margin-left: 0px;
  margin-right: 1px;
}
.measureDataTitleCol {
  background-color: white;
}
.ml-minus1 {
  margin-left: -1px;
}
.mt-minus1 {
  margin-top: -1px;
}
.diameterRow {
  height: 2em;
  margin-left: 0px;
  margin-right: 1px;
}
.diameterCol {
  height: 2em;
  background-color: white;
}
.pitchRow {
  position: relative;
  height: 2em;
  top: 1em;
  margin-left: 0px;
  margin-right: 0px;
}
.pitchCol {
  height: 2em;
  background-color: white;
}
/* input[type="text"],
select {
  border: 2px solid #aaaaaa;
} */

.modal-mask {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .input-validate .el-input {
  border: 2px solid #aaaaaa;
} */
/* .input-validate ::v-deep .el-input__inner {
  padding: 0.2rem 0.5rem;
  border: 1px solid #aaaaaa;
  font-size: 14px;
} */

</style>
<script>

import { Storage } from "aws-amplify";
import MeasureModel from "../../appModel/rebar/MeasureModel";
import { useToast } from "vue-toastification";
import DateUtil from '../../appUtils/DateUtil'
import AppLogs from '../../appUtils/AppLog'
import UserInfo from "../../appUtils/UserInfo"
import path from 'path'
import { Form, Field } from "vee-validate";
import * as yup from 'yup';
import AuthUtil from "../../appUtils/AuthUtil";
import { v4 as uuidv4 } from "uuid";
import ValidateUtil from "@/appUtils/ValidateUtil";
export default {
  components: {
    
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init();
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  setup() {
    //.positive('正の値で入力してください'),
    // const schemas = yup.object({
    //   rebar1PitchDesign: yup.number()       
    //     .min(0, '正の値で入力してください')
    //     .typeError('数値で入力してください'),
    //   rebar2PitchDesign: yup.number().min(0)
    //     .min(0, '正の値で入力してください')
    //     .typeError('数値で入力してください'),
    // });

    const schemas = yup.object({
      rebar1PitchDesign: yup.string().matches(/^[0-9]*$/, '数値(正の値)で入力してください'),
      rebar2PitchDesign: yup.string().matches(/^[0-9]*$/, '数値(正の値)で入力してください'),
      designValue: yup.string().matches(/^[0-9]*$/, '数値(正の値)で入力してください'),
    });
    
    return {
      schemas,
    };
  },
  data() {
    return {
      dupuricateNum: 10,
      loadwait: true,
      isLoading: false,
      loginUserName: "",
      images: [],
      thumbs: [],
      isShowDiameterList1: false,
      isShowDiameterList2: false,
      sk: "",
      designRangeList: [
        { id: "", name: "未設定" },
        { id: "0", name: "±Φ(設計径)" },
        { id: "1", name: "±10" },
        { id: "2", name: "±20" },
        { id: "3", name: "±20(±10)" },
      ],
      localDesignRangeList: [
        { id: "0", name: "未設定" },
        { id: "1", name: "±Φ(設計径)" },
        { id: "2", name: "規格値の80%" },
        { id: "3", name: "規格値の90%" },
        { id: "4", name: "規格値の80%(±10)" },
        { id: "5", name: "規格値の90%(±10)" },
      ],
      kaburiDesignRangeList: [
        { id: "0", name: "未設定"},
        { id: "1", name: "±○mmかつ最小かぶり以上"},
        { id: "2", name: "0以上+○mm以内"},
        { id: "3", name: "±○mm以内"},
        { id: "4", name: "設計値以上"}
      ],
      kaburiLocalDesignRangeList: [
        { id: "0", name: "未設定"},
        { id: "1", name: "±○mmかつ最小かぶり以上"},
        { id: "2", name: "0以上+○mm以内"},
        { id: "3", name: "±○mm以内"},
        { id: "4", name: "設計値以上"}
      ],
      typelist: [
        { id: "0", name: "鉄筋工" },
        { id: "1", name: "床版工" },
      ],
      diameterList: [
        { value: "10", name: "D10" },
        { value: "13", name: "D13" },
        { value: "16", name: "D16" },
        { value: "19", name: "D19" },
        { value: "22", name: "D22" },
        { value: "25", name: "D25" },
        { value: "29", name: "D29" },
        { value: "32", name: "D32" },
        { value: "35", name: "D35" },
        { value: "38", name: "D38" },
        { value: "41", name: "D41" },
        { value: "51", name: "D51" },
      ],
      measureDetailData: {},
      downloadedFlgs: {},
      measureType: "0", // 0:配筋間隔 1:かぶり 2:重ね継手長
      rules: {
        rebar1PitchDesign: [
          { required: false, validator: this.rebar1PitchDesign, trigger: "blur" },
        ],
        rebar2PitchDesign: [
          { required: false, validator: this.rebar2PitchDesign, trigger: "blur" },
        ],
        designValue: [
          { required: false, validator: this.designValue, trigger: "blur" },
        ]
      },
    };
  },
  watch: {},
  /**
   * コンピュートプロパティ
   */
  computed: {},
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    // async doDupuricate() {
    //   let data = this.measureDetailData
    //   let num = this.dupuricateNum
    //   await this.dupuricate(data, num)
    // },
    // async dupuricate(data, num) {
    //   for (let i = 0;i < num;i++){
    //     let newData = { ...data }
    //     newData.sk = `measurement#${uuidv4()}`
    //     newData.id = uuidv4();
    //     newData.rebarmeasure.shootingSpot = `Copy ${i}`
    //     console.log(`dupuricate ${i} : ${JSON.stringify(newData)}`)
    //     await MeasureModel.addMeasure(newData)
    //   }
    // },

    async init() {
      await this.outputLog(`info`, `start Detail. ${this.$route.query.id}`)

      this.sk = this.$route.query.id

      // 測定タイプ判定
      let skList = this.sk.split("#")
      if (skList.length >= 3) {
        this.measureType = skList[1]
      }
      
      this.measureDetailData = await MeasureModel.getSingleData({ sk: this.sk })

      // this.measureDetailData = this.$store.state.measureDetailData;

      //測定データ（赤）が存在する場合は表示するフラグをtrueに変更する
      if (this.measureDetailData.rebarmeasure.diameterList1) {
        this.isShowDiameterList1 = this.measureDetailData.rebarmeasure.diameterList1.length > 0
      }
      // 測定データ（緑）が存在する場合は表示するフラグをtrueに変更する
      if (this.measureDetailData.rebarmeasure.diameterList2) {
        this.isShowDiameterList2 = this.measureDetailData.rebarmeasure.diameterList2.length > 0
      }
      // 測定写真のリスト
      let list = []
      // 画像取得
      for (let i = 0; i < this.measureDetailData.rebarmeasure.imageList.length; i++){
        if (list.length == 0){
          // フォルダ内のファイル一覧を取得する
          const dir = path.dirname(this.measureDetailData.rebarmeasure.imageList[i].s3key)
          list = await Storage.list(dir, {level: "private"})
        }
        // オリジナル画像を取得(プレビュー用)
        let orgPath = this.measureDetailData.rebarmeasure.imageList[i].s3key;
        //DB上で画像svgとなっている場合はpngファイルを参照するように読み替え
        let file_type = path.extname(orgPath);
        
        if (file_type == ".svg")
        {
          let file_name=path.dirname(orgPath);
          const filename = path.basename(orgPath, file_type);
          orgPath=path.join(file_name, filename+ ".png");
        }

        let imageUrl = null;
        try {
          imageUrl = await Storage.get(orgPath, {level: "private"})
        } catch (e) {
          
          console.log(e)
        }
        
        if (imageUrl) {
          this.images.push(imageUrl);

          // サムネイルを取得
          let thumbPath = path.join(path.dirname(orgPath), `thumb-${path.basename(orgPath)}`);
          
          // サムネイルがリストに存在するかチェック
          const findResult = list.find((item) => {
            return item.key == thumbPath
          })
          if (findResult) {
            // 存在する
            const thumbUrl = await Storage.get(thumbPath, {level: "private"})
            this.thumbs.push(thumbUrl);
          } else {
            // 存在しない
            // オリジナル画像を登録
            this.thumbs.push(imageUrl);
          }
        }

      }
      this.loadwait = false
    },
    async onSubmit (values, actions) {

      // すべての入力値が正常だった場合のみ呼び出される
      // console.log(`onSubmit`);
      
      // １つ目のフォームのバリデーション(必ず存在)
      this.$refs.form.validate(async function(result) {
        console.log(`onSubmit result:${result}`)
        if (!result) {
          this.showBottomToast(`入力内容にエラーがあります`, `error`)
          return
        } else {
          // actions.resetForm();
          // セッション切れ確認
          let isValid = await AuthUtil.isValidSession();
          if (!isValid) {
            AuthUtil.alert();

            // セッションなし -> ルートへリダイレクト
            this.$router.push({
              path: `/`
            })
            return;
          }

          // 保存処理
          this.saveMeasureData()
        }
      }.bind(this))
      

      
    },
    async saveMeasureData() {
      try {

        const expectedTimestamp = this.measureDetailData.timestamp
        // タイムスタンプを更新
        let condition = {}
        condition.and = [
          {
            "timestamp": { "eq": expectedTimestamp}
          }
        ]
        this.measureDetailData.timestamp = DateUtil.getUnixTimeStampOfMilliSecond()
        //AppSyncで更新
        await MeasureModel.updateData(this.measureDetailData, condition)
        this.showBottomToast(`保存しました`, `info`)
        await this.outputLog(`info`, `更新  pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
        // AppLogs.warnLog(`更新  pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
      } catch (e) {
        if (e.errors) {
          if (e.errors.length > 0) {
            switch (e.errors[0].errorType) {
            case "DynamoDB:ConditionalCheckFailedException":
              this.showBottomToast(`他のユーザにて更新されています。`, `warning`)
              await this.outputLog(`warn`, `競合 pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
              // AppLogs.warnLog(`競合 pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)

              // リストへ遷移
              this.toList();
              break
            default:
              await this.outputLog(`error`, `保存に失敗 error:${JSON.stringify(e)} pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
              await this.outputLog(`error`, `保存に失敗 error:${JSON.stringify(e)} pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
              // AppLogs.warnLog(`保存に失敗 error:${JSON.stringify(e)} pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
              break
            }
          } else {
            this.showBottomToast(`保存に失敗しました`, `error`)
            await this.outputLog(`error`, `保存に失敗 error:${JSON.stringify(e)} pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
          }

        } else {
          this.showBottomToast(`保存に失敗しました`, `error`)
          await this.outputLog(`error`, `保存に失敗 error:${JSON.stringify(e)} pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
          // AppLogs.warnLog(`保存に失敗 error:${JSON.stringify(e)} pk:${this.measureDetailData.pk} sk:${this.measureDetailData.sk}`)
        }

      }
    },
    onClickNewTenant() {},
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },
    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos
      });
    },
    toList() {
      this.outputLog(`info`, `go to List`)

      this.$router.push({
        name: 'List'
      })
    },
    /**
     * ログインユーザ―IDを取得します。
     * @returns ログインユーザ―ID
     */
    async getUserId() {
      let id = '';

      let userInfo = await UserInfo.getUserInfo();
      id = userInfo.userName;

      return id;
    },

    /**
     * ログを出力します。
     * @param {string} level ログレベル
     * @param {string} message 内容
     */
    async outputLog(level, message) {
      const view = `Detail.vue`
      let user = await this.getUserId();

      switch (level) {
      case `debug`:
        AppLogs.debugLog(view, user, message)
        break;
      case `info`:
        AppLogs.infoLog(view, user, message)
        break;
      case `warn`:
        AppLogs.warnLog(view, user, message)
        break;
      case `error`:
        AppLogs.errLog(view, user, message)
        break;
      }
    },
    async downloadImageUrl(index) {
      // 対象ファイルのs3keyを取得
      const s3key = this.measureDetailData.rebarmeasure.imageList[index].s3key
      // 親フォルダからファイル一覧を取得して存在チェック
      const dir = path.dirname(s3key)      
      const list = await Storage.list(dir, {level: "private"})
      const findResult = list.find((item) => {
        return item.key == s3key
      })
      if (!findResult) {
        // 存在しない
        this.showBottomToast(`対象のファイルが存在しません。`, `error`)
        return 
      }
      const url = await Storage.get(s3key, { level: "private" });
      const fileName = path.basename(s3key)
      
      // ブラウザにダウンロードダイアログを表示する処理
      const a = document.createElement('a');

      a.href = url
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      this.downloadedFlgs[index] = false
    },
    /**
     * データA設計間隔のバリデーションメソッド
     */
    rebar1PitchDesign(rule, value, callback) {
      if (ValidateUtil.checkBase(value, callback, 50, false, false, false, false, true)) {
        callback();
      }
    },
    /**
     * データB設計間隔のバリデーションメソッド
     */
    rebar2PitchDesign(rule, value, callback) {
      if (ValidateUtil.checkBase(value, callback, 50, false, false, false, false, true)) {
        callback();
      }
    },
    designValue(rule, value, callback) {
      if (ValidateUtil.checkBase(value, callback, 50, false, false, false, false, true)) {
        callback();
      }
    }
  },
};
</script>
