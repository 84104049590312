<template>
  <!-- <bread-crumb list-classes="breadcrumb-links breadcrumb-dark"> -->
  <bread-crumb list-classes="breadcrumb-links ">
    <BreadCrumbItem>
      <li class="breadcrumb-item">
        <router-link to="/">
          <i class="fas fa-home"></i>
        </router-link>
      </li>
    </BreadCrumbItem>
    <BreadCrumbItem
      v-for="(route, index) in $route.matched.slice()"
      :key="route.name"
      :active="index === $route.matched.length - 1"
      style="display: inline-block"
    >
      <router-link
        :to="{ name: route.name }"
        v-if="index < $route.matched.length - 1"
      >
      {{ getBreadName(route) }}
        <!-- {{ route.title ? route.title : route.name }} -->
      </router-link>
      <span v-else>{{ getBreadName(route) }} {{title}}</span>
    </BreadCrumbItem>
  </bread-crumb>
</template>

<script>
import BreadCrumb from "./Breadcrumb";
import BreadCrumbItem from "./BreadcrumbItem";

export default {
  name: "route-breadcrumb",
  components: {
    BreadCrumb,
    BreadCrumbItem,
  },
  computed: {

  },
  methods: {
    getBreadName(route) {

        //alert(JSON.stringify(route))
        switch(route.name) {
            case "Dashboards":
                return "ダッシュボード"
            case "Projects":
                return "プロジェクト一覧"
            case "NewProject":
                return "プロジェクト作成"
            case "EditProject":
                return "プロジェクト編集"
            case "ProjectMain":
                return "プロジェクト"
            case "MeetingMain":
                return "ミーティング一覧"
            case "MeetingClient":
                return "ミーティング開始"
            case "NoticeList":
                return "通知一覧"
            default:
                return route.name
        }
      return route.name;
    },
  },
};
</script>

<style scoped></style>
