<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-success navbar-dark': type === 'default' }"
  >
    <!-- Search form -->
    <!-- <form
      class="navbar-search form-inline mr-sm-3"
      :class="{
        'navbar-search-light': type === 'default',
        'navbar-search-dark': type === 'light',
      }"
      id="navbar-search-main"
    >
      <div class="form-group mb-0">
        <div class="input-group input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <input class="form-control" placeholder="Search" type="text" />
        </div>
      </div>
      <button
        type="button"
        class="close"
        data-action="search-close"
        data-target="#navbar-search-main"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </form> -->
    <h2>{{appTitle}}</h2>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none" v-if="appType=='mcz'">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>

      <base-dropdown
        menu-classes="dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right"
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link"
        icon="ni ni-ungroup"
        v-if="appType=='mcz'"
      >
        <div class="row shortcuts px-4">
          <a @click="onClickTodo"  class="col-6 shortcut-item">
            <span class="shortcut-media avatar rounded-circle bg-gradient-red">
              <i class="fa fa-tasks"></i>
            </span>
            <small>ToDo</small>
          </a>
          <a @click="onClickMeeting" class="col-6 shortcut-item">
            <span
              class="shortcut-media avatar rounded-circle bg-gradient-orange"
            >
              <i class="fa fa-video"></i>
            </span>
            <small>ミーティング</small>
          </a>

        </div>
      </base-dropdown>
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="text-rebarprimary nav-link pr-0" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle far fa-user">
                <!-- <img alt="Image placeholder" src="img/theme/team-4.jpg" /> -->
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{userData.name}}</span>
              </div>
            </div>
          </a>
        </template>

        <a class="dropdown-item" @click="onClickAccount">
          <i class="far fa-id-card"></i>契約情報</a>
        <a class="dropdown-item" @click="toManual">
          <i class="fa fa-question-circle"></i>ユーザーマニュアル</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" @click="signOut">
          <i class="fa fa-door-open"></i>ログアウト</a>
      </base-dropdown>
    </ul>
  </base-nav>
  <div class="container-fluid">
      <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0)" class="text-rebarprimary" @click="toManual">ユーザーマニュアル</a>
          </li>
        </ol>
      </nav> -->
    </div>
</template>
<style>
@import "../Rebar/css/common.css";
</style>
<style scoped>
  .breadcrumb {
    background-color: transparent;
    font-size: 0.8em;
    margin-bottom: 0;
    padding: 0.5em 0.8em;
  }

</style>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import { Auth } from "aws-amplify";
import UserModel from "../../appModel/users/UserModel"
import UserInfo from "../../appUtils/UserInfo";
import appLog from "../../appUtils/AppLog"

export default {
  components: {
    BaseNav,
  },
  props: {
    appType: "",
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  mounted(){
    this.initNavbarData().then(() => {
      console.log('initNavbarData')
    })
  },
  data() {
    return {
      userData: {},
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      appTitle: ""
    };
  },
  methods: {
    onClickTodo() {
      let todoStore = this.$store.state.todo
      todoStore.drawer = !todoStore.drawer
      this.$store.commit('setTodo',todoStore)

    },
    onClickMeeting() {
      this.$router.push({
        name: 'MeetingMain'
      })
    },
    async initNavbarData() {
      // let result = await Auth.currentUserInfo()
      // alert(JSON.stringify(result,null,"\t"))
      try {
        this.appTitle = process.env.VUE_APP_TITLE

        let userinfo = await UserInfo.getUserInfo()

        //const userId = userinfo.username
        const pk = userinfo.group
        const sk = UserModel.createSk(userinfo.sub)
        //alert(`pk:${pk}, sk:${sk}`)
        const userData = await UserModel.getUser({pk: pk, sk: sk})
        //alert(JSON.stringify(userData,null,"\t"))
        this.userData = userData.user
      } catch (e) {
        console.log(`ユーザ情報取得エラー`)
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    async signOut() {
      // appLog.infoLog("RebarNavbar.vue", this.userData.userId, `Sign out.`)
      appLog.infoLogAsync("RebarNavbar.vue", this.userData.userId, `Sign out.`)

      Auth.signOut()
      this.$router.push({
        name: 'Signin'
      })
    },
    onClickAccount: function() {
      this.$router.push({
        name: 'Account'
      })
    },
    toManual() {
      console.log(`preparing now`)
      window.open(`./manual/index.html`, '_blank')
    }
  },
};
</script>
