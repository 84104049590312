const short = require("short-uuid");
const baseModel = require("../baseModel")

/**
 * プロジェクト用のデータ取得モデルクラス
 */
class ProjectModel extends baseModel {
  /**
   * テナント内のプロジェクトリストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getProjectList() {
    return await super.getList({ beginsWith: "project" })
  }

  /**
   * プロジェクトを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteProject(data) {
    await super.deleteData(data)
  }

  /**
   * プロジェクト管理レコードを追加する
   * @param {*} data
   */
  static async addProject(data) {
    await super.addData(data)
  }

  /**
   * プロジェクト管理レコードを更新する
   * @param {*} data
   */
  static async updateProject(data) {
    await super.updateData(data)
  }

  /**
   * プロジェクト管理レコードを取得する
   * @param {*} data
   */
  static async getProject(data) {
    return await super.getSingleData(data);

  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(id) {
    return `project#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData() {
    let newRowData = await super.getNewData()
    
    const id = short.generate()
    newRowData.sk = this.createSk(id)
    
    newRowData.project = {
      id: id,
      name: "",
      options: [],
      users: [],
    }

    return newRowData
    
  }
}

module.exports = ProjectModel;
