const short = require("short-uuid");
const baseModel = require("../baseModel")
const { API, graphqlOperation } = require("aws-amplify");
const {
  createMapletFSCloudData,
  deleteMapletFSCloudData,
  updateMapletFSCloudData,
} = require("@/graphql/mutations");
const {
  listMapletFSCloudDatas,
  getMapletFSCloudData,
} = require("@/graphql/queries");

/**
 * ミーティング用のデータ取得モデルクラス
 */
class MeetingModel extends baseModel{
  /**
   * テナント内のミーティングリストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getMeetingList(tenantId) {
    return await super.getList({ beginsWith: "meeting" });
  }

  /**
   * ミーティングを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteMeeting(data) {
    await super.deleteData(data)
  }

  /**
   * ミーティング管理レコードを追加する
   * @param {*} data
   */
  static async addMeeting(data) {
    await super.addData(data)
  }

  /**
   * ミーティング管理レコードを取得する
   * @param {*} data
   */
  static async getMeeting(data) {
    return await super.getSingleData(data);
  }

  /**
   * skの文字列を生成
   * @param {*} loginUser
   * @param {*} meetingId
   * @returns
   */
  static createSk(loginUser) {
    return `meeting#${loginUser}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(loginUser) {
    let newRowData = await super.getNewData()
    //const id = short.generate()
    const sk = this.createSk(loginUser)
    newRowData.sk = sk
    newRowData.meeting = {
      title: "",
      roomId: loginUser,
      meetingId: "",
      externalMeetingId: "",
      audioHostUrl: "",
      audioFallbackUrl: "",
      screenDataUrl: "",
      screenSharingUrl: "",
      screenViewingUrl: "",
      signalingUrl: "",
      turnControlUrl: "",
      mediaRegion: "",
      startDateTime: "",
      invitedUsers: "",
      ttl: 0,
    }
    return newRowData
  }
}

module.exports = MeetingModel;
