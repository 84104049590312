const short = require('short-uuid');
const baseModel = require("../baseModel")
const UserInfo = require('../../appUtils/UserInfo');

/**
 * ツリー用のデータ取得モデルクラス
 */
class TreeModel extends baseModel {
  /**
   * テナント内のツリーリストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getTreeList(tenantId) {

    return await super.getList({beginsWith:"tree#"})
  }

  /**
   * ツリーを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteTree(data) {
    await super.deleteData(data)
  }

  /**
   * ツリー管理レコードを追加する
   * @param {*} data
   */
  static async addTree(data){
    await super.addData(data)
  }

  /**
   * ツリー管理レコードを更新する
   * @param {*} data
   */
  static async updateTree(data) {
    await super.updateData(data)
  }

  /**
   * ツリー管理レコードを取得する
   * @param {*} data
   */
  static async getTree(projectId){
    const pk = (await UserInfo.getUserInfo()).group
    const data = {pk: pk, sk: this.createSk(projectId)}
    let treeData = await super.getSingleData(data);
    
    // 検査マスタが無ければ追加する
    if (treeData) {
      if (treeData.rootItem.children.length == 0) {
        treeData.rootItem.children.unshift(this.getInspectMasterNode())
      } else {
        if (treeData.rootItem.children[0].type != "inspectMaster"){
          treeData.rootItem.children.unshift(this.getInspectMasterNode())
        }
      }
    }
    


    return treeData
    // const loginInfo = await UserInfo.getUserInfo()
    // if(!loginInfo.group) {
    //     return null
    // }
    // const tenantId = loginInfo.group
    // const sk = `tree#${projectId}`

    // let result = await API.graphql(graphqlOperation(getMapletFSCloudData,{pk:tenantId,sk:sk}))
    // return result.data.getMapletFSCloudData

  }

  /**
   * ツリー項目に階層レベルを追加します。
   * @param {*} treeItem 1つのツリー項目
   * @param {Number} depthLv 階層レベル(ルートは0)
   * @returns 階層レベルを追加した1つのツリー項目
   */
  static async getDepthLevel(treeItem, depthLv) {
    // 返却用に複製
    let result = Object.assign({}, treeItem);

    // console.log(treeItem)

    // 現在の階層レベルを追加
    result['depthLv'] = depthLv;

    // 階層レベルインクリメント
    depthLv++;

    // 子階層処理
    let children = result.children;
    if (!children || children.length == 0) {
      // 子階層なし
      // console.log(result)
      return result;
    } else {
      for (let i = 0; i < children.length; i++) {
        // 再帰
        result.children[i] = await this.getDepthLevel(children[i], depthLv)
      }

    }

    // console.log(result)
    return result;
  }
  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId){
    return `tree#${projectId}`
  }
  static getInspectMasterNode() {
    let inspectMaster = {
      label: "検査マスタ",
      id: "inspectMaster",
      type: "inspectMaster",
      path: "",
      order: 0,
      deleteFlag: false,
      fileSize: 0,
      createDate: "",
      createUser: "",
      updateDate: "",
      updateUser: "",
      fileList: [],
    };
    return inspectMaster
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId) {
    let newRowData = await super.getNewData()

    newRowData.sk = this.createSk(projectId)
    newRowData.rootItem = {
      label: "",
      id: `${projectId}`,
      path: "",
      children: [],
      type: "project",
      order: 0,
      deleteFlag: false,
      fileSize: 0,
      fileList: [],
      createDate: "",
      createUser: "",
      updateDate: "",
      updateUser: "",
    }
    
    newRowData.rootItem.children.unshift(this.getInspectMasterNode())

    return newRowData
    // return {
    //     pk:`${loginInfo.group}`,
    //     sk:`${this.createSk(projectId)}`,
    //     lsiStr1:"-",
    //     lsiStr2:"-",
    //     lsiStr3:"-",
    //     lsiStr4:"-",
    //     lsiInt1:0,
    //     groups:[loginInfo.group],
    //     rootItem : {
    //         label:"",
    //         id:`${projectId}`,
    //         path:"",
    //         children:[],
    //         type:"project",
    //         order:0,
    //         deleteFlag:false,
    //         fileSize:0,
    //         fileList:[],
    //         createDate:"",
    //         createUser:"",
    //         updateDate:"",
    //         updateUser:"",
    //     }
    // }
  }
}

module.exports = TreeModel
