<template>
  <div @click.left="onleftclick">
    <div class="row align-items-center">
      <div class="col">
        <!-- <base-button outline  type="default">
                <i class="fa fa-plus"></i>新規追加
                </base-button> -->
        <base-dropdown
          ref="menuDropdown"
          :hasToggle="false"
          title-classes="btn btn-primary btn-sm"
          menuClasses="zindex-dropdown">
          <template v-slot:title>
            <!-- <i class="fa fa-plus"></i>アクション  -->
            <span class="btn-inner--icon">
              <i class="fa fa-plus"></i>
            </span>
          </template>

          <a v-bind:class="{disabled: isBackImage || isDepthLvLimit}" class="dropdown-item" @click="handlerMenuAddFolder">
            <i class="fa fa-folder-plus text-primary"></i>フォルダ追加</a>
          <div class="dropdown-divider"></div>
          <!--
          <a v-bind:class="{disabled: isBackImage}" class="dropdown-item" @click="handlerMenuAddFile">
            <i class="fa fa-file-medical text-primary"></i>ファイル追加</a>
          <div class="dropdown-divider"></div>
           -->
          <a v-bind:class="{disabled: isBackImage}" class="dropdown-item" @click="handlerMenuAddInspect">
            <i class="fas fa-notes-medical text-primary"></i>検査を追加</a>
          <!-- <a v-bind:class="{disabled: isBackImage}" class="dropdown-item" @click="handlerMenuAddBackImage">
            <i class="fa fa-layer-group text-primary"></i>背景図追加</a> -->
          <a v-bind:class="{disabled: !isBackImage || isMap}" class="dropdown-item" @click="handlerMenuBackImageSetting">
            <i class="fa fa-tools text-primary"></i>背景図設定</a>
          <div class="dropdown-divider"></div>
          <!-- <a v-bind:class="{disabled: isBackImage}" class="dropdown-item" @click="handlerMenuAddMap">
            <i class="fa fa-map-marked-alt text-primary"></i>地図追加</a> -->
          <a v-bind:class="{disabled: isProject}" class="dropdown-item" @click="handlerMenuRename">
            <i class="el-icon-edit text-primary"></i>名前の変更</a>
          <div class="dropdown-divider"></div>
          <a v-bind:class="{disabled: isProject}" class="dropdown-item" @click="handlerMenuDeleteNode"
            ><i class="fa fa-trash-alt text-primary"></i>削除</a
          >
        </base-dropdown>

        <card
          id="contextMenu"
          ref="contextMenu"
          class="dropdown-menu tree-context-menu context-menu"
          :noBody=true
          :style="context_position"
          v-show="treeContextShow">

          <a v-bind:class="{disabled: isBackImage || isDepthLvLimit}" class="dropdown-item pt-1 pb-1" @click="handlerMenuAddFolder" ><i class="fa fa-folder-plus text-primary"></i>フォルダ追加</a>
          <div class="dropdown-divider"></div>
          <!--
          <a v-bind:class="{disabled: isBackImage}" class="dropdown-item pt-1 pb-1" @click="handlerMenuAddFile" ><i class="fa fa-file-medical text-primary"></i>ファイル追加</a>
          <div class="dropdown-divider"></div>
           -->
          <a v-bind:class="{disabled: isBackImage}" class="dropdown-item pt-1 pb-1" @click="handlerMenuAddInspect"><i class="fa fa-notes-medical text-primary"></i>検査を追加</a>
          <!-- <a v-bind:class="{disabled: isBackImage}" class="dropdown-item pt-1 pb-1" @click="handlerMenuAddBackImage"><i class="fa fa-layer-group text-primary"></i>背景図追加</a> -->
          <a v-bind:class="{disabled: !isBackImage || isMap}" class="dropdown-item pt-1 pb-1" @click="handlerMenuBackImageSetting"><i class="fa fa-tools text-primary"></i>背景図設定</a>
          <!-- <a v-bind:class="{disabled: isBackImage}" class="dropdown-item pt-1 pb-1" @click="handlerMenuAddMap"><i class="fa fa-map-marked-alt text-primary"></i>地図追加</a> -->
          <a v-bind:class="{disabled: !isBackImage && !isMap}" class="dropdown-item pt-1 pb-1" @click="handlerMenuPlaceList"><i class="fa fa-bars text-primary"></i>検査箇所一覧</a>
          <div class="dropdown-divider"></div>
          <a v-bind:class="{disabled: isProject}" class="dropdown-item pt-1 pb-1" @click="handlerMenuRename"><i class="el-icon-edit text-primary"></i>名前の変更</a>
          <div class="dropdown-divider"></div>
          <a v-bind:class="{disabled: isProject}" class="dropdown-item pt-1 pb-1" @click="handlerMenuDeleteNode"><i class="fa fa-trash-alt text-primary"></i>削除</a>

        </card>

      </div>
    </div>

    <div class="mt-2 mr-2">
      <!-- <el-tree :data="treeData" :props="defaultProps"  @node-click="handleNodeClick" :render-content="renderContent"></el-tree> -->

      <!--  -->
      <el-tree
        ref="filetree"
        :data="treeData"
        node-key="id"
        default-expand-all="false"
        @node-drag-start="handleDragStart"
        @node-drag-enter="handleDragEnter"
        @node-drag-leave="handleDragLeave"
        @node-drag-over="handleDragOver"
        @node-drag-end="handleDragEnd"
        @node-drop="handleDrop"
        @node-click="handleNodeClick"
        @node-contextmenu="handleRightClick"
        @node-expand="nodeExpand"
        @node-collapse="nodeCollapse"
        draggable
        :highlight-current="true"
        :expand-on-click-node="false"
        :render-content="renderContent"
        :allow-drop="allowDrop"
        :allow-drag="allowDrag">
      </el-tree>
      <modal v-model:show="modal.showEditName">
        <div class="container-fluid mt-1">
          <div class="card mb-4">
            <!-- Card header -->
            <div class="card-header">
              <h3 class="mb-0">{{ modalTitle }}</h3>
            </div>
            <!-- Card body -->
            <div class="card-body">
              <!-- Form groups used in grid -->
              <div class="row">
                <div class="col-md-12">
                  <base-input v-bind:label="modalLabel">
                    <el-input
                      type="text"
                      v-model="modalValue"
                      ref="modalInput"
                      @focus="onForcusModalInput"></el-input>
                  </base-input>
                  <base-button block type="primary" @click="setModalValue">OK</base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>

      <modal v-model:show="modal.showUploadFile">
        <div class="container-fluid mt-1">
          <div class="card mb-4">
            <!-- Card header -->
            <div class="card-header">
              <h3 class="mb-0">ファイルアップロード</h3>
            </div>
            <!-- Card body -->
            <div class="card-body">
              <!-- Form groups used in grid -->
              <div class="row">
                <div class="col-md-12">
                  <dropzone-file-upload
                    ref="fileUploadArea"
                    v-model="fileMultiple"
                    multiple
                  ></dropzone-file-upload>
                  <base-button block type="primary" @click="fileUpload"
                    >OK</base-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
      <modal v-model:show="modal.showLevelEdit" size="lg">
        <tree-level-edit ref="treeLevelEdit" :treeChild="newChild" @cancel="cancelLevelEdit" @save="saveLevelEdit"></tree-level-edit>
      </modal>
    </div>
  </div>
</template>
<style scoped>
.tree-context-menu {
  position: fixed;
  z-index: 1050;
}

.context-menu {
  box-shadow:  0 50px 100px rgb(50 50 93 / 10%), 0 15px 35px rgb(50 50 93 / 15%), 0 5px 15px rgb(0 0 0 / 10%) !important;
}
</style>
<script>
import Short from "short-uuid";
import Modal from "@/components/Modal";
import TreeModel from "../../../appModel/Tree/TreeModel";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import TreeLevelEdit from "./TreeLevelEdit.vue";

export default {
  name: "tree-list",
  components: {
    Modal,
    DropzoneFileUpload,
    TreeLevelEdit,
  },
  props: {
    defaultProps: Object,
    project: Object,
    projectId: String
  },
  data() {
    return {
      treeData: [], // ツリーのデータ
      fileMultiple: [],
      inputs: {
        fileSingle: [],
      },
      modalLabel: "フォルダ名",
      modalTitle: "新規フォルダ作成",
      modalValue: "",
      dbData: {},
      context_position: {
        left: 0,
        top: 0,
      },
      treeContextShow: false,
      height: window.innerHeight,
      isProject: false,
      isBackImage: false,
      isBackMap: false,
      isDepthLvLimit: false,
      treeDepthLvLimit: 5,
      modal: {
        showEditName: false, // 名称変更モーダルのフラグ
        showUploadFile: false, // ファイル追加モーダルのフラグ
        showLevelEdit: false, // 検査階層モーダルのフラグ
      },
      selectedNode: null,
    };
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    this.loadProjectTreeData().then(function() {
      this.$nextTick(function() {
        this.setInitnode()
      }.bind(this))
    }.bind(this))

    window.addEventListener("scroll", this.handleScroll);
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    // ページ表示時に実行
    this.handleResize();
    window.addEventListener('resize', this.handleResize)

  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  methods: {
    /**
     * 左クリックイベントリスナー
     */
    onleftclick() {
      this.treeContextShow = false;
    },

    setInitnode() {
      // 初期表示時はユーザ作成ノードがあれば選択する
      // なければ検査マスタを選択状態にする

      let tree = this.$refs.filetree;
      console.log(`data: ${JSON.stringify(this.dbData)}`)
      let key = this.dbData.rootItem.children[0].id

      if (this.dbData.rootItem.children.length > 1) {
        // ユーザ作成ノードの先頭を選択状態に
        key = this.dbData.rootItem.children[1].id
      }
      console.log(`key: ${key}`)

      tree.setCurrentKey(key);
    },
    /**
     * 右クリックイベントリスナー
     */
    async handleRightClick(e, data) {
      if (e) {
        e.preventDefault();
      }
      console.log(`handleRightClick :: ${e.pageY} ${e.pageX}`)

      // ボタンのメニューを閉じる
      this.$refs.menuDropdown.closeDropDown();

      // 左クリックと同様にカレントに設定
      await this.updateSelectedNode(data);

      // console.log(`handleRightClick------------`)
      // console.log(`${JSON.stringify(data, null, "\t")}`)
      // console.log(`---------------------------`)

      // 表示制御
      this.isProject = false;
      switch (data.type.toLowerCase()) {
      case "backimage":
        this.isBackImage = true;
        this.isMap = false;
        break;
      case "map":
        this.isBackImage = true;
        this.isMap = true;
        break;
      case "nobackimage":
        this.isBackImage = true;
        this.isMap = true;
        break;
      case "inspectmaster":
        // 検査マスタは右クリック無し
        return
      case "project":
        this.isProject = true;
        break;
      default:
        this.isBackImage = false;
        this.isMap = false;
        this.isProject = false;
        break;
      }

      // 右クリックしたノードを選択状態に
      let tree = this.$refs.filetree;
      tree.setCurrentKey(data.id);

      console.log(`treeDepthLv:${data.depthLv}`)
      // test
      // data.depthLv = 5;

      if (data.depthLv >= this.treeDepthLvLimit) {
        // 階層上限超え
        this.isDepthLvLimit = true;
      } else {
        this.isDepthLvLimit = false;
      }

      // 一時表示
      this.context_position = {
        bottom: null,
        top: `${e.clientY}px`,
        left: `${e.pageX}px`
      };
      this.treeContextShow = true

      let menuHeight = 0;
      const _this = this;
      this.$nextTick(function() {
        // メニュー高さ取得（表示してからでないと0となる）
        menuHeight = _this.$refs.contextMenu.clientHeight;
        menuHeight = document.querySelector('#contextMenu').clientHeight;

        // 右クリック位置Y ＋ メニューの高さ > window高さなら
        // クリック位置をbottomとして表示する
        console.log(`windowHeight:${_this.height}`)
        console.log(`rightClickY:${e.clientY }`)
        console.log(`contextMenuHeight:${menuHeight}`)

        if ((e.clientY + menuHeight) > _this.height) {
          console.log(`top:${e.clientY - menuHeight}`)
          _this.context_position = {
            top: `${e.clientY - menuHeight}px`,
            left: `${e.pageX}px`
          };
        } else {
          _this.context_position = {
            bottom: null,
            top: `${e.clientY}px`,
            left: `${e.pageX}px`
          };
        }
      }.bind(this))

    },
    /**
     * プロジェクトIDからツリー情報を取得する
     */
    async loadProjectTreeData() {

      this.dbData = await TreeModel.getTree(this.projectId);
      if (!this.dbData) {
        this.dbData = await TreeModel.getNewData(this.projectId);
      }

      this.dbData.rootItem.label = this.project.project.name;
      this.treeData = [this.dbData.rootItem];
    },
    /**
     * ツリー情報を更新する
     */
    async updateProjectTreeData() {
      try {

        const currentData = await TreeModel.getTree(this.projectId);

        this.dbData.rootItem = this.treeData[0];
        console.log(JSON.stringify(this.dbData, null, "\t"));
        if (!currentData) {
          // 新規追加
          console.log(`新規`);

          await TreeModel.addTree(this.dbData);
        } else {
          // 更新
          console.log(`更新`);
          await TreeModel.updateTree(this.dbData);
        }

        console.log(`更新成功`);
        //await this.loadProjectTreeData()
      } catch (e) {
        console.log(`更新失敗`);
        throw e;
      }
    },
    /**
     * ツリーノードクリックのイベントリスナー
     */
    handleNodeClick(data) {
      this.treeContextShow = false;

      this.updateSelectedNode(data);
      // let selectedNode = this.$store.state.selectedNode;
      // selectedNode = data;
      // // console.log(`handleNodeClick------------`)
      // // console.log(`${JSON.stringify(data,null,"\t")}`)
      // // console.log(`---------------------------`)
      // this.$store.commit("setSelectedNode", selectedNode);

      // プロジェクトはvue変更なし
      if (data.type == "project") {
        return;
      }

      this.$emit("clicked-node", data);
    },
    /**
     * ノード選択時のイベントリスナー
     */
    async updateSelectedNode(data) {
      let selectedNode = this.$store.state.selectedNode;
      selectedNode = data;
      // console.log(`updateSelectedNode------------`)
      // console.log(`${JSON.stringify(data,null,"\t")}`)
      // console.log(`---------------------------`)
      this.$store.commit("setSelectedNode", selectedNode);
    },
    /**
     * ノード描画時にノード毎に呼び出されるデリゲート
     */
    renderContent(h, { node, data, store }) {
      let retObj = null;

      const currentKey = this.$refs.filetree.getCurrentKey();
      const isOpen = currentKey == node.data.id;

      switch (data.type) {
      case "folder":
        if (isOpen) {
          retObj = (
            <span class="el-tree-node__label">
              <span class="fa fa-folder-open mr-1 text-default"></span>
              {node.data.label}
            </span>
          );
        } else {
          retObj = (
            <span class="el-tree-node__label">
              <span class="fa fa-folder mr-1 text-default"></span>
              {node.data.label}
            </span>
          );
        }
        break;
      case "project":
        retObj = (
          <span class="el-tree-node__label">
            <span class="fa fa-project-diagram mr-1 text-primary"></span>
            {node.data.label}
          </span>
        );

        break;
      case "backimage":
        retObj = (
          <span class="el-tree-node__label">
            <span class="fa fa-layer-group mr-1 text-primary"></span>
            {node.data.label}
          </span>
        );
        break;
      case "map":
        retObj = (
          <span class="el-tree-node__label">
            <span class="fa fa-map-marked-alt mr-1 text-primary"></span>
            {node.data.label}
          </span>
        );
        break;
      case "nobackimage":
        retObj = (
          <span class="el-tree-node__label">
            <span class="fa fa-bars mr-1 text-primary"></span>
            {node.data.label}
          </span>
        );
        break;
      case "inspectMaster":
        retObj = (
          <span class="el-tree-node__label">
            <span class="fa fa-clipboard-check mr-1 text-primary"></span>
            {node.data.label}
          </span>
        );
        break;
      default:
        retObj = (
          <span class="el-tree-node__label">
            <span class="fa fa-folder mr-1 text-primary"></span>
            {node.data.label}
          </span>
        );
      }
      return retObj;
    },
    /**
     * ノードドラッグ開始のイベントリスナー
     */
    handleDragStart(node, ev) {
      console.log("drag start", node);
    },
    /**
     * ノードドラッグ中のイベントリスナー
     */
    handleDragEnter(draggingNode, dropNode, ev) {
      console.log("tree drag enter: ", dropNode.label);
    },
    /**
     * ノードドラッグ時にノードから離れる際のイベントリスナー
     */
    handleDragLeave(draggingNode, dropNode, ev) {
      console.log("tree drag leave: ", dropNode.label);
    },
    /**
     * ノードドラッグ時にノードに重なる時のイベントリスナー
     */
    handleDragOver(draggingNode, dropNode, ev) {
      console.log("tree drag over: ", dropNode.label);
    },
    /**
     * ノードドラッグ終了のイベントリスナー
     */
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      console.log("tree drag end: ", dropNode && dropNode.label, dropType);
    },
    /**
     * ノードドラッグ中のイベントリスナー
     */
    handleDrop(draggingNode, dropNode, dropType, ev) {
      console.log("tree drop: ", dropNode.label, dropType);
    },
    /**
     * ノード展開
     */
    nodeExpand(node) {
      //node.isOpen = true
    },
    /**
     *
     */
    nodeCollapse(node) {
      //node.isOpen = false
    },
    /**
     * ノード毎におけるドロップの可否を返す
     */
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.label === "Level two 3-1") {
        return type !== "inner";
      } else {
        return true;
      }
    },
    /**
     * ノード毎におけるドラッグの可否を返す
     */
    allowDrag(draggingNode) {
      let isDraggable = true;
      switch (draggingNode.data.type.toLowerCase()) {
      case "project":
        isDraggable = false;
        break;
      case "backimage":
        isDraggable = false;
        break;
      case "map":
        isDraggable = false;
        break;
      case "nobackimage":
        isDraggable = false;
        break;
      case "inspectmaster":
        isDraggable = false;
        break
      default:
        isDraggable = true;
        break;
      }
      console.log(`allowDrag:JSON:${JSON.stringify(draggingNode.data)}`);
      console.log(`allowDrag:${isDraggable}`);
      return isDraggable;
    },
    /**
     * 検査マスタ編集
     */
    handlerMenuInspectMaster() {
      // this.$router.push({
      //   name: "InspectMasterList"
      // })

      //@transfer="transferProc" :transferArgs="inspectMasterListArgs"
      this.$emit('transfer', {key: "inspectMasterListArgs", values: {}})
    },
    /**
     * フォルダ追加
     */
    handlerMenuAddFolder() {
      const selectedNode = this.$refs.filetree.getCurrentNode();
      console.log(JSON.stringify(selectedNode, null, "\t"));
      this.treeAddFolder(selectedNode);
    },
    /**
     * 名前の変更
     */
    handlerMenuRename() {
      const selectedNode = this.$refs.filetree.getCurrentNode();
      console.log(JSON.stringify(selectedNode, null, "\t"));
      this.treeRename(selectedNode);
    },
    handlerMenuAddFile() {},
    /**
     * 検査を追加
     */
    handlerMenuAddInspect() {
      const selectedNode = this.$refs.filetree.getCurrentNode();
      this.treeAddInspect(selectedNode);
    },
    /**
     * 背景図追加
     */
    handlerMenuAddBackImage() {
      const selectedNode = this.$refs.filetree.getCurrentNode();
      this.treeAddBackImage(selectedNode);
    },
    /**
     * 背景図設定
     */
    handlerMenuBackImageSetting() {
      this.$router.push({
        name: "EditBackImage",
      });
    },
    /**
     * 地図追加
     */
    handlerMenuAddMap() {
      const selectedNode = this.$refs.filetree.getCurrentNode();
      this.treeAddMap(selectedNode);
    },
    handlerMenuDeleteNode() {
      const selectedNode = this.$refs.filetree.getCurrentNode();
      this.treeDeleteNode(selectedNode);
    },
    handlerMenuPlaceList() {
      // const selectedNode = this.$refs.filetree.getCurrentNode();
      const selectedNode = this.$store.state.selectedNode
      console.log(selectedNode)
      this.$emit('transfer', {key: "inspectPlaceListArgs", values: selectedNode})
    },
    treeAddFolder(node) {
      console.log(this.$refs.filetree.getCurrentKey());
      let selectedNode = node;
      if (!selectedNode) {
        return;
      }

      let folderId = Short.generate();
      console.log(`treeAddFolder::${folderId}`);

      let newChild = {
        label: "新規フォルダ",
        id: folderId,
        type: "folder",
        path: "",
        order: 0,
        deleteFlag: false,
        fileSize: 0,
        createDate: "",
        createUser: "",
        updateDate: "",
        updateUser: "",
        fileList: [],
      };

      if (!selectedNode.children) {
        selectedNode["children"] = [];
      }
      selectedNode.children.push(newChild);
      let tree = this.$refs.filetree;
      this.modal.showEditName = true;
      this.modalValue = "新規フォルダ";
      this.modalLabel = "新規フォルダ作成";
      this.modalTitle = "フォルダ名";
      console.log(`treeAddFolder\t${JSON.stringify(this.dbData, null, "\t")}`);
      setTimeout(function () {
        console.log(`setTimeout on setCurrentKey : ${folderId}`);
        tree.setCurrentKey(folderId);
      }, 100);
    },
    treeRename(node) {
      console.log(this.$refs.filetree.getCurrentKey());
      let selectedNode = node;
      if (!selectedNode) {
        return;
      }

      let tree = this.$refs.filetree;
      this.modal.showEditName = true;
      this.modalValue = node.label;
      this.modalLabel = "新しい名前";
      this.modalTitle = "名前の変更";
      console.log(`treeRename\t${JSON.stringify(this.dbData, null, "\t")}`);
      setTimeout(function () {
        console.log(`setTimeout on setCurrentKey : ${node.id}`);
        tree.setCurrentKey(node.id);
      }, 100);
    },
    setModalValue() {
      let currentNode = this.$refs.filetree.getCurrentNode();
      currentNode.label = this.modalValue;
      this.modal.showEditName = false;

      this.updateProjectTreeData();
    },
    treeAddFile() {
      this.fileMultiple = [];
      this.modal.showUploadFile = true;
    },
    /**
     * 検査を追加処理
     */
    async treeAddInspect(node) {

      /**
       * TODO:
       * 以下の処理はノードをいきなり追加しているが、
       * 背景図設定をModalで表示させ、Modalで保存ボタン押下時にノードを追加するように修正する。
       *
       */

      console.log(this.$refs.filetree.getCurrentKey());
      const selectedNode = node;
      if (!selectedNode) {
        return;
      }
      let id = Short.generate();
      console.log(`treeAddInspect::${id}`);

      let newChild = {
        label: "新規検査",
        id: id,
        type: "",
        path: "",
        order: 0,
        deleteFlag: false,
        fileSize: 0,
        createDate: "",
        createUser: "",
        updateDate: "",
        updateUser: "",
        fileList: [],
      };
      // this.newChild = newChild;

      // 選択ノードを保持
      this.selectedNode = selectedNode;

      if (!selectedNode.children) {
        selectedNode["children"] = [];
      }
      // Modal表示後に追加
      // selectedNode.children.push(newChild);

      let tree = this.$refs.filetree;
      await this.$refs.treeLevelEdit.clearSelect();
      await this.$refs.treeLevelEdit.init(this.projectId, newChild);
      this.modal.showLevelEdit = true;

      setTimeout(function () {
        console.log(`setTimeout on setCurrentKey : ${id}`);
        tree.setCurrentKey(id);
      }, 100);

    },
    /**
     * 背景図追加処理
     */
    treeAddBackImage(node) {

      /**
       * TODO:
       * 以下の処理はノードをいきなり追加しているが、
       * 背景図設定をModalで表示させ、Modalで保存ボタン押下時にノードを追加するように修正する。
       *
       */

      console.log(this.$refs.filetree.getCurrentKey());
      const selectedNode = node;
      if (!selectedNode) {
        return;
      }
      let folderId = Short.generate();
      console.log(`treeAddFolder::${folderId}`);

      let newChild = {
        label: "新規背景図",
        id: folderId,
        type: "backimage",
        path: "",
        order: 0,
        deleteFlag: false,
        fileSize: 0,
        createDate: "",
        createUser: "",
        updateDate: "",
        updateUser: "",
        fileList: [],
      };

      if (!selectedNode.children) {
        selectedNode["children"] = [];
        //this.$set(selectedNode, 'children', []);
      }
      selectedNode.children.push(newChild);

      let tree = this.$refs.filetree;
      this.modal.showEditName = true;
      this.modalValue = "新規背景図";
      this.modalLabel = "新規背景図作成";
      this.modalTitle = "背景図名";
      setTimeout(function () {
        console.log(`setTimeout on setCurrentKey : ${folderId}`);
        tree.setCurrentKey(folderId);
      }, 100);

    },
    /**
     * 地図追加処理
     */
    treeAddMap(node) {
      console.log(this.$refs.filetree.getCurrentKey());
      const selectedNode = node;
      if (!selectedNode) {
        return;
      }
      let folderId = Short.generate();
      console.log(`treeAddFolder::${folderId}`);
      let newChild = {
        label: "新規地図",
        id: folderId,
        type: "map",
        path: "",
        order: 0,
        deleteFlag: false,
        fileSize: 0,
        createDate: "",
        createUser: "",
        updateDate: "",
        updateUser: "",
        fileList: [],
      };
      if (!selectedNode.children) {
        selectedNode["children"] = [];
        //this.$set(selectedNode, 'children', []);
      }
      selectedNode.children.push(newChild);
      let tree = this.$refs.filetree;
      this.modal.showEditName = true;
      this.modalValue = "新規地図";
      this.modalLabel = "新規地図作成";
      this.modalTitle = "地図名";
      setTimeout(function () {
        console.log(`setTimeout on setCurrentKey : ${folderId}`);
        tree.setCurrentKey(folderId);
      }, 100);
    },
    treeDeleteNode(node) {
      this.$refs.filetree.remove(node);
      console.log(`treeDeleteNode\t${JSON.stringify(this.dbData, null, "\t")}`);
      this.updateProjectTreeData();
    },
    onForcusModalInput(event) {
      this.$refs.modalInput.select();
    },
    /**
     * 検査階層のモーダルがキャンセルされた時の処理です。
     */
    cancelLevelEdit() {
      console.log(`cancelLevelEdit`)
      this.modal.showLevelEdit = false;
    },
    /**
     * 検査階層のモーダルが登録された時の処理です。
     */
    saveLevelEdit(treeChild) {
      console.log(treeChild)

      // 保持しておいた選択ノードに追加
      this.selectedNode.children.push(treeChild);

      // DBに登録
      this.updateProjectTreeData();

      this.modal.showLevelEdit = false;
    },
    /**
     * ファイルアップロード
     */
    fileUpload() {
      console.log(JSON.stringify(this.fileMultiple, null, "\t"));

      let value = this.$refs.fileUploadArea.value;
      console.log(JSON.stringify(value, null, "\t"));
    },
    /**
     * リサイズのイベントリスナー
     */
    handleResize: function() {
      // windowwのresizeのたびに発生
      // this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    /**
     * スクロール時のイベントリスナー
     */
    handleScroll: function() {
      this.treeContextShow = false;
    },
  },
};
</script>
<style></style>
