import { createRouter, createWebHashHistory } from "vue-router";

//import DashboardLayout from "@/views/Layout/DashboardLayout";
import DashboardLayout from "@/appViews/Layout/DashboardLayout";
import AuthLayout from "@/appViews/Layout/AuthLayout";
import RebarLayout from "@/appViews/Layout/RebarLayout"

// MapletFSCloudのアプリビュー
import Signin from "../appViews/Signin.vue";
import Projects from "../appViews/Projects/Projects.vue";
import ProjectMain from "../appViews/Projects/ProjectMain.vue";
import ProjectEditor from "../appViews/Projects/ProjectEditor.vue";
import Sample from "@/appViews/Library/Sample.vue";
import StoreDebug from "@/appViews/Library/StoreDebug.vue";
import MakeUser from "@/appViews/User/MakeUser.vue";
import UserList from "@/appViews/User/UserList.vue";
import EditBackImage from "@/appViews/BackImage/EditBackImage.vue";
import TenantManage from "@/appViews/NcsManage/TenantManage.vue";
import TenantEdit from "@/appViews/NcsManage/TenantEdit.vue";
import MeetingMain from "@/appViews/Meeting/MeetingMain.vue";
import MeetingClient from "@/appViews/Meeting/MeetingClient.vue";
import Dashboard from "@/appViews/Dashboard/Dashboard.vue";
import Mapview from "@/appViews/Map/Map.vue"
import InspectMasterEdit from "@/appViews/InspectMaster/InspectMasterEdit.vue"
import InspectMasterList from "@/appViews/InspectMaster/InspectMasterList.vue"
import NoticeList from "@/appViews/Notice/NoticeList.vue"
import ForgotPassword from "@/appViews/User/ForgotPassword.vue";
import Maintenance from "@/appViews/Maintenance.vue";
import ProjectView from "@/appViews/Projects/ProjectView.vue";
// import InputAuthCode from "@/appViews/User/InputAuthCode.vue";

// 開発テスト用
import AuthTest from "@/appViews/AuthTest"

// 配筋検査用のビュー
import List from "@/appViews/Rebar/List.vue"
import Detail from "@/appViews/Rebar/Detail.vue"
import Account from "@/appViews/Rebar/Account.vue"
import FormManage from "@/appViews/FormManage/FormManage.vue"

import { Auth } from "aws-amplify";
// import { useStore } from "vuex";

import UserInfo from "../appUtils/UserInfo"
import TenantModel from "../appModel/Tenant/TenantModel"
import AuthUtil from "../appUtils/AuthUtil"

const routes = [
  {
    path: "/dashboard",
    component: DashboardLayout,

    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "ダッシュボード",
        },
      },
      {
        path: "/sample",
        name: "Sample",
        components: { default: Sample },
        meta: {
          navbarType: "light",
        },
      },
      {
        path: "/storedebug",
        name: "StoreDebug",
        components: { default: StoreDebug },
        meta: {
          navbarType: "light",
        },
      },
      {
        path: "/userlist",
        name: "UserList",
        components: { default: UserList },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "ユーザー管理",
        },
      },
      {
        path: "/makeuser",
        name: "MakeUser",
        components: { default: MakeUser },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "ユーザー管理",

        },
        props: true,
      },
      {
        path: "/edituser",
        name: "EditUser",
        components: { default: MakeUser },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "ユーザー管理",

        },
        props: true,
      },
      {
        path: "/editusermodal",
        name: "EditUserModal",
        components: { default: MakeUser },
        meta: {
          navbarType: "light",  
          requiresAuth: true,
          title: "ユーザ編集",
        },
        props: true,
      },
      {
        path: "/Projects",
        name: "Projects",
        components: { default: Projects },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "プロジェクト",
          hideFooter: true,
        },
      },
      {
        path: "/projectMain",
        name: "ProjectMain",
        components: { default: ProjectMain },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "プロジェクト",
          hideFooter: true,
        },
      },
      {
        path: "/newproject",
        name: "NewProject",
        components: { default: ProjectEditor },
        meta: {
          navbarType: "light",
          isNewProject: 1,
          requiresAuth: true,
          title: "新規プロジェクト作成",
          hideFooter: true,
        },
      },
      {
        path: "/editproject",
        name: "EditProject",
        components: { default: ProjectEditor },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          title: "プロジェクト編集",
          hideFooter: true,
        },
      },
      {
        path: "/projectview",
        name: "ProjectView",
        components: { default: ProjectView },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          title: "プロジェクトビュー",
          hideFooter: true,
        },
      },
      {
        path: "/inspectmasterlist",
        name: "InspectMasterList",
        components: { default: InspectMasterList },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          title: "検査マスタ一覧",
          hideFooter: true,
        },
      },
      {
        path: "/inspectmasteredit",
        name: "InspectMasterEdit",
        components: { default: InspectMasterEdit },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          title: "検査マスタ編集",
          hideFooter: true,
        },
      },
      {
        path: "/meeting",
        name: "MeetingMain",
        title: "ミーティング",
        components: { default: MeetingMain },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "ミーティング",
          hideFooter: true,
        },
      },
      {
        path: "/formmanage",
        name: "FormManage",
        components: { default: FormManage },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          title: "帳票テンプレート管理",
          hideFooter: true,
        },
      },
      // {
      //   path: "/meetingclient",
      //   name: "MeetingClient",
      //   components: { default: MeetingClient },
      //   meta: {
      //     navbarType: "light",
      //     requiresAuth: true,
      //     title: "ミーティング",
      //   },
      // },
      {
        path: "/editBackImage",
        name: "EditBackImage",
        components: { default: EditBackImage },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          title: "背景図編集",
          hideFooter: true,
        },
      },
      {
        path: "/mapTest",
        name: "MapTest",
        components: { default: Mapview },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          title: "マップテスト",
          hideFooter: true,
        },
      },
      {
        path: "/tenantManage",
        name: "TenantManage",
        components: { default: TenantManage },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          ncsManager: true,
          title: "テナント管理",
          hideFooter: true,
        },
      },
      {
        path: "/tenantEdit",
        name: "TenantEdit",
        components: { default: TenantEdit },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          ncsManager: true,
          title: "テナント編集",
          hideFooter: true,
        },
      },
      {
        path: "/noticeList",
        name: "NoticeList",
        components: { default: NoticeList },
        meta: {
          navbarType: "light",
          requiresAuth: true,
          title: "通知",
          hideFooter: true,
        },
      },
    ],
  },
  {
    path: "/meetingclient",
    name: "MeetingClient",
    components: { default: MeetingClient },
    meta: {
      navbarType: "light",
      requiresAuth: true,
      title: "ミーティング",
    },
  },
  {
    path: "/list",
    component: RebarLayout,
    children: [
      {
        path: "/list",
        name: "List",
        components: { default: List },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          rebarinspect: true,
          title: "測定結果一覧",
          hideFooter: false,
        },
      },
      {
        path: "/detail",
        name: "Detail",
        components: { default: Detail },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          rebarinspect: true,
          title: "測定結果一覧",
          hideFooter: false,
        },
      },
      {
        path: "/rebarformmanage",
        name: "Rebarformmanage",
        components: { default: FormManage },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          rebarinspect: true,
          title: "帳票管理",
          hideFooter: true,
        },
      },
      {
        path: "/account",
        name: "Account",
        components: { default: Account },
        meta: {
          isNewProject: 0,
          navbarType: "light",
          requiresAuth: true,
          rebarinspect: true,
          title: "アカウント情報",
          hideFooter: false,
        },
      },

    ]
  },
  {
    path: "/authtest",
    name: "AuthTest",
    components: { default: AuthTest },
  },
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "/",
        name: "Signin",
        components: { default: Signin },
      },
      {
        path: "/forgotpassword",
        name: "ForgotPassword",
        components: { default: ForgotPassword },
        meta: {
          requiresAuth: false,
          title: "認証コードを送信",
        },
      },
      {
        path: "/maintenance",
        name: "Maintenance",
        components: { default: Maintenance },
      },
      // {
      //   path: "/inputAuthCode",
      //   name: "InputAuthCode",
      //   components: { default: InputAuthCode },
      //   meta: {
      //     requiresAuth: false,
      //     title: "認証コードを入力",
      //   },
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

// リダイレクト設定
router.beforeResolve(async (to, from, next) => {
  // セッション切れtest用
  // await Auth.signOut();

  //alert(JSON.stringify(store,null,"\n"))
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // MCZユーザ専用画面の判定
    //const user = await getUser()
    try {
      // 認証済みであるか
      const data = await Auth.currentAuthenticatedUser();

      if (data && data.signInUserSession) {
        // 認証済みである
        const info = await UserInfo.getUserInfo()

        // いったん保留
        // // tokenをリフレッシュ
        // await AuthUtil.refreshToken(data);

        // 有効期限内か
        const isValidPeriod = await AuthUtil.isValidPeriod(info.group);
        if (!isValidPeriod) {
          AuthUtil.alert();
          return next({
            path: "/",
          });
        }

        // NCS管理者アカウントであるか
        if (info.group == "ncsmanager") {
          // NCS管理者はすべての画面へのアクセスが可能
          return next()
        }

        // NCS専用の管理画面
        if (to.matched.some((record) => record.meta.ncsManager)) {
          // NCS管理者アカウントであるか
          // とりあえず固定
          if (info.group != "ncsmanager") {
            // 管理者以外はルートに飛ばす
            return next({
              path: "/",
            });
          }
        }

        if (to.matched.some((record) => record.meta.rebarinspect)) {
          // 配筋検査専用画面へのリクエストである場合
          const tenantData = await TenantModel.getTenant(info.group)
          // 契約オプションにREBARが含まれている場合は遷移可能
          if (tenantData.tenant.availableOptions.includes("REBAR")) {
            return next();
          } else {
            return next({
              path: "/",
            });
          }
        } else {
          // MCZ画面のリクエストである場合
          const info = await UserInfo.getUserInfo()
          const tenantData = await TenantModel.getTenant(info.group)
          // 契約オプションにMCZが含まれている場合は遷移可能
          if (tenantData.tenant.availableOptions.includes("MCZ")) {
            return next();
          } else {
            return next({
              path: "/",
            });
          }
        }
      } else {
        // 未認証であればルートパスへリダイレクト
        AuthUtil.alert();
        return next({
          path: "/",
        });
      }
    } catch (e) {
      // 例外発生時もルートパスへリダイレクトする
      AuthUtil.alert();
      return next({
        path: "/",
      });
    }
  }

  return next();
});

export default router;
