
const { API, graphqlOperation } = require("aws-amplify");
const {
  createMapletFSCloudData,
  deleteMapletFSCloudData,
  updateMapletFSCloudData,
} = require("@/graphql/mutations");
const {
  listMapletFSCloudDatas,
  getMapletFSCloudData,
} = require("@/graphql/queries");
const short = require('short-uuid');
const UserInfo = require('../appUtils/UserInfo');
const dayjs = require('dayjs')
//require('dayjs/locale/ja');



/**
 * ミーティング用のデータ取得モデルクラス
 */
class baseModel {

  static getNowDate() {
    return dayjs().format('YYYY-MM-DD HH:mm:ss.SSS')
  }
  /**
   * リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getList(skCondition) {

    const loginInfo = await UserInfo.getUserInfo()
    if (!loginInfo.group) {
      // console.log(`グループなし`)
      return null
    }
    // console.log(`getList:${loginInfo.group}`)
    const tenantId = loginInfo.group

    if (!tenantId) {
      return;
    }

    // 1回で取得する件数
    const limit = 10;
    let nextToken = ''
    let dataList = []

    while (nextToken !== null) {

      let result;

      if (nextToken.length > 0) {
        result = await API.graphql(
          graphqlOperation(listMapletFSCloudDatas, {
            pk: tenantId,
            sk: skCondition, //{ beginsWith: "meeting" },
            limit: limit,
            nextToken
          })
        );
      } else {
        // 初回取得時はnextTokenを指定するとパラメータエラーになる
        result = await API.graphql(
          graphqlOperation(listMapletFSCloudDatas, {
            pk: tenantId,
            sk: skCondition, //{ beginsWith: "meeting" },
            limit: limit,
          })
        );
      }
      //alert(JSON.stringify(result))
      if (result) {
        if (result.data) {
          // console.log(`getList : ${result.data.listMapletFSCloudDatas.items.length}`)

          dataList = dataList.concat(result.data.listMapletFSCloudDatas.items)

          // console.log(`nextToken : ${result.data.listMapletFSCloudDatas.nextToken}`)
          nextToken = result.data.listMapletFSCloudDatas.nextToken
        }
      }
    }

    return dataList;
  }

  /**
   * レコードを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteData(data) {
    if (!data) {
      return;
    }

    await API.graphql(
      graphqlOperation(deleteMapletFSCloudData, {
        input: { pk: data.pk, sk: data.sk },
      })
    );
    //alert(`ok`)
  }
  /**
   * レコードを更新する
   * @param {*} data
   */
   static async updateData(data, condition = null) {
    //TODO:更新されているかをチェックすること
    delete data.createdAt;
    delete data.updatedAt;
    // alert(`updateData`)
    if (condition) {
      await API.graphql(
        graphqlOperation(updateMapletFSCloudData, { input: data, condition: condition})
      );
    } else {
      await API.graphql(
        graphqlOperation(updateMapletFSCloudData, { input: data})
      );
    }

  }

  /**
   * レコードを追加する
   * @param {*} data
   */
  static async addData(data) {
    await API.graphql(
      graphqlOperation(createMapletFSCloudData, { input: data })
    );
  }

  /**
   * ミーティング管理レコードを取得する
   * @param {*} data
   */
  static async getSingleData(data) {
    const loginInfo = await UserInfo.getUserInfo()
    if (!loginInfo.group) {
      console.log(`グループなし`)
      return null
    }
    const tenantId = loginInfo.group

    if (!tenantId) {
      console.log(`テナント無し`)
      return;
    }

    let result = await API.graphql(
      graphqlOperation(getMapletFSCloudData, { pk: tenantId, sk: data.sk })
    );

    return result.data.getMapletFSCloudData;
  }

  /**
   * 新規登録用のスキーマ
   */
  static async getNewData() {
    const loginInfo = await UserInfo.getUserInfo()
    if (!loginInfo.group) {
      return null
    }
    const tenantId = loginInfo.group
    return {
      pk: `${tenantId}`,
      sk: "",
      lsiStr1: "-",
      lsiStr2: "-",
      lsiStr3: "-",
      lsiStr4: "-",
      lsiInt1: 0,
      groups: [tenantId],
      ttl: 0

    };
  }
}

module.exports = baseModel;
