<template>
  <div>

    <div class="container-fluid mt-1" v-if="false">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <div class="row">
            <div class="col-6">
              <base-button icon outline type="primary" @click="startMeeting">
                <span class="btn-inner--icon"
                  ><i class="fas fa-video"></i
                ></span>
                <span class="btn-inner--text">ミーティング開始</span>
              </base-button>
              <base-input name="select" label="マイクデバイス">
                <el-select
                  v-model="audioInput"
                  filterable

                  placeholder=""
                >
                  <el-option
                    v-for="option in audioInputList"
                    :key="option.text"
                    :label="option.text"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
              <base-input name="select" label="ビデオデバイス">
                <el-select
                  v-model="videoInput"
                  filterable
                  placeholder="Simple select"
                  @change="changeVideoDevice"
                >
                  <el-option
                    v-for="option in videoInputList"
                    :key="option.text"
                    :label="option.text"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
              <base-input name="select" label="ビデオ品質">
                <el-select
                  v-model="videoQuality"
                  filterable
                  placeholder="Simple select"
                >
                  <el-option
                    v-for="option in videoQualityList"
                    :key="option.text"
                    :label="option.text"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
              <base-input name="select" label="スピーカー">
                <el-select
                  v-model="speaker"
                  filterable
                  placeholder="Simple select"
                >
                  <el-option
                    v-for="option in speakerOutputList"
                    :key="option.text"
                    :label="option.text"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-6">
              <video
                id="video-preview"
                ref="videoPreview"
                class="w-100 h-100"
                style="border-radius: 8px; border: 1px solid gray"
              ></video>
            </div>
          </div>
        </div>
        <!-- Card body -->
        <div class="card-body" v-if="false">
          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-12"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <modal v-model:show="isStarted" v-if="isStarted"> -->
    <el-container v-if="isStarted" class="video-container" v-bind:class="{ 'video-container-wipe' : wipeMode }">
      <!-- サムネイル -->
      <el-header height="120px" class="video-header" v-bind:class="{ 'video-header-wipe' : wipeMode }">
        <div class="d-flex justify-content-center">
          <!-- <div class="col-sm-2" v-for="tile in tiles" v-bind:key="tile.tileState.tileId" > -->
            <div class="col-sm-2" v-for="tile in thumbnailTiles" v-bind:key="tile.tileState.tileId" >

            <video v-bind:ref="`video${tile.tileState.tileId}`" v-if="isShowTile(tile.tileState.tileId)" class="tile-video" />
            <div class="tile-info">{{roster[tile.state().boundAttendeeId] ? roster[tile.state().boundAttendeeId].extendName : ""}}</div>
            <div class="loader-inner ball-pulse"></div>
            <base-button round :class="`btn-icon-only pin-btn-${pinClass(tile.tileState.tileId)}`" @onClick.prevent="togglePin(tile.tileState.tileId)">
              <span class="btn-inner--icon"><i class="fas fa-thumbtack"></i></span>
            </base-button>
            <i class="fas fa-volume-up tile-volume" v-bind:class="{tileVolumeOn: isSpeaking[tile.tileState.tileId] }" ></i>

          </div>
          <audio ref="audio1" class="audio-style" />
        </div>
      </el-header>

      <transition name="right">
        <div  style="overflow-y:scroll;overflow-x:hidden;" class="drawer-menu-wrapper p-2" v-bind:class="{'drawer-menu-wrapper-hide' : !chatDrawerFlg}">
          <!-- <inspect-place @transfer="transferProc" :editPlaceId="editPlaceId" :projectId="projectId" :nodeId="nodeId" :addPlaceData="addPlaceData"></inspect-place> -->
          <div class="list-group receive-message" id="receive-message" style="flex: 1 1 auto; overflow-y: auto;
            border: 1px solid rgba(0, 0, 0, 0.125); background-color: #fff;height:80%;"></div>
          <div class="input-group send-message" style="display:flex;flex:0 0 auto;margin-top:0.2rem;height:19%">
            <textarea class="form-control shadow-none" id="send-message" rows="1" placeholder="Type a message" style="display:inline-block; width:100%;height:100%;
            resize:none; border-color: rgba(0, 0, 0, 0.125); outline: none; padding-left: 1.4rem"></textarea>
          </div>
        </div>
      </transition>

      <!-- テストcanvas -->
      <transition name="right">
        <div style="overflow-y:scroll;overflow-x:hidden;" class="drawer-menu-wrapper p-2" v-bind:class="{'drawer-menu-wrapper-hide' : !inDrawingMode}">
          <canvas id="drawCanvas" ref="canvas" style="width:100%"
            @mousedown="drawingStart"
            @mouseup="drawingEnd"
            @mouseout="drawingEnd"
            @mouseleave="drawingEnd"
            @mousemove="mouseMove"
            @touchstart="touchStart"
            @touchmove="touchMove"
            @touchend="drawingEnd"
            ></canvas>
<!--
          <div class="list-group receive-message" id="message-canvas" style="flex: 1 1 auto; overflow-y: auto;
            border: 1px solid rgba(0, 0, 0, 0.125); background-color: #fff;height:100%;">
          </div> -->

        </div>
      </transition>



          <!-- メインビデオ -->
          <el-main class="main-video-container">

            <div class="main-video-wrapper">
              <div class="main-attendee-info">
                {{activeRoster() ? activeRoster().extendName : ""}}
              </div>
              <video ref="mainVideo" class="main-video" />
              <div class="d-flex justify-content-start meeting-btn-container-wipe" v-if="wipeMode">
                <base-button round class="btn-icon-only  btn-default meeting-btn-wipe mr-0" @click="changeWipeMode">
                  <span class="btn-inner--icon"><i class="fas fa-minus-square"></i></span>
                </base-button>
                <base-button round class="btn-icon-only meeting-btn-default meeting-btn-wipe mr-0 text-red" @click="leaveMeeting">
                  <span class="btn-inner--icon"><i class="fas fa-power-off"></i></span>
                </base-button>
              </div>
            </div>
          </el-main>

          <el-footer height="100px" class="video-footer" v-bind:class="{ 'video-footer-wipe' : wipeMode }">
            <div class="d-flex justify-content-between">
              <div class="d-flex justify-content-start">
                <div class="text-center ml-2 mr-2">

                  <base-button round class="btn-icon-only meeting-btn-default mr-0" v-if="!isMute" @click="toggleMic">
                    <span class="btn-inner--icon"><i class="fas fa-microphone "></i></span>
                  </base-button>
                  <base-button round class="btn-icon-only meeting-btn-default mr-0" v-if="isMute" @click="toggleMic">
                    <span class="btn-inner--icon"><i class="fas fa-microphone-slash "></i></span>
                  </base-button>

                  <el-dropdown class="dropdown" trigger="click" @command="onChangeMicDevice">
                    <span class="btn btn-sm btn-icon-only text-dark mic-property-btn">
                      <!-- <i class="fas fa-ellipsis-v mt-2"></i> -->
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <!-- <a class="dropdown-item" v-for="option in audioInputList" :key="option.text" @click.prevent="onChangeMicDevice(option.value)" >{{option.text}}</a> -->
                        <el-dropdown-item v-for="option in audioInputList" :key="option.text" :command="option.value">{{option.text}}</el-dropdown-item>

                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                  <div class="meeting-btn-text">
                    マイク
                  </div>
                  <base-progress
                    class="progress-xs mb-0"
                    :type="`success`"
                    :value="selfAudioLevel"
                  >
                  </base-progress>
                </div>
                <div class="text-center ml-2 mr-2">
                  <base-button round class="btn-icon-only meeting-btn-default ml-1 mr-0" v-if="!isVideoOff" @click="toggleVideo">
                    <span class="btn-inner--icon"><i class="fas fa-video"></i></span>
                  </base-button>
                  <base-button round class="btn-icon-only meeting-btn-default ml-1 mr-0" v-if="isVideoOff" @click="toggleVideo">
                    <span class="btn-inner--icon"><i class="fas fa-video-slash"></i></span>
                  </base-button>
                  <el-dropdown class="dropdown" trigger="click" @command="onChangeVideoDevice">
                    <span class="btn btn-sm btn-icon-only text-dark mic-property-btn">
                      <!-- <i class="fas fa-ellipsis-v mt-2"></i> -->
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <!-- <a class="dropdown-item" v-for="option in videoInputList" :key="option.text" @click.prevent="onChangeVideoDevice(option.value)" >{{option.text}}</a> -->
                        <el-dropdown-item v-for="option in videoInputList" :key="option.text" :command="option.value">{{option.text}}</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>

                  <div class="meeting-btn-text">
                    ビデオ オン
                  </div>
                </div>
                <div class="text-center ml-2 mr-2" v-if="false">
                  <base-button round class="btn-icon-only meeting-btn-default mr-0">
                    <span class="btn-inner--icon"><i class="fas fa-th-large"></i></span>
                  </base-button>
                  <div class="meeting-btn-text">
                    タイル表示
                  </div>
                </div>
                <div class="text-center ml-2 mr-2" v-if="false">
                  <base-button round class="btn-icon-only meeting-btn-default mr-0" @click="changeWipeMode">
                    <span class="btn-inner--icon"><i class="fas fa-minus-square"></i></span>
                  </base-button>
                  <div class="meeting-btn-text">
                    縮小表示
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <div class="text-center ml-2 mr-2">
                  <base-button round class="btn-icon-only meeting-btn-default mr-0" @click="inDrawingMode = !inDrawingMode">
                    <span class="btn-inner--icon"><i class="fas fa-pen"></i></span>
                  </base-button>
                  <div class="meeting-btn-text">
                    キャンバス
                  </div>
                </div>
                <div class="text-center ml-2 mr-2">
                  <base-button round class="btn-icon-only meeting-btn-default mr-0" @click="chatDrawerFlg = !chatDrawerFlg">
                    <span class="btn-inner--icon"><i class="fas fa-comment-alt"></i></span>
                  </base-button>
                  <div class="meeting-btn-text">
                    チャット
                  </div>
                </div>
                <div class="text-center ml-2 mr-2">
                  <base-button round class="btn-icon-only meeting-btn-default mr-0" @click="shareDisplay" v-if="!isStartShare">
                    <span class="btn-inner--icon"><i class="fas fa-tv"></i></span>
                  </base-button>
                  <div class="meeting-btn-text" v-if="!isStartShare">
                    画面共有
                  </div>

                  <base-button round class="btn-icon-only meeting-btn-default mr-0" @click="stopShareDisplay" v-if="isStartShare">
                    <span class="btn-inner--icon"><i class="fas fa-tv"></i></span>
                  </base-button>
                  <div class="meeting-btn-text" v-if="isStartShare">
                    画面共有停止
                  </div>
                </div>
                <div class="text-center ml-2 mr-2">
                  <base-button round class="btn-icon-only meeting-btn-default mr-0" @click="captureImage">
                    <span class="btn-inner--icon"><i class="fas fa-camera"></i></span>
                  </base-button>
                  <div class="meeting-btn-text">
                    静止画
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <div class="text-center ml-2 mr-2">
                  <base-button round class="btn-icon-only meeting-btn-default mr-0">
                    <span class="btn-inner--icon"><i class="fas fa-users"></i></span>
                  </base-button>
                  <div class="meeting-btn-text">
                    参加者({{numberOfAttendee}})
                  </div>
                </div>
                <div class="text-center ml-2 mr-2">
                  <base-button round class="btn-icon-only meeting-btn-default mr-0 text-red" @click="leaveMeeting">
                    <span class="btn-inner--icon"><i class="fas fa-power-off"></i></span>
                  </base-button>
                  <div class="meeting-btn-text text-red">
                    <b>終了</b>
                  </div>
                </div>
              </div>
            </div>
          </el-footer>

    </el-container>
    <!-- <canvas ref="dummyAttendee" class="dummyAttendee" width="720" height="480"></canvas> -->
  </div>
</template>
<style scoped>
.video-container{
  margin-right: calc(50% - 50vw);
margin-left: calc(50% - 50vw);
  /* position:absolute; */
  width: 100vw !important;
  height: 100vh !important;
  background-color: rgba(90, 90, 90, 0.8);
  /* z-index: 90000 !important; */

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.video-container-min{
  position:absolute;
  width: 200px;
  height: 200px;
  z-index: 90000 !important;
}
.video-header{

}
.meeting-btn-text {
  font-size: 0.8em;
  color:white;
}
.meeting-btn-default {
  background-color: rgba(60, 60, 60, 1.0) !important;
  border: none;
  font-size:1.5rem;
}
.meeting-btn-wipe {
  background-color: rgba(60, 60, 60, 1.0) !important;
  border: none;
  font-size:1.0rem;
}
.main-video-container{
  padding:0 !important;
  overflow: hidden;
}
.main-video-wrapper{
  height:100%;
  width:100%;

}
.main-video {
  /* border: 1px solid white; */
  /* width: 100%;
        aspect-ratio: 16 / 9; */
  object-fit: contain;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.main-attendee-info{
  /* align-items: center;
  bottom: 0px;
  box-sizing: border-box;
  display: flex;
  padding: 8px 0;
  position: absolute;
  width: 100%; */
  align-items: center;
  /* bottom: 0px; */
  box-sizing: border-box;
  display: flex;
  padding: 8px 0;
  position: absolute;
  width: 100%;
  z-index: 9999 !important;
  color:white;
}
.tile-info{
  font-size: 8px;
  top:90px;
  position: absolute;
  z-index: 9999 !important;
  color: white;
}
.tile-volume{
  z-index:9999;
  position:absolute !important;
  left:20px;
  top:10px;
  color:#E4007F;
  transition-property: opacity;
  transition-duration: 0.1s;
  transition-timing-function : ease-out;
  opacity: 0;
}
.tileVolumeOn {
  opacity: 1;
}
.tileVolumeOff {
  opacity: 0;
}
.nomirror {
  transform: rotateY(0deg) !important;
}
.video-footer{
  padding-top: 10px;
  background-color: rgba(60, 60, 60, 1.0);
}
.layout-container {
  position: relative;
  width: 100%;
  height: 100vh;
  top: -77px;
  padding-top: 77px;
}
.metting-panel-parent {
  width: 100%;
  height: 100%;
  padding-bottom: 70px;
}
.meeting-menu {
  width: 100%;
  height: 70px;
  background-color: white;
  padding: 5px;
  position: relative;
  top: -70px;
}
.tile-video {
  width: 120px;
  height:120px;
  /* height: 240px; */
  /* border: 1px solid gray; */
  /* width: 100%; */
  background-color: #333333;
  object-fit: contain;
  /* aspect-ratio: 16 / 9; */
}

.audio-style {
  display: none;
}

.pin-btn-off{
  width:20px;
  height:20px;
  position: absolute;
  left: 110px;
  top: 5px;
  transform: rotate(45deg);
  background-color: transparent;
  border: 0px;
  color:gray
}
.pin-btn-on{
  width:20px;
  height:20px;
  position: absolute;
  left: 110px;
  top: 5px;
  transform: rotate(45deg);
  background-color: transparent;
  border: 0px;
  color:#E4007F
}
.dummyAttendee {
  display: none;
  /* position: absolute;
  left: -10px;
  top: 10px;
  width: 1px;
  height:1px; */
}
.mic-property-btn {
  width: auto !important;
  height: auto !important;
  color:white !important;
}
.video-property-btn {
  width: auto !important;
  height: auto !important;
  color:white !important;
}
.video-container-wipe {
  width: 240px !important;
  height: 180px !important;
  transition: width 0.5s, height 0.5s;
}
.video-header-wipe {
  display: none !important;
}
.video-footer-wipe {
  display: none !important;
}
.meeting-btn-container-wipe {
  position:absolute;
  left: 0px;
  bottom: 0px;
}

.markdown {
  margin-top: 0;
  margin-bottom: 0;
}

a.markdown:link {
  color: rgb(39, 137, 144);
}

a.markdown:visited {
  color: rgb(39, 137, 144);
}

a.markdown:hover {
  color: rgb(39, 137, 144);
}

a.markdown:active {
  color: rgb(39, 137, 144);
}

.message-bubble-self {
  border-radius: 5px;
  background-color: rgb(238, 248, 248);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(134, 209, 215);
  padding: 0.3rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
}

.message-bubble-other {
  border-radius: 5px;
  background-color: rgb(241, 241, 241);
  border-style: none;
  padding: 0.3rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
}

.message-bubble-sender {
  font-weight: bold;
  margin-left: 1.3rem;
  margin-right: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.drawer-menu-wrapper {
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: rgba(250,250,250,1);
  border-left:1px solid rgba(150,150,150,0.9);
  box-shadow: 0 -10px 25px 0 rgba(0, 0, 0, .5);
}
.drawer-menu-wrapper-hide {
  right: -300px;
}
</style>

<script>

import {
  ConsoleLogger,
  DefaultActiveSpeakerPolicy,
  DefaultModality,
  LogLevel,
  DefaultDeviceController,
  DefaultMeetingSession,
  MeetingSessionConfiguration,
  AsyncScheduler,
  DataMessage
} from "amazon-chime-sdk-js";

import { API } from "aws-amplify";
import MeetingModel from "../../appModel/meeting/MeetingModel";
import UserInfo from "@/appUtils/UserInfo"
// import markdownIt from "markdown-it"
import DateUtil from "@/appUtils/DateUtil"
import { json } from 'd3';

export default {
  components: {
  },
  setup() {},
  data() {
    return {
      sk: "",
      micstatus: true,
      mainPanelSize: 70,
      isStarted: true,
      meetingSession: {},
      audioVideo: {},
      tiles: {},
      bindElement: [],
      roster: {},
      audioInput: 0,
      audioInputList: [],
      videoInput: 0,
      videoInputList: [],
      speaker: 0,
      speakerOutputList: [],
      videoQuality: 0,
      videoQualityList: [],
      isStartShare: false,
      pinStatus: {},
      showThumbnailTile: false,
      showActiveSpeakerScores: false,
      dummyCanvas: null,
      selfAudioLevel: 0,
      isSpeaking: {},
      localAttendee: null,
      wipeMode: false,
      meeting: {},
      attendee: {},
      selectedMeeting: null,
      waitTime: 0,
      lastReceivedMessageTimestamp: 0,
      markdown: null,
      chatDrawerFlg: false,
      mainTileId: 0,
      isMute: false,
      isVideoOff: false,
      inDrawingMode: false,
      drawCanvas: null,
      context: null,
      inDrawing: false,
      inErasing: false,
      previous: {
        x: 0,
        y: 0
      }
    };
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    //インスタンスが DOM 要素にマウントされた後

    this.sk = this.$route.query.sk
    console.log(`sk:${this.sk}`)
    this.isStarted = false;

    // this.markdown = new markdownIt({ linkify: true })
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    // alert(`mounted`)

    //this.meetingStartProc()
    const _this = this
    this.prepareMeeting().then(function(){
      console.log(`ミーティング開始処理完了`)

      // ★
      console.log('canvas start')
      // _this.drawCanvas = _this.$refs.canvas
      // _this.drawCanvas = document.getElementById('drawCanvas')
      _this.drawCanvas = document.querySelector('#drawCanvas')
      console.log(_this.drawCanvas)
      _this.context = _this.drawCanvas.getContext('2d')
      _this.context.lineCap = 'round';
      _this.context.lineJoin = 'round';
      _this.context.lineWidth = 5;
      _this.context.strokeStyle = '#ff0000';
    }).catch(function(e) {
      console.log(e)
      console.log(`ミーティング開始失敗 ${JSON.stringify(e)}`)
    })

  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
    this.leaveMeeting().then(function() {
      console.log(`退席`)
    }).catch(function() {
      console.log(`退席失敗`)
    })
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  computed: {
    thumbnailTiles() {
      let displayTiles = {}
      let keys = Object.keys(this.tiles)
      for (let key in keys) {
        if (this.tiles[key].state().boundAttendeeId && !this.tiles[key].tileState.isContent) {
          // 参加者ID
          let attendeeId = this.tiles[key].state().boundAttendeeId
          if (this.roster[attendeeId]) {
            if (attendeeId != this.attendee.Attendee.AttendeeId) {
              if (!this.roster[this.tiles[key].state().boundAttendeeId].active) {
                displayTiles[key] = this.tiles[key]
              }
            }
          }
        }
      }
      return displayTiles
    },
    numberOfAttendee() {
      let count = 0
      let keys = Object.keys(this.tiles)
      for (let key in keys) {
        if (this.tiles[key].state().boundAttendeeId) {
          // 参加者ID
          if (!this.tiles[key].tileState.isContent) {
            count += 1
          }
        }
      }
      return count
    }
  },

  methods: {
    showLoading() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    // 【ここから】 ミーティングへの参加処理
    /**
     * ミーティング準備メイン処理
     */
    async prepareMeeting() {
      try {
        console.log(`prepareMeeting`)
        // ローディングを表示
        this.loader = this.showLoading() //this.$loading.show();
        // URLパラメータで指定されたミーティングルーム情報を取得する
        this.selectedMeeting = await MeetingModel.getMeeting({sk: this.sk})
        let inValidMeetingId = false
        if (this.selectedMeeting.meeting.meetingId) {
          console.log(`ミーティングIDあり ${this.selectedMeeting.meeting.meetingId}`)
          // ミーティングIDがあれば、有効なミーティングIDであるかチェックする
          try {
            // DB上のミーティングIDでChimeミーティングの開始と自身の参加まで行う
            await this.getMeeting(this.selectedMeeting)
          } catch (e) {
            console.log(`getMeetingで例外`)
            // 例外が起きた場合はミーティングIDが無効と判断する
            inValidMeetingId = true
          }
        }
        // ミーティングID無しまたはミーティング情報が無効である場合はミーティングID再取得を行いミーティング開始を試みる
        if (!this.selectedMeeting.meeting.meetingId || inValidMeetingId) {
          console.log(`新規ミーティングIDの発行`)

          await this.getMeetingId(this.selectedMeeting.pk, this.selectedMeeting.sk, this.selectedMeeting.meeting.meetingId)
        }
      } catch (e) {
        console.log(`${JSON.stringify(e)}`)
        this.loader.hide()
        throw e
      }

    },

    /**
     * ChimeミーティングID取得
     */
    async getMeetingId(pk, sk, compareMeetingId = "") {
      try {
        // ミーティングID発行APIの呼び出し
        this.loadingProgressMessage = "ミーティングを作成中"
        const meetingApiName = 'mczmeetingrestapi'
        let meetingApiPath = '/meeting'

        const params = {
          headers: {},
          response: true,
          body: {
            pk: pk,
            sk: sk
          }
        };
        const result = await API.post(meetingApiName, meetingApiPath, params)
        console.log(`mczmeetingrestapi end ${JSON.stringify(result)}`)
        // ミーティングID発行完了まで一定時間待機（初回は１秒待機）
        setTimeout(function() {
          console.log(`待機回数 : ${this.waitTime}`)
          this.waitTime += 1
          this.checkMeetingId(pk, sk, compareMeetingId)
        }.bind(this), 1000)

      } catch (e) {
        console.log(`error ${JSON.stringify(e)} pk:${pk} sk:${sk}`)
        throw e
      }
    },
    /**
     * ミーティングIDが発行されるまで一定回数待機する
     */
    checkMeetingId(pk, sk, compareMeetingId) {
      try {
        console.log(`checkMeetingId pk:${pk} sk:${sk}`)
        MeetingModel.getMeeting({pk: pk, sk: sk}).then(function(result) {
          if (result) {
            // ミーティング取得前のIDと異なるIDとなっているかチェック
            if (result.meeting.meetingId != compareMeetingId) {
              // ミーティングIDが取得できれば次へ
              this.getMeeting(result)
              return
            } else {
              // ミーティングIDの発行を待つ
              if (this.waitTime <= 10) {
                // 10回までまつ
                this.waitTime += 1
                setTimeout(function() {
                  console.log(`待機回数 : ${this.waitTime}`)
                  this.checkMeetingId(pk, sk, compareMeetingId)
                }.bind(this), 500)
                return
              } else {
                alert(`タイムアウト`)
                this.loader.hide()
                return
              }

            }
          } else {
            this.loader.hide()
            alert(`対象データが存在しないため待機を終了`)
            return
          }
        }.bind(this))

      } catch (e) {
        console.log(`${JSON.stringify(e)}`)
        this.loader.hide()
        throw e
      }

    },
    /**
     * ミーティングIDからミーティング情報を取得する。
     */
    async getMeeting(meetingRow) {
      try {
        console.log(`getMeeting`)

        // ミーティング情報取得の呼び出し
        const meetingApiName = 'mczmeetingrestapi'
        let meetingApiPath = '/meeting'
        meetingApiPath = `${meetingApiPath}/${meetingRow.meeting.meetingId}`
        console.log(`getMeeting ${meetingApiPath}`)
        const params = {
          headers: {},
          response: true,
        };
        const result = await API.get(meetingApiName, meetingApiPath, params)

        if (result.data) {
          if (!result.data.meeting) {
            throw new Error(`ミーティング情報取得失敗`)
          }
          console.log(`mczmeetingrestapi result ${JSON.stringify(result.data)}`)
          this.meeting = result.data.meeting

          // 参加情報を取得
          await this.attendMeeting(meetingRow)
          console.log(`ミーティング参加処理完了`)
          // ミーティング開始
          await this.meetingStartProc()

        } else {
          throw new Error(`ミーティング情報取得失敗`)
        }

      } catch (e) {
        console.log(`${JSON.stringify(e)}`)
        this.loader.hide()
        throw e
      }
    },
    /**
     * ミーティング参加者情報を取得して、ミーティングに参加する。
     */
    async attendMeeting(meetingRow) {
      try {
        // ミーティングID発行APIの呼び出し
        this.loadingProgressMessage = "ミーティングに参加します"

        const userInfo = await UserInfo.getUserInfo()

        const attendeeApiName = 'mczattendeeresstapi'
        let attendeeApiPath = '/attendee'
        attendeeApiPath = `${attendeeApiPath}/${encodeURIComponent(meetingRow.meeting.meetingId + "#" + userInfo.userName)}`
        const params = {
          headers: {},
          response: true,
        };
        const result = await API.get(attendeeApiName, attendeeApiPath, params)
        if (result.data) {
          this.attendee = result.data.attendee
          console.log(`result.data.attendee: ${JSON.stringify(result.data.attendee)}`)
          this.localAttendee = this.attendee.Attendee

          // Chimeセッションを作成する
          const logger = new ConsoleLogger('ChimeMeetingLogs', LogLevel.INFO);
          const deviceController = new DefaultDeviceController(logger);
          const configuration = new MeetingSessionConfiguration(this.meeting, this.attendee);
          this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);

        }

      } catch (e) {
        console.log(`${JSON.stringify(e)}`)
        this.loader.hide()
        throw e
      }
    },

    // 【ここまで】 ミーティングへの参加処理

    // 【ここから】 ミーティング開催中に関する処理

    changeWipeMode() {
      this.wipeMode = !this.wipeMode
    },
    captureImage() {
      console.log(`start captureImage`)
      let tileId = this.mainTileId
      console.log(`tileId: ${tileId}`)
      if (tileId) {
        const imageData = this.meetingSession.audioVideo.captureVideoTile(tileId)
        if (imageData) {
          this.imageToBase64Link(imageData)
        }
      }
    },
    imageToBase64Link(imageData) {
      console.log(`start imageToBase64Link`)
      if (!imageData){
        return
      }
      let canvas = document.createElement("canvas");
      canvas.width = imageData.width
      canvas.height = imageData.height

      let ctx = canvas.getContext('2d')
      ctx.putImageData(imageData, 0, 0)
      let dataURL = canvas.toDataURL('image/jpeg', 0.85)
      // let dataURL = canvas.toDataURL("image/png")
      let link = document.createElement("a")
      link.href = dataURL
      const fileName = DateUtil.dateStringBase(new Date(), 'YYYY-MM-DD-HH-mm-ss-SSS')
      console.log(`fileName ${fileName}`)
      link.download = `${fileName}.jpg`
      // link.download = `${fileName}.png`
      link.click();

    },
    createDummyCanvas(name) {
      // this.dummyCanvas = document.createElement("canvas");
      // this.dummyCanvas.width = 640;
      // this.dummyCanvas.height = 480;

      // if (document.getElementById('dummyAttendee')) {
      //   // alert('要素あり')
      //   document.body.removeChild(document.getElementById('dummyAttendee'))
      // }
      // if (document.getElementById('dummyAttendee')) {
      //   // alert('まだある')
      // } else {
      //   // alert('もうない')
      // }

      // this.dummyCanvas.setAttribute('id', 'dummyAttendee');
      // this.dummyCanvas.setAttribute('style', 'display:none');
      // document.body.appendChild(this.dummyCanvas);
      // if (document.getElementById('dummyAttendee')) {
      //   // alert('できた')
      // }
      this.dummyCanvas = this.$refs.dummyAttendee
      let ctx = this.dummyCanvas.getContext('2d');
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, this.dummyCanvas.width, this.dummyCanvas.height);
      ctx.fillStyle = "white";

      //ctx.font = "28px serif";
      //ctx.fillText(`name`, 10, 50);

      ctx.beginPath();
      let fontSize = 24

      ctx.font = "bold " + fontSize + "px Arial, meiryo, sans-serif" ;
      let textWidth = ctx.measureText(name).width;

      ctx.fillText(name, (this.dummyCanvas.width - textWidth) / 2, fontSize)


      // let img = new Image();
      // img.onload = function() {
      //   //let ctx = c.getContext('2d');
      //   let ctx = this.dummyCanvas.getContext('2d');
      //   ctx.fillStyle = "white";
      //   ctx.drawImage(img, 0, 0, 200, 200);

      // }.bind(this)
      // img.src = 'http://localhost:8080/video-slash-solid.svg';
    },

    async meetingStartProc() {
      this.isStarted = true;

      await this.getPermissions()
      await this.showPreview(this.videoInput);
      const user = await UserInfo.getUserInfo()

      // 音声入力ソースをChime SDKにセット
      if (this.audioInput) {
        // alert(`音声あり`)
        await this.meetingSession.audioVideo.chooseAudioInputDevice(
          this.audioInput
        );
      }

      // 映像入力ソースをChime SDKにセット
      if (this.videoInput) {
        // 入力デバイスがある場合
        await this.meetingSession.audioVideo.chooseVideoInputDevice(this.videoInput)
      } else {
        // ない場合
        // await this.meetingSession.audioVideo.chooseVideoInputDevice(null)
        const blueScreen = DefaultDeviceController.createEmptyVideoDevice()
        await this.meetingSession.audioVideo.chooseVideoInputDevice(blueScreen)
        // this.createDummyCanvas(user.userName)
        // await this.meetingSession.audioVideo.chooseVideoInputDevice(this.dummyCanvas.captureStream(15))
        // this.$nextTick(async function(){
        //   this.createDummyCanvas(user.userName)
        // }.bind(this))
      }

      await this.meetingSession.audioVideo.setDeviceLabelTrigger(async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        return stream;
      });

      // ミーティング状態変化の監視クラスを定義
      const observer = {
        // 映像ソースの増減時のデリゲート(参加者の増減)
        videoTileDidUpdate: function(tileState) {
          if (!tileState.boundAttendeeId) {
            return;
          }
          // this.updateTiles();
          const tiles = this.meetingSession.audioVideo.getAllVideoTiles();
          console.log(`videoTileDidUpdate tiles:${tiles.length}`);
          tiles.forEach(function(tile){
          // Object.keys(this.tiles).forEach(function(tileId){
            //alert(tileId)
            // if(this.tiles[tileId]) {
            // let tile = this.tiles[tileId]
            // this.tiles.forEach(function(tile){
            // let tileId = tile.tileState.tileId;
            // 固定表示状態管理を追加
            if (!this.pinStatus[tile.tileState.tileId]) {
              this.pinStatus[tile.tileState.tileId] = false;
            }
            // }
          }.bind(this))

          // this.tiles = tiles;
          this.tiles = tiles.filter((item) => {
            return item.tileState.tileId != null
          });
          this.$nextTick(this.updateTiles);
        }.bind(this),
        videoTileWasRemoved: function(tileId) {
          console.log(`start videoTileWasRemoved ${tileId}`)
          // const keys = Object.keys(this.tiles)

          //Object.keys(this.tiles).forEach(function(id) {
          this.tiles = this.tiles.filter((item) => {
            return item.tileState.tileId != null || item.tileState.tileId === tileId
          })
          // for (let index = 0; index < this.tiles.length; index++) {
          //   if (!this.tiles[index].tileState.tileId || this.tiles[index].tileState.tileId === tileId) {
          //     delete this.tiles[index];
          //     console.log(`タイル削除`)
          //     break
          //   }
          // }
        }.bind(this)
      };

      const callback = (presentAttendeeId, present, externalUserId,) => {
        if (present) {
          //入室時
          const isContentAttendee = new DefaultModality(presentAttendeeId).hasModality(DefaultModality.MODALITY_CONTENT);
          // roaster(参加者) が存在しなければ追加する。
          if (!this.roster[presentAttendeeId]) {
            this.roster[presentAttendeeId] = {
              name: `${presentAttendeeId}`,
              extendName: externalUserId.split('#').slice(-1)[0] + (isContentAttendee ? ' «Content»' : ''),
            };
          }
        } else {
          // 退出時
          delete this.roster[presentAttendeeId];
          // this.updateRoster();
          return;
        }
        this.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(presentAttendeeId, (attendeeId, volume, muted, signalStrength) => {
          if (!this.roster[attendeeId]) {
            console.log(`realtimeSubscribeToVolumeIndicator nothing ${attendeeId}`)
            return;
          }
          if (volume !== null) {

            this.roster[attendeeId].volume = Math.round(volume * 100);
            console.log(`realtimeSubscribeToVolumeIndicator volume ${this.roster[attendeeId].volume} ${attendeeId}`)
            this.setSelfAudioLevel()
            if (this.roster[attendeeId].volume - 0 > 0) {
              this.isSpeaking[this.tileIdForAttendeeId(attendeeId)] = true
              console.log(`isSpeaking ${this.tileIdForAttendeeId(attendeeId)} ${attendeeId} true`)
            } else {
              this.isSpeaking[this.tileIdForAttendeeId(attendeeId)] = false
              console.log(`isSpeaking ${this.tileIdForAttendeeId(attendeeId)} ${attendeeId} false`)
            }
          }
          if (muted !== null) {
            this.roster[attendeeId].muted = muted;
            console.log(`realtimeSubscribeToVolumeIndicator muted ${this.roster[attendeeId].muted} ${attendeeId}`)
          }
          if (signalStrength !== null) {
            this.roster[attendeeId].signalStrength = Math.round(signalStrength * 100);
            console.log(`realtimeSubscribeToVolumeIndicator signalStrength ${this.roster[attendeeId].signalStrength} ${attendeeId}`)

          }
          // this.updateRoster();
        })
      };
      // 参加者の状況を監視するハンドラを登録
      this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(
        callback
      );

      this.activeSpeakerHandler = (attendeeIds) => {
        console.log(`activeSpeakerHandler start`)
        // 一旦全部falseに
        for (const attendeeId in this.roster) {

          this.roster[attendeeId].active = false;
        }
        // alert(`attendeeId:${attendeeId}`)
        for (const attendeeId of attendeeIds) {
          if (this.roster[attendeeId]) {
            this.roster[attendeeId].active = true;
            break; // only show the most active speaker
          }
        }
        //this.layoutFeaturedTile();
      };

      const scoreHandler = (scores) => {
        for (const attendeeId in scores) {
          if (this.roster[attendeeId]) {
            this.roster[attendeeId].score = scores[attendeeId];
            console.log(`scoreHandler score: ${scores[attendeeId]} id:${attendeeId}`)
          }
        }
        //this.updateRoster();
      };
      let policy = new DefaultActiveSpeakerPolicy();
      //this.meetingSession.audioVideo.subscribeToActiveSpeakerDetector(policy, this.activeSpeakerHandler, scoreHandler, this.showActiveSpeakerScores ? 100 : 0);

      this.meetingSession.audioVideo.addObserver(observer);
      this.meetingSession.audioVideo.addContentShareObserver(observer);

      //this.audioVideo.subscribeToActiveSpeakerDetector(new amazon_chime_sdk_js_1.DefaultActiveSpeakerPolicy(), this.activeSpeakerHandler, scoreHandler, this.showActiveSpeakerScores ? 100 : 0);
      // this.setupSubscribeToAttendeeIdPresenceHandler();

      // this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(this.attendeeIdPresenceHandler);

      this.showActiveSpeakerScores = false
      this.meetingSession.audioVideo.subscribeToActiveSpeakerDetector(policy, this.activeSpeakerHandler, scoreHandler, this.showActiveSpeakerScores ? 100 : 0);
      // this.meetingSession.audioVideo.addObserver(observer);
      this.meetingSession.audioVideo.startLocalVideoTile();

      const audioOutputElement = this.$refs.audio1;
      this.meetingSession.audioVideo.bindAudioElement(audioOutputElement);
      this.meetingSession.audioVideo.start();
      this.setupDataMessage('chat')
      this.setupDataMessage('whiteboard')
      this.setupEventListener()

      // ★canvas用追加

      this.loader.hide()
    },
    setupDataMessage(topic) {
      this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage(topic, (dataMessage) => {
        this.dataMessageHandler(dataMessage);
      });
    },
    dataMessageHandler(dataMessage) {
      console.log("recieving data!!!!!")

      if (!dataMessage.throttled) {
        const isSelf = dataMessage.senderAttendeeId === this.meetingSession.configuration.credentials.attendeeId;
        if (dataMessage.timestampMs <= this.lastReceivedMessageTimestamp) {
          return;
        }

        switch (dataMessage.topic) {
        case 'chat': {
          this.lastReceivedMessageTimestamp = dataMessage.timestampMs;
          const messageDiv = document.getElementById('receive-message');
          const messageNameSpan = document.createElement('div');
          messageNameSpan.classList.add('message-bubble-sender');
          messageNameSpan.innerText = dataMessage.senderExternalUserId.split('#').slice(-1)[0];
          const messageTextSpan = document.createElement('div');
          messageTextSpan.classList.add(isSelf ? 'message-bubble-self' : 'message-bubble-other');
          messageTextSpan.innerHTML = dataMessage.text()
          const appendClass = (element, className) => {
            for (let i = 0; i < element.children.length; i++) {
              const child = element.children[i];
              child.classList.add(className);
              appendClass(child, className);
            }
          };
          appendClass(messageTextSpan, 'markdown');
          if (this.lastMessageSender !== dataMessage.senderAttendeeId) {
            messageDiv.appendChild(messageNameSpan);
          }
          this.lastMessageSender = dataMessage.senderAttendeeId;
          messageDiv.appendChild(messageTextSpan);
          messageDiv.scrollTop = messageDiv.scrollHeight;
          break;
        }
        case 'whiteboard': {
          const json = JSON.parse(Buffer.from(dataMessage.data).toString());
          const data = json.content;
          console.log(data)
          switch (data.mode) {
          case 'draw':
            this.draw(data.startXR, data.startYR, data.endXR, data.endYR, data.stroke, data.lineWidth);
            break;
          case 'erase':
            this.eraseReceiveData(data.startXR, data.startYR, data.endXR, data.endYR);
            break;
          case 'clear':
            this.clearDrawing();
            break;
          }
          break;
        }
        }
      }
      else {
        //this.log('Message is throttled. Please resend');
      }
    },
    setupEventListener() {
      const sendMessage = () => {
        AsyncScheduler.nextTick(() => {
          const textArea = document.getElementById('send-message');
          const textToSend = textArea.value.trim();
          if (!textToSend) {
            return;
          }
          textArea.value = '';
          this.meetingSession.audioVideo.realtimeSendDataMessage('chat', textToSend, 300000);
          // echo the message to the handler
          this.dataMessageHandler(new DataMessage(Date.now(), 'chat', new TextEncoder().encode(textToSend), this.meetingSession.configuration.credentials.attendeeId, this.meetingSession.configuration.credentials.externalUserId));
        });
      };
      const textAreaSendMessage = document.getElementById('send-message');
      textAreaSendMessage.addEventListener('keydown', e => {
        if (e.keyCode === 13) {
          if (e.shiftKey) {
            textAreaSendMessage.rows++;
          }
          else {
            e.preventDefault();
            sendMessage();
            textAreaSendMessage.rows = 1;
          }
        }
      });

    },
    setSelfAudioLevel(){
      const localAttendeeId = this.localAttendee.AttendeeId
      // const localAttendeeId = this.tileIdToAttendeeId(this.localTileId())
      let level = 0

      if (localAttendeeId) {
        level = this.roster[localAttendeeId].volume
      }

      this.selfAudioLevel = level > 0 ? level + Math.round((100 - level)/2) : 0
      console.log(`selfAudioLevel ${localAttendeeId} ${level} ${this.selfAudioLevel}`)
    },
    // 映像ソース固定のピンアイコンボタンのスタイルを返す
    pinClass(tileId) {
      return this.pinStatus[tileId] ? "on" : "off"
    },
    // 映像ソース固定のピンアイコンボタン押下時のイベント
    togglePin(tileId) {
      this.pinStatus[tileId] = !this.pinStatus[tileId]
    },
    /**
     * 権限取得
     */
    async getPermissions() {
      try {
        let streamAudio = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        streamAudio.getTracks().forEach((track) => track.stop());
        await this.setupAudioDeviceList();
      } catch (e) {
        //alert('音声入出力無し')
      }
      try {
        let streamVideo = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        streamVideo.getTracks().forEach((track) => track.stop());
        await this.setupVideoDeviceList();
      } catch (e) {
        //alert('ビデオカメラ無し')
        this.videoInputList = [{ text: `なし`, value: `` }]
        this.videoInput = ``

        this.videoQualityList = [{ text: `なし`, value: `` }]
        this.videoQuality = ``
      }

    },
    /**
     * 映像ソース変更
     */
    async changeVideoDevice() {
      const previewElement = this.$refs.videoPreview;
      const deviceId = this.videoInput;
      await this.showPreview(deviceId);
    },
    /**
     * プレビュー表示
     */
    async showPreview(deviceId) {
      try {
        console.log(`showPreview deviceId:${deviceId}`)
        if (deviceId) {
          console.log(`showPreview deviceId is exist`)
          await this.meetingSession.audioVideo.chooseVideoInputDevice(deviceId);
          this.meetingSession.audioVideo.startVideoPreviewForVideoInput(
            document.getElementById("video-preview")
          );
        } else {
          console.log(`showPreview deviceId is nothing`)
          //await this.meetingSession.audioVideo.chooseVideoInputDevice(deviceId)
          await this.meetingSession.audioVideo.chooseVideoInputDevice(null);
          this.meetingSession.audioVideo.stopVideoPreviewForVideoInput(
            document.getElementById("video-preview")
          );
        }
      } catch (e) {
        console.log(`showPreview error ${JSON.stringify(e, null, "\t")}`)
      }

    },
    /**
     * 音声デバイスリストを取得
     */
    async setupAudioDeviceList() {
      this.audioInputList = this.populateList(
        await this.meetingSession.audioVideo.listAudioInputDevices(),
        `マイク`,
        { text: `なし`, value: `` }
      );
      this.speakerOutputList = this.populateList(
        await this.meetingSession.audioVideo.listAudioOutputDevices(),
        `スピーカー`,
        { text: `なし`, value: `` }
      );
      this.audioInput = this.audioInputList[0].value;
      console.log(`setupAudioDeviceList this.audioInput:${this.audioInput}`)
      this.speaker = this.speakerOutputList[0].value;

      this.showPreview(this.videoInput);
    },
    /**
     * 映像デバイスリストを取得
     */
    async setupVideoDeviceList() {
      this.videoInputList = this.populateList(
        await this.meetingSession.audioVideo.listVideoInputDevices(),
        `ビデオ入力デバイス`,
        { text: `なし`, value: `` }
      );
      console.log(`ビデオデバイス数: ${this.videoInputList.length}`)

      this.videoQualityList.push({
        //640×360
        text: `360p (nHD) @ 15 fps (600 Kbps max)`,
        value: `360p`,
      });
      this.videoQualityList.push({
        //960x540
        text: `540p (qHD) @ 15 fps (1.4 Mbps max)`,
        value: `540p`,
      });
      this.videoQualityList.push({
        //1280x720
        text: `720p (HD) @ 15 fps (1.4 Mbps max)`,
        value: `720p`,
      });

      this.videoInput = this.videoInputList[0].value;
      this.videoQuality = this.videoQualityList[0].value;
    },
    /**
     *
     */
    populateList(devices, genericName, emptyItem) {
      //const devices = this.meetingSession.audioVideo.listAudioInputDevices()
      //alert(JSON.stringify(devices))

      let list = [];
      for (let i = 0; i < devices.length; i++) {
        list.push({
          text: devices[i].label || `${genericName} ${i + 1}`,
          value: devices[i].deviceId,
        });
      }
      if (emptyItem) {
        list.push(emptyItem);
      }
      console.log(`populateList genericName ${genericName} ${JSON.stringify(list, null, "\t")}`)
      return list;
    },
    attendeeIdPresenceHandler(attendeeId, present, externalUserId, dropped) {
      this.log(`${attendeeId} present = ${present} (${externalUserId})`);

      const isContentAttendee = new DefaultModality(attendeeId).hasModality(DefaultModality.MODALITY_CONTENT);
      const isSelfAttendee = new DefaultModality(attendeeId).base() ===
        this.meetingSession.configuration.credentials.attendeeId;
      if (!present) {
        delete this.roster[attendeeId];
        this.updateRoster();
        return;
      }

      if (!this.roster[attendeeId]) {
        this.roster[attendeeId] = {
          name: externalUserId.split('#').slice(-1)[0] + (isContentAttendee ? ' «Content»' : ''),
        };
      }
      this.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, (attendeeId, volume, muted, signalStrength) => {
        if (!this.roster[attendeeId]) {
          console.log(`realtimeSubscribeToVolumeIndicator nothing`)
          return;
        }
        if (volume !== null) {

          this.roster[attendeeId].volume = Math.round(volume * 100);
          console.log(`realtimeSubscribeToVolumeIndicator volume ${this.roster[attendeeId].volume}`)

        }
        if (muted !== null) {
          this.roster[attendeeId].muted = muted;
          console.log(`realtimeSubscribeToVolumeIndicator muted ${this.roster[attendeeId].muted}`)
        }
        if (signalStrength !== null) {
          this.roster[attendeeId].signalStrength = Math.round(signalStrength * 100);
          console.log(`realtimeSubscribeToVolumeIndicator signalStrength ${this.roster[attendeeId].signalStrength}`)
        }
        this.updateRoster();
      })
    },

    /**
     * 映像ソースの増減時に呼び出される
     */
    videoTileDidUpdate: (tileState) => {
      if (!tileState.boundAttendeeId) {
        return;
      }
      // this.updateTiles();
      const tiles = this.meetingSession.audioVideo.getAllVideoTiles();
      console.log("tiles", tiles);

      this.tiles = tiles.filter((item) => {
        return item.tileState.tileId != null
      });
      // DOM状態更新後にthis.updateTilesを呼び出す
      this.$nextTick(this.updateTiles);
    },
    async shareDisplay() {
      this.meetingSession.audioVideo.unbindVideoElement(this.mainTileId)
      this.$nextTick(async function(){
        await this.meetingSession.audioVideo.startContentShareFromScreenCapture();
      }.bind(this))

    },
    async stopShareDisplay() {
      await this.meetingSession.audioVideo.stopContentShare();

      this.$nextTick(function(){
        setTimeout(function() {
          //this.meetingSession.audioVideo.unbindVideoElement(this.mainTileId)
          this.updateTiles()
          this.meetingSession.audioVideo.pauseVideoTile(this.mainTileId)
          this.meetingSession.audioVideo.unpauseVideoTile(this.mainTileId)
          this.isStartShare = false;
        }.bind(this), 500)
      }.bind(this));
    },
    /**
     * サムネイルタイルを表示するかの制御
     */
    isShowTile(tileId) {
      return true
      // タイルが１つだけであるか
      if (Object.keys(this.tiles).length == 1) {
      // if (this.tiles.length == 1) {
        // 自身のタイルでなければ表示する(trueを返す)
        return tileId != this.localTileId()
      }
      return true
    },
    /**
     * タイル状態の更新
     * サムネイル映像、メイン映像の表示に関する処理
     */
    updateTiles() {
      //alert(`updateTiles`)
      let flg = false;
      console.log(`updateTiles: ${this.tiles.length}`)
      // Object.keys(this.tiles).forEach(function(id) {
      this.tiles.forEach(function(tile){
        // let tile = this.tiles[id]
        let tileId = tile.tileState.tileId;
        let videoId = `video${tileId}`;
        if (!this.bindElement.includes(videoId)) {
          this.bindElement.push(videoId);
          let videoElement = this.$refs[videoId];
          if (videoElement) {
            console.log(`updateTiles:サムネイル tileId:${tileId}`)
            this.meetingSession.audioVideo.bindVideoElement(
              tileId,
              videoElement
            );
          }
        }
        let localId = this.localTileId();
      }.bind(this));

      // 画面共有コンテンツであれば優先して表示する
      // Object.keys(this.tiles).forEach(function(id) {
      this.tiles.forEach(function(tile){
        // let tile = this.tiles[id]
        console.log(`tile.isContent:: ${tile.tileState.isContent}`);
        if (tile.tileState.isContent && !flg) {
          flg = true;
          let tileId = tile.tileState.tileId;
          this.isStartShare = true;
          console.log(`updateTiles isStartShare: ${this.isStartShare}`)
          const mainElement = this.$refs.mainVideo;
          if (mainElement) {
            console.log(`updateTiles:画面共有コンテンツ tileId:${tileId}`)
            this.meetingSession.audioVideo.bindVideoElement(
              tileId,
              mainElement
            );
            this.mainTileId = tileId
          }
        }
      }.bind(this));

      // 他のユーザの画面を表示する
      // Object.keys(this.tiles).forEach(function(id) {
      // let tile = this.tiles[id]
      this.tiles.forEach(function(tile){
        let tileId = tile.tileState.tileId;
        let localId = this.localTileId();
        if (tileId != localId && !flg) {
          flg = true;
          const mainElement = this.$refs.mainVideo;
          if (mainElement) {
            this.isStartShare = false;
            console.log(`updateTiles:他ユーザ tileId:${tileId}`)
            this.meetingSession.audioVideo.bindVideoElement(
              tileId,
              mainElement
            );
            this.mainTileId = tileId
          }
        }
      }.bind(this));

      // ここまで来てもメイン画面に何も表示されていなければ自身の画面を表示する
      if (!flg) {
        // Object.keys(this.tiles).forEach(function(id) {
        // let tile = this.tiles[id]
        this.tiles.forEach(function(tile){
          let tileId = tile.tileState.tileId;
          let localId = this.localTileId();
          if (tileId == localId && !flg) {
            flg = true;
            const mainElement = this.$refs.mainVideo;
            if (mainElement) {
              this.isStartShare = false;
              console.log(`updateTiles:自身の画面 tileId:${tileId}`)
              //this.meetingSession.audioVideo.unbindVideoElement(this.mainTileId)
            　
              this.meetingSession.audioVideo.bindVideoElement(
                tileId,
                mainElement
              );
              this.mainTileId = tileId

            }
          }
        }.bind(this));
      }
    },
    findContentTileId() {
      for (const tile of this.meetingSession.audioVideo.getAllVideoTiles()) {
        const state = tile.state();
        if (state.isContent) {
          return state.tileId;
        }
      }
      return null;
    },
    // メインで表示しているタイルIDを取得する
    activeTileId() {
      let contentTileId = this.findContentTileId();
      if (contentTileId !== null) {
        return contentTileId;
      }
      for (const attendeeId in this.roster) {
        if (this.roster[attendeeId].active) {
          return this.tileIdForAttendeeId(attendeeId);
        }
      }
      return null;
    },
    // メインで表示している参加者IDを取得する
    activeAttendeeId() {
      const activeTileId = this.activeTileId()
      return activeTileId.state().boundAttendeeId
    },
    // アクティブな参加者
    activeRoster(){
      let activeRoster = null
      for (const attendeeId in this.roster) {
        if (this.roster[attendeeId].active) {
          activeRoster = this.roster[attendeeId]
        }
      }

      if (activeRoster == null) {
        // 自身の参加者情報を返す
        return this.roster[this.localAttendee.AttendeeId]
        // const localTileId = this.localTileId()
        // if (localTileId) {
        //   const localAttendeeId = this.meetingSession.audioVideo.getLocalVideoTile().state().boundAttendeeId
        //   return this.roster[localAttendeeId]
        // }

      }
      return activeRoster
    },
    // AttendeeIdに紐づくTileIDを取得する
    tileIdForAttendeeId(attendeeId) {
      if (attendeeId) {
        console.log(`tileIdForAttendeeId ${attendeeId}`)
        for (const tile of this.meetingSession.audioVideo.getAllVideoTiles()) {
          const state = tile.state();
          if (state.boundAttendeeId === attendeeId) {
            return state.tileId;
          }
        }
      }
      return null;
    },
    tileIdToAttendeeId(tileId){
      if (tileId) {
        for (const tile of this.meetingSession.audioVideo.getAllVideoTiles()) {
          const state = tile.state();
          if (state.tileId === tileId) {
            return state.boundAttendeeId
          }
        }
      }
      return null
    },
    localTileId() {
      return this.meetingSession.audioVideo.hasStartedLocalVideoTile()
        ? this.meetingSession.audioVideo.getLocalVideoTile().state().tileId
        : null;
    },
    layoutFeaturedTile() {
      if (!this.meetingSession) {
        return;
      }
      //発言中のユーザをアクティブにする
    },
    async leaveMeeting() {
      try {
        this.meetingSession.audioVideo.stop();
      } finally {
        // this.$router.push({
        //   name: "MeetingMain",
        // });
        window.close();
      }
    },
    async onChangeMicDevice(value) {
      console.log(`onChangeMicDevice start  value:${value}`)

      if (value) {
        this.audioInput = value
        await this.meetingSession.audioVideo.chooseAudioInputDevice(
          this.audioInput
        );
      } else {
        this.isMute = false
        this.toggleMic()
      }

    },
    async onChangeVideoDevice(value) {
      console.log(`onChangeVideoDevice start  value:${value}`)

      if (value) {
        this.videoInput = value
        await this.meetingSession.audioVideo.chooseVideoInputDevice(
          this.videoInput
        );
      } else {

        this.isVideoOff = false
        this.toggleVideo()
      }

    },
    toggleMic() {
      if (this.isMute) {
        // ミュート解除
        this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
        this.isMute = !this.isMute
      } else {
        // ミュート
        this.meetingSession.audioVideo.realtimeMuteLocalAudio();
        this.isMute = !this.isMute
      }
    },
    toggleVideo() {
      this.$nextTick(function(){
        if (this.isVideoOff) {
          // ビデオをOFF→ONに切り替え
          try {
            if (this.videoInput) {
              // ビデオ入力有り
              // this.openVideoInputFromSelection(this.videoInput, false);
              this.meetingSession.audioVideo.startLocalVideoTile();
            }
            this.isVideoOff = false
          }
          catch (err) {
            console.log(`no video input device selected`)
          }
        }
        else {
          this.meetingSession.audioVideo.stopLocalVideoTile();
          this.isVideoOff = true
        }
      }.bind(this))
    },
    async endMeeting() {
      try {
        this.meetingSession.audioVideo.stop();
        let storeMeeting = this.$store.state.meeting;

        //alert(storeMeeting.selectedMeetingData.meeting.meetingId)

        let meetingId = storeMeeting.selectedMeetingData.meeting.meetingId;
        if (!meetingId) {
          alert(`ID未指定`);
          return;
        }

        const APIName = "meetingManager";
        const path = `/meeting/${meetingId}`;
        const myInit = {
          headers: {},
          response: true,
        };

        //alert(meetingId)

        const result = await API.del(APIName, path, myInit);
        //alert(JSON.stringify(result))
        if (result.data) {
          /**
           * {code: 0,meeting:chimecon.meeting,attendee:chimecon.attendee,message:``}
           */
          switch (result.data.code) {
          case 0:
            //alert("削除成功")
            let deleleteCondition = {
              pk: storeMeeting.selectedMeetingData.pk,
              sk: storeMeeting.selectedMeetingData.sk,
            };
            MeetingModel.deleteMeeting(deleleteCondition);

            break;
          case 1:
            alert(`エラー:${result.data.message}`);
            //エラー
            break;
          default:
            break;
          }
        }
      } catch (e) {
        console.log(JSON.stringify(e));
        throw e;
      } finally {

        // this.$router.push({
        //   name: "MeetingMain",
        // });
        window.close();
      }
    },
    // ★
    /**
     * 描画モードを設定します。
     * @param {boolean} enable 描画モードONの場合true、OFFの場合はfalse
     */
    setDrawingMode(enable) {
      this.inDrawingMode = enable;
    },
    // 描画
    drawing: function(offsetX, offsetY, movementX, movementY) {
      // console.log(this.inDrawing)
      // console.log(this.inDrawingMode)
      if (this.inDrawing && this.inDrawingMode) {
        if (!this.drawCanvas) {
          return;
        }
        console.log(`drawing`)
        const startX = offsetX - movementX
        const startY = offsetY - movementY

        const startXR = startX / this.drawCanvas.width
        const startYR = startY / this.drawCanvas.height
        const endXR = offsetX / this.drawCanvas.width
        const endYR = offsetY / this.drawCanvas.height

        if (this.inErasing){
          this.erase(startXR, startYR, endXR, endYR)
          this.sendDrawingBySignal(this.meetingSession.audioVideo, "", 'erase', startXR, startYR, endXR, endYR, this.context.strokeStyle, this.context.lineWidth);
        } else {
          this.draw(startXR, startYR, endXR, endYR, this.context.strokeStyle, this.context.lineWidth)
          this.sendDrawingBySignal(this.meetingSession.audioVideo, "", 'draw', startXR, startYR, endXR, endYR, this.context.strokeStyle, this.context.lineWidth);
        }
        // const x = e.layerX
        // const y = e.layerY

        // if (!this.inDrawing) {
        //   return;
        // }

        // this.context.lineTo(x, y);
        // this.context.stroke();
      }
    },
    // 描画開始（mousedown）
    drawingStart: function(e) {
      console.log(`drawingStart`)
      // const x = e.layerX
      // const y = e.layerY

      // this.context.beginPath();
      // this.context.lineTo(x, y);
      // this.context.stroke();

      this.inDrawing = true;
    },
    // 描画終了（mouseup, mouseout）
    drawingEnd: function() {
      // this.context.closePath();
      this.inDrawing = false;
    },
    mouseMove(e) {
      this.drawing(e.offsetX, e.offsetY, e.movementX, e.movementY);
    },
    touchStart(e) {
      this.drawingStart(e);
      this.previous.x = e.changedTouches[0].clientX - this.drawCanvas.getBoundingClientRect().left;
      this.previous.y = e.changedTouches[0].clientY - this.drawCanvas.getBoundingClientRect().top;
    },
    touchMove(e) {
      e.preventDefault();
      const prevX = this.previous.x;
      const prevY = this.previous.y;
      const thisTimeX = e.changedTouches[0].clientX - this.drawCanvas.getBoundingClientRect().left;
      const thisTimeY = e.changedTouches[0].clientY - this.drawCanvas.getBoundingClientRect().top;
      this.drawing(thisTimeX, thisTimeY, thisTimeX - prevX, thisTimeY - prevY)
      this.previous.x = thisTimeX;
      this.previous.y = thisTimeY;
    },
    /**
     * 描画
     */
    draw(startXR, startYR, endXR, endYR, stroke, lineWidth) {
      console.log(`startXR:${startXR} startYR:${startYR} endXR:${endXR} endYR:${endYR} stroke:${stroke} lineWidth:${lineWidth}`)
      const ctx = this.context;

      ctx.beginPath();
      ctx.moveTo(startXR * this.drawCanvas.width, startYR * this.drawCanvas.height);
      ctx.lineTo(endXR * this.drawCanvas.width, endYR * this.drawCanvas.height);
      ctx.strokeStyle = stroke;
      ctx.lineWidth = lineWidth;
      ctx.stroke();
      ctx.closePath();
    },
    /**
     * chimeから受け取ったデータを消去
     */
    eraseReceiveData(startXR, startYR, endXR, endYR) {
      if (!this.drawCanvas) {
        return;
      }
      const ctx = this.context;

      const width = 5
      ctx.clearRect(startXR * this.drawCanvas.width-width, startYR * this.drawCanvas.height-width, width*2, width*2)
      ctx.clearRect(endXR * this.drawCanvas.width-width, endYR * this.drawCanvas.height-width, width*2, width*2);
    },
    /**
     * キャンバスをクリア
     */
    clearDrawing() {
      if (!this.drawCanvas) {
        return;
      }
      const ctx = this.context;
      ctx.clearRect(0, 0, this.drawCanvas.width, this.drawCanvas.height)
    },
    sendDrawingBySignal(audioVideo, targetId, mode, startXR, startYR, endXR, endYR, stroke, lineWidth) {
      const topic = 'whiteboard';

      const message = {
        action: 'sendmessage',
        cmd: topic,
        targetId: targetId,
        // private: toPrivate,
        content: {
          targetId: targetId,
          // startTime   : Date.now(),
          mode: mode,
          startXR: startXR,
          startYR: startYR,
          endXR: endXR,
          endYR: endYR,
          stroke: stroke,
          lineWidth: lineWidth
        },
        done: false
      }

      console.log(message);
      audioVideo.realtimeSendDataMessage(topic, JSON.stringify(message))
    },
  },
};
</script>
