<template>
  <div>
    <base-button
      icon
      outline
      type="primary"
      size="sm"
      @click="onClickNewTodo"
    >
      <span class="btn-inner--icon"
        ><i class="fa fa-plus-square"></i
      ></span>
      <span class="btn-inner--text">ToDoを追加</span>
    </base-button>
    <ul class="list-group list-group-flush" data-toggle="checklist">
      <li class="list-group-item px-0" v-for="todo in todoList" :key="todo.title">
        <div
          class="checklist-item"
          :class="{
            'checklist-item-checked': todo.todo.status == 1,
          }"
        >
          <div class="checklist-info">
            <h5 class="checklist-title mb-0">{{ todo.todo.title }}</h5>
            <small>{{ todo.todo.scheduledDateTime }}</small><br />
            <small v-if="todo.todo.targetUser">対象:{{ users[todo.todo.targetUser] }}</small><br />
            <small>{{todo.todo.content}}</small>
          </div>
          <div>
            <!-- <base-checkbox v-model="item.done" :type="item.checkType" /> -->
            <el-dropdown class="dropdown" trigger="click">
              <span class="btn btn-sm btn-icon-only text-light">
                <i class="fas fa-ellipsis-v mt-2"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <a class="dropdown-item" @click.prevent="onClickTodoEdit(todo)">編集</a>
                  <a class="dropdown-item text-danger" @click.prevent="onClickTodoDelete(todo)" >削除</a>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import TodoModel from "@/appModel/ToDo/TodoModel"
import UserModel from "@/appModel/users/UserModel";

export default {
  data() {
    return {
      todoList: [],
      users: {},
      /**
       * id:String
  title:String
  createUser:String
  targetUser:String
  content:String
  scheduledDateTime:String
  status:String
  parameters:String
       */
      items: [
        // {
        //   title: "資材受入検査をする(南ゲート)",
        //   description: "2021/10/01 13:00 PM",
        //   author:"現場監督",
        //   done: false,
        //   checkType: "info",
        // },
        // {
        //   title: "地下躯体工事の立会検査",
        //   description: "2021/10/01 12:30 PM",
        //   author:"現場監督",
        //   done: false,
        //   checkType: "warning",
        // },
        // {
        //   title: "工事担当者間の打ち合わせ",
        //   description: "2021/10/01 09:30 AM",
        //   author:"検査　次郎",
        //   done: true,
        //   checkType: "info",
        // },
        // {
        //   title: "工事現場巡回",
        //   description: "2021/10/01 08:00 AM",
        //   author:null,
        //   done: true,
        //   checkType: "info",
        // },
      ],
    };
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  methods: {
    init() {
      this.getProjectUsers().then(function() {
        this.getTodoList()
      }.bind(this))
    },
    async getTodoList(){
      this.todoList = await TodoModel.getTodoList()
    }, 
    onClickNewTodo() {
      let todoStore = this.$store.state.todo
      todoStore.edit = true
      todoStore.data = null
      todoStore.drawer = false
      this.$store.commit('setTodo', todoStore)
      //this.$emit("todoEdit", null)
    },
    onClickTodoEdit(data){
      let todoStore = this.$store.state.todo
      todoStore.edit = true
      todoStore.data = data
      todoStore.drawer = false
      this.$store.commit('setTodo', todoStore)
      // this.$emit("todoEdit", data)
    },
    async onClickTodoDelete(data) {
      await TodoModel.deleteTodo(data)
      this.todoList = null
      this.$nextTick(function(){
        this.getTodoList()
      }.bind(this))
    },
    async getProjectUsers() {
      const result = await UserModel.getUserList();
      result.forEach(function(item){
        this.users[item.user.userId] = item.user.name
      }.bind(this))
    },
  }
};
</script>
