<template>
  <div>
    <!-- <base-header class="pb-1" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">プロジェクト</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header> -->
    <!-- <div class="container-fluid mt-1"> -->
      <div class="card">
        <div class="card-header border-0">
          <div class="row ml-0">

            <table-tool-menu
              class="col-12"
              :title="title"
              :addTitle="addTitle"
              :editTitle="editTitle"
              :isAdd="true"
              :isDelete="false"
              :isCopy="false"
              :isSingleSelect="selectRow"
              :isMultiSelect="false"
              :processing="false"
              :disableMoveDown="false"
              :disableMoveUp="false"
              @addNew="onClickNewProject"
              @edit="onEditProject(selectedData)"
            >
            </table-tool-menu>

            <!--
            <div class="col-12 text-right">
              <base-button
                icon
                outline
                type="primary"
                @click="onClickNewProject"
              >
                <span class="btn-inner--icon"
                  ><i class="fa fa-plus-square"></i
                ></span>
                <span class="btn-inner--text">新規プロジェクト</span>
              </base-button>
            </div>
             -->
          </div>
        </div>

        <el-table
          ref="projectTable"
          class="table-responsive align-items-center table-flush"
          header-row-class-name="thead-light"
          select-on-indeterminate="true"
          @select="handleSelectRow"
          @select-all="handleSelectAllRow"
          @sort-change="runSort"
          :data="projects"
        >
          <el-table-column type="selection" align="left" min-width="120px">
          </el-table-column>
          <el-table-column
            label="プロジェクト名"
            min-width="410px"
            prop="project.name"
            sortable="'custom'"
          >
            <template v-slot="{ row }">
              <!-- <img :src="row.project.image" class="avatar rounded-circle mr-3" /> -->
              <b>
                <a href="#!" @click.prevent="onClickProject(row)"
                  >{{ row.project.name }}
                </a>
              </b>
            </template>
          </el-table-column>
          <el-table-column label="作成日" min-width="140px" prop="createdAt" sortable="'custom'">
            <template v-slot="{ row }">
              <span>{{ dateStringFormat(row.createdAt) }}</span>
            </template>
          </el-table-column>
          <!--
          <el-table-column>
            <template v-slot="{ row }">
              <div class="table-actions">
                <el-tooltip content="Edit" placement="top">
                  <a
                    href="#!"
                    @click.prevent="onEditProject(row)"
                    class="table-action table-action-edit"
                    data-toggle="tooltip"
                    data-original-title="edit product"
                  >
                    <i
                      class="fas fa-edit text-red"
                      style="font-size: 1.5rem"
                    ></i>
                  </a>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          -->
          <!-- <el-table-column min-width="180px" align="center">
                    <template v-slot="{ row }">
                    <div class="table-actions">
                        <el-tooltip content="Delete" placement="top">
                        <a
                            href="#!"
                            @click.prevent="onDelete(row)"
                            class="table-action table-action-delete"
                            data-toggle="tooltip"
                            data-original-title="Delete product"
                        >
                            <i class="fas fa-trash text-red" style="font-size:1.5rem;"></i>
                        </a>
                        </el-tooltip>
                    </div>
                    </template>
                </el-table-column> -->
          <!-- <el-table-column min-width="180px">
                    <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-light">
                        <i class="fas fa-ellipsis-v mt-2"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show">
                        <a class="dropdown-item" href="#" @click.prevent="onClickProjectEdit(row)"><i class="fa fa-edit text-default"></i><span class="nav-link-text">編集</span></a>
                        <a class="dropdown-item" href="#" @click.prevent="onClickProjectDelete(row)"><i class="fa fa-trash-alt text-danger"></i><span class="nav-link-text"> 削除</span></a>

                        </el-dropdown-menu>
                    </template>
                    </el-dropdown>
                </el-table-column> -->
        </el-table>
      </div>
    <!-- </div> -->
  </div>
</template>
<script>
//import users from "./../users";
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElTooltip,
} from "element-plus";
import ProjectModel from "../../appModel/project/ProjectModel";
import UserInfo from "../../appUtils/UserInfo";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TableToolMenu from "../components/Menu/TableToolMenu"
import DateUtil from '../../appUtils/DateUtil';
import SortUtil from '../../appUtils/SortUtil';

export default {
  name: "inline-actions-table",
  components: {
    RouteBreadCrumb,
    [ElTooltip.name]: ElTooltip,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    TableToolMenu,
  },
  data() {
    return {
      //users,
      projects: [],
      currentPage: 1,
      selectedData: null,
      title: "プロジェクト一覧",
      addTitle: "新規プロジェクトの作成",
      editTitle: "プロジェクトの編集",
    };
  },
  /**
   * コンピュートプロパティ
   */
  computed: {
    selectRow() {
      if (this.selectedData) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {

  },
  async created() {
    let loader = this.showLoader();

    try {
      let navInfo = this.$store.state.navInfo
      navInfo.title = "プロジェクト一覧"
      navInfo.description = ""
      this.$store.commit("setNavInfo", navInfo)
      await this.getProjectList();
    } catch (e) {
      console.log(JSON.stringify(e))
    } finally {
      this.hideLoader(loader);
    }
  },
  methods: {
    async getProjectList() {
      const userInfo = await UserInfo.getUserInfo();
      this.projects = await ProjectModel.getProjectList();

      // console.log(`projects:${JSON.stringify(this.projects,null,"\t")}`)
    },
    onClickNewProject() {
      this.$router.push({
        name: "NewProject",
      });
    },
    onClickProject(row) {
      let id = row.sk.split("#")[1];
      //alert(id)
      let store = this.$store.state.project;
      // alert(JSON.stringify(row.meeting,null,"\t"))
      store = row;
      this.$store.commit("setProject", store);

      this.$router.push({
        path: `/projectMain`,
        query: { id: `${id}` },
      });
    },
    onEditProject(row) {
      console.log(JSON.stringify(row, null, "\t"));
      let store = this.$store.state.project;
      // alert(JSON.stringify(row.meeting,null,"\t"))
      store = row;
      this.$store.commit("setProject", store);
      this.$router.push({
        name: "EditProject",
      });
    },
    handleSelectRow(selection, row) {
      // console.log(row);

      // 今回選択した行以外は選択を外し、常に単一選択とする
      for (let i = 0; i < selection.length; i++) {
        if (selection[i] != row) {
          this.$refs.projectTable.toggleRowSelection(selection[i], false)
        }
      }
      if (this.selectedData == row) {
        // 同一業の場合はチェックOFF
        this.selectedData = null;
      } else {
        this.selectedData = row;
      }

    },
    handleSelectAllRow(selection) {
      // 全行選択時は選択を解除する
      this.$refs.projectTable.clearSelection();
      this.selectedData = null;
    },
    handleCurrentChange(currentRow, oldCurrentRow) {
      console.log(`handleCurrentChange`);

      // 同じ行を選択した場合は選択解除
      if (currentRow == oldCurrentRow) {
        this.$refs.projectTable.setCurrentRow();
        return;
      }
      this.selectedData = currentRow;
    },
    /**
     * 日付文字を別の形式に変更
     */
    dateStringFormat(dateString) {
      return DateUtil.getFormatString(dateString, "YYYY/MM/DD HH:mm")
    },
    /**
     * ソートを実行します。
     */
    async runSort(column) {
      await this.changeTableSort(column);
    },

    /**
     * テーブルのソート順を変更します。
     */
    async changeTableSort(column){
      console.log(column);

      if (column === false) {
        return;
      }

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;

      console.log(fieldName);

      if (!fieldName) {
        return;
      }

      // 復元用に保持
      // this.sortColumn = column;

      // 日本語ソート
      let isKana = true;
      if (fieldName == "createdAt") {
        isKana = false;
      }

      this.projects = await SortUtil.sort(this.projects, fieldName, sortingType, isKana);
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
  },
};
</script>
