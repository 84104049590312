<template>
    <ul class="list-group list-group-flush list my--3">
    <li class="list-group-item px-0" v-for="user in users" :key="user.id">
      <div class="row align-items-center">
        <div class="col-auto">
          <!-- Avatar -->
          <a href="#" class="avatar rounded-circle" v-if="user.img">
            <img alt="Image placeholder" :src="user.img" />
          </a>
          <span class="avatar rounded-circle far fa-user" v-else></span>
        </div>
        
        <div class="col ml--2">
          <h4 class="mb-0">
            <a href="#!">{{ user.name }}</a>
          </h4>
          <!-- <span :class="`text-${user.statusType}`">● </span>-->
          <small>{{ user.authority }}</small>
        </div>
        <div class="col-auto">
          <base-button type="primary" size="sm">Add</base-button>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  name: "user-list",
  data() {
    return {
      users: [
        {
          id: 1,
          img: "",
          name: "監督　太郎",
          status: "オンライン",
          authority:"管理者",
          statusType: "success",
        },
        {
          id: 3,
          img: "",
          name: "ユーザ１",
          status: "オフライン",
          authority:"一般ユーザ",
          statusType: "danger",
        },
        {
          id: 4,
          img: "",
          name: "ユーザ２",
          status: "オンライン",
          authority:"一般ユーザ",
          statusType: "success",
        },
        {
          id: 4,
          img: "",
          name: "ユーザ３",
          status: "オンライン",
          authority:"一般ユーザ",
          statusType: "success",
        },
        {
          id: 4,
          img: "",
          name: "ユーザ４",
          status: "オンライン",
          authority:"一般ユーザ",
          statusType: "success",
        },
      ],
    };
  },
};
</script>
<style></style>