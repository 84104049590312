<template>
  <div>
    <card v-if="noticeData">
    <!-- タイトル -->
    <!-- <base-input type="text" label="タイトル">
      <template></template>
      <el-input
        placeholder="ToDoのタイトルを入力"
        v-model="noticeData.todo.title"
      ></el-input>
    </base-input> -->

    <!-- 区分 -->
    <div class="d-flex flex-row align-items-center">
      <span
        v-if="selectedIcon"
        v-bind:class="[selectedIcon]"
        class="bg-white mr-3"  style="font-size: 2rem; margin-right: 1rem; display:inline-block;"></span>
      <base-input name="通知の区分を選択" label="区分" v-model="selectedType" alternative style="width: 100%">
        <el-select placeholder="区分を選択してください"
          v-model="selectedType"
          v-on:change="handerleInputType"
          >
          <el-option v-for="type in types"
            class="select-danger"
            :key="type.value"
            :value="type.value"
            :label="type.label"
            :icon="type.icon">
          </el-option>
        </el-select>
      </base-input>
    </div>

    <!-- 送信先のユーザー -->
    <base-input name="selectmultiple" label="送信先のユーザー">
      <el-select
        v-model="selectedUsers"
        multiple
        filterable
        placeholder="ユーザーを選択して下さい（複数選択可）"
        >
        <el-option v-for="user in users"
          :key="user.user.userId"
          :label="user.user.name"
          :value="user.user.userId">
        </el-option>
      </el-select>
    </base-input>
    <!-- 内容 -->
    <base-input type="text" label="内容">
      <template></template>
      <!-- <el-input
        placeholder="Please input"
        v-model="noticeData.todo.content"
      ></el-input> -->
      <el-input type="textarea" v-model="noticeData.notice.content"></el-input>
    </base-input>
    <!-- 予定日時 -->
    <!-- <base-input
      type="text"
      label="予定日"
      addon-left-icon="ni ni-calendar-grid-58"
    >
      <template v-slot="{ focus, blur }">
        <flat-picker
          @on-open="focus"
          @on-close="blur"
          :config="{ allowInput: true }"
          class="form-control datepicker"
          v-model="noticeData.todo.scheduledDateTime"
        >
        </flat-picker>
      </template>
    </base-input> -->

    <!-- 状態 -->

    <!-- パラメータ(任意のJSONリテラル)   -->
    <div class="row">
      <div class="col-6">
        <base-button
          block
          type="secondary"
          @click="onClickCancel"
          >キャンセル</base-button
        >
      </div>
      <div class="col-6">
        <base-button
          block
          type="primary"
          @click="onClickSave"
          >保存</base-button
        >
      </div>
    </div>
    </card>
  </div>
</template>

<style>

</style>

<script>
import UserModel from "@/appModel/users/UserModel";
import UserInfo from "../../appUtils/UserInfo";
import NoticeModel from "@/appModel/Notice/NoticeModel"
import Card from "@/components/Cards/Card.vue";
import { useToast } from "vue-toastification";

export default {
  components: {
    Card
  },
  props: {
    editNoticeData: Object,
    isEdit: Boolean,
    id: String
  },
  computed: {

  },
  data() {
    return {
      users: [],
      selectedUsers: [],
      types: [],
      selectedType: null,
      selectedIcon: "",
      selectedIconType: "",
      noticeData: null,
      loader: null,
    }
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後

  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    //this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async initNotice() {
      console.log(`NoticeEdit initNotice`)

      // this.loader = this.$loading.show();

      // ユーザー取得
      await this.getProjectUsers();
      this.selectedUsers = [];

      // 新規登録データ
      this.noticeData = null;

      if (this.editNoticeData) {
        console.log(`edit`);
        console.log(this.editNoticeData);
        this.noticeData = Object.assign({}, this.editNoticeData);
      } else {
        console.log(`new`);
        await this.getNewNoticeData();
      }

      // 自身のユーザを除外
      await this.removeSelfUser();

      // 通知タイプ
      await this.initNoticeType();
      this.selectedType = null;
      // this.selectedType = this.types[0];
      // console.log(this.selectedType);

      // アイコン
      this.selectedIcon = null;
      this.selectedIconType = null;

      console.log(this.types);
      console.log(this.users);

      // this.loader.hide();
      // this.loader = null;
    },
    async initNoticeType() {
      this.types = [
        {value: 0, label: 'ミーティング', icon: 'fas fa-video', iconType: 'primary'},
        {value: 1, label: 'ToDo通知', icon: 'ni ni-check-bold', iconType: 'warning'},
        {value: 2, label: '検査箇所', icon: 'ni ni-pin-3', iconType: 'success'},
        {value: 3, label: 'ユーザ間の任意内容', icon: 'ni ni-circle-08', iconType: 'default'}
      ]
    },
    async getProjectUsers() {
      this.users = await UserModel.getUserList();
    },
    async getNewNoticeData() {
      this.noticeData = await NoticeModel.getNewData();
      console.log(this.noticeData);
    },
    async removeSelfUser() {

      console.log(this.noticeData);

      const userInfo = await UserInfo.getUserInfo();
      const rems = this.users.filter(item => item.user.userId !== userInfo.sub);
      console.log(this.noticeData.notice.createUser);
      console.log(rems);
      this.users = null;
      this.users = rems;

    },
    async onClickSave() {
      try {
        if (this.editNoticeData) {
          // 更新
          console.log(`更新`)
          // 区分
          this.noticeData.rawData.notice.type = this.selectedType.toString();
          // 送信先のユーザー
          this.noticeData.rawData.notice.targetUsers = this.selectedUsers;
          // 内容
          this.noticeData.rawData.notice.content = this.noticeData.notice.content;

          await NoticeModel.updateNotice(this.noticeData.rawData);
        } else {
          // 新規
          console.log(`新規`)
          console.log(this.users);
          console.log(this.selectedUsers);

          this.showLoader(true);

          try {

            // 入力チェック
            if (!this.validateInputs()) {
              return;
            }

            // 区分
            this.noticeData.notice.type = this.selectedType.toString();
            // 送信先のユーザー
            this.noticeData.notice.targetUsers = this.selectedUsers;
            // 内容
            // this.noticeData.notice.content = this.noticeData.content;
            console.log(this.noticeData);

            // 自身の登録(既読フラグはtrue)
            this.noticeData.notice.Readed = true;
            await NoticeModel.addNotice(this.noticeData);

            // 送信先ユーザーの登録
            await this.addNoticeTargetUsers(this.noticeData);

          } catch (e) {
            console.log(JSON.stringify(e))
            this.showBottomToast(`登録時にエラーが発生しました`, `error`)
            throw e
          } finally {
            this.showLoader(false);
          }
        }
        this.onEndNotice(false)
      } catch (e) {
        alert(`Notice更新エラー ${JSON.stringify(e)}`)
        throw e
      }
    },
    async addNoticeTargetUsers(noticeData) {

      for (let i = 0; i < this.selectedUsers.length; i++) {
        let addData = Object.assign({}, noticeData);

        // 既読フラグはfalse
        addData.notice.Readed = false;

        // skからcognitIdを取得
        console.log(this.selectedUsers[i]);
        const targetUserId = this.selectedUsers[i];

        // 登録者のIDを送信先ユーザのIDで置換
        const createUserId = addData.notice.createUser;
        addData.sk = addData.sk.replace(createUserId, targetUserId);

        // DBに登録
        await NoticeModel.addNotice(addData);
      }
    },
    async getUserIdFromSk(sk) {
      console.log(sk);
      return sk.replace('user#', '');
    },
    /**
     * 入力項目を検証します。
     *
     * @returns 入力項目にエラーがあった場合はfalse、さもなくばture
     */
    validateInputs() {

      console.log(this.selectedType);
      console.log(this.selectedUsers);
      console.log(this.noticeData.notice.content);

      let checkType = -1;
      if (this.selectedType !== null) {
        checkType = Number(this.selectedType);
      }
      if (checkType == 0) {
        // 0がfalse判定となるので回避
        checkType++;
      }
      console.log(checkType);

      if (
        checkType < 0 ||
        !this.selectedUsers ||
        !this.noticeData.notice.content
      ) {
        // 未入力はエラー
        this.showBottomToast(`未入力の項目があります`, `error`)
        return false;
      }

      return true;
    },

    onEndNotice(isCanceled){
      let noticeStore = this.$store.state.notice;
      noticeStore.edit = false;
      noticeStore.data = null;
      // noticeStore.drawer = true

      // Todo
      this.$store.commit('setNotice', noticeStore)
      // this.$store.commit('setTodo', noticeStore)

      this.$emit("closeNotice", isCanceled);
    },
    onClickCancel(){
      this.onEndNotice(true)
    },
    /**
     * 区分変更
     */
    handerleInputType() {

      console.log('選択区分：' + this.selectedType);
      console.log('区分：' + this.types);

      this.selectedIcon = Object.assign({}, this.types[Number(this.selectedType)]).icon;
      this.selectedIconType = Object.assign({}, this.types[Number(this.selectedType)]).iconType;

      console.log('選択アイコン：' + this.selectedIcon);
      console.log(this.selectedIconType);

    },

    showLoader(isShow) {
      if (isShow) {
        this.loader = this.$loading.show();
      } else {
        this.loader.hide();
        this.loader = null;
      }
    },

    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },

    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
  }
}
</script>
