<template>
  <footer class="footer px-4 mx-2">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
          <a
            class="ml-1 text-rebarprimary"
            target="_blank"
            > Nippon Computer Systems Corp.</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>
<style scoped>
.footer {
  background-color: transparent;
}
.footer .copyright {
  font-size: 0.675rem
}
</style>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
