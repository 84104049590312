<template>
  <div class="drawer-container" v-if="inspectPlaceDbData != null">
    <!-- <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop> -->
    <div class="row py-2 header">
      <div class="col-6">
        <base-button
          block
          type="secondary"
          @click="onClickCancelPlace"
          v-if="!isNewProject"
          >キャンセル</base-button
        >
      </div>
      <div class="col-6">
        <base-button
          block
          type="primary"
          @click="onClickSavePlace"
          v-if="!isNewProject"
          >保存</base-button
        >
      </div>
    </div>
    <el-collapse v-model="activeNames" @change="handleChange" class="">
      <el-collapse-item title="検査箇所" name="inspectPlace">
        <div class="px-2 mt-2">
          <!-- 検査マスタ -->
          <base-input name="select" label="検査マスタ" v-model="inspectPlaceDbData.inspectPlace.inspectMasterId">
            <el-select placeholder=""
              v-bind:disabled="!showInspectMasterList || isChangeInspectResult"
              v-model="inspectPlaceDbData.inspectPlace.inspectMasterId"
              @change="chengeInspectMaster"
              filterable>
              <el-option v-for="item in inspectMasterList"
                        class="select-primary"
                        :value="item.inspectMaster.id"
                        :label="item.inspectMaster.name"
                        :key="item.inspectMaster.name">
              </el-option>
            </el-select>
          </base-input>

          <!-- アイコン -->
          <div class="d-flex flex-row align-items-center">
          <img :src="iconPath" class="bg-white avatar mr-3" v-if="iconPath" style="display:inline-block;" />
          <base-input name="select" label="アイコンを選択" v-model="inspectPlaceDbData.inspectPlace.iconClass" v-if="inspectMasterList!=null">
            <el-select placeholder=""
              v-model="inspectPlaceDbData.inspectPlace.iconClass"
              @change="chengeIcon"
              filterable>
              <el-option v-for="item in iconSettingList"
                        class="select-primary"
                        :value="item.iconClass"
                        :label="item.name"
                        :key="item.name">
              </el-option>
            </el-select>
          </base-input>
          </div>
          <!-- 箇所名	name -->
          <div class="row">
            <div class="col-12">
              <div class="">
                <base-input type="text" label="箇所名">
                  <template></template>
                  <el-input
                    placeholder=""
                    v-model="inspectPlaceDbData.inspectPlace.name"
                  ></el-input>
                </base-input>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="">
                <base-input type="text" label="通り芯名">
                  <template></template>
                  <el-input
                    placeholder=""
                    v-model="inspectPlaceDbData.inspectPlace.lineName"
                  ></el-input>
                </base-input>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="検査結果" name="inspectResult" v-if="inspectResult">
        <div class="px-2 mt-2" >
          <div class="row">
            <div
              class="col-12"
              v-for="resultItem in inspectResult.inspectResultSet.inspectList"
              :key="resultItem.itemId"
            >
              <!-- ラベル -->
              <span v-if="resultItem.itemType == `0`">{{resultItem.value}}</span>
              <!-- テキスト -->
              <base-input type="text" :label="resultItem.name" v-if="resultItem.itemType == `1`">
                <template></template>
                <el-input
                  placeholder="テキストで入力"
                  v-model="resultItem.value"
                ></el-input>
              </base-input>
              <!-- 数値 -->
              <base-input type="text" :label="resultItem.name" v-if="resultItem.itemType == `2`">
                <template></template>
                <el-input
                  placeholder="数値で入力"
                  v-model="resultItem.value"
                ></el-input>
              </base-input>
              <!-- 日付 -->
              <base-input
                type="text"
                :label="resultItem.name"
                addon-left-icon="ni ni-calendar-grid-58"
                v-if="resultItem.itemType == `3`"
              >
                <template v-slot="{ focus, blur }">
                  <flat-picker
                    @on-open="focus"
                    @on-close="blur"
                    :config="{ allowInput: true }"
                    class="form-control datepicker"
                    v-model="resultItem.value"
                  >
                  </flat-picker>
                </template>
                <!-- <el-input placeholder="Please input" v-model   ="tenantData.tenant.contractStart"></el-input> -->
              </base-input>
              <!-- 選択 (リスト) -->
              <base-input name="select" :label="resultItem.name"  v-if="resultItem.itemType == `4`">
                <el-select placeholder=""
                  v-model="resultItem.value"
                  filterable>
                  <el-option v-for="item in getInputMaster(resultItem.masterType)"
                            class="select-primary"
                            :value="item.value"
                            :label="item.name"
                            :key="item.name">
                  </el-option>
                </el-select>
              </base-input>
              <!-- 選択 (ボタン) -->
              <base-input name="selectButton" :label="resultItem.name" v-if="resultItem.itemType == `5`">
                <div class="btn-group btn-group-toggle d-flex flex-wrap" >
                  <label
                    v-for="(item, index) in getInputMaster(resultItem.masterType)"
                    :key="index"
                    class="btn border mr-2 mb-2 col-auto"
                    style="border-radius: 2px;white-space: nowrap;"
                    v-bind:class="{active: resultItem.value === item.value}"

                  >
                    <input
                      :value="item.value"
                      v-model="resultItem.value"
                      type="radio"
                      id="'option' + index"
                      autocomplete="off"
                    />
                    {{ item.name }}
                  </label>
                </div>
              </base-input>
              <!-- <p>selected value: {{ resultItem.value }}</p> -->
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="検査写真" name="inspectPicture" v-if="inspectResult">
        <div class="px-2">
          <!-- 箇所名	name -->
          <div class="row mt-1">
            <div class="col-12">
              <div v-if="uploadFileList.length > 0">
                <small>未アップロード写真 {{uploadFileList.length}}枚</small>
              </div>
              <el-upload
                class="upload-demo m-1"
                action=""
                :on-change="handleOnChange"
                :on-remove="handleRemove"
                :file-list="uploadFileList"
                :multiple="true"
                :auto-upload="false"
                list-type="picture-card"
              >
                <el-button size="small" type="primary" class="mb-0">写真を選択</el-button>
              </el-upload>
            </div>
          </div>
          <div class="row mt-1 d-flex my-box-light flex-wrap">
            <div class="col-12">
              <div class="mb-2 mt-2">
                <el-image
                  v-for="thumb in thumbList"
                  :key="thumb"
                  style="width:130px;height:130px;"
                  class="m-1 "
                  :src="thumb"
                  :preview-src-list="photoList"
                  :fit="`contain`"></el-image>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
    <el-collapse-item title="通知する" name="notice">
      <base-input name="ToDoの対象者を選択" label="対象者">
        <el-select
        v-model="selectedUsers"
        multiple
        filterable
        placeholder="ユーザーを選択して下さい（複数選択可）"
        >
        <el-option v-for="user in users"
          :key="user.user.userId"
          :label="user.user.name"
          :value="user.user.userId">
        </el-option>
      </el-select>
      </base-input>
      <base-input type="text" label="内容">
      <template></template>
      <el-input type="textarea" v-model="noticeContent"></el-input>
    </base-input>
    </el-collapse-item>
    </el-collapse>

    <!--
    <h4 class="pt-3 pb-3 pl-1">検査箇所</h4>

    <el-tabs type="card" v-model="activeTab" @tab-click="tabSelect" >
      <el-tab-pane label="箇所情報" name="tab1">
         -->
        <!-- 検査箇所 -->
        <!-- <card v-if="tab1Show == true" class="mt--2"> -->
          <!--
          <div class="pl-2">
             -->
            <!-- 検査マスタ -->
            <!--
            <base-input name="select" label="検査マスタ" v-if="showInspectMasterList" v-model="inspectPlaceDbData.inspectPlace.inspectMasterId">
              <el-select placeholder=""
                v-model="inspectPlaceDbData.inspectPlace.inspectMasterId"
                @change="chengeInspectMaster"
                filterable>
                <el-option v-for="item in inspectMasterList"
                          class="select-primary"
                          :value="item.inspectMaster.id"
                          :label="item.inspectMaster.name"
                          :key="item.inspectMaster.name">
                </el-option>
              </el-select>
            </base-input>
            -->

            <!-- アイコン -->
          <!--
            <div class="d-flex flex-row align-items-center">
            <img :src="iconPath" class="bg-white avatar mr-3" v-if="iconPath" style="display:inline-block;" />
            <base-input name="select" label="アイコンを選択" v-model="inspectPlaceDbData.inspectPlace.iconClass" v-if="inspectMasterList!=null">
              <el-select placeholder=""
                v-model="inspectPlaceDbData.inspectPlace.iconClass"
                @change="chengeIcon"
                filterable>
                <el-option v-for="item in iconSettingList"
                          class="select-primary"
                          :value="item.iconClass"
                          :label="item.name"
                          :key="item.name">
                </el-option>
              </el-select>
            </base-input>
            </div>
            -->

            <!-- 箇所名	name -->
            <!--
            <div class="row">
              <div class="col-12">
                <div class="">
                  <base-input type="text" label="箇所名">
                    <template></template>
                    <el-input
                      placeholder="Please input"
                      v-model="inspectPlaceDbData.inspectPlace.name"
                    ></el-input>
                  </base-input>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="">
                  <base-input type="text" label="通り芯名">
                    <template></template>
                    <el-input
                      placeholder="Please input"
                      v-model="inspectPlaceDbData.inspectPlace.lineName"
                    ></el-input>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
           -->
        <!-- </card> -->
      <!--
      </el-tab-pane>
      <el-tab-pane v-if="inspectResult && editPlaceId" label="検査結果"  name="tab2">
         -->
        <!-- 検査結果 -->
        <!-- <card v-if="tab2Show == true && inspectResult != null" class="mt--2"> -->
        <!--
          <div class="pl-2" >

            <div class="row">
              <div
                class="col-12"
                v-for="resultItem in inspectResult.inspectResultSet.inspectList"
                :key="resultItem.itemId"
              >
              -->

              <!-- {value: "0", label: "ラベル(編集不可)"},
              {value: "1", label: "テキスト"},
              {value: "2", label: "数値"},
              {value: "3", label: "日付"},
              {value: "4", label: "マスタデータ"} -->

              <!-- itemId:String
              value:String
              name:String
              itemType:String
              masterType:String
              required:Boolean
              resultFlg:String
              order:Int -->

                <!-- ラベル -->
                <!-- <span v-if="resultItem.itemType == `0`">{{resultItem.value}}</span> -->
                <!-- テキスト -->
                <!-- <base-input type="text" :label="resultItem.name" v-if="resultItem.itemType == `1`">
                  <template></template>
                  <el-input
                    placeholder="テキストで入力"
                    v-model="resultItem.value"
                  ></el-input>
                </base-input> -->
                <!-- 数値 -->
                <!-- <base-input type="text" :label="resultItem.name" v-if="resultItem.itemType == `2`">
                  <template></template>
                  <el-input
                    placeholder="数値で入力"
                    v-model="resultItem.value"
                  ></el-input>
                </base-input> -->
                <!-- 日付 -->
                <!-- <base-input
                  type="text"
                  :label="resultItem.name"
                  addon-left-icon="ni ni-calendar-grid-58"
                  v-if="resultItem.itemType == `3`"
                >
                  <template v-slot="{ focus, blur }">
                    <flat-picker
                      @on-open="focus"
                      @on-close="blur"
                      :config="{ allowInput: true }"
                      class="form-control datepicker"
                      v-model="resultItem.value"
                    >
                    </flat-picker>
                  </template> -->
                  <!-- <el-input placeholder="Please input" v-model   ="tenantData.tenant.contractStart"></el-input> -->
                <!-- </base-input> -->
                <!-- マスタデータ -->

                <!-- <base-input name="select" :label="resultItem.name"  v-if="resultItem.itemType == `4`">
                  <el-select placeholder=""
                    v-model="resultItem.value"
                    filterable>
                    <el-option v-for="item in getInputMaster(resultItem.masterType)"
                              class="select-primary"
                              :value="item.value"
                              :label="item.name"
                              :key="item.name">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div> -->
        <!-- </card> -->
      <!--
      </el-tab-pane>
      <el-tab-pane v-if="inspectResult && editPlaceId" label="写真" name="tab3" >
      -->

        <!-- 写真 -->
        <!-- <card v-if="tab3Show == true && inspectResult != null" class="mt--2"> -->
          <!-- <div class="pl-2 "> -->
            <!-- 箇所名	name -->
            <!--
            <div class="row mt-1">
              <div class="col-12">
                <div class="d-flex my-box-light flex-wrap mb-2 mt-2">
                  <el-image
                    v-for="thumb in thumbList"
                    :key="thumb"
                    style="width:130px;height:130px;"
                    class="m-1 "
                    :src="thumb"
                    :preview-src-list="photoList"
                    :fit="`contain`"></el-image>
                </div>
                <div v-if="uploadFileList.length > 0">
                  <small>未アップロード写真 {{uploadFileList.length}}枚</small>
                </div>
                -->

                <!-- <file-input initialLabel="アップロードする写真を選択" @change="uploadPhoto" ref="fileInput"></file-input> -->
                <!--
                <el-upload
                  class="upload-demo m-1"
                  action=""
                  :on-change="handleOnChange"
                  :on-remove="handleRemove"
                  :file-list="uploadFileList"
                  :multiple="true"
                  :auto-upload="false"
                  list-type="picture-card"
                >
                  <el-button size="small" type="primary">写真を選択</el-button>

                </el-upload>
              </div>
            </div>
            -->
            <!-- <div class="row">
              <div
                class="col-6"
                v-for="photo in photoList"
                :key="photo.url"
              > -->
              <!-- <div class="d-flex my-box-light flex-wrap mb-2"> -->
              <!-- <div
                style="display:inline-block;"
                class="m-1"
                v-for="photo in photoList"
                :key="photo.url"
              > -->
              <!-- <div class="d-flex my-box-light flex-wrap mb-2 mt-2"> -->
                <!-- <img v-for="photo in photoList"
                style="width:100px;height:100px;object-fit: contain;"
                class="m-1 my-box w-25"
                :key="photo.url"
                :src="photo.url" /> -->
              <!-- <el-image
                v-for="photo in photoList"
                :key="photo.url"
                style="width:130px;height:130px;"
                class="m-1 "
                :src="photo.url"
                :fit="`contain`"></el-image>
              </div> -->
                <!-- <img :src="photo.url" /> -->
              <!-- </div>
            </div> -->
          <!-- </div> -->
        <!-- </card> -->

      <!-- </el-tab-pane>
    </el-tabs> -->

    <!-- <base-button :type="tab1Show ? `white` : `light`" @click.prevent="changeTab(1)">箇所情報</base-button>
    <base-button :type="tab2Show ? `white` : `light`" @click.prevent="changeTab(2)" v-if="inspectResult && editPlaceId">検査結果</base-button>
    <base-button :type="tab3Show ? `white` : `light`" @click.prevent="changeTab(3)" v-if="inspectResult && editPlaceId">写真</base-button> -->
    <!--
    <div class="row">

      <div class="col-6">
        <base-button
          block
          type="secondary"
          @click="onClickCancelPlace"
          v-if="!isNewProject"
          >キャンセル</base-button
        >
      </div>
      <div class="col-6">
        <base-button
          block
          type="primary"
          @click="onClickSavePlace"
          v-if="!isNewProject"
          >保存</base-button
        >
      </div>
    </div>
    -->
  </div>
</template>
<style>
.el-upload--picture-card{
  width: 100% !important;
  height: 100px !important;
  line-height: 100px !important;
}

.el-upload-list__item{
  width: 120px !important;
  height: 120px !important;
}
.el-upload-list__item-thumbnail{
  object-fit: contain;
}
.el-collapse-item__header {
  background-color: #705470 !important;
  color: white !important;
  padding-left: 8px;
  height: 48vh;
  margin-top: 2px;
  margin-left: -8px;
  margin-right: -8px;
  border-radius: 1px;
  font-weight: bold;
}

</style>
<style scoped>
.active {
  color: white;
  background-color: #e4007f;
}
.active:hover {
  color: white;
}
.drawer-container {
  align-items: flex-start;
  background-color:white;
  opacity: 1.0;
}
.header {
  position: sticky;
  top: 0;
  background-color:white;
  opacity: 1.0;
  z-index: 1010;
}

</style>

<script>
import InspectPlaceModel from "@/appModel/InspectPlace/InspectPlaceModel"
import InspectResultModel from "@/appModel/InspectResult/InspectResultModel"
import InspectMasterModel from "@/appModel/InspectMaster/InspectMasterModel"
import FileUtil from "../../appUtils/FileUtil"
import FileInput from "@/components/Inputs/FileInput";
import UserInfo from '../../appUtils/UserInfo';
import API from '@aws-amplify/api';
import { Storage } from "aws-amplify";
import path from 'path'
import mime from 'mime-types';
import constants from "@/Config/constants"
import TenantStorage from "@/appUtils/TenantStorage"
import UserModel from "@/appModel/users/UserModel";
import NoticeModel from "@/appModel/Notice/NoticeModel"
// import "../../assets/sass/custom/_variables.scss";
// import "bootstrap/scss/variables";

export default {
  components: {
    FileInput
  },
  props: {

    /**
     * プロジェクトID
     */
    projectId: {
      type: String,
      default: "2F47bBSU4tyrFDnqhmuMGA",
    },
    /**
     * ノードID
     */
    nodeId: {
      type: String,
      default: "mSRe8zwA4r8N9NkMpH21Zw",
    },
    /**
     * 検査マスタID
     *
     */
    inspectMasterId: {
      type: String
    },
    /**
     * 図面で検査箇所選択時にSplitFileViewから渡される検査箇所情報
     */
    editPlaceId: {
      type: Object
    },
    /**
     * 図面で新規箇所作成した情報
     */
    addPlaceData: {
      type: Object
    }
  },
  computed: {
    /**
     * 検査箇所に設定するアイコンのリスト。検査マスタのアイコンリストを表示する
     *
     */
    iconSettingList() {
      if (this.currentInspectMaster) {
        //console.log(`iconSettingList exist this.currentInspectMaster.`)
        if (this.currentInspectMaster.inspectMaster) {
          //console.log(`currentInspectMaster: ${JSON.stringify(this.currentInspectMaster, null, "\t")}`)
          return this.currentInspectMaster.inspectMaster.iconSetting
        }
      }
      //console.log(`iconSettingList nothing`)
      return []
    },
    placeId() {
      if (this.addPlaceData) {
        return this.addPlaceData.inspectPlace.placeId
      } else {
        return this.editPlaceId
      }
    },
    isChangeInspectResult() {
      if (!this.defaultInspectResult) {
        return false;
      }
      if (JSON.stringify(this.defaultInspectResult) == JSON.stringify(this.inspectResult.inspectResultSet.inspectList)) {
        return false;
      }
      console.log(this.defaultInspectResult)
      console.log(this.inspectResult)
      console.log(`isChangeInspectResult:true`)
      return true;
    }
  },
  methods: {
    /**
     * 初期化処理
     */
    async init() {
      await this.getProjectUsers()
      console.log(`inspectPlace init`)
      const user = await UserInfo.getUserInfo()
      this.tenantStorage = new TenantStorage(user.group)

      if (this.editPlaceId) {
        // 検査箇所読み込み
        console.log(`placeId:${this.placeId}`)
        const sk = InspectPlaceModel.createSk(this.projectId, this.nodeId, this.placeId)
        InspectPlaceModel.getInspectPlace({sk: sk}).then(function(result) {
          this.inspectPlaceDbData = result
          this.getIconPath(this.inspectPlaceDbData.inspectPlace.iconClass).then(function(result){
            this.iconPath = result

          }.bind(this))
        }.bind(this))

        // 検査結果読み込み
        console.log(`検査結果sk ${this.projectId} ${this.nodeId} ${this.placeId}`)
        InspectResultModel.getInspectResultList(this.projectId, this.nodeId, this.placeId).then(function(result) {
          console.log(`検査結果件数 ${result.length}`)
          if (result.length > 0) {
            // TODO: 1件目だけ取得しているのは暫定処理
            this.inspectResult = result[0]
            this.showInspectMasterList = false
            console.log(this.inspectResult)

            // 写真リスト作成
            this.makePhotoList()

          }
        }.bind(this))
        // 検査マスタIDが指定されている場合はマスタリスト選択は表示しない
        this.showInspectMasterList = !this.inspectMasterId

      } else {
        console.log(`add :${JSON.stringify(this.addPlaceData)}`)
        // 新規検査箇所追加
        this.inspectPlaceDbData = Object.assign({}, this.addPlaceData)
        this.$nextTick(function() {
          this.changeTab(1)
        }.bind(this))
      }
      // 検査マスタ取得
      InspectMasterModel.getInspectMasterList(this.projectId).then(function(result) {
        this.inspectMasterList = result
        if (result.length > 0) {
          this.currentInspectMaster = result[0]
        }

      }.bind(this))

    },
    async getProjectUsers() {
      this.users = await UserModel.getUserList();
    },
    tabSelect(tab, event) {
      switch (tab.props.name) {
      case "tab1":
        this.changeTab(1)
        break
      case "tab2":
        this.changeTab(2)
        break
      case "tab3":
        this.changeTab(3)
        break
      }
    },
    /**
     * タブ切り替え時のハンドラ
     */
    changeTab(tabNum) {
      console.log(`changeTab ${tabNum}`)
      // 一旦全て非表示にする
      this.tab1Show = false
      this.tab2Show = false
      this.tab3Show = false

      //UI更新後の処理でタブ表示切り替えを行う。
      this.$nextTick(function() {
        switch (tabNum) {
        case 1:
          this.tab1Show = true
          break
        case 2:
          this.tab2Show = true
          break
        case 3:
          this.tab3Show = true
          break
        }
      }.bind(this))
    },
    /**
     * 検査マスタリスト選択変更時
     * ※箇所作成時点で使用するマスタは決定されているのでこの処理は開発用
     */
    chengeInspectMaster() {
      // 一旦非表示
      this.iconPath = null
      this.currentInspectMaster = null
      this.inspectPlaceDbData.inspectPlace.iconClass = null
      this.inspectResult = null

      // UI更新後に処理
      this.$nextTick(function() {
        // 検査箇所情報にセットされているマスタIDを取得
        const masterId = this.inspectPlaceDbData.inspectPlace.inspectMasterId
        console.log(`masterId: ${masterId}`)
        // 検査マスタリストから該当するものを取得
        const selectedMaster = this.inspectMasterList.filter(function(item){
          console.log(`item.id ${item.inspectMaster.id}`)
          return item.inspectMaster.id == masterId
        }.bind(this))
        // マスタ件数が1件以上ある場合
        if (selectedMaster.length > 0) {
          // 基本1件
          this.currentInspectMaster = selectedMaster[0]
        }

        if (this.inspectResult == null) {
          console.log(`this.inspectResult is null`)
          // 検査結果がまだない場合は検査結果用の雛形を作成する
          InspectResultModel.getNewData(this.projectId, this.nodeId, this.placeId).then(function(result){
            this.inspectResult = result
            this.inspectResult.inspectResultSet.inspectList = []
            console.log(`this.inspectResult get`)
            const inspectList = []

            if (this.currentInspectMaster) {
              this.currentInspectMaster.inspectMaster.inspectItemList.forEach(function(item) {
                inspectList.push({
                  itemId: item.id,
                  value: item.defaultValue,
                  name: item.name,
                  itemType: item.itemType,
                  masterType: item.masterType,
                  required: item.required,
                  resultFlg: "",
                  order: item.order
                })

                // this.inspectResult.inspectResultSet.inspectList.push({
                //   itemId: item.id,
                //   value: item.defaultValue,
                //   name: item.name,
                //   itemType: item.itemType,
                //   masterType: item.masterType,
                //   required: item.required,
                //   resultFlg: "",
                //   order: item.order
                // })

                // // 編集確認用に保持
                // this.defaultInspectResult = JSON.parse(JSON.stringify(this.inspectResult.inspectResultSet.inspectList));

              }.bind(this));

              // 表示順でソート
              this.inspectResult.inspectResultSet.inspectList = inspectList.sort((a, b) => {
                console.log(`order: ${a.order} ${b.order}`)
                if (a.order < b.order) return -1;
                if (a.order > b.order) return 1;
                return 0;
              });

              // 編集確認用に保持
              this.defaultInspectResult = JSON.parse(JSON.stringify(this.inspectResult.inspectResultSet.inspectList));
            }
          }.bind(this))
        }

        // console.log(`this.currentInspectMaster: ${JSON.stringify(this.currentInspectMaster,null,"\t")}`)
      }.bind(this))

    },
    /**
     * アイコン変更時のハンドラ
     */
    chengeIcon() {
      // 検査箇所情報のチェック
      if (this.inspectPlaceDbData) {
        // 選択したアイコン区分がセットされているかチェック
        if (this.inspectPlaceDbData.inspectPlace.iconClass) {
          this.getIconPath(this.inspectPlaceDbData.inspectPlace.iconClass).then(function(result) {
            // 取得結果をセット。
            this.iconPath = result
          }.bind(this))
        }
      }
    },
    /**
     * アイコンクラスから検査アイコンのパスを取得
     */
    async getIconPath(iconClass) {

      const iconList = this.iconSettingList;
      // アイコンリストから該当するアイコン情報を取得
      const iconDatas = iconList.filter(function(icon) {
        return icon.iconClass == iconClass
      }.bind(this))

      // アイコン情報取得チェック
      if (iconDatas.length > 0) {
        // S3上のリソースURLを取得
        console.log(`アイコンパス ${iconDatas[0].iconFilePath}`)
        return await FileUtil.getTenantFileUrl(iconDatas[0].iconFilePath)
      }
    },

    /**
     * 検査マスタIDから検査マスタを取得する
     */
    getInputMaster(id) {
      console.log(`getInputMaster:${id}`)
      if (this.currentInspectMaster) {
        const inputMasters = this.currentInspectMaster.inspectMaster.inputMaster.filter(function(InputMaster) {
          console.log(`${InputMaster.id} ${id}`)
          return InputMaster.id == id
        }.bind(this))
        console.log(`getInputMaster: inputMasters.lengh ${inputMasters.length}`)
        if (inputMasters.length > 0) {
          console.log(inputMasters[0].list)
          return inputMasters[0].list
        }
      }
      return []
    },
    /**
     * キャンセルボタン押下時
     */
    onClickCancelPlace() {
      this.$emit('transfer', {key: "inspectPlaceCancel", values: {}});
    },

    /**
     * 保存ボタン押下時
     */
    async onClickSavePlace() {
      console.log(`onClickSavePlace`)

      let loader = this.showLoader();

      try {
        if (this.uploadFileList.length > 0) {

          // 写真のアップロード
          const s3KeyList = await this.uploadPhotos(this.uploadFileList)
          // サムネイル作成
          await this.createThumbnail(this.uploadFileList)
          // 検査結果レコードに写真情報を追加
          s3KeyList.forEach(function(key) {
            const order = this.inspectResult.inspectResultSet.photoList.length ? this.inspectResult.inspectResultSet.photoList.length : 0

            this.inspectResult.inspectResultSet.photoList.push({
              filePath: key,
              order: order,
              isWrite: false,
            })
          }.bind(this))

          console.log(`inspectPlaceDbData ${JSON.stringify(this.inspectPlaceDbData, null, "\t")}`)
        }

        if (this.addPlaceData) {
          // 追加
          InspectPlaceModel.addInspectPlace(this.inspectPlaceDbData).then(function() {
            // 検査結果
            console.log(`inspectResult ${this.inspectResult}`)
            if (this.inspectResult) {
              //let resultSk = InspectResultModel.createSkForList(this.projectId, this.nodeId, this.placeId)
              InspectResultModel.getInspectResultList(this.projectId, this.nodeId, this.placeId).then(function(resultList) {
                if (resultList.length > 0) {
                  // 保存
                  InspectResultModel.updateInspectResult(this.inspectResult)
                } else {
                  // 追加
                  InspectResultModel.addInspectResult(this.inspectResult)
                }
              }.bind(this))
            }
            const data = Object.assign({}, this.inspectPlaceDbData)
            this.$emit('transfer', {key: "inspectPlaceAddComplete", values: data});
          }.bind(this))
        } else if (this.editPlaceId) {
          // 更新
          InspectPlaceModel.updateInspectPlace(this.inspectPlaceDbData).then(function() {
            // 検査結果
            console.log(`inspectResult ${this.inspectResult}`)
            if (this.inspectResult) {
              //let resultSk = InspectResultModel.createSkForList(this.projectId, this.nodeId, this.placeId)
              InspectResultModel.getInspectResultList(this.projectId, this.nodeId, this.placeId).then(function(resultList) {
                if (resultList.length > 0) {
                  // 保存
                  InspectResultModel.updateInspectResult(this.inspectResult)
                } else {
                  // 追加
                  InspectResultModel.addInspectResult(this.inspectResult)
                }
              }.bind(this))

            }
            const data = Object.assign({}, this.inspectPlaceDbData)
            this.$emit('transfer', {key: "inspectPlaceEditComplete", values: data});
          }.bind(this))
        }
        // 通知の送信先が指定されているか
        if (this.selectedUsers.length > 0) {
          this.noticeData = await NoticeModel.getNewData();
          this.noticeData.notice.targetUsers = this.selectedUsers
          // コメント
          this.noticeData.notice.content = this.noticeContent
          // 区分(検査箇所で固定)
          this.noticeData.notice.type = "2"
          // 自身は既読
          this.noticeData.notice.Readed = true;
          // リンクする検査箇所のキーをセットする
          this.noticeData.notice.linkedId = this.inspectPlaceDbData.sk
          // 登録
          await NoticeModel.addNotice(this.noticeData);
          for (let i = 0; i < this.selectedUsers.length;i ++) {
            let addData = Object.assign({}, this.noticeData);

            const targetUserId = this.selectedUsers[i];
            const createUserId = addData.notice.createUser;
            addData.notice.Readed = false
            addData.sk = addData.sk.replace(createUserId, targetUserId);
            // DBに登録
            await NoticeModel.addNotice(addData);
          }
          
        }
      } catch (e) {
        console.log(JSON.stringify(e))
        throw e
      } finally {
        this.hideLoader(loader);
      }
    },
    handleOnChange(file, fileList){
      this.uploadFileList = fileList
    },
    handleRemove(file, fileList){
      this.uploadFileList = fileList
    },
    async createThumbnail(files) {
      const user = await UserInfo.getUserInfo()
      const s3KeyPrefix = `${constants.s3_tenant_prefix}/${user.group}/`
      const s3KeyBase = `project_${this.projectId}/node_${this.nodeId}/place_${this.inspectPlaceDbData.inspectPlace.placeId}/result_${this.inspectResult.inspectResultSet.id}/`
      try {
        for (let i = 0; i < files.length;i++) {
          const s3Key = s3KeyBase + files[i].name

          // サムネイル作成
          const apiName = 'apiMakeThumbnail';
          const path = '/makethumbnail';

          const myInit = {
            response: true,
            body: {
              prefix: s3KeyPrefix,
              key: s3Key
            }
          };

          await API.post(apiName, path, myInit)
        }
      } catch (e) {
        console.log(`サムネイル作成エラー ${JSON.stringify(e)}`)
        throw e
      }
    },
    async uploadPhotos(files){
      const user = await UserInfo.getUserInfo()
      const s3KeyPrefix = `${constants.s3_tenant_prefix}/${user.group}/`
      const s3KeyBase = `project_${this.projectId}/node_${this.nodeId}/place_${this.inspectPlaceDbData.inspectPlace.placeId}/result_${this.inspectResult.inspectResultSet.id}/`
      const s3KeyList = []
      try {
        for (let i = 0; i < files.length;i++) {
          const contentType = mime.lookup(files[i].name)
          // const s3Key = s3KeyBase + files[i].name
          // const option = {level: "public", customPrefix: {public: s3KeyPrefix}, contentType: contentType}
          // await Storage.put(s3Key, files[i].raw, option)

          // TenantStorageを使用するように置き換え
          // S3Keyはルートから
          const s3Key = `${s3KeyPrefix}${s3KeyBase + files[i].name}`
          await this.tenantStorage.put(s3Key, files[i].raw)

          s3KeyList.push(s3Key)
        }
      } catch (e) {
        console.log(`アップロードエラー ${JSON.stringify(e)}`)
        throw e
      }
      return s3KeyList
    },
    makePhotoList() {
      this.thumbList = []
      this.photoList = []

      this.$nextTick(function() {
        this.inspectResult.inspectResultSet.photoList.forEach(function(item){
          // 一覧表示に使用するサムネイル写真のURLリストを作成
          const thumbKey = `${path.dirname(item.filePath)}/thumb-${path.basename(item.filePath)}`
          FileUtil.getTenantFileUrl(thumbKey).then(function(result){
            this.thumbList.push(result)
          }.bind(this))
          // プレビューに使用するフルサイズ写真のURLを取得
          const photoKey = `${path.dirname(item.filePath)}/${path.basename(item.filePath)}`
          FileUtil.getTenantFileUrl(photoKey).then(function(result){
            this.photoList.push(result)
          }.bind(this))
        }.bind(this))
      }.bind(this))
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    handleChange(val) {
      console.log(val);
    },
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init().then(() => {
      console.log("call init")
    })
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  data() {
    return {
      inspectPlaceDbData: null,
      inspectResult: null,
      inspectMasterList: null,
      currentInspectMaster: null,
      tab1Show: true,
      tab2Show: false,
      tab3Show: false,
      iconPath: null,
      activeTab: "tab1",
      showInspectMasterList: true,
      resultTypes: [
        {value: "", label: "未判定"},
        {value: "0", label: "合格"},
        {value: "1", label: "不合格"},
      ],
      thumbList: [],
      photoList: [],
      uploadFileList: [],
      tenantStorage: null,
      // activeNames: ['inspectPlace']
      activeNames: ['inspectPlace', 'inspectResult', 'inspectPicture', 'notice'],
      activeStyle: [{'background-color': 'primary'}],
      defaultInspectResult: null,
      users: [],
      noticeData: null,
      noticeContent: "",
      selectedUsers: []
    }
  }
}
</script>
