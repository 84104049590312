<template>
  <!-- <div class="container-fluid pt-1 bg-light-gray" style="height:100%"> -->
    <div class="card">
      <div class="card-header border-0">
        <div id="horizonal-toolbar" class="horizonal-toolbar col-12 align-items-center" role="toolbar">
          <div class="row">
            <div>
              <h4 class="mt-1 mb-0">検査マスタ一覧</h4>
            </div>
            <div v-if="!isSingleSelect && !isMultiSelect" id="no-select-menu" class="col-md-auto inline-block">
              <el-tooltip class="item" effect="dark" content="検査マスタの新規作成" placement="bottom">
                <el-button size="medium" circle @click="onClickNewInspectMaster">
                  <i class="fas fa-plus"></i>
                </el-button>
              </el-tooltip>
            </div>
            <div v-if="isSingleSelect" id="single-select-menu" class="col-md-auto inline-block">
              <el-tooltip class="item" effect="dark" content="削除" placement="bottom">
                <el-button size="medium" circle icon="el-icon-delete" style="font-size:1.2em;" @click="onDeleteInspectMaster">
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="検査マスタのコピー" placement="bottom">
                <el-button size="medium" circle @click="onCopyInspectMaster">
                  <i class="far fa-clone"></i>
                </el-button>
              </el-tooltip>
            </div>
            <div v-if="isMultiSelect" id="multi-select-menu" class="col-md-auto inline-block">
              <el-tooltip class="item" effect="dark" content="削除" placement="bottom">
                <el-button size="medium" circle icon="el-icon-delete" style="font-size:1.2em;" @click="onDeleteInspectMaster">
                </el-button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>

      <el-table
        class="table-responsive align-items-center table-flush"
        header-row-class-name="thead-light"
        @selection-change="handleSelectionChange"
        @sort-change="runSort"
        :data="InspectMasterList"
      >
        <el-table-column type="selection" align="left" min-width="120px">
        </el-table-column>
        <el-table-column
          label="検査名"
          min-width="410px"
          prop="inspectMaster.name"
          sortable="'custom'"
        >
          <template v-slot="{ row }">
            <!-- <img :src="row.project.image" class="avatar rounded-circle mr-3" /> -->
            <b>
              <a href="#!" @click.prevent="onEditInspectMaster(row)"
                >{{ row.inspectMaster.name }}
              </a>
            </b>
          </template>
        </el-table-column>
        <el-table-column label="作成日" min-width="140px" prop="createdAt" sortable="'custom'">
            <template v-slot="{ row }">
              <span>{{ dateStringFormat(row.createdAt) }}</span>
            </template>
          </el-table-column>
        <el-table-column>
          <!-- <template v-slot="{ row }">
            <div class="table-actions">
              <el-tooltip content="Edit" placement="top">
                <a
                  href="#!"
                  @click.prevent="onEditInspectMaster(row)"
                  class="table-action table-action-edit"
                  data-toggle="tooltip"
                  data-original-title="edit product"
                >
                  <i
                    class="fas fa-edit text-red"
                    style="font-size: 1.5rem"
                  ></i>
                </a>
              </el-tooltip>
            </div>
          </template> -->
          <!--
          <template v-slot="{ row }">
            <div class="table-actions mr-1">
              <el-tooltip content="削除" placement="top">
                <a
                  href="#!"
                  @click.prevent="onDeleteInspectMaster(row)"
                  class="table-action table-action-edit"
                  data-toggle="tooltip"
                  data-original-title="削除"
                >
                  <i
                    class="fa fa-trash-alt text-red"
                    style="font-size: 1.5rem"
                  ></i>
                </a>
              </el-tooltip>
            </div>
          </template>
           -->
        </el-table-column>
      </el-table>
      <!-- 検査マスタコピーのモーダル -->
      <modal v-model:show="modalCopy" size="sm" body-classes="p-0">
        <template v-slot:header>
          <h6 class="modal-title">{{title}}のコピー</h6>
        </template>
        <card
          type="secondary"
          header-classes="bg-transparent pb-5"
          body-classes="px-lg-2 py-lg-2"
          class="border-0 mb-0"
        >
          <div class="col-md-12">
            <base-input type="text" label="検査名">
              <template></template>
              <el-input
                placeholder="検査名を入力してください"
                v-model="editMaster.inspectMaster.name"
              ></el-input>
            </base-input>
          </div>
        </card>
          <div class="row mt-2 mb-2 ml-2 mr-2">
            <div class="col-sm-5">
              <base-button
                type="secondary"
                block
                @click="modalCopy = false"
                >キャンセル</base-button
              >
            </div>
            <div class="col-sm-2">
            </div>
            <div class="col-sm-5">
              <base-button
                type="primary"
                block
                @click="onClickSubmit"
                >コピー</base-button
              >
            </div>
          </div>
      </modal>
    </div>
    <modal v-model:show="modal.isShow">
      <template v-slot:header>
          <h5 class="modal-title" id="exampleModalLabel">{{modal.title}}</h5>
      </template>
      <div>
        {{modal.message}}
      </div>
      <template v-slot:footer>
          <base-button v-model:type="modal.cancelType" @click="modal.cancelCallBack">{{modal.cancelLabel}}</base-button>
          <base-button v-model:type="modal.okType" @click="modal.okCallBack">{{modal.okLabel}}</base-button>
      </template>
    </modal>
  <!-- </div> -->
</template>
<style scoped>
  .el-button {
    border-style: none;
  }

  .inline-block {
    position: relative;
    display: inline-block;
    font-size: 14px;
  }

  .horizonal-toolbar {
    left: 0;
    height: 24px;
    width: 100%;
    text-align: left;
    font-size: 0;
  }

  .horizonal-toolbar.base-toolbar {
    /* background-color: #ffffff; */
    box-sizing: border-box;
    padding: 0px 24px 0px 21px;
    z-index: 1200;
    /* height: 48px; */
  }

  .base-toolbar {
    /* background-color: #f5f5f5 */
    z-index: 1001;
  }
</style>
<script>
//import users from "./../users";
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElTooltip,
} from "element-plus";
import InspectMasterModel from "../../appModel/InspectMaster/InspectMasterModel";
import Modal from "@/components/Modal";
import DateUtil from '../../appUtils/DateUtil';
import UserInfo from '../../appUtils/UserInfo';
import TenantStorage from "@/appUtils/TenantStorage"
import path from 'path'
import mime from 'mime-types';
import { useToast } from "vue-toastification";
import short from "short-uuid"
import SortUtil from '../../appUtils/SortUtil';

export default {
  name: "inline-actions-table",
  props: {
    transferArgs: null,
    /**
     * プロジェクトID
     */
    projectId: String
  },
  components: {
    [ElTooltip.name]: ElTooltip,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    Modal
  },
  data() {
    return {
      //users,
      dbData: null,
      currentPage: 1,
      //projectId: null,
      InspectMasterList: [],
      isSingleSelect: false,
      isMultiSelect: false,
      selectedData: null,
      modal: {
        isShow: false,
        title: "",
        message: "",
        okLabel: "OK",
        okType: "secondary",
        okCallBack: function() {
          alert(`push Ok`)
          this.modal.isShow = false
        },
        cancelLabel: "キャンセル",
        cancelType: "primary",
        cancelCallBack: function() {
          alert(`push cancel`)
          this.modal.isShow = false
        },
      },
      modalCopy: false,
      editMaster: {
        inspectMaster: {
          name: "",
        }
      },
      title: "検査マスタ",
    };
  },
  mounted() {

  },
  created() {

    this.getInspectMasterList();
  },
  unmounted() {
    this.modal.isShow = false
  },
  methods: {
    async getInspectMasterList() {
      // const currentProject = this.$store.state.project;
      // this.projectId = currentProject.project.id
      this.InspectMasterList = await InspectMasterModel.getInspectMasterList(this.projectId);
    },
    onClickNewInspectMaster() {
      // this.$router.push({
      //   name: "InspectMasterEdit",
      // });

      this.$emit('transfer', {key: "inspectMasterEditArgs", values: {}})
    },
    onDeleteInspectMaster() {
    // onDeleteInspectMaster(row) {
      const count = this.selectedData.length;
      const _this = this
      this.modal = {
        isShow: true,
        title: "検査マスタの削除",
        message: `${count}件を削除します。よろしいですか？`,
        // message: `${row.inspectMaster.name}を削除しますか？`,
        okLabel: "削除",
        okType: "danger",
        okCallBack: function() {

          _this.deleteInspectMaster(count);

          // 閉じる
          _this.modal.isShow = false
        },
        cancelLabel: "キャンセル",
        cancelType: "secondary",
        cancelCallBack: function() {
          _this.modal.isShow = false
        },
      }
    },
    onEditInspectMaster(row) {
      console.log(JSON.stringify(row, null, "\t"));

      this.$emit('transfer', {key: "inspectMasterEditArgs", values: row})
    },
    async onCopyInspectMaster() {
      const row = this.selectedData[0];
      console.log(row)

      // コピーオブジェクトを複製
      this.editMaster = JSON.parse(JSON.stringify(row));
      // this.editMaster = Object.assign({}, row);
      // this.editMaster = {...row};

      this.modalCopy = true;
    },
    /**
     * 検査マスタコピーのモーダルでコピーボタンクリック時の処理です。
     */
    async onClickSubmit() {

      let loader = this.showLoader();

      try {
        // 新規オブジェクトにコピー元のデータをセット
        const newData = await InspectMasterModel.getNewData(this.projectId);
        newData.inspectMaster.name = this.editMaster.inspectMaster.name;
        newData.inspectMaster.inspectItemList = this.editMaster.inspectMaster.inspectItemList;
        newData.inspectMaster.iconSetting = this.editMaster.inspectMaster.iconSetting;
        newData.inspectMaster.inputMaster = this.editMaster.inspectMaster.inputMaster;

        console.log(`コピー登録データ：${JSON.stringify(newData, null, "\t")}`)

        if (newData.inspectMaster.iconSetting) {
          try {
            // コピー元のs3からアイコンファイルをコピー
            await this.copyS3Icon(newData.inspectMaster.id, newData.inspectMaster.iconSetting)
          } catch (e) {
            this.showBottomToast(`アイコンファイルのコピーに失敗しました`, 'warning')
            return;
          }
        }

        // コピーした検査マスタを登録
        await InspectMasterModel.addInspectMaster(newData);

        // 一覧を更新
        await this.getInspectMasterList();

      } finally {
        this.hideLoader(loader);
        this.modalCopy = false;
      }
    },
    async deleteInspectMaster(deleteCount) {

      let loader = this.showLoader();

      try {
        for (let i = 0; i < deleteCount; i++) {
          const row = this.selectedData[i];

          // s3からアイコンの削除
          await this.deleteS3Icons(row);

          // DBから削除
          await InspectMasterModel.deleteInspectMaster(row);

          // 選択行のskに該当しないものだけを残す
          this.InspectMasterList = this.InspectMasterList.filter(function(item) {
            return item.sk != row.sk
          })
        }
      } finally {
        this.hideLoader(loader);
      }

    },
    /**
     * s3からアイコンファイルを削除します。
     */
    async deleteS3Icons(data) {
      const user = await UserInfo.getUserInfo();
      const tenantStorage = new TenantStorage(user.group);

      try {
        const iconSettings = data.inspectMaster.iconSetting;

        if (iconSettings) {
          for (let i = 0; i < iconSettings.length; i++) {
            const icon = iconSettings[i];

            // テナントディレクトリを付与
            const s3key = `${tenantStorage.getBaseDir()}${icon.iconFilePath}`;

            tenantStorage.delete(s3key)
          }
        }
      } catch (e) {
        alert(`error deleteTenantIconFile ${JSON.stringify(e, null, "\t")}`)
        throw e
      }

    },
    /**
     * コピー元のs3からアイコンファイルをコピーします。
     * @param {string} inspectMasterId 検査マスタID（コピー先）
     * @param {object} iconSetting アイコン定義(※コピー元のパスが設定されている状態)
     */
    async copyS3Icon(inspectMasterId, iconSetting) {

      const user = await UserInfo.getUserInfo();
      const tenantStorage = new TenantStorage(user.group);

      // ---------------------
      // コピー元からDL
      // ---------------------
      let iconDatas = [];

      try {
        for (let i = 0; i < iconSetting.length; i++) {
          const icon = iconSetting[i];

          // テナントディレクトリを付与
          const s3key = `${tenantStorage.getBaseDir()}${icon.iconFilePath}`;

          // s3からget(この時点ではbase64)
          const iconData = (await tenantStorage.get(s3key)).toBase64();
          console.log(`DL iconData:: ${iconData}`)

          iconDatas.push(iconData);
        }
      } catch (e) {
        alert(`error getTenantIconFile ${JSON.stringify(e, null, "\t")}`)
        throw e
      }

      // ---------------------
      // コピー先へアップロード
      // ---------------------
      try {
        for (let i = 0; i < iconDatas.length; i++) {
          const icon = iconDatas[i];
          const iconData = icon.replace(/^data:\w+\/\w+;base64,/, '')
          const decodedIcon = Buffer(iconData, 'base64')

          const iconFileName = path.basename(iconSetting[i].iconFilePath)

          const newIconFilePath = `project_${this.projectId}/inspectMaster_${inspectMasterId}/${iconFileName}`
          // テナントprefixを付与
          const s3key = `${tenantStorage.getBaseDir()}${newIconFilePath}`;

          let contentType = mime.lookup(iconFileName)
          const option = {ContentType: contentType}

          console.log(`uploadIcon:${s3key} ${JSON.stringify(option)}`)

          await tenantStorage.put(s3key, decodedIcon, option)

          // アイコン定義のs3パスをコピー先に更新
          iconSetting[i].iconFilePath = newIconFilePath;
        }
      } catch (e) {
        alert(`error putTenantIconFile ${JSON.stringify(e, null, "\t")}`)
        throw e
      }
    },
    /**
     * テーブルの選択状態の変更時に呼び出されます。
     */
    handleSelectionChange(selections) {
      const count = selections.length;
      console.log(`選択件数：${count}`);

      if (count > 1) {
        this.isSingleSelect = false;
        this.isMultiSelect = true;
      } else if (count == 1) {
        this.isSingleSelect = true;
        this.isMultiSelect = false;
      } else {
        // 0件
        this.isSingleSelect = false;
        this.isMultiSelect = false;
      }

      this.selectedData = selections;
    },
    /**
     * 日付文字を別の形式に変更
     */
    dateStringFormat(dateString) {
      return DateUtil.getFormatString(dateString, "YYYY/MM/DD HH:mm")
    },
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },
    /**
     * ソートを実行します。
     */
    async runSort(column) {
      await this.changeTableSort(column);
    },

    /**
     * テーブルのソート順を変更します。
     */
    async changeTableSort(column){
      console.log(column);

      if (column === false) {
        return;
      }

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;

      console.log(fieldName);

      if (!fieldName) {
        return;
      }

      // 復元用に保持
      // this.sortColumn = column;

      // 日本語ソート
      let isKana = true;
      if (fieldName == "createdAt") {
        isKana = false;
      }

      this.InspectMasterList = await SortUtil.sort(this.InspectMasterList, fieldName, sortingType, isKana);
    },
    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
  },
};
</script>
