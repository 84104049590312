<template>
  <div>
    <div class="container-fluid">
      <div class="row ">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0)" class="text-rebarprimary" @click="toList">測定データ一覧</a>
            </li>
          </ol>
        </nav>
      </div>
      <h3 class="content-title mt-3 ml-2">アカウント契約情報</h3>
      <div class="row mt-0 no-gutters">
        <!-- 契約期間 -->
        <div class="col-sm-12 col-md-6">
          <div class="subContent">
            <div class="row pt-3 pb-2 pl-2 pr-3 text-left">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-12">
                    <h4 class="content-title">契約期間</h4>
                    <div class="row pt-1 pb-0">
                      <h5 class="col">{{contractStart}} ~ {{contractEnd}}</h5>
                      <span class="col text-right">あと{{remainDays}}日</span>
                    </div>
                    <base-progress class="ml-0 mr-0" :type="contractType" :height="8" :value="contractProgressValue" ></base-progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ストレージ使用容量 -->
        <div class="col-sm-12 col-md-6">
          <div class="subContent">
            <div class="row pt-3 pb-2 pl-2 pr-3 text-left ">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-12">
                    <h4 class="content-title">ストレージ使用容量</h4>
                    <div class="row pt-1 pb-0">
                      <h5 class="col">{{usedSpace}}GB / {{maxSize}}GB</h5>
                      <span class="col text-right">{{usedSpaceProgressValue}}％</span>
                    </div>
                    <base-progress class="ml-0 mr-0" :type="spaceType" :height="8" :value="usedSpaceProgressValue" ></base-progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row " v-if="allowChangePassword">
        <!-- パスワード変更機能 -->
        <div class="col-12">
          <div class="subContent">
            <div class="row pt-3 pb-2 pl-2 pr-3 text-left justify-content-center">
              <div class="col-sm-6 col-xs-12">
                <h4 class="content-title">パスワードの変更</h4>
                <div class="pl-lg-4">
                  <div class="col-lg-12">
                    <base-input type="text" label="現在のパスワード">
                      <template></template>
                      <el-input
                        type="password"
                        placeholder="現在のパスワード"
                        v-model="currentPassword"
                      ></el-input>
                    </base-input>
                  </div>

                  <div class="col-lg-12">
                    <base-input type="text" label="パスワード">
                      <template></template>
                      <el-input
                        type="password"
                        placeholder="パスワード"
                        v-model="password"
                      ></el-input>
                    </base-input>
                  </div>

                  <div class="col-lg-12">
                    <base-input type="text" label="パスワードの確認">
                      <template></template>
                      <el-input
                        type="password"
                        placeholder="パスワードの確認"
                        v-model="confirmPassword"
                      ></el-input>
                    </base-input>
                  </div>
                </div>

                <button type="button" class="btn btn-rebarprimary w-100 m-2" @click="updatePassword">パスワード変更</button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "./css/common.css";
</style>
<style scoped>
  .breadcrumb {
    background-color: transparent;
    font-size: 0.8em;
    margin-bottom: 0;
    padding: 0.5em 2em;
  }

</style>
<script>
import MeasureModel from "../../appModel/rebar/MeasureModel";
import TenantModel from "../../appModel/Tenant/TenantModel"
const UserInfo = require('../../appUtils/UserInfo');
const DateUtil = require('../../appUtils/DateUtil');
import AuthUtil from "../../appUtils/AuthUtil"
import appLog from "../../appUtils/AppLog"
import { Auth } from 'aws-amplify'
import { useToast } from "vue-toastification";

export default {
  components: {

  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前

    // 上限値(最大数 x 契約数)
    this.maxSize = process.env.VUE_APP_STORAGE_LIMIT;
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  data() {
    return {
      contractStart: "",
      contractEnd: "",
      contractType: "rebarprimary",
      contractProgressValue: 0.0,
      remainDays: 0,
      usedSpace: 0.0,
      spaceType: "rebarprimary",
      usedSpaceProgressValue: 0.0,
      maxSize: 20.0,
      allowChangePassword: false,
      currentPassword: "",
      password: "",
      confirmPassword: ""
    };
  },
  watch: {},
  /**
   * コンピュートプロパティ
   */
  computed: {
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {
      // パスワード変更を許可するユーザのプレフィックス
      const trialPrefix = `trial`
      const testPrefix = `test`
      // ユーザ情報
      const loginInfo = await UserInfo.getUserInfo()
      if (loginInfo.userName.indexOf(trialPrefix) === 0 || loginInfo.userName.indexOf(testPrefix) === 0) {
        
        this.allowChangePassword = true
      }

      appLog.infoLog("Account.vue", loginInfo.userName, `start account.`)

      // 契約期間の取得
      await this.initContractPeriod(loginInfo.group);

      // ストレージ空き容量の取得
      await this.initUsedSpace();
    },
    /**
     * 契約期間の初期設定
     * @param {String} tenantId テナントID
     */
    async initContractPeriod(tenantId) {

      if (!tenantId) {
        // ToDo テナント情報なし
        return;
      }

      // データ取得
      const tenantData = await TenantModel.getTenant(tenantId);
      // 契約日
      const tenant = tenantData.tenant;
      const format = 'YYYY/MM/DD';
      this.contractStart = DateUtil.getFormatString(tenant.contractStart, format);
      this.contractEnd = DateUtil.getFormatString(tenant.contractEnd, format);

      // 利用可能容量
      console.log(`${tenant.maxCapacity}`)
      this.maxSize = tenant.maxCapacity ?? 20

      // 進捗値
      this.contractProgressValue = await this.getContractValue();

      // 種別
      this.contractType = await this.getType(this.contractProgressValue);

    },
    /**
     * ストレージ空き容量の初期設定
     */
    async initUsedSpace() {
      const measures = await MeasureModel.getMeasureList()

      let totalBytes = 0.0;
      // 測定画像のサイズを取得する
      for (let m = 0; m < measures.length; m++) {
        const images = measures[m].rebarmeasure.imageList;

        for (let i = 0; i < images.length; i++) {
          if (images[i].bytes) {
            totalBytes = totalBytes + images[i].bytes;
          }
        }
      }
      // 使用容量
      this.usedSpace = await this.round(totalBytes / 1024 / 1024 / 1024, 0.1);

      // GB換算時に0の場合は0.1表記にする
      if (totalBytes > 0 && this.usedSpace == 0) {
        this.usedSpace = 0.1;
      }

      // test
      // this.usedSpace = 9.90999;
      // this.usedSpace = await this.round(this.usedSpace, 0.1);

      // 進捗値、ラベル表示用(n.n%)
      this.usedSpaceProgressValue = await this.round(this.usedSpace / this.maxSize * 100, 0.1);

      // GB換算時に0の場合は0.1表記にする
      if (totalBytes > 0 && this.usedSpaceProgressValue == 0) {
        this.usedSpaceProgressValue = 0.1;
      }

      // 種別
      this.spaceType = await this.getType(this.usedSpaceProgressValue);
    },
    /***
     * 契約期間の進捗値を取得します。
     */
    async getContractValue() {
      let value = 0;
      
      
      // 現在日時
      const nowDateTime = DateUtil.getDateString('YYYY/MM/DD')
      const diff = DateUtil.diff(nowDateTime, this.contractStart, "day")
      const total = DateUtil.diff(this.contractEnd, this.contractStart, "day")
      
      let parcent = (diff / total) * 100

      // 残日数
      this.remainDays = DateUtil.diff(this.contractEnd, nowDateTime, "day")

      return Math.round(parcent);
    },

    /**
     * プログレスバーのタイプを取得します。
     * @param {number} value 進捗値
     * @return {String} プログレスバータイプ
     */
    async getType(value) {
      if (value < 40) {
        return "rebarprimary";
      } else if (value >= 40 && value < 60) {
        return "info";
      } else if (value >= 60 && value < 70) {
        return "success";
      } else if (value >= 70 && value < 80) {
        return "yellow";
      } else if (value >= 80 && value < 90) {
        return "warning";
      } else if (value >= 90) {
        return "danger";
      }
    },
    /**
     * 任意の桁で四捨五入します。
     * @param {number} value 四捨五入する数値
     * @param {number} base どの桁で四捨五入するか（10→10の位、0.1→小数第１位）
     * @return {number} 四捨五入した値
     */
    async round(value, base) {
      if (base < 1.0) {
        // 小数点以下の誤差発生対策
        base *= 100;
        return Math.round(value * base) / base;
      } else {
        return Math.round(value / base) * base;
      }
    },
    /**
     * 測定データ一覧へ
     */
    async toList() {
      // セッション切れ確認
      let isValid = await AuthUtil.isValidSession();
      if (!isValid) {
        AuthUtil.alert();

        // セッションなし -> ルートへリダイレクト
        this.$router.push({
          path: `/`
        })
        return;
      }

      const loginInfo = await UserInfo.getUserInfo()
      appLog.infoLog("Account.vue", loginInfo.userName, `go to List.`)

      this.$router.push({
        name: 'List'
      })
    },
    /**
     * Toastの表示
     */
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },
    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos
      });
    },
    /**
     * パスワード更新
     */
    async updatePassword() {
      const loginInfo = await UserInfo.getUserInfo()
      
      if (this.changePassword) {
        if (!this.currentPassword) {
          this.showBottomToast(`現在のパスワードを入力してください`, `error`)
          return;
        }
        if (this.password != this.confirmPassword) {
          //未入力はエラー
          this.showBottomToast(`パスワードが一致しません`, `error`)
          return;
        }
      }
      // 確認
      this.$confirm('パスワードを変更します。よろしいですか？', 'パスワードの変更', {
        confirmButtonText: 'OK',
        cancelButtonText: 'キャンセル',
        type: 'info'
      }).then(async function() {
        try {
          this.loader = this.$loading.show({color: '#11cdef', backgroundColor: "#333333"});
          // 新パスワードで認証ができるかチェック(認証ができれば古いパスワードと同じということになる)
          let authCheckResult = false
          try {
            await Auth.signIn(loginInfo.userName, this.password)
          } catch (e) {
            switch (e.code) {
            case 'NotAuthorizedException':
              // 認証出来なければOK
              authCheckResult = true
              break
            default:
              authCheckResult = false
              break
            }
          }
          
          if (!authCheckResult) {
            this.showBottomToast(`パスワードの変更に失敗しました。変更前と同じパスワードは使用できません`, `error`)
            return
          }

          const user = await Auth.currentAuthenticatedUser() // ログイン中のユーザー情報
          await Auth.changePassword(
            user,
            this.currentPassword,
            this.password
          )
          // 入力値をクリア
          this.currentPassword = ""
          this.password = ""
          this.confirmPassword = ""

          this.showBottomToast(`パスワードを更新しました。`, `info`)

        } catch (e) {
          switch (e.code) {
          case 'NotAuthorizedException':
            // oldPassword が誤っている
            this.showBottomToast(`パスワードの変更に失敗しました。変更前のパスワードが異なります。`, `error`)
            break
          case 'LimitExceededException':
            // oldPassword 誤りの上限に達した
            this.showBottomToast(`パスワードの変更に失敗しました。パスワード変更がロックされています。`, `error`)
            break
          case 'InvalidPasswordException':
            // 許可されないパスワード強度
            this.showBottomToast(`パスワードの変更に失敗しました。パスワードの強度が不足しています。`, `error`)
            break
          case 'InvalidParameterException':
            // 入力値がCognitoの仕様を満たしていない
            this.showBottomToast(`パスワードの変更に失敗しました。パスワードの強度が不足または使用できない文字が設定されています。`, `error`)
            break
          default:
            // その他のエラー
            appLog.errLog("Account.vue", loginInfo.userName, `${JSON.stringify(e)}`)
          }
        } finally {
          this.loader.hide();
        }
      }.bind(this))
      
    }
  },
};
</script>

