const MiltFormCreator = require("./MiltFormCreator");
const MiltFormKaburiCreator = require("./MiltFormKaburiCreator");
const MiltFormJointCreator = require("./MiltFormJointCreator");
const SmcFormCreator = require("./SmcFormCreator");
const ShizuokaFormCreator = require("./ShizuokaFormCreator");

class FormFactory {
  constructor(formTypeId) {
    this.formCreator = null

    switch (formTypeId){
    case 1:
      // 静岡県庄板工フォーマット
      this.formCreator = new ShizuokaFormCreator()
      break
    case 2:
      // 三井オリジナルフォーマット
      this.formCreator = new SmcFormCreator()
      break
    case 3:
      // 国交省フォーマット 配筋間隔
      this.formCreator = new MiltFormCreator()
      break
    case 4:
      // 国交省フォーマット かぶり厚確認
      this.formCreator = new MiltFormKaburiCreator()
      break
    case 5:
      // 国交省フォーマット 重ね継手長確認
      this.formCreator = new MiltFormJointCreator()
      break
    }
  }
}

module.exports = FormFactory;